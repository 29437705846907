import React from 'react';
import {RemoveIcon} from '../../assets/icons';
import styles from '../../styles/AddFamilyMemberAtBranchForm.module.scss';

const AddFamilyMemberForm = ({
  index,
  onDelete,
  onInputChange,
  data,
  familyNumberText,
}) => {
  // const handleInputChange = e => {
  //   const {name, value} = e.target;
  //   onInputChange(index, name, value);
  // };

  const handleInputChange = (name, value) => {
    onInputChange(index, name, value);
  };

  return (
    <div className="form">
      <div className={styles.formMemberNumText}>
        <p> {familyNumberText[index]} Family Member </p>
        <div
          className={styles.deleteMemberForm}
          onClick={() => onDelete(index)}>
          <RemoveIcon />
        </div>
      </div>
      <div className={styles.fieldContainer2}>
        <div className={styles.fieldInnerContainer1}>
          <label htmlFor="">
            First Name<sup>*</sup>
          </label>
          <input type="text" name="First_name" onChange={(e)=>{handleInputChange('First_name', e.target.value)}}
 />
        </div>
        <div className={styles.fieldInnerContainer1}>
          <label htmlFor="">
            Middle Name<sup>*</sup>
          </label>
          <input type="text"
            name="Middle_name" onChange={(e)=>{handleInputChange('Middle_name', e.target.value)}}/>
        </div>
        <div className={styles.fieldInnerContainer1}>
          <label htmlFor="">
            Last Name<sup>*</sup>
          </label>
          <input type="text" name="Last_name" onChange={(e)=>{handleInputChange('Last_name', e.target.value)}}/>
        </div>
      </div>
      <div className={styles.fieldContainer2}>
        <div className={styles.fieldInnerContainer1}>
          <label htmlFor="">
            Email ID<sup>*</sup>
          </label>
          <input type="text" name="User_Email_id" onChange={(e)=>{handleInputChange('User_Email_id', e.target.value)}}/>
        </div>
        <div className={styles.fieldInnerContainer1}>
          <label htmlFor="">
            Mobile No
            <sup>*</sup>(No Country Code or '0')
          </label>
          <input type="text" name="Phone_no" onChange={(e)=>{handleInputChange('Phone_no', e.target.value)}} />
        </div>

        <div className={styles.fieldInnerContainer1}>
          <label htmlFor="">
            Redemption Limit Per Day<sup>*</sup>
          </label>
          <input type="text" name="Family_redeem_limit" onChange={(e)=>{handleInputChange('Family_redeem_limit', e.target.value)}} />
        </div>
      </div>
    </div>
  );
};

export default AddFamilyMemberForm;
