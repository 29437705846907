import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Table from '../../component/CartTable/CartTable';
import Dropdown from '../../component/DropDown/DropDown';
import styles from '../../styles/Cart.module.scss';
import CartStructure from '../CartStructure/CartStructure';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';

const Cart = () => {
  // const cart = JSON.parse(sessionStorage.getItem('cart')) || [];
  const [cart, setCart] = useState([]);

  const [stateID, setStateID] = useState('');
  const [cityID, setCityID] = useState('');
  const [optionID, setOptionID] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');

  const handleStateID = id => {
    setStateID(id);
  };
  const handleCityID = id => {
    setCityID(id);
  };

  const handleState = name => {
    setState(name);
  };
  const handleCity = name => {
    setCity(name);
  };

  useEffect(() => {
    const cartData = JSON.parse(sessionStorage.getItem('cart')) || [];
    setCart(cartData);
  }, []);

  const removeFromCart = index => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);

    setCart(updatedCart);
    sessionStorage.setItem('cart', JSON.stringify(updatedCart));
  };
  //   console.log(cartData);
  //  const data = [
  //     {
  //       'Item Name': "OMA'S CRISP PLANTAIN CHIPS SPICY 2005G",
  //       'Redemption Method': 'Pick-up',
  //       'Partner Location': 'Grand Square Victoria Island, VICTORIA ISLAND',
  //       Quantity: 110,
  //       'Redemabled Points': 181500,
  //       'Shipping Points': 0,
  //       Action: 'Remove',
  //     },
  //     {
  //       'Item Name': "OMA'S CRISP PLANTAIN CHIPS SPICY 2100G",
  //       'Redemption Method': 'Pick-up',
  //       'Partner Location': 'Grand Square Victoria Island, VICTORIA ISLAND',
  //       Quantity: 1,
  //       'Redemabled Points': 1800,
  //       'Shipping Points': 0,
  //       Action: 'Remove',
  //     },
  //     {
  //       'Item Name': "OMA'S PLANTAIN CHIPS SPICY 200G",
  //       'Redemption Method': 'Pick-up',
  //       'Partner Location': 'Grand Square Victoria Island, VICTORIA ISLAND',
  //       Quantity: 1,
  //       'Redemabled Points': 18150,
  //       'Shipping Points': 0,
  //       Action: 'Remove',
  //     },
  //   ];

  //   const dataDelivery = [
  //     {
  //       'Item Name': "OMA'S CRISP PLANTAIN CHIPS SPICY 2005G",
  //       'Redemption Method': 'Delivery',

  //       Quantity: 110,
  //       'Redemabled Points': 181500,
  //       'Shipping Points': 0,
  //       Action: 'Remove',
  //     },
  //     {
  //       'Item Name': "OMA'S CRISP PLANTAIN CHIPS SPICY 2100G",
  //       'Redemption Method': 'Delivery',

  //       Quantity: 1,
  //       'Redemabled Points': 1800,
  //       'Shipping Points': 0,
  //       Action: 'Remove',
  //     },
  //     {
  //       'Item Name': "OMA'S PLANTAIN CHIPS SPICY 200G",
  //       'Redemption Method': 'Delivery',

  //       Quantity: 1,
  //       'Redemabled Points': 18150,
  //       'Shipping Points': 0,
  //       Action: 'Remove',
  //     },
  //   ];

  return (
    <CartStructure>
      <div>
        <div className={styles.mainContainer}>
          <div className={styles.profileNavOuterContainer}>
            <div className={styles.profileNav}>
              <div>
                <Link
                  to="/call-center/member-profile"
                  className={styles.unActiveLink}>
                  Profile
                </Link>
              </div>
              <div className={styles.currentNav}>
                <Link
                  className={`${styles.currentNav} ${styles.linkstyles}`}
                  to="/call-center/redeem-catalogue">
                  Redeem Catalogue
                </Link>
              </div>
              <div>
                <Link
                  className={styles.unActiveLink}
                  to="/call-center/transaction-details">
                  Transaction details
                </Link>
              </div>
              <div>
                <Link
                  className={styles.unActiveLink}
                  to="/call-center/member-query-record">
                  Member Query Record
                </Link>
              </div>
              <div>
                <Link
                  className={styles.unActiveLink}
                  to="/call-center/log-query">
                  Log Query
                </Link>
              </div>
              <div>
                <Link
                  className={styles.unActiveLink}
                  to="/call-center/add-family-card">
                  Add Family Card
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.cartTextContainer}>
            <p className={styles.cartText}>Cart</p>
            <Link
              className={styles.backToCatalogue}
              to="/call-center/redeem-catalogue">
              Back to catalogue
            </Link>
          </div>
          <div className={styles.pickupSection}>
            <h6 className={styles.itemsText}>Pick Up Items</h6>
            <div className={styles.tableContainer}>
              <Table
                data={
                  cart.filter(
                    item => item['Redemption Method'] === 'Pick-Up',
                  ) || []
                }
                removeFromCart={removeFromCart}
              />
            </div>
          </div>
          <div className={styles.deliverySection}>
            <h6 className={styles.itemsText}>Delivery Items</h6>
            <div className={styles.tableContainer}>
              <Table
                data={
                  cart.filter(
                    item => item['Redemption Method'] === 'Delivery',
                  ) || []
                }
                removeFromCart={removeFromCart}
              />
            </div>
          </div>
          <div className={styles.deliveryAddressContainer}>
            <p>Delivery Address</p>
            <div className={styles.inputContainer1}>
              <div className={styles.inputBox1}>
                <label htmlFor="">First name</label>
                <input type="text" placeholder="First name" />
              </div>
              <div className={styles.inputBox1}>
                <label htmlFor="">Last Name</label>
                <input type="text" placeholder="Last Name" />
              </div>
              <div className={styles.inputBox1}>
                <label htmlFor="">Email Address</label>
                <input type="text" placeholder="Email Address" />
              </div>
              <div className={styles.inputBox1}>
                <label htmlFor="">Mobile Number</label>
                <input type="tel" placeholder="Mobile Number" />
              </div>
            </div>
            <div className={styles.inputContainer1}>
              <div className={styles.inputBox1}>
                <label htmlFor="">Country</label>
                <Dropdown />
              </div>
              <div className={styles.inputBox1}>
                <label htmlFor="">State</label>
                <Dropdown
                  api={ApiConfig.fetchState(1)}
                  onSelect={handleStateID}
                  onSelectOption={handleState}
                  retrievedValue={stateID}
                  column={'name'}
                  key="state"
                />
              </div>
              <div className={styles.inputBox1}>
                <label htmlFor="">City</label>
                <Dropdown
                  api={ApiConfig.fetchCity(stateID)}
                  onSelect={handleCityID}
                  onSelectOption={handleCity}
                  retrievedValue={cityID}
                  column={'name'}
                  key="city"
                />
              </div>
              <div className={styles.inputBox1}>
                <label htmlFor="">Address</label>
                <input type="text" placeholder="Address" />
              </div>
            </div>
          </div>
          <div className={styles.shippingAddressContainer}>
            <div className={styles.pointsContainer}>
              <p>Total Points</p>
              <h6>88760</h6>
            </div>
            <div className={styles.pointsContainer}>
              <p>Total Shipping Points</p>
              <h6>0</h6>
            </div>
            <div className={styles.pointsContainer}>
              <p>Grand Total Points</p>
              <h6>0</h6>
            </div>
            <div className={styles.pointsContainer}>
              <div className={styles.innerPointsContainer}>
                <p>Call Center User Pin</p>
                <div>
                  <input type="text" placeholder="Pin" />
                </div>
              </div>
            </div>
            <div className={styles.placeOrderBtn}>
              <button>Place Order</button>
            </div>
            <Link
              className={styles.backToCatalogueButtom}
              to="/call-center/redeem-catalogue">
              Back to catalogue
            </Link>
          </div>
        </div>
      </div>
    </CartStructure>
  );
};

export default Cart;
