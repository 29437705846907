import React from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/AssignFamilyMembermembershipIDList.module.scss';
import {useLocation} from 'react-router-dom';

const AssignFamilyMembermembershipIDList = () => {
  const location = useLocation();

  const data = [location.state.data];

  // const tableData = [
  //   {
  //     firstName: 'John',
  //     lastName: 'Smith',
  //     middleName: 'Doe',
  //     email: 'eudoxusganium@gmail.com',
  //     phoneNumber: '08146819263',
  //     membershipId: 8711131,
  //   },
  //   {
  //     firstName: 'John',
  //     lastName: 'Smith',
  //     middleName: 'Doe',
  //     email: 'eudoxusganium@gmail.com',
  //     phoneNumber: '08146819263',
  //     membershipId: 8711132,
  //   },
  //   {
  //     firstName: 'John',
  //     lastName: 'Smith',
  //     middleName: 'Doe',
  //     email: 'eudoxusganium@gmail.com',
  //     phoneNumber: '08146819263',
  //     membershipId: 8711130,
  //   },
  // ];

  return (
    <CallCenterStructure>
      <div className={styles.assignFamilyMembermembershipIDList}>
        <div className={styles.headerText}>
          <p>Assign Family Member membership ID</p>
        </div>
        <div className={styles.membershipIdContainer}>
          {/* <p>[[{data[0]['Membership_id']}]] - Family Members</p> */}
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Middle Name</th>
                  <th>Last Name</th>
                  <th>Email ID</th>
                  <th>Phone No.</th>
                  <th>Membership ID</th>
                </tr>
              </thead>
              <tbody>
                {data.map(each => (
                  <tr key={each.membershipId}>
                    <td>{each.First_name}</td>
                    <td>{each.Middle_name}</td>
                    <td>{each.Last_name}</td>
                    <td>{each.User_Email_id}</td>
                    <td>{each.Phone_no}</td>
                    <td>{each.Membership_id}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </CallCenterStructure>
  );
};

export default AssignFamilyMembermembershipIDList;
