import React, {useState, useEffect} from 'react';
import {Mark, Times, ViewPen} from '../../assets/icons';
import SearchInput from '../../component/SearchInput/SearchInput';
import styles from '../../styles/PaymentType.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import { useQuery, useQueryClient, useMutation} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
//import { getUser } from '../../service/common';
//import { getToken } from '../../service/common';
import { Headers, ProgramID} from '../../service/Essentials';
import { useNavigate } from "react-router-dom";
import Paginate from '../../component/Paginate/Paginate';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';

const PaymentType = () => {
const queryClient = useQueryClient();

//const navigate = useNavigate();

// const user = getUser();

// if(!user){
//     navigate('/');
//   }

  const program_id = ProgramID() ?? '';

  const [page, setPage] = useState(1);

  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState(null);


  const [searchTerm, setSearchTerm] = useState('');
  
  const [filteredData, setFilteredData] = useState([]);


  // const handleSearch = async () => {
  //   try {
  //     const response = await axiosProperty.get(ApiConfig.searchPaymentType+`?search=${searchTerm}`,
  //     {
  //       headers:{
  //           'Program-Id':program_id,
  //           'Authorization':'Bearer ' + getToken(),
  //           'X-Requested-With': 'XMLHttpRequest',
  //       }
  //   }  
  //     );
  //     //setSearchResults(response.data);
  //     console.log(response.data);
  //   } catch (error) {
  //     console.error('Error fetching search results:', error);
  //   }
  // };


  const fetchPaymentTypes = async (page) =>{
    const res = await axiosProperty.get(ApiConfig.fetchPaymentType+`?page=${page}`, {
        headers:Headers()
    }
    );

    return res.data;
  }  
  const {data, status} = useQuery(['paymenttypes', page], () => fetchPaymentTypes(page), {keepPreviousData:true});

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data) {
      const filtered = data.data.filter(
        (item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  const [text, setText] = useState('');
 // const [successModal, setSuccessModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState();

  const handleChange = e => {
    setText(e.target.value);
  };

  const addPaymentType = async () => {
    try{
      await axiosProperty.post(ApiConfig.createPaymentType, {name: text}, 
      {
        headers:Headers()
    }
      );
      //setSuccessModal(true);
      setText('');
      setErrors(null);
      swal({
        title: "Success!",
        text: "Payment type created successfully",
        icon: "success",
      });
  }catch(err){
    if(err.response){
    setError(err.response.data.message);
    setErrors(err.response.data);
    }else{
     setError('Something went wrong, probably network'); 
    }
    console.log(err);
    swal({
      title: "Error!",
      text: error,
      icon: "error",
    });
  }
  };

  const {mutate:addPaymentTypeMutation} = useMutation({
    mutationFn:addPaymentType,
    onSuccess:()=>{
      queryClient.invalidateQueries(['paymenttypes']);
    }
  });

  const updatePaymentType = async () => {
    //e.preventDefault();
    try{
        await axiosProperty.patch(ApiConfig.updatePaymentType+`/${updateID}`, {name: text},
        {
          headers:Headers()
      }
        );
        //setSuccessModal(true);
        setText('');
        setErrors(null);
        setIsSubmit(true);
        setIsUpdate(false);
        swal({
          title: "Success!",
          text: "Payment type updated successfully",
          icon: "success",
        });
    }catch(err){
      if(err.response){
      setError(err.response.data.message);
      setErrors(err.response.data);
      }else{
       setError('Something went wrong, probably network'); 
      }
      console.log(err);
      swal({
        title: "Error!",
        text: error,
        icon: "error",
      });
    }
  };

  const {mutate:updatePaymentTypeMutation} = useMutation({
    mutationFn:updatePaymentType,
    onSuccess:()=>{
      queryClient.invalidateQueries(['paymenttypes']);
    }
  });

  const editPaymentType = async (id) => {
       const res = await axiosProperty.get(ApiConfig.editPaymentType+`/${id}`,
        {
          headers:Headers()
      }
      );
      setIsUpdate(true); 
      setIsSubmit(false); 
      setText(res.data.data.name);
  };

 // console.log(text, 'Text');
  return (
    <CallCenterStructure>
      <div className={styles.transactionTypeContainer}>
        <div>
          <h6 className={styles.headerText}>Payment Type Master</h6>
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="" className={styles.transactionTypeLabel}>
            Enter Payment Type Name <sup>*</sup>
          </label>

          <div className={styles.formContainer}>
            <input
              type="text"
              value={text}
              onChange={handleChange}
              className={styles.transactionTypeInput}
            />
            {isSubmit && (  <button
              className={styles.transactionTypeBtn}
              onClick={()=>addPaymentTypeMutation()}>
              Submit
            </button>)}
            {isUpdate && ( <button
              className={styles.transactionTypeBtn}
              onClick={()=>updatePaymentTypeMutation()}>
              Update
            </button>)}
          </div>

          <p className={styles.transactionTypeNoteText}>
            *Note:Click on column header for Ascending/Descending
          </p>
        </div>
        <div className={styles.tableContainer}>
          <ErrorMessage errMsg={errors} />
          <div className={styles.formContainer}>
            <div className={styles.searchInput}>
              <SearchInput onSearchInputChange={handleSearchInputChange} />
            </div>
            {/* <button className={styles.transactionTypeBtn}>Search</button> */}
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.dataTable}>
              <thead>
                <tr>
                  <th>Payment Type ID</th>
                  <th>Payment Type Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {
                status === 'error' && (<tr><td className='text-danger'>Error Occured</td></tr>)
              }
              {
                status === 'loading' && (<tr><td className='text-info'>loading</td></tr>)
              }

                { status === 'success' && (filteredData.map(each => (
                  <tr key={each.id}>
                    <td>{each.id}</td>
                    <td>{each.name}</td>
                    <td onClick={()=>{editPaymentType(each.id); setUpdateID(each.id)}}>
                      <ViewPen />
                    </td>
                  </tr>
                )))
                }

              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={data} setPage={setPage} />
          </div>
        </div>
        {/* {successModal && (
          <div className={styles.successModal}>
            <Mark />
            <p className={styles.successText}>
              Operation Successful!
            </p>
            <span onClick={() => setSuccessModal(false)}>
              <Times />
            </span>
          </div>
        )} */}
      </div>
    </CallCenterStructure>
  );
};

export default PaymentType;
