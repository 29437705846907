import React from 'react';
import Dropdown from '../../component/DropDown/DropDown';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/LSLPartner.module.scss';

const LSLPartner = () => {
  return (
    <CallCenterStructure>
      <div className={styles.lslInvoiceContainer}>
        <div className={styles.headerText}>
          <p>LSL Partner Redemption Report</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputInnerContainer}>
            <div className={styles.inputAndLabelContainer}>
              <label htmlFor="">
                From Date<sup>*</sup>
              </label>
              <input type="date" />
            </div>
            <div className={styles.inputAndLabelContainer}>
              <label htmlFor="">
                Till Date<sup>*</sup>
              </label>
              <input type="date" />
            </div>
            <div className={styles.inputAndLabelContainer}>
              <label htmlFor="">
                Select Partner<sup>*</sup>
              </label>
              <Dropdown />
            </div>
          </div>
          {/* <div className={styles.inputInnerContainerMiddle}>
            <div className={styles.inputAndLabelContainer}>
              <label htmlFor="">
                Select Partner<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.inputAndLabelContainer}>
              <label htmlFor="">
                Select Company<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.inputAndLabelContainer}>
              <label htmlFor="">
                Select loyalty program<sup>*</sup>
              </label>
              <Dropdown />
            </div>
          </div> */}
          <div className={styles.inputInnerContainerMiddle}>
            <div className={styles.paidAndInvoiceContainer}>
              <label htmlFor="">
                Select Partner Branch<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.paidAndInvoiceContainer}>
              <label htmlFor="">
                Select Loyalty Program<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <button className={styles.submitBTN}>Submit</button>
          </div>
          <div className={styles.noteText}>
            <p>* Note:Click on column header for Ascending/Descending</p>
          </div>
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <table className={styles.invoiceTable}>
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Payment Date</th>
                  <th>Invoice No.</th>
                  <th>Partner</th>
                  <th>Amount Paid</th>
                  <th>Payment Type</th>
                  <th>Paid By</th>
                  <th>Remark</th>
                </tr>
              </thead>
              <tbody></tbody>
              <div className={styles.noData}>
                <p>No Data Found</p>
              </div>
            </table>
          </div>
        </div>
      </div>
    </CallCenterStructure>
  );
};

export default LSLPartner;
