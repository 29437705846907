import React, {useState} from 'react';
import SearchInput from '../../component/SearchInput/SearchInput';
import {Times, ViewPen} from '../../assets/icons';
import styles from '../../styles/SegmentTypeMaster.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const SegmentTypeMaster = () => {
  const [clearModal, setClearModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const onClose = () => {
    setDeleteModal(false);
  };

  const tableData = [
    {
      id: 4,
      partnerName: 'Wakanow',

      address: 'Online Store',
      name: 'Eniola Taiwo',
      number: 8023327843,
      mail: 'tadesola@hotmail.com',
    },
    {
      id: 2,
      partnerName: 'Wakanow',

      address: 'Online Store',
      name: 'Eniola Taiwo',
      number: 8023327843,
      mail: 'tadesola@hotmail.com',
    },
    {
      id: 9,
      partnerName: 'Wakanow',

      address: 'Online Store',
      name: 'Eniola Taiwo',
      number: 8023327843,
      mail: 'tadesola@hotmail.com',
    },
    {
      id: 6,
      partnerName: 'Wakanow',

      address: 'Online Store',
      name: 'Eniola Taiwo',
      number: 8023327843,
      mail: 'tadesola@hotmail.com',
    },
  ];
  return (
    <div>
      {' '}
      <CallCenterStructure>
        <div className={styles.PointsForfeitureMaster}>
          <div className={styles.headerText}>
            <p>Segment Type Master</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field5}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Enter Segment Code<sup>*</sup>
                </label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Enter Segment Name<sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field5}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Select Operator<sup>*</sup>
                </label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Enter Upgrade Criteria Level<sup>*</sup>
                </label>
                <Dropdown />
              </div>
            </div>
            <div className={styles.field5}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Enter Value<sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>

            <div className={styles.buttonContainer}>
              <button className={styles.addOne}>
                Add One more Combination
              </button>
            </div>
            <div className={styles.tableSection}>
              <div className={styles.tableContainer}>
                <table className={styles.mainTable}>
                  <thead>
                    <tr>
                      <th>Segment Name</th>
                      <th>Segment Code</th>
                      <th>Segment Operation</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map(each => (
                      <tr key={each.id}>
                        <td>{each.partnerName}</td>
                        <td>{each.mail}</td>
                        <td>{each.number}</td>

                        <td>
                          <div className={styles.iconContainer}>
                            <span className={styles.icon}>
                              <ViewPen />
                            </span>

                            <span
                              className={styles.icon}
                              onClick={() => setDeleteModal(true)}>
                              <Times />
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className={styles.pagination}>
                <p>Pagination</p>
              </div>
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Submit</button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.searchInputContainer}>
              <div className={styles.searchInput}>
                <SearchInput />
              </div>
              <button className={styles.searchBTN}>search</button>
            </div>
            <div className={styles.tableContainer1}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Segment Code</th>
                    <th>Segment Name</th>
                    <th>Segment Operation</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr key={each.id}>
                      <td>{each.partnerName}</td>
                      <td>{each.mail}</td>
                      <td>{each.number}</td>

                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>

                          <span
                            className={styles.icon}
                            onClick={() => setDeleteModal(true)}>
                            <Times />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.pagination}>
              <p>Pagination</p>
            </div>
          </div>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default SegmentTypeMaster;
