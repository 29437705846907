import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import styles from '../../styles/CreateCatalogueMerchandizeCategory.module.scss';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const InwardStockEntry = () => {
  const [clearModal, setClearModal] = useState(false);

  const data = [{code: 'TG23456', item: 'Juice', branch: 'FCMB', stock: 34}];

  return (
    <>
      <CallCenterStructure>
        <div className="mt-5 p-4">
          <h4 className="pb-4">Inward Stock Entry</h4>
          <main className="border border-light p-3">
            <form action="">
              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="selectBranch">Select branch</label>
                  <Dropdown />
                </div>
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="selectName">Select item</label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="select item"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="itemcode">Item code</label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="item code"
                  />
                </div>
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="itemCode">item name</label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="item name"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="itemPrice">Item Price</label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="item price"
                  />
                </div>
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="itemVat">item vat</label>
                  <input className="w-100" type="text" placeholder="item vat" />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="currentBalance">current balance</label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="current balance"
                  />
                </div>
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="thresholdBalance">threshold balance</label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="threshold balance"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="refNo">reference bill no</label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="reference bill no"
                  />
                </div>
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="inwardQty">Inward Qty</label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="inward qty"
                  />
                </div>
              </div>
              <div className="form-group d-flex justify-content-between d-md-flex ">
                <button className="mr-4">Submit</button>
                <button
                  className="resetButton"
                  type="button"
                  onClick={() => setClearModal(true)}>
                  Reset
                </button>
              </div>
            </form>
          </main>

          <div className={styles.inputContainer}>
            <div className={styles.tableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Item Code</th>
                    <th>Item Name</th>
                    <th>Branch name</th>
                    <th>Available Stock</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((each, index) => (
                    <tr key={index}>
                      <td>{each.code}</td>
                      <td>{each.item}</td>
                      <td>{each.branch}</td>
                      <td>{each.stock}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.pagination}>
              <p>Pagination</p>
            </div>
          </div>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
        </div>
      </CallCenterStructure>
    </>
  );
};

export default InwardStockEntry;
