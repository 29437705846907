import React, {useState, useEffect} from 'react';
import {Mark, Times, ViewPen} from '../../assets/icons';
import SearchInput from '../../component/SearchInput/SearchInput';
import styles from '../../styles/TierLevelMaster.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import { useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import { Headers} from '../../service/Essentials';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';

const TierLevelMaster = () => {
  const queryClient = useQueryClient();
//RedirectToLogin();
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const fetchTierLevels = async (page) =>{
    const res = await axiosProperty.get(ApiConfig.fetchTierLevel+`?page=${page}`, {
        headers:Headers()
    }
    );
    return res.data;
  }  
  const {data, status} = useQuery(['tierlevels', page], () => fetchTierLevels(page), {keepPreviousData:true,});

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data) {
      const filtered = data.data.filter(
        (item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };


  const [name, setName] = useState('');
  const [error, setError] = useState('oops... something went wrong')
  const [errors, setErrors] = useState(null);
  const [successModal, setSuccessModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState();

  const handleName = e => {
    setName(e.target.value);
  };
  const addTierLevel = async () => {
    try{
        await axiosProperty.post(ApiConfig.createTierLevel, {name:name}, 
        {
          headers:Headers()
      }
        );
       // setSuccessModal(true);
        setName('');
        setErrors(null);
        swal({
          title: "Success!",
          text: "Tier level created successfully",
          icon: "success",
        });
    }catch(err){
      if(err.response){
      setErrors(err.response.data);
      setError(err.response.data.message);
      }else{
      setError('Something went wrong, probably network'); 
      }
      console.log(err);
      swal({
        title: "Error!",
        text: error,
        icon: "error",
      });
    }
  };

const {mutate:addTierLevelMutation} = useMutation(
  {
    mutationFn: addTierLevel,
    onSuccess:()=>{queryClient.invalidateQueries(['tierlevels'])},
  
  }
  );

  const updateTierLevel = async () => {
    try{
        await axiosProperty.patch(ApiConfig.updateTierLevel+`/${updateID}`, {name:name},
        {
          headers:Headers(),
      }
        );
        //setSuccessModal(true);
        setName('');
        setIsSubmit(true);
        setIsUpdate(false);
        setErrors(null);
        swal({
          title: "Success!",
          text: "Tier level updated successfully",
          icon: "success",
        });
    }catch(err){
      if(err.response){
        setErrors(err.response.data);
        setError(err.response.data.message);
      }else{
        setError('Something went wrong, probably network'); 
      }
      console.log(err);
      swal({
        title: "Error!",
        text: error,
        icon: "error",
      });
    }
  };

  const {mutate:updateTierLevelMutation} = useMutation(
    {
      mutationFn: updateTierLevel,
      onSuccess:()=>{queryClient.invalidateQueries(['tierlevels'])}, 
    }
    );

  const editTierLevel = async (id) => {
       const res = await axiosProperty.get(ApiConfig.editTierLevel+`/${id}`,
        {
          headers:Headers(),
      }
      );
      setIsUpdate(true); 
      setIsSubmit(false); 
      setName(res.data.data.name);
  };
  return (
    <CallCenterStructure>
      <div className={styles.transactionTypeContainer}>
        <div>
          <h6 className={styles.headerText}>Tier Level Master</h6>
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="" className={styles.transactionTypeLabel}>
            Enter Level Name
          </label>

        <div className={styles.formContainer}>
            <input
              type="text"
              value={name}
              onChange={handleName}
              className={styles.transactionTypeInput}
            />
             {isSubmit && (<button
              className={styles.transactionTypeBtn}
              onClick={()=>addTierLevelMutation()}>
              Submit
            </button>)}
            {isUpdate && ( <button
              className={styles.transactionTypeBtn}
              onClick={()=>updateTierLevelMutation()}>
              Update
            </button>)}
          </div>
          <ErrorMessage errMsg={errors} />

          <p className={styles.transactionTypeNoteText}>
            *Note:Click on column header for Ascending/Descending
          </p>
        </div>
        <div className={styles.tableContainer}>
          <div className={styles.formContainer}>
            <div className={styles.searchInput}>
              <SearchInput onSearchInputChange={handleSearchInputChange} />
            </div>
            {/* <button className={styles.transactionTypeBtn}>Search</button> */}
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.dataTable}>
              <thead>
                <tr>
                  <th>Tier Level ID</th>
                  <th>Tier Level Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {
                status === 'error' && (<tr><td className='text-danger'>Error Occured</td></tr>)
              }

              {
                status === 'loading' && (<tr><td className='text-info'>loading</td></tr>)
              }
                {status === 'success' && (filteredData.map(each => (
                  <tr key={each.id}>
                    <td>{each.id}</td>
                    <td>{each.name}</td>
                    <td onClick={()=>{editTierLevel(each.id); setUpdateID(each.id)}}>
                      <ViewPen />
                    </td>
                  </tr>
                )))}

              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={data} setPage={setPage} />
          </div>
        </div>
        {successModal && (
          <div className={styles.successModal}>
            <Mark />
            <p className={styles.successText}>
              Payment Type Inserted Successfully!
            </p>
            <span onClick={() => setSuccessModal(false)}>
              <Times />
            </span>
          </div>
        )}
      </div>
    </CallCenterStructure>
  );
};

export default TierLevelMaster;
