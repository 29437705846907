import React, {useState, useEffect} from 'react';
import {Mark, Times, ViewPen} from '../../assets/icons';
import Dropdown from '../../component/DropDown/DropDown';
import SearchInput from '../../component/SearchInput/SearchInput';
import styles from '../../styles/CodeDecodeMaster.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import { useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
// import { getUser } from '../../service/common';
// import { getToken } from '../../service/common';
import { Headers, ProgramID} from '../../service/Essentials';
import { useNavigate } from "react-router-dom";
import Paginate from '../../component/Paginate/Paginate';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';

const CodeDecodeMaster = () => {
//  const navigate = useNavigate();
const queryClient = useQueryClient();

// const user = getUser();

// if(!user){
//     navigate('/');
//   }
  const program_id = ProgramID() ?? '';

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const fetchCodeDecodeMaster = async (page) =>{
    const res = await axiosProperty.get(ApiConfig.fetchCodeDecodeMaster+`?page=${page}`, {
        headers:Headers()
    }
    );
    return res.data;
  }  
  const {data, status} = useQuery(['codedecodemaster', page], () => fetchCodeDecodeMaster(page), {keepPreviousData:true});

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data) {
      const filtered = data.data.filter(
        (item) =>
          item.type_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.type_desc.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.decode_desc.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  const [type_code, setTypeCode] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [decode_desc, setDecodeDesc] = useState('');
 // const [successModal, setSuccessModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState();
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState(null);


  const handleDecodeDescChange = e => {
    setDecodeDesc(e.target.value);
  };

  const handleDropdownChange = (id) => {
    setTypeCode(id);
  };

  const handleDropdownOption = (name) => {
    setSelectedOption(name);
  };

  const addCodeDecodeMaster = async () => {
    try{
        await axiosProperty.post(ApiConfig.createCodeDecodeMaster, {type_code: type_code, type_desc:selectedOption, decode_desc:decode_desc}, 
        {
          headers:Headers()
      }
        );
        //setSuccessModal(true);
        setErrors(null);
        setTypeCode('');
        setDecodeDesc('');
        setSelectedOption('');
        swal({
          title: "Success!",
          text: "Code Decode Master created successfully",
          icon: "success",
        });
    }catch(err){
      if(err.response){
        setError(err.response.data.message);
        setErrors(err.response.data);
      }else{
        setError('Something went wrong, probably network'); 
      }
      console.log(err);
      swal({
        title: "Error!",
        text: error,
        icon: "error",
      });
    }
  };

const {mutate:addCodeDecodeMasterMutation} = useMutation(
  {
    mutationFn: addCodeDecodeMaster,
    onSuccess:()=>{queryClient.invalidateQueries(['codedecodemaster'])}, 
  
  }
  );

  const updateCodeDecodeMaster = async () => {
    //e.preventDefault();
    try{
        await axiosProperty.patch(ApiConfig.updateCodeDecodeMaster+`/${updateID}`, {type_code: type_code, type_desc:selectedOption, decode_desc:decode_desc},
        {
          headers:Headers()
      }
        );
        //setSuccessModal(true);
        setErrors(null);
        setTypeCode('');
        setDecodeDesc('');
        setSelectedOption('');
        setIsSubmit(true);
        setIsUpdate(false);
        swal({
          title: "Success!",
          text: "Code Decode Master updated successfully",
          icon: "success",
        });
    }catch(err){
      if(err.response){
      setError(err.response.data.message);
      setErrors(err.response.data);
      }else{
     setError('Something went wrong, probably network'); 
      }
      console.log(err);
      swal({
        title: "Error!",
        text: error,
        icon: "error",
      });
    }
  };

  const {mutate:updateCodeDecodeMasterMutation} = useMutation(
    {
      mutationFn: updateCodeDecodeMaster,
      onSuccess:()=>{queryClient.invalidateQueries(['codedecodemaster'])}, 
    
    }
    );

  const editCodeDecodeMaster = async (id) => {
       const res = await axiosProperty.get(ApiConfig.editCodeDecodeMaster+`/${id}`,
        {
          headers:Headers()
      }
      );
      setIsUpdate(true); 
      setIsSubmit(false); 
      setTypeCode(res.data.data.type_code);
      setDecodeDesc(res.data.data.decode_desc);
      setSelectedOption(res.data.data.type_desc);
  };

  return (
    <CallCenterStructure>
      <div className={styles.transactionTypeContainer}>
        <div>
          <h6 className={styles.headerText}>Code-Decode Master</h6>
        </div>

        <div className={styles.inputContainer}>


        <div className={styles.formContainer}>
            <div className={styles.typeCodeInputContainer}>
              <label htmlFor="">
                Select Type Code<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchCodeDecodeTypeMaster} onSelect={handleDropdownChange} onSelectOption={handleDropdownOption} retrievedValue={selectedOption} column={'name'} key={'type_code'}/>
            </div>
            <div className={styles.typeCodeInputContainer}>
              <label htmlFor="" className={styles.transactionTypeLabel}>
              Decode Description<sup>*</sup>
              </label>
              <input
                type="text"
                value={decode_desc}
                onChange={handleDecodeDescChange}
                className={styles.transactionTypeInput}
              />
               <input
                type="hidden"
                value={type_code}
                className={styles.transactionTypeInput}
              />
            </div>
            {isSubmit && (<button
              className={styles.transactionTypeBtn}
              onClick={()=>addCodeDecodeMasterMutation()}>
              Submit
            </button>)}
            {isUpdate && (<button
              className={styles.transactionTypeBtn}
              onClick={()=>updateCodeDecodeMasterMutation()}>
              Update
            </button>)}
          </div>

          <p className={styles.transactionTypeNoteText}>
            *Note:Click on column header for Ascending/Descending
          </p>
        </div>
        <div className={styles.tableContainer}>
          <ErrorMessage errMsg={errors} />
          <div className={styles.formContainer}>
            <div className={styles.searchInput}>
              <SearchInput onSearchInputChange={handleSearchInputChange} />
            </div>
            {/* <button className={styles.transactionTypeBtn}>Search</button> */}
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.dataTable}>
              <thead>
                <tr>
                  <th>Type Code</th>
                  <th>Type Description</th>
                  <th>Decode Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {
                status === 'error' && (<tr><td className='text-danger'>Error Occured</td></tr>)
              }
              {
                status === 'loading' && (<tr><td className='text-info'>loading</td></tr>)
              }
                
                {status === 'success' && ( filteredData.map(each => (
                  <tr key={each.id}>
                    <td>{each.type_code}</td>
                    <td>{each.type_desc}</td>
                    <td>{each.decode_desc}</td>
                    <td onClick={()=>{editCodeDecodeMaster(each.id); setUpdateID(each.id)}}>
                      <ViewPen />
                    </td>
                  </tr>
                ))) }
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={data} setPage={setPage} />
          </div>
        </div>
        {/* {successModal && (
          <div className={styles.successModal}>
            <Mark />
            <p className={styles.successText}>
              Code Decode Type Inserted Successfully!
            </p>
            <span onClick={() => setSuccessModal(false)}>
              <Times />
            </span>
          </div>
        )} */}
      </div>
    </CallCenterStructure>
  );
};

export default CodeDecodeMaster;
