import React from 'react';

const paginateStyles = {
  flex: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    width: '100%',
  },
  button: {
    background: 'none',
    color: 'black',
    border: '2px solid #CED4DF',
    width: '147px',
  },
  page: {color: '#fff', background: '#28245F', width: '30px'},
};

const Paginate = props => {
  return (
    <>
    <div style={paginateStyles.flex}>
      <button
        style={paginateStyles.button}
        onClick={() => props.setPage(prevState => Math.max(prevState - 1, 1))}
        disabled={props.page === 1}>
        Previous
      </button>
      <button style={paginateStyles.page}>{props.page}</button>
      <button
        style={paginateStyles.button}
        onClick={() =>
          props.setPage(prevState =>
            props.data.data.next_page_url == null ? prevState : prevState + 1,
          )
        }>
        Next
      </button>
    </div>
    </>
  );
};

export default Paginate;
