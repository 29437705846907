import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import DownloadButtonPdf from '../../component/DownloadButtonPdf/DownloadButtonPdf';
import DownloadButtonXlsx from '../../component/DownloadButtonXlsx/DownloadButtonXlsx';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const SurveyReport = () => {
  const [clearModal, setClearModal] = useState(false);
  return (
    <>
      <CallCenterStructure>
        <div className="mt-5 p-4">
          <h4 className="pb-4">Survey Report</h4>
          <main className="border border-light p-3">
            <form action="">
              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="transactionFrom">Transaction From *</label>
                  <Dropdown />
                </div>
              </div>

              <div className="form-group d-flex justify-content-between d-md-flex ">
                <button className="mr-5">Submit</button>
                <button
                  className="resetButton"
                  type="button"
                  onClick={() => setClearModal(true)}>
                  Reset
                </button>
              </div>
            </form>
          </main>
          <div className="mt-4 d-flex justify-content-between d-md-flex justify-content-md-start">
            <DownloadButtonPdf />
            <DownloadButtonXlsx />
          </div>

          <section className="border border-light mt-4 mb-4">
            <div className="row p-4">
              <div className="col-12 table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="">User Name</th>
                      <th className="">User Email</th>
                      <th className="">User Type</th>
                      <th className="">Start Time</th>
                      <th className="">End Time</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
              <div className="col-12">Pagination</div>
            </div>
          </section>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
        </div>
      </CallCenterStructure>
    </>
  );
};
export default SurveyReport;
