import React, {useState} from 'react';
import Category from '../../component/Category/Category';
import TemplateFooter from '../../component/TemplateFooter/TemplateFooter';
import TemplateNavbar from '../../component/TemplateNavbar/TemplateNavbar';
import styles from '../../styles/TemplateCart.module.scss';
import product1 from '../../assets/images/product1.png';
import product2 from '../../assets/images/product2.png';
import product3 from '../../assets/images/product3.png';
import product4 from '../../assets/images/product4.png';
import {TemplateTimes} from '../../assets/icons';

const TemplateCart = () => {
  const cartItems = {
    pickup: [
      {
        img: product1,
        name: 'Giusepi Luigi Eau de Spirit',
        point: '1,200',
        id: 1,
      },
      {
        img: product4,
        name: 'Giusepi Luigi Eau de Spirit',
        point: '28,000',
        id: 2,
      },
      {
        img: product1,
        name: 'Nutella Hazelnut Spread with Cocoa - 30ghfgh hsjffehj',
        point: '30,000',
        id: 3,
      },
    ],
    delivery: [
      {
        img: product1,
        name: 'Giusepi Luigi Eau de Spirit',
        point: '1,200',
        id: 4,
      },
      {
        img: product4,
        name: 'Giusepi Luigi Eau de Spirit',
        point: '28,000',
        id: 5,
      },
      {
        img: product2,
        name: 'Nutella Hazelnut Spread with Cocoa - 30g',
        point: '30,000',
        id: 6,
      },
    ],
  };

  const [items, setItems] = useState(cartItems);

  const deletePickupItem = itemId => {
    const filteredItems = items.pickup.filter(each => each.id !== itemId);
    setItems({
      ...items,
      pickup: filteredItems,
    });
  };

  const deleteDeliveryItem = itemId => {
    const filteredItems = items.delivery.filter(each => each.id !== itemId);
    setItems({
      ...items,
      delivery: filteredItems,
    });
  };

  return (
    <div className={styles.templateCart}>
      <TemplateNavbar />
      <Category />
      <div className={styles.cartTemplateBody}>
        <div className={styles.leftCartBody}>
          <div className={styles.cartText}>
            <p>Cart</p>
          </div>
          <div className={styles.itemContainer}>
            <div className={styles.innerItemContainer}>
              {items?.pickup?.length === 0 && items?.delivery?.length === 0 && (
                <div className={styles.emptyCart}>
                  {' '}
                  <p>Empty Cart</p>{' '}
                </div>
              )}

              <div className={styles.pickupContainer}>
                {items?.pickup?.length >= 1 && (
                  <div className={styles.pickupText}>
                    <p>Pickup Items</p>
                  </div>
                )}
                <div className={styles.cardCartOutter}>
                  {items?.pickup?.map(each => (
                    <div className={styles.cartCart}>
                      <div className={styles.cardLeft}>
                        <div className={styles.cardImg}>
                          <img src={each.img} alt={each.name} />
                        </div>
                        <div className={styles.cardDetails}>
                          <p className={styles.itemName}>{each.name}</p>
                          <p className={styles.itemPoint}>
                            {each.point} Points
                          </p>
                          <div className={styles.qty}>
                            <p>Qty: </p>
                            <input
                              type="number"
                              className={styles.numberInput}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.cardRight}>
                        <span onClick={() => deletePickupItem(each.id)}>
                          <TemplateTimes />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.pickupContainer}>
                {items?.delivery?.length >= 1 && (
                  <div className={styles.pickupText}>
                    <p>Delivery Items</p>
                  </div>
                )}

                <div className={styles.cardCartOutter}>
                  {items?.delivery?.map(each => (
                    <div className={styles.cartCart}>
                      <div className={styles.cardLeft}>
                        <div className={styles.cardImg}>
                          <img src={each.img} alt={each.name} />
                        </div>
                        <div className={styles.cardDetails}>
                          <p className={styles.itemName}>{each.name}</p>
                          <p className={styles.itemPoint}>
                            {each.point} Points
                          </p>
                          <div className={styles.qty}>
                            <p>Qty: </p>
                            <input
                              type="number"
                              className={styles.numberInput}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.cardRight}>
                        <span onClick={() => deleteDeliveryItem(each.id)}>
                          <TemplateTimes />
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightCartBody}>
          <div className={styles.innerRightContainer}>
            <div className={styles.rightTop}>
              <p>Total</p>
            </div>
            <div className={styles.rightBottom}>
              <div className={styles.subTotal}>
                <p>Sub-total</p>
                <p>2890 Points</p>
              </div>
              <div className={styles.subTotalContent}>
                <p>
                  This this price is exclusive of shipping fee. This will be
                  calculated upon checkout
                </p>
              </div>
              <div className={styles.checkoutBTNContainer}>
                <button className={styles.checkoutBTN}>
                  Confirm & Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TemplateFooter />
    </div>
  );
};

export default TemplateCart;
