import React, {useState} from 'react';
import styles from '../../styles/SetupCustomerWebsiteHomePageLayout.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles1 from '../../styles/RadioButtonBlue.module.scss';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
import Dropdown from '../../component/DropDown/DropDown';
import CheckBox from '../../component/CheckBox/CheckBox';
import {Times, ViewPen} from '../../assets/icons';
const SetupCustomerWebsiteHomePageLayout = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [selectedImageName1, setSelectedImageName1] = useState('');
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [selectedImageName2, setSelectedImageName2] = useState('');
  const [selectedImage3, setSelectedImage3] = useState(null);
  const [selectedImageName3, setSelectedImageName3] = useState('');
  const [primaryColor, setPrimaryColor] = useState('#628768');
  const [secondaryColor, setSecondaryColor] = useState('#628766');
  const [footerColor, setFooterColor] = useState('#628762');

  // Upload Images State Up There

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  const [selectedOption4, setSelectedOption4] = useState(null);
  const [selectedOption5, setSelectedOption5] = useState(null);
  const [selectedOption6, setSelectedOption6] = useState(null);
  const options = [{color: '#000000'}, {color: '#fff'}];
  const options1 = [{color: '#628698'}, {color: '#8D8298'}, {color: '#AAB628'}];
  const options2 = [{color: '#628898'}, {color: '#8A8298'}, {color: '#AAC628'}];
  const options3 = [{color: '#628798'}];
  const options4 = [{color: '#628768'}];
  const options6 = [
    {color: '#628768'},
    {color: '#628898'},
    {color: '#8A8298'},
    {color: '#628AAA'},
  ];
  const handleOptionChange = option => {
    setSelectedOption(option);
  };

  const handleOptionChange1 = option => {
    setSelectedOption1(option);
  };

  const handleOptionChange2 = option => {
    setSelectedOption2(option);
  };
  const handleOptionChange3 = option => {
    setSelectedOption3(option);
  };
  const handleOptionChange4 = option => {
    setSelectedOption4(option);
  };
  const handleOptionChange5 = option => {
    setSelectedOption5(option);
  };
  const handleOptionChange6 = option => {
    setSelectedOption6(option);
  };

  const tableData = [
    {
      id: 4,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Active',
      groupName1: 'Active',
      groupName2: 'Active',
      groupName3: 'Inactive',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 3,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Inactive',
      groupName1: 'Active',
      groupName2: 'Active',
      groupName3: 'Inactive',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 9,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Active',
      groupName1: 'Active',
      groupName2: 'Active',
      groupName3: 'Inactive',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 6,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Active',
      groupName1: 'Active',
      groupName2: 'Active',
      groupName3: 'Inactive',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    ,
  ];

  console.log(primaryColor, 'primary color');

  return (
    <div>
      <CallCenterStructure>
        <div className={styles.SetupCustomerWebsiteHomePageLayout}>
          <div className={styles.headerText}>
            <p>Setup Customer Website Home Page Layout</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field1}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Enter Customer Website Home Page Template Name<sup>*</sup>
                </label>
                <input type="text" name="" id="" />
              </div>
            </div>
            <div className={styles.fieldColor1}>
              <div className={styles.fieldColorContainer1}>
                <label htmlFor="">
                  Choose Primary Color<sup>*</sup>
                </label>
                <div className={styles.colorHousing}>
                  <input
                    type="color"
                    name="primary"
                    id=""
                    value={primaryColor}
                    onChange={e => setPrimaryColor(e.target.value)}
                  />
                  <p>{primaryColor}</p>
                </div>
              </div>
              <div className={styles.fieldColorContainer1}>
                <label htmlFor="">
                  Choose Secondary Color<sup>*</sup>
                </label>
                <div className={styles.colorHousing}>
                  <input
                    type="color"
                    name="secondary"
                    id=""
                    value={secondaryColor}
                    onChange={e => setSecondaryColor(e.target.value)}
                  />
                  <p>{secondaryColor}</p>
                </div>
              </div>
              <div className={styles.fieldColorContainer1}>
                <label htmlFor="">
                  Choose Footer Color<sup>*</sup>
                </label>
                <div className={styles.colorHousing}>
                  <input
                    type="color"
                    name="footer"
                    id=""
                    value={footerColor}
                    onChange={e => setFooterColor(e.target.value)}
                  />
                  <p>{footerColor}</p>
                </div>
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">More Color:</label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Choose Header Color<sup>*</sup>
                </label>
                <div className={styles.radioButtonsContainer3}>
                  {options.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer3}
                      key={index}
                      onClick={() => handleOptionChange(option.color)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.color === selectedOption
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <div
                          style={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: `${option.color}`,
                            borderColor: '#c1c1c1',
                            borderStyle: 'solid',
                            borderWidth: '1px',
                          }}></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Upload Image</label>
                <ImageDragAndDrop
                  selectedImage1={selectedImage}
                  selectedImageName1={selectedImageName}
                  setSelectedImage1={setSelectedImage}
                  setSelectedImageName1={setSelectedImageName}
                />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Facebook link</label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Twitter link</label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Youtube link</label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Linkedin link</label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Instagram link</label>
                <input type="text" />
              </div>
            </div>
            {/* <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Choose Header Image</label>
                <div className={styles.radioButtonsContainer3}>
                  {options2.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer3}
                      key={index}
                      onClick={() => handleOptionChange3(option.color)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.color === selectedOption3
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <div
                          style={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: `${option.color}`,
                          }}></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Upload Image</label>
                <ImageDragAndDrop
                  selectedImage1={selectedImage1}
                  selectedImageName1={selectedImageName1}
                  setSelectedImage1={setSelectedImage1}
                  setSelectedImageName1={setSelectedImageName1}
                />
              </div>
            </div> */}
            {/* <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Choose Menu & Redeemable Slider Header Background Color
                  <sup>*</sup>
                </label>
                <div className={styles.radioButtonsContainer3}>
                  {options3.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer3}
                      key={index}
                      onClick={() => handleOptionChange4(option.color)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.color === selectedOption4
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <div
                          style={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: `${option.color}`,
                          }}></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">More Color:</label>
                <input type="text" />
              </div>
            </div> */}
            {/* <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Choose Menu & Redeemable Slider Header Font Color
                  <sup>*</sup>
                </label>
                <div className={styles.radioButtonsContainer3}>
                  {options4.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer3}
                      key={index}
                      onClick={() => handleOptionChange5(option.color)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.color === selectedOption5
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <div
                          style={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: `${option.color}`,
                          }}></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">More Color:</label>
                <input type="text" />
              </div>
            </div> */}
            {/* <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Choose Color for Footer Panel
                  <sup>*</sup>
                </label>
                <div className={styles.radioButtonsContainer3}>
                  {options6.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer3}
                      key={index}
                      onClick={() => handleOptionChange6(option.color)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.color === selectedOption6
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <div
                          style={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: `${option.color}`,
                          }}></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">More Color:</label>
                <input type="text" />
              </div>
            </div> */}
            {/* <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Select Font size to Apply</label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Select Font Family to Apply</label>
                <Dropdown />
              </div>
            </div> */}
          </div>

          {/* <div className={styles.customerMenus}>
            <div className={styles.customerMenusHeader}>
              <p>Customer Menus</p>
            </div>
            <div className={styles.customerMenusInnerContainer}>
              <div className={styles.customerMenusMainContainer}>
                <div className={styles.menu}>
                  <div className={styles.menuLeft}>
                    <CheckBox />
                    <p>My Profile</p>
                  </div>
                  <div className={styles.menuRight}>
                    <CheckBox />
                    <p>Benefit Partners</p>
                  </div>
                </div>
                <div className={styles.menu}>
                  <div className={styles.menuLeft}>
                    <CheckBox />
                    <p>Rewards Catalogue</p>
                  </div>
                  <div className={styles.menuRight}>
                    <CheckBox />
                    <p>Notification</p>
                  </div>
                </div>
                <div className={styles.menu}>
                  <div className={styles.menuLeft}>
                    <CheckBox />
                    <p>Transfer Points</p>
                  </div>
                  <div className={styles.menuRight}>
                    <CheckBox />
                    <p>Bid For Auction</p>
                  </div>
                </div>
                <div className={styles.menu}>
                  <div className={styles.menuLeft}>
                    <CheckBox />
                    <p>Promo Code</p>
                  </div>
                  <div className={styles.menuRight}>
                    <CheckBox />
                    <p>Take Survey</p>
                  </div>
                </div>
                <div className={styles.menu}>
                  <div className={styles.menuLeft}>
                    <CheckBox />
                    <p>My Statement</p>
                  </div>
                  <div className={styles.menuRight}>
                    <CheckBox />
                    <p>Contact Us</p>
                  </div>
                </div>
                <div className={styles.menu}>
                  <div className={styles.menuLeft}>
                    <CheckBox />
                    <p>Play Game</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Submit</button>
            <button className={styles.reset}>Preview</button>
          </div>
          <div className={styles.tableSection}>
            <div className={styles.innerTableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Customer Website Homepage Template Name</th>
                    <th>Background Color</th>
                    <th>Background Image</th>
                    <th>Header Image</th>
                    <th>Font Size</th>
                    <th>Font Family</th>
                    <th>Preview</th>
                    <th>Click to Deactivate</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr key={each.id}>
                      <td>{each.name}</td>
                      <td>{each.code}</td>
                      <td>{each.itemName}</td>
                      <td>
                        {each.groupName === 'Active' ? (
                          <p className={styles.active}>{each.groupName}</p>
                        ) : (
                          <p className={styles.inactive}>{each.groupName}</p>
                        )}
                      </td>
                      <td>
                        {each.groupName1 === 'Active' ? (
                          <p className={styles.active}>{each.groupName1}</p>
                        ) : (
                          <p className={styles.inactive}>{each.groupName1}</p>
                        )}
                      </td>
                      <td>
                        {each.groupName2 === 'Active' ? (
                          <p className={styles.active}>{each.groupName2}</p>
                        ) : (
                          <p className={styles.inactive}>{each.groupName2}</p>
                        )}
                      </td>
                      <td>
                        {each.groupName3 === 'Active' ? (
                          <p className={styles.active}>{each.groupName3}</p>
                        ) : (
                          <p className={styles.inactive}>{each.groupName3}</p>
                        )}
                      </td>
                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <Times />
                          </span>
                        </div>
                      </td>

                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default SetupCustomerWebsiteHomePageLayout;
