import React, {useEffect, useRef, useState} from 'react';
import styles from '../../styles/LSLUserDashboard.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  // CartesianGrid,
  Tooltip,
  // Legend,
} from 'recharts';
import Chart from 'react-google-charts';
import Dropdown from '../../component/DropDown/DropDown';
import {Link} from 'react-router-dom';
const LSLUserDashboard = () => {
  const [barChartStyling, setBarChartStyling] = useState(1000);

  useEffect(() => {
    const handleResize = () => {
      const value = window.innerWidth;
      if (value > 888) {
        setBarChartStyling(value - 300);
      } else {
        setBarChartStyling(value - 50);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const data1 = [
    {
      month: 'Jan',

      Amount: 2400,
    },
    {
      month: 'Feb',

      Amount: 1398,
    },
    {
      month: 'Mar',

      Amount: 9800,
    },
    {
      month: 'Apr',

      Amount: 3908,
    },
    {
      month: 'May',

      Amount: 4800,
    },
    {
      month: 'Jun',

      Amount: 3800,
    },
    {
      month: 'Jul',

      Amount: 4300,
    },
    {
      month: 'Aug',

      Amount: 4300,
    },
    {
      month: 'Sep',

      Amount: 4300,
    },
    {
      month: 'Oct',

      Amount: 4300,
    },
    {
      month: 'Nov',

      Amount: 4300,
    },
    {
      month: 'Dec',

      Amount: 4300,
    },
  ];

  const dataPie1 = [
    ['Task', 'Hours per Day'],
    ['Unresolved', 11],
    ['Resolved', 7],
  ];

  const optionsPie1 = {
    title: 'Queries',
    pieHole: 0.4,
    is3D: false,
    colors: ['#000000', '#0D99FF'],
  };

  const dataPie2 = [
    ['Task', 'Hours per Day'],
    ['Unresolved', 11],
    ['Resolved', 7],
  ];

  const optionsPie2 = {
    title: 'Partners',
    pieHole: 0.4,
    is3D: false,
    colors: ['#000000', '#0D99FF'],
  };

  // PREVENT SCROLLING WHEN NOT ON FOCUSED

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;

    const handleMouseEnter = () => {
      if (scrollContainer) {
        scrollContainer.style.overflow = 'auto';
      }
    };

    const handleMouseLeave = () => {
      if (scrollContainer) {
        scrollContainer.style.overflow = 'hidden';
      }
    };

    if (scrollContainer) {
      scrollContainer.addEventListener('mouseenter', handleMouseEnter);
      scrollContainer.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('mouseenter', handleMouseEnter);
        scrollContainer.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);

  ///////// PREVENT SCROLLING WHEN NOT ON FOCUSED ENDS////////////////

  return (
    <div>
      <CallCenterStructure>
        <div className={styles.LSLUserDashboard} ref={scrollContainerRef}>
          <h1 className={styles.headerText}>
            LSL User Dashboard (non-super admin)
          </h1>
          <div className={styles.inputContainer}>
            <div className={styles.chartHeader}>
              <p className={styles.chartTitle}>Total Points Accumulated</p>
              <div className={styles.dropdownContainer}>
                <Dropdown />
              </div>
            </div>
            <BarChart
              width={barChartStyling}
              height={250}
              margin={{top: 5, right: 20, left: 20, bottom: 5}}
              data={data1}>
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />

              {/* <Legend /> */}
              <Bar dataKey="Amount" fill="#4FC143" />
            </BarChart>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.chartHeader}>
              <p className={styles.chartTitle}>Total Users Enrolled</p>
              <div className={styles.dropdownContainer}>
                <Dropdown />
              </div>
            </div>
            <BarChart
              width={barChartStyling}
              height={250}
              margin={{top: 5, right: 20, left: 20, bottom: 5}}
              data={data1}>
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />

              {/* <Legend /> */}
              <Bar dataKey="Amount" fill="#28245F" />
            </BarChart>
          </div>
          <div className={styles.pieChartContainer}>
            <div className={styles.pieChart}>
              <div className={styles.pieChartHeader}>
                <div className={styles.dropdownOutterContainer}>
                  <div className={styles.dropdownContainer}>
                    <Dropdown />
                  </div>
                  <div className={styles.dropdownContainer}>
                    <Dropdown />
                  </div>
                </div>
                <Link className={styles.queryLink}>View Queries</Link>
              </div>
              <Chart
                chartType="PieChart"
                width="100%"
                height="300px"
                data={dataPie1}
                options={optionsPie1}
              />
            </div>
            <div className={styles.pieChart}>
              <div className={styles.pieChartHeader}>
                <div className={styles.dropdownOutterContainer}>
                  <div className={styles.dropdownContainer}>
                    <Dropdown />
                  </div>
                  <div className={styles.dropdownContainer}>
                    <Dropdown />
                  </div>
                </div>
                <Link className={styles.queryLink}>View Queries</Link>
              </div>
              <Chart
                chartType="PieChart"
                width="100%"
                height="300px"
                data={dataPie2}
                options={optionsPie2}
              />
            </div>
          </div>
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default LSLUserDashboard;
