import React, {useState} from 'react';
import styles from '../../styles/ColorPicker.module.scss';

const ColorPicker = ({graphColor, setGraphColor}) => {
  const handleChange = e => {
    const value = e.target.value;
    setGraphColor(value);
  };

  console.log(graphColor, 'graphColor');
  return (
    <div>
      <input
        type="color"
        name="graphColor"
        id=""
        value={graphColor}
        onChange={handleChange}
        className={styles.colorPicker}
      />
    </div>
  );
};

export default ColorPicker;
