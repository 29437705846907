import React, {useState} from 'react';
import {useMutation} from 'react-query';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import {getUser} from '../../service/common';
import swal from 'sweetalert';
import {TransactionHeaders} from '../../service/Essentials';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const WalletCreditTransaction = () => {
  const [clearModal, setClearModal] = useState(false);
  const user = getUser();

  //const program_id = user.user_group_id == 1 ? 2 : user.user_group_id;
  const program_id = user.user_group_id;

  const dataString = sessionStorage.getItem('profile');
  if (dataString) {
    var profile = JSON.parse(dataString);
  } else {
    profile = {};
  }
  const membership_id = profile?.Membership_id;
  const enrolment_id = profile?.Enrollment_id;
  const pin = profile?.pin;

  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState({credit_point: '', remark: ''});
  const [formData, setFormData] = useState({credit_point: '', remark: ''});

  console.log(formData);

  const handleChange = e => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const addManualCredit = async () => {
    try {
      await axiosProperty.post(ApiConfig.createManualCredit(1), formData, {
        headers: TransactionHeaders(),
      });
      setFormData({credit_point: '', remark: ''});
      setErrors({});
      swal({
        title: 'Success!',
        text: 'Manual Credit was successful',
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: addManualCreditMutation} = useMutation({
    mutationFn: addManualCredit,
  });

  return (
    <>
      <CallCenterStructure>
        <div className="mt-5 p-4">
          <h4 className="pb-4">Wallet Credit Transaction</h4>
          <main className="border border-light p-3">
            <form action="">
              <div className="row">
                <div className="form-group col-12 col-md-12">
                  <label htmlFor="membershipID">Membership ID *</label>
                  <input className="w-100" type="text" value={membership_id} />
                </div>
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="pointsCredited">Wallet Credit Amount *</label>
                  <input
                    name="credit_point"
                    value={formData.credit_point}
                    onChange={handleChange}
                    className="w-100"
                    type="text"
                    placeholder="Credit Amount"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="pin">Pin *</label>
                  <input
                    value={pin}
                    className="w-100"
                    type="text"
                    placeholder="pin"
                  />
                </div>
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="remarks">Payment By</label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="Payer name"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="remarks">Remarks</label>
                  <input
                    name="remark"
                    value={formData.remark}
                    onChange={handleChange}
                    className="w-100"
                    type="text"
                    placeholder="comment here"
                  />
                </div>
                <div className="form-group col-12 col-md-6"></div>
              </div>

              <div className="form-group mt-3 d-flex justify-content-between d-md-flex">
                <button
                  className="mr-4"
                  onClick={e => {
                    e.preventDefault();
                    addManualCreditMutation();
                  }}>
                  Submit
                </button>
                <button
                  className="resetButton"
                  type="button"
                  onClick={() => setClearModal(true)}>
                  Reset
                </button>
              </div>
            </form>
          </main>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
        </div>
      </CallCenterStructure>
    </>
  );
};

export default WalletCreditTransaction;
