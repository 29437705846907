import React from 'react';
import styles from '../../styles/TemplateFooter.module.scss';
import logo from '../../assets/images/PerxLogo.png';
import {
  TemplateFB,
  TemplateInstagram,
  TemplateLinkedIn,
  TemplateTwitter,
  TemplateYouTube,
} from '../../assets/icons';

const TemplateFooter = () => {
  return (
    <div className={styles.TemplateFooter}>
      <div className={styles.innerTemplateFooter}>
        <div className={styles.footerTop}>
          <div className={styles.navLogo}>
            <img src={logo} alt="" />
          </div>
          <div className={styles.mediaIconContainer}>
            <TemplateFB />
            <TemplateInstagram />
            <TemplateLinkedIn />
            <TemplateTwitter />
            <TemplateYouTube />
          </div>
        </div>
        <div className={styles.footerBottom}>
          <p>2023 Loyalty Solutions Limited.</p>
        </div>
      </div>
    </div>
  );
};

export default TemplateFooter;
