import React, {useEffect, useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  // CartesianGrid,
  Tooltip,
  // Legend,
} from 'recharts';
import Chart from 'react-google-charts';
import Dropdown from '../../component/DropDown/DropDown';
import {Link} from 'react-router-dom';
import styles from '../../styles/ClientDashboard.module.scss';

const ClientDashboard = () => {
  const [barChartStyling, setBarChartStyling] = useState(1000);

  useEffect(() => {
    const handleResize = () => {
      const value = window.innerWidth;
      if (value > 888) {
        setBarChartStyling(value - 300);
      } else {
        setBarChartStyling(value - 50);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const data1 = [
    {
      month: 'Jan',
      Amount: 2400,
    },
    {
      month: 'Feb',
      Amount: 1398,
    },
    {
      month: 'Mar',
      Amount: 9800,
    },
    {
      month: 'Apr',
      Amount: 3908,
    },
    {
      month: 'May',
      Amount: 4800,
    },
    {
      month: 'Jun',
      Amount: 3800,
    },
    {
      month: 'Jul',
      Amount: 4300,
    },
    {
      month: 'Aug',
      Amount: 4300,
    },
    {
      month: 'Sep',
      Amount: 4300,
    },
    {
      month: 'Oct',
      Amount: 4300,
    },
    {
      month: 'Nov',
      Amount: 4300,
    },
    {
      month: 'Dec',
      Amount: 4300,
    },
  ];

  const dataPie1 = [
    ['Task', 'Hours per Day'],
    ['ATM', 5000],
    ['Mobile', 4000],
    ['POS', 1000],
    ['USSD', 5000],
    ['Web', 5000],
  ];

  const optionsPie1 = {
    // title: 'Queries',
    pieHole: 0.4,
    is3D: false,
    colors: ['#0E237D', '#00BCE1', '#81C041', '#27824D', '#989898'],
  };

  const dataPie2 = [
    ['Task', 'Hours per Day'],
    ['Active', 5000],
    ['In-Active', 4000],
  ];

  const optionsPie2 = {
    // title: 'Partners',
    pieHole: 0.4,
    is3D: false,
    colors: ['#00BCE1', '#0E237D'],
  };

  const dataPie3 = [
    ['Task', 'Hours per Day'],
    ['Expiry', 5000],
    ['Forfeited', 4000],
  ];

  const optionsPie3 = {
    pieHole: 0.4,
    is3D: false,
    colors: ['#747272', '#0D99FF'],
  };

  const dataPie4 = [
    ['Task', 'Hours per Day'],
    ['Merchandize', 5000],
    ['Airtime & Bills', 4000],
    ['Experinces', 1000],
    ['Meals', 5000],
    ['Cinema', 5000],
  ];

  const optionsPie4 = {
    pieHole: 0.4,
    is3D: false,
    colors: ['#FFCD29', '#7B61FF', '#E1E7FF', '#0D99FF', '#FF6B6A'],
  };

  const dataPie5 = [
    ['Task', 'Hours per Day'],
    ['Completed', 4000],
    ['Cancelled', 4000],
  ];

  const optionsPie5 = {
    pieHole: 0.4,
    is3D: false,
    colors: ['#4FC143', '#FF005F'],
  };

  const dataPie6 = [
    ['Task', 'Hours per Day'],
    ['Logged In Customers', 5000],
    ['Customers not logged In', 4000],
    ['Customers who redeemed', 1000],
    ['Customers yet to redeem', 5000],
  ];

  const optionsPie6 = {
    pieHole: 0.4,
    is3D: false,
    colors: ['#0D99FF', '#4FC143', '#27824D', '#989898'],
  };

  return (
    <CallCenterStructure>
      <div className={styles.ClientDashboard}>
        <h1 className={styles.headerText}>Client Dashboard</h1>
        <div className={styles.inputContainer}>
          <div className={styles.chartHeader}>
            <p className={styles.chartTitle}>Transactions</p>
            <div className={styles.dropdownContainer}>
              <Dropdown />
            </div>
          </div>
          <BarChart
            width={barChartStyling}
            height={250}
            margin={{top: 5, right: 20, left: 20, bottom: 5}}
            data={data1}>
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />

            {/* <Legend /> */}
            <Bar dataKey="Amount" fill="#4FC143" />
          </BarChart>
        </div>
        <div className={styles.pieChartContainer}>
          <div className={styles.pieChart}>
            <div className={styles.pieChartHeader}>
              <h2 className={styles.headerH2}>Channel Usage Summary</h2>
              <p className={styles.headerP}>
                This is a summary of all the channel usage
              </p>
              <div className={styles.dropdownOutterContainer}>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
              </div>
            </div>
            <Chart
              chartType="PieChart"
              width="100%"
              height="300px"
              data={dataPie1}
              options={optionsPie1}
            />
          </div>
          <div className={styles.pieChart}>
            <div className={styles.pieChartHeader}>
              <h2 className={styles.headerH2}>Customer Activity Summary</h2>
              <p className={styles.headerP}>
                This is a summary of all customer transaction activity
              </p>
              <div className={styles.dropdownOutterContainer}>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
              </div>
            </div>
            <Chart
              chartType="PieChart"
              width="100%"
              height="300px"
              data={dataPie2}
              options={optionsPie2}
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.chartHeader}>
            <div>
              <p className={styles.chartTitle}>Enrollment Report</p>
              <p className={styles.chartSubTitle}>
                This a graph that shows all the enrollment done
              </p>
            </div>
            <div className={styles.dropdownContainer}>
              <Dropdown />
            </div>
          </div>
          <BarChart
            width={barChartStyling}
            height={250}
            margin={{top: 5, right: 20, left: 20, bottom: 5}}
            data={data1}>
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />

            {/* <Legend /> */}
            <Bar dataKey="Amount" fill="#28245F" />
          </BarChart>
        </div>
        <div className={styles.pieChartContainer}>
          <div className={styles.pieChart}>
            <div className={styles.pieChartHeader}>
              <h2 className={styles.headerH2}>
                Expired & Forfeited Points Chart
              </h2>
              <p className={styles.headerP}>
                View Expired & Forfeited Points Chart here
              </p>
              <div className={styles.dropdownOutterContainer}>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
              </div>
            </div>
            <Chart
              chartType="PieChart"
              width="100%"
              height="300px"
              data={dataPie3}
              options={optionsPie3}
            />
          </div>
          <div className={styles.pieChart}>
            <div className={styles.pieChartHeader}>
              <h2 className={styles.headerH2}>Redemption Chart</h2>
              <p className={styles.headerP}>View all redemption chart here</p>
              <div className={styles.dropdownOutterContainer}>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
              </div>
            </div>
            <Chart
              chartType="PieChart"
              width="100%"
              height="300px"
              data={dataPie4}
              options={optionsPie4}
            />
          </div>
        </div>
        <div className={styles.pieChartContainer}>
          <div className={styles.pieChart}>
            <div className={styles.pieChartHeader}>
              <h2 className={styles.headerH2}>Customer Delivery Chart</h2>
              <p className={styles.headerP}>
                This is a summary of all the delivery status
              </p>
              <div className={styles.dropdownOutterContainer}>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
              </div>
            </div>
            <Chart
              chartType="PieChart"
              width="100%"
              height="300px"
              data={dataPie5}
              options={optionsPie5}
            />
          </div>
          <div className={styles.pieChart}>
            <div className={styles.pieChartHeader}>
              <h2 className={styles.headerH2}>Customer Dashboard</h2>
              <p className={styles.headerP}>
                This is a summary of all the channel usage
              </p>
              <div className={styles.dropdownOutterContainer}>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
              </div>
            </div>
            <Chart
              chartType="PieChart"
              width="100%"
              height="300px"
              data={dataPie6}
              options={optionsPie6}
            />
          </div>
        </div>
      </div>
    </CallCenterStructure>
  );
};

export default ClientDashboard;
