import React from 'react';
import {Times} from '../../assets/icons';
import styles from '../../styles/MenuDeleteModal.module.scss';

const MenuDeleteModal = ({onClose, onDelete}) => {

  const handleDelete = async () => {
    try {
      onDelete();
      onClose(); // Close the modal
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  return (
    <div className={styles.menuDeleteModal}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeaderContainer}>
          <p>Confirmation</p>
          <span className={styles.icon} onClick={onClose}>
            <Times />
          </span>
        </div>
        <div className={styles.modalContentText}>
          <h6>Are you sure you want to Delete Menu?</h6>
        </div>
        <div className={styles.modalBTNContainer}>
          <button onClick={handleDelete}>Yes</button>
          <button className={styles.resetBTN} onClick={onClose}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default MenuDeleteModal;
