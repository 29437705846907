import React, {useState, useEffect} from 'react';
import {Times, ViewPen} from '../../assets/icons';
import Dropdown from '../../component/DropDown/DropDown';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import styles from '../../styles/PromoCampaign.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import defaultImage from '../../assets/images/defaultImage.png';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import Paginate from '../../component/Paginate/Paginate';
import {
  Headers,
  SweepStakeHeaders,
  campaignHeaders,
} from '../../service/Essentials';
import swal from 'sweetalert';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

// joadRMP8Ct0CLU0B;
// eudoxusganium;
//mongodb+srv://eudoxusganium:joadRMP8Ct0CLU0B@cluster0.u9k42uj.mongodb.net/
const PromoCampaign = () => {
  const [clearModal, setClearModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const queryClient = useQueryClient();
  const [deleteModal, setDeleteModal] = useState(false);
  const [error, setError] = useState('oops... something went wrong');
  const [page, setPage] = useState(1);
  const [programID, setProgramID] = useState('');
  const [programName, setProgramName] = useState('');
  const [sweepstakeID, setsweepstakeID] = useState('');
  const [sweepstakeSlug, setsweepstakeSlug] = useState('');
  const [companyID, setCompanyID] = useState('');
  const [campaignStatus, setCampaignStatus] = useState(false);
  const [sweepstakeFlag, setSweepstakeFlag] = useState(false);
  const [promo, setPromo] = useState([]);
  const [deleteId, setDeleteId] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState();
  const [selectedItem, setSelectedItem] = useState({});

  const onClose = () => {
    setDeleteModal(false);
  };

  useEffect(() => {
    const getCompanyId = JSON.parse(sessionStorage.getItem('company'));
    // setCompanyID(getCompanyId.id);
    setCompanyID(1);
    setFormData(prevFormData => ({
      ...prevFormData,
      Company_id: 1,
      File_name: selectedImageName,
    }));
  }, [selectedImageName]);

  const handleProgramName = name => {
    setProgramName(name);
    setFormData(prevFormData => ({
      ...prevFormData,
      program: name,
    }));
  };

  const handleProgramID = id => {
    setProgramID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Loyalty_program_id: id,
    }));
  };
  const handleSweepstakeID = id => {
    setsweepstakeID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Sweepstake_id: id,
    }));
  };
  const handleSweepstakeSlug = name => {
    setsweepstakeSlug(name);
  };

  const handleCampaignStatus = value => {
    if (value) {
      setCampaignStatus('Enabled');
    } else {
      setCampaignStatus('Disabled');
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      campaign_status: value ? 'Enabled' : 'Disabled',
    }));
  };
  const handleSweepstakeFlag = value => {
    if (value) {
      setSweepstakeFlag(1);
    } else {
      setSweepstakeFlag(0);
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      Sweepstake_flag: value ? 1 : 0,
    }));
  };

  const [errors, setErrors] = useState({
    Loyalty_program_id: '',
    Company_id: '',
    Membership_id: '',
    From_date: '',
    To_date: '',
    Campaign_name: '',
    Campaign_description: '',
    campaign_status: '',
    Reward_points: '',
    company_name: '',
    program: '',
    File_name: '',
    Promo_code: '',
    Points: '',
    Sweepstake_id: '',
    Sweepstake_flag: '',
    Sweepstake_ticket_limit: '',
  });

  const [formData, setFormData] = useState({
    Loyalty_program_id: programID,
    Company_id: companyID,
    Membership_id: '7744889000',
    From_date: '',
    To_date: '',
    Campaign_name: '',
    Campaign_description: '',
    campaign_status: 'Disabled',
    Reward_points: '',
    company_name: '',
    program: '',
    File_name: '',
    Promo_code: '',
    Points: '',
    Sweepstake_id: '',
    Sweepstake_flag: 0,
    Sweepstake_ticket_limit: '',
  });

  const handleChange = event => {
    const {name, value} = event.target;
    setFormData(prev => ({...prev, [name]: value}));
  };

  const handleReset = () => {
    setFormData({
      Loyalty_program_id: '',
      Company_id: '',
      Membership_id: '',
      From_date: '',
      To_date: '',
      Campaign_name: '',
      Campaign_description: '',
      campaign_status: '',
      Reward_points: '',
      company_name: '',
      program: '',
      File_name: '',
      Promo_code: '',
      Points: '',
      Sweepstake_id: '',
      Sweepstake_flag: '',
      Sweepstake_ticket_limit: '',
    });
  };

  const addPromo = async () => {
    try {
      const res = await axiosProperty.post(
        ApiConfig.createPromo,
        {
          Loyalty_program_id: programID,
          Company_id: companyID,
          Membership_id: '7744889000',
          From_date: formData.From_date,
          To_date: formData.To_date,
          Campaign_name: formData.Campaign_name,
          Campaign_description: formData.Campaign_description,
          campaign_status: formData.campaign_status,
          Reward_points: formData.Reward_points,
          company_name: formData.company_name,
          program: formData.program,
          File_name: formData.File_name,
          Promo_code: formData.Promo_code,
          Points: formData.Points,
          Sweepstake_id: formData.Sweepstake_id,
          Sweepstake_flag: formData.Sweepstake_flag,
          Sweepstake_ticket_limit: formData.Sweepstake_ticket_limit,
        },
        {
          headers: campaignHeaders(),
        },
      );

      setErrors({});

      swal({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: addPromoMutation} = useMutation({
    mutationFn: addPromo,
  });

  const fetchPromoCampaign = async page => {
    const res = await axiosProperty.get(ApiConfig.fetchPromo(page), {
      headers: campaignHeaders(),
    });
    return res.data;
  };

  const {data: promoData, status} = useQuery(
    ['promo', page],
    () => fetchPromoCampaign(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setPromo(promoData?.data || []);
  }, [promoData]);

  const data = [
    {
      code: 4356,
      name: 'Campaign',
      from: '28/6/2023',
      to: '28/6/2023',
      point: 5476,
    },
    {
      code: 4456,
      name: 'Campaign',
      from: '28/6/2023',
      to: '28/6/2023',
      point: 5476,
    },
    {
      code: 4346,
      name: 'Campaign',
      from: '28/6/2023',
      to: '28/6/2023',
      point: 5476,
    },
    {
      code: 4359,
      name: 'Campaign',
      from: '28/6/2023',
      to: '28/6/2023',
      point: 5476,
    },
  ];

  // console.log(formData);
  return (
    <CallCenterStructure>
      <div className={styles.referalCampaign}>
        <div className={styles.headerText}>
          <p>Promo Campaign</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Loyalty Programme<sup>*</sup>
              </label>
              <Dropdown
                header={Headers()}
                api={ApiConfig.fetchLoyaltyProgram}
                onSelect={handleProgramID}
                onSelectOption={handleProgramName}
                column={'program_name'}
                retrievedValue={formData.program}
                key="programme_name"
              />
              {errors?.program && (
                <p className="text-danger">{errors?.program}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Start Date<sup>*</sup>
              </label>
              <input
                type="date"
                name="From_date"
                value={formData.From_date}
                onChange={handleChange}
              />
              {errors?.From_date && (
                <p className="text-danger">{errors?.From_date}</p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Campaign Name<sup>*</sup>
              </label>
              <input
                type="text"
                name="Campaign_name"
                value={formData?.Campaign_name}
                onChange={handleChange}
              />
              {errors?.Campaign_name && (
                <p className="text-danger">{errors?.Campaign_name}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                End Date<sup>*</sup>
              </label>
              <input
                type="date"
                name="To_date"
                value={formData.To_date}
                onChange={handleChange}
              />
              {errors?.To_date && (
                <p className="text-danger">{errors?.To_date}</p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Campaign Description<sup>*</sup>
              </label>
              <input
                type="text"
                name="Campaign_description"
                value={formData.Campaign_description}
                onChange={handleChange}
              />
              {errors?.Campaign_description && (
                <p className="text-danger">{errors?.Campaign_description}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Campaign Status<sup>*</sup>
              </label>
              <RadioButtonBlue
                firstText="Enabled"
                secondText="Disabled"
                name="campaign_status"
                onChange={handleCampaignStatus}
                value={formData.campaign_status}
              />
              {errors?.campaign_status && (
                <p className="text-danger">{errors?.campaign_status}</p>
              )}
            </div>
          </div>
          <div className={styles.itemInputContainer4}>
            <div className={styles.defaultImageContainer}>
              <div className={styles.imageContainer}>
                {selectedImage ? (
                  <img src={selectedImage} alt="default" />
                ) : (
                  <img src={defaultImage} alt="default" />
                )}
              </div>
              <p>Logo</p>
            </div>

            <div className={styles.uploadContainer}>
              <div className={styles.uploadInputContainer}>
                <label htmlFor="">Upload</label>
                <ImageDragAndDrop
                  selectedImageName={selectedImageName}
                  setSelectedImageName={setSelectedImageName}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
                {/* <div>
                <input
                  type="file"
                  name="File_name"
                  value={formData.File_name}
                  onChange={handleChange}
                />
                </div> */}
                {errors?.File_name && (
                  <p className="text-danger">{errors?.File_name}</p>
                )}
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer3}>
            <label htmlFor="">
              Sweepstake to Link<sup>*</sup>
            </label>
            <RadioButtonBlue
              firstText="Yes"
              secondText="No"
              name="Sweepstake_flag"
              onChange={handleSweepstakeFlag}
              value={formData.Sweepstake_flag}
            />
            {errors?.Sweepstake_flag && (
              <p className="text-danger">{errors?.Sweepstake_flag}</p>
            )}
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Sweepstake<sup>*</sup>
              </label>
              <Dropdown
                header={SweepStakeHeaders()}
                api={ApiConfig.fetchActiveSweepStakes}
                onSelect={handleSweepstakeID}
                onSelectOption={handleSweepstakeSlug}
                column={'slug'}
                retrievedValue={formData.Sweepstake_id}
                key="sweepstake"
              />
              {errors?.Sweepstake_id && (
                <p className="text-danger">{errors?.Sweepstake_id}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Number of Sweepstake entries
                <sup>*</sup>
              </label>
              <input
                type="text"
                name="Sweepstake_ticket_limit"
                value={formData?.Sweepstake_ticket_limit}
                onChange={handleChange}
              />
              {errors?.Sweepstake_ticket_limit && (
                <p className="text-danger">{errors?.Sweepstake_ticket_limit}</p>
              )}
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button
              className={styles.submit}
              onClick={e => {
                e.preventDefault();
                addPromoMutation();
              }}>
              Submit
            </button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Remove file</th>
                  <th>ID</th>
                  <th>Uploaded file</th>
                </tr>
              </thead>
              <tbody>
                {status === 'loading' && (
                  <tr>
                    <td className="text-info">loading...</td>
                  </tr>
                )}

                {status === 'success' &&
                  (promo.length === 0 ? (
                    <tr>
                      <td>No Promo Campaign!</td>
                    </tr>
                  ) : (
                    promo?.map(
                      each =>
                        each?.Active_flag === 1 && (
                          <tr key={each.Campaign_id}>
                            <td>{each.Points}</td>
                            <td>{each.Campaign_id}</td>
                            <td>{each.File_name}</td>
                          </tr>
                        ),
                    )
                  ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <Paginate page={page} data={promoData} setPage={setPage} />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <h6 className={styles.disableText}>Disabled Campaigns</h6>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Company Code</th>
                  <th>Company Name</th>
                  <th>From Date</th>
                  <th>End Date</th>
                  <th>Reward Points</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map(each => (
                  <tr key={each.code}>
                    <td>{each.code}</td>
                    <td>{each.name}</td>
                    <td>{each.from}</td>
                    <td>{each.to}</td>
                    <td>{each.point}</td>
                    <td>
                      <div className={styles.iconContainer}>
                        <span className={styles.icon}>
                          <ViewPen />
                        </span>
                        <span className={styles.icon}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <p>Pagination</p>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
      </div>
    </CallCenterStructure>
  );
};

export default PromoCampaign;
