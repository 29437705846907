import React, {useState} from 'react';
import styles from '../../styles/LSLPartnerBranchMaster.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import DropDown from '../../component/DropDown/DropDown';
import SearchInput from '../../component/SearchInput/SearchInput';
import {Times, ViewPen} from '../../assets/icons';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const LSLPartnerBranchMaster = () => {
  const [clearModal, setClearModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const onClose = () => {
    setDeleteModal(false);
  };
  const data = [
    {
      id: 232,
      code: '0001',
      partner: 'Jumia',
      name: 'Jumia Online Store',
      address: 'Online Store',
      country: 'Nigeria',
      city: 'VICTORIA ISLAND',
    },
    {
      id: 222,
      code: '0001',
      partner: 'Jumia',
      name: 'Jumia Online Store',
      address: 'Online Store',
      country: 'Nigeria',
      city: 'VICTORIA ISLAND',
    },
    {
      id: 212,
      code: '0001',
      partner: 'Jumia',
      name: 'Jumia Online Store',
      address: 'Online Store',
      country: 'Nigeria',
      city: 'VICTORIA ISLAND',
    },
  ];

  return (
    <CallCenterStructure>
      <div className={styles.lslPartnerBranchMaster}>
        <div className={styles.headerText}>
          <p>LSL Partner Branch Master</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputField1}>
            <div className={styles.inputFieldInnerContainer1}>
              <label htmlFor="">
                Select Partner<sup>*</sup>
              </label>
              <DropDown />
            </div>
            <div className={styles.inputFieldInnerContainer1}>
              <label htmlFor="">
                Enter Branch Code<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.inputFieldInnerContainer1}>
              <label htmlFor="">
                Enter Branch Name<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.inputField2}>
            <div className={styles.inputFieldInnerContainer2}>
              <label htmlFor="">
                Enter Branch Address<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.inputFieldInnerContainer2}>
              <label htmlFor="">
                Enter Receipt Number Series <sup>*</sup>{' '}
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.inputField2}>
            <div className={styles.inputFieldInnerContainer2}>
              <label htmlFor="">
                Select Country <sup>*</sup>
              </label>
              <DropDown />
            </div>
            <div className={styles.inputFieldInnerContainer2}>
              <label htmlFor="">
                Select Region<sup>*</sup>{' '}
              </label>
              <DropDown />
            </div>
          </div>
          <div className={styles.inputField1Adjust}>
            <div className={styles.inputFieldInnerContainer1}>
              <label htmlFor="">
                Select Zone<sup>*</sup>
              </label>
              <DropDown />
            </div>
            <div className={styles.inputFieldInnerContainer1}>
              <label htmlFor="">
                Select State<sup>*</sup>
              </label>
              <DropDown />
            </div>
            <div className={styles.inputFieldInnerContainer1}>
              <label htmlFor="">
                Select City<sup>*</sup>
              </label>
              <DropDown />
            </div>
          </div>
          <div className={styles.btnContainer}>
            <button className={styles.submit}>Submit</button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.searchInputContainer}>
              <SearchInput />
              <button>Search</button>
            </div>
            <div className={styles.tableContainer}>
              <table className={styles.dataTable}>
                <thead>
                  <tr>
                    <th>Branch ID</th>
                    <th>Branch Code</th>
                    <th>Partner</th>
                    <th>Branch Name</th>
                    <th>Branch Address</th>
                    <th>Country</th>
                    <th>City</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(each => (
                    <tr key={each.id}>
                      <td>{each.id}</td>
                      <td>{each.code}</td>
                      <td>{each.partner}</td>
                      <td>{each.name}</td>
                      <td>{each.address}</td>
                      <td>{each.country}</td>
                      <td>{each.city}</td>
                      <td>
                        <div className={each.iconContainer}>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>
                          <span
                            className={styles.icon}
                            onClick={() => setDeleteModal(true)}>
                            <Times />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.paginationContainer}>
              <p>pagination</p>
            </div>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && <MenuDeleteModal onClose={onClose} />}
      </div>
    </CallCenterStructure>
  );
};

export default LSLPartnerBranchMaster;
