import React, {useState, useEffect} from 'react';
import {Mark, Times, ViewPen} from '../../assets/icons';
import Dropdown from '../../component/DropDown/DropDown';
import SearchInput from '../../component/SearchInput/SearchInput';
import styles from '../../styles/NotificationTemplate.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import { useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import { Headers, RedirectToLogin } from '../../service/Essentials';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';

const NotificationTemplate = () => {

const queryClient = useQueryClient();

//RedirectToLogin();

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const fetchNotificationTypes = async (page) =>{
    const res = await axiosProperty.get(ApiConfig.fetchNotificationType+`?page=${page}`, {
        headers:Headers()
    }
    );
    return res.data;
  }  

  const {data, status} = useQuery(['notificationtypes', page], () => fetchNotificationTypes(page), {keepPreviousData:true,});

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data) {
      const filtered = data.data.filter(
        (item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase())||
          item.type.toLowerCase().includes(searchTerm.toLowerCase())||
          item.recipient.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  const [optionID, setOptionID] = useState('');
  const [option1, setDropdown1] = useState('');
  const [option2, setDropdown2] = useState('');
  const [option3, setDropdown3] = useState('');
  const [successModal, setSuccessModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState();
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState(null);

  const handleDropdownChange = (id) => {
    setOptionID(id);
  };

  const handleDropdown1 = (name) => {
    setDropdown1(name);
  };

  const handleDropdown2 = (name) => {
    setDropdown2(name);
  };

  const handleDropdown3 = (name) => {
    setDropdown3(name);
  };
 
  const addNotificationType = async () => {
    try{
        await axiosProperty.post(ApiConfig.createNotificationType, {name: option1, type: option2, recipient: option3}, 
        {
          headers:Headers()
      }
        );
       // setSuccessModal(true);
        setDropdown1('');
        setDropdown2('');
        setDropdown3(''); 
        setErrors(null);
        swal({
          title: "Success!",
          text: "Notification template created successfully",
          icon: "success",
        });
    }catch(err){
      if(err.response){
       setError(err.response.data.message);
       setErrors(err.response.data);
       swal({
        title: "Error!",
        text: error,
        icon: "error",
      });
      }else{
      setError('Something went wrong, try again'); 
      swal({
        title: "Warning!",
        text: error,
        icon: "warning",
      });
      }
      console.log(err);
    }
  };

const {mutate:addNotificationTypeMutation} = useMutation(
  {
    mutationFn: addNotificationType,
    onSuccess:()=>{queryClient.invalidateQueries(['notificationtypes'])}, 
  }
  );

  const updateNotificationType = async () => {
    try{
        await axiosProperty.patch(ApiConfig.updateNotificationType+`/${updateID}`, {name: option1, type: option2, recipient: option3},
        {
          headers:Headers(),
      }
        );
        //setSuccessModal(true);
        setErrors(null);
        setDropdown1('');
        setDropdown2('');
        setDropdown3('');    
        setIsSubmit(true);
        setIsUpdate(false);
        swal({
          title: "Success!",
          text: "Notification template updated successfully",
          icon: "success",
        });
    }catch(err){
      if(err.response){
        setError(err.response.data.message);
        setErrors(err.response.data);
        swal({
          title: "Error!",
          text: error,
          icon: "error",
        });
      }else{
       setError('Something went wrong, try again'); 
       swal({
        title: "Warning!",
        text: error,
        icon: "warning",
      });
      }
      console.log(err);
    }
  };

  const {mutate:updateNotificationTypeMutation} = useMutation(
    {
      mutationFn: updateNotificationType,
      onSuccess:()=>{queryClient.invalidateQueries(['notificationtypes'])}, 
    }
    );

  const editNotificationType = async (id) => {
       const res = await axiosProperty.get(ApiConfig.editNotificationType+`/${id}`,
        {
          headers:Headers(),
      }
      );
      setIsUpdate(true); 
      setIsSubmit(false); 
      setDropdown1(res.data.data.name);
      setDropdown2(res.data.data.type);
      setDropdown3(res.data.data.recipient);
  };
  return (
    <CallCenterStructure>
      <div className={styles.transactionTypeContainer}>
        <div>
          <h6 className={styles.headerText}>
            Notification Template Type Master Setup
          </h6>
        </div>
        <div className={styles.inputContainer}>

          <div className={styles.formContainer}>
            <div>
              <label htmlFor="">
                Select Template Type <sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchNotificationType} onSelect={handleDropdownChange} onSelectOption={handleDropdown1} column={'type'} retrievedValue={option1} key="type" />
            </div>
            <div>
              <label htmlFor="">
                Enter Template Type Name<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchNotificationType} onSelect={handleDropdownChange} onSelectOption={handleDropdown2} column={'name'} retrievedValue={option2} key="name" />
            </div>
            <div>
              <label htmlFor="">
                Select Primary Recipient<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchNotificationType} onSelect={handleDropdownChange} onSelectOption={handleDropdown3} column={'recipient'} retrievedValue={option3} key="recipient"/>
            </div>
            {isSubmit && (<button
              className={styles.transactionTypeBtn}
              onClick={()=>addNotificationTypeMutation()}>
              Submit
            </button>)}
            {isUpdate && ( <button
              className={styles.transactionTypeBtn}
              onClick={()=>updateNotificationTypeMutation()}>
              Update
            </button>)}
          </div> 
          <p className={styles.transactionTypeNoteText}>
            *Note:Click on column header for Ascending/Descending
          </p>
        </div>
        <div className={styles.tableContainer}>
        <ErrorMessage errMsg={errors} />
          <div className={styles.formContainer}>
            <div className={styles.searchInput}>
              <SearchInput onSearchInputChange={handleSearchInputChange} />
            </div>
            <button className={styles.transactionTypeBtn}>Search</button>
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.dataTable}>
              <thead>
                <tr>
                  <th>Template Type ID</th>
                  <th>Template Type</th>
                  <th>Template Type Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {
                status === 'error' && (<tr><td className='text-danger'>Error Occured</td></tr>)
              }

              {
                status === 'loading' && (<tr><td className='text-info'>loading</td></tr>)
              }
                {status === 'success' && (filteredData.map(each => (
                  <tr key={each.id}>
                    <td>{each.id}</td>
                    <td>{each.name}</td>
                    <td>{each.type}</td>
                    <td onClick={()=>{editNotificationType(each.id); setUpdateID(each.id)}}>
                      <ViewPen />
                    </td>
                  </tr>
                )))}

              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={data} setPage={setPage} />
          </div>
        </div>
        {successModal && (
          <div className={styles.successModal}>
            <Mark />
            <p className={styles.successText}>User Type addes successfully</p>
            <span onClick={() => setSuccessModal(false)}>
              <Times />
            </span>
          </div>
        )}
      </div>
    </CallCenterStructure>
  );
};

export default NotificationTemplate;
