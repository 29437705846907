import React, {useState} from 'react';
import Dropdown from '../../component/DropDown/DropDown';
import QuillContainer from '../../component/Quill/Quill';
import styles from '../../styles/CompanyEmailTemplateMaster.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles1 from '../../styles/RadioButtonBlue.module.scss';
import wole from '../../assets/images/wole.jpeg';
import RadioButton from '../../component/SingleRadioButton/SingleRadioButton';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
import {Times, ViewPen} from '../../assets/icons';
import SearchInput from '../../component/SearchInput/SearchInput';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CompanyEmailTemplateMaster = () => {
  const [clearModal, setClearModal] = useState(false);
  const [quillText, setQuillText] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  const [selectedOption4, setSelectedOption4] = useState(null);
  const [selectedOption5, setSelectedOption5] = useState(null);
  const [selectedOption6, setSelectedOption6] = useState(null);
  const [selectedOption7, setSelectedOption7] = useState(null);
  const [selectedOption8, setSelectedOption8] = useState(null);
  const [selectedOption9, setSelectedOption9] = useState(null);
  const [selectedOption10, setSelectedOption10] = useState(null);
  const [selectedOption11, setSelectedOption11] = useState(null);
  const [selectedOption12, setSelectedOption12] = useState(null);
  const [selectedOption13, setSelectedOption13] = useState(null);
  const [selectedOption14, setSelectedOption14] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const options = [
    {label: 'Horizontal Menu', img: wole},
    {label: 'Vertical Drop Menu', img: wole},
  ];
  const options1 = ['Yes', 'No'];
  const options2 = [
    {color: '#686262'},
    {color: '#622262'},
    {color: '#668628'},
    {color: '#988628'},
    {color: '#689262'},
    {color: '#688262'},
  ];
  const options3 = [
    {color: '#686262'},
    {color: '#622262'},
    {color: '#668628'},
    {color: '#988628'},
    {color: '#689262'},
    {color: '#688262'},
  ];
  const options4 = ['Yes', 'No'];
  const options5 = ['CC', 'BCC'];
  const handleOptionChange = option => {
    setSelectedOption(option);
  };

  const handleOptionChange2 = option => {
    setSelectedOption2(option);
  };
  const handleOptionChange3 = option => {
    setSelectedOption3(option);
  };

  const tableData = [
    {
      id: 4,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Active',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 3,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Inactive',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 9,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Active',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 6,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Active',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    ,
  ];

  return (
    <div>
      <CallCenterStructure>
        <div className={styles.CompanyEmailTemplateMaster}>
          <div className={styles.headerText}>
            <p>Company Email Template Master</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field1}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select Company<sup>*</sup>
                </label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Enter Application Website Template Name<sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Enter Description</label>
                <textarea name="" id="" />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Email Subject</label>
                <textarea name="" id="" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Select Variable for Subject</label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Body Content Width</label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Select Pre-defined Templates</label>
                <button>Choose Template</button>
              </div>
            </div>
            <div className={styles.field2Quill}>
              <div className={styles.fieldContainer1}>
                <div>
                  <label htmlFor="">Plans</label>
                  <QuillContainer
                    placeholder="Insert your text here"
                    onChange={setQuillText}
                  />
                </div>

                <div className={styles.fieldContainer2}>
                  <label htmlFor="">Select Variable for Body</label>
                  <input type="text" />
                </div>
                <div className={styles.fieldContainer2}>
                  <div className={styles.radioButtonsContainer}>
                    {options.map((option, index) => (
                      <div
                        className={styles.radioButtonContainer}
                        key={index}
                        onClick={() => handleOptionChange(option.label)}>
                        <div className={styles1.myCheckbox}>
                          <div
                            className={
                              option.label === selectedOption
                                ? styles1.checked
                                : styles1.uncheck
                            }></div>
                        </div>
                        <div className={styles.radioImage}>
                          <div className={styles.radioInnerImage}>
                            <img src={option.img} alt={option.label} />
                          </div>
                          <p>{option.label}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.fieldContainer2}>
                  <label htmlFor="">Enter Footer Note</label>
                  <textarea name="" id="" />
                </div>
                <div className={styles.fieldContainer2}>
                  <label htmlFor="">Select Variable for Footer</label>
                  <Dropdown />
                </div>
                <div className={styles.fieldContainer2}>
                  <label htmlFor="">Unsubscribe Link</label>
                  <RadioButton
                    options={options1}
                    onChange={setSelectedOption1}
                    selectedOption={selectedOption1}
                  />
                </div>
              </div>

              <div className={styles.fieldContainer1}>
                <label htmlFor=""></label>
                <div className={styles.emailPreviewField}>
                  <h5 className={styles.previewTitle}>
                    Email Template Preview
                  </h5>
                  <div className={styles.emailDetails}>From: </div>
                  <div className={styles.emailDetails}>To: </div>
                  <div className={styles.emailDetails}>Subject: </div>
                  <div className={styles.previewOutterContainer}>
                    <div className={styles.previewContainer}>
                      <div className={styles.emailDetails}>
                        Content: {quillText}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.field3}>
                <div className={styles.fieldContainer1}>
                  <label htmlFor="">Set Font Color</label>
                  <div className={styles.radioButtonsContainer3}>
                    {options2.map((option, index) => (
                      <div
                        className={styles.radioButtonContainer3}
                        key={index}
                        onClick={() => handleOptionChange2(option.color)}>
                        <div className={styles1.myCheckbox}>
                          <div
                            className={
                              option.color === selectedOption2
                                ? styles1.checked
                                : styles1.uncheck
                            }></div>
                        </div>
                        <div className={styles.radioImage}>
                          <div
                            style={{
                              width: '40px',
                              height: '40px',
                              backgroundColor: `${option.color}`,
                            }}></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.fieldContainer1}>
                  <label htmlFor="">More Colors:</label>
                  <input type="text" />
                </div>
              </div>
              <div className={styles.field3}>
                <div className={styles.fieldContainer1}>
                  <label htmlFor="">Set Font Color</label>
                  <div className={styles.radioButtonsContainer3}>
                    {options3.map((option, index) => (
                      <div
                        className={styles.radioButtonContainer3}
                        key={index}
                        onClick={() => handleOptionChange3(option.color)}>
                        <div className={styles1.myCheckbox}>
                          <div
                            className={
                              option.color === selectedOption3
                                ? styles1.checked
                                : styles1.uncheck
                            }></div>
                        </div>
                        <div className={styles.radioImage}>
                          <div
                            style={{
                              width: '40px',
                              height: '40px',
                              backgroundColor: `${option.color}`,
                            }}></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.fieldContainer1}>
                  <label htmlFor="">More Colors:</label>
                  <input type="text" />
                </div>
              </div>
              <div className={styles.field3}>
                <div className={styles.fieldContainer1}>
                  <label htmlFor="">
                    Select Font Size<sup>*</sup>
                  </label>
                  <Dropdown />
                </div>
                <div className={styles.fieldContainer1}>
                  <label htmlFor="">
                    Select Font Family to Apply<sup></sup>
                  </label>
                  <Dropdown />
                </div>
              </div>
              <div className={styles.field3}>
                <div className={styles.fieldContainer1}>
                  <label htmlFor="">Enable notification Share</label>
                  <RadioButton
                    options={options4}
                    selectedOption={selectedOption4}
                    onChange={setSelectedOption4}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field4}>
              <div className={styles.fieldContainer4Left}>
                <div className={styles.fieldContainer4}>
                  <label htmlFor="">Enter Title for Share</label>
                  <input type="text" />
                </div>
                <div className={styles.fieldContainer4Down}>
                  <label htmlFor="">Select Variable for Share Title</label>
                  <Dropdown />
                </div>
                <div className={styles.fieldContainer4Down}>
                  <label htmlFor="">Enter Content for Share</label>
                  <textarea name="" id="" />
                </div>
                <div className={styles.fieldContainer4Down}>
                  <label htmlFor="">Select Variable for Share Content</label>
                  <Dropdown />
                </div>
                <div className={styles.fieldContainer4Down}>
                  <label htmlFor="">Select Image for Share</label>
                  <ImageDragAndDrop
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                    selectedImageName={selectedImageName}
                    setSelectedImageName={setSelectedImageName}
                  />
                </div>
                <div className={styles.fieldContainer4Down}>
                  <label htmlFor="">Social Media Links</label>
                  <input type="text" />
                </div>
              </div>
              <div className={styles.fieldContainer4Right}>
                <h5>Share Content Preview on Social Media</h5>
                <div></div>
              </div>
            </div>
          </div>

          <div className={styles.field5}>
            <h5>For Selecting User Groups</h5>
            <div className={styles.inputContainer}>
              <div className={styles.innerField5}>
                <h5>
                  Select User Type<sup>*</sup>
                </h5>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                  <div className={styles.innerFieldContainer5}>
                    <input type="text" />
                  </div>
                </div>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                </div>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.innerField5}>
                <h5>
                  Select User Group<sup>*</sup>
                </h5>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                  <div className={styles.innerFieldContainer5}>
                    <input type="text" />
                  </div>
                </div>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                </div>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.innerField5}>
                <h5>
                  Primary Recipient<sup>*</sup>
                </h5>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <RadioButton
                      options={options5}
                      selectedOption={selectedOption5}
                      onChange={setSelectedOption5}
                    />
                  </div>
                  <div className={styles.innerFieldContainer5}>
                    <RadioButton
                      options={options5}
                      selectedOption={selectedOption6}
                      onChange={setSelectedOption6}
                    />
                  </div>
                </div>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <RadioButton
                      options={options5}
                      selectedOption={selectedOption7}
                      onChange={setSelectedOption7}
                    />
                  </div>
                  <div className={styles.innerFieldContainer5}>
                    <RadioButton
                      options={options5}
                      selectedOption={selectedOption8}
                      onChange={setSelectedOption8}
                    />
                  </div>
                </div>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <RadioButton
                      options={options5}
                      selectedOption={selectedOption9}
                      onChange={setSelectedOption9}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.field5}>
            <h5>For Selecting Individual Users</h5>
            <div className={styles.inputContainer}>
              <div className={styles.innerField5}>
                <h5>
                  Select User Type<sup>*</sup>
                </h5>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                  <div className={styles.innerFieldContainer5}>
                    <input type="text" />
                  </div>
                </div>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                </div>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.innerField5}>
                <h5>
                  Select User Group<sup>*</sup>
                </h5>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                  <div className={styles.innerFieldContainer5}>
                    <input type="text" />
                  </div>
                </div>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                </div>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.innerField5}>
                <h5>
                  Select User Name<sup>*</sup>
                </h5>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                  <div className={styles.innerFieldContainer5}>
                    <input type="text" />
                  </div>
                </div>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                </div>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <Dropdown />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.innerField5}>
                <h5>
                  Primary Recipient<sup>*</sup>
                </h5>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <RadioButton
                      options={options5}
                      selectedOption={selectedOption10}
                      onChange={setSelectedOption10}
                    />
                  </div>
                  <div className={styles.innerFieldContainer5}>
                    <RadioButton
                      options={options5}
                      selectedOption={selectedOption11}
                      onChange={setSelectedOption11}
                    />
                  </div>
                </div>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <RadioButton
                      options={options5}
                      selectedOption={selectedOption12}
                      onChange={setSelectedOption12}
                    />
                  </div>
                  <div className={styles.innerFieldContainer5}>
                    <RadioButton
                      options={options5}
                      selectedOption={selectedOption13}
                      onChange={setSelectedOption13}
                    />
                  </div>
                </div>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <RadioButton
                      options={options5}
                      selectedOption={selectedOption14}
                      onChange={setSelectedOption14}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Submit</button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
          <div className={styles.tableSection}>
            <div className={styles.searchInputContainer}>
              <div className={styles.searchInput}>
                <SearchInput />
              </div>
              <button className={styles.searchBTN}>Search</button>
            </div>
            <div className={styles.innerTableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Email Template ID</th>
                    <th>Email Template Name</th>
                    <th>Template Description</th>
                    <th>Click to Activate</th>
                    <th>Click to Deactivate</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr key={each.id}>
                      <td>{each.name}</td>
                      <td>{each.code}</td>
                      <td>{each.itemName}</td>
                      <td>
                        {each.groupName === 'Active' ? (
                          <p className={styles.active}>{each.groupName}</p>
                        ) : (
                          <p className={styles.inactive}>{each.groupName}</p>
                        )}
                      </td>
                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <Times />
                          </span>
                        </div>
                      </td>

                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default CompanyEmailTemplateMaster;
