import React, {useState} from 'react';
import {CheckBoxMark} from '../../assets/icons';
import styles from '../../styles/CheckBox.module.scss';

const CheckBox = ({name, onSelect, checkbox}) => {
  const [checked, setChecked] = useState(false);

  const toggleCheck = () => {
    setChecked(!checked);
    onSelect(name, !checked);
  };

  return (
    <div>
      <div
        className={checkbox ? styles.checked : styles.uncheck}
        onClick={toggleCheck}>
        <CheckBoxMark />
      </div>
    </div>
  );
};

export default CheckBox;
