import React, {useState} from 'react';
import styles from '../../styles/VoucherRedemptionReceiptTemplate.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import wole from '../../assets/images/wole.jpeg';
import styles1 from '../../styles/RadioButtonBlue.module.scss';
import Dropdown from '../../component/DropDown/DropDown';
import {Times, ViewPen} from '../../assets/icons';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';
const VoucherRedemptionReceiptTemplate = () => {
  const [clearModal, setClearModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const options = [
    {label: 'Voucher Redemption Template 1', img: wole},
    {label: 'Voucher Redemption Template 2', img: wole},
  ];

  const options1 = [{color: '#628628'}, {color: '#8D8298'}, {color: '#AAA628'}];
  const options2 = [{color: '#628698'}, {color: '#8D8298'}, {color: '#AAB628'}];

  const handleOptionChange = option => {
    setSelectedOption(option);
  };

  const handleOptionChange1 = option => {
    setSelectedOption1(option);
  };

  const handleOptionChange2 = option => {
    setSelectedOption2(option);
  };

  const tableData = [
    {
      id: 4,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Active',
      groupName1: 'Active',
      groupName2: 'Active',
      groupName3: 'Inactive',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 3,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Inactive',
      groupName1: 'Active',
      groupName2: 'Active',
      groupName3: 'Inactive',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 9,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Active',
      groupName1: 'Active',
      groupName2: 'Active',
      groupName3: 'Inactive',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 6,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Active',
      groupName1: 'Active',
      groupName2: 'Active',
      groupName3: 'Inactive',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    ,
  ];

  return (
    <div>
      {' '}
      <CallCenterStructure>
        <div className={styles.VoucherRedemptionReceiptTemplate}>
          <div className={styles.headerText}>
            <p>Voucher Redemption Receipt Template</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field1}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Enter Voucher Redemption Template Name<sup>*</sup>
                </label>
                <input type="text" name="" id="" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer2}>
                <label htmlFor="">
                  Choose template<sup>*</sup>
                </label>
                <div className={styles.radioButtonsContainer}>
                  {options.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer}
                      key={index}
                      onClick={() => handleOptionChange(option.label)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.label === selectedOption
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <div className={styles.radioInnerImage}>
                          <img src={option.img} alt={option.label} />
                        </div>
                        <p>{option.label}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Choose Background Color</label>
                <div className={styles.radioButtonsContainer3}>
                  {options1.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer3}
                      key={index}
                      onClick={() => handleOptionChange1(option.color)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.color === selectedOption1
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <div
                          style={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: `${option.color}`,
                          }}></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">More Color:</label>
                <input type="text" name="" id="" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Choose Footer Text Color</label>
                <div className={styles.radioButtonsContainer3}>
                  {options2.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer3}
                      key={index}
                      onClick={() => handleOptionChange2(option.color)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.color === selectedOption2
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <div
                          style={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: `${option.color}`,
                          }}></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">More Color:</label>
                <input type="text" name="" id="" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Set Font Size</label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Set Font Family to Apply</label>
                <Dropdown />
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <button className={styles.submit}>Submit</button>
              <button
                className={styles.reset}
                type="button"
                onClick={() => setClearModal(true)}>
                Reset
              </button>
            </div>
          </div>
          <div className={styles.tableSection}>
            <div className={styles.innerTableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Voucher Template Name</th>
                    <th>Template Type</th>
                    <th>Background Color</th>
                    <th>Footer Text Color</th>
                    <th>Font Size</th>
                    <th>Font Family</th>
                    <th>Preview</th>
                    <th>Click to Deactivate</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr key={each.id}>
                      <td>{each.name}</td>
                      <td>{each.code}</td>
                      <td>{each.itemName}</td>
                      <td>
                        {each.groupName === 'Active' ? (
                          <p className={styles.active}>{each.groupName}</p>
                        ) : (
                          <p className={styles.inactive}>{each.groupName}</p>
                        )}
                      </td>
                      <td>
                        {each.groupName1 === 'Active' ? (
                          <p className={styles.active}>{each.groupName1}</p>
                        ) : (
                          <p className={styles.inactive}>{each.groupName1}</p>
                        )}
                      </td>
                      <td>
                        {each.groupName2 === 'Active' ? (
                          <p className={styles.active}>{each.groupName2}</p>
                        ) : (
                          <p className={styles.inactive}>{each.groupName2}</p>
                        )}
                      </td>
                      <td>
                        {each.groupName3 === 'Active' ? (
                          <p className={styles.active}>{each.groupName3}</p>
                        ) : (
                          <p className={styles.inactive}>{each.groupName3}</p>
                        )}
                      </td>
                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <Times />
                          </span>
                        </div>
                      </td>

                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default VoucherRedemptionReceiptTemplate;
