import React, {useState} from 'react';
import {useMutation} from 'react-query';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
//import { getUser } from '../../service/common';
import swal from 'sweetalert';
import {TransactionHeaders, ProgramID} from '../../service/Essentials';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';


const ManualCreditTransaction = () => {

   // const user = getUser();
   const [clearModal,setClearModal] = useState(false)

  //const program_id = user.user_group_id == 1 ? 2 : user.user_group_id;
  const program_id = ProgramID();

  // const dataString = sessionStorage.getItem('profile');
  // if (dataString) {
  //   var profile = JSON.parse(dataString);
  // } else {
  // profile = {};
  // }
  // const membership_id = profile?.Membership_id;
  // const enrolment_id = profile?.Enrollment_id;
  // const pin = profile?.pin;

  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState({"credit_point":'', "remark":'', "membership_id":'', pin:''});
  const [formData, setFormData] = useState({"credit_point":'', "remark":'', "membership_id":'', pin:''});
  
  const handleChange = e => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const addManualCredit = async () => {
    try{
       const res = await axiosProperty.put(ApiConfig.createManualCredit(1), formData, 
        {
          headers:TransactionHeaders(),
      }
        );
        if(res.data.status == 'Failed!'){
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          }); 
          return;
        }
       setFormData({credit_point:'', remark:'', "membership_id":'', pin:''});
          setErrors({});
        swal({
          title: "Success!",
          text: "Manual credit was successful",
          icon: "success",
        });
    }catch(err){
      if(err.response){
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: addManualCreditMutation} = useMutation({
    mutationFn: addManualCredit,
  });

  return (
    <>
      <CallCenterStructure>
        <div className="mt-5 p-4">
          <h4 className="pb-4">Manual Credit Transaction</h4>
          <main className="border border-light p-3">
            <form action="">
                <div className="row">
                <div className="form-group col-12 col-md-6" >
                    <label htmlFor="membershipID">Membership ID *</label>
                    <input className='w-100' type='text' name='membership_id' onChange={handleChange} value={formData?.membership_id} />
                </div>
                <div className="form-group col-12 col-md-6" >
                <label htmlFor="pointsCredited">Points credited *</label>
                    <input onChange={handleChange} name="credit_point" value={formData.credit_point}  className='w-100' type="text" placeholder='points credited' />
                    {errors?.credit_point && <p class="text-danger">{errors?.credit_point}</p>}
                </div>
              </div>

                <div className="row">
                <div className="form-group col-12 col-md-6" >
                    <label htmlFor="pin">Pin *</label>
                    <input type="text" className='w-100' name="pin" placeholder='pin' onChange={handleChange} value={formData?.pin} />
                </div>
                <div className="form-group col-12 col-md-6" >
                <label htmlFor="remarks">Remarks</label>
                    <input onChange={handleChange} name="remark" value={formData?.remark} className='w-100' type="text" placeholder='comment here' />
                    {errors?.remark && <p class="text-danger">{errors?.remark}</p>}
                </div>
              </div>

              <div className="form-group d-flex justify-content-between d-md-flex">
                <button
                  className="mr-4"
                  onClick={e => {
                    e.preventDefault();
                    addManualCreditMutation();
                  }}>
                  Submit
                </button>
                <button
                  className="resetButton"
                  type="button"
                  onClick={() => setClearModal(true)}>
                  Reset
                </button>
              </div>
            </form>
            </main> 
         </div>
        </CallCenterStructure>
        </>
    );

};

export default ManualCreditTransaction;
