import React, {useState} from 'react';
import styles from '../../styles/RadioButtonBlue.module.scss';

const RadioBlueButton3 = ({firstText, secondText, thirdText, onchange}) => {
  const [inputChecked, setInputChecked] = useState({
    radio1: true,
    radio2: false,
    radio3: false,
  });


  // const handleRadioChangeFalse = () => {
  //   setInputChecked(false);
  // };

  // const handleRadioChangeTrue = () => {
  //   setInputChecked(true);
  // };

  const handleRadioChange = checked => {
    setInputChecked(prev => ({
      ...prev,
      radio1: false,
      radio2: false,
      radio3: false,
      [checked]: true,
    }));
  };

  return (
    <div className={styles.radioButtons_}>
      <div className={styles.radioButton_}>
        <div
          className={styles.myCheckbox}
          onClick={() => {handleRadioChange('radio1'); onchange(firstText.toLowerCase())}}>
          <div
            className={
              inputChecked.radio1 ? styles.checked : styles.uncheck
            }></div>
        </div>

        <p>{firstText}</p>
      </div>
      <div className={styles.radioButton_}>
        <div
          className={styles.myCheckbox}
          onClick={() => {handleRadioChange('radio2'); onchange(secondText.toLowerCase())}}>
          <div
            className={
              inputChecked.radio2 ? styles.checked : styles.uncheck
            }></div>
        </div>
        <p>{secondText}</p>
      </div>
      <div className={styles.radioButton_}>
        <div
          className={styles.myCheckbox}
          onClick={() => {handleRadioChange('radio3'); onchange(thirdText.toLowerCase())}}>
          <div
            className={
              inputChecked.radio3 ? styles.checked : styles.uncheck
            }></div>
        </div>
        <p>{thirdText}</p>
      </div>
    </div>
  );
};

export default RadioBlueButton3;
