import React, {useEffect, useState} from 'react';
import { useQuery } from 'react-query';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import CartStructure from '../CartStructure/CartStructure';
import styles from '../../styles/Profile.module.scss';
import av from '../../assets/images/av.png';
import recieved from '../../assets/images/recieved.png';
import blocked from '../../assets/images/blocked.png';
import used from '../../assets/images/used.png';
import wole from '../../assets/images/wole.jpeg';
import {ChevronDown, ChevronUp} from '../../assets/icons';
import TableModule from '../../component/Table/Table';
import {Link, useLocation} from 'react-router-dom';
import RadioButton from '../../component/SingleRadioButton/SingleRadioButton';
import Dropdown from '../../component/DropDown/DropDown';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import { CustomerEnrollmentHeaders } from '../../service/Essentials';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const Profile = () => {
  const [clearModal, setClearModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMenu, setIsMenu] = useState(false);

  const [selectedOption, setSelectedOption] = useState('');
  const [selectedMenu, setSelectedMenu] = useState('');

  const options = ['Option 1', 'Option 2', 'Option 3'];

  const [familyLists, setFamilyLists] = useState([]);


  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleDropdownMenu = () => {
    setIsMenu(!isMenu);
  };
  const handleOptionSelect = option => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleMenuSelect = option => {
    setSelectedMenu(option);
    setIsMenu(false);
  };

  const fetchFamilyLists = async () =>{
    const res = await axiosProperty.get(ApiConfig.fetchFamilyMembers(data.Enrollment_id), {
      headers:CustomerEnrollmentHeaders(),
    }
    );
    return res.data;
  }  
  const {data:familyData, status} = useQuery(['familylists'], () => fetchFamilyLists(), {keepPreviousData:true});

  useEffect(() => {
    setFamilyLists(familyData?.data || []);
  }, [familyData]);

  const pointsRecieved = [
    {
      'Total Transaction Points': 15165916,
      'Total Transfer In Points': 0,
      'Total Bonus Points': 0,
      'Total Refund Points': 9358655,
      'Total Discretionary Points Credited': 12760000,
    },
  ];

  const pointsUsed = [
    {
      'Total Redemption Points': 6004904,
      'Total Shipped Points': 2332032,
      'Total Transferred Points': 0,
      'Total Expired Points': 9358655,
      'Total Auction win points': 12760000,
      'Total Discretionary Points Debited': 12760000,
    },
  ];

  // const customerFamily = [
  //   {
  //     Name: 'Rasaq',
  //     'Address / City / Region / State': 'Lagos Nigeria',
  //     'Email ID': 'eudoxusganium@gmail.com',
  //     'Phone No': '+23481234567890',
  //     'Membership ID': 7615397,
  //   },
  // ];

  //const location = useLocation();

  //const data = location.state.data;

  const dataString = sessionStorage.getItem('profile');
  if (dataString) {
    var data = JSON.parse(dataString);
  } else {
    data = {};
  }

  const option1 = ['Yes', 'No'];
  const option2 = ['Enable', 'Disable'];
  // console.log(location.state);
  // console.log(location.state.data);
  //console.log(data);
  return (
    <>
      <CartStructure>
        <div className={styles.Profile}>
          {/* <div>
            <h4 className={styles.handleMemberQueryHeader}>
              Membership Id - ({data?.Membership_id})
            </h4>
            <p className={styles.balance}>Current Balance - ({data?.Current_balance !== null ? data?.Current_balance : 0})</p>
          </div> */}
          {/* <div className={styles.balanceContainer}>
            <div className={styles.pointsContainer}>
              <div>
                <img src={av} alt=" available balance" />
              </div>
              <div className={styles.balanceInnerContainer}>
                <p>Available balance</p>
                <h6>1293982</h6>
              </div>
            </div>
            <div className={styles.pointsContainer}>
              <div>
                <img src={recieved} alt="recieved points" />
              </div>
              <div className={styles.balanceInnerContainer}>
                <p>Total Points Received</p>
                <h6>28725916</h6>
              </div>
            </div>
            <div className={styles.pointsContainer}>
              <div>
                <img src={blocked} alt="blocked points" />
              </div>
              <div className={styles.balanceInnerContainer}>
                <p>Total Blocked Points</p>
                <h6>0</h6>
              </div>
            </div>
            <div className={styles.pointsContainer}>
              <div>
                <img src={used} alt="used points" />
              </div>
              <div className={styles.balanceInnerContainer}>
                <p>Total Points used</p>
                <h6>15956333</h6>
              </div>
            </div>
          </div> */}
          <div className={styles.mainContainer}>
            <div className={styles.profileNavOuterContainer}>
              <div className={styles.profileNav}>
                <div className={styles.currentNav}>
                  <Link
                    className={`${styles.currentNav} ${styles.linkstyles}`}
                    to="/call-center/member-profile">
                    Profile
                  </Link>
                </div>
                <div>
                  <Link
                    className={styles.unActiveLink}
                    to="/call-center/redeem-catalogue">
                    Redeem Catalogue
                  </Link>
                </div>
                <div>
                  <Link
                    className={styles.unActiveLink}
                    to="/call-center/transaction-details">
                    Transaction details
                  </Link>
                </div>
                <div>
                  <Link
                    className={styles.unActiveLink}
                    to="/call-center/member-query-record">
                    Member Query Record
                  </Link>
                </div>
                <div>
                  <Link
                    className={styles.unActiveLink}
                    to="/call-center/log-query">
                    Log Query
                  </Link>
                </div>
                <div>
                  <Link
                    className={styles.unActiveLink}
                    to="/call-center/add-family-card">
                    Add Family Card
                  </Link>
                </div>
              </div>
            </div>
            <div className={styles.profileTextContainer}>
              <p className={styles.profileText}>Profile</p>
              <div className={styles.profilePictureContainer}>
                <img src={wole} alt="profile" />
                <p>Profile Picture</p>
              </div>
            </div>
            <form action="" className={styles.profileForm}>
              <div className={styles.inputContainer}>
                <div className={styles.inputInnerContainer}>
                  <label htmlFor="First name">First Name</label>
                  <input
                    type="text"
                    placeholder="First name"
                    value={data.First_name}
                  />
                </div>
                <div className={styles.inputInnerContainer}>
                  <label htmlFor="Middle Name">Middle Name</label>
                  <input
                    type="text"
                    placeholder="Middle Name"
                    value={data.Middle_name}
                  />
                </div>
                <div className={styles.inputInnerContainer}>
                  <label htmlFor="Last Name">Last Name</label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={data.Last_name}
                  />
                </div>
              </div>

              <div className={styles.inputNextContainer}>
                <div className={styles.inputInnerContainer}>
                  <label htmlFor="Address">Address</label>
                  <input
                    type="text"
                    placeholder="Address"
                    value={data.Address}
                  />
                </div>
                <div className={styles.inputInnerContainer}>
                  <label htmlFor="Email Address">Email Address</label>
                  <input
                    type="email"
                    placeholder="Email"
                    value={data.User_Email_id}
                  />
                </div>
                <div className={styles.inputInnerContainer}>
                  <label htmlFor="Phone Number">Phone Number</label>
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    value={data.Phone_no}
                  />
                </div>
              </div>

              <div className={styles.inputNextContainer}>
                <div className={styles.inputInnerContainer}>
                  <label htmlFor="Total Purchase">Total Purchase</label>
                  <input
                    type="text"
                    placeholder="Total Purchase"
                    value={data.Total_purchase_amount}
                  />
                </div>
                <div className={styles.inputInnerContainer}>
                  <label htmlFor="Enrollment Date">Enrollment Date</label>
                  <input
                    type="date"
                    placeholder="Enrollment Data"
                    value={data.Enroll_date}
                  />
                </div>
                <div className={styles.inputInnerContainer}>
                  <label htmlFor="Birth Date">Birth Date</label>
                  <input
                    type="text"
                    placeholder="Birth Date"
                    value={data.Birth_date}
                  />
                </div>
              </div>

              <div className={styles.inputNextContainer}>
                <div className={styles.selectedInfo}>
                  <label htmlFor="Member Status">Member Status</label>
                  <input
                    type="text"
                    placeholder="Active"
                    value={data.Active_flag}
                    disabled
                    className={styles.MemberStatus}
                  />
                </div>
                <div className={styles.selectedInfoAdjust}>
                  <label htmlFor="Member Tier">Member Tier</label>
                  <input
                    type="text"
                    placeholder="Member Tier"
                    value={data.Tier_id}
                  />
                </div>
              </div>

              <div className={styles.inputNextContainer}>
                <div className={styles.selectedInfo}>
                  <label htmlFor="Member Tier">Customer Status</label>
                  <RadioButton options={option2} />
                </div>
                <div className={styles.selectedInfoAdjust}>
                  <label htmlFor="Enable_wallet">Receive Communication</label>
                  <RadioButton options={option1} />
                </div>
              </div>

              <div className={styles.inputNextContainer}>
                <div className={styles.selectedInfo}>
                  <label htmlFor="Member Tier">
                    Select Information to Send to Member
                  </label>
                  <Dropdown />
                </div>
                <div className={styles.selectedInfoAdjust}>
                  <label htmlFor="Enable_wallet">Enable wallet</label>
                  <RadioButton options={option1} />
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <button className={styles.submit}>Submit</button>
                <button
                  className={styles.reset}
                  type="button"
                  onClick={() => setClearModal(true)}>
                  Reset
                </button>
              </div>
            </form>
            {/* Pass your clear input function to clearInputFn */}
            <ClearInputModal
              //   clearInputFn={handleReset}
              clearModalStatus={clearModal}
              clearInputModalState={setClearModal}
            />
          </div>
          <div className={styles.pointsRecieved}>
            <p>Total Points Received</p>
            <div className={styles.tableContainer}>
              <TableModule data={pointsRecieved} />
            </div>
          </div>
          <div className={styles.pointsRecieved}>
            <p>Total Points Used</p>
            <div className={styles.tableContainer}>
              <TableModule data={pointsUsed} />
            </div>
          </div>
          <div className={styles.pointsRecieved}>
            <p>Customer Family Member(s)</p>
            <div className={styles.tableContainer}>
              <TableModule data={familyLists} />
            </div>
          </div>
        </div>
      </CartStructure>
    </>
  );
};

export default Profile;
