import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/CompanyDashboardGraphSetup.module.scss';
import CheckBox from '../../component/CheckBox/CheckBox';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import ColorPicker from '../../component/ColorPicker/ColorPicker';
import SingleRadioButton from '../../component/SingleRadioButton/SingleRadioButton';
import Dropdown from '../../component/DropDown/DropDown';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CompanyDashboardGraphSetup = () => {
  const [clearModal, setClearModal] = useState(false);
  const [dashboardColor, setDashboardColor] = useState('#ffffff');
  const [dashboardColor1, setDashboardColor1] = useState('#fff000');

  return (
    <div>
      <CallCenterStructure>
        <div className={styles.companyDashboardGraphSetup}>
          <div className={styles.headerText}>
            <p>Company Dashboard Graph Setup</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.setupContainer}>
              <div className={styles.setupInnerContainer}>
                <div className={styles.setupMainContainer}>
                  <div className={styles.tableTop}>
                    <div className={styles.leftContainer}>
                      <p>Select Graph</p>
                    </div>
                    <div className={styles.rightContainer}>
                      <div className={styles.innerLeftContainer}>
                        <p>Set bar/line Color : </p>
                      </div>
                      <div className={styles.innerRighttContainer}>
                        <p>Set Dimension</p>
                      </div>
                    </div>
                  </div>
                  <div className={styles.tableBody}>
                    <div className={styles.bodyLeftContainer}>
                      <div className={styles.checkboxContainer}>
                        <CheckBox />
                        <p>Member Enrollment Graph</p>
                      </div>
                      <div className={styles.radioContainer}>
                        <RadioButtonBlue firstText="Bar" secondText="Line" />
                      </div>
                    </div>
                    <div className={styles.rightContainer}>
                      <div className={styles.innerLeftTable}>
                        <div className={styles.colorContainer}>
                          <p className={styles.hexColor}>{dashboardColor}</p>
                          <ColorPicker
                            graphColor={dashboardColor}
                            setGraphColor={setDashboardColor}
                          />
                        </div>
                        <div className={styles.colorContainer}>
                          <p className={styles.hexColor}>{dashboardColor1}</p>
                          <ColorPicker
                            graphColor={dashboardColor1}
                            setGraphColor={setDashboardColor1}
                          />
                        </div>
                      </div>
                      <div className={styles.innerRightTable}>
                        <RadioButtonBlue firstText="2D" secondText="3D" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.tableBody}>
                    <div className={styles.bodyLeftContainer}>
                      <div className={styles.checkboxContainer}>
                        <CheckBox />
                        <p>Active Vs Inactive Members Graph</p>
                      </div>
                      <div className={styles.radioContainer}>
                        <SingleRadioButton firstText="Pie" />
                      </div>
                    </div>
                    <div className={styles.rightContainer}>
                      <div className={styles.innerLeftTable}>
                        {/* <div className={styles.colorContainer}>
                      <p className={styles.hexColor}>{dashboardColor}</p>
                      <ColorPicker
                        graphColor={dashboardColor}
                        setGraphColor={setDashboardColor}
                      />
                    </div>
                    <div className={styles.colorContainer}>
                      <p className={styles.hexColor}>{dashboardColor1}</p>
                      <ColorPicker
                        graphColor={dashboardColor1}
                        setGraphColor={setDashboardColor1}
                      />
                    </div> */}
                      </div>
                      <div className={styles.innerRightTable}>
                        <RadioButtonBlue firstText="2D" secondText="3D" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.tableBody}>
                    <div className={styles.bodyLeftContainer}>
                      <div className={styles.checkboxContainer}>
                        <CheckBox />
                        <p>
                          Total Points Issued vs Total Points Redeemed Graph
                        </p>
                      </div>
                      <div className={styles.radioContainer}>
                        <RadioButtonBlue firstText="Bar" secondText="Line" />
                      </div>
                    </div>
                    <div className={styles.rightContainer}>
                      <div className={styles.innerLeftTable}>
                        <div className={styles.colorContainer}>
                          <p className={styles.hexColor}>{dashboardColor}</p>
                          <ColorPicker
                            graphColor={dashboardColor}
                            setGraphColor={setDashboardColor}
                          />
                        </div>
                        <div className={styles.colorContainer}>
                          <p className={styles.hexColor}>{dashboardColor1}</p>
                          <ColorPicker
                            graphColor={dashboardColor1}
                            setGraphColor={setDashboardColor1}
                          />
                        </div>
                      </div>
                      <div className={styles.innerRightTable}>
                        <RadioButtonBlue firstText="2D" secondText="3D" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.tableBody}>
                    <div className={styles.bodyLeftContainer}>
                      <div className={styles.checkboxContainer}>
                        <CheckBox />
                        <p>
                          Total Number of Points to Expire & to be Forfeited
                          Graph
                        </p>
                      </div>
                      <div className={styles.radioContainer}>
                        <SingleRadioButton firstText="Bar" />
                      </div>
                    </div>
                    <div className={styles.rightContainer}>
                      <div className={styles.innerLeftTable}>
                        <div className={styles.colorContainer}>
                          <p className={styles.hexColor}>{dashboardColor}</p>
                          <ColorPicker
                            graphColor={dashboardColor}
                            setGraphColor={setDashboardColor}
                          />
                        </div>
                        <div className={styles.colorContainer}>
                          <p className={styles.hexColor}>{dashboardColor1}</p>
                          <ColorPicker
                            graphColor={dashboardColor1}
                            setGraphColor={setDashboardColor1}
                          />
                        </div>
                      </div>
                      <div className={styles.innerRightTable}>
                        <RadioButtonBlue firstText="2D" secondText="3D" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.tableBody}>
                    <div className={styles.bodyLeftContainer}>
                      <div className={styles.checkboxContainer}>
                        <CheckBox />
                        <p>Total Redemptions by Merchandise Category Graph</p>
                      </div>
                      <div className={styles.radioContainer}>
                        <SingleRadioButton firstText="Pie" />
                      </div>
                    </div>
                    <div className={styles.rightContainer}>
                      <div className={styles.innerLeftTable}>
                        {/* <div className={styles.colorContainer}>
                      <p className={styles.hexColor}>{dashboardColor}</p>
                      <ColorPicker
                        graphColor={dashboardColor}
                        setGraphColor={setDashboardColor}
                      />
                    </div>
                    <div className={styles.colorContainer}>
                      <p className={styles.hexColor}>{dashboardColor1}</p>
                      <ColorPicker
                        graphColor={dashboardColor1}
                        setGraphColor={setDashboardColor1}
                      />
                    </div> */}
                      </div>
                      <div className={styles.innerRightTable}>
                        <RadioButtonBlue firstText="2D" secondText="3D" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.tableBody}>
                    <div className={styles.bodyLeftContainer}>
                      <div className={styles.checkboxContainer}>
                        <CheckBox />
                        <p>Total Redemptions by Region Graph</p>
                      </div>
                      <div className={styles.radioContainer}>
                        <RadioButtonBlue firstText="Bar" secondText="Line" />
                      </div>
                    </div>
                    <div className={styles.rightContainer}>
                      <div className={styles.innerLeftTable}>
                        <div className={styles.colorContainer}>
                          <p className={styles.hexColor}>{dashboardColor}</p>
                          <ColorPicker
                            graphColor={dashboardColor}
                            setGraphColor={setDashboardColor}
                          />
                        </div>
                        {/* <div className={styles.colorContainer}>
                      <p className={styles.hexColor}>{dashboardColor1}</p>
                      <ColorPicker
                        graphColor={dashboardColor1}
                        setGraphColor={setDashboardColor1}
                      />
                    </div> */}
                      </div>
                      <div className={styles.innerRightTable}>
                        <RadioButtonBlue firstText="2D" secondText="3D" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.tableBody}>
                    <div className={styles.bodyLeftContainer}>
                      <div className={styles.checkboxContainer}>
                        <CheckBox />
                        <p>Open Vs Closed Ticket Graph</p>
                      </div>
                      <div className={styles.radioContainer}>
                        <SingleRadioButton firstText="Pie" />
                      </div>
                    </div>
                    <div className={styles.rightContainer}>
                      <div className={styles.innerLeftTable}>
                        {/* <div className={styles.colorContainer}>
                      <p className={styles.hexColor}>{dashboardColor}</p>
                      <ColorPicker
                        graphColor={dashboardColor}
                        setGraphColor={setDashboardColor}
                      />
                    </div>
                    <div className={styles.colorContainer}>
                      <p className={styles.hexColor}>{dashboardColor1}</p>
                      <ColorPicker
                        graphColor={dashboardColor1}
                        setGraphColor={setDashboardColor1}
                      />
                    </div> */}
                      </div>
                      <div className={styles.innerRightTable}>
                        <RadioButtonBlue firstText="2D" secondText="3D" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.tableBody}>
                    <div className={styles.bodyLeftContainer}>
                      <div className={styles.checkboxContainer}>
                        <CheckBox />
                        <p>Call Center Ticket Status Bar Graph</p>
                      </div>
                      <div className={styles.radioContainer}>
                        <SingleRadioButton firstText="Bar" />
                      </div>
                    </div>
                    <div className={styles.rightContainer}>
                      <div className={styles.innerLeftTable}>
                        <div className={styles.colorContainer}>
                          <p className={styles.hexColor}>{dashboardColor}</p>
                          <ColorPicker
                            graphColor={dashboardColor}
                            setGraphColor={setDashboardColor}
                          />
                        </div>
                        <div className={styles.colorContainer}>
                          <p className={styles.hexColor}>{dashboardColor1}</p>
                          <ColorPicker
                            graphColor={dashboardColor1}
                            setGraphColor={setDashboardColor1}
                          />
                        </div>
                      </div>
                      <div className={styles.innerRightTable}>
                        <RadioButtonBlue firstText="2D" secondText="3D" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.tableBody}>
                    <div className={styles.bodyLeftContainer}>
                      <div className={styles.checkboxContainer}>
                        <CheckBox />
                        <p>Total Points Received</p>
                      </div>
                      <div className={styles.radioContainer}>
                        <SingleRadioButton firstText="Line" />
                      </div>
                    </div>
                    <div className={styles.rightContainer}>
                      <div className={styles.innerLeftTable}>
                        {/* <div className={styles.colorContainer}>
                      <p className={styles.hexColor}>{dashboardColor}</p>
                      <ColorPicker
                        graphColor={dashboardColor}
                        setGraphColor={setDashboardColor}
                      />
                    </div>
                    <div className={styles.colorContainer}>
                      <p className={styles.hexColor}>{dashboardColor1}</p>
                      <ColorPicker
                        graphColor={dashboardColor1}
                        setGraphColor={setDashboardColor1}
                      />
                    </div> */}
                      </div>
                      <div className={styles.innerRightTable}>
                        <RadioButtonBlue firstText="2D" secondText="3D" />
                      </div>
                    </div>
                  </div>
                  <div className={styles.tableBody}>
                    <div className={styles.bodyLeftContainer}>
                      <div className={styles.checkboxContainer}>
                        <CheckBox />
                        <p>Total Points Used</p>
                      </div>
                      <div className={styles.radioContainer}>
                        <SingleRadioButton firstText="Line" />
                      </div>
                    </div>
                    <div className={styles.rightContainer}>
                      <div className={styles.innerLeftTable}>
                        {/* <div className={styles.colorContainer}>
                      <p className={styles.hexColor}>{dashboardColor}</p>
                      <ColorPicker
                        graphColor={dashboardColor}
                        setGraphColor={setDashboardColor}
                      />
                    </div>
                    <div className={styles.colorContainer}>
                      <p className={styles.hexColor}>{dashboardColor1}</p>
                      <ColorPicker
                        graphColor={dashboardColor1}
                        setGraphColor={setDashboardColor1}
                      />
                    </div> */}
                      </div>
                      <div className={styles.innerRightTable}>
                        <RadioButtonBlue firstText="2D" secondText="3D" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.belowTable}>
                <div className={styles.dropdownInputColor}>
                  <label htmlFor="">
                    Select Background color for all graphs
                  </label>
                  <div className={styles.inputColorPicker}>
                    {dashboardColor}
                    <ColorPicker
                      graphColor={dashboardColor}
                      setGraphColor={setDashboardColor}
                    />
                  </div>
                </div>
                <div className={styles.dropdownInput}>
                  <label htmlFor="">Select Theme for graph</label>
                  <Dropdown />
                </div>
                <div className={styles.dropdownInput}>
                  <label htmlFor="">Select Theme for graph</label>
                  <Dropdown />
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <button className={styles.submit}>Submit</button>
                <button
                  className={styles.reset}
                  type="button"
                  onClick={() => setClearModal(true)}>
                  Reset
                </button>
              </div>
            </div>
          </div>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default CompanyDashboardGraphSetup;
