import React, {useState} from 'react';
import {ChevronDownTemplate, ChevronUpTemplate} from '../../assets/icons';
import styles from '../../styles/Category.module.scss';

const Category = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMenu, setIsMenu] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleMenu = () => {
    setIsMenu(!isMenu);
  };

  return (
    <div className={styles.Category}>
      <ul className={styles.innerCategory}>
        <li>
          <div className={styles.dropdown}>
            <div className={styles.dropdownTitle} onClick={toggleMenu}>
              <p>Shop</p>
              {isMenu ? <ChevronUpTemplate /> : <ChevronDownTemplate />}
            </div>
            {isMenu && (
              <ul className={styles.dropdownOptions}>
                <li>My Profile</li>
                <li>Logout</li>
              </ul>
            )}
          </div>
        </li>
        <li className={styles.dropdownTitle}>Airtime & Bills</li>
        <li className={styles.dropdownTitle}>Meals</li>
        <li>
          <div className={styles.dropdown}>
            <div className={styles.dropdownTitle} onClick={toggleDropdown}>
              <p>Entertainment</p>
              {isOpen ? <ChevronUpTemplate /> : <ChevronDownTemplate />}
            </div>
            {isOpen && (
              <ul className={styles.dropdownOptions}>
                <li>My Profile</li>
                <li>Logout</li>
              </ul>
            )}
          </div>
        </li>
        <li className={styles.dropdownTitle}>Vouchers</li>
      </ul>
    </div>
  );
};

export default Category;
