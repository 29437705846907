//import React, {useState} from 'react';
import {SearchIcon} from '../../assets/icons';
import styles from '../../styles/SearchInput.module.scss';

const SearchInput = ({ onSearchInputChange }) => {
  const handleInputChange = (event) => {
    const value = event.target.value; 
    console.log(value)   
    onSearchInputChange(value);
  };


  return (
    <div className={styles.searchBarContainer}>
      <div className={styles.searchField}>
        <div className={styles.searchIconContainer}>
          <SearchIcon />
        </div>
        <input
          onChange={handleInputChange}
          type="text"
          className={styles.searchInput}
          placeholder="Insert your text here"
        />
      </div>
    </div>
  );
};

export default SearchInput;

// import React, { useState } from 'react';
// import styles from '../../styles/SearchInput.module.scss';

// const SearchInput = ({ onSearch }) => {
//   const [searchValue, setSearchValue] = useState('');

//   const handleInputChange = (event) => {
//     setSearchValue(event.target.value);
//   };

//   const handleSearch = () => {
//     if (onSearch) {
//       onSearch(searchValue);
//     }
//   };

//   return (
//     <div>
//       <input
//         type="text"
//         className={styles.input}
//         value={searchValue}
//         onChange={handleInputChange}
//       />
//       <button className={styles.button} onClick={handleSearch}>
//         Search
//       </button>
//     </div>
//   );
// };

// export default SearchInput;

// import React from 'react';
// import { useQuery } from 'react-query';
// import SearchInput from './SearchInput';

// const ParentComponent = () => {
//   const handleSearch = (searchQuery) => {
//     // Use React Query's useQuery hook to fetch data from the API
//     const { data, isLoading, isError } = useQuery(['search', searchQuery], () =>
//       // Your API request logic goes here
//       fetch(`https://api.example.com/search?q=${searchQuery}`).then((response) => response.json())
//     );

//     // Handle the data, loading, and error states as needed
//     if (isLoading) {
//       // Show loading state
//     } else if (isError) {
//       // Show error state
//     } else {
//       // Show the fetched data
//     }
//   };

//   return (
//     <div>
//       <SearchInput onSearch={handleSearch} />
//     </div>
//   );
// };

// export default ParentComponent;
