import React, {useEffect, useRef, useState} from 'react';
import Footer from '../../component/Footer/Footer';
import Navbar from '../../component/NavBar/NavBar';
import Sidebar from '../../component/SideBar/SideBar';
import styles from '../../styles/CallCenterStructure.module.scss';

const CallCenterStructure = ({children}) => {
  //RESIZING SIDEBAR STATE
  const [isNav, setIsNav] = useState(false);

  // SIDEBAR INDICATOR STATES

  const [state, setState] = useState({
    dashboard: true,
    callCenter: false,
    masterSetup: false,
    enrolment: false,
    definedMaster: false,
  });

  const [dropwDownValue, setDropwDownValue] = useState({
    value1: false,
    value2: false,
  });

  const [definedMasterDropdown, setDefinedMasterDropdown] = useState({
    value1: false,
    value2: false,
    value3: false,
    value4: false,
    value5: false,
    value6: false,
    value7: false,
    value8: false,
    value9: false,
  });

  // SIDEBAR INDICATOR FUNCTIONS

  const handleAllState = key => {
    localStorage.setItem('navbarState', key);

    setState(prevState => ({
      ...prevState,
      dashboard: false,
      callCenter: false,
      masterSetup: false,
      enrolment: false,
      definedMaster: false,
      [key]: true,
    }));
  };

  const handleDefinedMasterValue = key => {
    localStorage.setItem('definedMasterState', key);
    setDefinedMasterDropdown(prev => ({
      ...prev,
      value1: false,
      value2: false,
      value3: false,
      value4: false,
      value5: false,
      value6: false,
      value7: false,
      value8: false,
      value9: false,
      [key]: true,
    }));
  };

  const handleAllValues = key => {
    localStorage.setItem('values', key);
    setDropwDownValue(prev => ({
      ...prev,
      value1: false,
      value2: false,
      [key]: true,
    }));
  };

  // GETTING STORED VALUES FROM LOCAL STORAGE

  const mainValue = localStorage.getItem('values');
  const storedState = localStorage.getItem('navbarState');
  const definedMaster = localStorage.getItem('definedMasterState');

  // useEffect FOR ALL SIDEBAR INDICATOR FUNCTION

  useEffect(() => {
    handleAllValues(mainValue);
  }, [mainValue]);

  useEffect(() => {
    handleDefinedMasterValue(definedMaster);
  }, [definedMaster]);

  useEffect(() => {
    const defaultState = storedState || 'dashboard';
    if (defaultState !== 'callCenter') {
      setDropwDownValue({
        value1: false,
        value2: false,
      });
    }
    handleAllState(defaultState);
  }, [storedState]);

  ////////////////////////////////////////

  const toggleIsNav = () => {
    setIsNav(!isNav);
  };

  // console.log(state, ' state');

  return (
    <>
      <div className={styles.parentContainer}>
        <div className={isNav ? styles.sidebarConAdjust : styles.sidebarCon}>
          <Sidebar
            handleAllState={handleAllState}
            state={state}
            isNav={isNav}
            handleAllValues={handleAllValues}
            dropwDownValue={dropwDownValue}
            handleDefinedMasterValue={handleDefinedMasterValue}
            definedMasterDropdown={definedMasterDropdown}
          />
        </div>
        <div className={isNav ? styles.navbarConAdjust : styles.navbarCon}>
          <Navbar
            toggleIsNav={toggleIsNav}
            isNav={isNav}
            state={state}
            handleAllState={handleAllState}
            handleAllValues={handleAllValues}
            dropwDownValue={dropwDownValue}
          />
          <div className={styles.Dashboard}>{children}</div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default CallCenterStructure;
