import React, {useState} from 'react';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';
import Dropdown from '../../component/DropDown/DropDown';
import ReIssuanceModal from '../../component/ReIssuanceModal/ReIssuanceModal';
import styles from '../../styles/RegenerationOfMembershipID.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';

const RegenerationOfMembershipID = () => {
  const [clearModal, setClearModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const onClose = () => {
    setShowModal(false);
  };
  const tabelData = [
    {
      id: 1,
      iteration: 'Iteration-1',
      number: '2147483647',
      date: 'July 24, 2025',
    },
    {
      id: 2,
      iteration: 'Iteration-1',
      number: '2147483647',
      date: 'July 24, 2025',
    },
    {
      id: 3,
      iteration: 'Iteration-1',
      number: '2147483647',
      date: 'July 24, 2025',
    },
    {
      id: 4,
      iteration: 'Iteration-1',
      number: '2147483647',
      date: 'July 24, 2025',
    },
  ];
  return (
    <CallCenterStructure>
      <div className={styles.regenerationOfMembershipID}>
        <div className={styles.headerText}>
          <p>Re-Issuance/Regeneration of Membership ID</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputField1}>
            <div className={styles.innerInputContainer1}>
              <label htmlFor="">
                Select Company<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.innerInputContainer1}>
              <label htmlFor="">Membership Generation</label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.inputField2}>
            <div className={styles.innerInputContainer}>
              <label htmlFor="">
                Total No. of Membership Cards Left<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.innerInputContainer}>
              <label htmlFor="">Last Number Of Membership Card</label>
              <input type="text" />
            </div>
            <div className={styles.innerInputContainer}>
              <label htmlFor="">Enter Next Limit Of Cards</label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Generate</button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.tableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Iterations</th>
                    <th>Number of Membership Cards Issued</th>
                    <th>Date of Membership ID regeneration</th>
                  </tr>
                </thead>
                <tbody>
                  {tabelData.map(each => (
                    <tr>
                      <td>{each.id}</td>
                      <td>{each.iteration}</td>
                      <td>{each.number}</td>
                      <td>{each.date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.downloadBtn}>
              <button
                className={styles.PDFbtn}
                onClick={() => setShowModal(true)}>
                Get Details
              </button>
              {/* <button className={styles.XLSXbtn}>Download as xlsx</button> */}
            </div>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {showModal && <ReIssuanceModal onClose={onClose} />}
      </div>
    </CallCenterStructure>
  );
};

export default RegenerationOfMembershipID;
