import React from 'react';
import {Times} from '../../assets/icons';
import styles from '../../styles/Modal.module.scss';

const Modal = ({name, onClose, data, modalRef}) => {
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal} ref={modalRef}>
        <div className={styles.modalHeader}>
          <h3 className={styles.modalTitle}>{name}</h3>
          <span className={styles.closeIcon} onClick={onClose}>
            <Times />
          </span>
        </div>
        <div className={styles.modalContent}>
          <div className={styles.modalInnerContent}>
            <div className={styles.itemName}>
              <p>ORDER NUMBER</p>
            </div>
            <div className={styles.itemValue}>
              <p>{data[0].ORDERNUMBER}</p>
            </div>
          </div>
          <div className={styles.modalInnerContent}>
            <div className={styles.itemName}>
              <p>DATE</p>
            </div>
            <div className={styles.itemValue}>
              <p>{data[0].DATE}</p>
            </div>
          </div>
          <div className={styles.modalInnerContent}>
            <div className={styles.itemName}>
              <p>ITEM NAME</p>
            </div>
            <div className={styles.itemValue}>
              <p>{data[0].ITEMNAME}</p>
            </div>
          </div>
          <div className={styles.modalInnerContent}>
            <div className={styles.itemName}>
              <p>ITEM CODE</p>
            </div>
            <div className={styles.itemValue}>
              <p>{data[0].ITEMCODE}</p>
            </div>
          </div>
          <div className={styles.modalInnerContent}>
            <div className={styles.itemName}>
              <p>QUANTITY</p>
            </div>
            <div className={styles.itemValue}>
              <p>{data[0].Quantity}</p>
            </div>
          </div>
          <div className={styles.modalInnerContent}>
            <div className={styles.itemName}>
              <p>POINTS REDEEMED PER ITEM</p>
            </div>
            <div className={styles.itemValue}>
              <p>{data[0].POINTSREDEEMEDPERITEM}</p>
            </div>
          </div>
          <div className={styles.modalInnerContent}>
            <div className={styles.itemName}>
              <p>TOTAL POINTS REDEEMED</p>
            </div>
            <div className={styles.itemValue}>
              <p>{data[0].TOTALPOINTSREDEEMED}</p>
            </div>
          </div>
          <div className={styles.modalInnerContent}>
            <div className={styles.itemName}>
              <p>REDEMPTION TYPE</p>
            </div>
            <div className={styles.itemValue}>
              <p>{data[0].REDEMPTIONTYPE}</p>
            </div>
          </div>
          <div className={styles.modalInnerContent}>
            <div className={styles.itemName}>
              <p>VOUCHER NO.</p>
            </div>
            <div className={styles.itemValue}>
              <p>{data[0].VOUCHERNO}</p>
            </div>
          </div>
          <div className={styles.modalInnerContent}>
            <div className={styles.itemName}>
              <p>PARTNER NAME</p>
            </div>
            <div className={styles.itemValue}>
              <p>{data[0].PARTNERNAME}</p>
            </div>
          </div>
          <div className={styles.modalInnerContent}>
            <div className={styles.itemName}>
              <p>PARTNER ADDRESS</p>
            </div>
            <div className={styles.itemValue}>
              <p>{data[0].PARTNERADDRESS}</p>
            </div>
          </div>
          <div className={styles.modalInnerContent}>
            <div className={styles.itemName}>
              <p>REMARK</p>
            </div>
            <div className={styles.itemValue}>
              <p>{data[0].REMARK}</p>
            </div>
          </div>
          <div className={styles.modalInnerContent}>
            <div className={styles.itemName}>
              <p>STATUS</p>
            </div>
            <div className={styles.itemValue}>
              <p>{data[0].STATUS}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
