import React, {useState, useEffect} from 'react';
import Dropdown from '../../component/DropDown/DropDown';
import styles from '../../styles/AssignMembershipID.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import {Headers, CustomerEnrollmentHeaders} from '../../service/Essentials';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';
import {useNavigate} from 'react-router-dom';

const AssignMembershipID = () => {
  const [branch, setBranch] = useState('');
  const [branchID, setBranchID] = useState('');
  const [userID, setUserID] = useState('');
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState(null);
  //const [data, setData] = useState(null);

  const handleBranchID = id => {
    setBranchID(id);
  };

  const handleBranch = name => {
    setBranch(name);
  };

  const navigate = useNavigate();

  //useEffect(()=>{assignMembershipIDCustomer()}, [navigate]);

  const assignMembershipIDCustomer = async () => {
    try {
      const res = await axiosProperty.put(
        ApiConfig.fetchAssignMembershipID(userID),
        {},
        {
          headers: CustomerEnrollmentHeaders(),
        },
      );

      if (
        res.data.message == 'Membership ID Already Exists' ||
        res.data.message == 'Member Do Not Exist'
      ) {
        swal({
          title: 'Warning!',
          text: res.data.message,
          icon: 'warning',
        });
        return;
      }
      //  setData(res.data);
      setBranch('');
      setBranchID('');
      setUserID('');
      // swal({
      //   title: "Success!",
      //   text: "Membership ID assigned successfully",
      //   icon: "success",
      // });
      setErrors(null);
      navigate('/customer-enrolment/assign-membershipID-list', {
        state: res.data,
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data);
        swal({
          title: 'error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, try again');
        swal({
          title: 'error!',
          text: error,
          icon: 'error',
        });
      }
      // console.log(err);
    }
  };

  return (
    <CallCenterStructure>
      <div className={styles.assignMembershipIDContainer}>
        <div className={styles.headerText}>
          <p>Assign Membership ID</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldInnerContainer}>
              <label htmlFor="">
                Branch<sup>*</sup>
              </label>
              <Dropdown
              header={Headers()}
                api={ApiConfig.fetchCompanyBranch}
                onSelect={handleBranchID}
                onSelectOption={handleBranch}
                column={'branch_name'}
                key="branch"
              />
            </div>
            <div className={styles.fieldInnerContainer}>
              <label htmlFor="">
                Enter User Name / Phone No. / User Email-ID<sup>*</sup>
                {/* (without country code or ‘0’) */}
              </label>
              <input
                value={userID}
                onChange={e => setUserID(e.target.value)}
                type="text"
              />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button
              className={styles.next}
              onClick={() => assignMembershipIDCustomer(userID)}>
              Search
            </button>
          </div>
          <ErrorMessage errMsg={errors} />
        </div>
      </div>
    </CallCenterStructure>
  );
};

export default AssignMembershipID;
