import React, {useState} from 'react';
import styles from '../../styles/SetupCustomerSmartphonePageLayout.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import wole from '../../assets/images/wole.jpeg';
import styles1 from '../../styles/RadioButtonBlue.module.scss';
import Dropdown from '../../component/DropDown/DropDown';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
import RadioButton from '../../component/SingleRadioButton/SingleRadioButton';
import defaultImage from '../../assets/images/defaultImage.png';
import CheckBox from '../../component/CheckBox/CheckBox';
import {Times, ViewPen} from '../../assets/icons';
const SetupCustomerSmartphonePageLayout = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [selectedImageName1, setSelectedImageName1] = useState('');
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [selectedImageName2, setSelectedImageName2] = useState('');
  const [selectedImage3, setSelectedImage3] = useState(null);
  const [selectedImageName3, setSelectedImageName3] = useState('');
  const [selectedImage4, setSelectedImage4] = useState(null);
  const [selectedImageName4, setSelectedImageName4] = useState('');
  const [selectedImage8, setSelectedImage8] = useState(null);
  const [selectedImageName8, setSelectedImageName8] = useState('');
  const [selectedImage9, setSelectedImage9] = useState(null);
  const [selectedImageName9, setSelectedImageName9] = useState('');
  const [selectedImage10, setSelectedImage10] = useState(null);
  const [selectedImageName10, setSelectedImageName10] = useState('');
  const [selectedImage11, setSelectedImage11] = useState(null);
  const [selectedImageName11, setSelectedImageName11] = useState('');
  const [selectedImage12, setSelectedImage12] = useState(null);
  const [selectedImageName12, setSelectedImageName12] = useState('');
  const [selectedImage13, setSelectedImage13] = useState(null);
  const [selectedImageName13, setSelectedImageName13] = useState('');
  const [selectedImage14, setSelectedImage14] = useState(null);
  const [selectedImageName14, setSelectedImageName14] = useState('');
  const [selectedImage15, setSelectedImage15] = useState(null);
  const [selectedImageName15, setSelectedImageName15] = useState('');
  const [selectedImage16, setSelectedImage16] = useState(null);
  const [selectedImageName16, setSelectedImageName16] = useState('');
  const [selectedImage17, setSelectedImage17] = useState(null);
  const [selectedImageName17, setSelectedImageName17] = useState('');
  const [selectedImage18, setSelectedImage18] = useState(null);
  const [selectedImageName18, setSelectedImageName18] = useState('');

  // Options Logics

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  const [selectedOption4, setSelectedOption4] = useState(null);
  const [selectedOption5, setSelectedOption5] = useState(null);
  const [selectedOption6, setSelectedOption6] = useState(null);
  const [selectedOption7, setSelectedOption7] = useState(null);
  const [selectedOption8, setSelectedOption8] = useState(null);
  const [selectedOption9, setSelectedOption9] = useState(null);
  const [selectedOption10, setSelectedOption10] = useState(null);
  const [selectedOption11, setSelectedOption11] = useState(null);
  const [selectedOption12, setSelectedOption12] = useState(null);
  const [selectedOption13, setSelectedOption13] = useState(null);
  const [selectedOption14, setSelectedOption14] = useState(null);
  const [selectedOption15, setSelectedOption15] = useState(null);
  const [selectedOption16, setSelectedOption16] = useState(null);
  const [selectedOption17, setSelectedOption17] = useState(null);
  const [selectedOption18, setSelectedOption18] = useState(null);
  const [primaryColor, setPrimaryColor] = useState('#628768');
  const [secondaryColor, setSecondaryColor] = useState('#628766');
  const [footerColor, setFooterColor] = useState('#628762');
  const handleOptionChange = option => {
    setSelectedOption(option);
  };
  const handleOptionChange1 = option => {
    setSelectedOption1(option);
  };
  const handleOptionChange2 = option => {
    setSelectedOption2(option);
  };
  const handleOptionChange3 = option => {
    setSelectedOption3(option);
  };
  const handleOptionChange4 = option => {
    setSelectedOption4(option);
  };
  const handleOptionChange5 = option => {
    setSelectedOption5(option);
  };
  const handleOptionChange8 = option => {
    setSelectedOption8(option);
  };

  const handleOptionChange9 = option => {
    setSelectedOption9(option);
  };
  const handleOptionChange10 = option => {
    setSelectedOption10(option);
  };
  const handleOptionChange11 = option => {
    setSelectedOption11(option);
  };
  const handleOptionChange12 = option => {
    setSelectedOption12(option);
  };
  const handleOptionChange13 = option => {
    setSelectedOption13(option);
  };
  const handleOptionChange14 = option => {
    setSelectedOption14(option);
  };
  const handleOptionChange15 = option => {
    setSelectedOption15(option);
  };
  const handleOptionChange16 = option => {
    setSelectedOption16(option);
  };
  const handleOptionChange17 = option => {
    setSelectedOption17(option);
  };
  const handleOptionChange18 = option => {
    setSelectedOption18(option);
  };
  const options1 = [{color: '#628698'}, {color: '#8D8298'}, {color: '#AAB628'}];
  const options2 = [
    {color: '#628698'},
    {color: '#8D8298'},
    {color: '#AAB628'},
    {color: '#ABB628'},
  ];
  const options3 = [{color: '#628668'}];

  const options = [
    {label: 'Square with Rounded Corners -1', img: wole},
    {label: 'Circle', img: wole},
    {label: 'Square with Rounded Corners -2', img: wole},
    {label: 'Horizontal Rectangle', img: wole},
    {label: 'Square & Rectangle - 1', img: wole},
    {label: 'Square & Rectangle - 2', img: wole},
    {label: 'Hexagone', img: wole},
  ];

  const options4 = ['Yes', 'No'];
  const tableData = [
    {
      id: 4,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Active',
      groupName1: 'Active',
      groupName2: 'Active',
      groupName3: 'Inactive',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 3,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Inactive',
      groupName1: 'Active',
      groupName2: 'Active',
      groupName3: 'Inactive',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 9,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Active',
      groupName1: 'Active',
      groupName2: 'Active',
      groupName3: 'Inactive',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 6,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Active',
      groupName1: 'Active',
      groupName2: 'Active',
      groupName3: 'Inactive',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
  ];

  return (
    <div>
      <CallCenterStructure>
        <div className={styles.SetupCustomerSmartphonePageLayout}>
          <div className={styles.headerText}>
            <p>Setup Customer Smartphone Page Layout</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field1}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select Font Family to Apply<sup>*</sup>
                </label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select Font size to Apply<sup>*</sup>
                </label>
                <Dropdown />
              </div>
            </div>
            <div className={styles.fieldColor1}>
              <div className={styles.fieldColorContainer1}>
                <label htmlFor="">
                  Choose Primary Color<sup>*</sup>
                </label>
                <div className={styles.colorHousing}>
                  <input
                    type="color"
                    name="primary"
                    id=""
                    value={primaryColor}
                    onChange={e => setPrimaryColor(e.target.value)}
                  />
                  <p>{primaryColor}</p>
                </div>
              </div>
              <div className={styles.fieldColorContainer1}>
                <label htmlFor="">
                  Choose Secondary Color<sup>*</sup>
                </label>
                <div className={styles.colorHousing}>
                  <input
                    type="color"
                    name="secondary"
                    id=""
                    value={secondaryColor}
                    onChange={e => setSecondaryColor(e.target.value)}
                  />
                  <p>{secondaryColor}</p>
                </div>
              </div>
              <div className={styles.fieldColorContainer1}>
                <label htmlFor="">
                  Choose Footer Color<sup>*</sup>
                </label>
                <div className={styles.colorHousing}>
                  <input
                    type="color"
                    name="footer"
                    id=""
                    value={footerColor}
                    onChange={e => setFooterColor(e.target.value)}
                  />
                  <p>{footerColor}</p>
                </div>
              </div>
            </div>

            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Show Loyalty Program Logo<sup>*</sup>
                </label>
                <RadioButton
                  options={options4}
                  onChange={setSelectedOption5}
                  selectedOption={selectedOption5}
                />
              </div>
            </div>
          </div>
          <div className={styles.customerMenus}>
            <div className={styles.customerMenusHeader}>
              <p>Customer Menus</p>
            </div>
            <div className={styles.customerMenusInnerContainer}>
              <div className={styles.customerMenusMainContainer}>
                <div className={styles.menu}>
                  <div className={styles.menuLeft}>
                    <CheckBox />
                    <p>Airtime & Bills</p>
                  </div>
                  <div className={styles.menuLeft}>
                    <CheckBox />
                    <p>Vouchers</p>
                  </div>
                  <div className={styles.menuRight}>
                    <CheckBox />
                    <p>Events</p>
                  </div>
                </div>
                <div className={styles.menu}>
                  <div className={styles.menuLeft}>
                    <CheckBox />
                    <p>Meals</p>
                  </div>
                  <div className={styles.menuLeft}>
                    <CheckBox />
                    <p>Movies</p>
                  </div>
                  <div className={styles.menuRight}>
                    <CheckBox />
                    <p>Surveys</p>
                  </div>
                </div>

                <div className={styles.menu}>
                  <div className={styles.menuLeft}>
                    <CheckBox />
                    <p>Sweepstakes</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Submit</button>
            <button className={styles.reset}>Preview</button>
          </div>
          <div className={styles.tableSection}>
            <div className={styles.innerTableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Template Name</th>
                    <th>Template Type</th>
                    <th>Background Color</th>
                    <th>Primary Color</th>
                    <th>Font Family</th>
                    <th>Preview</th>
                    <th>Click to Deactivate</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr key={each.id}>
                      <td>{each.name}</td>
                      <td>{each.code}</td>
                      <td>{each.itemName}</td>
                      <td>
                        {each.groupName === 'Active' ? (
                          <p className={styles.active}>{each.groupName}</p>
                        ) : (
                          <p className={styles.inactive}>{each.groupName}</p>
                        )}
                      </td>
                      <td>
                        {each.groupName1 === 'Active' ? (
                          <p className={styles.active}>{each.groupName1}</p>
                        ) : (
                          <p className={styles.inactive}>{each.groupName1}</p>
                        )}
                      </td>
                      <td>
                        {each.groupName2 === 'Active' ? (
                          <p className={styles.active}>{each.groupName2}</p>
                        ) : (
                          <p className={styles.inactive}>{each.groupName2}</p>
                        )}
                      </td>

                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <Times />
                          </span>
                        </div>
                      </td>

                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default SetupCustomerSmartphonePageLayout;
