import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/PoSInventoryMaster.module.scss';
import Dropdown from '../../component/DropDown/DropDown';
import SearchInput from '../../component/SearchInput/SearchInput';
import {Times, ViewPen} from '../../assets/icons';

const PoSInventoryMaster = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const onClose = () => {
    setDeleteModal(false);
  };

  const tableData = [
    {
      id: 4,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Granny Nuts Natural Almond',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 3,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Granny Nuts Natural Almond',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 9,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Granny Nuts Natural Almond',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 6,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Granny Nuts Natural Almond',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    ,
  ];
  return (
    <div>
      <CallCenterStructure>
        <div className={styles.PoSInventoryMaster}>
          <div className={styles.headerText}>
            <p>PoS Inventory Master</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field1}>
              <div className={styles.fieldContainer}>
                <label htmlFor="">Enter Item Code</label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Enter item Name<sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Select Product Group<sup>*</sup>
                </label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Select Product brand<sup>*</sup>
                </label>
                <Dropdown />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Select Item Type (for Points Award)<sup>*</sup>
                </label>
                <Dropdown />
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <button className={styles.submitBTN}>Submit</button>
            </div>
          </div>
          <div className={styles.tableSection}>
            <div className={styles.searchInputContainer}>
              <div className={styles.searchInput}>
                <SearchInput />
              </div>
              <button className={styles.searchBTN}>Search</button>
            </div>
            <div className={styles.innerTableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Company Name</th>
                    <th>Item Code</th>
                    <th>Item Name</th>
                    <th>Product Group Name</th>
                    <th>Product Brand Name</th>
                    <th>Item Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr key={each.id}>
                      <td>{each.name}</td>
                      <td>{each.code}</td>
                      <td>{each.itemName}</td>
                      <td>{each.groupName}</td>
                      <td>{each.brandName}</td>
                      <td>{each.price}</td>

                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>
                          <span
                            className={styles.icon}
                            onClick={() => setDeleteModal(true)}>
                            <Times />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default PoSInventoryMaster;
