import React from 'react';
import {Link} from 'react-router-dom';
import Category from '../../component/Category/Category';
import TemplateFooter from '../../component/TemplateFooter/TemplateFooter';
import TemplateNavbar from '../../component/TemplateNavbar/TemplateNavbar';
import styles from '../../styles/AirtimeAndBills.module.scss';

const AirtimeAndBills = () => {
  return (
    <div className={styles.AirtimeAndBillsContainer}>
      <TemplateNavbar />
      <Category />
      <div className={styles.AirtimeAndBillsContent}>
        <h1 className={styles.headerText}>Stay Connected</h1>
        <div className={styles.AirtimeAndBillsInnerContent}>
          <div className={styles.AirtimeAndBillsLeft}>
            <div className={styles.AirtimeAndBillsBTN}>
              <p>Airtime & Data</p>
            </div>
            <div className={styles.BRTBTN}>
              <p>BRT</p>
            </div>
          </div>
          <div className={styles.AirtimeAndBillsRight}>
            <h6>Airtime & Data</h6>
            <div className={styles.airtimeCardContainer}>
              <Link
                className={styles.checkoutLink}
                to="/airtime-checkout-template">
                <div className={styles.airtimeContainer}>
                  <div className={styles.airtel}>
                    <p>Airtel</p>
                  </div>
                  <p className={styles.airtimeText}>Airtel</p>
                </div>
              </Link>
              <Link
                className={styles.checkoutLink}
                to="/airtime-checkout-template">
                <div className={styles.airtimeContainer}>
                  <div className={styles.glo}>
                    <p>Glo</p>
                  </div>
                  <p className={styles.airtimeText}>Glo</p>
                </div>
              </Link>
              <Link
                className={styles.checkoutLink}
                to="/airtime-checkout-template">
                <div className={styles.airtimeContainer}>
                  <div className={styles.mtn}>
                    <p>MTN</p>
                  </div>
                  <p className={styles.airtimeText}>MTN</p>
                </div>
              </Link>
              <Link
                className={styles.checkoutLink}
                to="/airtime-checkout-template">
                <div className={styles.airtimeContainer}>
                  <div className={styles.mobile9}>
                    <p>9mobile</p>
                  </div>
                  <p className={styles.airtimeText}>9mobile</p>
                </div>
              </Link>
            </div>
            <div className={styles.brtContainer}>
              <p className={styles.brtText}>BRT</p>
              <div className={styles.airtimeCardContainer}>
                <Link
                  className={styles.checkoutLink}
                  to="/airtime-checkout-template">
                  <div className={styles.airtimeContainer}>
                    <div className={styles.brt}>
                      <p>BRT</p>
                    </div>
                    <p className={styles.airtimeText}>BRT</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TemplateFooter />
    </div>
  );
};

export default AirtimeAndBills;
