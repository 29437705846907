import React, {useState} from 'react';
import Dropdown from '../../component/DropDown/DropDown';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
import styles from '../../styles/CustomerEnrolmentAtBranch.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import defaultImage from '../../assets/images/defaultImage.png';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import RadioButton from '../../component/SingleRadioButton/SingleRadioButton';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import { Headers } from '../../service/Essentials';
import { CustomerEnrollmentHeaders } from '../../service/Essentials';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CustomerEnrolmentAtBranch = () => {
  const [clearModal, setClearModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedComm, setSelectedComm] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [bday, setBday] = useState('');
  const [address, setAddress] = useState('');
  const [profession, setProfession] = useState('');
  const [email, setEmail] = useState('');
  const [membershipID, setMembershipID] = useState('');
  const [loyaltyP, setLoyaltyP] = useState('');
  const [loyaltyPID, setLoyaltyPID] = useState('');
  const [anniversary, setanniversary] = useState('');

  const [currencyID, setCurrencyID] = useState(1);
  const [regionID, setRegionID] = useState('');
  const [stateID, setStateID] = useState('');
  const [cityID, setCityID] = useState('');
  const [zoneID, setZoneID] = useState('');
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [zone, setZone] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');

  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState(null);
  const queryClient = useQueryClient();

  const handleGender = option => {
    setSelectedGender(option);
  };

  const handleEmployee = option => {
    setSelectedEmployee(option);
  };

  const handleComm = option => {
    setSelectedComm(option);
  };

  const handleCurrencyID = id => {
    setCurrencyID(id);
  };

  const handleRegionID = id => {
    setRegionID(id);
  };

  const handleStateID = id => {
    setStateID(id);
  };
  const handleCityID = id => {
    setCityID(id);
  };

  const handleZoneID = id => {
    setZoneID(id);
  };

  const handleCountry = name => {
    setCountry(name);
  };
  const handleRegion = name => {
    setRegion(name);
  };
  const handleZone = name => {
    setZone(name);
  };
  const handleState = name => {
    setState(name);
  };
  const handleCity = name => {
    setCity(name);
  };

  const handleLoyaltyPID = id => {
    setLoyaltyPID(id);
  };

  const handleLoyaltyP = name => {
    setLoyaltyP(name);
  };

  const gender = ['Male', 'Female'];

  const employee = ['Yes', 'No'];

  const communication = ['Yes', 'No'];

  const formData = {
    First_name: firstName,
    Last_name: lastName,
    Middle_name: middleName,
    Country: currencyID,
    Region: regionID,
    Zone: zoneID,
    State: stateID,
    City: cityID,
    Phone_no: phone,
    Sex: selectedGender,
    Profession: profession,
    email: email,
    Membership_id: membershipID,
    Password: password,
    Birth_date: bday,
    Anniversary: anniversary,
    loyalty_programme: loyaltyPID,
    Photograph: selectedImage,
  };

  const addEnrolmentBranch = async () => {
    try {
     const res = await axiosProperty.post(ApiConfig.createEnrolmentBranch, formData, {
        headers: CustomerEnrollmentHeaders(),
      });
     if(res.data.status == 'Failed!'){
        swal({
          title: 'error!',
          text: res.data.message,
          icon: 'error',
        });
     }else{
      setFirstName('');
      setLastName('');
      setMiddleName('');
      setAddress('');
      setProfession('');
      setCountry('');
      setCurrencyID('');
      setRegion();
      setZone('');
      setState('');
      setCity('');
      setRegionID();
      setZoneID('');
      setStateID('');
      setCityID('');
      setanniversary('');
      setPassword('');
      setPhone('');
      setEmail('');
      setLoyaltyP('');
      setLoyaltyPID('');
      setSelectedImage(null);
      setSelectedImageName('');
      setMembershipID('');
      setBday('');
      swal({
        title: 'Success!',
        text: 'Customer Enrolment Branch created successfully',
        icon: 'success',
      });
      setErrors(null);
    }
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data);
        swal({
          title: 'error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, try again');
        swal({
          title: 'error!',
          text: error,
          icon: 'error',
        });
      }
      console.log(err);
    }
  };

  const {mutate: addEnrolmentBranchMutation} = useMutation({
    mutationFn: addEnrolmentBranch,
    //   onSuccess:()=>{
  });

  return (
    <CallCenterStructure>
      <div className={styles.customerEnrolmentContainer}>
        <div className={styles.headerText}>
          <p>Customer Enrolment at Branch</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldInputContainer1}>
            <div className={styles.fieldInputInnerContainer1}>
              <label htmlFor="">
                First Name<sup>*</sup>
              </label>
              <input
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                type="text"
              />
            </div>

            <div className={styles.fieldInputInnerContainer1}>
              <label htmlFor="">
                Middle Name<sup>*</sup>
              </label>
              <input
                value={middleName}
                onChange={e => setMiddleName(e.target.value)}
                type="text"
              />
            </div>
          </div>

          <div className={styles.fieldInputContainer2}>
            <div className={styles.fieldInputInnerContainer1}>
              <label htmlFor="">
                Last Name<sup>*</sup>
              </label>
              <input
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                type="text"
              />
            </div>
            <div className={styles.fieldInputInnerContainer1}>
              <label htmlFor="">
                Email ID<sup>*</sup>
              </label>
              <input
                value={email}
                onChange={e => setEmail(e.target.value)}
                type="text"
              />
            </div>
          </div>

          <div className={styles.fieldInputContainer2}>
            <div className={styles.fieldInputInnerContainer1}>
              <label htmlFor="">
                Mobile Number<sup>*</sup> (without country code)
              </label>
              <input
                value={phone}
                onChange={e => setPhone(e.target.value)}
                type="text"
              />
            </div>
            <div className={styles.fieldInputInnerContainer1}>
              <label htmlFor="">
                Password<sup>*</sup>
              </label>
              <input
                value={password}
                onChange={e => setPassword(e.target.value)}
                type="text"
              />
            </div>
          </div>

          <div className={styles.fieldInputContainer3}>
            <label htmlFor="">
              Address<sup></sup>
            </label>
            <input
              value={address}
              onChange={e => setAddress(e.target.value)}
              type="text"
            />
          </div>
          <div className={styles.itemInputContainer4}>
            <div className={styles.defaultImageContainer}>
              <div className={styles.imageContainer}>
                {selectedImage ? (
                  <img src={selectedImage} alt="default" />
                ) : (
                  <img src={defaultImage} alt="default" />
                )}
              </div>
              <p>Logo</p>
            </div>

            <div className={styles.uploadContainer}>
              <div className={styles.uploadInputContainer}>
                <label htmlFor="">Upload</label>
                <ImageDragAndDrop
                  selectedImageName={selectedImageName}
                  setSelectedImageName={setSelectedImageName}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldInputContainer4}>
            <div className={styles.dropDownContainer}>
              <label htmlFor="">
                Select Country<sup>*</sup>
              </label>
              <Dropdown
                api={ApiConfig.fetchCountry}
                onSelect={handleCurrencyID}
                onSelectOption={handleCountry}
                retrievedValue={currencyID}
                column={'name'}
                key="country"
              />
            </div>
            <div className={styles.dropDownContainer}>
              <label htmlFor="">
                Select Region<sup>*</sup>
              </label>
              <Dropdown
                api={ApiConfig.fetchRegion(currencyID)}
                onSelect={handleRegionID}
                onSelectOption={handleRegion}
                retrievedValue={regionID}
                column={'name'}
                key="region"
              />
            </div>
            <div className={styles.dropDownContainer}>
              <label htmlFor="">
                Select Zone<sup>*</sup>
              </label>
              <Dropdown
                api={ApiConfig.fetchZone(regionID)}
                onSelect={handleZoneID}
                onSelectOption={handleZone}
                retrievedValue={zoneID}
                column={'name'}
                key="zone"
              />
            </div>
            <div className={styles.dropDownContainer}>
              <label htmlFor="">
                Select State<sup>*</sup>
              </label>
              <Dropdown
                api={ApiConfig.fetchState(zoneID)}
                onSelect={handleStateID}
                onSelectOption={handleState}
                retrievedValue={stateID}
                column={'name'}
                key="state"
              />
            </div>
            <div className={styles.dropDownContainer}>
              <label htmlFor="">
                Select City<sup>*</sup>
              </label>
              <Dropdown
                api={ApiConfig.fetchCity(stateID)}
                onSelect={handleCityID}
                onSelectOption={handleCity}
                retrievedValue={cityID}
                column={'name'}
                key="city"
              />
            </div>
          </div>
          <div className={styles.fieldInputContainer2}>
            <div className={styles.fieldInputInnerContainer1}>
              <label htmlFor="">
                Date of Birth<sup>*</sup>
              </label>
              <input
                value={bday}
                type="date"
                onChange={e => setBday(e.target.value)}
              />
            </div>
            <div className={styles.fieldInputInnerContainer1}>
              <label htmlFor="">
                Anniversary<sup>*</sup>
              </label>
              <input
                value={anniversary}
                type="date"
                onChange={e => setanniversary(e.target.value)}
              />
            </div>
          </div>
          <div>
            <div className={styles.checkBoxContainer}>
              <div className={styles.checkBoxInnerContainer}>
                <label htmlFor="">Gender</label>
                {/* <RadioButtonBlue firstText="Male" secondText="Female" /> */}
                <RadioButton
                  options={gender}
                  selectedOption={selectedGender}
                  onChange={handleGender}
                />
              </div>
              <div className={styles.checkBoxInnerContainer}>
                <label htmlFor="">Employee</label>
                {/* <RadioButtonBlue firstText="Yes" secondText="No" /> */}
                <RadioButton
                  options={employee}
                  selectedOption={selectedEmployee}
                  onChange={handleEmployee}
                />
              </div>
              <div className={styles.checkBoxInnerContainer}>
                <label htmlFor="">Receive Communication</label>
                {/* <RadioButtonBlue firstText="Yes" secondText="No" /> */}
                <RadioButton
                  options={communication}
                  selectedOption={selectedComm}
                  onChange={handleComm}
                />
              </div>
            </div>
            <div className={styles.fieldInputContainer5}>
              <div className={styles.fieldInputInnerContainer5}>
                <label htmlFor="">Profession</label>
                <input type="text" value={profession} onChange={e => setProfession(e.target.value)} />
              </div>
              <div className={styles.fieldInputInnerContainer5}>
                <label htmlFor="">
                  Loyalty Program<sup>*</sup>
                </label>
                <Dropdown
                header={Headers()}
                api={ApiConfig.fetchLoyaltyProgram}
                onSelect={handleLoyaltyPID}
                onSelectOption={handleLoyaltyP}
                retrievedValue={loyaltyPID}
                column={'program_name'}
                key="loyaltyprogram"
              />              </div>
              <div className={styles.fieldInputInnerContainer5}>
                <label htmlFor="">
                  Membership ID<sup>*</sup>
                </label>
                <input type="text" value={membershipID} onChange={e => setMembershipID(e.target.value)}
 />
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <button
                className={styles.submit}
                onClick={() => addEnrolmentBranchMutation()}>
                Submit
              </button>
              <button
                className={styles.reset}
                type="button"
                onClick={() => setClearModal(true)}>
                Reset
              </button>
            </div>
            <ErrorMessage errMsg={errors} />
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
      </div>
    </CallCenterStructure>
  );
};

export default CustomerEnrolmentAtBranch;
