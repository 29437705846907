import React, {useState} from 'react';
import {useMutation, useQuery} from 'react-query';
// import {useQuery} from 'react-query';
import {Times, ViewPen} from '../../assets/icons';
import CheckBox from '../../component/CheckBox/CheckBox';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';
import Dropdown from '../../component/DropDown/DropDown';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import RadioBlueButton3 from '../../component/RadioBlueButton3/RadioBlueButton3';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import RadioButton from '../../component/SingleRadioButton/SingleRadioButton';
import ApiConfig from '../../service/ApiConfig';
import {getToken} from '../../service/common';

import {
  Headers,
  ProgramID,
  SweepStakeHeaders,
  upgradeHeaders,
} from '../../service/Essentials';
import axiosProperty from '../../service/HttpConfig';
// import axiosProperty from '../../service/HttpConfig';
import styles from '../../styles/CumulativePeriodic.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';

const CumulativePeriodic = () => {
  const [clearModal, setClearModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loyaltyProgramID, setLoyaltyProgramID] = useState(null);
  const [programName, setProgramName] = useState('');
  const [membershipID, setMembershipID] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [campaignName, setCampaignName] = useState('');
  const [toDate, setToDate] = useState('');
  const [branchName, setBranchName] = useState('');
  const [branchID, setBranchID] = useState('');
  const [productGroupName, setProductGroupName] = useState('');
  const [productGroupID, setProductGroupID] = useState('');
  const [transactionName, setTransactionName] = useState('');
  const [transactionID, setTransactionID] = useState('');
  const [productBrandName, setProductBrandName] = useState('');
  const [productBrandID, setProductBrandID] = useState('');
  const [transactionChannelName, setTransactionChannelName] = useState('');
  const [transactionChannelID, setTransactionChannelID] = useState('');
  // const [productCodeName, setProductCodeName] = useState('');
  const [productCode, setProductCode] = useState('');
  const [productCodeName, setProductCodeName] = useState('');
  const [sweepstakeToLinkID, setSweepstakeToLinkID] = useState('');
  const [sweepstakeToLinkTracker, setSweepstakeToLinkTracker] = useState('');
  const [sweepstakeID, setSweepstakeID] = useState('');
  const [sweepstakeName, setSweepstakeName] = useState('');
  const [sweepstakeNo, setSweepstakeNo] = useState('');
  const [cummulativeAmount, setCummulativeAmount] = useState('');
  const [hours, sethours] = useState('');
  const [hoursName, sethoursName] = useState('');
  const [rewardCriteria, setRewardCriteria] = useState('');
  const [rewardCriteriaTracker, setRewardCriteriaTracker] = useState('');
  const [rewardPoint, setRewardPoint] = useState('');
  const [campaignDes, setCampaignDes] = useState('');
  const [rewardTier, setRewardTier] = useState('');
  const [rewardTierTracker, setRewardTierTracker] = useState('');
  const [tierID, setTierID] = useState('');
  const [tierName, setTierName] = useState('');
  const [campaignStatus, setCampaignStatus] = useState('');
  const [campaignStatusTracker, setCampaignStatusTracker] = useState('');
  const [recurringCampaign, setRecurringCampaign] = useState('');
  const [recurringCampaignTracker, setRecurringCampaignTracker] = useState('');
  const [fromHour, setFromHour] = useState('');
  const [fromMins, setFromMins] = useState('');
  const [toHour, setToHour] = useState('');
  const [toMins, setToMins] = useState('');
  const [fromMinsOption, setFromMinsOption] = useState('');
  const [fromHourOption, setFromHourOption] = useState('');
  const [toHourOption, setToHourOption] = useState('');
  const [toMinsOption, setToMinsOption] = useState('');

  const [executiveCriteria, setExecutiveCriteria] = useState('');
  const [checkboxes, setCheckboxes] = useState({
    'Select All': false,
    Jan: false,
    Feb: false,
    Mar: false,
    Apr: false,
    May: false,
    Jun: false,
    Jul: false,
    Aug: false,
    Sep: false,
    Oct: false,
    Nov: false,
    Dec: false,
  });

  const [weekstate, setWeekstate] = useState({
    'Select All': false,
    First: false,
    Second: false,
    Third: false,
    Fouth: false,
    Fifth: false,
  });

  const [days, setDays] = useState({
    'Select All': false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  });

  const hoursTime = Array.from({length: 24}, (_, index) => ({
    valueID: index.length === 1 ? `0${index}` : index.toString(),
    id: index.length === 1 ? `0${index}` : index.toString(),
  }));

  const minsTime = Array.from({length: 60}, (_, index) => ({
    valueID: index.length === 1 ? `0${index}` : index.toString(),
    id: index.length === 1 ? `0${index}` : index.toString(),
  }));

  const formData = {
    Company_id: ProgramID(),
    Membership_id: parseInt(membershipID),
    Loyalty_program_id: parseInt(loyaltyProgramID) || 1,
    Tier_id: parseInt(tierID) || 1,
    Sweepstake_id: parseInt(sweepstakeID),
    Campaign_name: campaignName,
    Sweepstake_ticket_limit: parseInt(sweepstakeNo),
    Campaign_description: campaignDes,
    Campaign_type: 7,
    Campaign_sub_type: 3,
    From_date: fromDate,
    To_date: toDate,
    Tier_flag: rewardTier,
    Reward_percent: '',
    Sweepstake_flag: parseInt(sweepstakeToLinkID) || 3,
    // hours: hours,
    Cumulative_amount: parseInt(cummulativeAmount),
    Product_group_id: parseInt(productGroupID),
    Product_brand_id: parseInt(productBrandID) || 6,
    Transaction_id: parseInt(transactionID),
    Transaction_channel_id: parseInt(transactionChannelID) || 3,
    branch_id: parseInt(branchID),
    campaign_status: campaignStatus,
    Reward_flag: rewardCriteria,
    Reward_points: parseInt(rewardPoint),
    Recuring_campaign_flag: recurringCampaign,
    Jan: checkboxes.Jan ? 1 : 0,
    Feb: checkboxes.Feb ? 1 : 0,
    Mar: checkboxes.Mar ? 1 : 0,
    Apr: checkboxes.Apr ? 1 : 0,
    May: checkboxes.May ? 1 : 0,
    Jun: checkboxes.Jun ? 1 : 0,
    Jul: checkboxes.Jul ? 1 : 0,
    Aug: checkboxes.Aug ? 1 : 0,
    Sep: checkboxes.Sep ? 1 : 0,
    Oct: checkboxes.Oct ? 1 : 0,
    Nov: checkboxes.Nov ? 1 : 0,
    Dec: checkboxes.Dec ? 1 : 0,
    Mon: days.Monday ? 1 : 0,
    Tue: days.Tuesday ? 1 : 0,
    Wed: days.Wednesday ? 1 : 0,
    Thu: days.Thursday ? 1 : 0,
    Fri: days.Friday ? 1 : 0,
    Sat: days.Saturday ? 1 : 0,
    Sun: days.Sunday ? 1 : 0,
    First_week: weekstate.First ? 1 : 0,
    Second_week: weekstate.Second ? 1 : 0,
    Third_week: weekstate.Third ? 1 : 0,
    Fourth_week: weekstate.Fouth ? 1 : 0,
    Start_time: ``,
    End_time: ``,
  };

  //  const formData = {
  //    Company_id: 1,
  //    Membership_id: 7744889000,
  //    Loyalty_program_id: 1,
  //    Tier_id: 1,
  //    Sweepstake_id: 3,
  //    Campaign_name: 'Cumulative Campaign New',
  //    Sweepstake_ticket_limit: 3,
  //    Campaign_description: 'Cumulative Description New',
  //    Campaign_type: 7,
  //    Campaign_sub_type: 3,
  //    From_date: '2024-11-28',
  //    To_date: '2025-12-31',
  //    Tier_flag: 1,
  //    Reward_percent: 0,
  //    Sweepstake_flag: 1,
  //    operator: ':',
  //    Cumulative_amount: 9500,
  //    Product_group_id: 1,
  //    Product_brand_id: 1,
  //    Transaction_id: 1,
  //    Transaction_channel_id: 3,
  //    branch_id: 6,
  //    campaign_status: 'Enabled',
  //    Reward_flag: 1,
  //    Reward_points: 500,
  //    Recuring_campaign_flag: 1,
  //    Jan: 1,
  //    Feb: 0,
  //    Mar: 1,
  //    Apr: 1,
  //    May: 1,
  //    Jun: 0,
  //    Jul: 0,
  //    Aug: 0,
  //    Sep: 1,
  //    Oct: 0,
  //    Nov: 0,
  //    Dec: 0,
  //    Mon: 0,
  //    Tue: 1,
  //    Wed: 1,
  //    Thu: 0,
  //    Fri: 1,
  //    Sat: 0,
  //    Sun: 0,
  //    First_week: 0,
  //    Second_week: 1,
  //    Third_week: 1,
  //    Fourth_week: 0,
  //    Start_time: '',
  //    End_time: '',
  //  };

  // Select Loyalty Program
  const handleLoyaltyProgramID = id => {
    setLoyaltyProgramID(id);
  };

  const handleProgramName = name => {
    setProgramName(name);
  };
  //////////
  // Select Branch
  const handleBranchID = id => {
    setBranchID(id);
  };

  const handleBranchName = name => {
    setBranchName(name);
  };
  ////////

  //Select Product Group
  const handleProductGroupID = id => {
    setProductGroupID(id);
  };

  const handleProductGroupName = name => {
    setProductGroupName(name);
  };
  /////////
  //Transaction Name
  const handleTransactionID = id => {
    setTransactionID(id);
  };

  const handleTransactionName = name => {
    setTransactionName(name);
  };
  /////

  //Transaction Name
  const handleProductBrandID = id => {
    setProductBrandID(id);
  };

  const handleProductBrandName = name => {
    setProductBrandName(name);
  };
  /////

  //Transaction Name
  const handleProductCode = id => {
    setProductCode(id);
  };

  const handleProductCodeName = name => {
    setProductCodeName(name);
  };
  /////

  //Transaction Name
  const handleTransactionChannelID = id => {
    setTransactionChannelID(id);
  };

  const handleTransactionChannelName = name => {
    setTransactionChannelName(name);
  };
  /////
  //Tier Name
  const handleTierID = id => {
    setTierID(id);
  };

  const handleTierName = name => {
    setTierName(name);
  };
  /////
  //Sweepstake
  const handleSweepstakeID = id => {
    setSweepstakeID(id);
  };

  const handleSweepstakeName = name => {
    setSweepstakeName(name);
  };
  /////

  //Sweepstake
  const handlehours = id => {
    sethours(id);
  };

  const handlehoursName = name => {
    sethoursName(name);
  };
  /////

  // Sweepstake link

  const handleSweepstakeToLink = value => {
    setSweepstakeToLinkTracker(value);
    if (value === 'Yes') {
      setSweepstakeToLinkID(1);
    } else {
      setSweepstakeToLinkID(0);
    }
  };

  const handleRewardCriteria = value => {
    setRewardCriteriaTracker(value);
    if (value === 'Yes') {
      setRewardCriteria(1);
    } else {
      setRewardCriteria(0);
    }
  };

  const handleRewardTier = value => {
    setRewardTierTracker(value);
    if (value === 'Yes') {
      setRewardTier(1);
    } else {
      setRewardTier(0);
    }
  };

  const handleRecurringCampaign = value => {
    setRecurringCampaignTracker(value);
    if (value === 'Yes') {
      setRecurringCampaign(1);
    } else {
      setRecurringCampaign(0);
    }
  };

  const handleCampaignStatus = value => {
    setCampaignStatus(value);
  };

  const handleExecutiveCriteria = value => {
    setExecutiveCriteria(value);
    if (value === 'Monthly') {
      setExecutiveCriteria(prev => [{...prev, Monthly: 1}]);
    } else if (value === 'Weekly') {
      setExecutiveCriteria(prev => [{...prev, Weekly: 1}]);
    } else {
      setExecutiveCriteria(prev => [{...prev, Days: 1}]);
    }
  };

  const onClose = () => {
    setDeleteModal(false);
  };

  // const fetchLoyaltyProgramData = async () => {
  //   const response = await axiosProperty.get(ApiConfig.fetchProductCode(3), {
  //     headers: Headers(),
  //   });
  //   return response?.data.data.data;
  // };

  // const {
  //   data: loyaltyProgramData,
  //   isLoading: isLoadingProgram,
  //   isError: isErrorProgram,
  // } = useQuery('fetch-loyal-program', fetchLoyaltyProgramData);

  const fetchProductBrandData = async param => {
    const response = await axiosProperty.get(
      ApiConfig.fetchProductBrandDrop(param),
      {
        headers: Headers(),
      },
    );

    return response?.data.data;
  };

  const {
    data: productBrandData,
    isLoading: isLoadingBrand,
    isError: isErrorBrand,
  } = useQuery(['fetch-product-brand', productGroupID], () =>
    fetchProductBrandData(productGroupID),
  );

  const fetchProductCodeData = async (param1, param2) => {
    const response = await axiosProperty.get(
      ApiConfig.fetchProductCodeDrop(param1, param2),
      {
        headers: Headers(),
      },
    );

    return response?.data.data;
  };

  const {
    data: producCodeData,
    isLoading: isLoadingCode,
    isError: isErrorCode,
  } = useQuery(['fetch-product-code', productBrandID, productGroupID], () =>
    fetchProductCodeData(productGroupID, productBrandID),
  );

  // console.log(producBrandData, producCodeData, 'productBrandData');

  const data = [
    {
      code: '46543',
      name: 'Campaign',
      linked: '376664',
      from: '28/6/2023',
      to: '28/6/2023',
      amount: '756382',
      point: '294',
      percentage: 56,
    },
    {
      code: '46533',
      name: 'Campaign',
      linked: '376664',
      from: '28/6/2023',
      to: '28/6/2023',
      amount: '756382',
      point: '294',
      percentage: 56,
    },
    {
      code: '46523',
      name: 'Campaign',
      linked: '376664',
      from: '28/6/2023',
      to: '28/6/2023',
      amount: '756382',
      point: '294',
      percentage: 56,
    },
    {
      code: '46513',
      name: 'Campaign',
      linked: '376664',
      from: '28/6/2023',
      to: '28/6/2023',
      amount: '756382',
      point: '294',
      percentage: 56,
    },
  ];

  const option1 = ['Yes', 'No'];
  const option2 = ['Enabled', 'Disabled'];
  const option3 = ['Monthly', 'Weekly', 'Days'];

  //CHECKBOX LOGIC

  const handleCheckboxChange = (name, isChecked) => {
    if (name === 'Select All') {
      setCheckboxes(prevCheckboxes => {
        const updatedCheckboxes = {...prevCheckboxes};
        const selectAllValue = updatedCheckboxes[name];
        Object.keys(updatedCheckboxes).forEach(key => {
          updatedCheckboxes[key] = !selectAllValue;
        });
        return updatedCheckboxes;
      });
    } else {
      setCheckboxes(prevCheckboxes => ({
        ...prevCheckboxes,
        [name]: isChecked,
      }));
    }
  };

  const handleWeekCheckbox = (name, isChecked) => {
    if (name === 'Select All') {
      setWeekstate(prev => {
        const updatedCheckboxes = {...prev};
        const selectAllValue = updatedCheckboxes[name];
        Object.keys(weekstate).forEach(key => {
          updatedCheckboxes[key] = !selectAllValue;
        });
        return updatedCheckboxes;
      });
    } else {
      setWeekstate(prev => ({...prev, [name]: isChecked}));
    }
  };

  const handleDaysCheckbox = (name, isChecked) => {
    if (name === 'Select All') {
      setDays(prev => {
        const updatedCheckboxes = {...prev};
        const selectAllValue = updatedCheckboxes[name];
        Object.keys(days).forEach(key => {
          updatedCheckboxes[key] = !selectAllValue;
        });
        return updatedCheckboxes;
      });
    } else {
      setDays(prev => ({...prev, [name]: isChecked}));
    }
  };
  //////////////////////////////
  const handleFromHour = id => {
    setFromHour(id);
  };
  const handleToHour = id => {
    setToHour(id);
  };
  const handleFromMins = id => {
    setFromMins(id);
  };
  const handleToMins = id => {
    setToMins(id);
  };

  const handleFromHourOption = name => {
    setFromHourOption(name);
  };
  const handleFromMinsOption = name => {
    setFromMinsOption(name);
  };
  const handleToHourOption = name => {
    setToHourOption(name);
  };
  const handleToMinsOption = name => {
    setToMinsOption(name);
  };

  //To handle form submit.

  const submitData = async () => {
    try {
      const response = await axiosProperty.post(
        ApiConfig.createCummulativePeriodic,
        formData,
        {
          headers: {
            admin: 1,
            token: process.env.REACT_APP_CAMPAIGN_TOKEN,
          },
        },
      );
      return response.data;
    } catch (error) {
      throw new Error('Failed to submit data');
    }
  };

  const {mutate, isLoading, isSuccess, isError} = useMutation(submitData);

  const handleSubmit = e => {
    e.preventDefault();
    mutate();
  };

  return (
    <CallCenterStructure>
      <div className={styles.cumulativePeriodic}>
        <div className={styles.headerText}>
          <p>Cumulative Periodic Campaign</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Loyalty Programme<sup>*</sup>
              </label>
              <Dropdown
                api={ApiConfig.fetchLoyaltyProgram}
                header={Headers()}
                column="program_name"
                onSelect={handleLoyaltyProgramID}
                onSelectOption={handleProgramName}
                key="program_name"
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Start Date<sup>*</sup>
              </label>
              <input
                type="date"
                name="fromDate"
                value={fromDate}
                onChange={e => setFromDate(e.target.value)}
                id=""
              />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Campaign Name<sup>*</sup>
              </label>
              <input
                type="text"
                name="campaign name"
                value={campaignName}
                onChange={e => setCampaignName(e.target.value)}
                id=""
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                End Date<sup>*</sup>
              </label>
              <input
                type="date"
                name="toDate"
                value={toDate}
                onChange={e => setToDate(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select Branches<sup>*</sup>
              </label>
              <Dropdown
                api={ApiConfig.fetchCompanyBranch}
                header={Headers()}
                onSelect={handleBranchID}
                onSelectOption={handleBranchName}
                column="branch_name"
                key="branches"
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Product Group<sup>*</sup>
              </label>
              <Dropdown
                api={ApiConfig.fetchProductGroup}
                header={Headers()}
                onSelect={handleProductGroupID}
                onSelectOption={handleProductGroupName}
                column="group_name"
                key="product-group"
              />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Transaction Type<sup>*</sup>
              </label>
              <Dropdown
                api={ApiConfig.fetchTransactionType}
                header={Headers()}
                onSelect={handleTransactionID}
                onSelectOption={handleTransactionName}
                column="transaction_name"
                key="transaction-type"
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Product Brand <sup>*</sup>
              </label>
              <Dropdown
                enumType={productBrandData}
                onSelect={handleProductBrandID}
                onSelectOption={handleProductBrandName}
                column="brand_name"
                key="product-brand"
              />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Transaction Channel <sup>*</sup>
              </label>
              <Dropdown
                api={ApiConfig.fetchTransactionChannel}
                header={Headers()}
                onSelect={handleTransactionChannelID}
                onSelectOption={handleTransactionChannelName}
                column="transaction_name"
                key="trasact-channels"
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Product Code<sup>*</sup>
              </label>
              <Dropdown
                enumType={producCodeData}
                onSelect={handleProductCode}
                onSelectOption={handleProductCodeName}
                column="product_name"
                key="product-code"
              />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Membership ID<sup>*</sup>
              </label>
              <input
                type="text"
                value={membershipID}
                name="membershipID"
                onChange={e => setMembershipID(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer3}>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                Sweepstake to Link<sup>*</sup>
              </label>

              <RadioButton
                options={option1}
                selectedOption={sweepstakeToLinkTracker}
                onChange={handleSweepstakeToLink}
              />
            </div>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                Sweepstake<sup>*</sup>
              </label>
              <Dropdown
                api={ApiConfig.fetchActiveSweepStakes}
                header={SweepStakeHeaders()}
                onSelect={handleSweepstakeID}
                onSelectOption={handleSweepstakeName}
                column="name"
                key="sweepstakes"
              />
            </div>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                No. of Sweepstake
                <sup>*</sup>
              </label>
              <input
                type="number"
                name="sweetstakeNo"
                value={sweepstakeNo}
                onChange={e => setSweepstakeNo(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer3}>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                Cumulative Amount<sup>*</sup>
              </label>
              <input
                type="number"
                name="cummulativeAmount"
                value={cummulativeAmount}
                onChange={e => setCummulativeAmount(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                Operator<sup>*</sup>
              </label>
              <Dropdown
                api={ApiConfig.fetchCodeDecodeMaster}
                header={Headers()}
                onSelect={handlehours}
                onSelectOption={handlehoursName}
                column="type_code"
                key="operator"
              />
            </div>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                Reward Criteria<sup>*</sup>
              </label>
              <RadioButton
                options={option1}
                onChange={handleRewardCriteria}
                selectedOption={rewardCriteriaTracker}
              />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Reward<sup>*</sup>
              </label>
              <input
                type="text"
                name="rewardPoint"
                value={rewardPoint}
                onChange={e => setRewardPoint(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Campaign Description<sup>*</sup>
              </label>
              <input
                type="text"
                name="campaignDes"
                value={campaignDes}
                onChange={e => setCampaignDes(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.radioButtonField2}>
              <label htmlFor="">
                Reward based on Tier<sup>*</sup>
              </label>
              <RadioButton
                options={option1}
                onChange={handleRewardTier}
                selectedOption={rewardTierTracker}
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select Tier<sup>*</sup>
              </label>
              <Dropdown
                api={ApiConfig.fetchTierCriteria}
                header={Headers()}
                onSelect={handleTierID}
                onSelectOption={handleTierName}
                column="name"
                key="tier-criteria"
              />
            </div>
          </div>
          <div className={styles.fieldContainer4}>
            <div className={styles.radioButtonField4}>
              <label htmlFor="">
                Campaign Status<sup>*</sup>
              </label>
              <RadioButton
                options={option2}
                selectedOption={campaignStatus}
                onChange={handleCampaignStatus}
              />
            </div>
            <div className={styles.radioButtonField4}>
              <label htmlFor="">
                Recurring Campaign<sup>*</sup>
              </label>
              <RadioButton
                options={option1}
                selectedOption={recurringCampaignTracker}
                onChange={handleRecurringCampaign}
              />
            </div>
          </div>
          <div className={styles.checkbox2}>
            <label htmlFor="">
              Execute Criteria<sup>*</sup>
            </label>
            <RadioButton
              options={option3}
              selectedOption={executiveCriteria}
              onChange={setExecutiveCriteria}
            />
          </div>
          {(executiveCriteria === 'Monthly' || executiveCriteria === '') && (
            <div className={styles.monthsContainer}>
              <label htmlFor="">
                Month<sup>*</sup>
              </label>
              <div className={styles.monthsMainContainer}>
                {Object.keys(checkboxes).map(name => (
                  <div key={name} className={styles.innerMonthsContainer}>
                    <CheckBox
                      name={name}
                      checkbox={checkboxes[name]}
                      onSelect={handleCheckboxChange}
                    />
                    <p className={styles.checkboxText}>{name}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {(executiveCriteria === 'Weekly' || executiveCriteria === '') && (
            <div className={styles.weeksContainer}>
              <label htmlFor="">
                Weeks <sup>*</sup>
              </label>
              <div className={styles.mainWeeksContainer}>
                {Object.keys(weekstate).map(name => (
                  <div className={styles.innerMonthsContainer}>
                    <CheckBox
                      name={name}
                      checkbox={weekstate[name]}
                      onSelect={handleWeekCheckbox}
                    />
                    <p className={styles.checkboxText}>{name}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {(executiveCriteria === 'Days' || executiveCriteria === '') && (
            <div className={styles.daysContainer}>
              <label htmlFor="">
                Days<sup>*</sup>
              </label>
              <div className={styles.mainDaysContainer}>
                {Object.keys(days).map(day => (
                  <div key={day} className={styles.innerDaysContainer}>
                    <CheckBox
                      name={day}
                      checkbox={days[day]}
                      onSelect={handleDaysCheckbox}
                    />
                    <p className={styles.checkboxText}>{day}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className={styles.fieldContainer5}>
            <label htmlFor="">Time (HH:MM)</label>
            <div className={styles.outterFieldContainer5}>
              <div className={styles.innerFieldContainer5}>
                <label htmlFor="">From-HH</label>
                <Dropdown
                  enumType={hoursTime}
                  column="valueID"
                  key="drop1"
                  onSelect={handleFromHour}
                  onSelectOption={handleFromHourOption}
                />
              </div>
              <div className={styles.innerFieldContainer5}>
                <label htmlFor="">From-MM</label>
                <Dropdown
                  enumType={minsTime}
                  column="valueID"
                  key="drop2"
                  onSelect={handleFromMins}
                  onSelectOption={handleFromMinsOption}
                />
              </div>
              <div className={styles.innerFieldContainer5}>
                <label htmlFor="">To-HH</label>
                <Dropdown
                  enumType={hoursTime}
                  column="valueID"
                  key="drop3"
                  onSelect={handleToHour}
                  onSelectOption={handleToHourOption}
                />
              </div>
              <div className={styles.innerFieldContainer5}>
                <label htmlFor="">To-MM</label>
                <Dropdown
                  enumType={minsTime}
                  column="valueID"
                  key="drop4"
                  onSelect={handleToMins}
                  onSelectOption={handleToMinsOption}
                />
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.submit} onClick={handleSubmit}>
              Submit
            </button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Company Code</th>
                  <th>Campaign Name</th>
                  <th>Linked to LBS?</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Cumulative Amount</th>
                  <th>Reward Points</th>
                  <th>Reward Percentage</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map(each => (
                  <tr key={each.code}>
                    <td>{each.code}</td>
                    <td>{each.name}</td>
                    <td>{each.linked}</td>
                    <td>{each.from}</td>
                    <td>{each.to}</td>
                    <td>{each.amount}</td>
                    <td>{each.point}</td>
                    <td>{each.percentage}</td>
                    <td>
                      <div className={styles.iconContainer}>
                        <span className={styles.icon}>
                          <ViewPen />
                        </span>
                        <span
                          className={styles.icon}
                          onClick={() => setDeleteModal(true)}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <p>Pagination</p>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <h6 className={styles.diabledText}>Disabled Campaigns</h6>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Company Code</th>
                  <th>Campaign Name</th>
                  <th>Linked to LBS?</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Cumulative Amount</th>
                  <th>Reward Points</th>
                  <th>Reward Percentage</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map(each => (
                  <tr key={each.code}>
                    <td>{each.code}</td>
                    <td>{each.name}</td>
                    <td>{each.linked}</td>
                    <td>{each.from}</td>
                    <td>{each.to}</td>
                    <td>{each.amount}</td>
                    <td>{each.point}</td>
                    <td>{each.percentage}</td>
                    <td>
                      <div className={styles.iconContainer}>
                        <span className={styles.icon}>
                          <ViewPen />
                        </span>
                        <span
                          className={styles.icon}
                          onClick={() => setDeleteModal(true)}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <p>Pagination</p>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && <MenuDeleteModal onClose={onClose} />}
      </div>
    </CallCenterStructure>
  );
};

export default CumulativePeriodic;
