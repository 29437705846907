import React, {useState, useEffect} from 'react';
import Dropdown from '../../component/DropDown/DropDown';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
import styles from '../../styles/AuctionMaster.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import defaultImage from '../../assets/images/defaultImage.png';
import {Times, ViewPen} from '../../assets/icons';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import {Headers, AuctionHeaders} from '../../service/Essentials';
import swal from 'sweetalert';
import EditAuctionModal from '../../component/EditAuctionModal/EditAuctionModal';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const AuctionMaster = () => {
  const [clearModal, setClearModal] = useState(false);
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [error, setError] = useState('oops... something went wrong');
  const [auctions, setAuctions] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [programID, setProgramID] = useState('');
  const [companyID, setCompanyID] = useState('');
  const [programName, setProgramName] = useState('');
  const [closeTime, setCloseTime] = useState('');
  const [deleteId, setDeleteId] = useState('');
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [eachId, setEachId] = useState(null);

  const onClose = () => {
    setDeleteModal(false);
  };
  const onCloseEdit = () => {
    setEachId(null);
    setEditData({});
    setEditModal(false);
  };

  useEffect(() => {
    const getCompanyId = JSON.parse(sessionStorage.getItem('company'));
    setCompanyID(getCompanyId.id);
    setFormData(prevFormData => ({
      ...prevFormData,
      program_id: getCompanyId.id,
      auction_img_url: selectedImage,
    }));
  }, [selectedImage]);

  const handleProgramID = id => {
    setProgramID(id);
  };

  const handleProgramName = name => {
    setProgramName(name);
    setFormData(prevFormData => ({
      ...prevFormData,
      program: name,
    }));
  };

  const handleHourID = id => {};

  const handleMinuteID = id => {};

  const handleHour = name => {
    setFormData(prevFormData => ({
      ...prevFormData,
      hour: name,
    }));
  };

  const handleMinute = name => {
    setFormData(prevFormData => ({
      ...prevFormData,
      minute: name,
    }));
  };

  const [errors, setErrors] = useState({
    //updated data
    minimum_bid: '',
    start_date: '',
    end_date: '',
    prize_description: '',
    auction_img_url: '',
    prize: '',
    program: '',
    auction_name: '',
    minimum_increment: '',
    close_time: '',
    hour: '',
    minute: '',
    program_id: '',
  });
  const [formData, setFormData] = useState({
    minimum_bid: '',
    start_date: '',
    end_date: '',
    prize_description: '',
    auction_img_url: '',
    prize: '',
    program_id: '',
    program: '',
    auction_name: '',
    minimum_increment: '',
    closeTime: '',
    hour: '',
    minute: '',
  });
  const handleChange = e => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  //for the time format
  useEffect(() => {
    let hour;
    let min;

    if (formData.hour <= 9) {
      hour = `0${formData?.hour}`;
    } else {
      hour = `${formData?.hour}`;
    }
    if (formData.minute <= 9) {
      min = `0${formData?.minute}`;
    } else {
      min = `${formData?.minute}`;
    }

    setCloseTime(`${hour}:${min}`);
  }, [formData.minute, formData.hour, closeTime]);

  const addAuction = async () => {
    try {
      const res = await axiosProperty.post(
        ApiConfig.createAuction,
        {
          minimum_bid: formData?.minimum_bid,
          start_date: formData?.start_date,
          end_date: formData?.end_date,
          prize_description: formData?.prize_description,
          auction_img_url: selectedImage,
          prize: formData?.prize,
          program_id: programID,
          program: programName,
          auction_name: formData?.auction_name,
          minimum_increment: formData?.minimum_increment,
          hour: formData?.hour,
          minute: formData.minute,
          close_time: closeTime,
        },
        {
          headers: AuctionHeaders(),
        },
      );

      setErrors({});
      swal({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: addAuctionMutation} = useMutation({
    mutationFn: addAuction,
  });

  const fetchAuctions = async page => {
    const res = await axiosProperty.get(ApiConfig.fetchAuctions(page), {
      headers: AuctionHeaders(),
    });
    return res.data;
  };

  const {data: auctionData, status} = useQuery(
    ['auctions', page],
    () => fetchAuctions(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setAuctions(auctionData?.data?.data || []);
  }, [auctionData]);

  const editEachAuction = async id => {
    try {
      const res = await axiosProperty.get(ApiConfig.fetchEachBid(id), {
        headers: AuctionHeaders(),
      });
      setEachId(id);
      setEditModal(true);
      return res.data.data;
    } catch (err) {
      setError(err.response);
    }
  };
  const {data: eachAuction} = useQuery(
    ['eachAuction', eachId],
    () => editEachAuction(eachId),
    {keepPreviousData: false},
  );

  useEffect(() => {
    setEditData(eachAuction || {});
  }, [eachAuction]);

  const deleteAuction = async id => {
    try {
      const res = await axiosProperty.get(ApiConfig.deleteAuction(id), {
        headers: AuctionHeaders(),
      });
      setDeleteModal(false);
      swal({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
      queryClient.invalidateQueries('auctions');
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);
      swal({
        title: 'Warning!',
        text: error,
        icon: 'warning',
      });
    }
  };

  var hourArray = [];

  for (var i = 0; i <= 23; i++) {
    hourArray.push({id: i, value: i});
  }

  var minuteArray = [];

  for (var ii = 0; ii <= 59; ii++) {
    minuteArray.push({id: ii, value: ii});
  }
  const handleReset = () => {
    setFormData({
      minimum_bid: '',
      start_date: '',
      end_date: '',
      prize_description: '',
      auction_img_url: '',
      prize: '',
      program_id: '',
      program: '',
      auction_name: '',
      minimum_increment: '',
      closeTime: '',
      hour: '',
      minute: '',
    });
  };

  return (
    <CallCenterStructure>
      <div className={styles.auctionMaster}>
        <div className={styles.headerText}>
          <p>Auction Master</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Loyalty Programme<sup>*</sup>
              </label>
              <Dropdown
                header={Headers()}
                api={ApiConfig.fetchLoyaltyProgram}
                onSelect={handleProgramID}
                onSelectOption={handleProgramName}
                column={'program_name'}
                retrievedValue={formData.program}
                key="programme_name"
              />
              {errors?.program && (
                <p className="text-danger">{errors?.program}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Valid From<sup>*</sup>
              </label>
              <input
                type="date"
                name="start_date"
                onChange={handleChange}
                value={formData.start_date}
              />
              {errors?.start_date && (
                <p className="text-danger">{errors?.start_date}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Valid Till<sup>*</sup>
              </label>
              <input
                type="date"
                name="end_date"
                onChange={handleChange}
                value={formData.end_date}
              />
              {errors?.end_date && (
                <p className="text-danger">{errors?.end_date}</p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer2}>
              <label htmlFor="">
                Auction Close Time (HH)<sup>*</sup>
              </label>
              <Dropdown
                onSelect={handleHourID}
                onSelectOption={handleHour}
                column={'value'}
                retrievedValue={formData.hour}
                key="hour"
                enumType={hourArray}
              />
              {errors?.hour && <p className="text-danger">{errors?.hour}</p>}
            </div>
            <div className={styles.innerFieldContainer2}>
              <label htmlFor="">
                Auction Close Time (MM)<sup>*</sup>
              </label>
              <Dropdown
                onSelect={handleMinuteID}
                onSelectOption={handleMinute}
                column={'value'}
                retrievedValue={formData.minute}
                key="minute"
                enumType={minuteArray}
              />
              {errors?.minute && (
                <p className="text-danger">{errors?.minute}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer2}>
              <label htmlFor="">
                Auction Name<sup>*</sup>
              </label>
              <input
                type="text"
                name="auction_name"
                onChange={handleChange}
                value={formData.auction_name}
              />
              {errors?.auction_name && (
                <p className="text-danger">{errors?.auction_name}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer2}>
              <label htmlFor="">
                Prize<sup>*</sup>
              </label>
              <input
                type="text"
                name="prize"
                onChange={handleChange}
                value={formData.prize}
              />
              {errors?.prize && <p className="text-danger">{errors?.prize}</p>}
            </div>
          </div>
          <div className={styles.fieldContainer3}>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                Minimum Points to Bid<sup>*</sup>
              </label>
              <input
                type="text"
                name="minimum_bid"
                onChange={handleChange}
                value={formData.minimum_bid}
              />
              {errors?.minimum_bid && (
                <p className="text-danger">{errors?.minimum_bid}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                Minimum Increment<sup>*</sup>
              </label>
              <input
                type="text"
                name="minimum_increment"
                onChange={handleChange}
                value={formData.minimum_increment}
              />
              {errors?.minimum_increment && (
                <p className="text-danger">{errors?.minimum_increment}</p>
              )}
            </div>
          </div>
          <div className={styles.itemInputContainer4}>
            <div className={styles.defaultImageContainer}>
              <div className={styles.imageContainer}>
                {selectedImage ? (
                  <img src={selectedImage} alt="default" />
                ) : (
                  <img src={defaultImage} alt="default" />
                )}
              </div>
              <p>Image</p>
            </div>

            <div className={styles.uploadContainer}>
              <div className={styles.uploadInputContainer}>
                <label htmlFor="">Image (maximum size of 100kb)</label>
                <ImageDragAndDrop
                  selectedImageName={selectedImageName}
                  setSelectedImageName={setSelectedImageName}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer4}>
            <label htmlFor="">Prize Description</label>
            <input
              type="text"
              name="prize_description"
              onChange={handleChange}
              value={formData.prize_description}
            />
            {errors?.prize_description && (
              <p className="text-danger">{errors?.prize_description}</p>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <button
              className={styles.submit}
              onClick={e => {
                e.preventDefault();
                addAuctionMutation();
              }}>
              Submit
            </button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Auction Name</th>
                  <th>Prize</th>
                  <th>Description</th>
                  <th>Min Bid Value</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {status === 'error' && (
                  <tr>
                    <td className="text-danger">Error Occured!</td>
                  </tr>
                )}

                {status === 'loading' && (
                  <tr>
                    <td className="text-info">loading...</td>
                  </tr>
                )}

                {status === 'success' &&
                  (auctions.length === 0 ? (
                    <tr>
                      <td>No Auction Created by this Company!</td>
                    </tr>
                  ) : (
                    auctions?.map(each => (
                      <tr key={each.id}>
                        <td>{each.valid_from}</td>
                        <td>{each.valid_until}</td>
                        <td>{each.auction_name}</td>
                        <td>{each.prize}</td>
                        <td>{each.prize_description}</td>
                        <td>{each.minimum_bid}</td>
                        <td>
                          <div className={styles.iconContainer}>
                            <span
                              className={styles.icon}
                              onClick={() => {
                                editEachAuction(each.id);
                              }}>
                              <ViewPen />
                            </span>
                            <span
                              className={styles.icon}
                              onClick={() => {
                                setDeleteId(each.id);
                                setDeleteModal(true);
                              }}>
                              <Times />
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))
                  ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={auctionData} setPage={setPage} />
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && (
          <MenuDeleteModal
            onClose={onClose}
            onDelete={() => deleteAuction(deleteId)}
          />
        )}
        {editModal && (
          <EditAuctionModal onClose={onCloseEdit} data={editData} />
        )}
      </div>
    </CallCenterStructure>
  );
};

export default AuctionMaster;
