import React, {useState, useEffect} from 'react';
import styles from '../../styles/CompanyPartnerBranchMaster.module.scss';

import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
import defaultImage from '../../assets/images/defaultImage.png';
import SearchInput from '../../component/SearchInput/SearchInput';
import {Times, ViewPen} from '../../assets/icons';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import {Headers, RedirectToLogin} from '../../service/Essentials';
import swal from 'sweetalert';
import {ProgramID} from '../../service/Essentials';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CompanyPartnerBranchMaster = () => {
  const [clearModal, setClearModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateID, setUpdateID] = useState('');
  const [selectedItemID, setSelectedItemId] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [countryID, setCountryID] = useState(1);
  const [stateID, setStateID] = useState('');
  const [cityID, setCityID] = useState('');
  const [zoneID, setZoneID] = useState('');
  const [regionID, setRegionID] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [zone, setZone] = useState('');
  const [region, setRegion] = useState('');
  const [partner, setPartner] = useState('');
  const [partnerID, setPartnerID] = useState();
  const [address, setAddress] = useState();
  const [branchCode, setBranchCode] = useState();
  const [branchName, setBranchName] = useState();
  const [receiptNo, setReceiptNo] = useState();
  const [errors, setErrors] = useState(null);
  const [error, setError] = useState('ooops, something went wrong');
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const handleStateID = id => {
    setStateID(id);
  };
  const handleCountryID = id => {
    setCountryID(id);
  };
  const handleCityID = id => {
    setCityID(id);
  };
  const handleRegionID = id => {
    setRegionID(id);
  };
  const handleZoneID = id => {
    setZoneID(id);
  };
  const handlePartnerID = id => {
    setPartnerID(id);
  };
  const handleCountry = name => {
    setCountry(name);
  };
  const handleRegion = name => {
    setRegion(name);
  };
  const handleZone = name => {
    setZone(name);
  };
  const handleState = name => {
    setState(name);
  };
  const handleCity = name => {
    setCity(name);
  };
  const handlePartner = name => {
    setPartner(name);
  };
  const onClose = () => {
    setDeleteModal(false);
  };

  const queryClient = useQueryClient();

  const formData = {
    partner_setup_id: partnerID,
    branch_code: branchCode,
    branch_name: branchName,
    address: address,
    receipt_number_series: 'lsl' + +new Date(),
    country_currency_id: countryID,
    region_id: regionID,
    zone_id: zoneID,
    state_id: stateID,
    city_id: cityID,
  };

  RedirectToLogin();

  const fetchPartnerBranches = async page => {
    const res = await axiosProperty.get(
      ApiConfig.fetchPartnerbranch + `?page=${page}`,
      {
        headers: Headers(),
      },
    );
    return res.data;
  };
  const {data, status} = useQuery(
    ['partnerbranches', page],
    () => fetchPartnerBranches(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = searchTerm => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data?.data) {
      const filtered = data.data.filter(
        (item) =>
          item.branch_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.branch_code.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredData(filtered);
    }
  };

  const addPartnerBranch = async () => {
    try {
      await axiosProperty.post(ApiConfig.createPartnerbranch, formData, {
        headers: Headers(),
      });

      setPartnerID('');
      setPartner('');
      setAddress('');
      setBranchCode('');
      setBranchName('');
      setReceiptNo('');
      setCountryID('');
      setRegionID('');
      setStateID('');
      setCityID('');
      setZoneID('');
      setErrors(null);
      swal({
        title: 'Success!',
        text: 'Partner branch created successfully',
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setErrors(err.response.data);
        setError(err.response.data.message);
      } else {
        setErrors('Something went wrong, try again');
      }
      swal({
        title: 'Error!',
        text: error,
        icon: 'error',
      });
    }
  };

  const {mutate: addPartnerBranchMutation} = useMutation({
    mutationFn: addPartnerBranch,
    onSuccess: () => {
      queryClient.invalidateQueries(['partnerbranches']);
    },
  });

  const updatePartnerBranch = async () => {
    try {
      await axiosProperty.patch(
        ApiConfig.updatePartnerbranch(updateID),
        formData,
        {
          headers: Headers(),
        },
      );
      setPartnerID('');
      setPartner('');
      setAddress('');
      setBranchCode('');
      setBranchName('');
      setReceiptNo('');
      setCountryID('');
      setRegionID('');
      setStateID('');
      setCityID('');
      setZoneID('');
      setErrors(null);
      setIsSubmit(true);
      setIsUpdate(false);
      swal({
        title: 'Success!',
        text: 'Partner branch updated successfully',
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setErrors(err.response.data);
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      swal({
        title: 'Error!',
        text: error,
        icon: 'error',
      });
    }
  };

  const {mutate: updatePartnerBranchMutation} = useMutation({
    mutationFn: updatePartnerBranch,
    onSuccess: () => {
      queryClient.invalidateQueries(['partnerbranches']);
    },
  });

  const editPartnerBranch = async id => {
    try {
      const res = await axiosProperty.get(ApiConfig.editPartnerbranch(id), {
        headers: Headers(),
      });
      setIsUpdate(true);
      setIsSubmit(false);
      setPartnerID(res.data.data.partner_setup_id || '');
      setAddress(res.data.data.address || '');
      setBranchCode(res.data.data.branch_code || '');
      setBranchName(res.data.data.branch_name || '');
      setReceiptNo(res.data.data.receipt_number_series || '');
      setCountryID(res.data.data.country_currency_id || '');
      setRegionID(res.data.data.region_id || '');
      setStateID(res.data.data.state_id || '');
      setCityID(res.data.data.city_id || '');
      setZoneID(res.data.data.zone_id || '');
      setErrors(null);
    } catch (err) {
      if (err.response) {
        setErrors(err.response.data);
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);
      swal({
        title: 'Warning!',
        text: error,
        icon: 'warning',
      });
    }
  };
  const deletePartnerBranch = async id => {
    try {
      const res = await axiosProperty.delete(
        ApiConfig.deletePartnerbranch(id),
        {
          headers: Headers(),
        },
      );
      setDeleteModal(false);
      swal({
        title: 'Success!',
        text: 'Partner branch deleted successfully',
        icon: 'success',
      });
      queryClient.invalidateQueries(['partnerbranches']);
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);
      swal({
        title: 'Warning!',
        text: error,
        icon: 'warning',
      });
      console.log(err);
    }
  };
  return (
    <div>
      <div>
        <CallCenterStructure>
          <div className={styles.CompanyPartnerBranchMaster}>
            <div className={styles.headerText}>
              <p>Company Partner Branch Master</p>
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.field1}>
                <div className={styles.fieldContainer1}>
                  <label htmlFor="">
                    Select Partner<sup>*</sup>
                  </label>
                  <Dropdown header={Headers()} api={ApiConfig.fetchPartnermaster} onSelect={handlePartnerID} onSelectOption={handlePartner} retrievedValue={partnerID} column={'partner_name'} key="partnersetup" />
                </div>
                <div className={styles.fieldContainer1}>
                  <label htmlFor="">
                    Enter Branch Code<sup>*</sup>
                  </label>
                  <input
                    value={branchCode}
                    onChange={e => setBranchCode(e.target.value)}
                    type="text"
                  />
                </div>
                <div className={styles.fieldContainer1}>
                  <label htmlFor="">
                    Enter Branch Name<sup>*</sup>
                  </label>
                  <input
                    value={branchName}
                    onChange={e => setBranchName(e.target.value)}
                    type="text"
                  />
                </div>
              </div>
              <div className={styles.fieldContainer2}>
                <label htmlFor="">Enter Partner Address</label>
                <textarea
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                  name=""
                  id=""
                />
              </div>
              <div className={styles.field3}>
                <div className={styles.fieldContainer1}>
                  <label htmlFor="">
                    Select Country<sup>*</sup>
                  </label>
                  <Dropdown
                    api={ApiConfig.fetchCountry}
                    onSelect={handleCountryID}
                    onSelectOption={handleCountry}
                    retrievedValue={countryID}
                    column={'name'}
                    key="country"
                  />
                </div>
                <div className={styles.fieldContainer1}>
                  <label htmlFor="">
                    Select Region<sup>*</sup>
                  </label>
                  <Dropdown
                    api={ApiConfig.fetchRegion(countryID)}
                    onSelect={handleRegionID}
                    onSelectOption={handleRegion}
                    retrievedValue={regionID}
                    column={'name'}
                    key="region"
                  />
                </div>
                <div className={styles.fieldContainer1}>
                  <label htmlFor="">
                    Select Zone<sup>*</sup>
                  </label>
                  <Dropdown
                    api={ApiConfig.fetchZone(regionID)}
                    onSelect={handleZoneID}
                    onSelectOption={handleZone}
                    retrievedValue={zoneID}
                    column={'name'}
                    key="zone"
                  />
                </div>
              </div>
              <div className={styles.field4}>
                <div className={styles.fieldContainer4}>
                  <label htmlFor="">
                    Select State<sup>*</sup>
                  </label>
                  <Dropdown
                    api={ApiConfig.fetchState(zoneID)}
                    onSelect={handleStateID}
                    onSelectOption={handleState}
                    retrievedValue={stateID}
                    column={'name'}
                    key="state"
                  />
                </div>
                <div className={styles.fieldContainer4}>
                  <label htmlFor="">
                    Select City<sup>*</sup>
                  </label>
                  <Dropdown
                    api={ApiConfig.fetchCity(stateID)}
                    onSelect={handleCityID}
                    onSelectOption={handleCity}
                    retrievedValue={cityID}
                    column={'name'}
                    key="city"
                  />
                </div>
              </div>

              <div className={styles.buttonContainer}>
                {isSubmit && (
                  <button
                    className={styles.submit}
                    onClick={() => addPartnerBranchMutation()}>
                    Submit
                  </button>
                )}
                {isUpdate && (
                  <button
                    className={styles.submit}
                    onClick={() => updatePartnerBranchMutation()}>
                    Update
                  </button>
                )}
                <button
                  className={styles.reset}
                  type="button"
                  onClick={() => setClearModal(true)}>
                  Reset
                </button>
              </div>
              <ErrorMessage errMsg={errors} />
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.searchInputContainer}>
                <div className={styles.searchInput}>
                  <SearchInput onSearchInputChange={handleSearchInputChange} />
                </div>
                <button className={styles.searchBTN}>Search</button>
              </div>
              <div className={styles.tableContainer}>
                <table className={styles.mainTable}>
                  <thead>
                    <tr>
                      <th>Branch ID</th>
                      <th>Branch Code</th>
                      <th>Partner</th>
                      <th>Branch Name</th>
                      <th>Branch Address</th>
                      <th>Country</th>
                      <th>City</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.length > 0 ? (
                      filteredData.map(each => (
                        <tr key={each.id}>
                          <td>{each.id}</td>
                          <td>{each.branch_code}</td>
                          <td>{each.partner_setup_id}</td>
                          <td>{each.address}</td>
                          <td>{each.branch_name}</td>
                          <td>{each.country_currency_id}</td>
                          <td>{each.city_id}</td>
                          <td>
                            <div className={styles.iconContainer}>
                              <span
                                className={styles.icon}
                                onClick={() => {
                                  editPartnerBranch(each.id);
                                  setUpdateID(each.id);
                                }}>
                                <ViewPen />
                              </span>

                              <span
                                className={styles.icon}
                                onClick={() => {
                                  setDeleteModal(true);
                                  setSelectedItemId(each.id);
                                }}>
                                <Times />
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colspan="8">No data available</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className={styles.pagination}>
                {/* <p>Pagination</p> */}
                <Paginate page={page} data={data} setPage={setPage} />
              </div>
            </div>
            {/* Pass your clear input function to clearInputFn */}
            <ClearInputModal
              //   clearInputFn={handleReset}
              clearModalStatus={clearModal}
              clearInputModalState={setClearModal}
            />
            {deleteModal && (
              <MenuDeleteModal
                onClose={onClose}
                onDelete={() => deletePartnerBranch(selectedItemID)}
              />
            )}
          </div>
        </CallCenterStructure>
      </div>
    </div>
  );
};

export default CompanyPartnerBranchMaster;
