import React, {useState} from 'react';
import {ViewPen} from '../../assets/icons';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';
import SearchInput from '../../component/SearchInput/SearchInput';
import styles from '../../styles/CountryCurrencyMasterSetup.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';

const CountryCurrencyMasterSetup = () => {
  const [clearModal, setClearModal] = useState(false);
  const tableData = [
    {
      id: 1,
      country: 'Nigeria',
      currency: 'Nigeria Naira',
      symbol: 'NGN',
      dialCode: '+234',
      rate: '1.00000',
    },
    {
      id: 2,
      country: 'Nigeria',
      currency: 'Nigeria Naira',
      symbol: 'NGN',
      dialCode: '+234',
      rate: '1.00000',
    },
    {
      id: 3,
      country: 'Nigeria',
      currency: 'Nigeria Naira',
      symbol: 'NGN',
      dialCode: '+234',
      rate: '1.00000',
    },
  ];
  return (
    <CallCenterStructure>
      <div className={styles.countryCurrencyContainer}>
        <div className={styles.headerText}>
          <p>Country Currency Master</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputFieldContainer1}>
            <div className={styles.inputFieldInnerContainer1}>
              <label htmlFor="">
                Enter Country Name<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.inputFieldInnerContainer1}>
              <label htmlFor="">
                Enter Currency Name<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.inputFieldInnerContainer1}>
              <label htmlFor="">
                Enter Currency Symbol<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.inputFieldContainer2}>
            <div className={styles.inputFieldInnerContainer2}>
              <label htmlFor="">
                Enter Dial Code<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.inputFieldInnerContainer2}>
              <label htmlFor="">
                Enter Exchange Rate<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Submit</button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>

        <div className={styles.inputContainer}>
          <div>
            <div className={styles.searchInputContainer}>
              <SearchInput />
              <button className={styles.searchInputBtn}>Search</button>
            </div>
            <div className={styles.tableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Country ID</th>
                    <th>Country</th>
                    <th>Currency</th>
                    <th>Currency Symbol</th>
                    <th>Dial Code</th>
                    <th>Exchange Rate</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr key={each.id}>
                      <td>{each.id}</td>
                      <td>{each.country}</td>
                      <td>{each.currency}</td>
                      <td>{each.symbol}</td>
                      <td>{each.dialCode}</td>
                      <td>{each.rate}</td>
                      <td>
                        <span className={styles.icon}>
                          <ViewPen />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.paginationContainer}>
              <p>Pagination</p>
            </div>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
      </div>
    </CallCenterStructure>
  );
};

export default CountryCurrencyMasterSetup;
