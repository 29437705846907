import React, { useEffect, useRef, useState } from "react";
import styles from "../../styles/ApproveAuction.module.scss";
import CallCenterStructure from "../CallCenterStructure/CallCenterStructure";
import axiosProperty from "../../service/HttpConfig";
import ApiConfig from "../../service/ApiConfig";
import { ApproveWinnerHeaders, AuctionHeaders } from "../../service/Essentials";
import { useQuery } from "react-query";
import swal from "sweetalert";
import Paginate from "../../component/Paginate/Paginate";

const ApproveAuction = () => {
  const approveRef = useRef();
  const [page, setPage] = useState(1);
  const [approveBid, setApproveBid] = useState([]);
  const [auctionWinners, setAuctionWinners] = useState([]);
  const [error, setError] = useState("oops... something went wrong");



  const getBidHistory = async (page) => {
    try {
      const response = await axiosProperty.get(
        ApiConfig.fetchHighestBid(page),
        {
          headers: AuctionHeaders(),
        }
      );

      return response.data;
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        swal({
          title: "Error!",
          text: error,
          icon: "error",
        });
      } else {
        setError("Something went wrong, probably network");
        swal({
          title: "warning!",
          text: error,
          icon: "error",
        });
      }
    }
  };
  const { data: bidData, status } = useQuery(
    ["bidhistory", page],
    () => getBidHistory(page),
    { keepPreviousData: true }
  );

  useEffect(() => {
    setApproveBid(bidData?.data || []);
  }, [bidData]);

  const getAuctionWinners = async (page) => {
    try {
      const response = await axiosProperty.get(
        ApiConfig.fetchAuctionWinners(page),
        {
          headers: AuctionHeaders(),
        }
      );

      return response.data;
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        swal({
          title: "Error!",
          text: error,
          icon: "error",
        });
      } else {
        setError("Something went wrong, probably network");
        swal({
          title: "warning!",
          text: error,
          icon: "error",
        });
      }
    }
  };
  const { data: winnersData, status: stat } = useQuery(
    ["auctionWinners", page],
    () => getAuctionWinners(page),
    { keepPreviousData: true }
  );

  useEffect(() => {
    setAuctionWinners(winnersData?.data || []);
  }, [winnersData]);

  const approveWinner = async (id) => {
    try {
      const response = await axiosProperty.get(ApiConfig.approveAuction(id), {
        headers: ApproveWinnerHeaders(),
      });
      setError('');
      swal({
        title: "Success",
        text: response.data.message,
        icon: "success",
      });
      return response.data
      
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        swal({
          title: "Error!",
          text: error,
          icon: "error",
        });
      } else {
        setError("Something went wrong, probably network");
        swal({
          title: "warning!",
          text: error,
          icon: "error",
        });
      }
    }
  };

  

 
  return (
    <CallCenterStructure>
      <div className={styles.auctionMaster}>
        <div className={styles.headerText}>Approve Auction</div>
        <div className={styles.noteText}>
          <p>Click on column header for Ascending/Descending</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <h6 className={styles.infoText}>Auction Winners to be Approved</h6>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Details</th>
                  <th>Auction Name</th>
                  <th>Prize</th>
                  <th>Bid Value</th>
                  <th>Minimum Bid</th>
                  <th>Approve Auction</th>
                </tr>
              </thead>
              <tbody>
                {status === "error" && (
                  <tr>
                    <td className="text-danger">Error Occured!</td>
                  </tr>
                )}

                {status === "loading" && (
                  <tr>
                    <td className="text-info">loading...</td>
                  </tr>
                )}

                {status === "success" &&
                  (approveBid?.length === 0 ? (
                    <tr><td>No Auction Created by this Company!</td></tr>
                  ) : (
                    approveBid?.map((each) => (
                      each.approve_winner === null ?
                      <tr key={each.id}>
                        <td>{each.id}</td>
                        <td>{each.auction_name}</td>
                        <td>{each.prize}</td>
                        <td>{each.current_bid}</td>
                        <td>{each.minimum_bid}</td>
                        <td ref={approveRef}>
                          <span
                            className={styles.approve}
                            onClick={() => approveWinner(each.id)}
                          >
                            Approve
                          </span>
                          <span className={styles.disapprove}>Disapprove</span>
                        </td>
                      </tr> : null
                    ))
                  ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <Paginate page={page} data={bidData} setPage={setPage} />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <h6 className={styles.infoText}>Approved Auction Winners</h6>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Details</th>
                  <th>Auction Name</th>
                  <th>Status</th>
                  <th>Prize</th>
                  <th>Winner</th>
                  <th>Approve/Unapprove</th>
                  
                </tr>
              </thead>
              <tbody>
                {stat === "error" && (
                  <tr>
                    <td className="text-danger">Error Occured!</td>
                  </tr>
                )}

                {stat === "loading" && (
                  <tr>
                    <td className="text-info">loading...</td>
                  </tr>
                )}

                {stat === "success" &&
                  (auctionWinners?.length === 0 ? (
                    <tr><td>No Auction Winner in this Company!</td></tr>
                  ) : (
                    auctionWinners?.map((each) => (
                      <tr key={each.id}>
                        <td>{each.id}</td>
                        <td>{each.auction_name}</td>
                        <td>{each.status}</td>
                        <td>{each.prize}</td>
                        <td>{each.membership_id}</td>
                        
                        <td>
                          <span
                            className={styles.approve}
                          >
                            Approved
                          </span>
                          
                        </td>
                      </tr>
                    ))
                  ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <Paginate page={page} data={approveBid} setPage={setPage} />
          </div>
        </div>
      </div>
    </CallCenterStructure>
  );
};

export default ApproveAuction;
