import React, {useState} from 'react';
// import loginImage from '../../assets/images/login-image.png';
import styles from '../../styles/Login.module.scss';
import PerxLogo from '../../assets/images/PerxBig.png';
//import {ChevronDown, ChevronUp} from '../../assets/icons';
import {Link, useNavigate} from 'react-router-dom';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import {setSession} from '../../service/common';
import {
  CarouselIcon1,
  CarouselIcon2,
  CarouselIcon3,
  CarouselIcon4,
  CarouselIcon5,
  CarouselIcon6,
  CarouselIcon7,
  CarouselIcon8,
  CarouselIcon9,
  CarouselIcon10,
  CarouselIcon11,
} from '../../assets/icons';
import Marquee from 'react-fast-marquee';

export const Login = () => {
  // const [isOpen, setIsOpen] = useState(false);
  // const [selectedOption, setSelectedOption] = useState('');

  // const toggleDropdown = () => {
  //   setIsOpen(!isOpen);
  // };

  // const handleOptionClick = option => {
  //   setSelectedOption(option);
  //   setIsOpen(false);
  // };
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async e => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await axiosProperty.post(ApiConfig.loginApi, {
        email: username,
        password: password,
      });
      setSession(res.data.data.token, res.data.data.user, res.data.data.user_type, res.data.data.user_group, res.data.data.company);
      navigate('/dashboard');
    //  console.log(res.data.data.user);
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, probably network');
      }
      setIsLoading(false);
      console.log(err);
    }
  };

  const carouselList = [
    {name: 'Customizable White Label Platform', icon: <CarouselIcon1 />},
    {name: 'Multi-Currency Capabilities', icon: <CarouselIcon2 />},
    {name: 'Multi-Channel Access', icon: <CarouselIcon3 />},
    {
      name: 'Real-time Integration or Batch File Data Processing',
      icon: <CarouselIcon4 />,
    },
    {name: 'Flexible Loyalty Campaign Configurations', icon: <CarouselIcon5 />},
    {name: 'Tiering & Segmentation', icon: <CarouselIcon6 />},
    {name: 'Liability Management Support', icon: <CarouselIcon7 />},
    {name: 'Integrated & Automated Reporting Suite', icon: <CarouselIcon8 />},
    {name: 'Audit Tracking & Access Management', icon: <CarouselIcon9 />},
    {name: 'Inventory Management', icon: <CarouselIcon10 />},
    {name: 'Dashboard', icon: <CarouselIcon11 />},
  ];

  return (
    <div className={styles.loginContainer}>
      <div className={styles.leftContainer}>
        <div className={styles.formTopLogo}>
          <img src={PerxLogo} alt="perx logo" />
        </div>
        {/* <div className={styles.box}> */}
        {/* <span className={styles.borderline}></span> */}
        <div className={styles.movingBorder}>
          <form className={styles.loginForm} action="">
            <div className={styles.formTop}>
              <h6 className={styles.formHeader}>Hello! let's get started</h6>
              <p className={styles.formParag}>
                Kindly log in using your{' '}
                <span className={styles.companyEmailText}>
                  assigned company email address
                </span>{' '}
                as Username
              </p>
            </div>
            <div className={styles.form1Container}>
              <label htmlFor="Username">Username</label>
              <input
                onChange={e => setUsername(e.target.value)}
                type="text"
                name="username"
                placeholder="Username"
                value={username}
              />
            </div>

            <div className={styles.form2Container}>
              <label htmlFor="Password">Password</label>
              <input
                onChange={e => setPassword(e.target.value)}
                type="password"
                name="password"
                placeholder="Password"
                value={password}
              />
            </div>
            {/* <div className={styles.dropdown}>
              <label htmlFor="Password">Select Company</label>
              <div className={styles.dropdownHeader} onClick={toggleDropdown}>
                {selectedOption ? (
                  <span>{selectedOption}</span>
                ) : (
                  <span className={styles.dropdownPlaceholder}>
                    Select Company
                  </span>
                )}
                <div>{isOpen ? <ChevronUp /> : <ChevronDown />}</div>
              </div>
              {isOpen && (
                <ul className={styles.dropdownOptions}>
                  <li onClick={() => handleOptionClick('Option 1')}>
                    Option 1
                  </li>
                  <li onClick={() => handleOptionClick('Option 2')}>
                    Option 2
                  </li>
                  <li onClick={() => handleOptionClick('Option 3')}>
                    Option 3
                  </li>
                </ul>
              )}
            </div> */}
            {error && <p className="text-danger">{error}</p>}
            {!isLoading && (
              <button onClick={handleLogin} className={styles.loginBtn}>
                Sign In
              </button>
            )}
            {isLoading && (
              <button className={styles.loginBtn}>Authenticating...</button>
            )}
            <div className={styles.belowForm}>
              <Link to="/forgot-password" className={styles.passwordLink}>
                <p className={styles.belowFormText}>Forgot Password?</p>
              </Link>
              <Link to="/change-password" className={styles.passwordLink}>
                <p className={styles.belowFormText}>Change Password</p>
              </Link>
            </div>
          </form>
        </div>
        {/* </div> */}
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.loginTextRHS}>
          <p>An enterprise level customer management solution</p>
        </div>
        <div className={styles.TopCarouselContainer}>
          <Marquee className={styles.marquee}>
            {carouselList.map(each => (
              <div className={styles.carouselContainer}>
                <p>{each.name}</p>
                <span>{each.icon}</span>
              </div>
            ))}
          </Marquee>
        </div>
        <div className={styles.BottomCarouselContainer}>
          <Marquee className={styles.marquee} direction="right">
            {carouselList.map(each => (
              <div className={styles.carouselContainer}>
                <p>{each.name}</p>
                <span>{each.icon}</span>
              </div>
            ))}
          </Marquee>
        </div>
      </div>
    </div>
  );
};
