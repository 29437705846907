import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import DownloadButtonPdf from '../../component/DownloadButtonPdf/DownloadButtonPdf';
import DownloadButtonXlsx from '../../component/DownloadButtonXlsx/DownloadButtonXlsx';
import styles from '../../styles/CompanyPointsLiabilityReport.module.scss';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CompanyPointsLiabilityReport = () => {
  const [clearModal, setClearModal] = useState(false);
  const data1 = [
    {name: 'Total Points Issued to Customer', points: 7, value: 4500},
  ];

  const data2 = [
    {name: 'Total Number of Points Redeemed', points: 8, value: 4500},
    {name: 'Total Number of Points Fulfilled', points: 8, value: 4500},
    {name: 'Total Number of Points Unfulfilled', points: 8, value: 4500},
  ];

  const data3 = [
    {name: 'Total Number of Shipping Points', points: 8, value: 4500},
    {name: 'Total Number of Fulfilled Shipping Points', points: 8, value: 4500},
    {
      name: 'Total Number of Unfulfilled Shipping Points',
      points: 8,
      value: 4500,
    },
  ];

  const data4 = [{name: 'Total Transaction Value', points: 7, value: 4500}];

  return (
    <>
      <CallCenterStructure>
        <div className="mt-5 p-4">
          <h4 className="pb-4">Company Points Liability Report</h4>
          <main className="border border-light p-3">
            <form action="">
              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label
                    className="d-flex justify-content-between"
                    htmlFor="fromDate">
                    From Date *
                  </label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="From date"
                  />
                </div>
                <div className="form-group col-12 col-md-6">
                  <label
                    className="d-flex justify-content-between"
                    htmlFor="tillDate">
                    Till Date *
                  </label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="Till date"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="status">Loyalty Programme *</label>
                  <Dropdown />
                </div>
              </div>

              <div className="form-group mt-3 d-flex justify-content-between d-md-flex">
                <button className="mr-5">Submit</button>
                <button
                  className="resetButton"
                  type="button"
                  onClick={() => setClearModal(true)}>
                  Reset
                </button>
              </div>
            </form>
          </main>
          <div className="mt-4 d-flex justify-content-between d-md-flex justify-content-md-start">
            <DownloadButtonPdf />
            <DownloadButtonXlsx />
          </div>

          <section className="border border-light mt-4 mb-4">
            <div className="row p-3">
              <div className="col-12 table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="w-25">
                        <strong>Points</strong>
                      </th>
                      <th className="w-25">
                        <strong>Naira Value</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody className={styles.tBODY}>
                    {data1.map(data => (
                      <tr key={data.name}>
                        <td className={styles.tD}>{data.name}</td>
                        <td className={styles.tD}>{data.points}</td>
                        <td className={styles.tD}>{data.value}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className={styles.tBODY}>
                    {data2.map(data => (
                      <tr key={data.name}>
                        <td className={styles.tD}>{data.name}</td>
                        <td className={styles.tD}>{data.points}</td>
                        <td className={styles.tD}>{data.value}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className={styles.tBODY}>
                    {data3.map(data => (
                      <tr key={data.name}>
                        <td className={styles.tD}>{data.name}</td>
                        <td className={styles.tD}>{data.points}</td>
                        <td className={styles.tD}>{data.value}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className={styles.tBODY}>
                    {data4.map(data => (
                      <tr key={data.name}>
                        <td className={styles.tD}>{data.name}</td>
                        <td className={styles.tD}>{data.points}</td>
                        <td className={styles.tD}>{data.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
        </div>
      </CallCenterStructure>
    </>
  );
};
export default CompanyPointsLiabilityReport;
