import React, {useState} from 'react';
import {Times, ViewPen} from '../../assets/icons';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';
import Dropdown from '../../component/DropDown/DropDown';
import SearchInput from '../../component/SearchInput/SearchInput';
import styles from '../../styles/CompanySMSTemplateMaster.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';

const CompanySMSTemplateMaster = () => {
  const [clearModal, setClearModal] = useState(false);

  const tableData = [
    {
      id: 4,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Active',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 3,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Inactive',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 9,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Active',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 6,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Active',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    ,
  ];
  return (
    <div>
      <CallCenterStructure>
        <div className={styles.CompanySMSTemplateMaster}>
          <div className={styles.headerText}>
            <p>Company SMS Template Master</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field1}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Select Template Name</label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Select Template For SMS</label>
                <Dropdown />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Enter Description</label>
                <textarea name="" id="" />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Content</label>
                <textarea name="" id="" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Select Variable for SMS Content</label>
                <Dropdown />
              </div>
              <div className={styles.previewBTN}>
                <button>Preview</button>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <button className={styles.submit}>Submit</button>
              <button
                className={styles.reset}
                type="button"
                onClick={() => setClearModal(true)}>
                Reset
              </button>
            </div>
          </div>
          <div className={styles.tableSection}>
            <div className={styles.searchInputContainer}>
              <div className={styles.searchInput}>
                <SearchInput />
              </div>
              <button className={styles.searchBTN}>Search</button>
            </div>
            <div className={styles.innerTableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Email Template ID</th>
                    <th>Email Template Name</th>
                    <th>Template Description</th>
                    <th>Click to Activate</th>
                    <th>Click to Deactivate</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr key={each.id}>
                      <td>{each.name}</td>
                      <td>{each.code}</td>
                      <td>{each.itemName}</td>
                      <td>
                        {each.groupName === 'Active' ? (
                          <p className={styles.active}>{each.groupName}</p>
                        ) : (
                          <p className={styles.inactive}>{each.groupName}</p>
                        )}
                      </td>
                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <Times />
                          </span>
                        </div>
                      </td>

                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default CompanySMSTemplateMaster;
