import React, {useState, useEffect} from 'react';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import styles from '../../styles/CallCenterResolutionPriorityLevel.module.scss';
import Dropdown from '../../component/DropDown/DropDown';
import SearchInput from '../../component/SearchInput/SearchInput';
import {Times, ViewPen} from '../../assets/icons';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import {getUser} from '../../service/common';
import swal from 'sweetalert';
import Paginate from '../../component/Paginate/Paginate';
import {CallCenterHeaders, Headers} from '../../service/Essentials';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CallCenterResolutionPriorityLevel = () => {
  const [clearModal, setClearModal] = useState(false);
  const user = getUser();

  const program_id = 2; // user.user_group_id;

  const dataString = sessionStorage.getItem('profile');
  if (dataString) {
    var profile = JSON.parse(dataString);
  } else {
    profile = {};
  }
  const membership_id = 7744889018; // profile?.Membership_id;
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState({
    Company_id: '',
    Membership_id: '',
    Level_name: '',
    Resolution_priority_levels: '',
    No_of_days_expected_resolve: '',
    Query_type_id: '',
    Query_id: '',
    User_type_id: '',
    User_group_id: '',
  });
  const [userTypeID, setUserTypeID] = useState('');
  const [userType, setUserType] = useState();
  const [userGroupID, setUserGroupID] = useState('');
  const [userGroup, setUserGroup] = useState();
  const [userNameID, setUserNameID] = useState('');
  const [userName, setUserName] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItemID, setSelectedItemId] = useState('');

  const [formData, setFormData] = useState({
    Company_id: program_id,
    Membership_id: membership_id,
    Level_name: '',
    Resolution_priority_levels: '',
    No_of_days_expected_resolve: '',
    Query_type_id: 4,
    Query_id: 7,
    User_type_id: '',
    User_group_id: '',
  });
  console.log(formData);
  const handleUserType = name => {
    setUserType(name);
  };

  const handleUserTypeID = id => {
    setUserTypeID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      User_type_id: id,
    }));
  };

  const handleUserGroup = name => {
    setUserGroup(name);
  };

  const handleUserGroupID = id => {
    setUserGroupID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      User_group_id: id,
    }));
  };

  const handleUserName = name => {
    setUserName(name);
  };

  const handleUserNameID = id => {
    setUserNameID(id);
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   User_name_id: id
    // }));
  };

  const handleSearchInputChange = searchTerm => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data) {
      const filtered = data?.data.filter(item =>
        item.Level_name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredData(filtered);
    }
  };

  const fetchResolutionLevels = async page => {
    const res = await axiosProperty.get(
      ApiConfig.fetchResolutionLevels(7744889018),
      {
        headers: CallCenterHeaders(),
      },
    );
    return res.data;
  };

  const {data, status} = useQuery(
    ['resolutions', page],
    () => fetchResolutionLevels(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  //console.log(filteredData);
  const handleChange = e => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const addResolutionLevel = async () => {
    try {
      await axiosProperty.post(ApiConfig.createResolutionLevel, formData, {
        headers: CallCenterHeaders(),
      });
      setFormData({
        Company_id: program_id,
        Membership_id: membership_id,
        Level_name: '',
        Resolution_priority_levels: '',
        No_of_days_expected_resolve: '',
        Query_type_id: 4,
        Query_id: 7,
        User_type_id: '',
        User_group_id: '',
      });
      setErrors({});
      setUserGroupID('');
      setUserGroup('');
      setUserType('');
      setUserTypeID('');
      swal({
        title: 'Success!',
        text: 'Resolution Level added successfully',
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: addResolutionLevelMutation} = useMutation({
    mutationFn: addResolutionLevel,
    onSuccess: () => {
      queryClient.invalidateQueries(['resolutions']);
    },
  });

  const updateResolutionLevel = async () => {
    try {
      await axiosProperty.put(
        ApiConfig.updateResolutionLevel(updateID),
        formData,
        {
          headers: CallCenterHeaders(),
        },
      );
      setIsSubmit(true);
      setIsUpdate(false);
      setFormData({
        Company_id: program_id,
        Membership_id: membership_id,
        Level_name: '',
        Resolution_priority_levels: '',
        No_of_days_expected_resolve: '',
        Query_type_id: 4,
        Query_id: 7,
        User_type_id: '',
        User_group_id: '',
      });
      setErrors({});
      setUserGroupID('');
      setUserGroup('');
      setUserType('');
      setUserTypeID('');
      swal({
        title: 'Success!',
        text: 'Resolution Level updated successfully',
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, try again');
        swal({
          title: 'Warning!',
          text: error,
          icon: 'warning',
        });
      }
    }
  };

  const {mutate: updateResolutionLevelMutation} = useMutation({
    mutationFn: updateResolutionLevel,
    onSuccess: () => {
      queryClient.invalidateQueries(['resolutions']);
    },
  });

  const editResolutionLevel = async id => {
    const res = await axiosProperty.get(ApiConfig.editResolutionLevel(id), {
      headers: CallCenterHeaders(),
    });
    // console.log(res.data.data);
    setIsUpdate(true);
    setIsSubmit(false);
    setFormData(prevFormData => ({
      ...prevFormData,
      Company_id: program_id,
      Membership_id: 7744889018,
      Level_name: res.data.data[0].Level_name,
      Resolution_priority_levels: res.data.data[0].Resolution_priority_levels,
      No_of_days_expected_resolve: res.data.data[0].No_of_days_expected_resolve,
      Query_type_id: res.data.data[0].Query_type_id,
      Query_id: res.data.data[0].Query_setup_id,
      User_type_id: res.data.data[0].User_type_id,
      User_group_id: res.data.data[0].User_group_id,
    }));
  };

  const deleteResolutionLevel = async id => {
    try {
      const res = await axiosProperty.delete(
        ApiConfig.deleteResolutionLevel(id),
        {
          headers: CallCenterHeaders(),
        },
      );
      //setSuccessModal(true);
      setDeleteModal(false);
      swal({
        title: 'Success!',
        text: 'Delete was successful',
        icon: 'success',
      });
      queryClient.invalidateQueries('resolutions');
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);
      swal({
        title: 'Warning!',
        text: error,
        icon: 'warning',
      });
    }
  };
  const onClose = () => {
    setDeleteModal(false);
  };
  // const tableData = [
  //   {
  //     id: 4,
  //     name: 'Fidelity Green Rewards',
  //     code: 'Lsl-THE OTH 521627',
  //     groupName: 'Active',
  //     itemName: 'candy',

  //     brandName: 'The Store',
  //     price: '139150 Points',
  //   },
  //   {
  //     id: 3,
  //     name: 'Fidelity Green Rewards',
  //     code: 'Lsl-THE OTH 521627',
  //     groupName: 'Inactive',
  //     itemName: 'candy',

  //     brandName: 'The Store',
  //     price: '139150 Points',
  //   },
  //   {
  //     id: 9,
  //     name: 'Fidelity Green Rewards',
  //     code: 'Lsl-THE OTH 521627',
  //     groupName: 'Active',
  //     itemName: 'candy',

  //     brandName: 'The Store',
  //     price: '139150 Points',
  //   },
  //   {
  //     id: 6,
  //     name: 'Fidelity Green Rewards',
  //     code: 'Lsl-THE OTH 521627',
  //     groupName: 'Active',
  //     itemName: 'candy',

  //     brandName: 'The Store',
  //     price: '139150 Points',
  //   },
  // ];
  return (
    <div>
      <CallCenterStructure>
        <div className={styles.CallCenterQueryType}>
          <div className={styles.headerText}>
            <p>Call Center Resolution Priority Level</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field1}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Enter Priority Level<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="Resolution_priority_levels"
                  value={formData.Resolution_priority_levels}
                  onChange={handleChange}
                />
                {errors?.Resolution_priority_levels && (
                  <p className="text-danger">
                    {errors?.Resolution_priority_levels}
                  </p>
                )}
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Enter Level Name<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="Level_name"
                  value={formData.Level_name}
                  onChange={handleChange}
                />
                {errors?.Level_name && (
                  <p className="text-danger">{errors?.Level_name}</p>
                )}
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Enter No. of Hours to Resolve<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="No_of_days_expected_resolve"
                  value={formData.No_of_days_expected_resolve}
                  onChange={handleChange}
                />
                {errors?.No_of_days_expected_resolve && (
                  <p className="text-danger">
                    {errors?.No_of_days_expected_resolve}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.middleText}>
              <p>Contact Details for Resolve Query</p>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select User Type<sup>*</sup>
                </label>
                <Dropdown
                  header={Headers()}
                  api={ApiConfig.fetchUserType}
                  onSelect={handleUserTypeID}
                  onSelectOption={handleUserType}
                  retrievedValue={formData.User_type_id}
                  column={'name'}
                  key="usertypes"
                />
                {errors?.User_type_id && (
                  <p className="text-danger">{errors?.User_type_id}</p>
                )}
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select User Group<sup>*</sup>
                </label>
                <Dropdown
                  onSelect={handleUserGroupID}
                  onSelectOption={handleUserGroup}
                  retrievedValue={formData.User_group_id}
                  column={'name'}
                  key="usergroups"
                />
                {errors?.User_group_id && (
                  <p className="text-danger">{errors?.User_group_id}</p>
                )}
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Select User Name</label>
                <Dropdown
                  onSelect={handleUserNameID}
                  onSelectOption={handleUserName}
                  retrievedValue={userNameID}
                  column={'name'}
                  key="username"
                />
              </div>
            </div>
            <div className={styles.buttonContainer}>
              {isSubmit && (
                <button
                  className={styles.submit}
                  onClick={() => addResolutionLevelMutation()}>
                  Submit
                </button>
              )}
              {isUpdate && (
                <button
                  className={styles.submit}
                  onClick={() => updateResolutionLevelMutation()}>
                  Update
                </button>
              )}
              <button
                className={styles.reset}
                type="button"
                onClick={() => setClearModal(true)}>
                Reset
              </button>
            </div>
          </div>
          <div className={styles.tableSection}>
            <div className={styles.searchInputContainer}>
              <div className={styles.searchInput}>
                <SearchInput onSearchInputChange={handleSearchInputChange} />
              </div>
              <button className={styles.searchBTN}>Search</button>
            </div>
            <div className={styles.innerTableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Priority Level</th>
                    <th>Level Name</th>
                    <th>No of Hours</th>
                    <th>User Type</th>
                    <th>User Group</th>
                    <th>User Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {status === 'error' && (
                    <p className="text-danger">Error Occured</p>
                  )}
                  {status === 'loading' && (
                    <p className="text-info">Loading Resolutions Levels...</p>
                  )}
                  {status === 'success' &&
                    filteredData.map(each => (
                      <tr key={each.id}>
                        <td>{each.Resolution_priority_levels}</td>
                        <td>{each.Level_name}</td>
                        <td>{each.No_of_days_expected_resolve}</td>
                        <td>{each.User_group_id}</td>
                        <td>{each.User_group_id}</td>
                        <td></td>
                        <td>
                          <div className={styles.iconContainer}>
                            <span
                              className={styles.icon}
                              onClick={() => {
                                setDeleteModal(true);
                                setSelectedItemId(each.Resolution_id);
                              }}>
                              <Times />
                            </span>
                            <span
                              className={styles.icon}
                              onClick={() => {
                                editResolutionLevel(each.Resolution_id);
                                setUpdateID(each.Resolution_id);
                              }}>
                              <ViewPen />
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            // clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
          {deleteModal && (
            <MenuDeleteModal
              onClose={onClose}
              onDelete={() => deleteResolutionLevel(selectedItemID)}
            />
          )}
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default CallCenterResolutionPriorityLevel;
