//const baseURL = 'http://127.0.0.1:8000/api/v1';

const baseURL = 'https://companymaster.perxclm2.com/api/v1';

//const baseURL = 'https://companymaster.perxclm.com/api/v1';

const cuEnrolURL = 'http://customerenrolment.perxclm.com';

const callCenterURL = ' https://callcenter.perxclm2.com';

const companySurveyURL = 'https://companysurvey.perxclm2.com';

const transactionsURL = 'https://transactions.perxclm2.com';

const sweepstakeURL = 'https://sweepstake.perxclm2.com/public/api/v1';

const auctionURL = 'https://auction.perxclm2.com/public/v1';

const campaignURL = 'https://companycampaign.perxclm2.com/public/admin';

const ApiConfig = {
  // login
  loginApi: baseURL + '/user/login',

  // user types endpoints
  createUserType: baseURL + '/usertype/create',
  fetchUserType: baseURL + '/usertype',
  editUserType: baseURL + '/usertype',
  updateUserType: baseURL + '/usertype',

  // payment types endpoints
  createPaymentType: baseURL + '/paymenttypemaster/create',
  fetchPaymentType: baseURL + '/paymenttypemaster',
  editPaymentType: baseURL + '/paymenttypemaster',
  updatePaymentType: baseURL + '/paymenttypemaster',
  searchPaymentType: baseURL + '/paymenttypemaster/search',

  // code decode master endpoint
  createCodeDecodeMaster: baseURL + '/codedecodemaster/create',
  fetchCodeDecodeMaster: baseURL + '/codedecodemaster',
  editCodeDecodeMaster: baseURL + '/codedecodemaster',
  updateCodeDecodeMaster: baseURL + '/codedecodemaster',

  // code decode type master endpoint
  createCodeDecodeTypeMaster: baseURL + '/codedecodetypemaster/create',
  fetchCodeDecodeTypeMaster: baseURL + '/codedecodetypemaster',
  editCodeDecodeTypeMaster: baseURL + '/codedecodetypemaster',
  updateCodeDecodeTypeMaster: baseURL + '/codedecodetypemaster',

  //transaction types endpoint
  createTransactionType: baseURL + "/transactiontypes/create",
  fetchTransactionType: baseURL + "/transactiontypes/types/all",
  editTransactionType: baseURL + "/transactiontypes",
  updateTransactionType: baseURL + "/transactiontypes",
  deleteTransactionType: baseURL + "/transactiontypes",

  //tiers-criteria endpoint
  createTierCriteria: baseURL + '/tiercriterias/create',
  fetchTierCriteria: baseURL + '/tiercriterias',
  editTierCriteria: baseURL + '/tiercriterias',
  updateTierCriteria: baseURL + '/tiercriterias',

  //tiers-levels endpoint
  createTierLevel: baseURL + '/tierlevels/create',
  fetchTierLevel: baseURL + '/tierlevels',
  editTierLevel: baseURL + '/tierlevels',
  updateTierLevel: baseURL + '/tierlevels',

  //notification template type endpoints
  createNotificationType: baseURL + '/notificationtemplatetype/create',
  fetchNotificationType: baseURL + '/notificationtemplatetype',
  editNotificationType: baseURL + '/notificationtemplatetype',
  updateNotificationType: baseURL + '/notificationtemplatetype',

  //solution logo type endpoints
  createCompanyLogoType: baseURL + '/companysolutiontypelogo/create',
  fetchCompanyLogoType: baseURL + '/companysolutiontypelogo',
  editCompanyLogoType: baseURL + '/companysolutiontypelogo',
  updateCompanyLogoType: baseURL + '/companysolutiontypelogo',
  deleteCompanyLogoType: baseURL + '/companysolutiontypelogo',

  //company master endpoints
  createCompanyMaster: baseURL + '/company_master/create',
  fetchCompanyMaster: baseURL + '/company_master/companys',
  editCompanyMaster: baseURL + '/company_master',
  updateCompanyMaster: baseURL + '/company_master/edit_company',
  deleteCompanyMaster: baseURL + '/company_master/delete_company',
  activeCompanyMaster: baseURL + '/company_master/active_companies',
  inactiveCompanyMaster: baseURL + '/company_master/inactive_companies',
  changeStatusCompanyMaster: baseURL + '/company_master/change_status',

  // company branch endpoints
  createCompanyBranch: baseURL + '/companybranch/create',
  fetchCompanyBranch: baseURL + '/companybranch',
  editCompanyBranch: baseURL + '/companybranch',
  updateCompanyBranch: baseURL + '/companybranch',
  deleteCompanyBranch: baseURL + '/companybranch',

  // loyalty program endpoints
  createLoyaltyProgram: baseURL + '/loyalty_program/create',
  fetchLoyaltyProgram: baseURL + '/loyalty_program/get',
  editLoyaltyProgram: baseURL + '/loyalty_program',
  updateLoyaltyProgram: baseURL + '/loyalty_program/edit',
  deleteLoyaltyProgram: baseURL + '/loyalty_program',

  //transaction channels endpoints
  createTransactionChannel: baseURL + "/transactionchannels/create",
  fetchTransactionChannel: baseURL + "/transactionchannels/types/all",
  editTransactionChannel: baseURL + "/transactionchannels",
  updateTransactionChannel: baseURL + "/transactionchannels",
  deleteTransactionChannel: baseURL + "/transactionchannels",
  // activeTransactionChannel: baseURL + "/transactionchannels/active/all",
  // inactiveTransactionChannel: baseURL + "/transactionchannels/inactive/all",
  // changeStatusTransactionChannel: baseURL + "/transactionchannels/changestatus",

  // company enrollment endpoints
  createCompanyEnrolment: baseURL + '/companyenrolment/create',
  fetchCompanyEnrolment: baseURL + '/companyenrolment',
  editCompanyEnrolment: baseURL + '/companyenrolment',
  updateCompanyEnrolment: baseURL + '/companyenrolment',
  deleteCompanyEnrolment: baseURL + '/companyenrolment',

  // partner enrollment endpoints
  createPartnerEnrolment: baseURL + '/partnerenrolment/create',
  fetchPartnerEnrolment: baseURL + '/partnerenrolment',
  editPartnerEnrolment: baseURL + '/partnerenrolment',
  updatePartnerEnrolment: baseURL + '/partnerenrolment',
  deletePartnerEnrolment: baseURL + '/partnerenrolment',

  // product group master endpoints
  createProductGroup: baseURL + '/productgroup/create',
  fetchProductGroup: baseURL + '/productgroup',
  editProductGroup: baseURL + '/productgroup',
  updateProductGroup: baseURL + '/productgroup',
  deleteProductGroup: baseURL + '/productgroup',

  // product brand master endpoints
  createProductBrand: baseURL + '/productbrand/create',
  fetchProductBrand: `${baseURL}/productbrand/`,
  fetchProductBrandDrop: id => `${baseURL}/productbrand/get/${id}`,
  editProductBrand: baseURL + '/productbrand',
  updateProductBrand: baseURL + '/productbrand',
  deleteProductBrand: baseURL + '/productbrand',

  //product code master endpoints
  fetchProductCode: baseURL + "/productcode",

  // user group endpoints
  createUserGroup: baseURL + '/usergroup/create',
  fetchUserGroup: baseURL + '/usergroup',
  editUserGroup: baseURL + '/usergroup',
  updateUserGroup: baseURL + '/usergroup',
  deleteUserGroup: baseURL + '/usergroup',
  fetchUserGroupEach: (userTypeId)=> baseURL + `/usergroup/get/${userTypeId}`,

  //company type
  fetchCompanyType: baseURL + `/company_master/companys`,

  // point forfeiture rule endpoints
  createPointforfeiturerule: baseURL + '/pointforfeiturerule/create',
  fetchPointforfeiturerule: baseURL + '/pointforfeiturerule',
  editPointforfeiturerule: baseURL + '/pointforfeiturerule',
  updatePointforfeiturerule: baseURL + '/pointforfeiturerule',
  deletePointforfeiturerule: baseURL + '/pointforfeiturerule',

  // relationships endpoints
  fetchCountry: baseURL + "/get/countries",
  fetchRegion: (id) => baseURL + `/dropdown/country/${id}/regions`,
  fetchZone: (id) => baseURL + `/dropdown/region/${id}/zones`,
  fetchState: (id) => baseURL + `/dropdown/zone/${id}/states`,
  fetchCity: (id) => baseURL + `/dropdown/state/${id}/cities`,
 // fetchPartnersByID: (id) => baseURL + `/get/partner/branches/${id}`,

  // company types endpoints
  fetchCompanyTypes: baseURL + '/companytype',
  editCompanyType: id => baseURL + `/companytype/${id}`,
  updateCompanyType: id => baseURL + `/companytype/${id}`,
  deleteCompanyType: id => baseURL + `/companytype/${id}`,

  // partner set up endpoints
  createPartnerMaster: baseURL + '/partnermaster/create',
  fetchPartnermaster: baseURL + '/partnermaster',
  editPartnermaster: id => baseURL + `/partnermaster/${id}`,
  updatePartnermaster: id => baseURL + `/partnermaster/${id}`,
  deletePartnermaster: id => baseURL + `/partnermaster/${id}`,

  // partner branch endpoints
  createPartnerbranch: baseURL + '/partnerbranch/create',
  fetchPartnerbranch: baseURL + '/partnerbranch',
  editPartnerbranch: id => baseURL + `/partnerbranch/${id}`,
  updatePartnerbranch: id => baseURL + `/partnerbranch/${id}`,
  deletePartnerbranch: id => baseURL + `/partnerbranch/${id}`,

  // customer enrolment
  createEnrolmentBranch: cuEnrolURL + '/admin/customer-enrollment-save',
  createEnrolmentWebsite: cuEnrolURL + '/admin/cust-enrol-from-website-save',
  fetchAssignMembershipIDFamily: id =>
    cuEnrolURL + `/admin/update-family-membership/${id}`,
  fetchAssignMembershipID: id => cuEnrolURL + `/admin/update-membership/${id}`,
  // fetchParentDetails: (id)=>cuEnrolURL + `/admin/handle-member-query/${id}`,
  fetchParentDetails: (id) => cuEnrolURL + `/admin/parent-member-query/${id}`,
  fetchProfileDetails: (id) => cuEnrolURL + `/admin/single-customer-enrollment/${id}`,
  addFamilyMember: (id) => cuEnrolURL + `/admin/add-family-member/${id}`,
  fetchFamilyMembers: (id) => cuEnrolURL + `/admin/parent-family-member/${id}`,

  // call center
  fetchCallCenterDetails: id =>
    callCenterURL + `/admin/membership?Membership_id=${id}`,
  fetchQueryRecord: id =>
    callCenterURL + `/admin/query-log?Membership_id=${id}`,
  fetchRedeemItems: callCenterURL + `/admin/all-redeemable-item`,
  fetchResolutionLevels: id =>
    callCenterURL + `/admin/resolution-priority-level?Membership_id=${id}`,
  createResolutionLevel: callCenterURL + '/admin/save-resolution-level',
  updateResolutionLevel: id =>
    callCenterURL + `/admin/update-resolution-level/${id}`,
  editResolutionLevel: id =>
    callCenterURL + `/admin/single-resolution-priority-level/${id}`,
  deleteResolutionLevel: id =>
    callCenterURL + `/admin/delete-resolution-level/${id}`,
  fetchQueryTypes: id =>
    callCenterURL + `/admin/query-type?Membership_id=${id}`,
  fetchSubQueryTypes: id =>
    callCenterURL + `/admin/query-setup?Membership_id=${id}`,
  createLogQuery: callCenterURL + '/admin/save-query-log',
  createQueryType: callCenterURL + '/admin/save-query-type',
  updateQueryType: id => callCenterURL + `/admin/update-query-type/${id}`,
  editQueryType: id => callCenterURL + `/admin/single-query-type/${id}`,
  deleteQueryType: id => callCenterURL + `/admin/delete-query-type/${id}`,
  createSubQueryType: callCenterURL + '/admin/save-query-setup',
  updateSubQueryType: id => callCenterURL + `/admin/update-query-setup/${id}`,
  editSubQueryType: id => callCenterURL + `/admin/sub-query-setup/${id}`,
  deleteSubQueryType: id => callCenterURL + `/admin/delete-query-setup/${id}`,
  fetchEscalationMatrix: callCenterURL + '/admin/escalation-matrix',
  createEscalationMatrix: callCenterURL + '/admin/save-escalation-matrix',
  updateEscalationMatrix: id =>
    callCenterURL + `/admin/update-escalation-matrix/${id}`,
  editEscalationMatrix: id =>
    callCenterURL + `/admin/single-escalation-matrix/${id}`,
  deleteEscalationMatrix: id =>
    callCenterURL + `/admin/delete-escalation-matrix/${id}`,
  fetchOpenLogTickets: id =>
    callCenterURL + `/admin/query-log-open?Membership_id=${id}`,
  fetchClosedLogTickets: id =>
    callCenterURL + `/admin/query-log-closed?Membership_id=${id}`,
  searchLogTickets: (para1, para2, para3) =>
    callCenterURL + `/admin/search-query-log-details?Resolution_id=${para1}&Querylog_ticket=${para2}&Closure_date=${para3}`,

  // company survey
  createSurvey: companySurveyURL + "/admin/save-survey-structure",
  fetchSurveyTypeSent: companySurveyURL + "/admin/sent-survey-structure",
  fetchSurveyTypeNotSent: companySurveyURL + "/admin/not-sent-survey-structure",
  fetchSurveyTypeClosed: companySurveyURL + "/admin/closed-survey-structure",
  fetchSurveyTypeAll: companySurveyURL + "/admin/all-survey-structure",
  fetchSurveyAll: companySurveyURL + "/admin/all-survey-type",
  editSurveyType: (id) => companySurveyURL + `/admin/single-survey-structure/${id}`,
  updateSurveyType: (id) => companySurveyURL + `/admin/update-survey-structure/${id}`,
  deleteSurveyType: (id) => companySurveyURL + `/admin/delete-survey/${id}`,
  createQuestionnaire: companySurveyURL + "/admin/save-questionaire",
  createSendSurvey: companySurveyURL + "/admin/send-survey",
  fetchSegments: companySurveyURL + "/admin/all-segment",
  fetchTiers: companySurveyURL + "/admin/all-tier",

  //perform company transactions
  createManualDebit: (id) => transactionsURL + `/admin/manual-debit-transaction/${id}`,
  createManualCredit: (id) => transactionsURL + `/admin/manual-credit-transaction/${id}`,
  createPointAward: transactionsURL + `/admin/save-points-award-transaction`,

  //sweepstakes endpoints
  createSweepStake: sweepstakeURL + `/sigma-prime/sweepstake/create`,
  updateSweepStake: id =>
    sweepstakeURL + `/sigma-prime/sweepstake/update/${id}`,
  deleteSweepStake: id => sweepstakeURL + `/sweepstake/delete/${id}`,
  getSweepStake: id => sweepstakeURL + `/sigma-prime/sweepstake/find/${id}`,
  fetchActiveSweepStakes: sweepstakeURL + `/sigma-prime/sweepstakes/active`,
  fetchDisabledSweepStakes: sweepstakeURL + `/sigma-prime/sweepstakes`,

  //auction endpoints
  createAuction: auctionURL + `/create_auction`,
  fetchAuctions: pageNum => auctionURL + `/all_items?page=${pageNum}`,
  updateAuction: id => auctionURL + `/update_auction/${id}`,
  fetchEachBid: id => auctionURL + `/show/${id}`,
  fetchHighestBid: page => auctionURL + `/view_highest?page=${page}`,
  deleteAuction: id => auctionURL + `/delete_item/${id}`,
  fetchAuctionWinners: page => auctionURL + `/auction_winners?page=${page}`,
  approveAuction: id => auctionURL + `/approve_winner/${id}`,

  //Upgrade Privilege Campaign
  createUpgradePrivilege: campaignURL + `/save-upgrade-privilege-campaign`,
  createDiscountBenefit: campaignURL + `/save-discount-benefit-campaign`,

  //referral campaign
  createReferral: campaignURL + `/save-referal-campaign`,
  fetchReferral: pageNum => campaignURL + `/referal-campaign?page=${pageNum}`,
  deleteReferral: id => campaignURL + `/delete-referal-campaign/${id}`,
  updateReferral: id => campaignURL + `/update-referal-campaign/${id}`,

  //promo campaign
  fetchPromo: pageNum => campaignURL + `/promo-campaign?page=${pageNum}`,
  createPromo: campaignURL + `/save-promo-campaign`,

  //value spend regular Campaign
  fetchValueSpendCampaign: page => campaignURL + `/spend-campaign?page=${page}`,
  deleteValueSpend: id => campaignURL + `/delete-spend-campaign/${id}`,

  //all branches
  fetchBranches: baseURL + `/companybranch`,

  //tier
  fetchTier: baseURL + `/tiercriterias`,

  //fixed budget regular campaign

  fetchBudgetRegular: page =>
    campaignURL + `/fixed-budget-campaign?page=${page}`,
  updateBudgetRegular: id =>
    campaignURL + `/update-fixed-budget-campaign/${id}`,
  deleteBudgetRegular: id =>
    campaignURL + `/delete-fixed-budget-campaign/${id}`,

  //cashback regular campaign
  fetchCashbackRegular:(page)=> campaignURL + `/cash-back-campaign?page=${page}`,
  updateCashbackRegular: (id) => campaignURL + `/update-cash-back-campaign/${id}`,
  deleteCashbackRegular: (id) => campaignURL + `/delete-cash-back-campaign/${id}`,

  //bonus regular campaign
  fetchBonusRegular: page => campaignURL + `/bonus-campaign?page=${page}`,
  updateBonusRegular: id => campaignURL + `/update-bonus-campaign/${id}`,
  deleteBonusRegular: id => campaignURL + `/delete-bonus-campaign/${id}`,

};
export default ApiConfig;
