import React, {useState, useEffect, useRef} from 'react';
import {useQuery} from 'react-query';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import CartStructure from '../CartStructure/CartStructure';
import styles from '../../styles/LogDetails.module.scss';
import Dropdown from '../../component/DropDown/DropDown';
import TicketTable from '../TicketTable/TicketTable';
import LogDetailsModal from '../../component/LogDetailsModal/LogDetailsModal';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import {CallCenterHeaders, Headers} from '../../service/Essentials';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const LogDetails = () => {
  const [clearModal, setClearModal] = useState(false);
  const dataString = sessionStorage.getItem('profile');
  if (dataString) {
    var profile = JSON.parse(dataString);
  } else {
    profile = {};
  }
  const membership_id = 7744889018; // profile?.Membership_id;
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [openTickets, setOpenTickets] = useState([]);
  const [closedTickets, setClosedTickets] = useState([]);
  const modalRef = useRef(null);
  const [viewedRows, setViewedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [resolutionID, setResolutionID] = useState('');
  const [resolutionName, setResolutionName] = useState('');
  const [ticketNumber, setTicketNumber] = useState('');
  const [closureDate, setClosureDate] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [searchTicketsData, setSearchTicketsData] = useState([]);

  const handleView = index => {
    setViewedRows(prevItemValues => [...prevItemValues, index]);
    setShowModal(true);
  };

  const isRowViewed = index => {
    return viewedRows.includes(index);
  };

  const handleResolutionName = name => {
    setResolutionName(name);
  };

  const handleResolutionID = id => {
    setResolutionID(id);
  };

  const fetchClosedTickets = async page => {
    const res = await axiosProperty.get(
      ApiConfig.fetchClosedLogTickets(membership_id),
      {
        headers: CallCenterHeaders(),
      },
    );
    return res.data;
  };

  const {data: closedticketsdata, status: closedstatus} = useQuery(
    ['closedtickets', page],
    () => fetchClosedTickets(page),
    {keepPreviousData: true},
  );

  const fetchOpenTickets = async page => {
    const res = await axiosProperty.get(
      ApiConfig.fetchOpenLogTickets(7744889018),
      {
        headers: CallCenterHeaders(),
      },
    );
    return res.data;
  };

  const {data: openticketsdata, status: openstatus} = useQuery(
    ['opentickets', page],
    () => fetchOpenTickets(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setClosedTickets(closedticketsdata?.data || []);
    setOpenTickets(openticketsdata?.data || []);
  }, [closedticketsdata, openticketsdata]);

  const keyMappingOpen = {
    Querylog_ticket: 'Ticket',
    Level_name: 'Query Priority',
    Membership_id: 'Membership ID',
    Query_type_name: 'Query Type',
    Sub_query: 'Sub Query Type',
    Query_details: 'Query Details',
    Creation_date: 'Creation Date',
    Query_status: 'Query Status',
    First_name: 'Created By',
  };

  var modifiedOpenTickets = openTickets.map(item => {
    const renamedItem = {};
    for (const oldKey in item) {
      if (oldKey in keyMappingOpen) {
        renamedItem[keyMappingOpen[oldKey]] = item[oldKey];
      } else {
        renamedItem[oldKey] = item[oldKey];
      }
    }
    return renamedItem;
  });

  const keyMappingClosed = {
    Querylog_ticket: 'Ticket',
    Level_name: 'Query Priority',
    Membership_id: 'Membership ID',
    Query_type_name: 'Query Type',
    Sub_query: 'Sub Query Type',
    Query_details: 'Query Details',
    Creation_date: 'Creation Date',
    Query_status: 'Query Status',
    First_name: 'Created By',
    Query_log_id: 'Query Log ID',
  };

  var modifiedClosedTickets = closedTickets.map(item => {
    const renamedItem = {};
    for (const oldKey in item) {
      if (oldKey in keyMappingClosed) {
        renamedItem[keyMappingClosed[oldKey]] = item[oldKey];
      } else {
        renamedItem[oldKey] = item[oldKey];
      }
    }
    return renamedItem;
  });

  const keyMappingSearch = {
    Querylog_ticket: 'Ticket',
    Level_name: 'Query Priority',
    Membership_id: 'Membership ID',
    Query_type_name: 'Query Type',
    Sub_query: 'Sub Query Type',
    Query_details: 'Query Details',
    Creation_date: 'Creation Date',
    Query_status: 'Query Status',
    First_name: 'Created By',
    Query_log_id: 'Query Log ID',
  };

  const modifiedSearchTickets = searchTicketsData.map(item => {
    const renamedItem = {};
    for (const oldKey in item) {
      if (oldKey in keyMappingSearch) {
        renamedItem[keyMappingSearch[oldKey]] = item[oldKey];
      } else {
        renamedItem[oldKey] = item[oldKey];
      }
    }
    return renamedItem;
  });

  // const data = [
  //   {
  //     Ticket: 9,
  //     'Query Priority': 'Call Center',
  //     'Customer Name': 'Rasaq Ganiyu',
  //     'Query Type': 'Technical Queries',
  //     'Query Details': 'Customer',
  //     'Creation Date': '2022-10-07 09:23:54',
  //     'Created By': 'Admin',
  //     'Expected Close Date': '2022-12-20',
  //     'Query Status': 'Forward',
  //     Interactions: 'Details',
  //   },
  //   {
  //     Ticket: 12,
  //     'Query Priority': 'IT',
  //     'Customer Name': 'Rasaq Ganiyu',
  //     'Query Type': 'Technical Queries',
  //     'Query Details': 'Customer',
  //     'Creation Date': '2022-10-07 09:23:54',
  //     'Created By': 'Admin',
  //     'Expected Close Date': '2022-12-20',
  //     'Query Status': 'Forward',
  //     Interactions: 'Details',
  //   },
  //   {
  //     Ticket: 91,
  //     'Query Priority': 'Branch',
  //     'Customer Name': 'Rasaq Ganiyu',
  //     'Query Type': 'Technical Queries',
  //     'Query Details': 'Customer',
  //     'Creation Date': '2022-10-07 09:23:54',
  //     'Created By': 'Admin',
  //     'Expected Close Date': '2022-12-20',
  //     'Query Status': 'Forward',
  //     Interactions: 'Details',
  //   },
  //   {
  //     Ticket: 921,
  //     'Query Priority': 'LSL Program Team',
  //     'Customer Name': 'Rasaq Ganiyu',
  //     'Query Type': 'Technical Queries',
  //     'Query Details': 'Customer',
  //     'Creation Date': '2022-10-07 09:23:54',
  //     'Created By': 'Admin',
  //     'Expected Close Date': '2022-12-20',
  //     'Query Status': 'Forward',
  //     Interactions: 'Details',
  //   },
  // ];

  const modalData = [
    {
      TicketNum: 9,
      ForwardedTo: 'Fidelity Bank Company Admin User Group',
      Date: '2022-10-07 09:23:54',
      Query: 'customer',
      Interaction: 'Details',
      Status: 'Forward',
    },
  ];

  useEffect(() => {
    const handleCloseModal = e => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setShowModal(false);
      }
    };

    window.addEventListener('mousedown', handleCloseModal);
    return () => {
      window.removeEventListener('mousedown', handleCloseModal);
    };
  }, []);

  const onClose = () => {
    setShowModal(false);
  };

  const searchTickets = async (para1, para2, para3) => {
    const res = await axiosProperty.get(ApiConfig.searchLogTickets(para1, para2, para3),
     {
       headers:CallCenterHeaders(),
   }
   );
  setSearchTicketsData(res.data.data);
  setIsSearch(true);
};

  return (
    <CallCenterStructure>
      <div className={styles.logDetailsContainer}>
        <div className={styles.handleLogDetailsHeader}>
          <p>View Query Details</p>
        </div>
        <div className={styles.logDetailsMainContainer}>
          <div className={styles.logInputContainer}>
            <div className={styles.logInputInnerContainer}>
              <label htmlFor="">Query Priority Level</label>
              <Dropdown
                header={CallCenterHeaders()}
                api={ApiConfig.fetchResolutionLevels(membership_id)}
                onSelect={handleResolutionID}
                onSelectOption={handleResolutionName}
                column={'Level_name'}
                idValue={'Resolution_id'}
                key="resolutionlevels"
              />
              {/* <input type="text" placeholder="Query Priority Level" /> */}
            </div>
            <div className={styles.logInputInnerContainer}>
              <label htmlFor="">Expected Closure Date</label>
              <input type="text" placeholder="Expected Closure Date" />
            </div>
            <div className={styles.logInputInnerContainer}>
              <label htmlFor="">Enter Query Log Ticket</label>
              <input type="text" placeholder="Enter Query Log Ticket" />
            </div>
            <div className={styles.logButtonContainer}>
              <button
                className={styles.submit}
                onClick={() =>
                  searchTickets(resolutionID, ticketNumber, closureDate)
                }>
                Search
              </button>
              <button
                className={styles.reset}
                type="button"
                onClick={() => setClearModal(true)}>
                Reset
              </button>
            </div>
          </div>
        </div>
        <div className={styles.allQueryLogContainer}>
          <div className={styles.indicatorContainer}>
            <div className={styles.logText}>
              {!isSearch && <p>All Query Log Tickets (Forward)</p>}
              {isSearch && <p>Searched Log Tickets</p>}
            </div>
            <div className={styles.indicatorInnerContainer}>
              <div className={styles.indicatorBox}>
                <div className={styles.orange}></div>
                <label className={styles.labelText} htmlFor="">
                  Branch
                </label>
              </div>
              <div className={styles.indicatorBox}>
                <div className={styles.blue}></div>
                <label className={styles.labelText} htmlFor="">
                  Call Center
                </label>
              </div>
              <div className={styles.indicatorBox}>
                <div className={styles.pink}></div>
                <label className={styles.labelText} htmlFor="">
                  IT
                </label>
              </div>
              <div className={styles.indicatorBox}>
                <div className={styles.red}></div>
                <label className={styles.labelText} htmlFor="">
                  LSL Program Team
                </label>
              </div>
            </div>
          </div>
          { !isSearch &&
            (
              <>
                 <div className={styles.TableContainer}>
            <TicketTable
              handleView={handleView}
              data={modifiedOpenTickets}
              setShowModal={setShowModal}
              isRowViewed={isRowViewed}
              viewedRows={viewedRows}
            />
          </div>
        {  !modifiedOpenTickets.length == 0 &&
        
        <div className={styles.pagination}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={modifiedOpenTickets} setPage={setPage} />
          </div> 
          
          }
              </>
            )
          }

{ isSearch &&
            (
              <>
                 <div className={styles.TableContainer}>
            <TicketTable
              handleView={handleView}
              data={modifiedSearchTickets}
              setShowModal={setShowModal}
              isRowViewed={isRowViewed}
              viewedRows={viewedRows}
            />
          </div>
          {  !modifiedSearchTickets.length == 0 &&
          <div className={styles.pagination}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={modifiedOpenTickets} setPage={setPage} />
          </div>
        }
              </>
            )
          }
         
        </div>
        {!isSearch && (
          <>
            <div className={styles.allQueryLogContainer}>
              <div className={styles.indicatorContainer}>
                <div className={styles.logText}>
                  <p>All Closed Query Log Tickets</p>
                </div>
                {/* <div className={styles.indicatorInnerContainer}>
              <div className={styles.indicatorBox}>
                <div className={styles.orange}></div>
                <label className={styles.labelText} htmlFor="">
                  Branch
                </label>
              </div>
              <div className={styles.indicatorBox}>
                <div className={styles.blue}></div>
                <label className={styles.labelText} htmlFor="">
                  Call Center
                </label>
              </div>
              <div className={styles.indicatorBox}>
                <div className={styles.pink}></div>
                <label className={styles.labelText} htmlFor="">
                  IT
                </label>
              </div>
              <div className={styles.indicatorBox}>
                <div className={styles.red}></div>
                <label className={styles.labelText} htmlFor="">
                  LSL Program Team
                </label>
              </div>
            </div> */}
              </div>

              <div className={styles.TableContainer}>
                <TicketTable
                  handleView={handleView}
                  data={modifiedClosedTickets}
                  setShowModal={setShowModal}
                  viewedRows={viewedRows}
                />
              </div>
              <div className={styles.pagination}>
                {/* <p>Pagination</p> */}
                <Paginate
                  page={page}
                  data={modifiedClosedTickets}
                  setPage={setPage}
                />
              </div>
            </div>
          </>
        )}

        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />

        {showModal && (
          <LogDetailsModal
            onClose={onClose}
            data={modalData}
            setShowModal={setShowModal}
            name="Call Center Query Interaction"
            modalRef={modalRef}
          />
        )}
      </div>
    </CallCenterStructure>
  );
};

export default LogDetails;
