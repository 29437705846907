import React, {useState, useEffect} from 'react';
import {Mark, Times, ViewPen} from '../../assets/icons';
import SearchInput from '../../component/SearchInput/SearchInput';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/CodeDecode.module.scss';
import { useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import { Headers, ProgramID} from '../../service/Essentials';
// import { getUser } from '../../service/common';
// import { getToken } from '../../service/common';
// import { useNavigate } from "react-router-dom";
import Paginate from '../../component/Paginate/Paginate';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';

const CodeDecode = () => {

 // const navigate = useNavigate();
const queryClient = useQueryClient();

// const user = getUser();

// if(!user){
//     navigate('/');
//   }

  const program_id = ProgramID() ?? '';


  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  
  const fetchCodeDecodeTypeMaster = async (page) =>{
    const res = await axiosProperty.get(ApiConfig.fetchCodeDecodeTypeMaster+`?page=${page}`, {
        headers:Headers()
    }
    );
    return res.data;
  }  
  const {data, status} = useQuery(['codedecodetypemaster', page], () => fetchCodeDecodeTypeMaster(page), {keepPreviousData:true,});
  
  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data) {
      const filtered = data.data.filter(
        (item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };
  
  const [text, setText] = useState('');
 // const [successModal, setSuccessModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState();
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState(null);

  const handleChange = e => {
    setText(e.target.value);
  };

  const addCodeDecodeTypeMaster = async () => {
    try{
        await axiosProperty.post(ApiConfig.createCodeDecodeTypeMaster, {name: text}, 
        {
          headers:Headers()
      }
        );
       // setSuccessModal(true);
        setErrors(null);
        setText('');
        swal({
          title: "Success!",
          text: "Code Decode created successfully",
          icon: "success",
        });
    }catch(err){
      if(err.response){
      setError(err.response.data.message);
      setErrors(err.response.data);
      }else{
      setError('Something went wrong, probably network'); 
      }
      console.log(err);
      swal({
        title: "Error!",
        text: error,
        icon: "error",
      });
    }
  };

const {mutate:addCodeDecodeTypeMasterMutation} = useMutation(
  {
    mutationFn: addCodeDecodeTypeMaster,
    onSuccess:()=>{queryClient.invalidateQueries(['codedecodetypemaster'])}, 
  
  }
  );

  const updateCodeDecodeTypeMaster = async () => {
    //e.preventDefault();
    try{
        await axiosProperty.patch(ApiConfig.updateCodeDecodeTypeMaster+`/${updateID}`, {name: text},
        {
          headers:Headers()
      }
        );
       // setSuccessModal(true);
        setErrors(null);
        setText('');
        setIsSubmit(true);
        setIsUpdate(false);
        swal({
          title: "Success!",
          text: "Code Decode updated successfully",
          icon: "success",
        });
    }catch(err){
      if(err.response){
      setError(err.response.data.message);
      setErrors(err.response.data);
      }else{
      setError('Something went wrong, probably network'); 
      }
      console.log(err);
      swal({
        title: "Error!",
        text: error,
        icon: "error",
      });
    }
  };

  const {mutate:updateCodeDecodeTypeMasterMutation} = useMutation(
    {
      mutationFn: updateCodeDecodeTypeMaster,
      onSuccess:()=>{queryClient.invalidateQueries(['codedecodetypemaster'])}, 
    
    }
    );

  const editCodeDecodeTypeMaster = async (id) => {
       const res = await axiosProperty.get(ApiConfig.editCodeDecodeTypeMaster+`/${id}`,
        {
          headers:Headers()
      }
      );
      setIsUpdate(true); 
      setIsSubmit(false); 
      setText(res.data.data.name);
  };

  return (
    <CallCenterStructure>
      <div className={styles.transactionTypeContainer}>
        <div>
          <h6 className={styles.headerText}>Code-Decode Type Master</h6>
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="" className={styles.transactionTypeLabel}>
            Type Description<sup>*</sup>
          </label>

            <div className={styles.formContainer}>
            <input
              type="text"
              value={text}
              onChange={handleChange}
              className={styles.transactionTypeInput}
            />
           {isSubmit && (<button
              className={styles.transactionTypeBtn}
              onClick={()=>addCodeDecodeTypeMasterMutation()}>
              Submit
            </button>)}
            {isUpdate && (<button
              className={styles.transactionTypeBtn}
              onClick={()=>updateCodeDecodeTypeMasterMutation()}>
              Update
            </button>)}
          </div> 
          <p className={styles.transactionTypeNoteText}>
            *Note:Click on column header for Ascending/Descending
          </p>
        </div>
        <div className={styles.tableContainer}>
          <ErrorMessage errMsg={errors} />
          <div className={styles.formContainer}>
            <div className={styles.searchInput}>
              <SearchInput onSearchInputChange={handleSearchInputChange} />
            </div>
            {/* <button className={styles.transactionTypeBtn}>Search</button> */}
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.dataTable}>
              <thead>
                <tr>
                  <th>Type Code</th>
                  <th>Type Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {
                status === 'error' && (<tr><td className='text-danger'>Error Occured</td></tr>)
              }
              {
                status === 'loading' && (<tr><td className='text-info'>loading</td></tr>)
              }
                {status === 'success' && (filteredData.map(each => (
                  <tr key={each.id}>
                    <td>{each.id}</td>
                    <td>{each.name}</td>
                    <td onClick={()=>{editCodeDecodeTypeMaster(each.id); setUpdateID(each.id)}}>
                      <ViewPen />
                    </td>
                  </tr>
                )) )}


              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={data} setPage={setPage} />
          </div>
        </div>
        {/* {successModal && (
          <div className={styles.successModal}>
            <Mark />
            <p className={styles.successText}>
              Code Decode Type Inserted Successfully!
            </p>
            <span onClick={() => setSuccessModal(false)}>
              <Times />
            </span>
          </div>
        )} */}
      </div>
    </CallCenterStructure>
  );
};

export default CodeDecode;
