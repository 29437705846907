import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import defaultImage from '../../assets/images/defaultImage.png';
import styles from '../../styles/CompanyGameConfiguration.module.scss';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';
//import upload from '../../assets/images/upload.png';

const CompanyGameConfiguration = () => {
  const [clearModal, setClearModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  function imagePreview(e) {
    // console.log(e.target.files);
    setSelectedImage(URL.createObjectURL(e.target.files[0]));
  }

  return (
    <>
      <CallCenterStructure>
        <div className="mt-5 p-4">
          <h4 className="pb-3">Company Game Configuration</h4>
          <main className="border border-grey p-3">
            <form action="">
              <div className="row">
                <div className="form-group col-12 col-md-6 p-2">
                  <label htmlFor="selectGame">Select Game *</label>
                  <Dropdown />
                </div>
                <div className="form-group col-12 col-md-6 p-2">
                  <label className="" htmlFor="enterGameLevel">
                    Enter Game Level *
                  </label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="Till date"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-12 col-md-4 p-2">
                  <p>
                    <label htmlFor="selectBranch">Select Time for level</label>
                  </p>
                  <Dropdown />
                </div>
                <div className="form-group col-12 col-md-4 p-2">
                  <p>
                    <label htmlFor="selectBranch"></label>
                  </p>
                  <Dropdown />
                </div>
                <div className="form-group col-12 col-md-4 p-2">
                  <p>
                    <label htmlFor="selectBranch"></label>
                  </p>
                  <Dropdown />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-12 col-md-6 p-2">
                  <label htmlFor="selectView">
                    Enter Total Moves for Level *
                  </label>
                  <Dropdown />
                </div>
                <div className="form-group col-12 col-md-6 p-2">
                  <label htmlFor="selectView">
                    Bonus Lives for Completing Level *
                  </label>
                  <Dropdown />
                </div>
              </div>
              <div className="row">
                <div className="col-12 p-2">
                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      alt="default"
                      width="100"
                      height="100"
                    />
                  ) : (
                    <img src={defaultImage} alt="default" />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-12 p-2">
                  <div className={styles.uploadContainer}>
                    <input
                      onChange={imagePreview}
                      type="file"
                      className={styles.upload}
                      id="fileInput"
                    />
                    <label htmlFor="fileInput" className={styles.uploadLabel}>
                      Upload
                    </label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6 p-2">
                  <label htmlFor="">Matric Size For Image</label>
                  <input className="w-100" type="text" />
                </div>
              </div>

              <div className="form-group d-flex justify-content-between d-md-flex  mt-3">
                <button className="mr-5">Submit</button>
                <button
                  className="resetButton"
                  type="button"
                  onClick={() => setClearModal(true)}>
                  Reset
                </button>
              </div>
            </form>
          </main>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
        </div>
      </CallCenterStructure>
    </>
  );
};
export default CompanyGameConfiguration;
