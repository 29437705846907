import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import RadioButtonSingle from '../../component/RadioButtonSingle/RadioButtonSingle';
import DownloadButtonPdf from '../../component/DownloadButtonPdf/DownloadButtonPdf';
import DownloadButtonXlsx from '../../component/DownloadButtonXlsx/DownloadButtonXlsx';

const BranchTransactionReport = ()=>{

    const [selectedOption, setSelectedOption] = useState(null);

    const handleRadioButtonChange = (value) => {
    setSelectedOption(value);
  };

  const data = [
    {
    receipt: '#75588', membId: '67', transDate:'23/06/2023', membName:'Kenneth', 
    billAmount:5000, redeemAmount:8000, balPaid:16000, pointsGained:8
    }
  ];
    return(
    <>
        <CallCenterStructure>
        <div className='mt-5 p-4'>
           <h4 className='pb-4'>Branch Transaction Report</h4>
           <main className='border border-light p-3'>
           <form action="">
               <div className="row">
               <div className="form-group col-12 col-md-6" >
               <label className='d-flex justify-content-between' htmlFor="fromDate">From Date *</label>
                   <input className="w-100" type="text" placeholder='From date' />
               </div>
               <div className="form-group col-12 col-md-6" >
               <label className='d-flex justify-content-between' htmlFor="tillDate">Till Date *</label>
                   <input className="w-100" type="text" placeholder='Till date' />
               </div>
               </div>
              
             

               <div className="row">
               <div className="form-group col-12 col-md-6" >
               <p><label htmlFor="ViewType"></label></p>
               <div className="d-flex">
               <RadioButtonSingle
               name="ViewType"
               value="customer"
               selected={selectedOption === 'customer'}
                    borderWidth={2}
                    borderHeight={2}
                    onChange={handleRadioButtonChange}
               />Customer View
                <div className='w-25'></div>
               <RadioButtonSingle
               name="ViewType"
               value="product"
               selected={selectedOption === 'product'}
                    borderWidth={2}
                    borderHeight={2}
                    onChange={handleRadioButtonChange}
               />Product View
               </div>
               
               </div>
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="transactionType">Select Country *</label>
                   <Dropdown/>
               </div>
               </div>

               <div className="row">
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="selectRegion">Select Region *</label>
                   <Dropdown/>
               </div>
               <div className="form-group col-12 col-md-6" >
               <label htmlFor="selectZone">Select Zone *</label>
                   <Dropdown/>
               </div>
               </div>

               <div className="row">
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="selectState">Select State *</label>
                   <Dropdown/>
               </div>
               <div className="form-group col-12 col-md-6" >
               <label htmlFor="selectCity">Select City *</label>
                   <Dropdown/>
               </div>
               </div>

               <div className="row">
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="selectBranch">Select Branch *</label>
                   <Dropdown/>
               </div>
               <div className="form-group col-12 col-md-6" >
               <label htmlFor="selectView">Select View *</label>
                   <Dropdown/>
               </div>
               </div>


               <div className='form-group mt-3 d-flex justify-content-between d-md-flex justify-content-md-start'>
                   <button className='mr-5'>submit</button>
            </div>

           </form>
           </main>
           <div className='mt-4 d-flex justify-content-between d-md-flex justify-content-md-start'>
           <DownloadButtonPdf />
          <DownloadButtonXlsx />
           </div>
          
           <section className="border border-light mt-4 mb-4">
                <div className="row p-4">
                <div className="col-12 table-responsive">
                <table className='table'>
                    <thead>
                        <tr>
                        <th className=''>Transaction Date</th>
                        <th className=''>Posting Date</th>
                        <th className=''>Membership Name</th>
                        <th className=''>Membership Id</th>
                        <th className=''>Branch Name</th>
                        <th className=''>Branch Username</th>
                        <th className=''>Bill No.</th>
                        <th className=''>Transaction Amount</th>
                        <th className=''>Item Name</th>
                        <th className=''>Quantity</th>
                        <th className=''>Earned Point</th>
                        <th className=''>Campaign Earned Point</th>
                        <th className=''>remark</th>



                        </tr>
                        
                    </thead>
                    <tbody>
                        {
                            data.map((each, index) =>(
                             <tr key={index}>
                                <td>{each.receipt}</td>
                                <td>{each.transDate}</td>
                                <td>{each.membId}</td>
                                <td>{each.membName}</td>
                                <td>{each.billAmount}</td>
                                <td>{each.redeemAmount}</td>
                                <td>{each.balPaid}</td>
                                <td>{each.pointsGained}</td>
                                <td>{each.pointsGained}</td>
                                <td>{each.pointsGained}</td>
                                <td>{each.pointsGained}</td>
                                <td>{each.pointsGained}</td>
                                <td>{each.pointsGained}</td>

                             </tr>
                            ))
                        }
                    </tbody>
                </table>
                </div>
                <div className="col-12">Pagination</div>
                </div>
           </section>
           
        </div>
       </CallCenterStructure>
    </>
    );
}
export default BranchTransactionReport;