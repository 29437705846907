import React, {useState} from 'react';
import Dropdown from '../../component/DropDown/DropDown';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import styles from '../../styles/LSLPartnerMaster.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import defaultImage from '../../assets/images/defaultImage.png';
import SearchInput from '../../component/SearchInput/SearchInput';
import {Times, ViewPen} from '../../assets/icons';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const LSLPartnerMaster = () => {
  const [clearModal, setClearModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);

  const onClose = () => {
    setDeleteModal(false);
  };

  const partnerData = [
    {
      id: 5,
      partnerName: 'Wakanow',
      partnerType: 'Merchandize Partner',
      partnerAddress: 'Online Store',
      contactName: 'Eniola Taiwo',
      phoneNum: '08023327843',
      email: 'tadesola@hotmail.com',
    },
    {
      id: 3,
      partnerName: 'Wakanow',
      partnerType: 'Merchandize Partner',
      partnerAddress: 'Online Store',
      contactName: 'Eniola Taiwo',
      phoneNum: '08023327843',
      email: 'tadesola@hotmail.com',
    },
    {
      id: 2,
      partnerName: 'Wakanow',
      partnerType: 'Merchandize Partner',
      partnerAddress: 'Online Store',
      contactName: 'Eniola Taiwo',
      phoneNum: '08023327843',
      email: 'tadesola@hotmail.com',
    },
    {
      id: 8,
      partnerName: 'Wakanow',
      partnerType: 'Merchandize Partner',
      partnerAddress: 'Online Store',
      contactName: 'Eniola Taiwo',
      phoneNum: '08023327843',
      email: 'tadesola@hotmail.com',
    },
  ];

  return (
    <CallCenterStructure>
      <div className={styles.lslPartnerMasterContainer}>
        <div className={styles.headerText}>
          <p> LSL Partner Master</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.dropDownContainer}>
            <div className={styles.dropDownField}>
              <label htmlFor="">
                Select Partner Type<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.dropDownField}>
              <label htmlFor="">
                Select Partner Category<sup>*</sup>
              </label>
              <Dropdown />
            </div>
          </div>
          <div className={styles.inputFieldContainer}>
            <div className={styles.dropDownField}>
              <label htmlFor="">
                Enter Partner Name<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.dropDownField}>
              <label htmlFor="">
                Enter Partner Address<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.multipleDropDownContainer}>
            <div className={styles.singleDropDown}>
              <label htmlFor="">
                Select Country<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.singleDropDown}>
              <label htmlFor="">
                Select Region<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.singleDropDown}>
              <label htmlFor="">
                Select Zone<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.singleDropDown}>
              <label htmlFor="">
                Select State<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.singleDropDown}>
              <label htmlFor="">
                Select City<sup>*</sup>
              </label>
              <Dropdown />
            </div>
          </div>
          <div className={styles.inputFieldContainer}>
            <div className={styles.dropDownField}>
              <label htmlFor="">
                Enter Name Of Contact Person<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.dropDownField}>
              <label htmlFor="">
                Enter Phone Number<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.emailIdCheckboxContainer}>
            <div className={styles.emailIdCheckboxInnerContainer}>
              <label htmlFor="">
                Enter Email Id<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.emailIdCheckboxInnerContainer}>
              <label htmlFor="">
                Enter Website<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.emailIdCheckboxMainContainer}>
              <label htmlFor="">Corporate Email Id</label>
              <RadioButtonBlue firstText="Yes" secondText="No" />
            </div>
          </div>
          <div>
            <div className={styles.defaultImageContainer}>
              <div className={styles.imageContainer}>
                {selectedImage ? (
                  <img src={selectedImage} alt="default" />
                ) : (
                  <img src={defaultImage} alt="default" />
                )}
              </div>
              <p>Logo</p>
            </div>

            <div className={styles.uploadContainer}>
              <div className={styles.uploadInputContainer}>
                <label htmlFor="">Upload</label>
                <ImageDragAndDrop
                  selectedImageName={selectedImageName}
                  setSelectedImageName={setSelectedImageName}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </div>
            </div>
          </div>
          <div className={styles.inputFieldContainer}>
            <div className={styles.dropDownField}>
              <label htmlFor="">
                Enter VAT<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.dropDownField}>
              <label htmlFor="">
                Set Markup Percentage<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.btnContainer}>
            <button className={styles.submit}>Submit</button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputInnerContainer}>
            <SearchInput />
            <div className={styles.tableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Partner ID</th>
                    <th>Partner Name</th>
                    <th>Type of Partner</th>
                    <th>Partner Address</th>
                    <th>Contact Person Name</th>
                    <th>Contact Person Phone Number</th>
                    <th>Contact Person Email</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {partnerData.map(each => (
                    <tr>
                      <td>{each.id}</td>
                      <td>{each.partnerName}</td>
                      <td>{each.partnerType}</td>
                      <td>{each.partnerAddress}</td>
                      <td>{each.contactName}</td>
                      <td>{each.phoneNum}</td>
                      <td>{each.email}</td>
                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>
                          <span
                            className={styles.icon}
                            onClick={() => setDeleteModal(true)}>
                            <Times />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.paginationContainer}>
              <p>Pagination</p>
            </div>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && <MenuDeleteModal onClose={onClose} />}
      </div>
    </CallCenterStructure>
  );
};

export default LSLPartnerMaster;
