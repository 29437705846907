import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/AddFamilyMembersAtBranch.module.scss';
import {ViewPen} from '../../assets/icons';
import Dropdown from '../../component/DropDown/DropDown';
import {Link} from 'react-router-dom';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import {CustomerEnrollmentHeaders} from '../../service/Essentials';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';
import {useNavigate} from 'react-router-dom';

const AddFamilyMembersAtBranch = () => {
  const [searchItem, setSearchItem] = useState('');
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState(null);
  const [details, setDetails] = useState({});
  const [buttonStatus, setButtonStatus] = useState('Search');

  const navigate = useNavigate();

  const fetchMemberDetails = async () => {
    setButtonStatus('Fetching record');
    try {
      const res = await axiosProperty.get(
        ApiConfig.fetchParentDetails(searchItem),
        {
          headers: CustomerEnrollmentHeaders(),
        },
      );
      if (res.data.data === null) {
        swal({
          title: 'error!',
          text: 'Membership ID not found',
          icon: 'error',
        });
        setButtonStatus('Search');
        return;
      }
      setDetails(res.data.data);
      setSearchItem('');
      setErrors(null);
      setButtonStatus('Search');
      // navigate('/customer-enrolment/assign-family-member-membershipID-list', {state:res.data});
    } catch (err) {
      setDetails({});
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data);
        swal({
          title: 'error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, try again');
        swal({
          title: 'error!',
          text: error,
          icon: 'error',
        });
      }
      setButtonStatus('Search');
    }
  };

  const fetchProfile = async id => {
    try {
      const res = await axiosProperty.get(ApiConfig.fetchProfileDetails(id), {
        headers: CustomerEnrollmentHeaders(),
      });

      if (res.status === 404) {
        swal({
          title: 'Warning!',
          text: 'No record found',
          icon: 'warning',
        });
        return;
      }
      navigate('/customer-enrolment/add-family-member-at-branch-form', {
        state: res.data,
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        swal({
          title: 'error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, try again');
        swal({
          title: 'error!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const data = Object.keys(details).length > 0 ? [details] : [];

  return (
    <CallCenterStructure>
      <div className={styles.addFamilyMembersAtBranchContainer}>
        <div className={styles.headerText}>
          <p>Add Family Member at Branch</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldInputContainer1}>
            <div className={styles.fieldInputInnerContainer1}>
              <label htmlFor="">
                First Name<sup>*</sup>
              </label>
              <input type="text" />
            </div>

            <div className={styles.fieldInputInnerContainer1}>
              <label htmlFor="">
                Last Name<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.fieldInputInnerContainer1}>
              <label htmlFor="">
                Memebership ID<sup>*</sup>
              </label>
              <input type="text" onChange={(e)=>{setSearchItem(e.target.value)}} />
            </div>
          </div>
          <div className={styles.fieldInputContainer2}>
            <div className={styles.fieldInputInnerContainer2}>
              <label htmlFor="">
                Phone Number<sup>*</sup>
              </label>
              <input type="tel" onChange={(e)=>{setSearchItem(e.target.value)}} />
            </div>

            <div className={styles.fieldInputInnerContainer2}>
              <label htmlFor="">
                Email ID <sup>*</sup>
              </label>
              <input type="email" />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            {/* <Link to="/customer-enrolment/assign-family-member-membershipID-list">
              <button className={styles.enter}>Enter</button>
            </Link> */}
            <button
              className={styles.enter}
              onClick={() => fetchMemberDetails(searchItem)}>
              {buttonStatus}
            </button>
          </div>
        </div>
        <div className={styles.tableMainContainer}>
          <table className={styles.HMQTable}>
            <thead className={styles.handleMemberQueryTable}>
              <tr>
                <th className={styles.firstColumn}>First Name</th>
                <th>Middle Name</th>
                <th>Last Name</th>
                <th>Membership ID</th>
                <th>Phone Number</th>
                <th>Birth Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((result, index) => (
                  <tr key={result.membershipID} className={styles.resultRows}>
                    <td className={styles.firstColumn}>{result.First_name}</td>
                    <td>{result.Middle_name}</td>
                    <td>{result.Last_name}</td>
                    <td>{result.Membership_id}</td>
                    <td>{result.Phone_no}</td>
                    <td>{result.Birth_date}</td>
                    <td
                      className={styles.lastChild}
                      onClick={() => fetchProfile(1)}>
                      {/* <Link to="/call-center/member-profile"> */}
                      <ViewPen />
                      {/* {result.Action}
                        </Link> */}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>No record</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </CallCenterStructure>
  );
};

export default AddFamilyMembersAtBranch;
