import React, {useState, useEffect} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import {Times, ViewPen} from '../../assets/icons';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import styles from '../../styles/ProductBrandMaster.module.scss';
import SearchInput from '../../component/SearchInput/SearchInput';
import Dropdown from '../../component/DropDown/DropDown';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import {Headers, RedirectToLogin} from '../../service/Essentials';
import swal from 'sweetalert';
import {ProgramID} from '../../service/Essentials';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const ProductBrandMaster = () => {
  const [clearModal, setClearModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [productGroupID, setProductGroupID] = useState('');
  const [productGroup, setProductGroup] = useState('');
  const [brandCode, setBrandCode] = useState('');
  const [brandName, setBrandName] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState('');
  const [selectedItemID, setSelectedItemId] = useState('');
  const [error, setError] = useState();
  const [errors, setErrors] = useState(null);
  const queryClient = useQueryClient();
  const onClose = () => {
    setDeleteModal(false);
  };

  const handleProductGroupID = id => {
    setProductGroupID(id);
  };

  const handleProductGroup = name => {
    setProductGroup(name);
  };

  const formData = {
    product_group_id: productGroupID,
    brand_code: brandCode,
    brand_name: brandName,
    company_setup_id: ProgramID(),
  };

  RedirectToLogin();

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const fetchProductBrands = async page => {
    const res = await axiosProperty.get(
      ApiConfig.fetchProductBrand + `?page=${page}`,
      {
        headers: Headers(),
      },
    );
    return res.data;
  };
  const {data, status} = useQuery(
    ['productbrands', page],
    () => fetchProductBrands(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = searchTerm => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data) {
      const filtered = data.data.filter(
        (item) =>
          item.brand_name.toLowerCase().includes(searchTerm.toLowerCase())||
          item.brand_code.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  const addProductBrand = async () => {
    try {
      await axiosProperty.post(ApiConfig.createProductBrand, formData, {
        headers: Headers(),
      });
      setBrandCode('');
      setBrandName('');
      setErrors(null);
      swal({
        title: 'Success!',
        text: 'Product brand created successfully',
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);
      swal({
        title: 'Error!',
        text: error || 'server issue',
        icon: 'error',
      });
    }
  };

  const {mutate: addProductBrandMutation} = useMutation({
    mutationFn: addProductBrand,
    onSuccess: () => {
      queryClient.invalidateQueries(['productbrands']);
    },
  });

  const updateProductBrand = async () => {
    try {
      await axiosProperty.patch(
        ApiConfig.updateProductBrand + `/${updateID}`,
        formData,
        {
          headers: Headers(),
        },
      );
      setBrandCode('');
      setBrandName('');
      setIsSubmit(true);
      setIsUpdate(false);

      swal({
        title: 'Success!',
        text: 'Product brand updated successfully',
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);

      swal({
        title: 'Error!',
        text: error,
        icon: 'error',
      });
    }
  };

  const {mutate: updateProductBrandMutation} = useMutation({
    mutationFn: updateProductBrand,
    onSuccess: () => {
      queryClient.invalidateQueries(['productbrands']);
    },
  });

  const editProductBrand = async id => {
    try {
      const res = await axiosProperty.get(
        ApiConfig.editProductBrand + `/${id}`,
        {
          headers: Headers(),
        },
      );
      setIsUpdate(true);
      setIsSubmit(false);
      setBrandCode(res.data.data.brand_code || 'no data');
      setBrandName(res.data.data.brand_name || 'no data');
      setErrors(null);
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
        setErrors(err.response.data);
      }
      console.log(err);
      swal({
        title: "Warning!",
        text: error || 'something went wrong',
        icon: "warning",
      });
    }
  };

  const deleteProductBrand = async id => {
    try {
      const res = await axiosProperty.delete(
        ApiConfig.deleteProductBrand + `/${id}`,
        {
          headers: Headers(),
        },
      );
      setDeleteModal(false);
      swal({
        title: 'Success!',
        text: 'Product brand deleted successfully',
        icon: 'success',
      });
      queryClient.invalidateQueries(['productbrands']);
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);

  swal({
    title: "Error!",
    text: error || 'something went wrong',
    icon: "error",
  });

}
  }
  return (
    <CallCenterStructure>
      <div className={styles.productGroupMaster}>
        <div className={styles.headerText}>
          <p>Product Brand Master</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select Product Group<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchProductGroup} onSelect={handleProductGroupID} onSelectOption={handleProductGroup} column={'group_name'} retrievedValue={productGroupID} key="productgroup"/>
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Enter Product Brand Code<sup>*</sup>
              </label>
              <input
                value={brandCode}
                type="text"
                onChange={e => setBrandCode(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Enter Product Brand Name<sup>*</sup>
              </label>
              <input
                value={brandName}
                type="text"
                onChange={e => setBrandName(e.target.value)}
              />
            </div>
          </div>

          <div className={styles.buttonContainer}>
            {isSubmit && (
              <button
                className={styles.submit}
                onClick={() => addProductBrandMutation()}>
                Submit
              </button>
            )}
            {isUpdate && (
              <button
                className={styles.submit}
                onClick={() => updateProductBrandMutation()}>
                Update
              </button>
            )}
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
          <ErrorMessage errMsg={errors} />
        </div>
        <div className={styles.inputContainer}>
          <SearchInput onSearchInputChange={handleSearchInputChange} />
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Product Group Code</th>
                  <th>Product Group Name</th>
                  {/* <th>Description</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map(each => (
                    <tr key={each.brand_code}>
                      <td>{each.brand_code}</td>
                      <td>{each.brand_name}</td>
                      {/* <td>{each.desc}</td> */}

                      <td>
                        <div className={styles.iconContainer}>
                          <span
                            className={styles.icon}
                            onClick={() => {
                              editProductBrand(each.id);
                              setUpdateID(each.id);
                            }}>
                            <ViewPen />
                          </span>
                          <span
                            className={styles.icon}
                            onClick={() => {
                              setDeleteModal(true);
                              setSelectedItemId(each.id);
                            }}>
                            <Times />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colspan="3">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={data} setPage={setPage} />
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && (
          <MenuDeleteModal
            onClose={onClose}
            onDelete={() => deleteProductBrand(selectedItemID)}
          />
        )}
      </div>
    </CallCenterStructure>
  );
};

export default ProductBrandMaster;
