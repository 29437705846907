import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/CreateCatalogueMerchandizeCategory.module.scss';
import Dropdown from '../../component/DropDown/DropDown';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import SearchInput from '../../component/SearchInput/SearchInput';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import {Times, ViewPen} from '../../assets/icons';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CreateCatalogueMerchandizeCategory = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [clearModal, setClearModal] = useState(false);
  const onClose = () => {
    setDeleteModal(false);
  };
  const data = [
    {id: 2, name: 'Laundry Services', category: 'So Fresh'},
    {id: 1, name: 'Laundry Services', category: 'So Fresh'},
    {id: 3, name: 'Laundry Services', category: 'So Fresh'},
  ];
  return (
    <CallCenterStructure>
      <div className={styles.mainContainer}>
        <div className={styles.headerText}>
          <p>LSL Merchandize Catalogue Category</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputInnerContainer}>
            <div className={styles.labelAndInputContainer}>
              <label htmlFor="">Parent Category</label>
              <Dropdown />
            </div>
            <div className={styles.labelAndInputContainer}>
              <label htmlFor="">
                Merchandize Category Name<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.labelAndRadioContainer}>
              <label htmlFor="">
                Make Link<sup>*</sup>
              </label>
              <RadioButtonBlue firstText="Yes" secondText="No" />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Submit</button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.searchInputContainer}>
            <SearchInput />
            <button className={styles.search}>Search</button>
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Merchandize Category ID</th>
                  <th>Merchandize Category Name</th>
                  <th>Parent Category Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map(each => (
                  <tr>
                    <td>{each.id}</td>
                    <td>{each.name}</td>
                    <td>{each.category}</td>
                    <td>
                      <div className={each.iconContainer}>
                        <span className={styles.icon}>
                          <ViewPen />
                        </span>
                        <span
                          className={styles.icon}
                          onClick={() => setDeleteModal(true)}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.pagination}>
            <p>Pagination</p>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && <MenuDeleteModal onClose={onClose} />}
      </div>
    </CallCenterStructure>
  );
};

export default CreateCatalogueMerchandizeCategory;
