import React, {useState} from 'react';
import styles from '../../styles/TemplateNavbar.module.scss';
import logo from '../../assets/images/PerxLogo.png';
import {
  ChevronDown,
  ChevronDownMobile,
  ChevronDownTemplate,
  ChevronRight,
  ChevronUpTemplate,
  MobileProfile,
  ProfileIcon,
  SilverIcon,
  TemplateCartIcon,
  TemplateHamburger,
  TemplateSearchIcon,
} from '../../assets/icons';

const TemplateNavbar = () => {
  const [activeNav, setActiveNav] = useState([
    'shop',
    'airtime & bills',
    'meals',
    'entertainments',
    'voucher',
  ]);
  const [mobileNav, setMobileNav] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isMenu, setIsMenu] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setIsMenu(false);
  };

  const toggleMenu = () => {
    setIsMenu(!isMenu);
    setIsOpen(false);
  };

  const toggleMobileNav = () => {
    setMobileNav(!mobileNav);
  };

  return (
    <>
      <ul className={styles.navContainer}>
        <li
          className={styles.templateHmaburgerContainer}
          onClick={toggleMobileNav}>
          <TemplateHamburger />
        </li>
        <li>
          <div className={styles.navLogo}>
            <img src={logo} alt="" />
          </div>
        </li>
        <li className={styles.searchOutterContainer}>
          <div className={styles.searchContainer}>
            <div className={styles.searchIconContainer}>
              <TemplateSearchIcon />
            </div>

            <input
              type="search"
              placeholder="Search for products"
              className={styles.navSearch}
            />
          </div>
        </li>
        <li className={styles.memberIconContainer}>
          <div className={styles.memberIcon}>
            <SilverIcon />
            <p>Silver Member</p>
          </div>
        </li>
        <li className={styles.balanceOutterContainer}>
          <div className={styles.balanceContainer}>
            <p>Balance: </p>
            <h6>28,000 Points</h6>
          </div>
        </li>
        <li>
          <div>
            <TemplateCartIcon />
          </div>
        </li>
        <li className={styles.dropDownContainer}>
          <div className={styles.dropdown}>
            <div className={styles.dropdownTitle} onClick={toggleDropdown}>
              <ProfileIcon />
              <p>
                Hi, <span className={styles.username}>Rasgan</span>
              </p>
            </div>
            {isOpen && (
              <ul className={styles.dropdownOptions}>
                <li>My Profile</li>
                <li>Logout</li>
              </ul>
            )}
          </div>
        </li>
      </ul>
      {mobileNav && (
        <div className={`${styles.mobileNav} ${mobileNav ? styles.open : ''}`}>
          <div className={styles.navContent}>
            <div className={styles.topContent}>
              <div className={styles.topUsernameContent}>
                <div className={styles.usernameMobile}>
                  <ProfileIcon />
                  <p>
                    Hi, <span className={styles.username}>Rasgan</span>
                  </p>
                </div>
                <p className={styles.mobileLogoutText}>Logout</p>
              </div>
              <div className={styles.mobileBalanceContainer}>
                <p className={styles.mobileBalanceText}>Balance: </p>
                <p className={styles.mobileBalanceNum}>28, 000</p>
              </div>
              <div className={styles.memberIcon}>
                <SilverIcon />
                <p>Silver Member</p>
              </div>
            </div>
            <div className={styles.bottomContent}>
              <ul className={styles.mobileNavContent}>
                <li
                  className={`${
                    activeNav === 'shop' ? styles.activeMobileNav : ''
                  }`}
                  onClick={() => setActiveNav('shop')}>
                  <div className={styles.dropdownMobile}>
                    <div
                      className={styles.dropdownMobileTitle}
                      onClick={toggleDropdown}>
                      <p>Shop</p>
                      <div>
                        {isOpen ? <ChevronDownMobile /> : <ChevronRight />}
                      </div>
                    </div>
                    {isOpen && (
                      <ul className={styles.dropdownMobileOptions}>
                        <li>My Profile</li>
                        <li>Logout</li>
                        <li>My Profile</li>
                        <li>Logout</li>
                        <li>My Profile</li>
                        <li>Logout</li>
                        <li>My Profile</li>
                        <li>Logout</li>
                      </ul>
                    )}
                  </div>
                </li>
                <li
                  className={`${
                    activeNav === 'airtime & bills'
                      ? styles.activeMobileNav
                      : ''
                  }`}
                  onClick={() => setActiveNav('airtime & bills')}>
                  <p>Airtime & Bills</p>
                </li>
                <li
                  className={`${
                    activeNav === 'meals' ? styles.activeMobileNav : ''
                  }`}
                  onClick={() => setActiveNav('meals')}>
                  <p>Meals</p>
                </li>
                <li
                  className={`${
                    activeNav === 'entertainment' ? styles.activeMobileNav : ''
                  }`}
                  onClick={() => setActiveNav('entertainment')}>
                  <div className={styles.dropdownMobile1}>
                    <div
                      className={styles.dropdownMobile1Title}
                      onClick={toggleMenu}>
                      <p>Entertainment</p>
                      <div>
                        {isMenu ? <ChevronDownMobile /> : <ChevronRight />}
                      </div>
                    </div>
                    {isMenu && (
                      <ul className={styles.dropdownMobile1Options}>
                        <li>My Profile</li>
                        <li>Logout</li>
                      </ul>
                    )}
                  </div>
                </li>
                <li
                  className={`${
                    activeNav === 'voucher' ? styles.activeMobileNav : ''
                  }`}
                  onClick={() => setActiveNav('voucher')}>
                  <p>Vouchers</p>
                </li>
                <li className={styles.mobileLine}></li>
                <li>
                  <div className={styles.mobileProfile}>
                    <MobileProfile />
                    <p>Profile</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TemplateNavbar;
