import React from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/Dashboard.module.scss';
import premium from '../../assets/images/premium.png';
import premiumPlus from '../../assets/images/premiumPlus.png';
import coalition from '../../assets/images/coalition.png';
import enterprise from '../../assets/images/enterprise.png';
import bank from '../../assets/images/bank.png';
import retail from '../../assets/images/retail.png';
import fmcg from '../../assets/images/fmcg.png';
import telco from '../../assets/images/telco.png';

const DashBoard = () => {
  return (
    <>
      <CallCenterStructure>
        <div className={styles.dashboardContainer}>
          <div>
            <h4 className={styles.dashboardText}>Dashbaord</h4>
          </div>

          <div>
            <div className={styles.mainContainer}>
              <div className={styles.leftMainContainer}>
                <p className={styles.boxHeader}>Total Clients (By Solutions)</p>
                <div className={styles.leftInnerContainer}>
                  <div className={styles.premiumContainer}>
                    <div className={styles.premiumInnerContainer}>
                      <div>
                        <img src={premium} alt="premium" />
                      </div>
                      <div>
                        <p className={styles.boxText}>Premium</p>
                        <h5 className={styles.boxNumber}>0</h5>
                      </div>
                    </div>
                  </div>

                  <div className={styles.premiumPlusContainer}>
                    <div className={styles.premiumPlusInnerContainer}>
                      <div>
                        <img src={premiumPlus} alt="premium plus" />
                      </div>
                      <div>
                        <p className={styles.boxText}>Premium Plus</p>
                        <h5 className={styles.boxNumber}>0</h5>
                      </div>
                    </div>
                  </div>

                  <div className={styles.coalitionContainer}>
                    <div className={styles.coalitionInnerContainer}>
                      <div>
                        <img src={coalition} alt="coalition" />
                      </div>
                      <div>
                        <p className={styles.boxText}>Coalition</p>
                        <h5 className={styles.boxNumber}>0</h5>
                      </div>
                    </div>
                  </div>

                  <div className={styles.enterpriseContainer}>
                    <div className={styles.enterpriseInnerContainer}>
                      <div>
                        <img src={enterprise} alt="enterprise" />
                      </div>
                      <div>
                        <p className={styles.boxText}>Enterprise</p>
                        <h5 className={styles.boxNumber}>0</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.rightMainContainer}>
                <p className={styles.boxHeader}>Total Clients (By Companies)</p>
                <div className={styles.rightInnerContainer}>
                  <div className={styles.bankContainer}>
                    <div className={styles.bankInnerContainer}>
                      <div>
                        <img src={bank} alt="bank" />
                      </div>
                      <div>
                        <p className={styles.boxText}>Bank</p>
                        <h5 className={styles.boxNumber}>0</h5>
                      </div>
                    </div>
                  </div>

                  <div className={styles.retailContainer}>
                    <div className={styles.retailInnerContainer}>
                      <div>
                        <img src={retail} alt="retail" />
                      </div>
                      <div>
                        <p className={styles.boxText}>Retail</p>
                        <h5 className={styles.boxNumber}>0</h5>
                      </div>
                    </div>
                  </div>

                  <div className={styles.fmcgContainer}>
                    <div className={styles.fmcgInnerContainer}>
                      <div>
                        <img src={fmcg} alt="fmcg" />
                      </div>
                      <div>
                        <p className={styles.boxText}>FMCG</p>
                        <h5 className={styles.boxNumber}>0</h5>
                      </div>
                    </div>
                  </div>

                  <div className={styles.telcoContainer}>
                    <div className={styles.telcoInnerContainer}>
                      <div>
                        <img src={telco} alt="telco" />
                      </div>
                      <div>
                        <p className={styles.boxText}>Telco</p>
                        <h5 className={styles.boxNumber}>0</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.belowMainContainer}>
              <div className={styles.leftBelowMainContainer}>
                <div className={styles.totalsContainer}>
                  <div className={styles.totalContainer}>
                    <div className={styles.totalTextContainer}>
                      <p>Total No. of Branches</p>
                    </div>

                    <div className={styles.totalNumberContainer}>
                      <p>273</p>
                    </div>
                  </div>
                  <div className={styles.totalContainer}>
                    <div className={styles.totalTextContainer}>
                      <p>Total No. of Transactions:</p>
                    </div>

                    <div className={styles.totalNumberContainer}>
                      <p>3409</p>
                    </div>
                  </div>
                  <div className={styles.totalContainer}>
                    <div className={styles.totalTextContainer}>
                      <p>Total No. of Partners:</p>
                    </div>

                    <div className={styles.totalNumberContainer}>
                      <p>38</p>
                    </div>
                  </div>
                  <div className={styles.totalContainer}>
                    <div className={styles.totalTextContainer}>
                      <p>Total No. of Transactions:</p>
                    </div>

                    <div className={styles.totalNumberContainer}>
                      <p>3409</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.rightBelowMainContainer}>
                <div className={styles.totalsContainer}>
                  <div className={styles.totalContainer}>
                    <div className={styles.totalTextContainer}>
                      <p>Total Enrollments</p>
                    </div>

                    <div className={styles.totalNumberContainer}>
                      <p>1541</p>
                    </div>
                  </div>
                  <div className={styles.totalContainer}>
                    <div className={styles.totalTextContainer}>
                      <p>Total Member Enrolled</p>
                    </div>

                    <div className={styles.totalNumberContainer}>
                      <p>1532</p>
                    </div>
                  </div>
                  <div className={styles.totalContainer}>
                    <div className={styles.totalTextContainer}>
                      <p>Total System User Enrolled</p>
                    </div>

                    <div className={styles.totalNumberContainer}>
                      <p>9</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.lastSection}>
              <div className={styles.leftLastSection}>
                <div className={styles.lastSectionTextContainer}>
                  <div>
                    <img src={fmcg} alt="fmcg" />
                  </div>
                  <div className={styles.redeemAndIssuedPoints}>
                    <p>Total Points Redeemed</p>
                    <h3>1293982</h3>
                  </div>
                </div>
              </div>
              <div className={styles.rightLastSection}>
                <div className={styles.lastSectionTextContainer}>
                  <div>
                    <img src={fmcg} alt="fmcg" />
                  </div>
                  <div className={styles.redeemAndIssuedPoints}>
                    <p>Total Points Issued</p>
                    <h3>28725916</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CallCenterStructure>
    </>
  );
};

export default DashBoard;
