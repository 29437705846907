import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Times, ViewPen} from '../../assets/icons';
import Dropdown from '../../component/DropDown/DropDown';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import styles from '../../styles/CreateValueSpendRegularCampaign.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import {campaignHeaders} from '../../service/Essentials';
import {useQuery, useQueryClient} from 'react-query';
import Paginate from '../../component/Paginate/Paginate';
import swal from 'sweetalert';

const ValueSpendRegularCampaignTable = () => {
  const [page, setPage] = useState(1);
  const queryClient = useQueryClient();
  const [error, setError] = useState('oops... something went wrong');
  const [deleteModal, setDeleteModal] = useState(false);
  const [valueSpend, setValueSpend] = useState([]);
  const [deleteId, setDeleteId] = useState('');

  const [selectedValues, setSelectedValues] = useState({});

  const handleRadioButtonChange = (id, checked) => {
    setSelectedValues(prevValues => ({
      ...prevValues,
      [id]: checked,
    }));
  };

  const onClose = () => {
    setDeleteModal(false);
  };

  const fetchValueSpend = async page => {
    const res = await axiosProperty.get(
      ApiConfig.fetchValueSpendCampaign(page),
      {
        headers: campaignHeaders(),
      },
    );
    return res.data;
  };

  const {data: valueSpendData, status} = useQuery(
    ['valuespend', page],
    () => fetchValueSpend(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setValueSpend(valueSpendData?.data || []);
  }, [valueSpendData]);

  const deleteValueSpendCampaign = async id => {
    try {
      const res = await axiosProperty.delete(ApiConfig.deleteValueSpend(id), {
        headers: campaignHeaders(),
      });
      setDeleteModal(false);
      swal({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
      queryClient.invalidateQueries('valuespend');
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);
      swal({
        title: 'Warning!',
        text: error,
        icon: 'warning',
      });
    }
  };

  return (
    <CallCenterStructure>
      <div className={styles.createValueSpendRegularCampaign}>
        <div className={styles.createValueHeader}>
          <div className={styles.headerText}>
            <p>Value/Spend Regular Campaign Table</p>
          </div>
          <Link to="/company-campaigns/create-value-spend-regular-campaign">
            <button>Create Campaign </button>
          </Link>
        </div>

        <div className={styles.inputContainer}>
          <p className={styles.disabledCampaigns}>Active Campaigns</p>
          <div className={styles.tableContainer1}>
            <table className={styles.mainTable1}>
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th>Linked to LBS?</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Transaction Amount</th>
                  <th>Reward Points</th>
                  <th>Rewards Percent</th>
                  <th>Fixed Amount</th>
                  <th>Fixed Percent/Points</th>
                  <th>Discreet Amount</th>
                  <th>Discreet Percent/Points</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {status === 'error' && (
                  <tr>
                    <td className="text-danger">Error Occured!</td>
                  </tr>
                )}

                {status === 'loading' && (
                  <tr>
                    <td className="text-info">loading...</td>
                  </tr>
                )}

                {status === 'success' &&
                  (valueSpend.length === 0 ? (
                    <tr>
                      <td>No Data!</td>
                    </tr>
                  ) : (
                    valueSpend?.map(
                      each =>
                        each?.Active_flag === 1 && (
                          <tr key={each.Campaign_id}>
                            <td>{each.Campaign_name}</td>
                            <td>{each.LBS_link}</td>
                            <td>{each.From_date}</td>
                            <td>{each.To_date}</td>
                            <td>{each.Transaction_amount}</td>
                            <td>{each.Reward_points}</td>
                            <td>{each.Reward_percent}</td>
                            <td>{each.Fixed_amount}</td>
                            <td>{each.First_iteration_percentage}</td>
                            <td>{each.Discrete_amt}</td>
                            <td>{each.Second_iteration_percentage}</td>

                            <td>
                              <div className={styles.iconContainer}>
                                <span
                                  className={styles.icon}
                                  onClick={() => {
                                    localStorage.setItem(
                                      'eachSpend',
                                      JSON.stringify(each),
                                    );
                                  }}>
                                  <Link
                                    to={`/company-campaigns/edit-value-spend-regular-campaign?id=${each.Campaign_id}`}>
                                    <ViewPen />
                                  </Link>
                                </span>
                                <span
                                  className={styles.icon}
                                  onClick={() => {
                                    setDeleteId(each.Campaign_id);
                                    setDeleteModal(true);
                                  }}>
                                  <Times />
                                </span>
                              </div>
                            </td>
                          </tr>
                        ),
                    )
                  ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <Paginate page={page} data={valueSpendData} setPage={setPage} />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <p className={styles.disabledCampaigns}>Disabled Campaigns</p>

          <div className={styles.tableContainer1}>
            <table className={styles.mainTable1}>
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th>Linked to LBS?</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Transaction Amount</th>
                  <th>Reward Points</th>
                  <th>Rewards Percent</th>
                  <th>Fixed Amount</th>
                  <th>Fixed Percent/Points</th>
                  <th>Discreet Amount</th>
                  <th>Discreet Percent/Points</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {status === 'error' && (
                  <tr>
                    <td className="text-danger">Error Occured!</td>
                  </tr>
                )}

                {status === 'loading' && (
                  <tr>
                    <td className="text-info">loading...</td>
                  </tr>
                )}

                {status === 'success' &&
                  (valueSpend.length === 0 ? (
                    <tr>
                      <td>No Data!</td>
                    </tr>
                  ) : (
                    valueSpend?.map(
                      each =>
                        each?.Active_flag === 0 && (
                          <tr key={each.Campaign_id}>
                            <td>{each.Campaign_name}</td>
                            <td>{each.LBS_link}</td>
                            <td>{each.From_date}</td>
                            <td>{each.To_date}</td>
                            <td>{each.Transaction_amount}</td>
                            <td>{each.Reward_points}</td>
                            <td>{each.Reward_percent}</td>
                            <td>{each.Fixed_amount}</td>
                            <td>{each.First_iteration_percentage}</td>
                            <td>{each.Discrete_amt}</td>
                            <td>{each.Second_iteration_percentage}</td>

                            <td>
                              <div className={styles.iconContainer}>
                                <span
                                  className={styles.icon}
                                  onClick={() => {
                                    localStorage.setItem(
                                      'eachSpend',
                                      JSON.stringify(each),
                                    );
                                  }}>
                                  <Link
                                    to={`/company-campaigns/edit-value-spend-regular-campaign?id=${each.Campaign_id}`}>
                                    <ViewPen />
                                  </Link>
                                </span>
                                <span
                                  className={styles.icon}
                                  onClick={() => {
                                    setDeleteId(each.Campaign_id);
                                    setDeleteModal(true);
                                  }}>
                                  <Times />
                                </span>
                              </div>
                            </td>
                          </tr>
                        ),
                    )
                  ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <Paginate page={page} data={valueSpendData} setPage={setPage} />
          </div>
        </div>
      </div>
      {deleteModal && (
        <MenuDeleteModal
          onClose={onClose}
          onDelete={() => deleteValueSpendCampaign(deleteId)}
        />
      )}
    </CallCenterStructure>
  );
};

export default ValueSpendRegularCampaignTable;
