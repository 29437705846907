import React from 'react';
import CheckBox from '../../component/CheckBox/CheckBox';
import Dropdown from '../../component/DropDown/DropDown';
import styles from '../../styles/AssignAdditionalMenuForCompany.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';

const AssignAdditionalMenuForCompany = () => {
  return (
    <div>
      <CallCenterStructure>
        <div className={styles.AssignAdditionalMenuForCompany}>
          <div className={styles.headerText}>
            <p>Assign Additional Menu for Company</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.fieldContainer}>
              <label htmlFor="">
                Select Company<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.checkboxContainer}>
              <div className={styles.checkoutMainContainer}>
                <div className={styles.checkboxInnerContainer}>
                  <div className={styles.header}>
                    <div className={styles.td}>
                      <p>Level-0</p>
                    </div>
                    <div className={styles.td}>
                      <p>Level-1</p>
                    </div>
                    <div className={styles.td}>
                      <p>Level-2</p>
                    </div>
                    <div className={styles.td}>
                      <p>Level-3</p>
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.td}>
                      <p>Fidelity Green Rewards</p>
                    </div>
                    <div className={styles.td}>
                      <p></p>
                    </div>
                    <div className={styles.td}>
                      <p></p>
                    </div>
                    <div className={styles.td}>
                      <CheckBox />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.td}>
                      <p></p>
                    </div>
                    <div className={styles.td}>
                      <p>Company Setup</p>
                    </div>
                    <div className={styles.td}>
                      <p></p>
                    </div>
                    <div className={styles.td}>
                      <CheckBox />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.td}>
                      <p></p>
                    </div>
                    <div className={styles.td}>
                      <p></p>
                    </div>
                    <div className={styles.td}>
                      <p>Company Setup</p>
                    </div>
                    <div className={styles.td}>
                      <CheckBox />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.td}>
                      <p></p>
                    </div>
                    <div className={styles.td}>
                      <p></p>
                    </div>
                    <div className={styles.td}>
                      <p>Define Loyalty Program</p>
                    </div>
                    <div className={styles.td}>
                      <CheckBox />
                    </div>
                  </div>
                  <div className={styles.row}>
                    <div className={styles.td}>
                      <p></p>
                    </div>
                    <div className={styles.td}>
                      <p></p>
                    </div>
                    <div className={styles.td}>
                      <p>Create Company Branches</p>
                    </div>
                    <div className={styles.td}>
                      <CheckBox />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <button className={styles.submitBTN}>Submit</button>
              </div>
            </div>
          </div>
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default AssignAdditionalMenuForCompany;
