import React, {useState} from 'react';
import Dropdown from '../../component/DropDown/DropDown';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import styles from '../../styles/CreateCatalogueMerchandizeItems.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import defaultImage from '../../assets/images/defaultImage.png';
import CheckBox from '../../component/CheckBox/CheckBox';
import SearchInput from '../../component/SearchInput/SearchInput';
import {Times, ViewPen} from '../../assets/icons';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CreateCatalogueMerchandizeItems = () => {
  const [clearModal, setClearModal] = useState(false);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);

  const onClose = () => {
    setDeleteModal(false);
  };

  const tableData = [
    {
      code: 435,
      category: 'category',
      name: 'item 1',
      partner: 'partner 2',
      percentage: 5,
      cost: '3,456',
      billing: '2,203',
      validity: '12/05/23 to 12/05/24',
    },
    {
      code: 435,
      category: 'category',
      name: 'item 1',
      partner: 'partner 2',
      percentage: 5,
      cost: '3,456',
      billing: '2,203',
      validity: '12/05/23 to 12/05/24',
    },
    {
      code: 435,
      category: 'category',
      name: 'item 1',
      partner: 'partner 2',
      percentage: 5,
      cost: '3,456',
      billing: '2,203',
      validity: '12/05/23 to 12/05/24',
    },
  ];

  return (
    <CallCenterStructure>
      <div className={styles.merchandiseItemsContainer}>
        <div className={styles.headerText}>
          <p>Create Catalogue Merchandize Items</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.itemInputContainer1}>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Select Merchandize Category<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Enter Merchandize item Name<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Link to Partner<sup>*</sup>
              </label>
              <Dropdown />
            </div>
          </div>
          <div className={styles.itemInputContainer2}>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Enter Cost Price<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Cost Payable To Partner<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Billing Price<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.itemInputContainer3}>
            <div className={styles.inputField3}>
              <label htmlFor="">
                Enter Weight<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.radioButtonField3}>
              <label htmlFor="">
                Enable Remark Field<sup>*</sup>
              </label>
              <RadioButtonBlue firstText="Enable" secondText="Disable" />
            </div>

            <div className={styles.radioButtonField3}>
              <label htmlFor="">
                Status<sup>*</sup>
              </label>
              <RadioButtonBlue firstText="Enable" secondText="Disable" />
            </div>
          </div>
          <div className={styles.itemInputContainer4}>
            <div className={styles.defaultImageContainer}>
              <div className={styles.imageContainer}>
                {selectedImage ? (
                  <img src={selectedImage} alt="default" />
                ) : (
                  <img src={defaultImage} alt="default" />
                )}
              </div>
              <p>Logo</p>
            </div>

            <div className={styles.uploadContainer}>
              <div className={styles.uploadInputContainer}>
                <label htmlFor="">Upload</label>
                <ImageDragAndDrop
                  selectedImageName={selectedImageName}
                  setSelectedImageName={setSelectedImageName}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </div>
            </div>
          </div>
          <div className={styles.inputFieldContainer5}>
            <div className={styles.inputFieldInnerContainer5}>
              <label htmlFor="">
                Enter Merchandize item Code<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.inputFieldInnerContainer5}>
              <label htmlFor="">
                Enter Merchandize item Code<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.checkboxContainer}>
            <label htmlFor="">
              Link to Partner Branches<sup>*</sup>
            </label>
            <div className={styles.checkboxInnerContainer}>
              <div className={styles.checkboxMainContainer}>
                <CheckBox />
                <p>Branch 1</p>
              </div>
              <div className={styles.checkboxMainContainer}>
                <CheckBox />
                <p>Branch 1</p>
              </div>
              <div className={styles.checkboxMainContainer}>
                <CheckBox />
                <p>Branch 1</p>
              </div>
              <div className={styles.checkboxMainContainer}>
                <CheckBox />
                <p>Branch 1</p>
              </div>
              <div className={styles.checkboxMainContainer}>
                <CheckBox />
                <p>Branch 1</p>
              </div>
            </div>
          </div>
          <div className={styles.itemInputContainer2}>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Enter VAT<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Set Up the Margin<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Delivery Method<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.inputFieldContainer5}>
            <div className={styles.inputFieldInnerContainer5}>
              <label htmlFor="">
                Select Unit of Weight<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.inputFieldInnerContainer5}>
              <label htmlFor="">
                Enter Remark Field Label<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.inputFieldContainer5}>
            <div className={styles.inputFieldInnerContainer5}>
              <label htmlFor="">
                Select Unit of Weight<sup>*</sup>
              </label>
              <input type="date" />
            </div>
            <div className={styles.inputFieldInnerContainer5}>
              <label htmlFor="">
                Enter Remark Field Label<sup>*</sup>
              </label>
              <input type="date" />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Submit</button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
          <div className={styles.inputContainer}>
            <label htmlFor="">Active Merchandize Items</label>
            <div className={styles.searchInputContainer}>
              <SearchInput />
              <button className={styles.searchInputBtn}>Search</button>
            </div>
            <div className={styles.inputFieldContainer5}>
              <div className={styles.inputFieldInnerContainer5}>
                <label htmlFor="">Select Partner</label>
                <Dropdown />
              </div>
              <div className={styles.inputFieldInnerContainer5}>
                <label htmlFor="">Select Merchandize Category</label>
                <Dropdown />
              </div>
            </div>
            <div className={styles.tableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Item Code</th>
                    <th>Merchandize Category</th>
                    <th>Merchandize Item Name</th>
                    <th>Partner</th>
                    <th>LSL Markup Percentage (%)</th>
                    <th>Cost Price</th>
                    <th>Billing Price (NGN)</th>
                    <th>Validity</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr>
                      <td>{each.code}</td>
                      <td>{each.category}</td>
                      <td>{each.name}</td>
                      <td>{each.partner}</td>
                      <td>{each.percentage}</td>
                      <td>{each.cost}</td>
                      <td>{each.billing}</td>
                      <td>{each.validity}</td>
                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>

                          <span
                            className={styles.icon}
                            onClick={() => setDeleteModal(true)}>
                            <Times />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.paginationContainer}>
              <p>Pagination</p>
            </div>
          </div>
          <div className={styles.inputContainer}>
            <label htmlFor="">In-Active Merchandize Items</label>
            <div className={styles.searchInputContainer}>
              <SearchInput />
              <button className={styles.searchInputBtn}>Search</button>
            </div>
            <div className={styles.inputFieldContainer5}>
              <div className={styles.inputFieldInnerContainer5}>
                <label htmlFor="">Select Partner</label>
                <Dropdown />
              </div>
              <div className={styles.inputFieldInnerContainer5}>
                <label htmlFor="">Select Merchandize Category</label>
                <Dropdown />
              </div>
            </div>
            <div className={styles.tableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Item Code</th>
                    <th>Merchandize Category</th>
                    <th>Merchandize Item Name</th>
                    <th>Partner</th>
                    <th>LSL Markup Percentage (%)</th>
                    <th>Cost Price</th>
                    <th>Billing Price (NGN)</th>
                    <th>Validity</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr>
                      <td>{each.code}</td>
                      <td>{each.category}</td>
                      <td>{each.name}</td>
                      <td>{each.partner}</td>
                      <td>{each.percentage}</td>
                      <td>{each.cost}</td>
                      <td>{each.billing}</td>
                      <td>{each.validity}</td>
                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>

                          <span
                            className={styles.icon}
                            onClick={() => setDeleteModal(true)}>
                            <Times />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.paginationContainer}>
              <p>Pagination</p>
            </div>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && <MenuDeleteModal onClose={onClose} />}
      </div>
    </CallCenterStructure>
  );
};

export default CreateCatalogueMerchandizeItems;
