import React, {useState} from 'react';
import Dropdown from '../../component/DropDown/DropDown';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/CreateGameCampaign.module.scss';
import {Times, ViewPen} from '../../assets/icons';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CreateGameCampaign = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [clearModal, setClearModal] = useState(false);

  const onClose = () => {
    setDeleteModal(false);
  };
  const data = [
    {
      companyName: 'Fidelity Bank Plc.',
      game: 'Verve Debut Card Web Pay Monthly Count',
      program: 'No',
      campaignName: 'Campaign',
      from: '28/6/2023',
      to: '28/6/2023',
      cfb: 7,
      mfb: 8,
    },
    {
      companyName: 'Fidelity Bank Plc.',
      game: 'Verve Debut Card Web Pay Monthly Count',
      program: 'No',
      campaignName: 'Campaign',
      from: '28/6/2023',
      to: '28/6/2023',
      cfb: 6,
      mfb: 8,
    },
    {
      companyName: 'Fidelity Bank Plc.',
      game: 'Verve Debut Card Web Pay Monthly Count',
      program: 'No',
      campaignName: 'Campaign',
      from: '28/6/2023',
      to: '28/6/2023',
      cfb: 9,
      mfb: 8,
    },
    {
      companyName: 'Fidelity Bank Plc.',
      game: 'Verve Debut Card Web Pay Monthly Count',
      program: 'No',
      campaignName: 'Campaign',
      from: '28/6/2023',
      to: '28/6/2023',
      cfb: 3,
      mfb: 8,
    },
  ];
  return (
    <CallCenterStructure>
      <div className={styles.createGameCampaign}>
        <div className={styles.headerText}>
          <p>Game Campaign</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Loyalty Programme<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Start Date<sup>*</sup>
              </label>
              <input type="date" />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer2}>
              <label htmlFor="">
                Campaign Name<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.innerFieldContainer2}>
              <label htmlFor="">
                End Date<sup>*</sup>
              </label>
              <input type="date" />
            </div>
          </div>

          <div className={styles.fieldContainer3}>
            <div className={styles.innerFieldContainer2}>
              <label htmlFor="">
                Campaign Description<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.innerFieldContainer2}>
              <div className={styles.radioContainer2}>
                <label htmlFor="">
                  Reward once<sup>*</sup>
                </label>
                <RadioButtonBlue firstText="Yes" secondText="No" />
              </div>
            </div>
          </div>

          <div className={styles.fieldContainer4}>
            <div className={styles.innerFieldContainer2}>
              <div className={styles.radioContainer2}>
                <label htmlFor="">
                  Fixed Budget<sup>*</sup>
                </label>
                <RadioButtonBlue firstText="Yes" secondText="No" />
              </div>
            </div>
            <div className={styles.innerFieldContainer2}>
              <div className={styles.radioContainer2}>
                <label htmlFor="">
                  Campaign Status<sup>*</sup>
                </label>
                <RadioButtonBlue firstText="Enabled" secondText="Disabled" />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer2}>
              <label htmlFor="">
                Select Game<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.innerFieldContainer2}>
              <label htmlFor="">
                Game Level<sup>*</sup>
              </label>
              <input type="date" />
            </div>
          </div>
          <div className={styles.fieldContainer5}>
            <div className={styles.innerFieldContainer5}>
              <label htmlFor="">
                Reward Points<sup>*</sup>
              </label>
              <input type="date" />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Submit</button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Game</th>
                  <th>Loyalty Program</th>
                  <th>Campaign Name</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Company Fixed Budget</th>
                  <th>Member Fixed Budget</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map(each => (
                  <tr key={each.cfb}>
                    <td>{each.companyName}</td>
                    <td>{each.game}</td>
                    <td>{each.program}</td>
                    <td>{each.campaignName}</td>
                    <td>{each.from}</td>
                    <td>{each.to}</td>
                    <td>{each.cfb}</td>
                    <td>{each.mfb}</td>
                    <td>
                      <div className={styles.iconContainer}>
                        <span className={styles.icon}>
                          <ViewPen />
                        </span>

                        <span
                          className={styles.icon}
                          onClick={() => setDeleteModal(true)}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <p>Pagination</p>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <h6 className={styles.disbaledText}>Disabled Campaigns</h6>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Game</th>
                  <th>Loyalty Program</th>
                  <th>Campaign Name</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Company Fixed Budget</th>
                  <th>Member Fixed Budget</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map(each => (
                  <tr key={each.cfb}>
                    <td>{each.companyName}</td>
                    <td>{each.game}</td>
                    <td>{each.program}</td>
                    <td>{each.campaignName}</td>
                    <td>{each.from}</td>
                    <td>{each.to}</td>
                    <td>{each.cfb}</td>
                    <td>{each.mfb}</td>
                    <td>
                      <div className={styles.iconContainer}>
                        <span className={styles.icon}>
                          <ViewPen />
                        </span>

                        <span
                          className={styles.icon}
                          onClick={() => setDeleteModal(true)}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <p>Pagination</p>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && <MenuDeleteModal onClose={onClose} />}
      </div>
    </CallCenterStructure>
  );
};

export default CreateGameCampaign;
