import React, {useState} from 'react';
import SearchInput from '../../component/SearchInput/SearchInput';
import {Times, ViewPen} from '../../assets/icons';
import styles from '../../styles/CompanyMerchandizeCatalogue.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
import defaultImage from '../../assets/images/defaultImage.png';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CompanyMerchandizeCatalogue = () => {
  const [clearModal, setClearModal] = useState(false);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const onClose = () => {
    setDeleteModal(false);
  };

  const tableData = [
    {
      id: 4,
      partnerName: 'Wakanow',

      address: 'Online Store',
      name: 'Eniola Taiwo',
      number: 8023327843,
      mail: 'tadesola@hotmail.com',
    },
    {
      id: 2,
      partnerName: 'Wakanow',

      address: 'Online Store',
      name: 'Eniola Taiwo',
      number: 8023327843,
      mail: 'tadesola@hotmail.com',
    },
    {
      id: 9,
      partnerName: 'Wakanow',

      address: 'Online Store',
      name: 'Eniola Taiwo',
      number: 8023327843,
      mail: 'tadesola@hotmail.com',
    },
    {
      id: 6,
      partnerName: 'Wakanow',

      address: 'Online Store',
      name: 'Eniola Taiwo',
      number: 8023327843,
      mail: 'tadesola@hotmail.com',
    },
  ];
  return (
    <div>
      <CallCenterStructure>
        <div className={styles.PointsForfeitureMaster}>
          <div className={styles.headerText}>
            <p>Company Merchandize Catalogue</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field5}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Link to Loyalty Program<sup>*</sup>
                </label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Select Merchandize Category<sup>*</sup>
                </label>
                <Dropdown />
              </div>
            </div>
            <div className={styles.field5}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Select Merchandize Item Code<sup>*</sup>
                </label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Select Merchandize Item Name<sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field5}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Enter Merchandize Item Information<sup>*</sup>
                </label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Link to Partner<sup>*</sup>
                </label>
                <Dropdown />
              </div>
            </div>
            <div className={styles.field5}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Link to Partner Branches<sup>*</sup>
                </label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Enter Cost Price<sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field5}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Enter VAT<sup>*</sup>
                </label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Cost Payable to Partner<sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field5}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Set Up the Margin (%)<sup>*</sup>
                </label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Billing Price<sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field5}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Redeemable Points<sup>*</sup>
                </label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Enter Weight<sup>*</sup>
                </label>
                <div className={styles.radioContainer}>
                  <RadioButtonBlue firstText="Yes" secondText="No" />
                </div>
              </div>
            </div>
            <div className={styles.field5}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Select Unit of Weight<sup>*</sup>
                </label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Enter Remark Field<sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field5}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Valid from<sup>*</sup>
                </label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Valid Till<sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field5}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Delivery Method<sup>*</sup>
                </label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Status<sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>

            <div className={styles.itemInputContainer4}>
              <div className={styles.defaultImageContainer}>
                <div className={styles.imageContainer}>
                  {selectedImage ? (
                    <img src={selectedImage} alt="default" />
                  ) : (
                    <img src={defaultImage} alt="default" />
                  )}
                </div>
                <p>Image</p>
              </div>

              <div className={styles.uploadContainer}>
                <div className={styles.uploadInputContainer}>
                  <label htmlFor="">
                    Upload Photograph of Merchandize Item * You can upload upto
                    maximum 100kb. Use Image of Resolution 230 Pixels (H) X 140
                    Pixels (V)
                  </label>
                  <ImageDragAndDrop
                    selectedImageName={selectedImageName}
                    setSelectedImageName={setSelectedImageName}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                  />
                </div>
              </div>
            </div>
            {/* <div className={styles.tableSection}>
              <div className={styles.tableContainer}>
                <table className={styles.mainTable}>
                  <thead>
                    <tr>
                      <th>Segment Name</th>
                      <th>Segment Code</th>
                      <th>Segment Operation</th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map(each => (
                      <tr key={each.id}>
                        <td>{each.partnerName}</td>
                        <td>{each.mail}</td>
                        <td>{each.number}</td>

                        <td>
                          <div className={styles.iconContainer}>
                            <span className={styles.icon}>
                              <ViewPen />
                            </span>

                            <span
                              className={styles.icon}
                              onClick={() => setDeleteModal(true)}>
                              <Times />
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className={styles.pagination}>
                <p>Pagination</p>
              </div>
            </div> */}
          </div>

          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Submit</button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.searchInputContainer}>
              <div className={styles.searchInput}>
                <SearchInput />
              </div>
              <button>Search</button>
            </div>
            <div className={styles.field5}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Select Partner<sup>*</sup>
                </label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Select Merchandize Category<sup>*</sup>
                </label>
                <Dropdown />
              </div>
            </div>
            <div className={styles.activeAndInactiveText}>
              <p>Active Company Merchandize Items</p>
            </div>
            <div className={styles.tableContainer1}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Segment Code</th>
                    <th>Segment Name</th>
                    <th>Segment Operation</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr key={each.id}>
                      <td>{each.partnerName}</td>
                      <td>{each.mail}</td>
                      <td>{each.number}</td>

                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>

                          <span
                            className={styles.icon}
                            onClick={() => setDeleteModal(true)}>
                            <Times />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.pagination}>
              <p>Pagination</p>
            </div>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.searchInputContainer}>
              <div className={styles.searchInput}>
                <SearchInput />
              </div>
              <button className={styles.searchBTN}>Search</button>
            </div>
            <div className={styles.field5}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Select Partner<sup>*</sup>
                </label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Select Merchandize Category<sup>*</sup>
                </label>
                <Dropdown />
              </div>
            </div>
            <div className={styles.activeAndInactiveText}>
              <p>In-active Company Merchandize Items</p>
            </div>
            <div className={styles.tableContainer1}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Segment Code</th>
                    <th>Segment Name</th>
                    <th>Segment Operation</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr key={each.id}>
                      <td>{each.partnerName}</td>
                      <td>{each.mail}</td>
                      <td>{each.number}</td>

                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>

                          <span
                            className={styles.icon}
                            onClick={() => setDeleteModal(true)}>
                            <Times />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.pagination}>
              <p>Pagination</p>
            </div>
          </div>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default CompanyMerchandizeCatalogue;
