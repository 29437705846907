import React, {useState, useEffect} from 'react';
import {useQuery, useMutation} from 'react-query';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import RadioButtonSingle from '../../component/RadioButtonSingle/RadioButtonSingle';
import styles from '../../styles/RemoveBottomBorderFromTd.module.scss';
import {Modal} from 'react-bootstrap';
import {Copy} from '../../assets/icons';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import {TransactionHeaders, Headers} from '../../service/Essentials';
import swal from 'sweetalert';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const PointsAwardTransaction = () => {
  const [clearModal, setClearModal] = useState(false);
  const [isQustionOpen, setIsQuestionOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [isInvalidOpen, setIsInvalidOpen] = useState(false);
  const [isPinOpen, setIsPinOpen] = useState(false);
  const [isForgotIDOpen, setIsForgotIDOpen] = useState(false);
  const [awardPoints, setAwardPoints] = useState([]);
  const [transactionName, setTransactionName] = useState('');
  const [transanctionID, setTransactionID] = useState('');
  const [paymentName, setPaymentName] = useState('');
  const [paymentID, setPaymentID] = useState('');
  const [branchName, setBranchName] = useState('');
  const [branchID, setBranchID] = useState('');
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState({
    membership_id: '',
    price: '',
    branch_id: '',
    payment_type_id: '',
    remark: '',
    gift_card: '',
    branch_user_pin_id: '',
    gift_card_id: '',
    member_balance: '',
    member_wallet_balance: '',
    member_name: '',
    redeem_amount: '',
    redeem_point: '',
    balance_to_pay: '',
    total_balance_to_pay: '',
    item_grand_total: '',
    item_name: '',
    item_quantity: '',
    item_total: '',
    item_vat: '',
  });
  const [formData, setFormData] = useState({
    membership_id: '',
    price: '',
    branch_id: '',
    payment_type_id: '',
    remark: '',
    gift_card: '',
    branch_user_pin_id: '',
    gift_card_id: '',
    member_balance: '',
    member_wallet_balance: '',
    member_name: '',
    redeem_amount: '',
    redeem_point: '',
    balance_to_pay: '',
    total_balance_to_pay: '',
    item_grand_total: '',
    item_name: '',
    item_quantity: '',
    item_total: '',
    item_vat: '',
  });

  console.log(formData);

  const openForgotIDModal = () => {
    setIsForgotIDOpen(true);
  };

  const closeForgotIDModal = () => {
    setIsForgotIDOpen(false);
  };

  const openQuestionModal = () => {
    setIsQuestionOpen(true);
  };

  const closeQuestionModal = () => {
    setIsQuestionOpen(false);
  };

  const openInvalidModal = () => {
    setIsInvalidOpen(true);
  };

  const closeInvalidModal = () => {
    setIsInvalidOpen(false);
  };

  const openPinModal = () => {
    setIsPinOpen(true);
  };

  const closePinModal = () => {
    setIsPinOpen(false);
  };
  // const handleSubmit = (event) => {
  // event.preventDefault(); // Prevent form submission

  // Open the modal after performing the desired action
  //  openQuestionModal();
  // };

  // const [selectedOption, setSelectedOption] = useState(null);

  // const handleRadioButtonChange = value => {
  //   setSelectedOption(value);
  // };

  const handleTransactionName = name => {
    setTransactionName(name);
  };

  const handleTransactionID = id => {
    setTransactionID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      transaction_type_id: id,
    }));
  };

  const handlePaymentName = name => {
    setPaymentName(name);
  };

  const handlePaymentID = id => {
    setPaymentID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      payment_type_id: id,
    }));
  };

  const handleBranchName = name => {
    setBranchName(name);
  };

  const handleBranchID = id => {
    setBranchID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      branch_id: id,
    }));
  };

  const handleChange = e => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const addPointAward = async () => {
    try {
      await axiosProperty.post(ApiConfig.createPointAward, formData, {
        headers: TransactionHeaders(),
      });
      setPaymentName('');
      setPaymentID('');
      setTransactionName('');
      setTransactionID('');
      setBranchName('');
      setBranchID('');
      setFormData({
        membership_id: '',
        price: '',
        branch_id: '',
        payment_type_id: '',
        remark: '',
        gift_card: '',
        branch_user_pin_id: '',
        gift_card_id: '',
        member_balance: '',
        member_wallet_balance: '',
        member_name: '',
        redeem_amount: '',
        redeem_point: '',
        balance_to_pay: '',
        total_balance_to_pay: '',
        item_grand_total: '',
        item_name: '',
        item_quantity: '',
        item_total: '',
        item_vat: '',
      });
      setErrors({});
      swal({
        title: 'Success!',
        text: 'Point Award was added successfully',
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: addPointawardMutation} = useMutation({
    mutationFn: addPointAward,
  });

  const fetchPointAwards = async page => {
    const res = await axiosProperty.get(ApiConfig.fetchPointAwards, {
      headers: TransactionHeaders(),
    });
    return res.data;
  };

  const {data: awardPointsdata, status} = useQuery(
    ['awardpoints', page],
    () => fetchPointAwards(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setAwardPoints(awardPointsdata?.data || []);
  }, [awardPointsdata]);

  const data = [
    {
      receipt: '#75588',
      membId: '67',
      transDate: '23/06/2023',
      membName: 'Kenneth',
      billAmount: 5000,
      redeemAmount: 8000,
      balPaid: 16000,
      pointsGained: 8,
    },
  ];
  return (
    <>
      <CallCenterStructure>
        <div className="mt-5 p-4">
          <h4 className="pb-4">Points Award Transaction</h4>
          <main className="border border-light p-3">
            <form action="">
              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="transactionType">Transaction Type</label>
                  <Dropdown
                    header={Headers()}
                    api={ApiConfig.fetchTransactionType}
                    onSelect={handleTransactionID}
                    onSelectOption={handleTransactionName}
                    column={'name'}
                    key="awardpoints"
                  />
                </div>
                <div className="form-group col-12 col-md-6">
                  <label
                    className="d-flex justify-content-between"
                    htmlFor="membershipId">
                    Membership ID *
                    <span
                      onClick={openForgotIDModal}
                      style={{color: '#00BCE1'}}>
                      Forgot membership id ?
                    </span>
                  </label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="Transaction Type"
                    name="membership_id"
                    value={formData.membership_id}
                    onChange={handleChange}
                  />
                  {errors.membership_id && (
                    <p className="text-danger">{errors.membership_id}</p>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="memberBalance">Member Balance *</label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="Member balance"
                    name="member_balance"
                    value={formData.member_balance}
                    onChange={handleChange}
                  />
                  {errors.member_balance && (
                    <p className="text-danger">{errors.member_balance}</p>
                  )}
                </div>
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="memberWalletBalance">
                    Member Wallet Balance{' '}
                  </label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="Member Wallet balance"
                    name="member_wallet_balance"
                    value={formData.member_wallet_balance}
                    onChange={handleChange}
                  />
                  {errors.member_wallet_balance && (
                    <p className="text-danger">
                      {errors.member_wallet_balance}
                    </p>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="form-group col-12">
                  <label htmlFor="memberName">Member Name *</label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="Member Name"
                    name="member_name"
                    value={formData.member_name}
                    onChange={handleChange}
                  />
                  {errors.member_name && (
                    <p className="text-danger">{errors.member_name}</p>
                  )}
                </div>
                {/* <div className="form-group col-12 col-md-6">
                  <p>
                    <label htmlFor="puchurseGiftCard">Purchase Gift Card</label>
                  </p>
                  <div className="d-flex">
                    <RadioButtonSingle
                      name="purchaseGiftCard"
                      value="yes"
                      selected={selectedOption === 'yes'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    Yes
                    <div className="w-25"></div>
                    <RadioButtonSingle
                      name="purchaseGiftCard"
                      value="no"
                      selected={selectedOption === 'no'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    No
                  </div>
                </div> */}
              </div>

              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="paymentType">Payment By *</label>
                  <Dropdown
                    header={Headers()}
                    api={ApiConfig.fetchPaymentType}
                    onSelect={handlePaymentID}
                    onSelectOption={handlePaymentName}
                    retrievedValue={paymentID}
                    column={'name'}
                    key="paymenttypes"
                  />
                  {errors.payment_type_id && (
                    <p className="text-danger">{errors.payment_type_id}</p>
                  )}
                </div>
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="selectBranch">Select Branch *</label>
                  <Dropdown
                    header={Headers()}
                    api={ApiConfig.fetchCompanyBranch}
                    onSelect={handleBranchID}
                    onSelectOption={handleBranchName}
                    retrievedValue={branchID}
                    column={'name'}
                    key="companybranches"
                  />
                  {errors.branch_id && (
                    <p className="text-danger">{errors.branch_id}</p>
                  )}
                </div>
              </div>

              <div className="border border-light mb-4">
                <div className="row mt-3 mb-4 w-100">
                  <div className="col-12 table-responsive m-2">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="">#</th>
                          <th className="">Items</th>
                          <th className="">Quantity</th>
                          <th className="">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className={styles.removeBottomBorder}>1</td>
                          <td className={styles.removeBottomBorder}>
                            <input
                              className="w-100"
                              type="text"
                              name="item_name"
                              value={formData.item_name}
                              onChange={handleChange}
                            />
                            {errors.item_name && (
                              <p className="text-danger">{errors.item_name}</p>
                            )}
                          </td>
                          <td className={styles.removeBottomBorder}>
                            <input
                              className="w-100"
                              type="text"
                              name="item_quantity"
                              value={formData.item_quantity}
                              onChange={handleChange}
                            />
                            {errors.item_quantity && (
                              <p className="text-danger">
                                {errors.item_quantity}
                              </p>
                            )}
                          </td>
                          <td className={styles.removeBottomBorder}>
                            <input
                              className="w-100"
                              type="text"
                              name="price"
                              value={formData.price}
                              onChange={handleChange}
                            />
                            {errors.price && (
                              <p className="text-danger">{errors.price}</p>
                            )}
                          </td>
                        </tr>
                        {/* <tr>
                          <td className={styles.removeBottomBorder}>2</td>
                          <td className={styles.removeBottomBorder}>
                            <input className="w-100 " type="text" />
                          </td>
                          <td className={styles.removeBottomBorder}>
                            <input className="w-100" type="text" />
                          </td>
                          <td className={styles.removeBottomBorder}>
                            <input className="w-100" type="text" />
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="col-12">Pagination</div> */}
                </div>

                <div className="row mt-3 pr-4 mb-4 w-100">
                  <div
                    className="col-12 ml-4 mr-4 p-0"
                    style={{background: '#F5F5F5'}}>
                    <div className="row mt-3">
                      <div className="form-group col-12 col-md-6">
                        <label className="mt-3 p-2" htmlFor="totalAmount">
                          Total Amount
                        </label>
                      </div>
                      <div className="form-group col-12 col-md-6">
                        <input
                          className="w-100"
                          type="number"
                          name="item_total"
                          value={formData.item_total}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-12 col-md-6">
                        <label className="mt-3 p-2" htmlFor="vatAmount">
                          Vat Amount
                        </label>
                      </div>
                      <div className="form-group col-12 col-md-6">
                        <input
                          className="w-100"
                          type="number"
                          name="item_vat"
                          value={formData.item_vat}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-12 col-md-6">
                        <label className="mt-3 p-2" htmlFor="grandTotal">
                          Grand Total
                        </label>
                      </div>
                      <div className="form-group col-12 col-md-6">
                        <input
                          className="w-100"
                          type="number"
                          name="item_grand_total"
                          value={formData.item_grand_total}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="redeemPoint">Redeem Points *</label>
                  <input
                    className="w-100"
                    type="number"
                    placeholder="redeem points"
                    name="redeem_point"
                    value={formData.redeem_point}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="redeemAmount">Redeem Amount *</label>
                  <input
                    className="w-100"
                    type="number"
                    placeholder="redeem amount"
                    name="redeem_amount"
                    value={formData.redeem_amount}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="balanceToPay">Balance to pay *</label>
                  <input
                    className="w-100"
                    type="number"
                    placeholder="Balance To Pay"
                    name="balance_to_pay"
                    value={formData.balance_to_pay}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="balanceToPay">Total Balance To pay *</label>
                  <input
                    className="w-100"
                    type="number"
                    placeholder="Total Balance To Pay"
                    name="total_balance_to_pay"
                    value={formData.total_balance_to_pay}
                    onChange={handleChange}
                  />
                </div>
                {/* <div className="form-group col-12 col-md-6">
                  <p>
                    <label htmlFor="useGiftCard">Use Gift Card</label>
                  </p>
                  <div className="d-flex">
                    <RadioButtonSingle
                      name="useGiftCard"
                      value="1"
                      selected={selectedOption === '1'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    Yes
                    <div className="w-25"></div>
                    <RadioButtonSingle
                      name="purchaseGiftCard"
                      value="2"
                      selected={selectedOption === '2'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    No
                  </div>
                </div> */}
              </div>

              {/* <div className="row">
                <div className="form-group col-12 col-md-4">
                  <label htmlFor="giftCardNumber">Gift Card Number *</label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="Gift card number"
                  />
                </div>
                <div className="form-group col-12 col-md-4">
                  <label htmlFor="giftCardRedeemAmount">
                    Gift Card Redeem Amount *
                  </label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="Gift card redeem amount"
                  />
                </div>
                <div className="form-group col-12 col-md-4">
                  <label htmlFor="giftCardRedeemBalance">Redeem Balance</label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="Gift card redeem balance"
                  />
                </div>
              </div> */}

              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label
                    htmlFor="pin"
                    className="d-flex justify-content-between d-block">
                    <span>Pin *</span>
                    <span
                      onClick={openQuestionModal}
                      style={{color: '#00BCE1'}}>
                      Forgot pin ?
                    </span>
                  </label>
                  {/* <p style={{fontStyle:'italic', color:'#CED4DF', marginTop:0, paddingTop:0}}></p> */}
                  <input
                    className="w-100"
                    type="number"
                    placeholder="Enter pin ending with **45"
                    name="branch_user_pin_id"
                    value={formData.branch_user_pin_id}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="remark">Remark</label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="remark"
                    name="remark"
                    value={formData.remark}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* <div className="row form-group col-6">
                <label htmlFor="remarks">Remarks</label>
                <input
                  className="w-100"
                  type="text"
                  placeholder="comment here"
                />
              </div> */}

              <div className="form-group mt-3 d-flex justify-content-between d-md-flex">
                <button
                  className="mr-5"
                  onClick={e => {
                    e.preventDefault();
                    addPointawardMutation();
                  }}>
                  Submit
                </button>
                <button
                  className="resetButton"
                  type="button"
                  onClick={() => setClearModal(true)}>
                  Reset
                </button>
              </div>
            </form>
          </main>

          <section className="border border-light mt-4 mb-4">
            <div className="row p-4">
              <div className="col-12 table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="">Receipt</th>
                      <th className="">Transaction Date</th>
                      <th className="">Membership Id</th>
                      <th className="">Membership Name</th>
                      <th className="">Total Bill Amount</th>
                      <th className="">Total Redeem Amount</th>
                      <th className="">Balance Paid</th>
                      <th className="">Points Gained</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((each, index) => (
                      <tr key={index}>
                        <td>{each.receipt}</td>
                        <td>{each.transDate}</td>
                        <td>{each.membId}</td>
                        <td>{each.membName}</td>
                        <td>{each.billAmount}</td>
                        <td>{each.redeemAmount}</td>
                        <td>{each.balPaid}</td>
                        <td>{each.pointsGained}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <div className="col-12">Pagination</div> */}
              <Paginate page={page} data={awardPointsdata} setPage={setPage} />
            </div>

            {/* <div className="row mt-3 mb-4 w-100 pr-4">
              <div
                className="col-12 ml-4 mr-4 p-0"
                style={{background: '#F5F5F5'}}>
                <div className="row mt-3">
                  <div className="form-group col-12 col-md-6">
                    <label className="mt-3 p-2" htmlFor="totalAmount">
                      Total Cost
                    </label>
                  </div>
                  <div className="form-group col-12 col-md-6">
                    <input className="w-100" type="text" />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-12 col-md-6">
                    <label className="mt-3 p-2" htmlFor="grandTotal">
                      Grand Total
                    </label>
                  </div>
                  <div className="form-group col-12 col-md-6">
                    <input className="w-100" type="text" />
                  </div>
                </div>
              </div>
            </div> */}
          </section>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
        </div>
      </CallCenterStructure>

      {/* forgot membership ID Modal */}
      <Modal show={isForgotIDOpen} onHide={closeForgotIDModal} size="lg">
        <Modal.Body>
          <section className="d-flex justify-content-between mb-2">
            <h6>Forgot Pin?</h6>
            <span onClick={closeForgotIDModal}>&times;</span>
          </section>
          <p className="text-danger mb-3">
            At least one field is required to search
          </p>
          <div className="row">
            <div className="form-group col-12 col-md-6">
              <label htmlFor="">Customer Phone Number</label>
              <input className="w-100" type="text" />
            </div>
            <div className="form-group col-12 col-md-6">
              <label htmlFor="">Membership ID</label>
              <input className="w-100" type="text" />
            </div>
          </div>
          <div className="form-group d-flex justify-content-between d-md-flex justify-content-md-start">
            <button className="mr-3">Search</button>
            <button onClick={closeForgotIDModal} className="resetButton">
              Cancel
            </button>
          </div>

          <section className="border border-light mt-4 mb-4">
            <div className="row p-4">
              <div className="col-12 table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="">First Name</th>
                      <th className="">Last Name</th>
                      <th className="">Membership Id</th>
                      <th className="">Phone Number</th>
                      <th className="">Username</th>
                      <th className="">Date of Birth</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>

      {/* Security Questions Modal */}
      <Modal show={isQustionOpen} onHide={closeQuestionModal} size="lg">
        <Modal.Body>
          <section className="d-flex justify-content-between mb-2">
            <h6>Forgot Pin?</h6>
            <span onClick={closeQuestionModal}>&times;</span>
          </section>
          <p className="text-danger mb-3">
            Kindly answer one of the questions below:
          </p>
          <div className="row">
            <div className="form-group col-12 col-md-6">
              <label htmlFor="">1. What is your Mothers' Maiden name?</label>
              <input className="w-100" type="text" />
            </div>
            <div className="form-group col-12 col-md-6">
              <label htmlFor="">
                2. What street did you spend your childhood days?
              </label>
              <input className="w-100" type="text" />
            </div>
          </div>

          <div className="form-group d-flex justify-content-between d-md-flex justify-content-md-start">
            <button
              onClick={() => {
                openPinModal();
                closeQuestionModal();
              }}
              className="mr-3">
              Submit
            </button>
            <button onClick={closeQuestionModal} className="resetButton">
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* invalid answer Modal */}
      <Modal show={isInvalidOpen} onHide={closeInvalidModal}>
        <Modal.Body className="text-center">
          <h6 className="pb-4">Invalid Answer</h6>
          <button className="w-50" onClick={closeInvalidModal}>
            ok
          </button>
        </Modal.Body>
      </Modal>

      {/* copy pin Modal */}
      <Modal show={isPinOpen} onHide={closePinModal} size="lg">
        <Modal.Body className="text-center">
          <p className="text-bold d-flex justify-content-start">Forgot Pin?</p>
          <p className="text-danger d-flex justify-content-start">
            Copy your pin below
          </p>
          <h6 className="pb-4 d-flex justify-content-center">
            56789
            <span style={{fontSize: '10px'}} className="ml-4">
              <Copy /> Copy
            </span>
          </h6>
          <button className="w-50 m-auto" onClick={closePinModal}>
            Ok
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default PointsAwardTransaction;
