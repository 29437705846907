import React, {useState} from 'react';
import {SelectedItemsCancle, TemplateTimes} from '../../assets/icons';
import styles from '../../styles/SelectedCampaignItem.module.scss';

const SelectedCampaignItem = ({
  pickedItems,
  handleOnFocusChange,
  handleOnBlurChange,
  searchTerm,
  handleInputChange,
  handleRemoveBranch,
  disableInput,
}) => {
  // const [searchTerm, setSearchTerm] = useState('');
  return (
    <div
      className={
        disableInput
          ? styles.selectedCampaignContainerDisabled
          : styles.selectedCampaignContainer
      }>
      {pickedItems.map(each => (
        <div
          className={
            disableInput ? styles.selectedItemsDisbaled : styles.selectedItems
          }
          key={each}>
          <div
            className={
              disableInput ? styles.selectedTextDisabled : styles.selectedText
            }>
            <p>{each}</p>
          </div>
          <span onClick={() => handleRemoveBranch(each)}>
            <SelectedItemsCancle />
          </span>
        </div>
      ))}

      <input
        className={
          disableInput ? styles.searchInputDisabled : styles.searchInput
        }
        type="text"
        onFocus={handleOnFocusChange}
        onBlur={handleOnBlurChange}
        value={searchTerm}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default SelectedCampaignItem;
