import React from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';

const PaymentToCataloguePartner = ()=>{
   
  const data = [
    {
    receipt: '#75588', membId: '67', transDate:'23/06/2023', membName:'Kenneth', 
    billAmount:5000, redeemAmount:8000, balPaid:16000, pointsGained:8
    }
  ];
    return(
        <>
        <CallCenterStructure>
        <div className='mt-5 p-4'>
           <h4 className='pb-4'>Payment To Cataloque Partner</h4>
           <main className='border border-light p-3'>
           <form action="">
               <div className="row">
               <div className="form-group col-12 col-md-6" >
               <label htmlFor="membershipId">Invoice Number *</label>
                   <input className="w-100" type="text" placeholder='Invoice Number' />
               </div>
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="transactionType">Select Partner *</label>
                   <Dropdown/>
               </div>
               </div>

               <div className="row">
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="transactionType">Select Company *</label>
                   <Dropdown/>
               </div>
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="transactionType">Select Loyalty Program *</label>
                   <Dropdown/>
               </div>
               </div>

               <div className="row">
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="transactionType">From Date *</label>
                   <Dropdown/>
               </div>
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="transactionType">Till Date *</label>
                   <Dropdown/>
               </div>
               </div>

               <div className='form-group mt-3 d-flex justify-content-center d-md-flex justify-content-md-start'>
                   <button className='mr-5'>Search</button>
               </div>
           </form>
           </main>

           <section className="border border-light mt-4 mb-4">
                <div className="row p-4">
                <div className="col-12 table-responsive">
                <table className='table'>
                    <thead>
                        <tr>
                        <th className=''>Receipt</th>
                        <th className=''>Transaction Date</th>
                        <th className=''>Membership Id</th>
                        <th className=''>Membership Name</th>
                        <th className=''>Total Bill Amount</th>
                        <th className=''>Total Redeem Amount</th>
                        <th className=''>Balance Paid</th>
                        <th className=''>Points Gained</th>

                        </tr>
                        
                    </thead>
                    <tbody>
                        {
                            data.map((each, index) =>(
                             <tr key={index}>
                                <td>{each.receipt}</td>
                                <td>{each.transDate}</td>
                                <td>{each.membId}</td>
                                <td>{each.membName}</td>
                                <td>{each.billAmount}</td>
                                <td>{each.redeemAmount}</td>
                                <td>{each.balPaid}</td>
                                <td>{each.pointsGained}</td>
                             </tr>
                            ))
                        }
                    </tbody>
                </table>
                </div>
                <div className="col-12">Pagination</div>
                </div>

                <div className="row mt-3 mb-4 w-100 pr-4">
                <div className="col-12 ml-4 mr-4 p-0" style={{background:'#F5F5F5'}}>
               <div className="row mt-3">
               <div className="form-group col-12 col-md-6" >
                   <label className='mt-3 p-2' htmlFor="totalAmount">Total Cost</label>
               </div>
               <div className="form-group col-12 col-md-6" >
                   <input className="w-100" type='text' />
               </div>
               </div>

               <div className="row">
               <div className="form-group col-12 col-md-6" >
                   <label className='mt-3 p-2' htmlFor="grandTotal">Grand Total</label>
               </div>
               <div className="form-group col-12 col-md-6" >
                   <input className="w-100" type='text' />
               </div>
               </div>

            </div>
                </div>
           </section>
           
        </div>
       </CallCenterStructure>
       </>
    );
}
export default PaymentToCataloguePartner;