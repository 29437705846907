import React, {useState} from 'react';
import styles from '../../styles/RadioButtonBlue.module.scss';

const RadioButtonBlue = ({firstText, secondText, onChange}) => {
  const [inputChecked, setInputChecked] = useState(false);

  // const handleRadioChangeFalse = () => {
  //   setInputChecked(false);
  // };

  // const handleRadioChangeTrue = () => {
  //   setInputChecked(true);
  // };

  const handleRadioChange = checked => {
    setInputChecked(checked);
    onChange(checked);
  };

  // console.log(initialChecked, 'initialChecked');

  return (
    <div className={styles.radioButtons}>
      <div className={styles.radioButton}>
        <div
          className={styles.myCheckbox}
          onClick={() => handleRadioChange(true)}>
          <div className={inputChecked ? styles.checked : styles.uncheck}></div>
        </div>

        <p>{firstText}</p>
      </div>
      <div className={styles.radioButton}>
        <div
          className={styles.myCheckbox}
          onClick={() => handleRadioChange(false)}>
          <div
            className={!inputChecked ? styles.checked : styles.uncheck}></div>
        </div>
        <p>{secondText}</p>
      </div>
    </div>
  );
};

export default RadioButtonBlue;
