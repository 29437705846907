import React, {useState} from 'react';
import styles from '../../styles/ForgotPassword.module.scss';
import PerxLogo from '../../assets/images/PerxBig.png';
import ForgotPasswordModal from '../../component/ForgotPasswordModal/ForgotPasswordModal';

const ForgotPassword = () => {
  const [openModal, setOpenModal] = useState(false);
  const onClose = () => {
    setOpenModal(false);
  };
  return (
    <div className={styles.ForgotPasswordContainer}>
      <div className={styles.ForgotPasswordInnerContainer}>
        <div className={styles.perxLogo}>
          <img src={PerxLogo} alt="perx logo" />
        </div>
        <div className={styles.formContainer}>
          <h6>Forgot Password</h6>
          <div className={styles.formMainContainer}>
            <label htmlFor="">
              Enter your username to get your login credentials
            </label>
            <input type="text" name="" id="" />
            <button
              onClick={() => setOpenModal(true)}
              className={styles.sendBTN}>
              Send Credentials
            </button>
          </div>
        </div>
      </div>
      {openModal && <ForgotPasswordModal onClose={onClose} />}
    </div>
  );
};

export default ForgotPassword;
