import React, {useEffect, useState} from 'react';
import styles from '../../styles/SuperAdminDashboard.module.scss';

import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  // CartesianGrid,
  Tooltip,
  // Legend,
} from 'recharts';
import Chart from 'react-google-charts';
import Dropdown from '../../component/DropDown/DropDown';

const SuperAdminDashboard = () => {
  const [barChartStyling, setBarChartStyling] = useState(1000);

  useEffect(() => {
    const handleResize = () => {
      const value = window.innerWidth;
      if (value > 888) {
        setBarChartStyling(value - 300);
      } else {
        setBarChartStyling(value - 50);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  ///////////   LINE CHART PROPERTIES STARTS//////////

  const options = {
    colors: ['#00BCE1', '#28245F', '#81C041'],
  };

  const data = [
    ['Month', 'FirstBank', 'Fidelity Bank', 'Diamond Bank'],
    ['Jan', 37.8, 80.8, 41.8],
    ['Feb', 30.9, 69.5, 32.4],
    ['Mar', 25.4, 57, 25.7],
    ['Apr', 11.7, 18.8, 10.5],
    ['May', 11.9, 17.6, 10.4],
    ['Jun', 8.8, 13.6, 7.7],
    ['Jul', 7.6, 12.3, 9.6],
    ['Aug', 12.3, 29.2, 10.6],
    ['Sep', 16.9, 42.9, 14.8],
    ['Oct', 12.8, 30.9, 11.6],
    ['Nov', 5.3, 7.9, 4.7],
    ['Dec', 6.6, 8.4, 5.2],
  ];
  ///////////   LINE CHART PROPERTIES ENDS//////////

  const dataPie1 = [
    ['Task', 'Hours per Day'],
    ['Premium', 5],
    ['Premium Plus', 3],
    ['Coalition', 2],
    ['Enterprise', 2],
  ];

  const optionsPie1 = {
    // title: 'Queries',
    pieHole: 0.4,
    is3D: false,
    colors: ['#00BCE1', '#0E237D', '#989898', '#81C041'],
  };

  const dataPie2 = [
    ['Task', 'Hours per Day'],
    ['FMCG', 5],
    ['Telco', 3],
    ['Retail', 2],
    ['Bank', 2],
  ];

  const optionsPie2 = {
    // title: 'Partners',
    pieHole: 0.4,
    is3D: false,
    colors: ['#00BCE1', '#0E237D', '#989898', '#81C041'],
  };

  const dataPie3 = [
    ['Task', 'Hours per Day'],
    ['FirstBank', 45],
    ['Fidelity', 20],
  ];

  const optionsPie3 = {
    pieHole: 0.4,
    is3D: false,
    colors: ['#00BCE1', '#81C041'],
  };

  const dataPie4 = [
    ['Task', 'Hours per Day'],
    ['FirstBank', 45],
    ['Fidelity', 20],
    ['UBA', 25],
  ];

  const optionsPie4 = {
    pieHole: 0.4,
    is3D: false,
    colors: ['#00BCE1', '#81C041', '#0E237D'],
  };

  //////////////   MULTIPLE BAR CHARTS/////////////////
  const multiData = [
    ['Month', 'FirstBank', 'Fidelity Bank', 'Diamond Bank', 'Access Bank'],
    ['Jan', 1000, 400, 200, 300],
    ['Feb', 1170, 460, 250, 200],
    ['Mar', 660, 1120, 300, 500],
    ['Apr', 1030, 540, 350, 120],
    ['May', 1030, 540, 350, 120],
    ['Jun', 1030, 540, 350, 120],
    ['Jul', 1030, 540, 350, 120],
    ['Aug', 1030, 540, 350, 120],
    ['Sep', 1030, 540, 350, 120],
    ['Oct', 1030, 540, 350, 120],
    ['Nov', 1030, 540, 350, 120],
    ['Dec', 1030, 540, 350, 120],
  ];

  const multiOptions = {
    colors: ['#00BCE1', '#81C041', '#0E237D', '#628628'],
  };

  return (
    <CallCenterStructure>
      <div className={styles.SuperAdminDashboard}>
        <h1 className={styles.headerText}>Super Admin Dashboard</h1>
        <div className={styles.pieChartContainer}>
          <div className={styles.pieChart}>
            <div className={styles.pieChartHeader}>
              <h2 className={styles.headerH2}>Total Clients by Solutions</h2>

              <div className={styles.dropdownOutterContainer}>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
              </div>
            </div>
            <Chart
              chartType="PieChart"
              width="100%"
              height="300px"
              data={dataPie1}
              options={optionsPie1}
            />
          </div>
          <div className={styles.pieChart}>
            <div className={styles.pieChartHeader}>
              <h2 className={styles.headerH2}>Total Clients by Sector</h2>

              <div className={styles.dropdownOutterContainer}>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
              </div>
            </div>
            <Chart
              chartType="PieChart"
              width="100%"
              height="300px"
              data={dataPie2}
              options={optionsPie2}
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.lineChartHeader}>
            <p className={styles.headerH2}>Enrolments</p>
            <div className={styles.dropdownContainer}>
              <Dropdown />
            </div>
          </div>
          <Chart
            chartType="Line"
            width="100%"
            height="400px"
            data={data}
            options={options}
          />
        </div>
        <div className={styles.pieChartContainer}>
          <div className={styles.pieChart}>
            <div className={styles.pieChartHeader}>
              <h2 className={styles.headerH2}>Points</h2>

              <div className={styles.dropdownOutterContainer}>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
              </div>
            </div>
            <Chart
              chartType="PieChart"
              width="100%"
              height="300px"
              data={dataPie3}
              options={optionsPie3}
            />
          </div>
          <div className={styles.pieChart}>
            <div className={styles.pieChartHeader}>
              <h2 className={styles.headerH2}>Branches</h2>

              <div className={styles.dropdownOutterContainer}>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
                <div className={styles.dropdownContainer}>
                  <Dropdown />
                </div>
              </div>
            </div>
            <Chart
              chartType="PieChart"
              width="100%"
              height="300px"
              data={dataPie4}
              options={optionsPie4}
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.lineChartHeader}>
            <p className={styles.headerH2}>Transaction Report</p>
            <div className={styles.dropdownContainer}>
              <Dropdown />
            </div>
          </div>

          <Chart
            chartType="Bar"
            width="100%"
            height="400px"
            data={multiData}
            options={multiOptions}
          />
        </div>
      </div>
    </CallCenterStructure>
  );
};

export default SuperAdminDashboard;
