import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const WalletDebitTransaction = () => {
  const [clearModal, setClearModal] = useState(false);
  return (
    <>
      <CallCenterStructure>
        <div className="mt-5 p-4">
          <h4 className="pb-4">Wallet Debit Transaction</h4>
          <main className="border border-light p-3">
            <form action="">
              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="membershipID">Membership ID *</label>
                  <Dropdown />
                </div>
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="pointsDebited">Wallet Debit Amount *</label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="Debit Amount"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="pin">Pin *</label>
                  <input className="w-100" type="text" placeholder="pin" />
                </div>

                <div className="form-group col-12 col-md-6">
                  <label htmlFor="remarks">Remarks</label>
                  <input className="w-100" type="text" placeholder="remarks" />
                </div>
              </div>

              <div className="form-group mt-3 d-flex justify-content-between d-md-flex ">
                <button className="mr-4">Submit</button>
                <button
                  className="resetButton"
                  type="button"
                  onClick={() => setClearModal(true)}>
                  Reset
                </button>
              </div>
            </form>
          </main>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
        </div>
      </CallCenterStructure>
    </>
  );
};

export default WalletDebitTransaction;
