import React, {useEffect, useRef, useState} from 'react';
import {useQuill} from 'react-quilljs'; // Assuming you are using Quill library
import 'quill/dist/quill.snow.css'; // Assuming you are using Quill styles
import styles from '../../styles/Quill.module.scss';

const QuillContainer = ({placeholder, onChange}) => {
  const {quill, quillRef} = useQuill();

  const insertToEditor = url => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, 'image', url);
  };

  const saveToServer = async file => {
    const body = new FormData();
    body.append('file', file);

    const res = await fetch('Your Image Server URL', {method: 'POST', body});
    insertToEditor(res.uploadedImageUrl);
  };

  const selectLocalImage = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };

  useEffect(() => {
    if (!quill) return;

    quill.on('text-change', () => {
      const editorHTML = quill.root.innerHTML;
      onChange(editorHTML); // Send the updated HTML to parent component
    });
  }, [quill, onChange]);

  return (
    <div className={styles.quillContainer}>
      <div ref={quillRef} className={styles.quill} placeholder={placeholder} />
    </div>
  );
};

export default QuillContainer;
