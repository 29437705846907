import React from 'react';

const ErrorMessage = ({ errMsg }) => {
  if (!errMsg) {
    return null;
  }

  return (
    <>
      <h5 className='text-danger'>{errMsg.message}</h5>
      <br />
      <ul>
        {Object.keys(errMsg.errors || {}).map((fieldName, index) => (
          <li className='text-danger' key={index}>
            <strong>{fieldName}:</strong> {errMsg.errors[fieldName][0]}
          </li>
        ))}
      </ul>
    </>
  );
};

export default ErrorMessage;
