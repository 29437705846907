import React, {useState} from 'react';
import styles from '../../styles/TicketTable.module.scss';
import {Unview, View} from '../../assets/icons';

const TicketTable = ({data, setShowModal}) => {
  const [rowIndex, setRowIndex] = useState(null);
  const [viewedRows, setViewedRows] = useState([]);

  const handleView = index => {
    setViewedRows(prevItemValues => [...prevItemValues, index]);
    setShowModal(true);
  };

  const isRowViewed = index => {
    return viewedRows.includes(index);
  };

  const getPriorityColor = priority => {
    switch (priority) {
      case 'LSL Program Team':
        return '#ff0000';
      case 'Branch':
        return '#ffbc00';
      case 'IT':
        return '#df508b';
      case 'Call Center':
        return '#00bce1';
      default:
        return 'gray';
    }
  };

  // useEffect(()=>{})

  const renderInteractions = value => {
    // console.log(viewedRows, rowIndex, 'rowIndex');
    if (value === 'Query') {
      return (
        <div className={styles.interactions}>
          {isRowViewed(rowIndex) ? <Unview /> : <View />}
          {value}
        </div>
      );
    }
    return value;
  };

  if (!data || data.length === 0) {
    return <p className='text-danger'>No record...</p>;
  }

  return (
    <table className={styles.ticketTable}>
      <thead>
        <tr>
          {Object.keys(data[0]).map(key => (
            <th key={key}>{key}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            {Object.entries(row).map(([key, value]) => (
              <td key={key}>
                {key === 'Query Priority' ? (
                  <div
                    style={{
                      backgroundColor: getPriorityColor(value),
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                    }}></div>
                ) : (
                  renderInteractions(value)
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TicketTable;
