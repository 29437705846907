import React, {useEffect, useState} from 'react';
import {Mark, Times, ViewPen} from '../../assets/icons';
import SearchInput from '../../component/SearchInput/SearchInput';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/CreateMasterMenu.module.scss';
import Dropdown from '../../component/DropDown/DropDown';
import CheckBox from '../../component/CheckBox/CheckBox';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import CheckboxUpgradePrivilege from '../../component/CheckboxUpgradePrivilege/CheckboxUpgradePrivilege';
import {HeadersOperator} from '../../service/Essentials';
import ApiConfig from '../../service/ApiConfig';
import axiosProperty from '../../service/HttpConfig';
import swal from 'sweetalert';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import Paginate from '../../component/Paginate/Paginate';
import {SearchIcon} from '../../assets/icons';
import {Link} from 'react-router-dom';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CreateMasterMenu = () => {
  const queryClient = useQueryClient();
  const [deleteModal, setDeleteModal] = useState(false);
  const [clearModal, setClearModal] = useState(false);
  const onClose = () => {
    setDeleteModal(false);
  };
  const [data, setData] = useState([
    {
      id: 1,
      name: 'Cash',
      href: 'new_user_type_master.php',
      desc: 'System Defined Master Setup',
    },
    {
      id: 2,
      name: 'Debit Card',
      href: 'new_user_type_master.php',
      desc: 'System Defined Master Setup',
    },
    {
      id: 3,
      name: 'Credit Card',
      href: 'new_user_type_master.php',
      desc: 'System Defined Master Setup',
    },
  ]);
  const [error, setError] = useState('oops... something went wrong');
  const [page, setPage] = useState(1);
  const [menuId, setMenuId] = useState(null);
  const [menuName, setMenuName] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [successModal, setSuccessModal] = useState(false);
  const [menuMaster, setMenuMaster] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  const [deleteId, setDeleteId] = useState(null);

  const [check, setCheck] = useState([
    {name: 'Premium', tag: false},
    {name: 'Premium Plus', tag: false},
    {name: 'Coalition', tag: false},
    {name: 'Enterprise', tag: false},
    {name: 'Dome', tag: false},
  ]);

  const [checkOperation, setCheckOperation] = useState([
    {name: 'Add', tag: false},
    {name: 'View', tag: false},
    {name: 'Modify', tag: false},
    {name: 'Delete', tag: false},
  ]);

  const [solutionArr, setSolutionArr] = useState([]);
  const [operationArr, setOperationArr] = useState([]);

  const [errors, setErrors] = useState({
    name: '',
    description: '',
    solution_type: '',
    operation: '',
    parent_id: null,
    href: '',
  });

  const [formData, setFormData] = useState({
    name: '',
    description: '',
    solution_type: '',
    operation: '',
    parent_id: null,
    href: '',
  });

  const handleChange = e => {
    const {name, value} = e.target;
    setFormData(prevData => ({...prevData, [name]: value}));
  };

  const handleSearch = event => {
    setSearchValue(event.target.value);
  };

  const handleSolutionCheck = (tag, i) => {
    let solution = check[i];
    solution.tag = !tag;
    let checkClone = [...check];
    checkClone[i] = solution;
    setCheck([...checkClone]);
    if (checkClone[i].tag === true) {
      setSolutionArr(prev => [...prev, checkClone[i].name]);
    } else if (checkClone[i].tag === false) {
      const getItem = solutionArr.find(item => {
        return item === checkClone[i].name;
      });
      if (getItem) {
        setSolutionArr(
          solutionArr.filter(itemDel => {
            return itemDel !== checkClone[i].name;
          }),
        );
      }
    }
  };

  const handleCheckOperation = (tag, i) => {
    let operation = checkOperation[i];
    operation.tag = !tag;
    let checkClone = [...checkOperation];
    checkClone[i] = operation;
    setCheckOperation([...checkClone]);
    if (checkClone[i].tag === true) {
      setOperationArr(prev => [...prev, checkClone[i].name]);
    } else if (checkClone[i].tag === false) {
      const getItem = operationArr.find(item => {
        return item === checkClone[i].name;
      });
      if (getItem) {
        setOperationArr(
          operationArr.filter(itemDel => {
            return itemDel !== checkClone[i].name;
          }),
        );
      }
    }
  };

  const handleMenuID = id => {
    console.log(id);
    setMenuId(id);
    setFormData(prev => {
      return {...prev, parent_id: parseInt(id)};
    });
  };
  const handleMenuName = name => {
    setMenuName(name);
  };

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      solution_type: solutionArr.toString(),
      operation: operationArr.toString(),
    }));
  }, [solutionArr, operationArr]);

  const handleReset = () => {
    setFormData({
      name: '',
      description: '',
      solution_type: '',
      operation: '',
      parent_id: '',
      href: '',
    });
  };
  const getMenu = async page => {
    const res = await axiosProperty.get(ApiConfig.fetchMenuMaster(page), {
      headers: HeadersOperator(),
    });
    return res.data;
  };
  const {data: menuData, status} = useQuery(
    ['menumaster', {other:'allmenu', page}],
    () => getMenu(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    if (searchValue === '') {
      setMenuMaster(menuData?.data?.data || []);
    }
    setMenuMaster(menuData?.data?.data || []);
  }, [menuData, searchValue]);

  const addMenu = async () => {
    try {
      const res = await axiosProperty.post(ApiConfig.createMenu, formData, {
        headers: HeadersOperator(),
      });

      setErrors({});

      swal({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };


  const {mutate: addMenuMutation} = useMutation({
    mutationFn: addMenu,
    onSuccess: () => {
      queryClient.invalidateQueries(['allmenu']);
    },
  });

  // search menu
  const searchMenu = async name => {
    try {
      const res = await axiosProperty.post(
        ApiConfig.searchMenu(name),
        searchValue,
        {
          headers: HeadersOperator(),
        },
      );

      setErrors({});
      setMenuMaster(res.data.data);

      swal({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (searchValue === '') {
      setMenuMaster(menuData?.data?.data || []);
    }
    setMenuMaster(menuData?.data?.data || []);
  }, [menuData, searchValue]);

  const updateMenu = async () => {
    try {
      const res = await axiosProperty.patch(
        ApiConfig.updateMenu(updateID),
        formData,
        {
          headers: HeadersOperator(),
        },
      );

      setIsSubmit(true);
      setIsUpdate(false);

      setErrors({});
      swal({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: updateMenuMutation} = useMutation({
    mutationFn: updateMenu,
    onSuccess: () => {
      queryClient.invalidateQueries(['allmenu']);
    },
  });

  const deleteMenu = async id => {
    try {
      const res = await axiosProperty.delete(ApiConfig.deleteMenu(id), {
        headers: HeadersOperator(),
      });
      setDeleteModal(false);
      swal({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
      queryClient.invalidateQueries(['allmenu']);
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      swal({
        title: 'Warning!',
        text: error,
        icon: 'warning',
      });
    }
  };

  return (
    <CallCenterStructure>
      <div className={styles.transactionTypeContainer}>
        <div>
          <h6 className={styles.headerText}>Menu Master</h6>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.formContainer}>
            <div className={styles.masterMenuInput}>
              <label htmlFor="" className={styles.transactionTypeLabel}>
                Menu Level<sup>*</sup>
              </label>
              <Dropdown
                header={HeadersOperator()}
                api={ApiConfig.fetchMenuDropdown}
                onSelect={handleMenuID}
                onSelectOption={handleMenuName}
                column={'name'}
                retrievedValue={menuName}
                key="name"
              />
              {errors?.parent_id && (
                <p className="text-danger">{errors?.parent_id}</p>
              )}
            </div>
            <div className={styles.masterMenuInput}>
              <label htmlFor="" className={styles.transactionTypeLabel}>
                Menu Description<sup>*</sup>
              </label>
              <input
                type="text"
                value={formData.description}
                name="description"
                onChange={handleChange}
                className={styles.transactionTypeInput}
              />
              {errors?.description && (
                <p className="text-danger">{errors?.description}</p>
              )}
            </div>
          </div>

          {/* <p className={styles.transactionTypeNoteText}>
            *Note:Click on column header for Ascending/Descending
          </p> */}

          <div className={styles.allCheckboxContainer}>
            <label htmlFor="" className={styles.transactionTypeLabel}>
              Type of Solution<sup>*</sup>
            </label>
            <div className={styles.generalCheckboxContainer}>
              {check.map(({name, tag}, i) => (
                <div className={styles.checkboxContainer} key={i}>
                  <CheckboxUpgradePrivilege
                    id={i}
                    checked={tag}
                    onChange={() => handleSolutionCheck(tag, i)}
                  />
                  <p className={styles.checkboxText}>{name}</p>
                </div>
              ))}
            </div>
            {errors?.solution_type && (
              <p className="text-danger">{errors?.solution_type}</p>
            )}
          </div>
          <div className={styles.menuSections}>
            <div className={styles.menuInnerSections}>
              <label htmlFor="">Menu HREF</label>
              <input
                type="text"
                name="href"
                value={formData.href}
                onChange={handleChange}
              />
              {errors?.href && <p className="text-danger">{errors?.href}</p>}
            </div>
            <div className={styles.menuInnerSections}>
              <label htmlFor="">
                Menu Name<sup>*</sup>
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors?.name && <p className="text-danger">{errors?.name}</p>}
            </div>
          </div>
          <div className={styles.allCheckboxContainer}>
            <label htmlFor="" className={styles.transactionTypeLabel}>
              Type of Operation<sup>*</sup>
            </label>
            <div className={styles.generalCheckboxContainer}>
              {checkOperation.map(({name, tag}, i) => (
                <div className={styles.checkboxContainer} key={i}>
                  <CheckboxUpgradePrivilege
                    id={i}
                    checked={tag}
                    onChange={() => handleCheckOperation(tag, i)}
                  />
                  <p className={styles.checkboxText}>{name}</p>
                </div>
              ))}
            </div>
            {errors?.operation && (
              <p className="text-danger">{errors?.operation}</p>
            )}
          </div>
          <div className={styles.viewMapBTNContainer}>
            <Link to="/menu-and-privilege-management/view-and-update-master-map">
              <button className={styles.viewMapBTN}>View Solution Map</button>
            </Link>
          </div>
          <div className={styles.submitAndResetBTN}>
            {isSubmit && (
              <button
                onClick={event => {
                  event.preventDefault();
                  addMenuMutation();
                }}>
                Submit
              </button>
            )}
            {isUpdate && (
              <button
                onClick={event => {
                  event.preventDefault();
                  updateMenuMutation();
                }}>
                Update
              </button>
            )}
            <button className={styles.resetBTN} onClick={handleReset}>
              Reset
            </button>
          </div>
          <div className={styles.noteTextContainer}>
            <p className={styles.noteText}>
              *Note:Click on column header for Ascending/Descending
            </p>
          </div>
        </div>
        <div className={styles.tableContainer}>
          <div className={styles.formContainer}>
            <div className={styles.searchInput}>
              <div className={styles.searchBarContainer}>
                <div className={styles.searchField}>
                  <div className={styles.searchIconContainer}>
                    <SearchIcon />
                  </div>
                  <input
                    type="text"
                    name="name"
                    value={searchValue}
                    className={styles.searchInput}
                    onChange={handleSearch}
                  />
                  {/* <SearchInput onSearchInputChange={()=>setSearchValue()}/> */}
                </div>
              </div>
            </div>
            <button
              className={styles.transactionTypeBtn}
              onClick={event => {
                event.preventDefault();
                searchMenu(searchValue);
              }}>
              Search
            </button>
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.dataTable}>
              <thead>
                <tr>
                  <th>Menu level</th>
                  <th>Menu Name</th>
                  <th>Menu HREF</th>
                  <th>Menu Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {status === 'loading' && (
                  <tr>
                    <td className="text-info">loading...</td>
                  </tr>
                )}

                {status === 'success' &&
                  (menuMaster?.length === 0 ? (
                    <tr>
                      <td>No Data!</td>
                    </tr>
                  ) : (
                    menuMaster?.map(each => (
                      <tr key={each.id}>
                        <td>{each.parent_id}</td>
                        <td>{each.name}</td>
                        <td>{each.href}</td>
                        <td>{each.description}</td>
                        <td>
                          <span
                            className={styles.icon}
                            onClick={() => {
                              setUpdateID(parseInt(each.id));
                              setIsSubmit(false);
                              setIsUpdate(true);
                              setSelectedItem(each);
                              setFormData({
                                name: each?.name,
                                description: each?.description,
                                solution_type: each?.solution_type,
                                operation: each?.operation,
                                parent_id: each?.parent_id,
                                href: each?.href,
                              });
                              window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth',
                              });
                            }}>
                            <ViewPen />
                          </span>
                          <span
                            className={styles.icon}
                            onClick={() => {
                              setDeleteId(each.id);
                              setDeleteModal(true);
                            }}>
                            <Times />
                          </span>
                        </td>
                      </tr>
                    ))
                  ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <Paginate page={page} data={menuData} setPage={setPage} />
          </div>
        </div>
        {successModal && (
          <div className={styles.successModal}>
            <Mark />
            <p className={styles.successText}>
              Payment Type Inserted Successfully!
            </p>
            <span onClick={() => setSuccessModal(false)}>
              <Times />
            </span>
          </div>
        )}
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && (
          <MenuDeleteModal
            onClose={onClose}
            onDelete={() => deleteMenu(deleteId)}
          />
        )}
      </div>
    </CallCenterStructure>
  );
};

export default CreateMasterMenu;
