import React from 'react';

import {Modal} from 'react-bootstrap';

const InvalidPin = ({onClose})=>{

    return (
        <Modal show={true} onHide={onClose}>
          <Modal.Header>
            <Modal.Title>Incorrect pin</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Enter the correct pin to validate the transaction</p>
          </Modal.Body>
          <Modal.Footer>
            <button onClick={onClose}>
              ok
            </button>
          </Modal.Footer>
        </Modal>
    );
};

export default InvalidPin;
