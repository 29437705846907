export const getAuth = ()=>{
    const auth = sessionStorage.getItem('token');
    if(auth){
        return true;
    }else{
        return false
    }
}

export const getToken = ()=>{
    return sessionStorage.getItem('token');
}

export const getUser = ()=>{
    const user = sessionStorage.getItem('user');
    return JSON.parse(user);
}
export const getCompany = ()=>{
    const company = sessionStorage.getItem('company');
    return JSON.parse(company);
}

export const getUsertype = ()=>{
    const usertype = sessionStorage.getItem('usertype');
    return JSON.parse(usertype);
}

export const getUsergroup = ()=>{
    const usergroup = sessionStorage.getItem('usergroup');
    return JSON.parse(usergroup);
}

export const getCompanyType = ()=>{
    const companytype = sessionStorage.getItem('company');
    return JSON.parse(companytype);
}

export const setSession = (token, user, usertype, usergroup, company)=>{
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', JSON.stringify(user));
    sessionStorage.setItem('usertype', JSON.stringify(usertype));
    sessionStorage.setItem('usergroup', JSON.stringify(usergroup));
    sessionStorage.setItem('company', JSON.stringify(company));
}


export const removeSession = ()=>{
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('usertype');
    sessionStorage.removeItem('usergroup');
    sessionStorage.removeItem('company');

}