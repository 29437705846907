import React from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/TemplateMasterSetupForEmail.module.scss';

const TemplateMasterSetupForEmail = () => {
  return (
    <CallCenterStructure>
      <div className={styles.emailTemplateMaster}>
        <div className={styles.headerText}>
          <p> Template Master Setup For Email</p>
        </div>
        <div className={styles.emailTemplateInnerContainer}>
          <div className={styles.leftContainer}></div>
          <div className={styles.rightContainer}></div>
        </div>
      </div>
    </CallCenterStructure>
  );
};

export default TemplateMasterSetupForEmail;
