import React, {useState} from 'react';
import {Times, ViewPen} from '../../assets/icons';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import styles from '../../styles/ApproveGameWinner.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';

const ApproveGameWinner = () => {
  const [deleteModal, setDeleteModal] = useState(false);

  const onClose = () => {
    setDeleteModal(false);
  };
  const data = [
    {name: 'Rasgan', level: 28, point: 'Yes', gameName: 'Chess', score: 28},
    {name: 'Rasgan', level: 28, point: 'Yes', gameName: 'Chess', score: 268},
    {name: 'Rasgan', level: 28, point: 'Yes', gameName: 'Chess', score: 628},
    {name: 'Rasgan', level: 28, point: 'Yes', gameName: 'Chess', score: 286},
  ];
  return (
    <CallCenterStructure>
      <div className={styles.approveGameWinner}>
        <div className={styles.headerText}>
          <p>Approve Game Winner</p>
        </div>
        <div className={styles.inputContainer}>
          <h6 className={styles.tableText}>Game winner(s) to be approved</h6>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Winner Name</th>
                  <th>Winner Level</th>
                  <th>Points/Prize</th>
                  <th>Game Name</th>
                  <th>Highest Score</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map(each => (
                  <tr key={each.score}>
                    <td>{each.name}</td>
                    <td>{each.level}</td>
                    <td>{each.point}</td>
                    <td>{each.gameName}</td>
                    <td>{each.score}</td>
                    <td>
                      <div className={styles.iconContainer}>
                        <span className={styles.icon}>
                          <ViewPen />
                        </span>

                        <span
                          className={styles.icon}
                          onClick={() => setDeleteModal(true)}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <p>Pagination</p>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <h6 className={styles.tableText}>Approved Game winners</h6>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Winner Name</th>
                  <th>Winner Level</th>
                  <th>Points/Prize</th>
                  <th>Game Name</th>
                  <th>Highest Score</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {data.map(each => (
                  <tr key={each.score}>
                    <td>{each.name}</td>
                    <td>{each.level}</td>
                    <td>{each.point}</td>
                    <td>{each.gameName}</td>
                    <td>{each.score}</td>
                    {/* <td>
                      <div className={styles.iconContainer}>
                        <span className={styles.icon}>
                          <ViewPen />
                        </span>

                        <span
                          className={styles.icon}
                          onClick={() => setDeleteModal(true)}>
                          <Times />
                        </span>
                      </div>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <p>Pagination</p>
          </div>
        </div>
        {deleteModal && <MenuDeleteModal onClose={onClose} />}
      </div>
    </CallCenterStructure>
  );
};

export default ApproveGameWinner;
