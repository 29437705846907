import React, {useState} from 'react';
import Dropdown from '../../component/DropDown/DropDown';
import styles from '../../styles/ApplicationManagementWebsite.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles1 from '../../styles/RadioButtonBlue.module.scss';
import wole from '../../assets/images/wole.jpeg';
import bank from '../../assets/images/bank.png';
import av from '../../assets/images/av.png';
import {Times, ViewPen} from '../../assets/icons';
const ApplicationManagementWebsite = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  const [selectedOption4, setSelectedOption4] = useState(null);
  const [selectedOption5, setSelectedOption5] = useState(null);
  const [selectedOption6, setSelectedOption6] = useState(null);
  const [selectedOption7, setSelectedOption7] = useState(null);
  const options = [
    {label: 'Horizontal Menu', img: wole},
    {label: 'Vertical Drop Menu', img: wole},
  ];

  const options1 = [
    {color: '#628862'},
    {color: '#638628'},
    {color: '#982628'},
    {color: '#682628'},
  ];

  const options2 = [{color: '#622262'}, {color: '#668628'}, {color: '#988628'}];
  const options3 = [{color: '#622262'}, {color: '#668628'}, {color: '#988628'}];
  const options4 = [{img: wole}, {img: bank}, {img: av}];
  const options6 = [{img: wole}, {img: bank}, {img: av}];
  const options5 = [{color: '#622262'}, {color: '#668628'}, {color: '#988628'}];
  const options7 = [
    {color: '#628862'},
    {color: '#638628'},
    {color: '#982628'},
    {color: '#682628'},
  ];
  const handleOptionChange = option => {
    setSelectedOption(option);
  };
  const handleOptionChange1 = option => {
    setSelectedOption1(option);
  };
  const handleOptionChange2 = option => {
    setSelectedOption2(option);
  };

  const handleOptionChange3 = option => {
    setSelectedOption3(option);
  };
  const handleOptionChange4 = option => {
    setSelectedOption4(option);
  };
  const handleOptionChange5 = option => {
    setSelectedOption5(option);
  };
  const handleOptionChange6 = option => {
    setSelectedOption6(option);
  };
  const handleOptionChange7 = option => {
    setSelectedOption7(option);
  };

  const tabelData = [
    {
      companyName: 'Fidelity Green Rewards',
      templateName: 'Lsl-THE OTH 521627',
      backgroundColor: '#454545',
      headerImage: wole,
      footerColor: '#682628',
      backgroundImage: bank,
      fontSize: '28px',
      fontFamily: 'Outfit',
      preview: 'Preview',
      activate: 'Activate',
    },
  ];

  return (
    <div>
      <CallCenterStructure>
        <div className={styles.ApplicationManagementWebsite}>
          <div className={styles.headerText}>
            <p>Application Management Website</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field1}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select Company<sup>*</sup>
                </label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select Company<sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer2}>
                <div className={styles.radioButtonsContainer}>
                  {options.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer}
                      key={index}
                      onClick={() => handleOptionChange(option.label)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.label === selectedOption
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <div className={styles.radioInnerImage}>
                          <img src={option.img} alt={option.label} />
                        </div>
                        <p>{option.label}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className={styles.field3}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Choose Font Colour of The Title menu<sup>*</sup>
                </label>
                <div className={styles.radioButtonsContainer3}>
                  {options1.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer3}
                      key={index}
                      onClick={() => handleOptionChange1(option.color)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.color === selectedOption1
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <div
                          style={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: `${option.color}`,
                          }}></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">More Colors:</label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field3}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Choose Font Colour of The Text on the Form<sup>*</sup>
                </label>
                <div className={styles.radioButtonsContainer3}>
                  {options2.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer3}
                      key={index}
                      onClick={() => handleOptionChange2(option.color)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.color === selectedOption2
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <div
                          style={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: `${option.color}`,
                          }}></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">More Colors:</label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field3}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Choose Colour of Background<sup>*</sup>
                </label>
                <div className={styles.radioButtonsContainer3}>
                  {options3.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer3}
                      key={index}
                      onClick={() => handleOptionChange3(option.color)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.color === selectedOption3
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <div
                          style={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: `${option.color}`,
                          }}></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">More Colors:</label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field3}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Choose Image for the Background<sup>*</sup>
                </label>
                <div className={styles.radioButtonsContainer3}>
                  {options4.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer3}
                      key={index}
                      onClick={() => handleOptionChange4(option.img)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.img === selectedOption4
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <img
                          src={option.img}
                          alt={option.img}
                          style={{
                            width: '40px',
                            height: '40px',
                          }}></img>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Upload Your Image</label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field3}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Choose Colour of Header</label>
                <div className={styles.radioButtonsContainer3}>
                  {options5.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer3}
                      key={index}
                      onClick={() => handleOptionChange5(option.color)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.color === selectedOption5
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <div
                          style={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: `${option.color}`,
                          }}></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">More Colors:</label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field3}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Choose Image for Header Panel</label>
                <div className={styles.radioButtonsContainer3}>
                  {options6.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer3}
                      key={index}
                      onClick={() => handleOptionChange6(option.img)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.img === selectedOption6
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <img
                          src={option.img}
                          alt={option.img}
                          style={{
                            width: '40px',
                            height: '40px',
                          }}></img>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Upload Your Image</label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field3}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Choose Colour of Header</label>
                <div className={styles.radioButtonsContainer3}>
                  {options7.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer3}
                      key={index}
                      onClick={() => handleOptionChange7(option.color)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.color === selectedOption7
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <div
                          style={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: `${option.color}`,
                          }}></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">More Colors:</label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field4}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select Font Family to Apply<sup>*</sup>
                </label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select Font Family to Apply<sup>*</sup>
                </label>
                <Dropdown />
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Save</button>
            <button className={styles.reset}>Preview</button>
          </div>
          <div className={styles.tableSection}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Template Name</th>
                  <th>Background Color</th>
                  <th>Header Image</th>
                  <th>Footer Color</th>
                  <th>Background Image</th>
                  <th>Font Size</th>
                  <th>Font Family</th>
                  <th>Preview</th>
                  <th>Click to Activate</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tabelData.map(each => (
                  <tr>
                    <td>{each.companyName}</td>
                    <td>{each.templateName}</td>
                    <td>{each.backgroundColor}</td>
                    <td>
                      <div className={styles.headerImage}>
                        <img src={each.headerImage} alt="header" />
                      </div>
                    </td>
                    <td>
                      <div
                        style={{
                          width: '100px',
                          height: '60px',
                          backgroundColor: `${each.footerColor}`,
                        }}></div>
                    </td>
                    <td>
                      <div className={styles.headerImage}>
                        <img src={each.backgroundImage} alt="header" />
                      </div>
                    </td>
                    <td>{each.fontSize}</td>
                    <td>{each.fontFamily}</td>
                    <td>{each.preview}</td>
                    <td>{each.activate}</td>
                    <td>
                      <div className={styles.iconContainer}>
                        <span className={styles.icon}>
                          <ViewPen />
                        </span>
                        <span className={styles.icon}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default ApplicationManagementWebsite;
