import React, {useState} from 'react';
import styles from '../../styles/MultipleChoiceStructureMaster.module.scss';
import Dropdown from '../../component/DropDown/DropDown';
import SearchInput from '../../component/SearchInput/SearchInput';
import {Times, ViewPen} from '../../assets/icons';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';
const MultipleChoiceStructureMaster = () => {
  const [clearModal, setClearModal] = useState(false);
  const tableData = [
    {
      id: 4,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Active',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 3,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Inactive',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 9,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Active',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 6,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Active',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
  ];
  return (
    <div>
      <CallCenterStructure>
        <div className={styles.CallCenterQueryType}>
          <div className={styles.headerText}>
            <p>Multiple Choice Structure Master</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field1}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Name Of Multiple Choice Structure<sup>*</sup>
                </label>
                <input type="text" name="" id="" />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Enter Number of Options<sup>*</sup>
                </label>
                <input type="text" name="" id="" />
              </div>
            </div>

            <div className={styles.middleText}>
              <p>Click Here to Add Options</p>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Name Of Option 1<sup>*</sup>
                </label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Name Of Option 2<sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Name Of Option 3</label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <button className={styles.submit}>Submit</button>
              <button
                className={styles.reset}
                type="button"
                onClick={() => setClearModal(true)}>
                Reset
              </button>
            </div>
          </div>
          <div className={styles.tableSection}>
            <div className={styles.searchInputContainer}>
              <div className={styles.searchInput}>
                <SearchInput />
              </div>
              <button className={styles.searchBTN}>Search</button>
            </div>
            <div className={styles.innerTableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Name Of Multiple Choice Structure</th>
                    <th>Number Of Options</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr key={each.id}>
                      <td>{each.name}</td>
                      <td>{each.code}</td>

                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <Times />
                          </span>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default MultipleChoiceStructureMaster;
