import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/AssignMembershipIDToFamilyMembers.module.scss';
import Dropdown from '../../component/DropDown/DropDown';
import {Link} from 'react-router-dom';
import { useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import { CustomerEnrollmentHeaders, Headers} from '../../service/Essentials';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';
import { useNavigate } from "react-router-dom"

const AssignMembershipIDToFamilyMembers = () => {
  const [branch, setBranch] = useState('');
  const [branchID, setBranchID] = useState('');
  const [userID, setUserID] = useState('');
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState(null);

  const handleBranchID = (id) => {
    setBranchID(id);
  };

  const handleBranch = (name) => {
    setBranch(name);
  };

  const navigate = useNavigate();

  const assignMembershipIDFamily = async () => {
    try{
        const res = await axiosProperty.put(ApiConfig.fetchAssignMembershipIDFamily(userID), {},
        {
          headers:CustomerEnrollmentHeaders(),
        } 
        );

       if(res.data.message == 'Membership ID Already Exists' || res.data.message == 'Member Do Not Exist'){
        swal({
            title: "Warning!",
            text: res.data.message,
            icon: "warning",
          });
          return;
       }
        setBranch('');
        setBranchID('');
        setUserID('');
        setErrors(null);
        navigate('/customer-enrolment/assign-family-member-membershipID-list', {state:res.data});
    }catch(err){
      if(err.response){
       setError(err.response.data.message);
       setErrors(err.response.data);
       swal({
        title: "error!",
        text: error,
        icon: "error",
      });
      }else{
      setError('Something went wrong, try again'); 
      swal({
        title: "error!",
        text: error,
        icon: "error",
      });
      }
     // console.log(err);
    }
  };

  return (
    <CallCenterStructure>
      <div className={styles.assignMembershipIDToFamilyMembers}>
        <div className={styles.headerText}>
          <p>Assign Family Member membership ID</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer}>
            <div className={styles.fieldContainer1}>
              <label htmlFor="">
                Branch<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchCompanyBranch} onSelect={handleBranchID} onSelectOption={handleBranch} column={'branch_name'} key="branch" />
            </div>
            <div className={styles.fieldContainer1}>
              <label htmlFor="">
                Enter Parent Membership ID/Phone No.<sup>*</sup>
              </label>
              <p className={styles.noticeText}> (No ‘0’ or country code)</p>
              <input value={userID} onChange={(e)=>setUserID(e.target.value)} type="text" />
            </div>
            {/* <Link to="/customer-enrolment/handle-member-query-at-branch"> */}
              <button className={styles.enterBTN} onClick={()=>assignMembershipIDFamily(userID)}>Enter</button>
            {/* </Link> */}
          </div>
          <ErrorMessage errMsg={errors} />
        </div>
      </div>
    </CallCenterStructure>
  );
};

export default AssignMembershipIDToFamilyMembers;
