import React, {useState, useEffect} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/SendSurvey.module.scss';
import RadioBlueButton3 from '../../component/RadioBlueButton3/RadioBlueButton3';
import Dropdown from '../../component/DropDown/DropDown';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import RadioButtonSingle from '../../component/RadioButtonSingle/RadioButtonSingle';
import RadioButtonFour from '../../component/RadioButtonFour/RadioButtonFour';
import RadioButton from '../../component/SingleRadioButton/SingleRadioButton';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import {getUser} from '../../service/common';
import swal from 'sweetalert';
import { CompanySurveyHeaders, ProgramID } from '../../service/Essentials';
import { useMutation} from 'react-query';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const SendSurvey = () => {
  const [clearModal, setClearModal] = useState(false);
  const user = getUser();
  const program_id = ProgramID();
  const [state, setState] = useState(false);
  const [labelText, setLabelText] = useState('Enter Membership ID');
  const [checkValue, setCheckValue] = useState('radio1');
  const [communication_type, setCommunication_type] = useState('Email');
  const [customer, setCustomer] = useState('Single Customer');
  const [option, setOption] = useState('');
  const [optionID, setOptionID] = useState('');
  const [survey, setSurvey] = useState('');
  const [surveyID, setSurveyID] = useState('');
  const [endpoint, setEndpoint] = useState(ApiConfig.fetchTiers);
  const [column, setColumn] = useState('Tier_name');
  const [idValue, setIdValue] = useState('Tier_id');
  const [membershipID, setMembershipID] = useState('');
  const [error, setError] = useState('oops... something went wrong');

  const handleOption = name => {
    setOption(name);
  };

  const handleOptionID = id => {
    setOptionID(id);
  };

  const handleSurvey = name => {
    setSurvey(name);
  };

  const handleSurveyID = id => {
    setSurveyID(id);
  };

  useEffect(() => {
    if (checkValue === 'radio1') {
      setState(true);
      setLabelText('Enter Membership ID');
    } else if (checkValue === 'radio2') {
      setState(false);
      setLabelText('All Customer');
    } else if (checkValue === 'radio3') {
      setState(false);
      setLabelText('Select Tier');
      setEndpoint(ApiConfig.fetchTiers);
      setColumn('Tier_name');
      setIdValue('Tier_id');
    } else if (checkValue === 'radio4') {
      setState(false);
      setLabelText('Select Segment');
      setEndpoint(ApiConfig.fetchSegments);
      setColumn('Segment_name');
      setIdValue('Segment_id');
    }
  }, [checkValue, customer]);

  const formData = {Company_id:program_id, Survey_id:surveyID, Enrollment_id:1, customer:customer, communication_type:communication_type,
                    membership_id:membershipID, tier:optionID, segment:optionID};
//console.log(formData);
                    const addSendSurvey = async () => {
                      try{
                         const res = await axiosProperty.post(ApiConfig.createSendSurvey, formData, 
                          {
                            headers:CompanySurveyHeaders(),
                        }
                          );

                          if(res.data.status == 'Failed!'){
                            swal({
                              title: "Error!",
                              text: res.data.message,
                              icon: "error",
                            }); 
                            return
                          }
                         
                          setSurvey('');
                          setSurveyID('');
                          setOption('');
                          setOptionID('');
                          setMembershipID('');
                         
                          swal({
                            title: "Success!",
                            text: "Send Survey added successfully",
                            icon: "success",
                          });
                      }catch(err){
                        if(err.response){
                          setError(err.response.data.message);
                          swal({
                            title: "Error!",
                            text: error,
                            icon: "error",
                          });
                        }else{
                          setError(err.response.data.message);
                          swal({
                            title: "warning!",
                            text: error,
                            icon: "error",
                          });
                        }
                      }
                    };
                  
                  const {mutate:addSendSurveyMutation} = useMutation(
                    {
                      mutationFn: addSendSurvey,
                    }
                    );
                  
  return (
    <CallCenterStructure>
      <div className={styles.sendSurvey}>
        <div className={styles.headerText}>
          <p>Send Survey</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select Survey Questionnaire<sup>*</sup>
              </label>
              <Dropdown
                header={CompanySurveyHeaders()}
                api={ApiConfig.fetchSurveyTypeAll}
                onSelect={handleSurveyID}
                onSelectOption={handleSurvey}
                retrievedValue={surveyID}
                column={'Survey_name'}
                idValue={'Survey_id'}
                key={'survey'}
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor=""></label>
              <RadioBlueButton3
                firstText="Email"
                secondText="SMS"
                thirdText="Both"
                onchange={setCommunication_type}
              />
              {/* <RadioButton
                  options={communication}
                  selectedOption={communication_type}
                  onChange={handleCommType}
                /> */}
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer2}>
              <label htmlFor="">
                Select Survey Questionnaire<sup>*</sup>
              </label>
              <RadioButtonFour
                firstText="Single Customer"
                secondText="All Customer"
                thirdText="Tier Based"
                fourthText="Segment Based"
                setCheckValue={setCheckValue}
                onchange={setCustomer}
              />
              {/* <RadioButton
                  options={customers}
                  selectedOption={customer}
                  onChange={handleCustomer}
                /> */}
            </div>
          </div>
{customer=='all customer' ? '':
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                {labelText}
                <sup>*</sup>
              </label>
              {state ? (
                <input
                  value={membershipID}
                  onChange={e => {
                    setMembershipID(e.target.value);
                  }}
                  type="text"
                />
              ) : (
                <Dropdown
                  header={CompanySurveyHeaders()}
                  api={endpoint}
                  onSelect={handleOptionID}
                  onSelectOption={handleOption}
                  column={column}
                  retrievedValue={optionID}
                  idValue={idValue}
                  key={'sendSurvey'}
                />
              )}
            </div>
          </div>
}
          <div className={styles.buttonContainer}>
            <button
              className={styles.submit}
              onClick={() => addSendSurveyMutation()}>
              Submit
            </button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
      </div>
    </CallCenterStructure>
  );
};

export default SendSurvey;
