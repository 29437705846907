import React, {useState, useEffect} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/HandleMemberQuery.module.scss';
import {ViewPen} from '../../assets/icons';
import {Link, useNavigate} from 'react-router-dom';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import { CallCenterHeaders, CustomerEnrollmentHeaders } from '../../service/Essentials';
import swal from 'sweetalert';
//import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';
const HandleMemberQuery = () => {
  const [searchItem, setSearchItem] = useState('');
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState(null);
  const [details, setDetails] = useState({});
  const [buttonStatus, setButtonStatus] = useState('Search');

  const navigate = useNavigate();


  const fetchMemberDetails = async () => {
    setButtonStatus('Fetching record');
    try{
        const res = await axiosProperty.get(ApiConfig.fetchCallCenterDetails(searchItem),
        {
          headers:CallCenterHeaders(),
        } 
        );
        if(res.data.data === null){
          swal({
            title: "error!",
            text: 'Membership ID not found',
            icon: "error",
          });
          setButtonStatus('Search');
          return;
        }
        setDetails(res.data.data);
        setSearchItem('');
        setErrors(null);
        setButtonStatus('Search');
       // navigate('/customer-enrolment/assign-family-member-membershipID-list', {state:res.data});
    }catch(err){
      setDetails({});
      if(err.response){
       setError(err.response.data.message);
       setErrors(err.response.data);
       swal({
        title: "error!",
        text: error,
        icon: "error",
      });
      }else{
      setError('Something went wrong, try again'); 
      swal({
        title: "error!",
        text: error,
        icon: "error",
      });
      }
      setButtonStatus('Search');
    }
  };

  const fetchProfile = async (id) => {
    try{
        const res = await axiosProperty.get(ApiConfig.fetchProfileDetails(id),
        {
          headers:CustomerEnrollmentHeaders(),
        } 
        );

       if(res.status === 404){
        swal({
            title: "Warning!",
            text: "No record found",
            icon: "warning",
          });
          return;
       }
       const data = JSON.stringify(res.data.data);
       sessionStorage.setItem('profile', data);
     navigate('/call-center/member-profile', {state:res.data});
    }catch(err){
      if(err.response){
       setError(err.response.data.message);
       swal({
        title: "error!",
        text: error,
        icon: "error",
      });
      }else{
      setError('Something went wrong, try again'); 
      swal({
        title: "error!",
        text: error,
        icon: "error",
      });
      }
    }
  };

  const data = Object.keys(details).length > 0 ? [details] : [];

  return (
    <>
      <CallCenterStructure>
        <div className={styles.handleMemberQueryContainer}>
          <h4 className={styles.handleMemberQueryHeader}>
            Handle Member Query
          </h4>

          <div className={styles.formContainer}>
            <div>
              <p className={styles.formHeader}>Enter Search Criteria</p>
            </div>
            <form className={styles.HMQForm}>
              <div className={styles.bvnContainer}>
                <label htmlFor="BVN">BVN</label>
                <input type="text" placeholder="Insert your text here" />
              </div>
              <div className={styles.memIdContainer}>
                <label htmlFor="MembershipID">Membership ID</label>
                <input type="text" value={searchItem} placeholder="Insert your text here" onChange={(e)=>setSearchItem(e.target.value)} />
              </div>
              <button className={styles.searchBtn} onClick={async (e)=>{e.preventDefault(); await fetchMemberDetails(searchItem);}}>{buttonStatus}</button>
            </form>
            <div>
              <p className={styles.formInfo}>
                Note: Atleast 1 Field is Required for Search
              </p>
            </div>
          </div>

          <div className={styles.searchedResults}>
            <div>
              <p className={styles.formHeader}>Search Result</p>
            </div>
            <div className={styles.tableMainContainer}>
              <table className={styles.HMQTable}>
                <thead className={styles.handleMemberQueryTable}>
                  <tr>
                    <th className={styles.firstColumn}>First Name</th>
                    <th>Last Name</th>
                    <th>Membership ID</th>
                    <th>Phone Number</th>
                    <th>Date of Birth</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ?(data.map((result, index) => (
                    <tr key={result.membershipID} className={styles.resultRows}>
                      <td className={styles.firstColumn}>{result.First_name}</td>
                      <td>{result.Last_name}</td>
                      <td>{result.Membership_id}</td>
                      <td>{result.Phone_no}</td>
                      <td>{result.Birth_date}</td>
                      <td className={styles.lastChild} onClick={()=>fetchProfile(result.Enrollment_id)}>
                        {/* <Link to="/call-center/member-profile"> */}
                          <ViewPen />
                          {/* {result.Action}
                        </Link> */}
                      </td>
                    </tr>
                  ))):(<tr><td colSpan={6}></td></tr>) }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </CallCenterStructure>
    </>
  );
};

export default HandleMemberQuery;
