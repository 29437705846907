import React from "react";
import styles from "../../styles/CheckBoxUpgrade.module.scss";

const CheckboxUpgradePrivilege = ({ id, onChange, checked, name }) => {
  //   const [checkedStyle, setCheckedStyle] = useState(false);

  const toggleCheck = () => {
    // setCheckedStyle(!checkedStyle);
    onChange({
      id: id,
      value: !checked,
    });
  };

  return (
    <div>
      <label className={styles.container}>
        <input
          type="checkbox"
          checked={checked}
          onChange={toggleCheck}
          id={id}
          name={name}
        />
        <span className={styles.checkmark}></span>
      </label>
    </div>
  );
};

export default CheckboxUpgradePrivilege;
