import {getToken, getUser, getCompanyType, getCompany} from './common';
import {Navigate} from 'react-router-dom';

export const Headers = () => {
  var company = getCompanyType();

  if (company) {
    var program_id = company.company_type_id ?? '';
  } else {
    program_id = '';
  }

  return {
    'Program-Id': program_id,
    Authorization: 'Bearer ' + getToken(),
    'X-Requested-With': 'XMLHttpRequest',
  };
};
export const HeadersOperator = () => {
  return {
    // 'program-id': pId,

    Authorization: `Bearer ${getToken()}`,
    'X-Requested-With': 'XMLHttpRequest',
  };
};

export const upgradeHeaders = () => {
  return {
    admin: 1,
    token: '79671898374164BAEKOTWZRAEKOTWZEAEKOTWZD',
  };
};

export const CustomerEnrollmentHeaders = () => {
  return {
    admin: 1,
    //token: "79671898374164BAEKOTWZRAEKOTWZEAEKOTWZD",
    token:"46338513769207BAEKOTWZPAEKOTWZPAEKOTWZF" ,
   "X-Requested-With": "XMLHttpRequest",
  };
};

export const CallCenterHeaders = () => {
  return {
    admin: 1,
    token: '99613064559561ZAEKOTWZWAEKOTWZAAEKOTWZM',
    'X-Requested-With': 'XMLHttpRequest',
  };
};

export const CompanySurveyHeaders = () => {
  return {
    admin: 1,
    token: '91797488944320NAEKOTWZOAEKOTWZIAEKOTWZR',
    'X-Requested-With': 'XMLHttpRequest',
  };
};

export const TransactionHeaders = () => {
  return {
    admin: 1,
   // token: "87800228978443YAEKOTWZCAEKOTWZMAEKOTWZA",
    token:"12542425358112CAEKOTWZRAEKOTWZDAEKOTWZZ",
    "X-Requested-With": "XMLHttpRequest",
  };
};

export const SweepStakeHeaders = () => {
  return {
    token: 'c15ec791f7630cb1beca7914932707a26a8532926b81d4b920ae6ccf4c1820a3',
    'X-Requested-With': 'XMLHttpRequest',
  };
};

export const AuctionHeaders = () => {
  var company = getCompany();
  let pId = company.id;
  return {
    token: '1234',
    'content-type': 'application/json',
    'program-id': 1,
  };
};

export const ApproveWinnerHeaders = () => {
  var company = getCompany();
  let pId = company.id;
  return {
    token: '1234',
    'content-type': 'application/json',
    'company-id': 1,
  };
};

export const campaignHeaders = () => {
  return {
    admin: 1,
    token: '79671898374164BAEKOTWZRAEKOTWZEAEKOTWZD',
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
  };
};

export const ProgramID = () => {
  var company = getCompanyType();

  if (company) {
    var program_id = company.company_type_id ?? '';
  } else {
    program_id = '';
  }
  return program_id;
};

export const RedirectToLogin = () => {
  const user = getUser();
  if (!user) {
    return <Navigate to="/" />;
  }
};