import React, {useState, useEffect} from 'react';
import {Times, ViewPen} from '../../assets/icons';
import Dropdown from '../../component/DropDown/DropDown';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import styles from '../../styles/ReferalCampaign.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import Paginate from '../../component/Paginate/Paginate';
import {
  Headers,
  HeadersOperator,
  campaignHeaders,
} from '../../service/Essentials';
import swal from 'sweetalert';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const ReferalCampaign = () => {
  const [clearModal, setClearModal] = useState(false);
  const queryClient = useQueryClient();
  const [deleteModal, setDeleteModal] = useState(false);
  const [error, setError] = useState('oops... something went wrong');
  const [page, setPage] = useState(1);
  const [programID, setProgramID] = useState('');
  const [programName, setProgramName] = useState('');
  const [companyID, setCompanyID] = useState(1);
  const [companyName, setCompanyName] = useState('');
  const [campaignStatus, setCampaignStatus] = useState(true);
  const [referral, setReferral] = useState([]);
  const [deleteId, setDeleteId] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState();
  const [selectedItem, setSelectedItem] = useState({});
  const [getCompanyName, setGetCompanyName] = useState({});

  const onClose = () => {
    setDeleteModal(false);
  };

  const handleCompanyID = () => {
    const getCompanyId = JSON.parse(sessionStorage.getItem('company'));
    // setCompanyID(getCompanyId.id);
    setCompanyID(1);
    setFormData(prevFormData => ({
      ...prevFormData,
      Company_id: 1,
    }));
  };
  console.log(companyID);
  const handleProgramName = name => {
    setProgramName(name);
    setFormData(prevFormData => ({
      ...prevFormData,
      program: name,
    }));
  };

  const handleProgramID = id => {
    setProgramID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Loyalty_program_id: id,
    }));
  };

  const handleCompanyName = name => {
    setCompanyName(name);
    setFormData(prevFormData => ({
      ...prevFormData,
      company_name: name,
    }));
  };

  const handleCampaignStatus = value => {
    if (value) {
      setCampaignStatus('Enabled');
    } else {
      setCampaignStatus('Disabled');
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      campaign_status: value ? 'Enabled' : 'Disabled',
    }));
  };

  const [errors, setErrors] = useState({
    Loyalty_program_id: '',
    Company_id: '',
    Membership_id: '',
    From_date: '',
    To_date: '',
    Campaign_name: '',
    Campaign_description: '',
    campaign_status: '',
    Reward_points: '',
    company_name: '',
    program: '',
  });

  const [formData, setFormData] = useState({
    Loyalty_program_id: programID,
    Company_id: companyID,
    Membership_id: '7744889000',
    From_date: '',
    To_date: '',
    Campaign_name: '',
    Campaign_description: '',
    campaign_status: 'Disabled',
    Reward_points: '',
    company_name: '',
    program: '',
  });

  const handleChange = event => {
    const {name, value} = event.target;
    setFormData(prev => ({...prev, [name]: value}));
  };

  const handleReset = () => {
    setFormData({
      Loyalty_program_id: '',
      Company_id: '',
      Membership_id: '',
      From_date: '',
      To_date: '',
      Campaign_name: '',
      Campaign_description: '',
      campaign_status: '',
      Reward_points: '',
      company_name: '',
      program: '',
    });
  };

  const addReferral = async () => {
    try {
      const res = await axiosProperty.post(
        ApiConfig.createReferral,
        {
          Loyalty_program_id: programID,
          Company_id: companyID,
          Membership_id: formData.Membership_id,
          From_date: formData.From_date,
          To_date: formData.To_date,
          Campaign_name: formData.Campaign_name,
          Campaign_description: formData.Campaign_description,
          campaign_status: campaignStatus,
          Reward_points: formData.Reward_points,
          company_name: formData.company_name,
        },
        {
          headers: campaignHeaders(),
        },
      );

      setErrors({});

      swal({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: addReferralMutation} = useMutation({
    mutationFn: addReferral,
  });

  const getCompany = async () => {
    const res = await axiosProperty.get(ApiConfig.fetchCompanyMaster, {
      headers: HeadersOperator(),
    });
    console.log(res.data);
    return res.data;
  };

  const {data: companyData} = useQuery(['company'], () => getCompany(), {
    keepPreviousData: true,
  });

  useEffect(() => {
    setGetCompanyName(companyData?.data?.data || []);
  }, [companyData]);

  const getReferral = async page => {
    const res = await axiosProperty.get(ApiConfig.fetchReferral(page), {
      headers: campaignHeaders(),
    });
    console.log(res.data);
    return res.data;
  };

  const {data: referralData, status} = useQuery(
    ['referral', page],
    () => getReferral(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setReferral(referralData?.data || []);
  }, [referralData]);

  const updateReferral = async () => {
    try {
      const res = await axiosProperty.put(
        ApiConfig.updateReferral(updateID),
        {
          Loyalty_program_id: formData.Loyalty_program_id,
          Company_id: formData.Company_id,
          Membership_id: formData.Membership_id,
          From_date: formData.From_date,
          To_date: formData.To_date,
          Campaign_name: formData.Campaign_name,
          Campaign_description: formData.Campaign_description,
          campaign_status: formData.campaign_status,
          Reward_points: formData.Reward_points,
          company_name: formData.company_name,
        },
        {
          headers: campaignHeaders(),
        },
      );

      setIsSubmit(true);
      setIsUpdate(false);

      setErrors({});
      swal({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: updateReferralMutation} = useMutation({
    mutationFn: updateReferral,
    onSuccess: () => {
      queryClient.invalidateQueries(['referral']);
    },
  });

  const deleteReferralCampaign = async id => {
    try {
      const res = await axiosProperty.delete(ApiConfig.deleteReferral(id), {
        headers: campaignHeaders(),
      });
      setDeleteModal(false);
      swal({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
      queryClient.invalidateQueries('referral');
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);
      swal({
        title: 'Warning!',
        text: error,
        icon: 'warning',
      });
    }
  };

  return (
    <CallCenterStructure>
      <div className={styles.referalCampaign}>
        <div className={styles.headerText}>
          <p>Create Referal Campaign</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Loyalty Programme<sup>*</sup>
              </label>
              <Dropdown
                header={Headers()}
                api={ApiConfig.fetchLoyaltyProgram}
                onSelect={handleProgramID}
                onSelectOption={handleProgramName}
                column={'program_name'}
                retrievedValue={formData.program}
                key="programme_name"
              />
              {errors?.program && (
                <p className="text-danger">{errors?.program}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Start Date<sup>*</sup>
              </label>
              <input
                type="date"
                name="From_date"
                value={formData.From_date}
                onChange={handleChange}
              />
              {errors?.From_date && (
                <p className="text-danger">{errors?.From_date}</p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Campaign Name<sup>*</sup>
              </label>
              <input
                type="text"
                name="Campaign_name"
                value={formData?.Campaign_name}
                onChange={handleChange}
              />
              {errors?.Campaign_name && (
                <p className="text-danger">{errors?.Campaign_name}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                End Date<sup>*</sup>
              </label>
              <input
                type="date"
                name="To_date"
                value={formData.To_date}
                onChange={handleChange}
              />
              {errors?.To_date && (
                <p className="text-danger">{errors?.To_date}</p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Campaign Description<sup>*</sup>
              </label>
              <input
                type="text"
                name="Campaign_description"
                value={formData.Campaign_description}
                onChange={handleChange}
              />
              {errors?.Campaign_description && (
                <p className="text-danger">{errors?.Campaign_description}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Company Name<sup>*</sup>
              </label>
              <Dropdown
                header={HeadersOperator()}
                api={ApiConfig.fetchCompanyMaster}
                onSelect={handleCompanyID}
                onSelectOption={handleCompanyName}
                column={'company_name'}
                retrievedValue={formData.company_name}
                key="company_name"
              />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Membership Id <sup>*</sup>
              </label>
              <input
                type="text"
                name="Membership_id"
                value={formData.Membership_id}
                onChange={handleChange}
              />
              {errors?.Membership_id && (
                <p className="text-danger">{errors?.Membership_id}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Reward Points for Referral<sup>*</sup>
              </label>
              <input
                type="text"
                name="Reward_points"
                value={formData.Reward_points}
                onChange={handleChange}
              />
              {errors?.Reward_points && (
                <p className="text-danger">{errors?.Reward_points}</p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer3}>
            <label htmlFor="">
              Campaign Status <sup>*</sup>
            </label>
            <RadioButtonBlue
              firstText="Enabled"
              secondText="Disabled"
              name="campaign_status"
              onChange={handleCampaignStatus}
              value={formData.campaign_status}
            />
            {errors?.campaign_status && (
              <p className="text-danger">{errors?.campaign_status}</p>
            )}
          </div>
          <div className={styles.buttonContainer}>
            {isSubmit && (
              <button
                className={styles.submit}
                onClick={e => {
                  e.preventDefault();
                  addReferralMutation();
                }}>
                Submit
              </button>
            )}
            {isUpdate && (
              <button
                className={styles.submit}
                onClick={e => {
                  e.preventDefault();
                  updateReferralMutation();
                }}>
                Update
              </button>
            )}
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  {/* <th>Company Code</th> */}
                  <th>Company Name</th>
                  <th>From Date</th>
                  <th>End Date</th>
                  <th>Reward Points</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {status === 'loading' && (
                  <tr>
                    <td className="text-info">loading...</td>
                  </tr>
                )}

                {status === 'success' &&
                  (referral.length === 0 ? (
                    <p>No Referral Campaign by this Company!</p>
                  ) : (
                    referral?.map(
                      each =>
                        each?.Active_flag === 1 && (
                          <tr key={each.Campaign_id}>
                            {/* <td>{each.code}</td> */}
                            <td>
                              {getCompanyName?.map(one =>
                                one.id === each.Company_id
                                  ? one.company_name
                                  : null,
                              )}
                            </td>
                            <td>{each.From_date}</td>
                            <td>{each.To_date}</td>
                            <td>{each.Reward_points}</td>
                            <td>
                              <div className={styles.iconContainer}>
                                <span
                                  className={styles.icon}
                                  onClick={() => {
                                    setUpdateID(each.Campaign_id);
                                    setIsSubmit(false);
                                    setIsUpdate(true);
                                    setSelectedItem(each);
                                    setFormData({
                                      Loyalty_program_id:
                                        each.Loyalty_program_id,
                                      Company_id: each.Company_id,
                                      Membership_id: formData.Membership_id,
                                      From_date: each.From_date,
                                      To_date: each.To_date,
                                      Campaign_name: each.Campaign_name,
                                      Campaign_description:
                                        each.Campaign_description,
                                      campaign_status: formData.campaign_status,
                                      Reward_points: each.Reward_points,
                                      company_name: formData.company_name,
                                    });
                                    window.scrollTo({
                                      top: 0,
                                      left: 0,
                                      behavior: 'smooth',
                                    });
                                  }}>
                                  <ViewPen />
                                </span>
                                <span
                                  className={styles.icon}
                                  onClick={() => {
                                    setDeleteId(each.Campaign_id);
                                    setDeleteModal(true);
                                  }}>
                                  <Times />
                                </span>
                              </div>
                            </td>
                          </tr>
                        ),
                    )
                  ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <Paginate page={page} data={referralData} setPage={setPage} />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <h6 className={styles.disableText}>Disabled Campaigns</h6>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  {/* <th>Company Code</th> */}
                  <th>Company Name</th>
                  <th>From Date</th>
                  <th>End Date</th>
                  <th>Reward Points</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {status === 'loading' && (
                  <tr>
                    <td className="text-info">loading...</td>
                  </tr>
                )}

                {status === 'success' &&
                  (referral.length === 0 ? (
                    <p>No Referral Campaign by this Company!</p>
                  ) : (
                    referral?.map(
                      each =>
                        each?.Active_flag === 0 && (
                          <tr key={each.Campaign_id}>
                            {/* <td>{each.code}</td> */}
                            <td>
                              {getCompanyName?.map(one =>
                                one.id === each.Company_id
                                  ? one.company_name
                                  : null,
                              )}
                            </td>
                            <td>{each.From_date}</td>
                            <td>{each.To_date}</td>
                            <td>{each.Reward_points}</td>
                            <td>
                              <div className={styles.iconContainer}>
                                <span
                                  className={styles.icon}
                                  onClick={() => {
                                    setUpdateID(each.Campaign_id);
                                    setIsSubmit(false);
                                    setIsUpdate(true);
                                    setSelectedItem(each);
                                    setFormData({
                                      Loyalty_program_id:
                                        each.Loyalty_program_id,
                                      Company_id: each.Company_id,
                                      Membership_id: formData.Membership_id,
                                      From_date: each.From_date,
                                      To_date: each.To_date,
                                      Campaign_name: each.Campaign_name,
                                      Campaign_description:
                                        each.Campaign_description,
                                      campaign_status: formData.campaign_status,
                                      Reward_points: each.Reward_points,
                                      company_name: formData.company_name,
                                    });
                                  }}>
                                  <ViewPen />
                                </span>
                                <span
                                  className={styles.icon}
                                  onClick={() => {
                                    setDeleteId(each.Campaign_id);
                                    setDeleteModal(true);
                                  }}>
                                  <Times />
                                </span>
                              </div>
                            </td>
                          </tr>
                        ),
                    )
                  ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <Paginate page={page} data={referralData} setPage={setPage} />
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && (
          <MenuDeleteModal
            onClose={onClose}
            onDelete={() => deleteReferralCampaign(deleteId)}
          />
        )}
      </div>
    </CallCenterStructure>
  );
};

export default ReferalCampaign;
