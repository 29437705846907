import React, {useEffect, useState} from 'react';
import styles from '../../styles/CreateAndEditFixedBudgetRegularCampaign.module.scss';
import {CloseCampaignIcon, OpenCampaignIcon} from '../../assets/icons';
import Dropdown from '../../component/DropDown/DropDown';
import SelectedCampaignItem from '../../component/SelectedCampaignItem/SelectedCampaignItem';

import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import RadioButton from '../../component/SingleRadioButton/SingleRadioButton';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import {
  HeadersOperator,
  Headers,
  SweepStakeHeaders,
  campaignHeaders,
} from '../../service/Essentials';
import swal from 'sweetalert';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CreateAndEditFixedBudgetRegularCampaign = () => {
  const queryClient = useQueryClient();
  const [clearModal, setClearModal] = useState(false);
  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState();
  const [isOpen3, setIsOpen3] = useState();
  const option1 = ['Yes', 'No'];
  const option2 = ['In Points', 'In Percentage'];
  const option3 = ['Fixed', 'Discreet'];
  const option4 = ['Enabled', 'Disabled'];
  const option5 = ['In Points'];
  const [disableInput, setDisableInput] = useState(false);

  const [fix, setFix] = useState(false);
  const [discrete, setDiscrete] = useState(false);
  const [branch, setBranch] = useState('');
  const [branchId, setBranchId] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [transactionName, setTransactionName] = useState('');
  const [transactionChannelId, setTransactionChannelId] = useState('');
  const [transactionChannelName, setTransactionChannelName] = useState('');
  const [productGroupId, setProductGroupId] = useState('');
  const [productGroupName, setProductGroupName] = useState('');
  const [productBrandId, setProductBrandId] = useState('');
  const [productBrandName, setProductBrandName] = useState('');
  const [productCodeId, setProductCodeId] = useState('');
  const [productCodeName, setProductCodeName] = useState('');

  const [sweepstakeID, setsweepstakeID] = useState('');
  const [sweepstakeSlug, setsweepstakeSlug] = useState('');
  const [sweepstakeFlag, setSweepstakeFlag] = useState('');
  const [transactionFlag, setTransactionFlag] = useState('');
  const [rewardFlag, setRewardFlag] = useState('');
  const [rewardAmountFlag, setRewardAmountFlag] = useState('In Points');
  const [rewardAmountCriteriaFlag, setRewardAmountCriteriaFlag] = useState('');
  const [tierFlag, setTierFlag] = useState('');
  const [rewardOnceFlag, setRewardOnceFlag] = useState('');
  const [error, setError] = useState('oops... something went wrong');
  const [programID, setProgramID] = useState('');
  const [programName, setProgramName] = useState('');
  const [companyID, setCompanyID] = useState('');
  const [operator, setOperator] = useState('');
  const [operatorId, setOperatorId] = useState('');
  const [tier, setTier] = useState('');
  const [campaignStatus, setCampaignStatus] = useState('');
  const [tierId, setTierId] = useState('');
  const [updateID, setUpdateID] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [eachFixed, setEachFixed] = useState({});

  const handleOpen1 = () => {
    setIsOpen1(!isOpen1);
    setIsOpen2(false);
    setIsOpen3(false);
  };

  const handleOpen2 = () => {
    setIsOpen1(false);
    setIsOpen2(!isOpen2);
    setIsOpen3(false);
  };

  const handleOpen3 = () => {
    setIsOpen1(false);
    setIsOpen2(false);
    setIsOpen3(!isOpen3);
  };

  useEffect(() => {
    const getCompanyId = JSON.parse(sessionStorage.getItem('company'));
    setCompanyID(getCompanyId.id);
    // setCompanyID(1);
    setFormData(prevFormData => ({
      ...prevFormData,
      Company_id: getCompanyId.id,
    }));
  }, []);

  const handleProgramName = name => {
    setProgramName(name);
    setFormData(prevFormData => ({
      ...prevFormData,
      program: name,
    }));
  };

  const handleProgramID = id => {
    setProgramID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Loyalty_program_id: id,
    }));
  };

  const handleSweepstakeID = id => {
    setsweepstakeID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Sweepstake_id: id,
    }));
  };
  const handleSweepstakeSlug = name => {
    setsweepstakeSlug(name);
  };

  const handleSweepstakeFlag = value => {
    if (value === 'Yes') {
      setSweepstakeFlag('Yes');
    } else {
      setSweepstakeFlag('No');
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      Sweepstake_flag: value === 'Yes' ? 1 : 0,
    }));
  };

  const handleBranchName = name => {
    console.log(name);
    setBranch(name);
  };

  const handleBranchID = id => {
    console.log(id);
    setBranchId(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      branch_id: id,
    }));
  };

  const handleTierName = name => {
    setTier(name);
  };

  const handleTransactionTypeID = id => {
    setTransactionId(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Transaction_id: id,
    }));
  };

  const handleTransactionTypeName = name => {
    setTransactionName(name);
  };

  const handleTransactionChannelID = id => {
    setTransactionChannelId(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Transaction_channel_id: id,
    }));
  };

  const handleTransactionChannelName = name => {
    setTransactionChannelName(name);
  };

  const handleProductGroupID = id => {
    setProductGroupId(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Product_group_id: id,
    }));
  };

  const handleProductGroupName = name => {
    setProductGroupName(name);
  };

  const handleProductBrandID = id => {
    setProductBrandId(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Product_brand_id: id,
    }));
  };

  const handleProductBrandName = name => {
    setProductBrandName(name);
  };

  const handleProductCodeID = id => {
    setProductCodeId(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Product_code: id,
    }));
  };

  const handleProductCodeName = name => {
    setProductCodeName(name);
  };

  const handleTierID = id => {
    setTierId(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Tier_id: id,
    }));
  };

  const handleTierFlag = value => {
    if (value === 'Yes') {
      setTierFlag('Yes');
    } else {
      setTierFlag('No');
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      Tier_flag: value === 'Yes' ? 1 : 0,
    }));
  };
  const handleRewardOnceFlag = value => {
    if (value === 'Yes') {
      setRewardOnceFlag('Yes');
    } else {
      setRewardOnceFlag('No');
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      Reward_once_flag: value === 'Yes' ? 1 : 0,
    }));
  };

  const handleTransactionFlag = value => {
    if (value === 'Yes') {
      setTransactionFlag('Yes');
    } else {
      setTransactionFlag('No');
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      Transaction_amt_flag: value === 'Yes' ? 1 : 0,
    }));
  };

  const handleRewardFlag = value => {
    if (value === 'In Points') {
      setRewardFlag('In Points');
    } else if (value === 'In Percentage') {
      setRewardFlag('In Percentage');
    }
  };

  const handleCampaignStatus = value => {
    if (value === 'Enabled') {
      setCampaignStatus('Enabled');
    } else {
      setCampaignStatus('Disabled');
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      campaign_status: value ? 'Enabled' : 'Disabled',
    }));
  };

  const handleRewardAmountFlag = value => {
    if (value === 'Fixed') {
      setFix(true);
      setDiscrete(false);
      setRewardAmountFlag('Fixed');
    } else if (value === 'Discreet') {
      setFix(false);
      setDiscrete(true);
      setRewardAmountFlag('Discreet');
    }
  };

  const handleRewardAmountCriteriaFlag = value => {
    if (value === 'In Points') {
      setFix(true);
      setDiscrete(false);
      setRewardAmountCriteriaFlag('In Points');
    } else if (value === 'In Percentage') {
      setFix(false);
      setDiscrete(true);
      setRewardAmountCriteriaFlag('In Percentage');
    }
  };

  const handleOperator = namee => {
    setOperator(namee);
    setFormData(prevFormData => ({
      ...prevFormData,
      operator: namee,
    }));
  };

  const handleOperatorID = id => {
    setOperatorId(id);
  };

  const [errors, setErrors] = useState({
    Loyalty_program_id: '',
    Company_id: '',
    Membership_id: '',
    Product_group_id: '',
    Product_brand_id: '',
    Transaction_id: '',
    Transaction_channel_id: '',
    Transaction_amount: '',
    Transaction_amt_flag: '',
    Product_code: '',
    Tier_flag: '',
    Tier_id: '',
    branch_id: '',
    From_date: '',
    To_date: '',
    Campaign_name: '',
    Campaign_description: '',
    campaign_status: '',
    Reward_points: '',
    Reward_percent: '',
    Fixed_amount: '',
    Discrete_amt: '',
    Reward_fix_amt_flag: '',
    First_iteration_percentage: '',
    Second_iteration_percentage: '',
    company_name: '',
    program: '',
    Points: '',
    Sweepstake_id: '',
    Sweepstake_flag: '',
    Sweepstake_ticket_limit: '',
    Max_reward_budget: '',
    Max_reward_budget_cust: '',
  });

  const [formData, setFormData] = useState({
    Loyalty_program_id: programID,
    Company_id: companyID,
    Membership_id: '7744889000',
    Product_group_id: '',
    Product_brand_id: '',
    Transaction_id: '',
    Product_code: '',
    Transaction_channel_id: '',
    Transaction_amount: '',
    Transaction_amt_flag: '',
    Tier_flag: '',
    Tier_id: '',
    branch_id: '',
    From_date: '',
    To_date: '',
    Fixed_amount: '',
    Discrete_amt: '',
    Campaign_name: '',
    First_iteration_percentage: '',
    Second_iteration_percentage: '',
    Campaign_description: '',
    campaign_status: 'Disabled',
    Reward_points: '',
    Reward_percent: '',
    Reward_fix_amt_flag: '',
    company_name: '',
    program: '',
    Points: '',
    Sweepstake_id: '',
    Sweepstake_flag: '',
    Sweepstake_ticket_limit: '',
    Max_reward_budget: '',
    Max_reward_budget_cust: '',
  });

  const handleChange = event => {
    const {name, value} = event.target;
    setFormData(prev => ({...prev, [name]: value}));
  };

  const handleReset = () => {
    setFormData({
      Loyalty_program_id: '',
      Company_id: '',
      Membership_id: '',
      Product_group_id: '',
      Product_brand_id: '',
      Transaction_id: '',
      Product_code: '',
      Transaction_channel_id: '',
      Transaction_amount: '',
      Transaction_amt_flag: '',
      Tier_flag: '',
      branch_id: '',
      From_date: '',
      To_date: '',
      Fixed_amount: '',
      Discrete_amt: '',
      Campaign_name: '',
      First_iteration_percentage: '',
      Second_iteration_percentage: '',
      Campaign_description: '',
      campaign_status: '',
      Reward_points: '',
      Reward_percent: '',
      Reward_fix_amt_flag: '',
      company_name: '',
      program: '',
      Points: '',
      Sweepstake_id: '',
      Sweepstake_flag: '',
      Sweepstake_ticket_limit: '',
      Max_reward_budget: '',
      Max_reward_budget_cust: '',
    });
  };

  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    setUpdateID(params.get('id'));
    if (updateID) {
      setIsSubmit(false);
      setIsUpdate(true);
      setFormData({
        Loyalty_program_id: eachFixed?.Loyalty_program_id,
        Company_id: eachFixed?.Company_id,
        Membership_id: eachFixed?.Membership_id,
        Product_group_id: eachFixed?.Product_group_id,
        Product_brand_id: eachFixed?.Product_brand_id,
        Transaction_id: eachFixed?.Transaction_id,
        Product_code: eachFixed?.Product_code,
        Transaction_channel_id: eachFixed?.Transaction_channel_id,
        Transaction_amount: eachFixed?.Transaction_amount,
        Transaction_amt_flag: eachFixed?.Transaction_amt_flag,
        Tier_flag: eachFixed?.Tier_flag,
        Tier_id: eachFixed?.Tier_id,
        branch_id: eachFixed?.branch_id,
        From_date: eachFixed?.From_date,
        To_date: eachFixed?.To_date,
        Fixed_amount: eachFixed?.Fixed_amount,
        Discrete_amt: eachFixed?.Discrete_amt,
        Campaign_name: eachFixed?.Campaign_name,
        First_iteration_percentage: eachFixed?.First_iteration_percentage,
        Second_iteration_percentage: eachFixed?.Second_iteration_percentage,
        Campaign_description: eachFixed?.Campaign_description,
        campaign_status: eachFixed?.campaign_status,
        Reward_points: eachFixed?.Reward_points,
        Reward_percent: eachFixed?.Reward_percent,
        Reward_fix_amt_flag: eachFixed?.Reward_fix_amt_flag,
        company_name: eachFixed?.company_name,
        program: '',
        Points: '',
        Sweepstake_id: eachFixed?.Sweepstake_id,
        Sweepstake_flag: eachFixed?.Sweepstake_flag,
        Sweepstake_ticket_limit: eachFixed?.Sweepstake_ticket_limit,
        Max_reward_budget: eachFixed?.Max_reward_budget,
        Max_reward_budget_cust: eachFixed?.Max_reward_budget_cust,
      });
    } else {
      setIsSubmit(true);
      setIsUpdate(false);
      setFormData({});
    }
    let getOne = JSON.parse(localStorage.getItem('eachFixed'));
    setEachFixed(getOne);
  }, [updateID]);

  const addFixedBudget = async () => {
    try {
      const res = await axiosProperty.post(
        ApiConfig.createFixedBudget,
        {
          Company_id: 1,
          Membership_id: 7744889000,
          Loyalty_program_id: 1,
          Tier_id: 1,
          Sweepstake_id: 3,
          Campaign_name: 'Fixed Budget Campaign',
          Sweepstake_ticket_limit: 3,
          Campaign_description: 'Fixed Budget Description',
          From_date: '2024-11-20',
          To_date: '2026-12-31',
          Tier_flag: 1,
          Active_flag: 1,
          Reward_flag: 1,
          Reward_points: 4827,
          Reward_percent: '',
          Sweepstake_flag: 1,
          Reward_once_flag: 1,
          Transaction_amt_flag: 1,
          Transaction_amount: 3500,
          operator: '>=',
          Reward_fix_amt_flag: 1,
          Fixed_amount: 3700,
          Product_group_id: 1,
          Product_brand_id: 1,
          Transaction_id: 1,
          Transaction_channel_id: 3,
          branch_id: 6,
          campaign_status: 'Enabled',
          Discrete_amt: '',
          Max_reward_budget: 400,
          Max_reward_budget_cust: 20,

          // Loyalty_program_id: programID,
          // Company_id: companyID,
          // Membership_id: "7744889000",
          // Product_group_id: formData?.Product_group_id,
          // Product_brand_id: formData?.Product_brand_id,
          // Transaction_id: formData?.Transaction_id,
          // Product_code: formData?.Product_code,
          // Transaction_channel_id: formData?.Transaction_channel_id,
          // Transaction_amount: formData?.Transaction_amount,
          // Transaction_amt_flag: formData?.Transaction_amt_flag,
          // Tier_flag: formData?.Tier_flag,
          // Tier_id: formData?.Tier_id,
          // branch_id: formData?.branch_id,
          // From_date: formData?.From_date,
          // To_date: formData?.To_date,
          // Fixed_amount: formData?.Fixed_amount,
          // Discrete_amt: formData?.Discrete_amt,
          // Campaign_name: formData?.Campaign_name,
          // First_iteration_percentage: formData?.First_iteration_percentage,
          // Second_iteration_percentage: formData?.Second_iteration_percentage,
          // Campaign_description: formData?.Campaign_description,
          // campaign_status: formData?.campaign_status,
          // Reward_points: formData?.Reward_points,
          // Reward_percent: formData?.Reward_percent,
          // Reward_fix_amt_flag: formData?.Reward_fix_amt_flag,
          // company_name: formData?.company_name,
          // program: formData?.program,
          // Points: formData?.Points,
          // Sweepstake_id: formData?.Sweepstake_id,
          // Sweepstake_flag: formData?.Sweepstake_flag,
          // Sweepstake_ticket_limit: formData?.Sweepstake_ticket_limit,
          // Max_reward_budget: formData?.Max_reward_budget,
          // Max_reward_budget_cust: formData?.Max_reward_budget_cust,
        },
        {
          headers: campaignHeaders(),
        },
      );

      setErrors({});

      swal({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: addFixedBudgetMutation} = useMutation({
    mutationFn: addFixedBudget,
  });

  const updateFixedBudget = async () => {
    try {
      const res = await axiosProperty.put(
        ApiConfig.updateBudgetRegular(updateID),
        formData,
        {
          headers: campaignHeaders(),
        },
      );
      setIsSubmit(true);
      setIsUpdate(false);
      setErrors({});

      swal({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: updateFixedBudgetMutation} = useMutation({
    mutationFn: updateFixedBudget,
    onSuccess: () => {
      queryClient.invalidateQueries(['fixedbudget']);
    },
  });

  //SELECT BRANCH PROPS START
  const list = [
    {name: 'First Bank', id: 1},
    {name: 'UBA', id: 2},
    {name: 'Fidelity', id: 3},
    {name: 'Polaris', id: 4},
    {name: 'Access', id: 5},
    {name: 'GTB', id: 6},
    {name: 'Eco Bank', id: 7},
    {name: 'Micro Finance', id: 8},
  ];
  const [pickedBranch, setPickedBranch] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredResult, setFilteredResult] = useState(list);
  const [disabledItems, setDisabledItems] = useState([]);
  const handleInputChange = e => {
    const inputValue = e.target.value;
    setSearchTerm(inputValue);
    filterResult(inputValue);
  };

  const filterResult = input => {
    const filteredList = list.filter(each =>
      each.name.toLowerCase().includes(input.toLowerCase()),
    );
    setFilteredResult(filteredList);
  };

  const handlePickedBranch = selectedBranch => {
    setPickedBranch(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setDisabledItems(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setSearchTerm('');
  };

  const handleRemoveBranch = deletedBranch => {
    const removedBranch = pickedBranch.filter(each => each !== deletedBranch);
    setPickedBranch(removedBranch);
    setDisabledItems(removedBranch);
  };
  const handleOnFocusChange = () => {
    setIsFocused(true);
  };

  const handleOnBlurChange = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 200);
  };

  //SELECT BRANCH PROPS END

  // Transaction Type START///////////////////////////
  const transactionType = [
    {name: 'Bitcoin', id: 1},
    {name: 'USSD', id: 2},
    {name: 'USDT', id: 3},
    {name: 'Tranfer', id: 4},
    {name: 'NFT', id: 5},
  ];
  const [pickedTT, setPickedTT] = useState([]);
  const [disabledTT, setDisabledTT] = useState([]);
  const [searchTT, setSearchTT] = useState('');
  const [transactionTypeData, setTransactionTypeData] =
    useState(transactionType);
  const [isTTVisible, setIsTTVisible] = useState(false);

  const handleInputChangeTT = e => {
    const inputValue = e.target.value;
    setSearchTT(inputValue);
    filterResultTT(inputValue);
  };

  const filterResultTT = input => {
    const filteredList = transactionType.filter(each =>
      each.name.toLowerCase().includes(input.toLowerCase()),
    );
    setTransactionTypeData(filteredList);
  };

  const handlePickedTT = selectedBranch => {
    setPickedTT(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setDisabledTT(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setSearchTT('');
  };

  const handleRemoveTT = deletedTT => {
    const removedBranch = pickedTT.filter(each => each !== deletedTT);
    setPickedTT(removedBranch);
    setDisabledTT(removedBranch);
  };
  const handleOnFocusChangeTT = () => {
    setIsTTVisible(true);
  };

  const handleOnBlurChangeTT = () => {
    setTimeout(() => {
      setIsTTVisible(false);
    }, 200);
  };

  //TRANSACTION TYPE ENDS

  //TRANSACTION CHANNEL START
  const [pickedTC, setPickedTC] = useState([]);
  const [disabledTC, setDisabledTC] = useState([]);
  const [searchTC, setSearchTC] = useState('');
  const [transactionChanelData, setTransactionChanelData] =
    useState(transactionType);
  const [transactionChanelVisible, setTransactionChanelVisible] =
    useState(false);
  const handleInputChangeTC = e => {
    const inputValue = e.target.value;
    setSearchTC(inputValue);
    filterResultTC(inputValue);
  };

  const filterResultTC = input => {
    const filteredList = transactionType.filter(each =>
      each.name.toLowerCase().includes(input.toLowerCase()),
    );
    setTransactionChanelData(filteredList);
  };

  const handlePickedTC = selectedBranch => {
    setPickedTC(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setDisabledTC(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setSearchTC('');
  };

  const handleRemoveTC = deletedTT => {
    const removedBranch = pickedTT.filter(each => each !== deletedTT);
    setPickedTC(removedBranch);
    setDisabledTC(removedBranch);
  };
  const handleOnFocusChangeTC = () => {
    setTransactionChanelVisible(true);
  };

  const handleOnBlurChangeTC = () => {
    setTimeout(() => {
      setTransactionChanelVisible(false);
    }, 200);
  };

  //TRANSACTION CHANNEL END

  //   For Product Group///////////////////////////
  const [productGroupData, setProductGroupData] = useState(transactionType);
  const [searchPG, setSearchPG] = useState('');
  const [pickedPG, setPickedPG] = useState([]);
  const [disabledPG, setDisabledPG] = useState([]);
  const [productGroupVisible, setProductGroupVisible] = useState(false);
  const handleInputChangePG = e => {
    const inputValue = e.target.value;
    setSearchPG(inputValue);
    filterResultPG(inputValue);
  };

  const filterResultPG = input => {
    const filteredList = productGroupData.filter(each =>
      each.name.toLowerCase().includes(input.toLowerCase()),
    );
    setProductGroupData(filteredList);
  };

  const handlePickedPG = selectedBranch => {
    setPickedPG(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setDisabledPG(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setSearchPG('');
  };

  const handleRemovePG = deletedTT => {
    const removedBranch = pickedPG.filter(each => each !== deletedTT);
    setPickedPG(removedBranch);
    setDisabledPG(removedBranch);
  };

  const handleOnFocusChangePG = () => {
    setProductGroupVisible(true);
  };

  const handleOnBlurChangePG = () => {
    setTimeout(() => {
      setProductGroupVisible(false);
    }, 200);
  };

  //   For Product Group Ends///////////////////////////

  //   For Product Brand///////////////////////////

  const [searchPB, setSearchPB] = useState('');
  const [disabledPB, setDisabledPB] = useState([]);
  const [productBrandVisible, setProductBrandVisible] = useState(false);
  const [productBrandData, setProductBrandData] = useState(transactionType);
  const [pickedPB, setPickedPB] = useState([]);

  const handleInputChangePB = e => {
    const inputValue = e.target.value;
    setSearchPB(inputValue);
    filterResultPB(inputValue);
  };

  const filterResultPB = input => {
    const filteredList = productBrandData.filter(each =>
      each.name.toLowerCase().includes(input.toLowerCase()),
    );
    setProductBrandData(filteredList);
  };

  const handlePickedPB = selectedBranch => {
    setPickedPB(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setDisabledPB(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setSearchPB('');
  };

  const handleRemovePB = deletedTT => {
    const removedBranch = pickedPB.filter(each => each !== deletedTT);
    setPickedPB(removedBranch);
    setDisabledPB(removedBranch);
  };

  const handleOnFocusChangePB = () => {
    setProductBrandVisible(true);
  };

  const handleOnBlurChangePB = () => {
    setTimeout(() => {
      setProductBrandVisible(false);
    }, 200);
  };

  //   For Product Brand Ends///////////////////////////

  //   For Product Code///////////////////////////
  const [productCodeData, setProductCodeData] = useState(transactionType);
  const [searchPC, setSearchPC] = useState('');
  const [pickedPC, setPickedPC] = useState([]);
  const [disabledPC, setDisabledPC] = useState([]);
  const [productCodeVisible, setProductCodeVisible] = useState(false);
  const handleInputChangePC = e => {
    const inputValue = e.target.value;
    setSearchPC(inputValue);
    filterResultPC(inputValue);
  };

  const filterResultPC = input => {
    const filteredList = productCodeData.filter(each =>
      each.name.toLowerCase().includes(input.toLowerCase()),
    );
    setProductCodeData(filteredList);
  };

  const handlePickedPC = selectedBranch => {
    setPickedPC(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setDisabledPC(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setSearchPC('');
  };

  const handleRemovePC = deletedTT => {
    const removedBranch = pickedPC.filter(each => each !== deletedTT);
    setPickedPC(removedBranch);
    setDisabledPC(removedBranch);
  };

  const handleOnFocusChangePC = () => {
    setProductCodeVisible(true);
  };

  const handleOnBlurChangePC = () => {
    setTimeout(() => {
      setProductCodeVisible(false);
    }, 200);
  };

  //PRODUCT CODE END

  return (
    <CallCenterStructure>
      <div className={styles.createCampaign}>
        <div className={styles.headerText}>
          <p>Create Fixed Budget Regular Campaign</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.BasicInfoContainer}>
            <div className={styles.campaignHeader} onClick={handleOpen1}>
              <p>
                Basic Information<sup>*</sup>
              </p>
              {isOpen1 ? (
                <span>
                  <OpenCampaignIcon />
                </span>
              ) : (
                <span>
                  <CloseCampaignIcon />
                </span>
              )}
            </div>
            {isOpen1 && (
              <div className={styles.basicInfoForm}>
                <div className={styles.fieldContainer1}>
                  <div className={styles.fieldInnerContainer1}>
                    <label htmlFor="">
                      Loyalty Program <sup>*</sup>
                    </label>
                    <Dropdown
                      header={Headers()}
                      api={ApiConfig.fetchLoyaltyProgram}
                      onSelect={handleProgramID}
                      onSelectOption={handleProgramName}
                      column={'program_name'}
                      retrievedValue={formData.program}
                      key="programme_name"
                    />
                    {errors?.program && (
                      <p className="text-danger">{errors?.program}</p>
                    )}
                  </div>
                  <div className={styles.fieldInnerContainer1}>
                    <label htmlFor="">
                      Start Date<sup>*</sup>
                    </label>
                    <input
                      type="date"
                      name="From_date"
                      onChange={handleChange}
                      value={formData.From_date}
                    />
                    {errors?.From_date && (
                      <p className="text-danger">{errors?.From_date}</p>
                    )}
                  </div>
                </div>
                <div className={styles.fieldContainer2}>
                  <div className={styles.fieldInnerContainer1}>
                    <label htmlFor="">
                      Campaign Name<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      name="Campaign_name"
                      value={formData?.Campaign_name}
                      onChange={handleChange}
                    />
                    {errors?.Campaign_name && (
                      <p className="text-danger">{errors?.Campaign_name}</p>
                    )}
                  </div>
                  <div className={styles.fieldInnerContainer1}>
                    <label htmlFor="">
                      End Date<sup>*</sup>
                    </label>
                    <input
                      type="date"
                      name="To_date"
                      onChange={handleChange}
                      value={formData.To_date}
                    />
                    {errors?.To_date && (
                      <p className="text-danger">{errors?.To_date}</p>
                    )}
                  </div>
                </div>

                <div className={styles.fieldContainer3}>
                  <div className={styles.fieldContent3}>
                    <div className={styles.fieldInnerContainer3}>
                      <label htmlFor="">
                        Select Branches<sup>*</sup>
                      </label>
                      <div
                        className={
                          disableInput
                            ? styles.searchResultContainerDisabled
                            : styles.searchResultContainer
                        }>
                        <SelectedCampaignItem
                          pickedItems={pickedBranch}
                          handleOnFocusChange={handleOnFocusChange}
                          handleOnBlurChange={handleOnBlurChange}
                          searchTerm={searchTerm}
                          handleInputChange={handleInputChange}
                          handleRemoveBranch={handleRemoveBranch}
                          disableInput={disableInput}
                        />
                      </div>
                    </div>
                    {isFocused && (
                      <div className={styles.branchContainer}>
                        <ul className={styles.branchInnerContainer}>
                          {filteredResult.map(each => (
                            <li
                              className={
                                disabledItems.includes(each.name)
                                  ? styles.disabledItem
                                  : styles.activedItem
                              }
                              onClick={() => handlePickedBranch(each.name)}
                              key={each.id}>
                              {each.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.fieldContainer3}>
                  <div className={styles.fieldContent3}>
                    <div className={styles.fieldInnerContainer3}>
                      <label htmlFor="">Transaction Type</label>
                      <div
                        className={
                          disableInput
                            ? styles.searchResultContainerDisabled
                            : styles.searchResultContainer
                        }>
                        <SelectedCampaignItem
                          pickedItems={pickedTT}
                          handleOnFocusChange={handleOnFocusChangeTT}
                          handleOnBlurChange={handleOnBlurChangeTT}
                          searchTerm={searchTT}
                          handleInputChange={handleInputChangeTT}
                          handleRemoveBranch={handleRemoveTT}
                          disableInput={disableInput}
                        />
                      </div>
                    </div>
                    {isTTVisible && (
                      <div className={styles.branchContainer}>
                        <ul className={styles.branchInnerContainer}>
                          {transactionTypeData.map(each => (
                            <li
                              className={
                                disabledTT.includes(each.name)
                                  ? styles.disabledItem
                                  : styles.activedItem
                              }
                              onClick={() => handlePickedTT(each.name)}
                              key={each.id}>
                              {each.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.fieldContainer3}>
                  <div className={styles.fieldContent3}>
                    <div className={styles.fieldInnerContainer3}>
                      <label htmlFor="">Transaction Channel</label>
                      <div
                        className={
                          disableInput
                            ? styles.searchResultContainerDisabled
                            : styles.searchResultContainer
                        }>
                        <SelectedCampaignItem
                          pickedItems={pickedTC}
                          handleOnFocusChange={handleOnFocusChangeTC}
                          handleOnBlurChange={handleOnBlurChangeTC}
                          searchTerm={searchTC}
                          handleInputChange={handleInputChangeTC}
                          handleRemoveBranch={handleRemoveTC}
                          disableInput={disableInput}
                        />
                      </div>
                    </div>
                    {transactionChanelVisible && (
                      <div className={styles.branchContainer}>
                        <ul className={styles.branchInnerContainer}>
                          {transactionChanelData.map(each => (
                            <li
                              className={
                                disabledTC.includes(each.name)
                                  ? styles.disabledItem
                                  : styles.activedItem
                              }
                              onClick={() => handlePickedTC(each.name)}
                              key={each.id}>
                              {each.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.fieldContainer3}>
                  <div className={styles.fieldContent3}>
                    <div className={styles.fieldInnerContainer3}>
                      <label htmlFor="">Product Group</label>
                      <div
                        className={
                          disableInput
                            ? styles.searchResultContainerDisabled
                            : styles.searchResultContainer
                        }>
                        <SelectedCampaignItem
                          pickedItems={pickedPG}
                          handleOnFocusChange={handleOnFocusChangePG}
                          handleOnBlurChange={handleOnBlurChangePG}
                          searchTerm={searchPG}
                          handleInputChange={handleInputChangePG}
                          handleRemoveBranch={handleRemovePG}
                          disableInput={disableInput}
                        />
                      </div>
                    </div>
                    {productGroupVisible && (
                      <div className={styles.branchContainer}>
                        <ul className={styles.branchInnerContainer}>
                          {productGroupData.map(each => (
                            <li
                              className={
                                disabledPG.includes(each.name)
                                  ? styles.disabledItem
                                  : styles.activedItem
                              }
                              onClick={() => handlePickedPG(each.name)}
                              key={each.id}>
                              {each.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.fieldContainer3}>
                  <div className={styles.fieldContent3}>
                    <div className={styles.fieldInnerContainer3}>
                      <label htmlFor="">Product Brand</label>
                      <div
                        className={
                          disableInput
                            ? styles.searchResultContainerDisabled
                            : styles.searchResultContainer
                        }>
                        <SelectedCampaignItem
                          pickedItems={pickedPB}
                          handleOnFocusChange={handleOnFocusChangePB}
                          handleOnBlurChange={handleOnBlurChangePB}
                          searchTerm={searchPB}
                          handleInputChange={handleInputChangePB}
                          handleRemoveBranch={handleRemovePB}
                          disableInput={disableInput}
                        />
                      </div>
                    </div>
                    {productBrandVisible && (
                      <div className={styles.branchContainer}>
                        <ul className={styles.branchInnerContainer}>
                          {productBrandData.map(each => (
                            <li
                              className={
                                disabledPB.includes(each.name)
                                  ? styles.disabledItem
                                  : styles.activedItem
                              }
                              onClick={() => handlePickedPB(each.name)}
                              key={each.id}>
                              {each.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.fieldContainer3}>
                  <div className={styles.fieldContent3}>
                    <div className={styles.fieldInnerContainer3}>
                      <label htmlFor="">Product Code</label>
                      <div
                        className={
                          disableInput
                            ? styles.searchResultContainerDisabled
                            : styles.searchResultContainer
                        }>
                        <SelectedCampaignItem
                          pickedItems={pickedPC}
                          handleOnFocusChange={handleOnFocusChangePC}
                          handleOnBlurChange={handleOnBlurChangePC}
                          searchTerm={searchPC}
                          handleInputChange={handleInputChangePC}
                          handleRemoveBranch={handleRemovePC}
                          disableInput={disableInput}
                        />
                      </div>
                    </div>
                    {productCodeVisible && (
                      <div className={styles.branchContainer}>
                        <ul className={styles.branchInnerContainer}>
                          {productCodeData.map(each => (
                            <li
                              className={
                                disabledPC.includes(each.name)
                                  ? styles.disabledItem
                                  : styles.activedItem
                              }
                              onClick={() => handlePickedPC(each.name)}
                              key={each.id}>
                              {each.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.BasicInfoContainer}>
            <div
              className={
                disableInput
                  ? styles.campaignHeader2Disabled
                  : styles.campaignHeader2
              }
              onClick={handleOpen2}>
              <p>
                Sweepstakes & Transaction<sup>*</sup>
              </p>
              {isOpen2 ? (
                <span>
                  <OpenCampaignIcon />
                </span>
              ) : (
                <span>
                  <CloseCampaignIcon />
                </span>
              )}
            </div>
            {isOpen2 && (
              <div className={styles.sweepstakesContainer}>
                <div className={styles.sweepstakesInnerContainer1}>
                  <div className={styles.ssfield1}>
                    <label htmlFor="">Sweepstake to Link</label>
                    <RadioButton
                      options={option1}
                      selectedOption={sweepstakeFlag}
                      onChange={handleSweepstakeFlag}
                    />
                    {errors?.Sweepstake_flag && (
                      <p className="text-danger">{errors?.Sweepstake_flag}</p>
                    )}
                  </div>
                  <div className={styles.ssfield1}>
                    <label htmlFor="">
                      Sweepstake<sup>*</sup>
                    </label>
                    <Dropdown
                      header={SweepStakeHeaders()}
                      api={ApiConfig.fetchActiveSweepStakes}
                      onSelect={handleSweepstakeID}
                      onSelectOption={handleSweepstakeSlug}
                      column={'slug'}
                      retrievedValue={formData.Sweepstake_id}
                      key="sweepstake"
                    />
                    {errors?.Sweepstake_id && (
                      <p className="text-danger">{errors?.Sweepstake_id}</p>
                    )}
                  </div>
                </div>

                <div className={styles.field1}>
                  <label htmlFor="">
                    No. of Sweepstake entries per Qualifying Transaction
                    <sup>*</sup>
                  </label>
                  <input
                    type="text"
                    name="Sweepstake_ticket_limit"
                    value={formData?.Sweepstake_ticket_limit}
                    onChange={handleChange}
                  />
                  {errors?.Sweepstake_ticket_limit && (
                    <p className="text-danger">
                      {errors?.Sweepstake_ticket_limit}
                    </p>
                  )}
                </div>
                <div className={styles.sweepstakesInnerContainer4}>
                  <div className={styles.ssfield4}>
                    <label htmlFor="">Transaction Amount Criteria</label>
                    <RadioButton
                      options={option1}
                      selectedOption={transactionFlag}
                      onChange={handleTransactionFlag}
                    />
                    {errors?.Transaction_amt_flag && (
                      <p className="text-danger">
                        {errors?.Transaction_amt_flag}
                      </p>
                    )}
                  </div>
                  <div className={styles.ssfield4}>
                    <label htmlFor="">
                      Transaction Amount<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      name="Transaction_amount"
                      value={formData?.Transaction_amount}
                      onChange={handleChange}
                    />
                    {errors?.Transaction_amount && (
                      <p className="text-danger">
                        {errors?.Transaction_amount}
                      </p>
                    )}
                  </div>
                  <div className={styles.ssfield4}>
                    <label htmlFor="">
                      Operator<sup>*</sup>
                    </label>
                    <Dropdown
                      header={HeadersOperator()}
                      api={ApiConfig.fetchCodeDecodeMaster}
                      onSelect={handleOperatorID}
                      onSelectOption={handleOperator}
                      column={'type_code'}
                      retrievedValue={formData?.operator}
                      key="operator"
                    />
                    {errors?.operator && (
                      <p className="text-danger">{errors?.operator}</p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.BasicInfoContainer}>
            <div
              className={
                disableInput
                  ? styles.campaignHeader2Disabled
                  : styles.campaignHeader2
              }
              onClick={handleOpen3}>
              <p>
                Rewards<sup>*</sup>
              </p>
              {isOpen3 ? (
                <span>
                  <OpenCampaignIcon />
                </span>
              ) : (
                <span>
                  <CloseCampaignIcon />
                </span>
              )}
            </div>
            {isOpen3 && (
              <div className={styles.rewardsContainer}>
                <div className={styles.sweepstakesInnerContainer1}>
                  <div className={styles.ssfield1}>
                    <label htmlFor="">Reward Criteria</label>
                    <RadioButton
                      options={option2}
                      selectedOption={rewardFlag}
                      onChange={handleRewardFlag}
                    />
                    {errors?.Reward_flag && (
                      <p className="text-danger">{errors?.Reward_flag}</p>
                    )}
                  </div>
                  <div className={styles.ssfield1}>
                    <label htmlFor="">
                      Reward<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      name={
                        rewardFlag === 'In Points'
                          ? 'Reward_points'
                          : 'Reward_percent'
                      }
                      value={
                        (rewardFlag === 'In Points'
                          ? formData.Reward_points
                          : (formData.Reward_points = '')) ||
                        (rewardFlag === 'In Percentage'
                          ? formData.Reward_percent
                          : (formData.Reward_percent = ''))
                      }
                      onChange={handleChange}
                    />
                    {errors?.Reward_points && (
                      <p className="text-danger">{errors?.Reward_points}</p>
                    )}
                    {errors?.Reward_percent && (
                      <p className="text-danger">{errors?.Reward_percent}</p>
                    )}
                  </div>
                </div>
                <div className={styles.sweepstakesInnerContainer2}>
                  <div className={styles.ssfield1}>
                    <label htmlFor="">
                      Reward Based on tier<sup>*</sup>
                    </label>
                    <RadioButton
                      options={option1}
                      selectedOption={tierFlag}
                      onChange={handleTierFlag}
                    />
                    {errors?.Tier_flag && (
                      <p className="text-danger">{errors?.Tier_flag}</p>
                    )}
                  </div>
                  <div className={styles.ssfield1}>
                    <label htmlFor="">
                      Select Tier<sup>*</sup>
                    </label>
                    <Dropdown
                      header={HeadersOperator()}
                      api={ApiConfig.fetchTier}
                      onSelect={handleTierID}
                      column={'name'}
                      retrievedValue={tier}
                      key="tier"
                      onSelectOption={handleTierName}
                    />
                    {errors?.Tier_id && (
                      <p className="text-danger">{errors?.Tier_id}</p>
                    )}
                  </div>
                </div>
                <div className={styles.sweepstakesInnerContainer3}>
                  <div className={styles.ssfield3}>
                    <label htmlFor="">
                      Reward Budget maximum number of Points<sup>*</sup>
                    </label>

                    <input
                      type="text"
                      name="Max_reward_budget"
                      value={formData?.Max_reward_budget}
                      onChange={handleChange}
                    />
                    {errors?.Max_reward_budget && (
                      <p className="text-danger">{errors?.Max_reward_budget}</p>
                    )}
                  </div>
                  <div className={styles.ssfield3}>
                    <label htmlFor="">
                      Maximum points awarded per member<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      name="Max_reward_budget_cust"
                      value={formData?.Max_reward_budget_cust}
                      onChange={handleChange}
                    />
                    {errors?.Max_reward_budget_cust && (
                      <p className="text-danger">
                        {errors?.Max_reward_budget_cust}
                      </p>
                    )}
                  </div>
                </div>

                <div className={styles.field3Container}>
                  <div className={styles.field3}>
                    <label htmlFor="">
                      Reward Amount<sup>*</sup>
                    </label>
                    <RadioButton
                      options={option3}
                      selectedOption={rewardAmountFlag}
                      onChange={handleRewardAmountFlag}
                    />
                  </div>
                  <div className={styles.field3}>
                    <label htmlFor="">Reward Criteria</label>
                    <RadioButton
                      options={option5}
                      selectedOption={rewardAmountCriteriaFlag}
                      onChange={handleRewardAmountCriteriaFlag}
                    />
                  </div>
                  <div
                    className={styles.field3}
                    style={{opacity: fix ? 1 : 0.3}}>
                    <label htmlFor="">Fixed Amount</label>
                    <input
                      type="text"
                      name="Fixed_amount"
                      value={
                        fix
                          ? formData.Fixed_amount
                          : (formData.Fixed_amount = '')
                      }
                      onChange={handleChange}
                      disabled={fix === false}
                    />
                    {errors?.Fixed_amount && (
                      <p className="text-danger">{errors?.Fixed_amount}</p>
                    )}
                  </div>
                </div>

                <div className={styles.field3Container}>
                  <div
                    className={styles.field3}
                    style={{opacity: fix ? 1 : 0.3}}>
                    <label htmlFor="">Fixed Amount Points</label>
                    <input
                      type="text"
                      name="First_iteration_percentage"
                      value={
                        fix === true
                          ? formData.First_iteration_percentage
                          : (formData.First_iteration_percentage = '')
                      }
                      disabled={!fix}
                      onChange={handleChange}
                    />
                    {errors?.First_iteration_percentage && (
                      <p className="text-danger">
                        {errors?.First_iteration_percentage}
                      </p>
                    )}
                  </div>
                  <div
                    className={styles.field3}
                    style={{opacity: discrete ? 1 : 0.3}}>
                    <label htmlFor="">Discreet Amount</label>
                    <input
                      type="text"
                      name="Discrete_amt"
                      value={
                        discrete === true
                          ? formData.Discrete_amt
                          : (formData.Discrete_amt = '')
                      }
                      disabled={!discrete}
                      onChange={handleChange}
                    />
                    {errors?.Discrete_amt && (
                      <p className="text-danger">{errors?.Discrete_amt}</p>
                    )}
                  </div>
                  <div
                    className={styles.field3}
                    style={{opacity: discrete ? 1 : 0.3}}>
                    <label htmlFor="">Discreet Points</label>
                    <input
                      type="text"
                      name="Second_iteration_percentage"
                      value={
                        discrete
                          ? formData.Second_iteration_percentage
                          : (formData.Second_iteration_percentage = '')
                      }
                      onChange={handleChange}
                      disabled={!discrete}
                    />
                    {errors?.Second_iteration_percentage && (
                      <p className="text-danger">
                        {errors?.Second_iteration_percentage}
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.option4Container}>
                  <label htmlFor="">
                    Reward Once (Customer + Account + Product)
                  </label>
                  <RadioButton
                    options={option1}
                    selectedOption={rewardOnceFlag}
                    onChange={handleRewardOnceFlag}
                  />
                </div>

                <div className={styles.sweepstakesInnerContainer2}>
                  <div className={styles.ssfield1}>
                    <label htmlFor="">Campaign Status</label>
                    <RadioButton
                      options={option4}
                      selectedOption={campaignStatus}
                      onChange={handleCampaignStatus}
                    />
                    {errors?.campaign_status && (
                      <p className="text-danger">{errors?.campaign_status}</p>
                    )}
                  </div>
                  <div className={styles.ssfield1}>
                    <label htmlFor="">
                      Campaign Description<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      name="Campaign_description"
                      value={formData.Campaign_description}
                      onChange={handleChange}
                    />
                    {errors?.Campaign_description && (
                      <p className="text-danger">
                        {errors?.Campaign_description}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className={styles.buttonContainer}>
              {isSubmit && (
                <button
                  className={styles.submit}
                  onClick={event => {
                    event.preventDefault();
                    addFixedBudgetMutation();
                  }}>
                  Submit
                </button>
              )}
              {isUpdate && (
                <button
                  className={styles.submit}
                  onClick={e => {
                    e.preventDefault();
                    updateFixedBudgetMutation();
                  }}>
                  Update
                </button>
              )}

              <button
                className={styles.reset}
                type="button"
                onClick={() => setClearModal(true)}>
                Reset
              </button>
            </div>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
      </div>
    </CallCenterStructure>
  );
};

export default CreateAndEditFixedBudgetRegularCampaign;
