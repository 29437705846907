import React, {useState} from 'react';
import styles from '../../styles/LoginTemplate1.module.scss';
import logo from '../../assets/images/av.png';
import {CloseEyeTemplate, OpenEyeTemplate} from '../../assets/icons';

const LoginTemplate1 = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.LoginTemplate}>
      <div className={styles.LoginTemplateLeft}>
        <div className={styles.welcomeTextContainer}>
          <div className={styles.welcomeTextHeader}>
            <h2>Welcome Text</h2>
          </div>
          <div className={styles.welcomeTextBody}>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ex
              provident, quas neque, eligendi architecto veritatis atque placeat
              maiores deserunt quidem, inventore praesentium. Accusantium
              reiciendis molestiae aliquid ut, mollitia esse iure? Iusto,
              debitis itaque soluta sunt quis alias qui est a harum corporis
              doloribus praesentium delectus nesciunt asperiores adipisci
              deleniti sed consequatur atque pariatur necessitatibus dignissimos
              voluptate! Neque sunt dolorem explicabo. Exercitationem quas
              provident consequuntur fuga odit non nobis iure aspernatur ipsam
              suscipit maiores eveniet eum, officia totam similique. Perferendis
            </p>
          </div>
        </div>
      </div>
      <div className={styles.LoginTemplateRight}>
        <div className={styles.logoContainer}>
          <div className={styles.logoInnerContainer}>
            <img src={logo} alt="company logo" />
          </div>
        </div>
        <div className={styles.formContainer}>
          <div className={styles.formInnerContainer}>
            <h6 className={styles.formHeader}>Login to your loyalty account</h6>
            <div className={styles.formInput1}>
              <div className={styles.formInputText}>
                <p className={styles.formInputText1}>Membership ID</p>
                <p className={styles.formInputText2}>Forgot Membership ID?</p>
              </div>
              <input
                type="text"
                placeholder="Membership ID"
                className={styles.inputField}
              />
            </div>
            <div className={styles.formInput2}>
              <div className={styles.formInputText}>
                <p className={styles.formInputText1}>Password</p>
                <p className={styles.formInputText2}>Forgot Password?</p>
              </div>
              <input
                type={isOpen ? 'text' : 'password'}
                placeholder="Password"
                className={styles.inputField}
              />
              <div className={styles.viewIcons}>
                {isOpen ? (
                  <span onClick={() => setIsOpen(false)}>
                    <OpenEyeTemplate />
                  </span>
                ) : (
                  <span onClick={() => setIsOpen(true)}>
                    <CloseEyeTemplate />
                  </span>
                )}
              </div>
            </div>
            <div className={styles.formButtonContainer}>
              <button className={styles.formButton}>Login</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginTemplate1;
