import React from 'react';
import {NotificationIcon} from '../../assets/icons';
import styles from '../../styles/ClearInputModal.module.scss';

const ClearInputModal = ({
  clearInputFn,
  clearInputModalState,
  clearModalStatus,
}) => {
  const handleClearModal = () => {
    clearInputFn();
    clearInputModalState(false);
  };

  const handleCloseModal = () => {
    clearInputModalState(false);
  };

  return (
    <>
      {clearModalStatus && (
        <div className={styles.modalContainer}>
          <div className={styles.modalContent}>
            <div className={styles.iconContainer}>
              <NotificationIcon />
            </div>
            <p className={styles.modalHeaderContainer}>
              Are you sure you want to clear you inputs
            </p>
            <div className={styles.buttonContainer}>
              <button
                type="button"
                className={styles.submit}
                onClick={handleClearModal}>
                Yes
              </button>
              <button
                type="button"
                className={styles.reset}
                onClick={handleCloseModal}>
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClearInputModal;
