import React from 'react';
import {TemplateTimes} from '../../assets/icons';
import styles from '../../styles/ForgotPasswordModal.module.scss';

const ForgotPasswordModal = ({onClose}) => {
  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <div className={styles.modalTop}>
          <p>Confirmation</p>
          <span onClick={onClose} className={styles.cancelIcon}>
            <TemplateTimes />
          </span>
        </div>
        <div className={styles.modalTextContainer}>
          <h6>Kindly check your email address for your login credentials</h6>
        </div>
        <button className={styles.closeModal} onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
