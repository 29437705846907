import React, {useState} from 'react';
import styles from '../../styles/DeliveryPriceMasterReference.module.scss';
import {ViewPen} from '../../assets/icons';
import Dropdown from '../../component/DropDown/DropDown';
import SearchInput from '../../component/SearchInput/SearchInput';

import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const DeliveryPriceMasterReference = () => {
  const [clearModal, setClearModal] = useState(false);
  const tableData = [
    {
      id: 1,
      toCountry: 'Nigeria',
      fromCountry: 'Nigeria',
      toState: 'Ondo',
      fromState: 'Ondo',
      range: '0.00 - 1.00 KG',
      price: '888.73',
    },
    {
      id: 2,
      toCountry: 'Nigeria',
      fromCountry: 'Nigeria',
      toState: 'Ondo',
      fromState: 'Ondo',
      range: '0.00 - 1.00 KG',
      price: '888.73',
    },
    {
      id: 3,
      toCountry: 'Nigeria',
      fromCountry: 'Nigeria',
      toState: 'Ondo',
      fromState: 'Ondo',
      range: '0.00 - 1.00 KG',
      price: '888.73',
    },
  ];
  return (
    <CallCenterStructure>
      <div className={styles.countryCurrencyContainer}>
        <div className={styles.headerText}>
          <p>Delivery Price Master Reference</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputFieldContainer1}>
            <div className={styles.inputFieldInnerContainer2}>
              <label htmlFor="">
                From Country<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.inputFieldInnerContainer2}>
              <label htmlFor="">
                To Country<sup>*</sup>
              </label>
              <Dropdown />
            </div>
          </div>
          <div className={styles.inputFieldContainer2}>
            <div className={styles.inputFieldInnerContainer2}>
              <label htmlFor="">
                From State<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.inputFieldInnerContainer2}>
              <label htmlFor="">
                Enter City Name<sup>*</sup>
              </label>
              <Dropdown />
            </div>
          </div>
          <div className={styles.inputFieldContainer2}>
            <div className={styles.inputFieldInnerContainer2}>
              <label htmlFor="">
                Select Weight Range<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.inputFieldInnerContainer2}>
              <label htmlFor="">
                Enter Price Per Weight<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Submit</button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>

        <div className={styles.inputContainer}>
          <div>
            <div className={styles.searchInputContainer}>
              <div className={styles.inputFieldContainer1}>
                <div className={styles.inputFieldInnerContainer2}>
                  <label htmlFor="">
                    From Country<sup>*</sup>
                  </label>
                  <Dropdown />
                </div>
                <div className={styles.inputFieldInnerContainer2}>
                  <label htmlFor="">
                    To Country<sup>*</sup>
                  </label>
                  <Dropdown />
                </div>
              </div>
              <div className={styles.inputFieldContainer2}>
                <div className={styles.inputFieldInnerContainer2}>
                  <label htmlFor="">
                    From State<sup>*</sup>
                  </label>
                  <Dropdown />
                </div>
                <div className={styles.inputFieldInnerContainer2}>
                  <label htmlFor="">
                    To State<sup>*</sup>
                  </label>
                  <Dropdown />
                </div>
              </div>
              <button className={styles.searchInputBtn}>Search</button>
            </div>
            <div className={styles.tableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Delivery Price ID</th>
                    <th>From Country</th>
                    <th>From State</th>
                    <th>To Country</th>
                    <th>To State</th>
                    <th>Weight Range</th>
                    <th>Price Per Weight</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr key={each.id}>
                      <td>{each.id}</td>
                      <td>{each.fromCountry}</td>
                      <td>{each.fromState}</td>
                      <td>{each.toCountry}</td>
                      <td>{each.toState}</td>
                      <td>{each.range}</td>
                      <td>{each.price}</td>
                      <td>
                        <span className={styles.icon}>
                          <ViewPen />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.paginationContainer}>
              <p>Pagination</p>
            </div>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
      </div>
    </CallCenterStructure>
  );
};

export default DeliveryPriceMasterReference;
