import React, {useState} from 'react';
import styles from '../../styles/SmartPhoneHomePage.module.scss';
import {DeviceFrameset, DeviceSelector} from 'react-device-frameset';
import 'react-device-frameset/styles/marvel-devices.min.css';
import 'react-device-frameset/styles/device-selector.min.css';
import {
  BottomNavCart,
  BottomNavHome,
  BottomNavProfile,
  Cart,
  Hamburger,
  ProfileAuction,
  ProfileOrder,
  ProfileStatement,
  ProfileTier,
  ProfileTransfer,
  SMCart,
  TemplateTimes,
} from '../../assets/icons';
import product1 from '../../assets/images/product1.png';
import product2 from '../../assets/images/product2.png';
import product3 from '../../assets/images/product3.png';
import product4 from '../../assets/images/product4.png';
import product5 from '../../assets/images/product5.png';

const SmartPhoneHomePage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [cartPage, setCartPage] = useState(false);
  const [homePage, setHomePage] = useState(true);
  const [profilePage, setProfilePage] = useState(false);

  const handleCartPage = () => {
    setCartPage(true);
    setHomePage(false);
    setProfilePage(false);
  };

  const handleHomePage = () => {
    setCartPage(false);
    setHomePage(true);
    setProfilePage(false);
  };

  const handleProfilePage = () => {
    setCartPage(false);
    setHomePage(false);
    setProfilePage(true);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const itemsList = [
    {img: product1, name: 'Baron Romero', point: '28,600'},
    {img: product2, name: 'Nutella', point: '28,601'},
    {img: product3, name: 'Sony AC', point: '28,602'},
    {img: product4, name: 'Washing Machine', point: '28,603'},
    {img: product5, name: 'Slippers', point: '28,604'},
    {img: product2, name: 'Nutella', point: '28,605'},
    {img: product3, name: 'Sony AC', point: '28,606'},
    {img: product4, name: 'Washing Machine', point: '28,607'},
    {img: product5, name: 'Slippers', point: '28,608'},
  ];
  const [items, setItems] = useState(itemsList);
  const deleteItem = itemId => {
    const filteredItems = items.filter(each => each.point !== itemId);
    setItems([...filteredItems]);
  };

  const profileList = [
    {name: 'Tier Status', icon: <ProfileTier />},
    {name: 'Statement', icon: <ProfileStatement />},
    {name: 'Order History', icon: <ProfileOrder />},
    ,
    {name: 'Auction Bid History', icon: <ProfileAuction />},
    ,
    {name: 'Transfer Points', icon: <ProfileTransfer />},
  ];

  return (
    <div className={styles.smartphoneHomepage}>
      <div className={styles.smartphoneContentContainer}>
        <DeviceSelector banDevices={['iPhone X', 'MacBook Pro']}>
          {props => (
            <DeviceFrameset {...props}>
              <div className={styles.smartphoneOutterNav}>
                {homePage && (
                  <div className={styles.smartphoneNav}>
                    <div className={styles.inputSearch}>
                      <input placeholder="Search for products" type="search" />
                    </div>
                    <span className={styles.hamburger} onClick={openModal}>
                      <Hamburger />
                    </span>
                  </div>
                )}
                {cartPage && (
                  <div className={styles.cartNumberContainer}>
                    <p>Cart ({items.length})</p>
                  </div>
                )}

                {profilePage && (
                  <div className={styles.cartNumberContainer}>
                    <p>ID:22314</p>
                  </div>
                )}
              </div>
              {isOpen && (
                <div className={styles.navContent}>
                  <div className={styles.modalOverlay}>
                    <div className={styles.modal}>
                      <span className={styles.closeButton} onClick={closeModal}>
                        &times;
                      </span>
                      <div className={styles.modalContent}>
                        <ul className={styles.modalNav}>
                          <li>Airtime & Bills</li>
                          <li>Movies</li>
                          <li>Vouchers</li>
                          <li>Events</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.smartphoneContentOutter}>
                {homePage && (
                  <div
                    className={`${styles.smartphoneContent} ${
                      styles[props.device.replace(/\s+/g, '')]
                    }`}>
                    {itemsList.map(each => (
                      <div
                        className={styles.smartPhoneItemCard}
                        key={each.point}>
                        <div className={styles.smartPhoneCardImg}>
                          <img src={each.img} alt={each.name} />
                        </div>
                        <p className={styles.itemName}>{each.name}</p>
                        <div className={styles.itemPoint}>
                          <p> {each.point}</p>
                          <span>Points</span>
                        </div>
                        <div className={styles.buttonContainer}>
                          <button className={styles.addToCartBTN}>
                            Add to Cart <SMCart />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {cartPage && (
                  <div>
                    {items.length === 0 && (
                      <div className={styles.emptyCart}> Cart is Empty </div>
                    )}

                    {items?.map(each => (
                      <div className={styles.cartCart}>
                        <div className={styles.cardLeft}>
                          <div className={styles.cardImg}>
                            <img src={each.img} alt={each.name} />
                          </div>
                          <div className={styles.cardDetails}>
                            <p className={styles.itemName}>{each.name}</p>
                            <p className={styles.itemPoint}>
                              {each.point} Points
                            </p>
                            <div className={styles.qty}>
                              <p>Qty: </p>
                              <input
                                type="number"
                                className={styles.numberInput}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={styles.cardRight}>
                          <span onClick={() => deleteItem(each.point)}>
                            <TemplateTimes />
                          </span>
                        </div>
                      </div>
                    ))}

                    <div className={styles.confirmCheckoutBTNContainer}>
                      <button className={styles.confirmCheckoutBTN}>
                        Confirm & Checkout
                      </button>
                    </div>
                  </div>
                )}

                {profilePage && (
                  <div>
                    <div className={styles.userPointsContainer}>
                      <div className={styles.currentPoint}>
                        <p>Total Current Balance</p>
                        <h6>289,645.03 Points</h6>
                      </div>
                      <div className={styles.gainedPoint}>
                        <p>Total Gained Points</p>
                        <h6>28,645.03 Points</h6>
                      </div>
                      <div className={styles.redeemedPoint}>
                        <p>Total Redeemed Points</p>
                        <h6>628,645.03 Points</h6>
                      </div>
                    </div>
                    <div className={styles.profileIconsContainer}>
                      {profileList.map(each => (
                        <div className={styles.profileIcons}>
                          <p>{each.name}</p>
                          {each.icon}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.navFooter}>
                <div className={styles.navInnerFooter}>
                  <span onClick={handleHomePage}>
                    <BottomNavHome homePage={homePage} />
                  </span>
                  <p className={homePage ? styles.active : styles.unactive}>
                    Home
                  </p>
                </div>
                <div className={styles.navInnerFooter}>
                  <span onClick={handleCartPage}>
                    <BottomNavCart cartPage={cartPage} />
                  </span>
                  <p className={cartPage ? styles.active : styles.unactive}>
                    Cart
                  </p>
                </div>
                <div className={styles.navInnerFooter}>
                  <span onClick={handleProfilePage}>
                    <BottomNavProfile profilePage={profilePage} />
                  </span>
                  <p className={profilePage ? styles.active : styles.unactive}>
                    Profile
                  </p>
                </div>
              </div>
            </DeviceFrameset>
          )}
        </DeviceSelector>
      </div>
    </div>
  );
};

export default SmartPhoneHomePage;
