import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import RadioButtonSingle from '../../component/RadioButtonSingle/RadioButtonSingle';
import SearchInput from '../../component/SearchInput/SearchInput';
import styles from '../../styles/CreateCatalogueMerchandizeCategory.module.scss';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import {Times, ViewPen} from '../../assets/icons';
import fcmb from '../../assets/images/fidelity.png';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const EmailMasterSetup = () => {
  const [clearModal, setClearModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleRadioButtonChange = value => {
    setSelectedOption(value);
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const onClose = () => {
    setDeleteModal(false);
  };
  const data = [
    {id: 1, name: 'Kenneth template', desc: 'Mailing purpose'},
    {id: 2, name: 'Kenneth template', desc: 'Mailing purpose'},
    {id: 3, name: 'Kenneth template', desc: 'Mailing purpose'},
  ];

  return (
    <>
      <CallCenterStructure>
        <div className="mt-5 p-4">
          <h4 className="pb-4">Email Template Master</h4>
          <main className="row p-3">
            <div className="col-8 border border-light p-3">
              <form action="">
                <div className="row">
                  <div className="form-group col-6">
                    <label htmlFor="selectTemplateName">
                      Select Template Name
                    </label>
                    <Dropdown />
                  </div>
                  <div className="form-group col-6">
                    <label htmlFor="templateName">Template Name *</label>
                    <input
                      className="w-100"
                      type="text"
                      placeholder="Template name"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="description">Enter Description *</label>
                    <textarea
                      row="3"
                      cols="5"
                      className="w-100"
                      type="text"
                      placeholder="Description">
                      {' '}
                    </textarea>
                  </div>
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="description">Enter sms content *</label>
                    <textarea
                      row="3"
                      cols="5"
                      className="w-100"
                      type="text"
                      placeholder="sms content">
                      {' '}
                    </textarea>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="selectSubject">
                      Select Variable for subject
                    </label>
                    <Dropdown />
                  </div>
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="bodyContentWidth">Body content width</label>
                    <input
                      className="w-100"
                      type="text"
                      placeholder="body content width"
                    />
                  </div>
                </div>

                <div
                  style={{background: '#81C041', height: '40px'}}
                  className="mt-3 mb-3 text-light d-flex align-items-center justify-content-center">
                  Select Pre-defined Templates
                </div>

                <div className="row">
                  <div className="form-group col-12">
                    <label htmlFor="bodyContent">Body Content</label>
                    <p>editing panels will be here</p>
                    <textarea
                      row="3"
                      cols="5"
                      className="w-100"
                      type="text"
                      placeholder="body content"></textarea>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="selectBody">Select Variable for body</label>
                    <Dropdown />
                  </div>
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="linkToPartner">
                      Link to partners branches
                    </label>
                    <div className="d-flex">
                      <RadioButtonSingle
                        name="link"
                        value="link1"
                        selected={selectedOption === 'link1'}
                        borderWidth={2}
                        borderHeight={2}
                        onChange={handleRadioButtonChange}
                      />
                      <img src={fcmb} alt="" height="30" />
                      <div className="w-25"></div>
                      <RadioButtonSingle
                        name="link"
                        value="link2"
                        selected={selectedOption === 'link2'}
                        borderWidth={2}
                        borderHeight={2}
                        onChange={handleRadioButtonChange}
                      />
                      <img src={fcmb} alt="" height="30" />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-12">
                    <label htmlFor="note">Enter note</label>
                    <textarea
                      row="3"
                      cols="5"
                      className="w-100"
                      type="text"
                      placeholder="enter note"></textarea>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="selectBody">
                      Select Variable for footer
                    </label>
                    <Dropdown />
                  </div>
                  <div className="form-group col-12 col-md-6">
                    <p>
                      <label htmlFor="unsuscribeLink">unsubscribe link</label>
                    </p>
                    {/* <input className='w-100' type="text" placeholder='body content width' /> */}
                    <div className="d-flex">
                      <RadioButtonSingle
                        name="subscribe"
                        value="subscribe1"
                        selected={selectedOption === 'subscribe1'}
                        borderWidth={2}
                        borderHeight={2}
                        onChange={handleRadioButtonChange}
                      />
                      Yes
                      <div className="w-25"></div>
                      <RadioButtonSingle
                        name="subscribe"
                        value="subscribe2"
                        selected={selectedOption === 'subscribe2'}
                        borderWidth={2}
                        borderHeight={2}
                        onChange={handleRadioButtonChange}
                      />
                      No
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-12 col-md-10">
                    <p>
                      <label htmlFor="selectFontColor">Select font color</label>
                    </p>
                    <RadioButtonSingle
                      name="fontColor"
                      value="color1"
                      selected={selectedOption === 'color1'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    <input
                      style={{}}
                      className="mr-3 border-0"
                      type="color"
                      name=""
                      id=""
                    />
                    <RadioButtonSingle
                      name="fontColor"
                      value="color2"
                      selected={selectedOption === 'color2'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    <input
                      style={{}}
                      className="mr-3 border-0"
                      type="color"
                      name=""
                      id=""
                      value="#FFFFFF"
                    />
                    <RadioButtonSingle
                      name="fontColor"
                      value="color3"
                      selected={selectedOption === 'color3'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    <input
                      style={{}}
                      className="mr-3 border-0"
                      type="color"
                      name=""
                      id=""
                      value="#ADADAD"
                    />

                    <RadioButtonSingle
                      name="fontColor"
                      value="color4"
                      selected={selectedOption === 'color4'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    <input
                      style={{}}
                      className="mr-3 border-0"
                      type="color"
                      name=""
                      id=""
                      value="#D8D8D8"
                    />

                    <RadioButtonSingle
                      name="fontColor"
                      value="color5"
                      selected={selectedOption === 'color5'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    <input
                      style={{}}
                      className="mr-3 border-0"
                      type="color"
                      name=""
                      id=""
                      value="#E9E9E9"
                    />

                    <RadioButtonSingle
                      name="fontColor"
                      value="color6"
                      selected={selectedOption === 'color6'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    <input
                      style={{}}
                      className="mr-3 border-0"
                      type="color"
                      name=""
                      id=""
                    />
                  </div>
                  <div className="form-group col-12 col-md-2">
                    <label htmlFor="moreFontColors">More Colors</label>
                    <input
                      className="w-100"
                      type="color"
                      placeholder="more colors"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-12 col-md-10">
                    <p>
                      <label htmlFor="selectFontColor">
                        Select background color
                      </label>
                    </p>
                    <RadioButtonSingle
                      name="bgColor"
                      value="bg1"
                      selected={selectedOption === 'bg1'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    <input
                      style={{}}
                      className="mr-3 border-0"
                      type="color"
                      name=""
                      id=""
                    />

                    <RadioButtonSingle
                      name="fontbg"
                      value="bg2"
                      selected={selectedOption === 'bg2'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    <input
                      style={{}}
                      className="mr-3 border-0"
                      type="color"
                      name=""
                      id=""
                      value="#CDEEBE"
                    />

                    <RadioButtonSingle
                      name="fontbg"
                      value="bg3"
                      selected={selectedOption === 'bg3'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    <input
                      style={{}}
                      className="mr-3 border-0"
                      type="color"
                      name=""
                      id=""
                      value="#FFFFFF"
                    />

                    <RadioButtonSingle
                      name="fontbg"
                      value="bg4"
                      selected={selectedOption === 'bg4'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    <input
                      style={{}}
                      className="mr-3 border-0"
                      type="color"
                      name=""
                      id=""
                      value="#CDEEBE"
                    />

                    <RadioButtonSingle
                      name="fontbg"
                      value="bg5"
                      selected={selectedOption === 'bg5'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    <input
                      style={{}}
                      className="mr-3 border-0"
                      type="color"
                      name=""
                      id=""
                      value="#FFFFFF"
                    />
                    <RadioButtonSingle
                      name="fontbg"
                      value="bg6"
                      selected={selectedOption === 'bg6'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    <input
                      style={{}}
                      className="mr-3 border-0"
                      type="color"
                      name=""
                      id=""
                      value="#CDEEBE"
                    />
                  </div>
                  <div className="form-group col-12 col-md-2">
                    <label htmlFor="moreBgbg">More colors</label>
                    <input
                      className="w-100"
                      type="color"
                      placeholder="more colors"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="selectFontSize">Select Font size</label>
                    <Dropdown />
                  </div>
                  <div className="form-group col-12 col-md-6">
                    <label htmlFor="selectFontFamily">
                      Select Font family to apply *
                    </label>
                    <Dropdown />
                  </div>
                </div>

                <div className="form-group d-flex justify-content-between d-md-flex">
                  <button className="mr-4">submit</button>
                  <button
                    className="resetButton"
                    type="button"
                    onClick={() => setClearModal(true)}>
                    reset
                  </button>
                </div>
              </form>
            </div>

            <div className="col-12 col-md-4 border border-light p-3 h-75">
              <h4>Email template preview</h4>
              <p className="mt-3">Template name:</p>
              <p className="mt-3">From:</p>
              <p className="mt-3">To:</p>
              <p className="mt-3">Body contents:</p>
              <div className="bodyContent"></div>
              <div className="footerContent"></div>
            </div>
          </main>

          <div className={styles.inputContainer}>
            <div className={styles.searchInputContainer}>
              <SearchInput />
              <button className={styles.search}>Search</button>
            </div>
            <div className={styles.tableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Email Template Id</th>
                    <th>Email Template Name</th>
                    <th>Email Template Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(each => (
                    <tr>
                      <td>{each.id}</td>
                      <td>{each.name}</td>
                      <td>{each.desc}</td>
                      <td>
                        <div className={each.iconContainer}>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>
                          <span
                            className={styles.icon}
                            onClick={() => setDeleteModal(true)}>
                            <Times />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.pagination}>
              <p>Pagination</p>
            </div>
          </div>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
          {deleteModal && <MenuDeleteModal onClose={onClose} />}
        </div>
      </CallCenterStructure>
    </>
  );
};

export default EmailMasterSetup;
