import React, {useState} from 'react';
import SearchInput from '../../component/SearchInput/SearchInput';
import {Times, ViewPen} from '../../assets/icons';
import styles from '../../styles/ApproveCompanyMerchandizeCatalogue.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';

const ApproveCompanyMerchandizeCatalogue = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const onClose = () => {
    setDeleteModal(false);
  };

  const tableData = [
    {
      id: 4,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      category: 'candy',
      itemName: 'Granny Nuts Natural Almond',

      partner: 'The Store',
      price: '139150 Points',
      validity: '2022-10-03 - 2027-10-03',
      approved: 'Yes',
    },
    {
      id: 2,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      category: 'candy',
      itemName: 'Granny Nuts Natural Almond',

      partner: 'The Store',
      price: '139150 Points',
      validity: '2022-10-03 - 2027-10-03',
      approved: 'No',
    },
    {
      id: 9,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      category: 'candy',
      itemName: 'Granny Nuts Natural Almond',

      partner: 'The Store',
      price: '139150 Points',
      validity: '2022-10-03 - 2027-10-03',
      approved: 'Yes',
    },
    {
      id: 6,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      category: 'candy',
      itemName: 'Granny Nuts Natural Almond',

      partner: 'The Store',
      price: '139150 Points',
      validity: '2022-10-03 - 2027-10-03',
      approved: 'Yes',
    },
  ];
  return (
    <div>
      {' '}
      <CallCenterStructure>
        <div className={styles.PointsForfeitureMaster}>
          <div className={styles.headerText}>
            <p>Approve Company Merchandize Catalogue</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field5}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Select Merchandize Category<sup>*</sup>
                </label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Search<sup>*</sup>
                </label>
                <div className={styles.searchContainerTop}>
                  <div className={styles.searchInnerContainerTop}>
                    <SearchInput />
                  </div>
                  <button className={styles.searchBTN}>Search</button>
                </div>
              </div>
            </div>

            <div className={styles.buttonContainer}>
              <button className={styles.submit}>Approve Selection</button>
              <button className={styles.reset}>Reset</button>
            </div>

            <div className={styles.tableSection}>
              <div className={styles.paragText}>
                <p>List of All Approved Merchandize items of the Company</p>
              </div>
              <div className={styles.searchInputContainer}>
                <div className={styles.searchInput}>
                  <SearchInput />
                </div>
                <button className={styles.searchBTN}>Search</button>
              </div>
              <div className={styles.tableContainer1}>
                <table className={styles.mainTable}>
                  <thead>
                    <tr>
                      <th>Loyalty Program</th>
                      <th>Item Code</th>
                      <th>Merchandize Category</th>
                      <th>Merchandize Item Name</th>
                      <th>Partner</th>
                      <th>Billing Price in Points</th>
                      <th>Validity</th>
                      <th>Approved</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map(each => (
                      <tr key={each.id}>
                        <td>{each.name}</td>
                        <td>{each.code}</td>
                        <td>{each.category}</td>
                        <td>{each.itemName}</td>

                        <td>{each.partner}</td>
                        <td>{each.price}</td>
                        <td>{each.validity}</td>
                        <td>{each.approved}</td>

                        <td>
                          <div className={styles.iconContainer}>
                            <span className={styles.icon}>
                              <ViewPen />
                            </span>

                            <span
                              className={styles.icon}
                              onClick={() => setDeleteModal(true)}>
                              <Times />
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className={styles.pagination}>
                <p>Pagination</p>
              </div>
            </div>
          </div>
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default ApproveCompanyMerchandizeCatalogue;
