import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/GameMasterSetup.module.scss';
// import {useQuill} from 'react-quilljs';
// import 'quill/dist/quill.snow.css';
import QuillContainer from '../../component/Quill/Quill';
import CheckBox from '../../component/CheckBox/CheckBox';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const GameMasterSetup = () => {
  const [clearModal, setClearModal] = useState(false);
  //   const {quill, quillRef} = useQuill();

  const [gameDescription, setGameDescription] = useState('');

  const handleGameDescriptionChange = html => {
    setGameDescription(html);
  };

  return (
    <CallCenterStructure>
      <div className={styles.gameMasterSetupContainer}>
        <div className={styles.headerText}>
          <p>Game Master</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <label htmlFor="">
              Enter Game Name<sup>*</sup>
            </label>
            <input type="text" />
          </div>
          <div className={styles.quillContainer}>
            <label htmlFor="">Enter Game Description/Instruction</label>
            {/* <div ref={quillRef} className={styles.quill} /> */}
            <QuillContainer
              placeholder="Enter game description"
              onChange={handleGameDescriptionChange}
            />
          </div>
          <div>
            <div className={styles.checkBoxContainer1}>
              <div className={styles.checkBoxInnerContainer1}>
                <label htmlFor="">Image Based Game</label>
                <RadioButtonBlue firstText="Yes" secondText="No" />
              </div>
              <div className={styles.checkBoxInnerContainer1}>
                <label htmlFor="">Time Based Game</label>
                <RadioButtonBlue firstText="Yes" secondText="No" />
              </div>
            </div>
            <div className={styles.checkBoxContainer2}>
              <div className={styles.checkBoxInnerContainer2}>
                <label htmlFor="">Moves Based Game</label>
                <RadioButtonBlue firstText="Yes" secondText="No" />
              </div>
              <div className={styles.checkBoxInnerContainer2}>
                <label htmlFor="">Score Based Game</label>
                <RadioButtonBlue firstText="Yes" secondText="No" />
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <button className={styles.submit}>Submit</button>
              <button
                className={styles.reset}
                type="button"
                onClick={() => setClearModal(true)}>
                Reset
              </button>
            </div>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
      </div>
    </CallCenterStructure>
  );
};

export default GameMasterSetup;
