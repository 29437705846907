import React from 'react';
import {CameraIcon} from '../../assets/icons';
import Category from '../../component/Category/Category';
import TemplateFooter from '../../component/TemplateFooter/TemplateFooter';
import TemplateNavbar from '../../component/TemplateNavbar/TemplateNavbar';
import styles from '../../styles/Movies.module.scss';
import movie1 from '../../assets/images/movie1.png';
import movie2 from '../../assets/images/movie2.png';
import movie3 from '../../assets/images/movie3.png';
import movie4 from '../../assets/images/movie4.png';
import movie5 from '../../assets/images/movie5.png';

const Movies = () => {
  const movieList = [
    {img: movie1, title: 'The Woman King'},
    {img: movie2, title: 'Thor'},

    {img: movie3, title: 'Prey of the Devil'},

    {img: movie4, title: 'Ticket to Paradise'},

    {img: movie5, title: 'Wakanda'},
  ];

  return (
    <div className={styles.MoviesPage}>
      <TemplateNavbar />
      <Category />
      <div className={styles.moviesInnerPage}>
        <div className={styles.searchField}>
          <div className={styles.searchInnerField}>
            <CameraIcon />
            <p>Search Movies</p>
          </div>
        </div>
        <div className={styles.movieHero}></div>
        <div className={styles.moviesListing}>
          <h6>Now Showing</h6>
          <div className={styles.moviesListingContainer}>
            {movieList.map(each => (
              <div key={each.img} className={styles.eachMovie}>
                <div className={styles.movieImage}>
                  <img src={each.img} alt={each.title} />
                </div>
                <p className={styles.movieTitle}>{each.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <TemplateFooter />
    </div>
  );
};

export default Movies;
