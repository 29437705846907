import React, {useEffect, useState} from 'react';
import styles from '../../styles/PrizeComponent.module.scss';
import defaultImage from '../../assets/images/defaultImage.png';
import ImageDragAndDrop from '../ImageDragAndDrop/ImageDragAndDrop';
const PrizeComponent = ({setPrizesData, index}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');

  //   useEffect(() => {
  //     const newPrize = {
  //       sweepstakeName: '', // Add the necessary input values here
  //       prizeDescription: '',
  //       numOfWinners: '',
  //       selectedImage,
  //       selectedImageName,
  //     };

  //     setPrizesData(prevPrizes => [...prevPrizes, newPrize]);

  //     // Clear the form inputs
  //     setSelectedImage(null);
  //     setSelectedImageName('');
  //   }, [selectedImage, selectedImageName, setPrizesData]);

  return (
    <div>
      <div className={styles.itemInputContainer4}>
        <div className={styles.defaultImageContainer}>
          <div className={styles.imageContainer}>
            {selectedImage ? (
              <img src={selectedImage} alt="default" />
            ) : (
              <img src={defaultImage} alt="default" />
            )}
          </div>
          <p>Image</p>
        </div>

        <div className={styles.uploadContainer}>
          <div className={styles.uploadInputContainer}>
            <label htmlFor="">
              Prize - {index + 1} Image (maximum size of 100kb)
            </label>
            <ImageDragAndDrop
              selectedImageName={selectedImageName}
              setSelectedImageName={setSelectedImageName}
              selectedImage={selectedImage}
              setSelectedImage={setSelectedImage}
            />
          </div>
        </div>
      </div>
      <div className={styles.fieldContainer2}>
        <div className={styles.innerFieldContainer1}>
          <label htmlFor="">
            Sweepstake Name<sup>*</sup>
          </label>
          <input type="text" />
        </div>
        <div className={styles.innerFieldContainer1}>
          <label htmlFor="">
            Prize - 1st Description<sup>*</sup>
          </label>
          <input type="text" />
        </div>
        <div className={styles.innerFieldContainer1}>
          <label htmlFor="">
            Number of Winners<sup>*</sup>
          </label>
          <input type="text" />
        </div>
      </div>
    </div>
  );
};

export default PrizeComponent;
