import React, {useState, useEffect} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/PartnerUserEnrollment.module.scss';
import Dropdown from '../../component/DropDown/DropDown';
//import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
//import SingleRadioButton from '../../component/SingleRadioButton/SingleRadioButton';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
import defaultImage from '../../assets/images/defaultImage.png';
import SearchInput from '../../component/SearchInput/SearchInput';
import {Times, ViewPen} from '../../assets/icons';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import { Headers } from '../../service/Essentials';
import swal from 'sweetalert';
//import { ProgramID } from '../../service/Essentials';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';
import RadioButton from '../../component/SingleRadioButton/SingleRadioButton';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const PartnerUserEnrollment = () => {
  const [clearModal, setClearModal] = useState(false);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [allowUserPin, setAllowUserPin] = useState(null);
  const [gender, setGender] = useState(null);
  const [partnerCompanyID, setPartnerCompanyID] = useState('');
  const [partnerCompany, setPartnerCompany] = useState('');
  const [partnerBranchID, setPartnerBranchID] = useState('');
  const [partnerBranch, setPartnerBranch] = useState('');
  const [currencyID, setCountryID] = useState('');
  const [regionID, setRegionID] = useState('');
  const [stateID, setStateID] = useState('');
  const [cityID, setCityID] = useState('');
  const [zoneID, setZoneID] = useState('');
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [zone, setZone] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [userGroupID, setUserGroupID] = useState('');
  const [userGroupName, setUserGroupName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [otherName, setOtherName] = useState('');
  const [address, setAddress] = useState('');
  const [loginEmailID, setLoginEmailID] = useState('');
  const [communicationEmailID, setCommunicationEmailID] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [profession, setProfession] = useState('');
  const [dob, setDOB] = useState('');
  const [anniversary, setAnniversary] = useState('');
  const [password, setPassword] = useState('');

  const handlePartnerCompanyID = id => {
    setPartnerCompanyID(id);
  };

  const handlePartnerBranchID = id => {
    setPartnerBranchID(id);
  };

  const handleCountryID = (id) => {
    setCountryID(id);
  };

  const handleRegionID = id => {
    setRegionID(id);
  };

  const handleStateID = id => {
    setStateID(id);
  };
  const handleCityID = id => {
    setCityID(id);
  };

  const handleZoneID = id => {
    setZoneID(id);
  };

  const handleUserGroupID = (id) => {
    setUserGroupID(id);
  };

  const handleUserGroupName = (name) => {
    setUserGroupName(name);
  };



  const handleCountry = (name) => {
    setCountry(name);
  };
  const handleRegion = name => {
    setRegion(name);
  };
  const handleZone = name => {
    setZone(name);
  };
  const handleState = name => {
    setState(name);
  };
  const handleCity = name => {
    setCity(name);
  };
  const handlePartnerCompany = name => {
    setPartnerCompany(name);
  };

  const handlePartnerBranch = name => {
    setPartnerBranch(name);
  };

  const handleGender = option => {
    setGender(option);
  };

  const handleAllowPin = option => {
    setAllowUserPin(option);
  };

  const onClose = () => {
    setDeleteModal(false);
  };
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState('');
  const [selectedItemID, setSelectedItemId] = useState('');
  const [error, setError] = useState();
  const [errors, setErrors] = useState(null);
  const queryClient = useQueryClient();

  const userpin_radio = ['Yes', 'No'];
  const gender_radio = ['Male', 'Female'];

  const formData = {
    partner_setup_id: partnerCompanyID,
    user_group_id: userGroupID,
    partner_branch_id: partnerBranchID,
    first_name: firstName,
    last_name: lastName,
    middle_name: otherName,
    address: address,
    mobile_no: mobileNumber,
    profession: profession,
    login_email_id: loginEmailID,
    communication_email_id: communicationEmailID,
    photograph: selectedImageName,
    DOB: dob,
    gender: gender,
    anniversary: anniversary,
    country_currency_id: currencyID,
    region_id: regionID,
    zone_id: zoneID,
    state_id: stateID,
    city_id: cityID,
    allow_user_pin: allowUserPin == 'Yes' ? 1 : 0,
    password: 'password',
  };

//RedirectToLogin();

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const fetchpartnerenrolments = async page => {
    const res = await axiosProperty.get(
      ApiConfig.fetchPartnerEnrolment + `?page=${page}`,
      {
        headers: Headers(),
      },
    );
    return res.data;
  };
  const {data, status} = useQuery(
    ['partnerenrolments', page],
    () => fetchpartnerenrolments(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = searchTerm => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data|| []); // Reset to original data if search term is empty
    } else if (data?.data?.data) {
      const filtered = data.data.filter(
        (item) =>
          item.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.middle_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.mobile_no.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredData(filtered);
    }
  };

  const addPartnerEnrolment = async () => {
    try{
        await axiosProperty.post(ApiConfig.createPartnerEnrolment, formData, 
        {
          headers:Headers(),
      }
        );
  setAddress('');
  setAllowUserPin(false);
  setAnniversary('');
  setCity('');
  setCityID('');
  setCommunicationEmailID('');
  setCountry('');
  setCountryID('');
  setDOB('');
  setFirstName('');
  setLastName('');
  setLoginEmailID('');
  setGender(false);
  setOtherName('');
  setMobileNumber('');
  setRegion('');
  setRegionID('');
  setState('');
  setStateID('');
  setPartnerBranchID('');
  setPartnerBranch('');
  setPartnerCompanyID('');
  setZoneID('');
  setZone('');
  setProfession('');
  setPassword('');
  setUserGroupID('');
  setUserGroupName('');
  setPartnerCompany('');
  setErrors(null);
  swal({
    title: "Success!",
    text: "Partner Enrolment created successfully",
    icon: "success",
  });

    }catch(err){
      if(err.response){
       setError(err.response.data.message);
       setErrors(err.response.data);
      }else{
      setError('Something went wrong, try again'); 
      }
      swal({
        title: 'Error!',
        text: error || 'server issue',
        icon: 'error',
      });
    }
  };

  const {mutate: addPartnerEnrolmentMutation} = useMutation({
    mutationFn: addPartnerEnrolment,
    onSuccess: () => {
      queryClient.invalidateQueries(['partnerenrolments']);
    },
  });

  const updatePartnerEnrolment = async () => {
    try {
      await axiosProperty.patch(
        ApiConfig.updatePartnerEnrolment + `/${updateID}`,
        formData,
        {
          headers:Headers(),
      }
        );
        setAddress('');
        setAllowUserPin(false);
        setAnniversary('');
        setCity('');
        setCityID('');
        setCommunicationEmailID('');
        setCountry('');
        setCountryID('');
        setDOB('');
        setFirstName('');
        setLastName('');
        setLoginEmailID('');
        setGender(false);
        setOtherName('');
        setMobileNumber('');
        setRegion('');
        setRegionID('');
        setState('');
        setStateID('');
        setZoneID('');
        setPartnerBranchID('');
        setPartnerBranch('');
        setPassword('');
        setProfession('');
        setPartnerCompanyID('');
        setPartnerCompany('');
        setZoneID('');
        setZone('');
        setUserGroupID('');
        setUserGroupName('');
        setIsSubmit(true);
        setIsUpdate(false);
        setErrors(null);
        swal({
          title: "Success!",
          text: "Partner Enrolment updated successfully",
          icon: "success",
        });
      
    }catch(err){
      if(err.response){
        setError(err.response.data.message);
        setErrors(err.response.data);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);

      swal({
        title: 'Error!',
        text: error,
        icon: 'error',
      });
    }
  };

  const {mutate: updatePartnerEnrolmentMutation} = useMutation({
    mutationFn: updatePartnerEnrolment,
    onSuccess: () => {
      queryClient.invalidateQueries(['partnerenrolments']);
    },
  });

  const editPartnerEnrolment = async id => {
    try {
      const res = await axiosProperty.get(
        ApiConfig.editPartnerEnrolment + `/${id}`,
        {
          headers: Headers(),
        },
      );
  setIsUpdate(true); 
  setIsSubmit(false); 
  setAddress(res.data.data.address || '');
  setAllowUserPin(res.data.data.allow_user_pin || '');
  setAnniversary(res.data.data.anniversary || '');
  setCityID(res.data.data.city_id || '');
  setCommunicationEmailID(res.data.data.communication_email_id || '');
  setCountryID(res.data.data.country_currency_id);
  setDOB(res.data.data.DOB || '');
  setFirstName(res.data.data.first_name || '');
  setLastName(res.data.data.last_name || '');
  setLoginEmailID(res.data.data.login_email_id || '');
  setGender(res.data.data.gender || false);
  setOtherName(res.data.data.middle_name || '');
  setMobileNumber(res.data.data.mobile_no || '');
  setRegionID(res.data.data.region_id);
  setStateID(res.data.data.state_id);
  setZoneID(res.data.data.zone_id);
  setCityID(res.data.data.city_id);
  setPartnerBranchID(res.data.data.partner_branch_id);
  setPartnerCompanyID(res.data.data.partner_setup_id);
  setPartnerCompany(res.data.data.name || '');
  setPartnerBranch(res.data.data.name || '');
  setRegion(res.data.data.name || '');
  setCountry(res.data.data.name || '');
  setState(res.data.data.name || '');
  setZone(res.data.data.name || '');
  setCity(res.data.data.name || '');
  setUserGroupID(res.data.data.user_group_id || '');
  setPassword(res.data.data.password || '');
  setProfession(res.data.data.profession || '');
    }catch(err){
      if(err.response){
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);
      swal({
        title: 'Warning!',
        text: error,
        icon: 'warning',
      });
    }
  };

  const deletePartnerEnrolment = async id => {
    try {
      const res = await axiosProperty.delete(
        ApiConfig.deletePartnerEnrolment + `/${id}`,
        {
          headers: Headers(),
        },
      );
      setDeleteModal(false);
      swal({
        title: 'Success!',
        text: 'Partner Enrolment deleted successfully',
        icon: 'success',
      });
      queryClient.invalidateQueries(['partnerenrolments']);
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);

      swal({
        title: 'Error!',
        text: error,
        icon: 'error',
      });
    }
  };
  return (
    <div>
      <CallCenterStructure>
        <div className={styles.PartnerUserEnrollment}>
          <div className={styles.headerText}>
            <p>Partner User Enrollment</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field5}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Partner Company<sup>*</sup>
                </label>
                <Dropdown header={Headers()} api={ApiConfig.fetchPartnermaster} onSelect={handlePartnerCompanyID} onSelectOption={handlePartnerCompany} retrievedValue={partnerCompanyID} column={"partner_name"} key="partner"/>
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Branch<sup>*</sup>
                </label>
                <Dropdown header={Headers()} api={ApiConfig.fetchPartnerbranch} onSelect={handlePartnerBranchID} onSelectOption={handlePartnerBranch} retrievedValue={partnerBranchID} column={"branch_name"} key="branch"/>
              </div>
            </div>
            <div className={styles.field1}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">First Name</label>
                <input
                  type="text"
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Middle Name<sup>*</sup>
                </label>
                <input
                  type="text"
                  value={otherName}
                  onChange={e => setOtherName(e.target.value)}
                />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Last Name<sup>*</sup>
                </label>
                <input
                  type="text"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.fieldContainer2}>
              <label htmlFor="">Address</label>
              <textarea
                value={address}
                onChange={e => setAddress(e.target.value)}
              />
            </div>
            <div className={styles.field3}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select Country<sup>*</sup>
                </label>
                <Dropdown header={Headers()} api={ApiConfig.fetchCountry} onSelect={handleCountryID} onSelectOption={handleCountry} retrievedValue={currencyID} column={"name"} key="country"/>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select Region<sup>*</sup>
                </label>
                <Dropdown header={Headers()} api={ApiConfig.fetchRegion(currencyID)} onSelect={handleRegionID} onSelectOption={handleRegion} retrievedValue={regionID} column={"name"} key="region"/>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select Zone<sup>*</sup>
                </label>
                <Dropdown header={Headers()} api={ApiConfig.fetchZone(regionID)} onSelect={handleZoneID} onSelectOption={handleZone} retrievedValue={zoneID} column={"name"} key="zone"/>
              </div>
            </div>
            <div className={styles.field4}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Select State<sup>*</sup>
                </label>
                <Dropdown header={Headers()} api={ApiConfig.fetchState(zoneID)} onSelect={handleStateID} onSelectOption={handleState} retrievedValue={stateID} column={"name"} key="state"/>
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Select City<sup>*</sup>
                </label>
                <Dropdown header={Headers()} api={ApiConfig.fetchCity(stateID)} onSelect={handleCityID} onSelectOption={handleCity} retrievedValue={cityID} column={"name"} key="city"/>
              </div>
            </div>
            <div className={styles.field3}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  User Group<sup>*</sup>
                </label>
                <Dropdown header={Headers()} api={ApiConfig.fetchUserGroup} onSelect={handleUserGroupID} onSelectOption={handleUserGroupName} retrievedValue={userGroupID} column={"user_group_name"} key="usergroup"/>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Mobile Number<sup>*</sup>
                </label>
                <input
                  type="text"
                  value={mobileNumber}
                  onChange={e => setMobileNumber(e.target.value)}
                />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Profession<sup>*</sup>
                </label>
                <input
                  type="text"
                  value={profession}
                  onChange={e => setProfession(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.field6}>
              <div className={styles.fieldContainer6}>
                <label htmlFor="">
                  Login Email Id<sup>*</sup>
                </label>
                <input
                  type="text"
                  value={loginEmailID}
                  onChange={e => setLoginEmailID(e.target.value)}
                />
              </div>
              <div className={styles.fieldContainer6}>
                <label htmlFor="">
                  Communication Email Id<sup>*</sup>
                </label>
                <input
                  type="text"
                  value={communicationEmailID}
                  onChange={e => setCommunicationEmailID(e.target.value)}
                />
              </div>
              <div className={styles.fieldContainer6}>
                <label htmlFor="">
                  Password<sup>*</sup>
                </label>
                <input
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  type="text"
                />
              </div>
              <div className={styles.fieldContainer6}>
                <label htmlFor="">Allow User Pin</label>
                <div className={styles.radioButtonInnerContainer}>
                  {/* <RadioButtonBlue firstText="Yes" secondText="No" onChange={setAllowUserPin} />         */}
                  <RadioButton
                    options={userpin_radio}
                    selectedOption={allowUserPin}
                    onChange={handleAllowPin}
                  />
                </div>
              </div>
            </div>
            <div className={styles.itemInputContainer4}>
              <div className={styles.defaultImageContainer}>
                <div className={styles.imageContainer}>
                  {selectedImage ? (
                    <img src={selectedImage} alt="default" />
                  ) : (
                    <img src={defaultImage} alt="default" />
                  )}
                </div>
                <p>Image</p>
              </div>

              <div className={styles.uploadContainer}>
                <div className={styles.uploadInputContainer}>
                  <label htmlFor="">Photograph</label>
                  <ImageDragAndDrop
                    selectedImageName={selectedImageName}
                    setSelectedImageName={setSelectedImageName}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                  />
                </div>
              </div>
            </div>
            <div className={styles.field3}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select Gender<sup>*</sup>
                </label>
                {/* <RadioButtonBlue firstText="Male" secondText="Female" onChange={setGender}/> */}
                <RadioButton
                  options={gender_radio}
                  selectedOption={gender}
                  onChange={handleGender}
                />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  DOB<sup>*</sup>
                </label>
                <input
                  type="text"
                  value={dob}
                  onChange={e => setDOB(e.target.value)}
                />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Anniversary<sup>*</sup>
                </label>
                <input
                  type="text"
                  value={anniversary}
                  onChange={e => setAnniversary(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.buttonContainer}>
              {isSubmit && (
                <button
                  className={styles.submit}
                  onClick={() => addPartnerEnrolmentMutation()}>
                  Submit
                </button>
              )}
              {isUpdate && (
                <button
                  className={styles.submit}
                  onClick={() => updatePartnerEnrolmentMutation()}>
                  Update
                </button>
              )}
              <button
                className={styles.reset}
                type="button"
                onClick={() => setClearModal(true)}>
                Reset
              </button>
            </div>
            <ErrorMessage errMsg={errors} />
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.searchInputContainer}>
              <div className={styles.searchInput}>
                <SearchInput onSearchInputChange={handleSearchInputChange} />
              </div>
              <button className={styles.searchBTN}>search</button>
            </div>
            <div className={styles.tableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Email Id</th>
                    <th>Phone No.</th>
                    <th>Address</th>
                    <th>Branch</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    filteredData.map(each => (
                      <tr key={each.id}>
                        <td>{each.partner_setup_id}</td>
                        <td>{each.login_email_id}</td>
                        <td>{each.mobile_no}</td>
                        <td>{each.address}</td>
                        <td>{each.last_name}</td>
                        <td>
                          <div className={styles.iconContainer}>
                            <span
                              className={styles.icon}
                              onClick={() => {
                                editPartnerEnrolment(each.id);
                                setUpdateID(each.id);
                              }}>
                              <ViewPen />
                            </span>
                            <span
                              className={styles.icon}
                              onClick={() => {
                                setDeleteModal(true);
                                setSelectedItemId(each.id);
                              }}>
                              <Times />
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colspan="6">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className={styles.pagination}>
              {/* <p>Pagination</p> */}
              <Paginate page={page} data={data} setPage={setPage} />
            </div>
          </div>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
          {deleteModal && (
            <MenuDeleteModal
              onClose={onClose}
              onDelete={() => deletePartnerEnrolment(selectedItemID)}
            />
          )}
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default PartnerUserEnrollment;
