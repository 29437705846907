import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/SummaryOfLicenseMembershipRegeneration.module.scss';
import Dropdown from '../../component/DropDown/DropDown';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const SummaryOfLicenseMembershipRegeneration = () => {
  const [clearModal, setClearModal] = useState(false);
  const tabelData1 = [
    {
      name: 'Fidelity Bank Plc.',
      status: 'Active',
      solutionType: 'Enterprise',
      upgradeType: 'Enterprise',
      generationType: 'Manual',
      idGeneratedNum: 1,
      limit: '2147483647',
      memberLastId: 'FBN_07650',
      totalCard: '2147483647',
      totalUsed: '1533',
    },
    {
      name: 'Fidelity Bank Plc.',
      status: 'In-Active',
      solutionType: 'Enterprise',
      upgradeType: 'Enterprise',
      generationType: 'Manual',
      idGeneratedNum: 2,
      limit: '2147483647',
      memberLastId: 'FBN_07650',
      totalCard: '2147483647',
      totalUsed: '1533',
    },
    {
      name: 'Fidelity Bank Plc.',
      status: 'Active',
      solutionType: 'Enterprise',
      upgradeType: 'Enterprise',
      generationType: 'Manual',
      idGeneratedNum: 3,
      limit: '2147483647',
      memberLastId: 'FBN_07650',
      totalCard: '2147483647',
      totalUsed: '1533',
    },
    {
      name: 'Fidelity Bank Plc.',
      status: 'In-Active',
      solutionType: 'Enterprise',
      upgradeType: 'Enterprise',
      generationType: 'Manual',
      idGeneratedNum: 4,
      limit: '2147483647',
      memberLastId: 'FBN_07650',
      totalCard: '2147483647',
      totalUsed: '1533',
    },
  ];

  const tabelData2 = [
    {
      cardLeft: '2147482114',
      expiryDate: 'September 22, 2026',
      firstLicenseIssued: 'July 25, 2022',
      totalLicenseIssued: 3,
    },
    {
      cardLeft: '2147482114',
      expiryDate: 'September 22, 2026',
      firstLicenseIssued: 'July 25, 2022',
      totalLicenseIssued: 10,
    },
    {
      cardLeft: '2147482114',
      expiryDate: 'September 22, 2026',
      firstLicenseIssued: 'July 25, 2022',
      totalLicenseIssued: 6,
    },
    {
      cardLeft: '2147482114',
      expiryDate: 'September 22, 2026',
      firstLicenseIssued: 'July 25, 2022',
      totalLicenseIssued: 4,
    },
  ];
  return (
    <CallCenterStructure>
      <div className={styles.summaryContainer}>
        <div className={styles.headerText}>
          <p>Summary Of License Membership Regeneration</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer}>
            <label htmlFor="">
              Select Company<sup>*</sup>
            </label>
            <div className={styles.fieldInnerContainer}>
              <Dropdown />
              <button className={styles.submit}>Submit</button>
              <button
                className={styles.reset}
                type="button"
                onClick={() => setClearModal(true)}>
                Reset
              </button>
            </div>
          </div>
        </div>
        {/* <th className={styles.th2}>License Related</th> */}
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <div className={styles.tableInnerContainer}>
              <div className={styles.mainTable1Container}>
                <div className={styles.mainTable1ColorHead}>
                  <p>Membership Regeneration Related</p>
                </div>
                <table className={styles.mainTable1}>
                  <thead className={styles.thead1}>
                    <tr>
                      <th>Company Name</th>
                      <th>Active /In-active</th>
                      <th>Type of Solution</th>
                      <th>Upgraded Type of Solution</th>
                      <th>Membership Generation Type</th>
                      <th>No. of Times Membership ID Generated</th>
                      <th>Limit set in last Membership ID Iteration</th>
                      <th>Last No. of Membership ID</th>
                      <th>Total Cards Issued (till date)</th>
                      <th>Total Cards Utilized (till date)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabelData1.map(each => (
                      <tr key={each.idGeneratedNum}>
                        <td>{each.name}</td>
                        <td>
                          {each.status === 'Active' ? (
                            <div className={styles.activeStatus}>
                              {each.status}
                            </div>
                          ) : (
                            <div className={styles.inactiveStatus}>
                              {each.status}
                            </div>
                          )}
                        </td>
                        <td>{each.solutionType}</td>
                        <td>{each.upgradeType}</td>
                        <td>{each.generationType}</td>
                        <td>{each.idGeneratedNum}</td>
                        <td>{each.limit}</td>
                        <td>{each.memberLastId}</td>
                        <td>{each.totalCard}</td>
                        <td>{each.totalUsed}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className={styles.mainTable2Container}>
                <div className={styles.mainTable2ColorHead}>
                  <p>License Related</p>
                </div>
                <table className={styles.mainTable2}>
                  <thead>
                    <tr>
                      <th>Total No. of Cards Left</th>
                      <th>Current License Expiry Date</th>
                      <th>Date of First License Issuance</th>
                      <th>No. of Time License has been Renewed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tabelData2.map(each => (
                      <tr key={each.totalLicenseIssued}>
                        <td>{each.cardLeft}</td>
                        <td>{each.expiryDate}</td>
                        <td>{each.firstLicenseIssued}</td>
                        <td>{each.totalLicenseIssued}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className={styles.paginationContainer}>
            <p>Pagination</p>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
      </div>
    </CallCenterStructure>
  );
};

export default SummaryOfLicenseMembershipRegeneration;
