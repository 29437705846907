import React, {useState} from 'react';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';
import Dropdown from '../../component/DropDown/DropDown';
import styles from '../../styles/RenewalOfLicense.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';

const RenewalOfLicense = () => {
  const [clearModal, setClearModal] = useState(false);
  const tabelData = [
    {id: 1, iteration: 'Iteration-1', date: 'July 24, 2025'},
    {id: 2, iteration: 'Iteration-1', date: 'July 24, 2025'},
    {id: 3, iteration: 'Iteration-1', date: 'July 24, 2025'},
    {id: 4, iteration: 'Iteration-1', date: 'July 24, 2025'},
  ];
  return (
    <CallCenterStructure>
      <div className={styles.renewalOfLicenseContainer}>
        <div className={styles.headerText}>
          <p>Renewal Of License</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputField1}>
            <div className={styles.innerInputContainer}>
              <label htmlFor="">
                Select Company<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.innerInputContainer}>
              <label htmlFor="">Company Type Of Solution</label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.inputField2}>
            <div className={styles.innerInputContainer}>
              <label htmlFor="">
                Period of License Usage<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.innerInputContainer}>
              <label htmlFor="">Company Expiration Date</label>
              <input type="date" />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Submit</button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.tableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Iterations</th>
                    <th>Expiration Date</th>
                  </tr>
                </thead>
                <tbody>
                  {tabelData.map(each => (
                    <tr>
                      <td>{each.id}</td>
                      <td>{each.iteration}</td>
                      <td>{each.date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.downloadBtn}>
              <button className={styles.PDFbtn}>Download as PDF</button>
              <button className={styles.XLSXbtn}>Download as xlsx</button>
            </div>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
      </div>
    </CallCenterStructure>
  );
};

export default RenewalOfLicense;
