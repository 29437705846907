import React from 'react';
import {useState, useEffect} from 'react';
import CheckBox from '../../component/CheckBox/CheckBox';
import Dropdown from '../../component/DropDown/DropDown';
import RadioBlueButton3 from '../../component/RadioBlueButton3/RadioBlueButton3';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import {
  Headers,
  HeadersOperator,
  upgradeHeaders,
} from '../../service/Essentials';
import swal from 'sweetalert';
import axiosProperty from '../../service/HttpConfig';
import {useMutation} from 'react-query';
import ApiConfig from '../../service/ApiConfig';
import CheckboxUpgradePrivilege from '../../component/CheckboxUpgradePrivilege/CheckboxUpgradePrivilege';
import styles from '../../styles/DiscountBenefitCampaign.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';
import {CloseCampaignIcon, OpenCampaignIcon} from '../../assets/icons';
import styles1 from '../../styles/CreateCampaign.module.scss';
import RadioButton from '../../component/SingleRadioButton/SingleRadioButton';

const DiscountBenefitCampaign = () => {
  const [clearModal, setClearModal] = useState(false);
  const [page, setPage] = useState(1);
  const [error, setError] = useState('oops... something went wrong');
  const [programID, setProgramID] = useState('');
  const [programName, setProgramName] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [tier, setTier] = useState(0);
  const [LBS, setLBS] = useState(0);
  const [transaction, setTransaction] = useState('');
  const [campaignStatus, setCampaignStatus] = useState(true);
  const [execute, setExecute] = useState('');
  const [operator, setOperator] = useState('');
  const [operatorId, setOperatorId] = useState('');
  const [allMonth, setAllMonth] = useState({
    value: '',
    checked: false,
  });
  const [jan, setJan] = useState({
    value: '',
    checked: false,
  });
  const [feb, setFeb] = useState({
    value: '',
    checked: false,
  });
  const [march, setMarch] = useState({
    value: '',
    checked: false,
  });
  const [april, setApril] = useState({
    value: '',
    checked: false,
  });
  const [may, setMay] = useState({
    value: '',
    checked: false,
  });
  const [june, setJune] = useState({
    value: '',
    checked: false,
  });
  const [july, setJuly] = useState({
    value: '',
    checked: false,
  });
  const [aug, setAug] = useState({
    value: '',
    checked: false,
  });
  const [sept, setSept] = useState({
    value: '',
    checked: false,
  });
  const [oct, setOct] = useState({
    value: '',
    checked: false,
  });
  const [nov, setNov] = useState({
    value: '',
    checked: false,
  });
  const [dec, setDec] = useState({
    value: '',
    checked: false,
  });

  const [allDays, setAllDays] = useState({
    value: '',
    checked: false,
  });
  const [mon, setMon] = useState({
    value: '',
    checked: false,
  });
  const [tue, setTue] = useState({
    value: '',
    checked: false,
  });
  const [wed, setWed] = useState({
    value: '',
    checked: false,
  });
  const [thu, setThu] = useState({
    value: '',
    checked: false,
  });
  const [fri, setFri] = useState({
    value: '',
    checked: false,
  });
  const [sat, setSat] = useState({
    value: '',
    checked: false,
  });
  const [allWeek, setAllWeek] = useState({
    value: '',
    checked: false,
  });
  const [firstWeek, setFirstWeek] = useState({
    value: '',
    checked: false,
  });
  const [secondWeek, setSecondWeek] = useState({
    value: '',
    checked: false,
  });
  const [fourthWeek, setFourthWeek] = useState({
    value: '',
    checked: false,
  });
  const [fifthWeek, setFifthWeek] = useState({
    value: '',
    checked: false,
  });
  const [thirdWeek, setThirdWeek] = useState({
    value: '',
    checked: false,
  });

  const [errors, setErrors] = useState({
    //updated data
    From_date: '',
    To_date: '',
    Campaign_name: '',
    Tier_flag: '',
    Tier_id: '',
    Transaction_amt_flag: '',
    Transaction_amount: '',
    operator: '',
    campaign_status: '',
    Company_id: '',
    Membership_id: '',
    Campaign_description: '',
    Start_time: '',
    End_time: '',
    Spend_amount: '',
    Partner_id: '',
    Benefit_description: '',
    Benefit_communication: '',
    Upgrade_privilege: '',
    Loyalty_program_id: '',
    Discount: '',
    Special_occasion_criteria: '',
    LBS_linked: '',
  });
  const [formData, setFormData] = useState({
    From_date: '',
    To_date: '',
    Campaign_name: '',
    Tier_flag: tier,
    Tier_id: '',
    Transaction_amt_flag: 'total transaction amount',
    Transaction_amount: '',
    operator: '',
    campaign_status: 'Disabled',
    Company_id: '',
    Membership_id: '',
    Campaign_description: '',
    Start_time: '',
    End_time: '',
    Spend_amount: '',
    Partner_id: '',
    Benefit_description: '',
    Benefit_communication: '',
    Upgrade_privilege: '',
    LBS_linked: '',
    Discount: '',
    Jan: '',
    Feb: '',
    Mar: '',
    Apr: '',
    May: '',
    Jun: '',
    Jul: '',
    Aug: '',
    Sep: '',
    Oct: '',
    Nov: '',
    Dec: '',
    Mon: '',
    Tue: '',
    Wed: '',
    Thu: '',
    Fri: '',
    Sat: '',
    Sun: '',
    First_week: '',
    Second_week: '',
    Third_week: '',
    Fourth_week: '',
    Fifth_week: '',
    Loyalty_program_id: '',
    Special_occasion_criteria: 'monthly',
  });

  const handleTier = value => {
    if (value) {
      setTier(1);
    } else {
      setTier(0);
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      Tier_flag: value ? 1 : 0,
    }));
  };

  const handleLBS = value => {
    if (value) {
      setLBS(1);
    } else {
      setLBS(0);
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      LBS_linked: value ? 1 : 0,
    }));
  };

  useEffect(() => {
    const getCompanyId = JSON.parse(sessionStorage.getItem('company'));
    setCompanyId(getCompanyId.id);
  }, []);

  const handleTransaction = value => {
    if (value === 'total spent') {
      setTransaction('total spent');
    } else if (value === 'transaction count') {
      setTransaction('transaction count');
    } else if (value === 'total transaction amount') {
      setTransaction('total transaction amount');
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      Transaction_amt_flag: value,
    }));
  };

  const handleCampaignStatus = value => {
    console.log(value);
    if (value) {
      setCampaignStatus('enabled');
    } else {
      setCampaignStatus('disabled');
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      campaign_status: value ? 'Enabled' : 'Disabled',
    }));
  };

  const handleExecute = value => {
    if (value === 'weekly') {
      setExecute('weekly');
    } else if (value === 'monthly') {
      setExecute('monthly');
    } else if (value === 'days') {
      setExecute('days');
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      Special_occasion_criteria: value,
    }));
  };

  const handleOperator = name => {
    setOperator(name);
    setFormData(prevFormData => ({
      ...prevFormData,
      operator: name,
    }));
  };

  const handleOperatorID = id => {
    setOperatorId(id);
  };
  //months selected
  const handleCheck = value => {
    if (value.value && value.id === 'selectall') {
      setAllMonth({
        value: 1,
        checked: true,
      });
      setJan({
        value: 1,
        checked: true,
      });
      setFeb({
        value: 1,
        checked: true,
      });
      setMarch({
        value: 1,
        checked: true,
      });
      setApril({
        value: 1,
        checked: true,
      });
      setMay({
        value: 1,
        checked: true,
      });
      setJune({
        value: 1,
        checked: true,
      });
      setJuly({
        value: 1,
        checked: true,
      });
      setAug({
        value: 1,
        checked: true,
      });
      setSept({
        value: 1,
        checked: true,
      });
      setOct({
        value: 1,
        checked: true,
      });
      setNov({
        value: 1,
        checked: true,
      });
      setDec({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Jan: value.value ? 1 : 0,
        Feb: value.value ? 1 : 0,
        Mar: value.value ? 1 : 0,
        Apr: value.value ? 1 : 0,
        May: value.value ? 1 : 0,
        Jun: value.value ? 1 : 0,
        Jul: value.value ? 1 : 0,
        Aug: value.value ? 1 : 0,
        Sep: value.value ? 1 : 0,
        Oct: value.value ? 1 : 0,
        Nov: value.value ? 1 : 0,
        Dec: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'selectall') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setJan({
        value: null,
        checked: false,
      });
      setFeb({
        value: null,
        checked: false,
      });
      setMarch({
        value: null,
        checked: false,
      });
      setApril({
        value: null,
        checked: false,
      });
      setMay({
        value: null,
        checked: false,
      });
      setJune({
        value: null,
        checked: false,
      });
      setJuly({
        value: null,
        checked: false,
      });
      setAug({
        value: null,
        checked: false,
      });
      setSept({
        value: null,
        checked: false,
      });
      setOct({
        value: null,
        checked: false,
      });
      setNov({
        value: null,
        checked: false,
      });
      setDec({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Jan: !value.value && 0,
        Feb: !value.value && 0,
        Mar: !value.value && 0,
        Apr: !value.value && 0,
        May: !value.value && 0,
        Jun: !value.value && 0,
        Jul: !value.value && 0,
        Aug: !value.value && 0,
        Sep: !value.value && 0,
        Oct: !value.value && 0,
        Nov: !value.value && 0,
        Dec: !value.value && 0,
      }));
    } else if (value.value && value.id === 'jan') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setJan({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Jan: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'jan') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setJan({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Jan: !value.value && 0,
      }));
    } else if (value.value && value.id === 'feb') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setFeb({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Feb: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'feb') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setFeb({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Feb: !value.value && 0,
      }));
    } else if (value.value && value.id === 'march') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setMarch({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Mar: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'march') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setMarch({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Mar: !value.value && 0,
      }));
    } else if (value.value && value.id === 'april') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setApril({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Apr: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'april') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setApril({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Apr: !value.value && 0,
      }));
    } else if (value.value && value.id === 'may') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setMay({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        May: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'may') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setMay({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        May: !value.value && 0,
      }));
    } else if (value.value && value.id === 'june') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setJune({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Jun: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'june') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setJune({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Jun: !value.value && 0,
      }));
    } else if (value.value && value.id === 'july') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setJuly({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Jul: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'july') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setJuly({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Jul: value.value && 0,
      }));
    } else if (value.value && value.id === 'aug') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setAug({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Aug: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'aug') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setAug({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Aug: !value.value && 0,
      }));
    } else if (value.value && value.id === 'sept') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setSept({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Sep: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'sept') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setSept({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Sep: !value.value && 0,
      }));
    } else if (value.value && value.id === 'oct') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setOct({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Oct: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'oct') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setOct({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Oct: !value.value && 0,
      }));
    } else if (value.value && value.id === 'nov') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setNov({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Nov: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'nov') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setNov({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Nov: !value.value && 0,
      }));
    } else if (value.value && value.id === 'dec') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setDec({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Dec: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'dec') {
      setAllMonth({
        value: null,
        checked: false,
      });
      setDec({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Dec: !value.value && 0,
      }));
    }
  };

  //weeks selected
  const handleCheckWeek = value => {
    if (value.value && value.id === 'allweek') {
      setAllWeek({
        value: 1,
        checked: true,
      });
      setFirstWeek({
        value: 1,
        checked: true,
      });
      setSecondWeek({
        value: 1,
        checked: true,
      });
      setThirdWeek({
        value: 1,
        checked: true,
      });
      setFourthWeek({
        value: 1,
        checked: true,
      });
      setFifthWeek({
        value: 1,
        checked: true,
      });

      setFormData(prevFormData => ({
        ...prevFormData,
        First_week: value.value ? 1 : 0,
        Second_week: value.value ? 1 : 0,
        Third_week: value.value ? 1 : 0,
        Fourth_week: value.value ? 1 : 0,
        Fifth_week: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'allweek') {
      setAllWeek({
        value: null,
        checked: false,
      });
      setFirstWeek({
        value: null,
        checked: false,
      });
      setSecondWeek({
        value: null,
        checked: false,
      });
      setThirdWeek({
        value: null,
        checked: false,
      });
      setFourthWeek({
        value: null,
        checked: false,
      });
      setFifthWeek({
        value: null,
        checked: false,
      });

      setFormData(prevFormData => ({
        ...prevFormData,
        First_week: !value.value && 0,
        Second_week: !value.value && 0,
        Third_week: !value.value && 0,
        Fourth_week: !value.value && 0,
        Fifth_week: !value.value && 0,
      }));
    } else if (value.value && value.id === 'firstweek') {
      setAllWeek({
        value: null,
        checked: false,
      });
      setFirstWeek({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        First_week: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'firstweek') {
      setAllWeek({
        value: null,
        checked: false,
      });
      setFirstWeek({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        First_week: !value.value && 0,
      }));
    } else if (value.value && value.id === 'secondweek') {
      setAllWeek({
        value: null,
        checked: false,
      });
      setSecondWeek({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Second_week: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'secondweek') {
      setAllWeek({
        value: null,
        checked: false,
      });
      setSecondWeek({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Second_week: !value.value && 0,
      }));
    } else if (value.value && value.id === 'thirdweek') {
      setAllWeek({
        value: null,
        checked: false,
      });
      setThirdWeek({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Third_week: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'thirdweek') {
      setAllWeek({
        value: null,
        checked: false,
      });
      setThirdWeek({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Third_week: !value.value && 0,
      }));
    } else if (value.value && value.id === 'fourthweek') {
      setAllWeek({
        value: null,
        checked: false,
      });
      setFourthWeek({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Fourth_week: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'fourthweek') {
      setAllWeek({
        value: null,
        checked: false,
      });
      setFourthWeek({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Fourth_week: !value.value && 0,
      }));
    } else if (value.value && value.id === 'fifthweek') {
      setAllWeek({
        value: null,
        checked: false,
      });
      setFifthWeek({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Fifth_week: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'fifthweek') {
      setAllWeek({
        value: null,
        checked: false,
      });
      setFifthWeek({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Fifth_week: !value.value && 0,
      }));
    }
  };

  //days selected
  const handleCheckDays = value => {
    if (value.value && value.id === 'alldays') {
      setAllDays({
        value: 1,
        checked: true,
      });
      setMon({
        value: 1,
        checked: true,
      });
      setTue({
        value: 1,
        checked: true,
      });
      setWed({
        value: 1,
        checked: true,
      });
      setThu({
        value: 1,
        checked: true,
      });
      setFri({
        value: 1,
        checked: true,
      });
      setSat({
        value: 1,
        checked: true,
      });

      setFormData(prevFormData => ({
        ...prevFormData,
        Mon: value.value ? 1 : 0,
        Tue: value.value ? 1 : 0,
        Wed: value.value ? 1 : 0,
        Thu: value.value ? 1 : 0,
        Fri: value.value ? 1 : 0,
        Sat: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'alldays') {
      setAllDays({
        value: null,
        checked: false,
      });
      setMon({
        value: null,
        checked: false,
      });
      setTue({
        value: null,
        checked: false,
      });
      setWed({
        value: null,
        checked: false,
      });
      setThu({
        value: null,
        checked: false,
      });
      setFri({
        value: null,
        checked: false,
      });
      setSat({
        value: null,
        checked: false,
      });

      setFormData(prevFormData => ({
        ...prevFormData,
        Mon: !value.value && 0,
        Tue: !value.value && 0,
        Wed: !value.value && 0,
        Thu: !value.value && 0,
        Fri: !value.value && 0,
        Sat: !value.value && 0,
      }));
    } else if (value.value && value.id === 'mon') {
      setAllDays({
        value: null,
        checked: false,
      });
      setMon({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Mon: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'mon') {
      setAllDays({
        value: null,
        checked: false,
      });
      setMon({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Mon: !value.value && 0,
      }));
    } else if (value.value && value.id === 'tue') {
      setAllDays({
        value: null,
        checked: false,
      });
      setTue({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Tue: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'tue') {
      setAllDays({
        value: null,
        checked: false,
      });
      setTue({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Tue: !value.value && 0,
      }));
    } else if (value.value && value.id === 'wed') {
      setAllDays({
        value: null,
        checked: false,
      });
      setWed({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Wed: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'wed') {
      setAllDays({
        value: null,
        checked: false,
      });
      setWed({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Wed: !value.value && 0,
      }));
    } else if (value.value && value.id === 'thu') {
      setAllDays({
        value: null,
        checked: false,
      });
      setThu({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Thu: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'thu') {
      setAllDays({
        value: null,
        checked: false,
      });
      setThu({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Thu: !value.value && 0,
      }));
    } else if (value.value && value.id === 'fri') {
      setAllDays({
        value: null,
        checked: false,
      });
      setFri({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Fri: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'fri') {
      setAllDays({
        value: null,
        checked: false,
      });
      setFri({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Fri: !value.value && 0,
      }));
    } else if (value.value && value.id === 'sat') {
      setAllDays({
        value: null,
        checked: false,
      });
      setSat({
        value: 1,
        checked: true,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Sat: value.value ? 1 : 0,
      }));
    } else if (!value.value && value.id === 'sat') {
      setAllDays({
        value: null,
        checked: false,
      });
      setSat({
        value: null,
        checked: false,
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        Sat: !value.value && 0,
      }));
    }
  };

  const handleChange = e => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const handleProgramID = id => {
    setProgramID(id);
  };

  const handleProgramName = name => {
    setProgramName(name);
    setFormData(prevFormData => ({
      ...prevFormData,
      program: name,
    }));
  };
  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const handleReset = () => {
    setFormData({
      From_date: '',
      To_date: '',
      Campaign_name: '',
      Tier_flag: tier,
      Tier_id: '',
      Transaction_amt_flag: '',
      Transaction_amount: '',
      operator: '',
      campaign_status: '',
      Company_id: '',
      Membership_id: '',
      Campaign_description: '',
      Start_time: '',
      End_time: '',
      Spend_amount: '',
      Partner_id: '',
      Benefit_description: '',
      Benefit_communication: '',
      Upgrade_privilege: '',
      Discount: '',
      Jan: '',
      Feb: '',
      Mar: '',
      Apr: '',
      May: '',
      Jun: '',
      Jul: '',
      Aug: '',
      Sep: '',
      Oct: '',
      Nov: '',
      Dec: '',
      Mon: '',
      Tue: '',
      Wed: '',
      Thu: '',
      Fri: '',
      Sat: '',
      Sun: '',
      First_week: '',
      Second_week: '',
      Third_week: '',
      Fourth_week: '',
      Fifth_week: '',
      Loyalty_program_id: '',
      Special_occasion_criteria: '',
    });
  };

  const discountBenefitCampaign = async () => {
    try {
      await axiosProperty.post(
        ApiConfig.createDiscountBenefit,

        setFormData({
          Company_id: companyId,
          Membership_id: '',
          Loyalty_program_id: programID,
          Tier_id: tier,
          Campaign_name: formData.Campaign_name,
          Campaign_description: formData.Campaign_description,
          From_date: formData.From_date,
          To_date: formData.To_date,
          Tier_flag: formData.Tier_flag,
          Transaction_amt_flag: transaction,
          Transaction_amount: formData?.Transaction_amount,
          operator: operator,
          campaign_status: campaignStatus,
          Jan: jan,
          Feb: feb,
          Mar: march,
          Apr: april,
          May: may,
          Jun: june,
          Jul: july,
          Aug: aug,
          Sep: sept,
          Oct: oct,
          Nov: nov,
          Dec: dec,
          Mon: mon,
          Tue: tue,
          Wed: wed,
          Thu: thu,
          Fri: fri,
          Sat: sat,
          First_week: firstWeek,
          Second_week: secondWeek,
          Third_week: thirdWeek,
          Fourth_week: fourthWeek,
          Start_time: formData.Start_time,
          End_time: formData.End_time,
          Spend_amount: formData.Spend_amount,
          Partner_id: 3,
          Benefit_description: formData.Benefit_description,
          Benefit_communication: formData.Benefit_communication,
          Upgrade_privilege: formData.Upgrade_privilege,
        }),
        {
          headers: upgradeHeaders(),
        },
      );
      // setFormData({
      //   Company_id: 1,
      //   Membership_id: 7744889000,
      //   Loyalty_program_id: 1,
      //   Tier_id: 1,
      //   Campaign_name: "Upgrade Privilege Campaign",
      //   Campaign_description: "Upgrade Privilege Description One",
      //   From_date: "2024-11-21",
      //   To_date: "2028-12-31",
      //   Tier_flag: 1,
      //   Transaction_amt_flag: 1,
      //   Transaction_amount: 5700,
      //   operator: "",
      //   campaign_status: "Enabled",
      //   Jan: 1,
      //   Feb: 0,
      //   Mar: 1,
      //   Apr: 0,
      //   May: 1,
      //   Jun: 0,
      //   Jul: 1,
      //   Aug: 1,
      //   Sep: 0,
      //   Oct: 0,
      //   Nov: 0,
      //   Dec: 1,
      //   Mon: 1,
      //   Tue: 0,
      //   Wed: 1,
      //   Thu: 0,
      //   Fri: 1,
      //   Sat: 0,
      //   Sun: 0,
      //   First_week: 0,
      //   Second_week: 0,
      //   Third_week: 1,
      //   Fourth_week: 1,
      //   Start_time: "",
      //   End_time: "",
      //   Spend_amount: 0,
      //   Partner_id: 3,
      //   Benefit_description: "This is Upgrade Privilege",
      //   Benefit_communication: "Email",
      //   Upgrade_privilege: "Godd",
      // })
      setErrors({});
      swal({
        title: 'Success!',
        text: 'Discount Benefit Campaign successfull',
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.data);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: discountBenefitCampaignMutation} = useMutation({
    mutationFn: discountBenefitCampaign,
  });
  const [disableInput, setDisableInput] = useState(false);
  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState();

  const handleOpen1 = () => {
    setIsOpen1(!isOpen1);
    setIsOpen2(false);
  };

  const handleOpen2 = () => {
    setIsOpen1(false);
    setIsOpen2(!isOpen2);
  };
  const [executiveCriteria, setExecutiveCriteria] = useState('');
  const [rewardCriteria, setRewardCriteria] = useState('');
  const [rewardCriteriaTracker, setRewardCriteriaTracker] = useState('');
  const option1 = ['Yes', 'No'];
  const option3 = ['Monthly', 'Weekly', 'Days'];

  const handleRewardCriteria = value => {
    setRewardCriteriaTracker(value);
    if (value === 'Yes') {
      setRewardCriteria(1);
    } else {
      setRewardCriteria(0);
    }
  };

  return (
    <CallCenterStructure>
      <div className={styles.discountBenefitCampaign}>
        <div className={styles.headerText}>
          <p>Create Discount Benefit Campaign</p>
        </div>

        <div className={styles.inputContainer}>
          <div className={styles1.BasicInfoContainer}>
            <div className={styles1.campaignHeader} onClick={handleOpen1}>
              <p>
                Basic Information<sup>*</sup>
              </p>
              {isOpen1 ? (
                <span>
                  <OpenCampaignIcon />
                </span>
              ) : (
                <span>
                  <CloseCampaignIcon />
                </span>
              )}
            </div>
            {isOpen1 && (
              <div className="mt-3">
                <div className={styles.fieldContainer1}>
                  <div className={styles.innerFieldContainer1}>
                    <label htmlFor="">
                      Loyalty Programme<sup>*</sup>
                    </label>
                    <Dropdown
                      header={Headers()}
                      api={ApiConfig.fetchLoyaltyProgram}
                      onSelect={handleProgramID}
                      onSelectOption={handleProgramName}
                      column={'program_name'}
                      retrievedValue={formData.program}
                      key="programme_name"
                    />
                    {errors?.Loyalty_program_id && (
                      <p className="text-danger">
                        {errors?.Loyalty_program_id}
                      </p>
                    )}
                  </div>
                  <div className={styles.innerFieldContainer1}>
                    <label htmlFor="">
                      Start Date<sup>*</sup>
                    </label>
                    <input
                      type="date"
                      name="From_date"
                      onChange={handleChange}
                      value={formData.From_date}
                    />
                    {errors?.From_date && (
                      <p className="text-danger">{errors?.From_date}</p>
                    )}
                  </div>
                </div>
                <div className={styles.fieldContainer2}>
                  <div className={styles.innerFieldContainer2}>
                    <label htmlFor="">
                      Campaign Name<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      name="Campaign_name"
                      onChange={handleChange}
                      value={formData.Campaign_name}
                    />
                    {errors?.Campaign_name && (
                      <p className="text-danger">{errors?.Campaign_name}</p>
                    )}
                  </div>
                  <div className={styles.innerFieldContainer2}>
                    <label htmlFor="">
                      End Date<sup>*</sup>
                    </label>
                    <input
                      type="date"
                      name="To_date"
                      onChange={handleChange}
                      value={formData.To_date}
                    />
                    {errors?.To_date && (
                      <p className="text-danger">{errors?.To_date}</p>
                    )}
                  </div>
                </div>
                <div className={styles.fieldContainer3}>
                  <div className={styles.innerFieldContainer3}>
                    <label htmlFor="">
                      Campaign Description<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      name="Campaign_description"
                      onChange={handleChange}
                      value={formData.Campaign_description}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles1.BasicInfoContainer}>
            <div
              className={
                disableInput
                  ? styles1.campaignHeader2Disabled
                  : styles1.campaignHeader2
              }
              onClick={handleOpen2}>
              <p>
                Sweepstakes & Transaction<sup>*</sup>
              </p>
              {isOpen2 ? (
                <span>
                  <OpenCampaignIcon />
                </span>
              ) : (
                <span>
                  <CloseCampaignIcon />
                </span>
              )}
            </div>
            {isOpen2 && (
              <div>
                <div className={styles.fieldContainer4}>
                  <div className={styles.innerFieldContainer4}>
                    <label htmlFor="">
                      Reward Based on tier<sup>*</sup>
                    </label>
                    <RadioButton
                      options={option1}
                      selectedOption={rewardCriteriaTracker}
                      onChange={handleRewardCriteria}
                    />
                    {errors?.Tier_flag && (
                      <p className="text-danger">{errors?.Tier_flag}</p>
                    )}
                  </div>
                </div>
                <div className={styles.fieldContainer5}>
                  <div className={styles.innerFieldContainer5}>
                    <label htmlFor="">
                      Transaction Criteria<sup>*</sup>
                    </label>
                    <RadioBlueButton3
                      firstText="Total Transaction Amount"
                      secondText="Transaction Count"
                      thirdText="Total Spent"
                      name="Transaction_amt_flag"
                      onchange={handleTransaction}
                      value={formData.Transaction_amt_flag}
                    />
                    {errors?.Transaction_amt_flag && (
                      <p className="text-danger">
                        {errors?.Transaction_amt_flag}
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.fieldContainer2}>
                  <div className={styles.innerFieldContainer2}>
                    <label htmlFor="">
                      Transaction Amount/Count/Spend Amount<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      name="Transaction_amount"
                      onChange={handleChange}
                      value={formData.Transaction_amount}
                    />
                    {errors?.Transaction_amount && (
                      <p className="text-danger">
                        {errors?.Transaction_amount}
                      </p>
                    )}
                  </div>
                  <div className={styles.innerFieldContainer2}>
                    <label htmlFor="">
                      Operator<sup>*</sup>
                    </label>
                    <Dropdown
                      header={HeadersOperator()}
                      api={ApiConfig.fetchCodeDecodeMaster}
                      onSelect={handleOperatorID}
                      onSelectOption={handleOperator}
                      column={'type_code'}
                      retrievedValue={formData?.operator}
                      key="type_code"
                    />
                    {errors?.operator && (
                      <p className="text-danger">{errors?.operator}</p>
                    )}
                  </div>
                </div>
                <div className={styles.fieldContainer2}>
                  <div className={styles.innerFieldContainer2}>
                    <label htmlFor="">
                      % Discount to be applied<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      name="Discount"
                      onChange={handleChange}
                      value={formData.Discount}
                    />
                    {errors?.Discount && (
                      <p className="text-danger">{errors?.Discount}</p>
                    )}
                  </div>
                  <div className={styles.innerFieldContainer2}>
                    <label htmlFor="">
                      Benefit Partner<sup>*</sup>
                    </label>
                    <Dropdown />
                  </div>
                </div>
                <div className={styles.fieldContainer6}>
                  <div className={styles.innerFieldContainer6}>
                    <label htmlFor="">
                      Benefit Description<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      name="Benefit_description"
                      onChange={handleChange}
                      value={formData.Benefit_description}
                    />
                    {errors?.Benefit_description && (
                      <p className="text-danger">
                        {errors?.Benefit_description}
                      </p>
                    )}
                  </div>
                  <div className={styles.innerFieldContainer6}>
                    <label htmlFor="">
                      Partner Subcategory<sup>*</sup>
                    </label>
                    <Dropdown />
                  </div>
                  <div className={styles.innerFieldContainer6}>
                    <label htmlFor="">
                      Benefit Communication<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      name="Benefit_communication"
                      onChange={handleChange}
                      value={formData.Benefit_communication}
                    />
                    {errors?.Benefit_communication && (
                      <p className="text-danger">
                        {errors?.Benefit_communication}
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles.fieldContainer4}>
                  <div className={styles.innerFieldContainer4}>
                    <label htmlFor="">
                      Link to LBS (Location Based Service)<sup>*</sup>
                    </label>
                    <RadioButtonBlue
                      firstText="Yes"
                      secondText="No"
                      name="LBS_linked"
                      onChange={handleLBS}
                      value={formData.LBS_linked}
                    />
                    {errors?.LBS_linked && (
                      <p className="text-danger">{errors?.LBS_linked}</p>
                    )}
                  </div>
                </div>
                <div className={styles.fieldContainer7}>
                  <div className={styles.checkbox1}>
                    <label htmlFor="">
                      Campaign Status<sup>*</sup>
                    </label>
                    <RadioButtonBlue
                      firstText="Enabled"
                      secondText="Disabled"
                      name="campaign_status"
                      onChange={handleCampaignStatus}
                      value={formData.campaign_status}
                    />
                    {errors?.campaign_status && (
                      <p className="text-danger">{errors?.campaign_status}</p>
                    )}
                  </div>
                  <div className={styles.checkbox2}>
                    <label htmlFor="">
                      Execute Criteria<sup>*</sup>
                    </label>
                    <RadioButton
                      options={option3}
                      selectedOption={executiveCriteria}
                      onChange={setExecutiveCriteria}
                    />
                    {errors?.Special_occasion_criteria && (
                      <p className="text-danger">
                        {errors?.Special_occasion_criteria}
                      </p>
                    )}
                  </div>
                </div>
                {(executiveCriteria === 'Monthly' ||
                  executiveCriteria === '') && (
                  <div className={styles.monthsContainer}>
                    <label htmlFor="">
                      Month<sup>*</sup>
                    </label>
                    <div className={styles.monthsMainContainer}>
                      <div className={styles.innerMonthsContainer}>
                        <CheckboxUpgradePrivilege
                          id="selectall"
                          name="month"
                          value={allMonth.value}
                          checked={allMonth.checked}
                          onChange={handleCheck}
                        />
                        <p className={styles.checkboxText}>Select All</p>
                      </div>
                      <div className={styles.innerMonthsContainer}>
                        <CheckboxUpgradePrivilege
                          id="jan"
                          name="jan"
                          value={jan.value}
                          checked={jan.checked}
                          onChange={handleCheck}
                        />
                        <p className={styles.checkboxText}>January</p>
                      </div>
                      <div className={styles.innerMonthsContainer}>
                        <CheckboxUpgradePrivilege
                          id="feb"
                          name="feb"
                          value={feb.value}
                          checked={feb.checked}
                          onChange={handleCheck}
                        />
                        <p className={styles.checkboxText}>February</p>
                      </div>
                      <div className={styles.innerMonthsContainer}>
                        <CheckboxUpgradePrivilege
                          id="march"
                          name="march"
                          value={march.value}
                          checked={march.checked}
                          onChange={handleCheck}
                        />
                        <p className={styles.checkboxText}>March</p>
                      </div>
                      <div className={styles.innerMonthsContainer}>
                        <CheckboxUpgradePrivilege
                          id="april"
                          name="april"
                          value={april.value}
                          checked={april.checked}
                          onChange={handleCheck}
                        />
                        <p className={styles.checkboxText}>April</p>
                      </div>
                      <div className={styles.innerMonthsContainer}>
                        <CheckboxUpgradePrivilege
                          id="may"
                          name="may"
                          value={may.value}
                          checked={may.checked}
                          onChange={handleCheck}
                        />
                        <p className={styles.checkboxText}>May</p>
                      </div>
                      <div className={styles.innerMonthsContainer}>
                        <CheckboxUpgradePrivilege
                          id="june"
                          name="june"
                          value={june.value}
                          checked={june.checked}
                          onChange={handleCheck}
                        />
                        <p className={styles.checkboxText}>June</p>
                      </div>
                      <div className={styles.innerMonthsContainer}>
                        <CheckboxUpgradePrivilege
                          id="july"
                          name="july"
                          value={july.value}
                          checked={july.checked}
                          onChange={handleCheck}
                        />
                        <p className={styles.checkboxText}>July</p>
                      </div>
                      <div className={styles.innerMonthsContainer}>
                        <CheckboxUpgradePrivilege
                          id="aug"
                          name="aug"
                          value={aug.value}
                          checked={aug.checked}
                          onChange={handleCheck}
                        />
                        <p className={styles.checkboxText}>August</p>
                      </div>
                      <div className={styles.innerMonthsContainer}>
                        <CheckboxUpgradePrivilege
                          id="sept"
                          name="sept"
                          value={sept.value}
                          checked={sept.checked}
                          onChange={handleCheck}
                        />
                        <p className={styles.checkboxText}>September</p>
                      </div>
                      <div className={styles.innerMonthsContainer}>
                        <CheckboxUpgradePrivilege
                          id="oct"
                          name="oct"
                          value={oct.value}
                          checked={oct.checked}
                          onChange={handleCheck}
                        />
                        <p className={styles.checkboxText}>October</p>
                      </div>
                      <div className={styles.innerMonthsContainer}>
                        <CheckboxUpgradePrivilege
                          id="nov"
                          name="nov"
                          value={nov.value}
                          checked={nov.checked}
                          onChange={handleCheck}
                        />
                        <p className={styles.checkboxText}>November</p>
                      </div>
                      <div className={styles.innerMonthsContainer}>
                        <CheckboxUpgradePrivilege
                          id="dec"
                          name="dec"
                          value={dec.value}
                          checked={dec.checked}
                          onChange={handleCheck}
                        />
                        <p className={styles.checkboxText}>December</p>
                      </div>
                    </div>
                  </div>
                )}
                {(executiveCriteria === 'Weekly' ||
                  executiveCriteria === '') && (
                  <div className={styles.weeksContainer}>
                    <label htmlFor="">
                      Weeks <sup>*</sup>
                    </label>
                    <div className={styles.mainWeeksContainer}>
                      <div className={styles.innerMonthsContainer}>
                        <CheckboxUpgradePrivilege
                          id="allweek"
                          name="allweek"
                          value={allWeek.value}
                          checked={allWeek.checked}
                          onChange={handleCheckWeek}
                        />
                        <p className={styles.checkboxText}>Select All</p>
                      </div>
                      <div className={styles.innerMonthsContainer}>
                        <CheckboxUpgradePrivilege
                          id="firstweek"
                          name="First_week"
                          value={firstWeek.value}
                          checked={firstWeek.checked}
                          onChange={handleCheckWeek}
                        />
                        <p className={styles.checkboxText}>First</p>
                      </div>
                      <div className={styles.innerMonthsContainer}>
                        <CheckboxUpgradePrivilege
                          id="secondweek"
                          name="Second_week"
                          value={secondWeek.value}
                          checked={secondWeek.checked}
                          onChange={handleCheckWeek}
                        />
                        <p className={styles.checkboxText}>Second</p>
                      </div>
                      <div className={styles.innerMonthsContainer}>
                        <CheckboxUpgradePrivilege
                          id="thirdweek"
                          name="Third_week"
                          value={thirdWeek.value}
                          checked={thirdWeek.checked}
                          onChange={handleCheckWeek}
                        />
                        <p className={styles.checkboxText}>Third</p>
                      </div>
                      <div className={styles.innerMonthsContainer}>
                        <CheckboxUpgradePrivilege
                          id="fourthweek"
                          name="Fourth_week"
                          value={fourthWeek.value}
                          checked={fourthWeek.checked}
                          onChange={handleCheckWeek}
                        />
                        <p className={styles.checkboxText}>Fourth</p>
                      </div>
                      <div className={styles.innerMonthsContainer}>
                        <CheckboxUpgradePrivilege
                          id="fifthweek"
                          name="Fifth_week"
                          value={fifthWeek.value}
                          checked={fifthWeek.checked}
                          onChange={handleCheckWeek}
                        />
                        <p className={styles.checkboxText}>Fifth</p>
                      </div>
                    </div>
                  </div>
                )}
                {(executiveCriteria === 'Days' || executiveCriteria === '') && (
                  <div className={styles.daysContainer}>
                    <label htmlFor="">
                      Days<sup>*</sup>
                    </label>
                    <div className={styles.mainDaysContainer}>
                      <div className={styles.innerDaysContainer}>
                        <CheckboxUpgradePrivilege
                          id="alldays"
                          name="alldays"
                          value={allDays.value}
                          checked={allDays.checked}
                          onChange={handleCheckDays}
                        />
                        <p className={styles.checkboxText}>Select All</p>
                      </div>
                      <div className={styles.innerDaysContainer}>
                        <CheckboxUpgradePrivilege
                          id="mon"
                          name="Mon"
                          value={mon.value}
                          checked={mon.checked}
                          onChange={handleCheckDays}
                        />
                        <p className={styles.checkboxText}>Monday</p>
                      </div>
                      <div className={styles.innerDaysContainer}>
                        <CheckboxUpgradePrivilege
                          id="tue"
                          name="Tue"
                          value={tue.value}
                          checked={tue.checked}
                          onChange={handleCheckDays}
                        />
                        <p className={styles.checkboxText}>Tuesday</p>
                      </div>
                      <div className={styles.innerDaysContainer}>
                        <CheckboxUpgradePrivilege
                          id="wed"
                          name="Wed"
                          value={wed.value}
                          checked={wed.checked}
                          onChange={handleCheckDays}
                        />
                        <p className={styles.checkboxText}>Wednesday</p>
                      </div>
                      <div className={styles.innerDaysContainer}>
                        <CheckboxUpgradePrivilege
                          id="thu"
                          name="Thu"
                          value={thu.value}
                          checked={thu.checked}
                          onChange={handleCheckDays}
                        />
                        <p className={styles.checkboxText}>Thursday</p>
                      </div>
                      <div className={styles.innerDaysContainer}>
                        <CheckboxUpgradePrivilege
                          id="fri"
                          name="Fri"
                          value={fri.value}
                          checked={fri.checked}
                          onChange={handleCheckDays}
                        />
                        <p className={styles.checkboxText}>Friday</p>
                      </div>
                      <div className={styles.innerDaysContainer}>
                        <CheckboxUpgradePrivilege
                          id="sat"
                          name="Sat"
                          value={sat.value}
                          checked={sat.checked}
                          onChange={handleCheckDays}
                        />
                        <p className={styles.checkboxText}>Saturday</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <button
              className={styles.submit}
              onClick={e => {
                e.preventDefault();
                discountBenefitCampaignMutation();
              }}>
              Submit
            </button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
      </div>
    </CallCenterStructure>
  );
};

export default DiscountBenefitCampaign;
