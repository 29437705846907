import React from 'react';
import Category from '../../component/Category/Category';
import TemplateFooter from '../../component/TemplateFooter/TemplateFooter';
import TemplateNavbar from '../../component/TemplateNavbar/TemplateNavbar';
import styles from '../../styles/OrderReceipt.module.scss';
import product1 from '../../assets/images/product1.png';
import product2 from '../../assets/images/product2.png';
import product3 from '../../assets/images/product3.png';
import product4 from '../../assets/images/product4.png';

const OrderReceipt = () => {
  const cartItems = [
    {
      img: product1,
      name: 'Giusepi Luigi Eau de Spirit',
      point: '1,200',
      id: 1,
    },
    {
      img: product4,
      name: 'Giusepi Luigi Eau de Spirit',
      point: '28,000',
      id: 2,
    },
    {
      img: product1,
      name: 'Nutella Hazelnut Spread with Cocoa - 30ghfgh hsjffehj',
      point: '30,000',
      id: 3,
    },
    {
      img: product1,
      name: 'Giusepi Luigi Eau de Spirit',
      point: '1,200',
      id: 4,
    },
    {
      img: product4,
      name: 'Giusepi Luigi Eau de Spirit',
      point: '28,000',
      id: 5,
    },
    {
      img: product2,
      name: 'Nutella Hazelnut Spread with Cocoa - 30g',
      point: '30,000',
      id: 6,
    },
  ];

  return (
    <div className={styles.orderReceiptContainer}>
      <TemplateNavbar />
      <Category />
      <div className={styles.orderReceiptContent}>
        <div className={styles.leftContent}>
          <div className={styles.topLeftContent}>
            <h2 className={styles.appreciationText}>
              Thank You For Your Order
            </h2>
            <p className={styles.emailText}>
              We’ve sent an email to example@email.com with your order
              confirmation and receipt
            </p>
            <p className={styles.receiptDownload}>
              If you are unable to access your email,{' '}
              <a href=""> kindly download this receipt.</a>
            </p>
            <p className={styles.orderNumber}>Order #46756</p>
            <div className={styles.receiptDetail}>
              <p>
                Estimated Delivery by <span> 12 December 2022</span>
              </p>
            </div>
            <div className={styles.receiptDetail}>
              <p>
                Items Total: <span>2,674 Points</span>
              </p>
            </div>
            <div className={styles.receiptDetail}>
              <p>
                Delivery Fee: <span> 300 Points</span>
              </p>
            </div>
          </div>
          <div className={styles.itemsNumber}>
            <p>2 Items</p>
          </div>
          <div className={styles.itemContainer}>
            <div className={styles.innerItemContainer}>
              <div className={styles.cardCartOutter}>
                {cartItems?.map(each => (
                  <div className={styles.cartCart}>
                    <div className={styles.cardLeft}>
                      <div className={styles.cardImg}>
                        <img src={each.img} alt={each.name} />
                      </div>
                      <div className={styles.cardDetails}>
                        <p className={styles.itemName}>{each.name}</p>
                        <p className={styles.itemPoint}>{each.point} Points</p>
                        <div className={styles.qty}>
                          <p>Qty: </p>
                          <input type="number" className={styles.numberInput} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.policyContainer}>
            <a href="">Returns Policy</a>
            <a className={styles.myAccount} href="">
              My Account
            </a>
            <div className={styles.shoppingBTNContainer}>
              <button className={styles.shoppingBTN}>Continue Shopping</button>
            </div>
          </div>
        </div>
        <div className={styles.rightContent}>
          <div className={styles.advert}>
            <h2>Grocery Perfumes drinks food airtime</h2>
            <p>and more</p>
            <a href="">Shop</a>
          </div>
        </div>
      </div>
      <div className={styles.appreciationTextContainer}>
        <p>Thank you for shopping</p>
      </div>
      <TemplateFooter />
    </div>
  );
};

export default OrderReceipt;
