import React, {useState, useEffect} from 'react';
import Dropdown from '../../component/DropDown/DropDown';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/CompanySolutionTypeLogo.module.scss';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import {Mark, Times, ViewPen} from '../../assets/icons';
import defaultImage from '../../assets/images/defaultImage.png';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
import { useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import { Headers} from '../../service/Essentials';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';

const CompanySolutionTypeLogo = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  //   const [selectedImagePath, setSelectedImagePath] = useState('');
  const [selectedItemID, setSelectedItemId] = useState('');
  const onClose = () => {
    setDeleteModal(false);
  };
  
const queryClient = useQueryClient();

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const fetchSolutionLogoTypes = async (page) =>{
    const res = await axiosProperty.get(ApiConfig.fetchCompanyLogoType+`?page=${page}`, {
        headers:Headers()
    }
    );
    return res.data;
  }  

  const {data, status} = useQuery(['solutionlogotypes', page], () => fetchSolutionLogoTypes(page), {keepPreviousData:true,});

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data) {
      const filtered = data.data.filter(
        (item) =>
          item.solution_type.toLowerCase().includes(searchTerm.toLowerCase())||
          item.logo.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  const [optionID, setOptionID] = useState('');
  const [option1, setDropdown1] = useState('');
  const [successModal, setSuccessModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState();
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState(null);


  const handleDropdownChange = (id) => {
    setOptionID(id);
  };

  const handleDropdown1 = (name) => {
    setDropdown1(name);
  };
 
  const addSolutionLogoType = async () => {
    try{
        await axiosProperty.post(ApiConfig.createCompanyLogoType, {solution_type:option1, logo:selectedImage}, 
        {
          headers:Headers(),
      }
        );
       // setSuccessModal(true);
       setErrors(null);
        setSelectedImage(null);
        setSelectedImageName('');
        setDropdown1('');
        swal({
          title: "Success!",
          text: "Solution type logo created successfully",
          icon: "success",
        });
    }catch(err){
      if(err.response){
       setError(err.response.data.message);
       setErrors(err.response.data);
      }else{
      setError('Something went wrong, try again'); 
      }
      console.log(err);
      swal({
        title: "Error!",
        text: error,
        icon: "error",
      });
    }
  };

const {mutate:addSolutionLogoTypeMutation} = useMutation(
  {
    mutationFn: addSolutionLogoType,
    onSuccess:()=>{queryClient.invalidateQueries(['solutionlogotypes'])}, 
  }
  );

  const updateSolutionLogoType = async () => {
    try{
        await axiosProperty.patch(ApiConfig.updateCompanyLogoType+`/${updateID}`, {solution_type: option1, logo:selectedImage},
        {
          headers:Headers(),
      }
        );
        //setSuccessModal(true);
        setErrors(null);
        setDropdown1(''); 
        setSelectedImage(null);
        setSelectedImageName('');  
        setIsSubmit(true);
        setIsUpdate(false);
        swal({
          title: "Success!",
          text: "Solution type logo updated successfully",
          icon: "success",
        });
    }catch(err){
      if(err.response){
        setError(err.response.data.message);
        setErrors(err.response.data);
      }else{
       setError('Something went wrong, try again'); 
      }
      console.log(err);
      swal({
        title: "Error!",
        text: error,
        icon: "error",
      });
    }
  };

  const {mutate:updateSolutionTypeMutation} = useMutation(
    {
      mutationFn: updateSolutionLogoType,
      onSuccess:()=>{queryClient.invalidateQueries(['solutionlogotypes'])}, 
    }
    );

  const editSolutionLogoType = async (id) => {
       const res = await axiosProperty.get(ApiConfig.editCompanyLogoType+`/${id}`,
        {
          headers:Headers(),
      }
      );
      setIsUpdate(true); 
      setIsSubmit(false); 
      setDropdown1(res.data.data.solution_type);
      //setSelectedImage(res.data.data.logo);
  };

  const deleteSolutionLogoType = async (id) => {
     const res = await axiosProperty.delete(ApiConfig.deleteCompanyLogoType+`/${id}`,
      {
        headers:Headers(),
    }
    );
   // setSuccessModal(true);
    setDeleteModal(false); 
    queryClient.invalidateQueries(['solutionlogotypes']);
    swal({
      title: "Success!",
      text: "Solution type logo deleted successfully",
      icon: "success",
    });
};

  return (
    <CallCenterStructure>
      <div className={styles.transactionTypeContainer}>
        <div>
          <h6 className={styles.headerText}>Company Solution Type Logo</h6>
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.defaultImageContainer}>
            <div className={styles.imageContainer}>
              {selectedImage ? (
                <img src={selectedImage} alt="default" />
              ) : (
                <img src={defaultImage} alt="default" />
              )}
            </div>
            <p>Logo</p>
          </div>

          <div className={styles.formContainer}>
            <div className={styles.masterMenuInput}>
              <label htmlFor="" className={styles.transactionTypeLabel}>
                Solution Type<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchCompanyTypes} onSelect={handleDropdownChange} onSelectOption={handleDropdown1} retrievedValue={option1} column={'solution'} key="solutiontype"/>
            </div>
          </div>

          <div className={styles.uploadContainer}>
            <div className={styles.buttonAndUplaodContainer}>
              <div className={styles.uploadInputContainer}>
                <label htmlFor="">Upload</label>
                <ImageDragAndDrop
                  selectedImageName={selectedImageName}
                  setSelectedImageName={setSelectedImageName}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                  //   selectedImagePath={selectedImagePath}
                  //   setSelectedImagePath={setSelectedImagePath}
                />
              </div>
              {isSubmit && (<div className={styles.submitImageBTN}>
                <button onClick={()=>addSolutionLogoTypeMutation()}>Submit</button>
              </div>)}
              {isUpdate && (<div className={styles.submitImageBTN}>
                <button onClick={()=>updateSolutionTypeMutation()}>Update</button>
              </div>)}
            </div>
            <div className={styles.noteText}>
              <p>* Note:Click on column header for Ascending/Descending</p>
            </div>
          </div>
        </div>
        <div className={styles.tableContainer}>
          <ErrorMessage errMsg={errors} />
          <div className={styles.tableContainer}>
            <table className={styles.dataTable}>
              <thead>
                <tr>
                  <th>Solution Type</th>
                  <th>Logo</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {
                status === 'error' && (<tr><td className='text-danger'>Error Occured</td></tr>)
              }

              {
                status === 'loading' && (<tr><td className='text-info'>loading</td></tr>)
              }

                {status === 'success' && ( filteredData.map(each => (
                  <tr key={each.id}>
                    <td>{each.solution_type}</td>
                    <td>{each.logo}</td>
                    <td>
                      <span className={styles.icon} onClick={()=>{editSolutionLogoType(each.id); setUpdateID(each.id)}}>
                        <ViewPen />
                      </span>
                      <span
                        className={styles.icon}
                        onClick={() => {setDeleteModal(true); setSelectedItemId(each.id);}}>
                        <Times />
                      </span>
                    </td>
                  </tr>
                )))}

              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={data} setPage={setPage} />
          </div>
        </div>
        {successModal && (
          <div className={styles.successModal}>
            <Mark />
            <p className={styles.successText}>
              Solution Logo Type Inserted Successfully!
            </p>
            <span onClick={() => setSuccessModal(false)}>
              <Times />
            </span>
          </div>
        )}

        {deleteModal && <MenuDeleteModal onClose={onClose} onDelete={()=>deleteSolutionLogoType(selectedItemID)}/>}
      </div>
    </CallCenterStructure>
  );
};

export default CompanySolutionTypeLogo;
