import React, {useEffect, useState} from 'react';
import {AddFamilyPlusIcon, RemoveIcon} from '../../assets/icons';
import AddFamilyMemberForm from '../../component/AddFamilyMemberForm/AddFamilyMemberForm';
import Dropdown from '../../component/DropDown/DropDown';
import styles from '../../styles/AddFamilyMemberAtBranchForm.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import { useLocation } from "react-router-dom";
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import { CustomerEnrollmentHeaders } from '../../service/Essentials';
import { useQuery, useQueryClient, useMutation} from 'react-query';
import swal from 'sweetalert';

const AddFamilyMemberAtBranchForm = () => {
  const [familyLists, setFamilyLists] = useState([]);
  const [addFamilyMember, setAddFamilyMember] = useState([]);
  const [familyNumberText, setFamilyNumberText] = useState([]);
  const [familyMemberData, setFamilyMemberData] = useState([]);

  const location = useLocation();

  const data = location.state.data;

  const queryClient = useQueryClient();

  // console.log(location.state);
  //console.log(data);

  const handleAddFamilyMember = () => {
    setAddFamilyMember(prevForms => [
      ...prevForms,
      <AddFamilyMemberForm
        key={prevForms.length}
        index={prevForms.length}
        onDelete={handleDeleteMemberForm}
        onInputChange={handleInputChange}
        data={familyMemberData}
        familyNumberText={familyNumberText}
      />,
    ]);
  };

  // Function to delete a family member form by index
  const handleDeleteMemberForm = deleteIndex => {
    setAddFamilyMember(prevForms =>
      prevForms.filter((form, index) => index !== deleteIndex),
    );
  };

  // Function to handle input changes and update the data
  const handleInputChange = (index, name, value) => {
    setFamilyMemberData(prevData => ({
      ...prevData,
      [`${name}-${index}`]: value,
    }));
  };

  const fetchFamilyLists = async () =>{
    const res = await axiosProperty.get(ApiConfig.fetchFamilyMembers(data.Enrollment_id), {
      headers:CustomerEnrollmentHeaders(),
    }
    );
    return res.data;
  }  
  const {data:familyData, status} = useQuery(['familylists'], () => fetchFamilyLists(), {keepPreviousData:true});

  useEffect(() => {
    setFamilyLists(familyData?.data || []);
  }, [familyData]);

  const handleSubmit = async () => {
   // Collect the form data and format it as required
    // const requestData = {
    //   lsl_enrollment_master2:addFamilyMember.map((element) => ({
    //     First_name: element,
    //     Middle_name: element.Middle_name,
    //     Last_name: element.Last_name,
    //     User_Email_id: element.User_Email_id,
    //     Phone_no: element.Phone_no,
    //     Family_redeem_limit: element.Family_redeem_limit,
    //   })),
    // };

    const formData = addFamilyMember.map((element, index) => ({
      First_name: familyMemberData[`First_name-${index}`] || '',
      Middle_name: familyMemberData[`Middle_name-${index}`] || '',
      Last_name: familyMemberData[`Last_name-${index}`] || '',
      User_Email_id: familyMemberData[`User_Email_id-${index}`] || '',
      Phone_no: familyMemberData[`Phone_no-${index}`] || '',
      Family_redeem_limit: familyMemberData[`Family_redeem_limit-${index}`] || '',
    }));

    const requestData = {
      lsl_enrollment_master2: formData,
    };

    // Send the data to the server
    try{
      const res = await axiosProperty.post(ApiConfig.addFamilyMember(data.Enrollment_id), requestData, 
      {
        headers:CustomerEnrollmentHeaders(),
    }
      );
      if (
        res.data.status == 'Failed!') {
        swal({
          title: 'Error occurred!',
          text: 'required inputs are needed',
          icon: 'error',
        });
        return;
      }
      if (
        res.data.message == 'You Have Added Four (4) Family members And Have Reached Your Limit') {
        swal({
          title: 'Warning!',
          text: res.data.message,
          icon: 'warning',
        });
        return;
      }
      if (
        res.data.message == 'Phone Number Already Exists') {
        swal({
          title: 'Warning!',
          text: res.data.message,
          icon: 'warning',
        });
        return;
      }

      if (
        res.data.message == 'Email Already Exists') {
        swal({
          title: 'Warning!',
          text: res.data.message,
          icon: 'warning',
        });
        return;
      }
      setAddFamilyMember([]);
      swal({
        title: "Success!",
        text: "Family Member added successfully",
        icon: "success",
      });

  }catch(err){
    if(err.response){
      swal({
        title: "Error!",
        text: "Required fields must be filled",
        icon: "error",
      });
    }else{
      swal({
        title: "warning!",
        text: 'Network Connectivity issue',
        icon: "error",
      });
    }
    console.log(err);
  }
  };


  const {mutate:handleSubmitMutation} = useMutation(
    {
      mutationFn: handleSubmit,
      onSuccess:()=>{queryClient.invalidateQueries(['familylists'])}, 
    }
    );

  /* The `useEffect` hook is used to perform side effects in functional components. In this case, the
 `useEffect` hook is used to update the `familyNumberText` state based on the length of the
 `addFamilyMember` array. */

  useEffect(() => {
    if (addFamilyMember.length === 0) {
      setFamilyNumberText(['first']);
    } else if (addFamilyMember.length === 1) {
      setFamilyNumberText(['first', 'second']);
    } else if (addFamilyMember.length === 2) {
      setFamilyNumberText(['first', 'second', 'third']);
    } else {
      setFamilyNumberText(['first', 'second', 'third', 'fourth']);
    }
  }, [addFamilyMember]);

  //console.log(familyNumberText, 'family number Text');

  return (
    <CallCenterStructure>
      <div className={styles.addFamilyMemberAtBranch}>
        <div className={styles.headerText}>
          <p>Add Family Member at Branch</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.memberDetailsContainer}>
            <div className={styles.memberName}>
              <p>
                {data.First_name} <span>{data.Last_name}</span>, Membership Id - {data.Membership_id}, Current
                Balance - {data.Current_balance}
              </p>
            </div>
            <div className={styles.detailContainer}>
              <div className={styles.otherInfoContainer1}>
                <div className={styles.otherInfo1}>
                  <p className={styles.textTitle}>Name</p>
                  <h6 className={styles.textValue}>{data.First_name + ' ' + data.Last_name}</h6>
                </div>
                <div className={styles.otherInfo1}>
                  <p className={styles.textTitle}>Email ID</p>
                  <h6 className={styles.textValue}>{data.User_Email_id}</h6>
                </div>
                <div className={styles.otherInfo1}>
                  <p className={styles.textTitle}>Phone Number</p>
                  <h6 className={styles.textValue}>{data.Phone_no}</h6>
                </div>
              </div>
              <div className={styles.otherInfoContainer2}>
                <div className={styles.otherInfo1}>
                  <p className={styles.textTitle}>Points Balance</p>
                  <h6 className={styles.textValue}>28,000</h6>
                </div>
                <div className={styles.otherInfo1}>
                  <p className={styles.textTitle}>Enrolment Date</p>
                  <h6 className={styles.textValue}>{data.Enroll_date}</h6>
                </div>
                <div className={styles.otherInfo1}>
                  <p className={styles.textTitle}>Birthday</p>
                  <h6 className={styles.textValue}>{data.Birth_date}</h6>
                </div>
              </div>
              <div className={styles.otherInfoContainer2}>
                <div className={styles.otherInfo1}>
                  <p className={styles.textTitle}>Membership Status</p>
                  <h6 className={styles.textValue}>Active</h6>
                </div>
                <div className={styles.otherInfo1}>
                  <p className={styles.textTitle}>Membership Tier</p>
                  <h6 className={styles.textValue}>Silver</h6>
                </div>
                <div className={styles.otherInfo1}>
                  <p className={styles.textTitle}>Receive commmunication?</p>
                  <h6 className={styles.textValue}>Yes</h6>
                </div>
              </div>
              <div className={styles.otherInfoContainer2}>
                <div className={styles.otherInfo1}>
                  <p className={styles.textTitle}>Communication Type</p>
                  <h6 className={styles.textValue}>Email</h6>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.infoContainer}>
            <p>You can add only 4 members</p>
          </div>
          <div className={styles.familyMemberForm}>
            {addFamilyMember}
            <div className={styles.addMoreBTNContainer}>
              {addFamilyMember?.length <= 3 && (
                <div
                  className={styles.addMoreBTN}
                  onClick={handleAddFamilyMember}>
                  <AddFamilyPlusIcon />
                  {addFamilyMember?.length === 0 ? (
                    <p>Add Family Member</p>
                  ) : (
                    <p> Add Another Family Member</p>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.submit}  onClick={()=>handleSubmitMutation()}>Submit</button>
            <button className={styles.reset}>Reset</button>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Middle Name</th>
                  <th>Last Name</th>
                  <th>Membership ID</th>
                  <th>Email ID</th>
                  <th>Phone Number</th>
                  <th>Redemption Limit</th>
                  <th>Member Added From</th>
                </tr>
              </thead>
              <tbody>
                {familyLists.map(each => (
                  <tr key={each.membershipId}>
                    <td>{each.First_name}</td>
                    <td>{each.Middle_name}</td>
                    <td>{each.Last_name}</td>
                    <td>{each.Membership_id}</td>
                    <td>{each.User_Email_id}</td>
                    <td>{each.Phone_no}</td>
                    <td>{each.Family_redeem_limit}</td>
                    <td>{each?.Added_by}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </CallCenterStructure>
  );
};

export default AddFamilyMemberAtBranchForm;
