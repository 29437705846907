import React from 'react';
import styles from '../../styles/ViewOrEditPartnerUser.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';

const ViewOrEditPartnerUser = () => {
  return (
    <div>
      <CallCenterStructure>
        <div className={styles.viewOrEditPartnerUser}>
          <div className={styles.headerText}>
            <p>View or Edit Partner User</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.fieldContainer}>
              <p>
                Enter User Name / Phone no / Email ID.<sup>*</sup>
              </p>
              <p>(Phone No: Do not proceed with Country code or '0')</p>
              <div className={styles.field1}>
                <input type="text" />
                <button className={styles.nextBTN}>Next</button>
              </div>
            </div>
          </div>
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default ViewOrEditPartnerUser;
