import React, {useState} from 'react';
import {Times, ViewPen} from '../../assets/icons';
import Dropdown from '../../component/DropDown/DropDown';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import SearchInput from '../../component/SearchInput/SearchInput';
import styles from '../../styles/ReportAutomationMaster.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import RadioButton from '../../component/SingleRadioButton/SingleRadioButton';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const ReportAutomationMaster = () => {
  const [clearModal, setClearModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  const [selectedOption4, setSelectedOption4] = useState(null);

  const handleOptionChange = option => {
    setSelectedOption(option);
  };
  const handleOptionChange1 = option => {
    setSelectedOption1(option);
  };
  const handleOptionChange2 = option => {
    setSelectedOption2(option);
  };
  const handleOptionChange3 = option => {
    setSelectedOption3(option);
  };

  const handleOptionChange4 = option => {
    setSelectedOption4(option);
  };
  const options = ['Daily', 'Weekly', 'Monthly'];
  const options1 = ['CC', 'BCC'];

  const onClose = () => {
    setDeleteModal(false);
  };

  const tableData = [
    {
      id: 4,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Granny Nuts Natural Almond',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 3,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Granny Nuts Natural Almond',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 9,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Granny Nuts Natural Almond',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 6,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Granny Nuts Natural Almond',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    ,
  ];
  return (
    <div>
      <CallCenterStructure>
        <div className={styles.ReportAutomationMaster}>
          <div className={styles.headerText}>
            <p>Report Automation Master</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field1}>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Select Report<sup>*</sup>
                </label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor="">Report Schedule</label>

                <RadioButton
                  options={options}
                  selectedOption={selectedOption}
                  onChange={handleOptionChange}
                />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Select LSL Contact Person<sup>*</sup>
                </label>
                <Dropdown />
              </div>
            </div>
          </div>
          <div className={styles.inputContainer}>
            <h4 className={styles.sectionHeader}>
              Select User Type<sup>*</sup>
            </h4>
            <div className={styles.field1}>
              <div className={styles.fieldContainer}>
                <label htmlFor=""></label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor=""></label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor=""></label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor=""></label>
                <Dropdown />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor=""></label>
                <Dropdown />
              </div>
            </div>
          </div>
          <div className={styles.inputContainer}>
            <h4 className={styles.sectionHeader}>
              Select User Group<sup>*</sup>
            </h4>
            <div className={styles.field1}>
              <div className={styles.fieldContainer}>
                <label htmlFor=""></label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor=""></label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor=""></label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor=""></label>
                <Dropdown />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor=""></label>
                <Dropdown />
              </div>
            </div>
          </div>
          <div className={styles.inputContainer}>
            <h4 className={styles.sectionHeader}>
              Select User Name<sup>*</sup>
            </h4>
            <div className={styles.field1}>
              <div className={styles.fieldContainer}>
                <label htmlFor=""></label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor=""></label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor=""></label>
                <Dropdown />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor=""></label>
                <Dropdown />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor=""></label>
                <Dropdown />
              </div>
            </div>
          </div>
          <div className={styles.inputContainer}>
            <h4 className={styles.sectionHeader}>
              Primary Recipient<sup>*</sup>
            </h4>
            <div className={styles.field1}>
              <div className={styles.fieldContainer}>
                <RadioButton
                  options={options1}
                  selectedOption={selectedOption1}
                  onChange={handleOptionChange1}
                />
              </div>
              <div className={styles.fieldContainer}>
                <RadioButton
                  options={options1}
                  selectedOption={selectedOption2}
                  onChange={handleOptionChange2}
                />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <RadioButton
                  options={options1}
                  selectedOption={selectedOption3}
                  onChange={handleOptionChange3}
                />
              </div>
              <div className={styles.fieldContainer}>
                <RadioButton
                  options={options1}
                  selectedOption={selectedOption4}
                  onChange={handleOptionChange4}
                />
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Submit</button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
          <div className={styles.tableSection}>
            <div className={styles.searchInputContainer}>
              <div className={styles.searchInput}>
                <SearchInput />
              </div>
              <button className={styles.searchBTN}>Search</button>
            </div>
            <div className={styles.innerTableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Report Name</th>
                    <th>LSl Contact Person</th>
                    <th>User Name</th>
                    <th>Report Schedule</th>
                    <th>Receipients</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr key={each.id}>
                      <td>{each.name}</td>
                      <td>{each.code}</td>
                      <td>{each.itemName}</td>
                      <td>{each.groupName}</td>
                      <td>{each.brandName}</td>

                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>
                          <span
                            className={styles.icon}
                            onClick={() => setDeleteModal(true)}>
                            <Times />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default ReportAutomationMaster;
