import React, {useState, useEffect} from 'react';
import styles from '../../styles/CallCenterQuerySetup.module.scss';
import Dropdown from '../../component/DropDown/DropDown';
import SearchInput from '../../component/SearchInput/SearchInput';
import {Times, ViewPen} from '../../assets/icons';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import swal from 'sweetalert';
import { CallCenterHeaders, ProgramID } from '../../service/Essentials';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
//import Paginate from '../../component/Paginate/Paginate';
//import { getUser } from '../../service/common';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CallCenterQuerySetup = () => {
 
  const program_id = ProgramID();

  const dataString = sessionStorage.getItem('profile');
  if (dataString) {
    var profile = JSON.parse(dataString);
  } else {
    profile = {};
  }

  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState({
    Company_id: '',
    Membership_id: '',
    Query_type_id: '',
    Sub_query: '',
    Query_remarks: '',
  });
  const membership_id = 7744889018; //profile?.Membership_id;
  const [queryTypeID, setQueryTypeID] = useState('');
  const [queryType, setQueryType] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItemID, setSelectedItemId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [clearModal,setClearModal] = useState(false)

  const [formData, setFormData] = useState({
    Company_id: program_id,
    Membership_id: membership_id,
    Query_type_id: '',
    Sub_query: '',
    Query_remarks: '',
  });

  console.log(formData);

  const handleQueryType = name => {
    setQueryType(name);
  };

  const handleQueryTypeID = id => {
    setQueryTypeID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Query_type_id: id,
    }));
  };

  const fetchSubQueryTypes = async page => {
    const res = await axiosProperty.get(
      ApiConfig.fetchSubQueryTypes(7744889018),
      {
        headers: CallCenterHeaders(),
      },
    );
    return res.data;
  };

  const {data, status} = useQuery(
    ['subquerytypes', page],
    () => fetchSubQueryTypes(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = searchTerm => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data) {
      const filtered = data.data.filter(item =>
        item.Sub_query.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredData(filtered);
    }
  };
  const handleChange = e => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const addSubQueryType = async () => {
    //e.preventDefault();
    //setData(prev => [...prev, {id: 4, name: text}]);
    // return
    try {
      const res = await axiosProperty.post(
        ApiConfig.createSubQueryType,
        formData,
        {
          headers: CallCenterHeaders(),
        },
      );
      if (res.status === 200) {
        setFormData({
          Company_id: program_id,
          Membership_id: membership_id,
          Query_type_id: '',
          Sub_query: '',
          Query_remarks: '',
        });
        setErrors({});
        setQueryType('');
        setQueryTypeID('');
        swal({
          title: 'Success!',
          text: 'Sub Query added successfully',
          icon: 'success',
        });
      } else {
        setError('Something went wrong with the request');
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      }
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: addSubQueryTypeMutation} = useMutation({
    mutationFn: addSubQueryType,
    onSuccess: () => {
      queryClient.invalidateQueries(['subquerytypes']);
    },
  });

  const updateSubQueryType = async () => {
    try {
      const res = await axiosProperty.put(
        ApiConfig.updateSubQueryType(updateID),
        formData,
        {
          headers: CallCenterHeaders(),
        },
      );

      if (res.status === 200) {
        setIsSubmit(true);
        setIsUpdate(false);
        setQueryType('');
        setQueryTypeID('');
        setFormData({
          Company_id: program_id,
          Membership_id: membership_id,
          Query_type_id: '',
          Sub_query: '',
          Query_remarks: '',
        });
        setErrors({});
        swal({
          title: 'Success!',
          text: 'Sub Query Type updated successfully',
          icon: 'success',
        });
      } else {
        setError('Something went wrong with the request');
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      }
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, try again');
        swal({
          title: 'Warning!',
          text: error,
          icon: 'warning',
        });
      }
    }
  };

  const {mutate: updateSubQueryTypeMutation} = useMutation({
    mutationFn: updateSubQueryType,
    onSuccess: () => {
      queryClient.invalidateQueries(['subquerytypes']);
    },
  });

  const editSubQueryType = async id => {
    try {
      const res = await axiosProperty.get(ApiConfig.editSubQueryType(id), {
        headers: CallCenterHeaders(),
      });
      setIsUpdate(true);
      setIsSubmit(false);
      setFormData(prevFormData => ({
        ...prevFormData,
        Company_id: program_id,
        Membership_id: membership_id,
        Sub_query: res.data.data.Sub_query,
        Query_type_id: res.data.data.Query_type_id,
        Query_remarks: res.data.data.Query_remarks,
      }));
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      swal({
        title: 'Warning!',
        text: error,
        icon: 'warning',
      });
    }
  };

  const deleteSubQueryType = async id => {
    try {
      const res = await axiosProperty.delete(ApiConfig.deleteSubQueryType(id), {
        headers: CallCenterHeaders(),
      });
      //setSuccessModal(true);
      setDeleteModal(false);

      swal({
        title: 'Success!',
        text: 'Delete was successful',
        icon: 'success',
      });
      queryClient.invalidateQueries(['subquerytypes']);
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);
      swal({
        title: 'Warning!',
        text: error,
        icon: 'warning',
      });
    }
  };

  const onClose = () => {
    setDeleteModal(false);
  };

  return (
    <div>
      <CallCenterStructure>
        <div className={styles.CallCenterQueryType}>
          <div className={styles.headerText}>
            <p>Call Center Sub Query Setup</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field1}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Sub Query Type<sup>*</sup>
                </label>
                <input
                  value={formData.Sub_query}
                  type="text"
                  name="Sub_query"
                  id=""
                  onChange={handleChange}
                />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select Query Type<sup>*</sup>
                </label>
                <Dropdown
                  header={CallCenterHeaders()}
                  api={ApiConfig.fetchQueryTypes(7744889018)}
                  onSelect={handleQueryTypeID}
                  onSelectOption={handleQueryType}
                  retrievedValue={formData.Query_type_id}
                  column={'Query_type_name'}
                  idValue={'Query_type_id'}
                  key="query_types"
                />
              </div>
            </div>

            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Enter Remarks<sup>*</sup>
                </label>
                <textarea
                  value={formData.Query_remarks}
                  name="Query_remarks"
                  id=""
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className={styles.buttonContainer}>
              {isSubmit && (
                <button
                  className={styles.submit}
                  onClick={() => addSubQueryTypeMutation()}>
                  Submit
                </button>
              )}
              {isUpdate && (
                <button
                  className={styles.submit}
                  onClick={() => updateSubQueryTypeMutation()}>
                  Update
                </button>
              )}
              <button
                className={styles.reset}
                type="button"
                onClick={() => setClearModal(true)}>
                Reset
              </button>
            </div>
          </div>
          <div className={styles.tableSection}>
            <div className={styles.searchInputContainer}>
              <div className={styles.searchInput}>
                <SearchInput onSearchInputChange={handleSearchInputChange} />
              </div>
              <button className={styles.searchBTN}>Search</button>
            </div>
            <div className={styles.innerTableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Query Type</th>
                    <th>Sub Query</th>
                    <th>Remarks</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map(each => (
                    <tr key={each.Query_type_id}>
                      <td>{each?.Query_type_name}</td>
                      <td>{each?.Sub_query}</td>
                      <td>{each?.Query_remarks}</td>

                      <td>
                        <div className={styles.iconContainer}>
                          <span
                            className={styles.icon}
                            onClick={() => {
                              setDeleteModal(true);
                              setSelectedItemId(each.Query_id);
                            }}>
                            <Times />
                          </span>
                          <span
                            className={styles.icon}
                            onClick={() => {
                              editSubQueryType(each.Query_id);
                              setUpdateID(each.Query_id);
                            }}>
                            <ViewPen />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            // clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
          {deleteModal && (
            <MenuDeleteModal
              onClose={onClose}
              onDelete={() => deleteSubQueryType(selectedItemID)}
            />
          )}
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default CallCenterQuerySetup;
