import React, {useState, useEffect} from 'react';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import styles from '../../styles/CallCenterEscalationMatrix.module.scss';
import Dropdown from '../../component/DropDown/DropDown';
import SearchInput from '../../component/SearchInput/SearchInput';
import {Times, ViewPen} from '../../assets/icons';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import {getUser} from '../../service/common';
import swal from 'sweetalert';
import Paginate from '../../component/Paginate/Paginate';
//import { CallCenterHeaders } from '../../service/Essentials';
import {Headers, CallCenterHeaders} from '../../service/Essentials';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';
const CallCenterEscalationMatrix = () => {
  const user = getUser();
  const [clearModal, setClearModal] = useState(false);

  //const program_id = user.user_group_id == 1 ? 2 : user.user_group_id;
  const program_id = user.user_group_id;

  const dataString = sessionStorage.getItem('profile');
  if (dataString) {
    var profile = JSON.parse(dataString);
  } else {
    profile = {};
  }
  const membership_id = 7744889000; //profile?.Membership_id;
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState({
    Company_id: '',
    Membership_id: '',
    User_type_id: '',
    User_group_id: '',
    No_of_unresolved_days: '',
  });
  const [userTypeID, setUserTypeID] = useState('');
  const [userType, setUserType] = useState();
  const [userGroupID, setUserGroupID] = useState('');
  const [userGroup, setUserGroup] = useState();
  const [userNameID, setUserNameID] = useState('');
  const [userName, setUserName] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItemID, setSelectedItemId] = useState('');

  const [formData, setFormData] = useState({
    Company_id: program_id,
    Membership_id: membership_id,
    User_type_id: '',
    User_group_id: '',
    No_of_unresolved_days: '',
  });
  console.log(formData);
  const handleUserType = name => {
    setUserType(name);
  };

  const handleUserTypeID = id => {
    setUserTypeID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      User_type_id: id,
    }));
  };

  const handleUserGroup = name => {
    setUserGroup(name);
  };

  const handleUserGroupID = id => {
    setUserGroupID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      User_group_id: id,
    }));
  };

  const handleUserName = name => {
    setUserName(name);
  };

  const handleUserNameID = id => {
    setUserNameID(id);
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   User_name_id: id
    // }));
  };

  const handleSearchInputChange = searchTerm => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data) {
      const filtered = data?.data.filter(item =>
        item.Level_name.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredData(filtered);
    }
  };

  const fetchEscalationMatrix = async page => {
    const res = await axiosProperty.get(ApiConfig.fetchEscalationMatrix, {
      headers: CallCenterHeaders(),
    });
    return res.data;
  };

  const {data, status} = useQuery(
    ['EscalationMatrix', page],
    () => fetchEscalationMatrix(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  //console.log(filteredData);
  const handleChange = e => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const addEscalationMatrix = async () => {
    try {
      await axiosProperty.post(ApiConfig.createEscalationMatrix, formData, {
        headers: CallCenterHeaders(),
      });
      setFormData({
        Company_id: program_id,
        Membership_id: membership_id,
        User_type_id: '',
        User_group_id: '',
        No_of_unresolved_days: '',
      });
      setErrors({});
      setUserGroupID('');
      setUserGroup('');
      setUserType('');
      setUserTypeID('');
      setUserName('');
      setUserNameID('');
      swal({
        title: 'Success!',
        text: 'Excalation Matrix added successfully',
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: addEscalationMatrixMutation} = useMutation({
    mutationFn: addEscalationMatrix,
    onSuccess: () => {
      queryClient.invalidateQueries(['EscalationMatrix']);
    },
  });

  const updateEscalationMatrix = async () => {
    try {
      await axiosProperty.put(
        ApiConfig.updateEscalationMatrix(updateID),
        formData,
        {
          headers: CallCenterHeaders(),
        },
      );
      setIsSubmit(true);
      setIsUpdate(false);
      setFormData({
        Company_id: program_id,
        Membership_id: membership_id,
        User_type_id: '',
        User_group_id: '',
        No_of_unresolved_days: '',
      });
      setErrors({});
      setUserGroupID('');
      setUserGroup('');
      setUserType('');
      setUserTypeID('');
      setUserName('');
      setUserNameID('');
      swal({
        title: 'Success!',
        text: 'Excalation Matrix updated successfully',
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, try again');
        swal({
          title: 'Warning!',
          text: error,
          icon: 'warning',
        });
      }
    }
  };

  const {mutate: updateEscalationMatrixMutation} = useMutation({
    mutationFn: updateEscalationMatrix,
    onSuccess: () => {
      queryClient.invalidateQueries(['EscalationMatrix']);
    },
  });

  const editEscalationMatrix = async id => {
    const res = await axiosProperty.get(ApiConfig.editEscalationMatrix(id), {
      headers: CallCenterHeaders(),
    });
    setIsUpdate(true);
    setIsSubmit(false);
    setFormData(prevFormData => ({
      ...prevFormData,
      Company_id: program_id,
      User_type_id: res.data.data[0].User_type_id,
      User_group_id: res.data.data[0].User_group_id,
      No_of_unresolved_days: res.data.data[0].No_of_unresolved_days,
    }));
  };

  const deleteEscalationMatrix = async id => {
    try {
      const res = await axiosProperty.delete(
        ApiConfig.deleteEscalationMatrix(id),
        {
          headers: CallCenterHeaders(),
        },
      );
      //setSuccessModal(true);
      setDeleteModal(false);
      swal({
        title: 'Success!',
        text: 'Delete was successful',
        icon: 'success',
      });
      queryClient.invalidateQueries('EscalationMatrix');
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);
      swal({
        title: 'Warning!',
        text: error,
        icon: 'warning',
      });
    }
  };
  const onClose = () => {
    setDeleteModal(false);
  };

  return (
    <div>
      <CallCenterStructure>
        <div className={styles.CallCenterQueryType}>
          <div className={styles.headerText}>
            <p>Call Center Escalation Matrix</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field1}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Enter No of Unresolved Days<sup>*</sup>
                </label>
                <input
                  onChange={handleChange}
                  value={formData.No_of_unresolved_days}
                  type="text"
                  name="No_of_unresolved_days"
                  id=""
                />
                {errors?.No_of_unresolved_days && (
                  <p className="text-danger">{errors?.No_of_unresolved_days}</p>
                )}
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select User Type<sup>*</sup>
                </label>
                <Dropdown
                  header={Headers()}
                  api={ApiConfig.fetchUserType}
                  onSelect={handleUserTypeID}
                  onSelectOption={handleUserType}
                  retrievedValue={formData.User_type_id}
                  column={'name'}
                  key="usertypes"
                />
                {errors?.User_type_id && (
                  <p className="text-danger">{errors?.User_type_id}</p>
                )}
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select User Group<sup>*</sup>
                </label>
                <Dropdown
                  onSelect={handleUserGroupID}
                  onSelectOption={handleUserGroup}
                  retrievedValue={formData.User_group_id}
                  column={'name'}
                  key="usergroups"
                />
                {errors?.User_group_id && (
                  <p className="text-danger">{errors?.User_group_id}</p>
                )}
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select User Name<sup>*</sup>
                </label>
                <Dropdown
                  onSelect={handleUserNameID}
                  onSelectOption={handleUserName}
                  retrievedValue={userNameID}
                  column={'name'}
                  key="username"
                />
              </div>
            </div>

            <div className={styles.buttonContainer}>
              {isSubmit && (
                <button
                  className={styles.submit}
                  onClick={() => addEscalationMatrixMutation()}>
                  Submit
                </button>
              )}
              {isUpdate && (
                <button
                  className={styles.submit}
                  onClick={() => updateEscalationMatrixMutation()}>
                  Update
                </button>
              )}
              <button
                className={styles.reset}
                type="button"
                onClick={() => setClearModal(true)}>
                Reset
              </button>
            </div>
          </div>
          <div className={styles.tableSection}>
            {/* <div className={styles.searchInputContainer}>
              <div className={styles.searchInput}>
                <SearchInput />
              </div>
              <button className={styles.searchBTN}>Search</button>
            </div> */}
            <div className={styles.innerTableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>No. of Unresolved Days</th>
                    <th>User Type</th>
                    <th>User Group</th>
                    <th>User Name</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.map(each => (
                    <tr key={each.Escalation_matrix_id}>
                      <td>{each?.No_of_unresolved_days}</td>
                      <td>{each?.User_type_id}</td>
                      <td>{each?.User_group_id}</td>
                      <td>{each?.UserName}</td>

                      <td>
                        <div className={styles.iconContainer}>
                          <span
                            className={styles.icon}
                            onClick={() => {
                              setDeleteModal(true);
                              setSelectedItemId(each.Escalation_matrix_id);
                            }}>
                            <Times />
                          </span>
                          <span
                            className={styles.icon}
                            onClick={() => {
                              editEscalationMatrix(each.Escalation_matrix_id);
                              setUpdateID(each.Escalation_matrix_id);
                            }}>
                            <ViewPen />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            // clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
          {deleteModal && (
            <MenuDeleteModal
              onClose={onClose}
              onDelete={() => deleteEscalationMatrix(selectedItemID)}
            />
          )}
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default CallCenterEscalationMatrix;
