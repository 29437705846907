import React, {useState, useEffect} from 'react';
import Dropdown from '../../component/DropDown/DropDown';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
import styles from '../../styles/CompanyUserEnrollment.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import defaultImage from '../../assets/images/defaultImage.png';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import CheckBox from '../../component/CheckBox/CheckBox';
import {Times, ViewPen} from '../../assets/icons';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import { Headers } from '../../service/Essentials';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CompanyUserEnrollment = () => {
  const [clearModal, setClearModal] = useState(false);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [gender, setGender] = useState('Male');
  const [userTypeID, setUserTypeID] = useState('');
  const [userType, setUserType] = useState('');
  const [userGroupID, setUserGroupID] = useState('');
  const [userGroup, setUserGroup] = useState('');
  const [companyBranchID, setCompanyBranchID] = useState('');
  const [companyBranch, setCompanyBranch] = useState('');
  const [currencyID, setCountryID] = useState(1);
  const [regionID, setRegionID] = useState('');
  const [stateID, setStateID] = useState('');
  const [cityID, setCityID] = useState('');
  const [zoneID, setZoneID] = useState('');
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [zone, setZone] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [otherName, setOtherName] = useState('');
  const [address, setAddress] = useState('');
  const [loginEmailID, setLoginEmailID] = useState('');
  const [communicationEmailID, setCommunicationEmailID] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [profession, setProfession] = useState('');
  const [startSeriesNo, setStartSeriesNo] = useState('');
  const [dob, setDOB] = useState('');
  const [anniversary, setAnniversary] = useState('');
  const [password, setPassword] = useState('');

  const [linkedCompanys_loyalty, setLinkedCompanys_loyalty] = useState(false);
  const [linkedCompanys_firstbank, setLinkedCompanys_firstbank] =
    useState(false);
  const [linkedCompanys_fidelity, setLinkedCompanys_fidelity] = useState(false);
  const [linkedCompanys_gradsquare, setLinkedCompanys_gradsquare] =
    useState(false);
  const [linkedCompanys_uba, setLinkedCompanys_uba] = useState(false);

  const [linkedPrograms_perx, setLinkedPrograms_perx] = useState(false);
  const [linkedPrograms_loyalty, setLinkedPrograms_loyalty] = useState(false);
  const [linkedPrograms_green, setLinkedPrograms_green] = useState(false);

  const handleUserTypeID = id => {
    setUserTypeID(id);
  };

  const handleUserType = name => {
    setUserType(name);
  };

  const handleUserGroupID = id => {
    setUserGroupID(id);
  };

  const handleUserGroup = name => {
    setUserGroup(name);
  };

  const handleCompanyBranchID = id => {
    setCompanyBranchID(id);
  };

  const handleCompanyBranch = name => {
    setCompanyBranch(name);
  };

  const handleCountryID = id => {
    setCountryID(id);
  };

  const handleRegionID = id => {
    setRegionID(id);
  };

  const handleStateID = id => {
    setStateID(id);
  };
  const handleCityID = id => {
    setCityID(id);
  };

  const handleZoneID = id => {
    setZoneID(id);
  };

  const handleCountry = name => {
    setCountry(name);
  };
  const handleRegion = name => {
    setRegion(name);
  };
  const handleZone = name => {
    setZone(name);
  };
  const handleState = name => {
    setState(name);
  };
  const handleCity = name => {
    setCity(name);
  };

  const onClose = () => {
    setDeleteModal(false);
  };
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState('');
  const [selectedItemID, setSelectedItemId] = useState('');
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState(null);
  const queryClient = useQueryClient();

  const formData = {
    user_type_id: userTypeID,
    user_group_id: userGroupID,
    company_branch_id: companyBranchID,
    first_name: firstName,
    last_name: lastName,
    other_name: otherName,
    address: address,
    mobile_no: mobileNumber,
    profession: profession,
    login_email_id: loginEmailID,
    communication_email_id: communicationEmailID,
    photograph: selectedImageName,
    DOB: dob,
    gender: 'Female',
    password: password,
    anniversary: anniversary,
    linked_programs: JSON.stringify({
      loyalty: linkedCompanys_loyalty,
      firstbank: linkedCompanys_firstbank,
      fidelity: linkedCompanys_fidelity,
      gradsquare: linkedCompanys_gradsquare,
      uba: linkedCompanys_uba,
    }),
    country_currency_id: currencyID,
    region_id: regionID,
    zone_id: zoneID,
    state_id: stateID,
    city_id: cityID,
    linked_companys: JSON.stringify({
      perx: linkedPrograms_perx,
      greenrewards: linkedPrograms_green,
      loyaltyrewards: linkedPrograms_loyalty,
    }),
  };

//RedirectToLogin();

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const fetchCompanyEnrolments = async page => {
    const res = await axiosProperty.get(
      ApiConfig.fetchCompanyEnrolment + `?page=${page}`,
      {
        headers: Headers(),
      },
    );
    return res.data;
  };
  const {data, status} = useQuery(
    ['companyenrolments', page],
    () => fetchCompanyEnrolments(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = searchTerm => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data) {
      const filtered = data.data.filter(
        (item) =>
          item.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.mobile_no.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredData(filtered);
    }
  };

  const addCompanyEnrolment = async () => {
    try{
        await axiosProperty.post(ApiConfig.createCompanyEnrolment, formData, 
        {
          headers:Headers(),
      }
        );
  setAddress('');
  setAnniversary('');
  setCity('');
  setCityID('');
  setCommunicationEmailID('');
  setCountry('');
  setCountryID('');
  setDOB('');
  setFirstName('');
  setLastName('');
  setLoginEmailID('');
  setGender(false);
  setOtherName('');
  setMobileNumber('');
  setRegion('');
  setRegionID('');
  setZoneID('');
  setZone('');
  setState('');
  setStateID('');
  setUserTypeID('');
  setUserType('');
  setUserGroupID('');
  setUserGroup('');
  setCompanyBranchID('');
  setCompanyBranch('');
  setZoneID('');
  setZone('');
  setPassword('');
  setStartSeriesNo('');
  setProfession('');
  setErrors(null);
  swal({
    title: "Success!",
    text: "Company Enrolment created successfully",
    icon: "success",
  });

    }catch(err){
      if(err.response){
       setError(err.response.data.message);
       setErrors(err.response.data);
      }else{
      setError('Something went wrong, try again'); 
      }
      console.log(err);
      swal({
        title: 'Error!',
        text: error || 'server issue',
        icon: 'error',
      });
    }
  };

  const {mutate: addCompanyEnrolmentMutation} = useMutation({
    mutationFn: addCompanyEnrolment,
    onSuccess: () => {
      queryClient.invalidateQueries(['companyenrolments']);
    },
  });

  const updateCompanyEnrolment = async () => {
    try {
      await axiosProperty.patch(
        ApiConfig.updateCompanyEnrolment + `/${updateID}`,
        formData,
        {
          headers:Headers(),
      }
        );
        setAddress('');
        setAnniversary('');
        setCity('');
        setCityID('');
        setCommunicationEmailID('');
        setCountry('');
        setCountryID('');
        setDOB('');
        setFirstName('');
        setLastName('');
        setLoginEmailID('');
        setGender(false);
        setOtherName('');
        setMobileNumber('');
        setRegion('');
        setRegionID('');
        setState('');
        setStateID('');
        setUserTypeID('');
        setUserType('');
        setUserGroupID('');
        setUserGroup('');
        setCompanyBranchID('');
        setCompanyBranch(''); 
        setZoneID('');
        setZone('');
        setPassword('');
        setStartSeriesNo('');
        setProfession('');       
        setIsSubmit(true);
        setIsUpdate(false);
        setErrors(null);
        swal({
          title: "Success!",
          text: "Company Enrolment updated successfully",
          icon: "success",
        });
      
    }catch(err){
      if(err.response){
        setError(err.response.data.message);
        setErrors(err.response.data);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);

      swal({
        title: 'Error!',
        text: error,
        icon: 'error',
      });
    }
  };

  const {mutate: updateCompanyEnrolmentMutation} = useMutation({
    mutationFn: updateCompanyEnrolment,
    onSuccess: () => {
      queryClient.invalidateQueries(['companyenrolments']);
    },
  });

  const editCompanyEnrolment = async id => {
    try {
      const res = await axiosProperty.get(
        ApiConfig.editCompanyEnrolment + `/${id}`,
        {
          headers: Headers(),
        },
      );
  setIsUpdate(true); 
  setIsSubmit(false); 
  setAddress(res.data.data.address || '');
  setAnniversary(res.data.data.anniversary || '');
  setCityID(res.data.data.city_id);
  setCity(res.data.data.city || '');
  setZoneID(res.data.data.zone_id);
  setZone(res.data.data.zone || '');
  setCommunicationEmailID(res.data.data.communication_email_id || '');
  setCountryID(res.data.data.country_currency_id);
  setCountry(res.data.data.country || '');
  setDOB(res.data.data.DOB || '');
  setFirstName(res.data.data.first_name || '');
  setLastName(res.data.data.last_name || '');
  setLoginEmailID(res.data.data.login_email_id || '');
  setGender(res.data.data.gender);
  setOtherName(res.data.data.other_name || '');
  setMobileNumber(res.data.data.mobile_no || '');
  setRegionID(res.data.data.region_id);
  setRegion(res.data.data.region || '');
  setStateID(res.data.data.state_id);
  setState(res.data.data.state || '');
  setUserTypeID(res.data.data.user_type_id);
  setUserType(res.data.data.user_type || '');
  setUserGroupID(res.data.data.user_group_id);
  setUserGroup(res.data.data.user_group || '');
  setCompanyBranchID(res.data.data.company_branch_id);
  setCompanyBranch(res.data.data.company_branch || '');
  setProfession(res.data.data.profession || '');
  setPassword(res.data.data.password || '');


    }catch(err){
      if(err.response){
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);
      swal({
        title: 'Warning!',
        text: error,
        icon: 'warning',
      });
    }
  };

  const deleteCompanyEnrolment = async id => {
    try {
      const res = await axiosProperty.delete(
        ApiConfig.deleteCompanyEnrolment + `/${id}`,
        {
          headers: Headers(),
        },
      );
      setDeleteModal(false);
      swal({
        title: 'Success!',
        text: 'Company Enrolment deleted successfully',
        icon: 'success',
      });
      queryClient.invalidateQueries(['companyenrolments']);
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);

      swal({
        title: 'Error!',
        text: error,
        icon: 'error',
      });
    }
  };
  return (
    <CallCenterStructure>
      <div className={styles.companyUserEnrollment}>
        <div className={styles.headerText}>
          <p>Company User Enrollment</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                User Type<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchUserType} onSelect={handleUserTypeID} onSelectOption={handleUserType} retrievedValue={userTypeID} column={'name'} key="usertype"/>
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                User Group<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchUserGroup} onSelect={handleUserGroupID} onSelectOption={handleUserGroup} retrievedValue={userGroupID} column={'user_group_name'} key="usergroup" />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Branch<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchCompanyBranch} onSelect={handleCompanyBranchID} onSelectOption={handleCompanyBranch} retrievedValue={companyBranchID} column={'branch_name'} key="companybranch" />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                First Name<sup>*</sup>
              </label>
              <input
                type="text"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">Middle Name</label>
              <input
                type="text"
                value={otherName}
                onChange={e => setOtherName(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Last Name<sup>*</sup>
              </label>
              <input
                type="text"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer3}>
            <label htmlFor="">
              Enter Address<sup>*</sup>
            </label>
            <textarea
              name=""
              id=""
              value={address}
              onChange={e => setAddress(e.target.value)}
            />
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select Country<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchCountry} onSelect={handleCountryID}  onSelectOption={handleCountry} retrievedValue={currencyID} column={'name'} key='country'/>
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select Region<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchRegion(currencyID)} onSelect={handleRegionID} onSelectOption={handleRegion} retrievedValue={regionID} column={'name'} key="region"/>
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select Zone<sup>*</sup>
              </label>
              <Dropdown api={ApiConfig.fetchZone(regionID)} onSelect={handleZoneID} onSelectOption={handleZone} retrievedValue={zoneID} column={'name'} key='zone'/>
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select State<sup>*</sup>
              </label>

              <Dropdown api={ApiConfig.fetchState(zoneID)} onSelect={handleStateID} onSelectOption={handleState} retrievedValue={stateID} column={'name'} key='state'/>
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">Select City</label>

              <Dropdown api={ApiConfig.fetchCity(stateID)} onSelect={handleCityID} onSelectOption={handleCity} retrievedValue={cityID} column={'name'} key='city'/>
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Starting Series of PoS Bill No.<sup>*</sup>
              </label>
              <input type="text" value={startSeriesNo} onChange={(e)=>setStartSeriesNo(e.target.value)} />
            </div>
          </div>
          <div className={styles.fieldContainer4}>
            <div className={styles.innerFieldContainer4}>
              <label htmlFor="">
                Mobile Number* (Do not preceed with Country code or '0' )
                <sup>*</sup>
              </label>

              <input
                type="text"
                value={mobileNumber}
                onChange={e => setMobileNumber(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer4}>
              <label htmlFor="">Profession</label>

              <input
                type="text"
                value={profession}
                onChange={e => setProfession(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Login Email Id<sup>*</sup>
              </label>

              <input
                type="text"
                value={loginEmailID}
                onChange={e => setLoginEmailID(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">Communication Email Id</label>

              <input
                type="text"
                value={communicationEmailID}
                onChange={e => setCommunicationEmailID(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Password<sup>*</sup>
              </label>
              <input
                type="text"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.itemInputContainer4}>
            <div className={styles.defaultImageContainer}>
              <div className={styles.imageContainer}>
                {selectedImage ? (
                  <img src={selectedImage} alt="default" />
                ) : (
                  <img src={defaultImage} alt="default" />
                )}
              </div>
              <p>Image</p>
            </div>

            <div className={styles.uploadContainer}>
              <div className={styles.uploadInputContainer}>
                <label htmlFor="">Photograph</label>
                <ImageDragAndDrop
                  selectedImageName={selectedImageName}
                  setSelectedImageName={setSelectedImageName}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Gender<sup>*</sup>
              </label>

              <RadioButtonBlue
                firstText="Male"
                secondText="Female"
                onChange={setGender}
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                DOB(<span class="text-danger">YY-mm-dd</span>)<sup>*</sup>
                </label>

              <input
                type="text"
                value={dob}
                onChange={e => setDOB(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Anniversary(<span class="text-danger">YY-mm-dd</span>)<sup>*</sup>
              </label>
              <input
                type="text"
                value={anniversary}
                onChange={e => setAnniversary(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer5}>
            <label htmlFor="">Linked Companies</label>
            <div className={styles.radio5}>
              <div className={styles.radioContain}>
                <CheckBox onChange={setLinkedCompanys_loyalty} />
                <p>Loyalty Solutions</p>
              </div>
              <div className={styles.radioContain}>
                <CheckBox onChange={setLinkedCompanys_fidelity} />
                <p>Fidelity Bank</p>
              </div>
              <div className={styles.radioContain}>
                <CheckBox onChange={setLinkedCompanys_firstbank} />
                <p>First Bank</p>
              </div>
              <div className={styles.radioContain}>
                <CheckBox onChange={setLinkedCompanys_uba} />
                <p>UBA</p>
              </div>
              <div className={styles.radioContain}>
                <CheckBox onChange={setLinkedCompanys_gradsquare} />
                <p>Grandsquare</p>
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer6}>
            <label htmlFor="">
              Loyalty Programs<sup>*</sup>
            </label>
            <div className={styles.radio5}>
              <div className={styles.radioContain}>
                <CheckBox onChange={setLinkedPrograms_perx} />
                <p>Perx</p>
              </div>
              <div className={styles.radioContain}>
                <CheckBox onChange={setLinkedPrograms_green} />
                <p>Green rewards</p>
              </div>
              <div className={styles.radioContain}>
                <CheckBox onChange={setLinkedPrograms_loyalty} />
                <p>Loyalty Rewards</p>
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            {isSubmit && (
              <button
                className={styles.submit}
                onClick={() => addCompanyEnrolmentMutation()}>
                Submit
              </button>
            )}
            {isUpdate && (
              <button
                className={styles.submit}
                onClick={() => updateCompanyEnrolmentMutation()}>
                Update
              </button>
            )}
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
          <ErrorMessage errMsg={errors} />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>User Name</th>
                  <th>Email</th>
                  <th>Phone No.</th>
                  <th>Address</th>
                  <th>User Type</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map(each => (
                    <tr key={each.phone}>
                      <td>{each.first_name}</td>
                      <td>{each.login_email_id}</td>
                      <td>{each.mobile_no}</td>
                      <td>{each.address}</td>
                      <td>{each.user_type_id}</td>
                      <td>
                        <div className={styles.iconContainer}>
                          <span
                            className={styles.icon}
                            onClick={() => {
                              editCompanyEnrolment(each.id);
                              setUpdateID(each.id);
                            }}>
                            <ViewPen />
                          </span>
                          <span
                            className={styles.icon}
                            onClick={() => {
                              setDeleteModal(true);
                              setSelectedItemId(each.id);
                            }}>
                            <Times />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colspan="6">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={data} setPage={setPage} />
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && (
          <MenuDeleteModal
            onClose={onClose}
            onDelete={() => deleteCompanyEnrolment(selectedItemID)}
          />
        )}
      </div>
    </CallCenterStructure>
  );
};

export default CompanyUserEnrollment;
