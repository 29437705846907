import React, { useState, useEffect } from "react";
import CallCenterStructure from "../CallCenterStructure/CallCenterStructure";
import styles from "../../styles/ViewAndUpdateMenuMap.module.scss";
import CheckBox from "../../component/CheckBox/CheckBox";
import Paginate from "../../component/Paginate/Paginate";
import { ViewPen } from "../../assets/icons";
import axiosProperty from "../../service/HttpConfig";
import ApiConfig from "../../service/ApiConfig";
import { HeadersOperator } from "../../service/Essentials";
import { useQuery, useMutation, queryClient } from "react-query";
import CheckboxUpgradePrivilege from "../../component/CheckboxUpgradePrivilege/CheckboxUpgradePrivilege";
import swal from "sweetalert";

const ViewAndUpdateMenuMap = () => {
  const [page, setPage] = useState(1);
  const [error, setError] = useState("oops... something went wrong");

  // const [updateID, setUpdateID] = useState(null);
  const [eachId, setEachId] = useState(null);
  const [menu, setMenu] = useState([]);
  const [updatedArr, setUpdatedArr] = useState([])
  const [eachMenu, setEachMenu] = useState({});

  const [check, setCheck] = useState([
    { name: "Premium", tag: false },
    { name: "Premium Plus", tag: false },
    { name: "Coalition", tag: false },
    { name: "Enterprise", tag: false },
    { name: "Dome", tag: false },
  ]);

  const [solutionArr, setSolutionArr] = useState([]);

  const getMenu = async (page) => {
    const res = await axiosProperty.get(ApiConfig.fetchMenuMaster(page), {
      headers: HeadersOperator(),
    });
    return res.data;
  };
  const { data: menumapData, status } = useQuery(
    ["menumap", page],
    () => getMenu(page),
    { keepPreviousData: true }
  );

  useEffect(() => {
    setMenu(menumapData?.data?.data || []);
  }, [menumapData]);
 

  const getEachMenu = async (eachId) => {
    const res = await axiosProperty.get(ApiConfig.getEachMenu(eachId), {
      headers: HeadersOperator(),
    });
    console.log(res.data);
    return res.data;
  };
  const { data: eachData, status: stat } = useQuery(
    ["menuedit", eachId],
    () => getEachMenu(eachId),
    { keepPreviousData: true }
  );
  useEffect(() => {
    setEachMenu(eachData?.data || {});
  }, [eachData]);
  

  const handleSolutionCheck = (tag, i) => {
    let solution = check[i];
    solution.tag = !tag;
    let checkClone = [...check];
    checkClone[i] = solution;
    setCheck([...checkClone]);
    if (checkClone[i].tag === true) {
      setSolutionArr((prev) => [...prev, checkClone[i].name]);
    } else if (checkClone[i].tag === false) {
      const getItem = solutionArr.find((item) => {
        return item === checkClone[i].name;
      });
      if (getItem) {
        setSolutionArr(
          solutionArr.filter((itemDel) => {
            return itemDel !== checkClone[i].name;
          })
        );
      }
    }
  };

  useEffect(()=>{
    setUpdatedArr([{
      id: eachMenu.id,
      solution_type:solutionArr.toString(),
    }])
  },[eachMenu, solutionArr])
 

  
  const updateSolution = async () => {
    try {
      const res = await axiosProperty.patch(
        ApiConfig.updateSolution,
        updatedArr,
        {
          headers: HeadersOperator(),
        }
      );

      swal({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        swal({
          title: "Error!",
          text: error,
          icon: "error",
        });
      } else {
        setError("Something went wrong, probably network");
        swal({
          title: "warning!",
          text: error,
          icon: "error",
        });
      }
    }
  };

  const { mutate: updateSolutionMutation } = useMutation({
    mutationFn: updateSolution,
    onSuccess: () => {
      queryClient.invalidateQueries(["menuedit"]);
    },
  });

  return (
    <CallCenterStructure>
      <div className={styles.menuMap}>
        <div>
          <p className={styles.headerText}>Solution Menu Map</p>
        </div>
        <div className={styles.tableContainer}>
          <table className={styles.menuMapTable}>
            <thead>
              <tr>
                {/* <th className={styles.tableHead}>Level-0</th>
                <th className={styles.tableHead}>Level-1</th>
                 */}
                <th className={styles.tableHead}>
                  Level-{eachMenu?.parent_id}
                </th>
                <th>Premium</th>
                <th>Premium Plus</th>
                <th>Coalition</th>
                <th>Enterprise</th>
                <th>Dome</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{eachMenu?.name}</td>
                {/* <td></td>

                <td></td> */}
                {check.map(({ name, tag }, i) => (
                  <td
                    className={styles.checkboxTableData}
                    style={{ paddingBottom: "30px" }}
                    key={i}
                  >
                    <CheckboxUpgradePrivilege
                      id={i}
                      checked={tag}
                      onChange={() => handleSolutionCheck(tag, i)}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
        <button
          className={styles.transactionTypeBtn}
          onClick={(event) => {
            event.preventDefault();
            updateSolutionMutation();
          }}
        >
          Update Solution
        </button>
      </div>

      <div className={styles.tableContainer}>
        <table className={styles.dataTable}>
          <thead>
            <tr>
              <th>Menu ID</th>
              <th>Menu Name</th>
              <th>Solution Type</th>
              <th>Menu Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {status === "loading" && (
              <tr>
                <td className="text-info">loading...</td>
              </tr>
            )}

            {status === "success" &&
              (menu?.length === 0 ? (
                <tr>
                  <td>No Data!</td>
                </tr>
              ) : (
                menu?.map((each) => (
                  <tr key={each.id}>
                    <td>{each.id}</td>
                    <td>{each.name}</td>
                    <td>{each.solution_type}</td>
                    <td>{each.description}</td>
                    <td>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setEachId(each.id);
                          // setIsSubmit(false);
                          // setIsUpdate(true);
                          // setSelectedItem(each);
                          // setFormData({
                          //   name: each?.name,
                          //   description: each?.description,
                          //   solution_type: each?.solution_type,
                          //   operation: each?.operation,
                          //   parent_id: each?.parent_id,
                          //   href: each?.href,
                          // });
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <ViewPen />
                      </span>{" "}
                    </td>
                  </tr>
                ))
              ))}
          </tbody>
        </table>
      </div>
      <div className={styles.paginationContainer}>
        <Paginate page={page} data={menumapData} setPage={setPage} />
      </div>
    </CallCenterStructure>
  );
};

export default ViewAndUpdateMenuMap;
