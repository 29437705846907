import React, {useEffect, useState} from 'react';
import Category from '../../component/Category/Category';
import TemplateNavbar from '../../component/TemplateNavbar/TemplateNavbar';
import styles from '../../styles/SingleCategory.module.scss';
import hp1 from '../../assets/images/hp1.png';
import hp2 from '../../assets/images/hp2.png';
import hp3 from '../../assets/images/hp3.png';
import hp4 from '../../assets/images/hp4.png';
import TemplateFooter from '../../component/TemplateFooter/TemplateFooter';
import {TemplateArrowDown, TemplateArrowUp} from '../../assets/icons';

const SingleCategory = () => {
  const [itemIndex, setItemIndex] = useState(4);
  const [visibleItems, setVisibleItems] = useState([]);
  const [activeBTN, setActiveBTN] = useState({
    active: 'popular',
    active: 'L2H',
    active: 'H2L',
  });

  const items = [
    {img: hp1, name: 'Laptop HP', point: '28,000'},
    {img: hp2, name: 'Laptop HP', point: '28,001'},
    {img: hp3, name: 'Laptop HP', point: '28,002'},
    {img: hp4, name: 'Laptop HP', point: '28,003'},
    {img: hp4, name: 'Laptop HP', point: '28,004'},
    {img: hp4, name: 'Laptop HP', point: '28,005'},
    {img: hp4, name: 'Laptop HP', point: '28,006'},
    {img: hp4, name: 'Laptop HP', point: '28,007'},
    {img: hp4, name: 'Laptop HP', point: '28,008'},
    {img: hp4, name: 'Laptop HP', point: '28,009'},
    {img: hp4, name: 'Laptop HP', point: '28,010'},
  ];

  useEffect(() => {
    const showItems = items.slice(0, itemIndex);
    setVisibleItems(showItems);
  }, [itemIndex]);

  const sortItemsByPointsL2H = () => {
    setActiveBTN('L2H');
    const sortedItems = [...visibleItems].sort((a, b) => {
      const pointA = parseInt(a.point.replace(',', ''), 10);
      const pointB = parseInt(b.point.replace(',', ''), 10);
      console.log('visibleItems after sorting (L2H):', visibleItems);
      return pointA - pointB;
    });

    setVisibleItems(sortedItems);
  };

  const sortItemsByPointsH2L = () => {
    setActiveBTN('H2L');
    const sortedItems = [...visibleItems].sort((a, b) => {
      const pointA = parseInt(a.point.replace(',', ''), 10);
      const pointB = parseInt(b.point.replace(',', ''), 10);
      console.log('visibleItems after sorting (H2L):', visibleItems);
      return pointB - pointA;
    });

    setVisibleItems(sortedItems);
  };

  return (
    <div className={styles.singleCategoryPage}>
      <TemplateNavbar />
      <Category />
      <div className={styles.singleCategoryContainer}>
        <h1 className={styles.singleCategoryHeader}>Laptops</h1>
        <div className={styles.sortingContainer}>
          <p className={styles.sortingText}>Sort: </p>
          <button
            className={
              activeBTN === 'popular'
                ? styles.activeSorting
                : styles.unactiveSorting
            }
            onClick={() => setActiveBTN('popular')}>
            Popularity
          </button>
          <button
            className={
              activeBTN === 'L2H'
                ? styles.activeSorting
                : styles.unactiveSorting
            }
            onClick={() => sortItemsByPointsL2H()}>
            Price: Lowest to Highest
            <TemplateArrowDown />
          </button>
          <button
            className={
              activeBTN === 'H2L'
                ? styles.activeSorting
                : styles.unactiveSorting
            }
            onClick={() => sortItemsByPointsH2L()}>
            Price: Highest to Lowest
            <TemplateArrowUp />
          </button>
        </div>
        <div className={styles.itemsContainer}>
          {visibleItems.map(item => (
            <div key={item.point} className={styles.templateCard}>
              <div className={styles.itemImage}>
                <img src={item.img} alt={item.name} />
              </div>
              <p className={styles.itemName}>{item.name}</p>
              <p className={styles.itemPoint}>{item.point} Points</p>
              <div className={styles.btnContainer}>
                <button className={styles.quickBuyButton}>Quick Buy</button>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.loadMoreBTNContainer}>
          <button
            className={
              itemIndex >= items.length
                ? styles.loadMoreBTNEmpty
                : styles.loadMoreBTNUnEmpty
            }
            onClick={() => setItemIndex(itemNumber => itemNumber + 4)}>
            Load More
          </button>
        </div>
      </div>
      <TemplateFooter />
    </div>
  );
};

export default SingleCategory;
