import axios from 'axios';

const axiosProperty = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  all: axios.all,
};

export default axiosProperty;
