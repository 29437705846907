import React, {useState} from 'react';
import Dropdown from '../../component/DropDown/DropDown';
import styles from '../../styles/CustomerEnrolmentFromWebsite.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import {
  CustomerEnrollmentHeaders,
  RedirectToLogin,
} from '../../service/Essentials';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CustomerEnrolmentFromWebsite = () => {
  const [clearModal, setClearModal] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [account, setAccount] = useState('');
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState(null);
  const queryClient = useQueryClient();

  const formData = {
    First_name: firstName,
    Last_name: lastName,
    Middle_name: middleName,
    Phone_no: phone,
    Account_number: account,
    email: email,
  };

  const addEnrolmentWebsite = async () => {
    try{
       const res = await axiosProperty.post(ApiConfig.createEnrolmentWebsite, formData, 
        {
          headers:CustomerEnrollmentHeaders(),
      }
      );
      if(res.data.status == 'Failed!'){
        swal({
          title: 'error!',
          text: res.data.message,
          icon: 'error',
        });
     }else{
    setFirstName('');
    setLastName('');
    setMiddleName('');
    setEmail('');
    setAccount('');
    setPhone('');

  swal({
    title: "Success!",
    text: "Customer Enrolment Website created successfully",
    icon: "success",
  });
  setErrors(null);
}
    }catch(err){
      if(err.response){
       setError(err.response.data.message);
       setErrors(err.response.data);
       swal({
        title: "error!",
        text: error,
        icon: "error",
      });
      }else{
      setError('Something went wrong, try again'); 
      swal({
        title: "error!",
        text: error,
        icon: "error",
      });
      }
      console.log(err);
    }
  };

  const {mutate: addEnrolmentWebsiteMutation} = useMutation({
    mutationFn: addEnrolmentWebsite,
    //   onSuccess:()=>{
  });

  return (
    <CallCenterStructure>
      <div className={styles.customerEnrolmentFromWebsiteContainer}>
        <div className={styles.headerText}>
          <p>Customer Enrolment from Website</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.fieldInnerContainer1}>
              <label htmlFor="">
                First Name<sup>*</sup>
              </label>
              <input
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                type="text"
              />
            </div>
            <div className={styles.fieldInnerContainer1}>
              <label htmlFor="">
                Mobile Number<sup>*</sup>(without country code)
              </label>
              <input
                value={phone}
                onChange={e => setPhone(e.target.value)}
                type="text"
              />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.fieldInnerContainer1}>
              <label htmlFor="">
                Middle Name<sup>*</sup>
              </label>
              <input
                value={middleName}
                onChange={e => setMiddleName(e.target.value)}
                type="text"
              />
            </div>
            <div className={styles.fieldInnerContainer1}>
              <label htmlFor="">
                Email ID<sup>*</sup>
              </label>
              <input
                value={email}
                onChange={e => setEmail(e.target.value)}
                type="text"
              />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.fieldInnerContainer1}>
              <label htmlFor="">
                Last Name<sup>*</sup>
              </label>
              <input
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                type="text"
              />
            </div>
            <div className={styles.fieldInnerContainer1}>
              <label htmlFor="">
                Account Number<sup>*</sup>
              </label>
              <input
                value={account}
                onChange={e => setAccount(e.target.value)}
                type="text"
              />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button
              className={styles.submit}
              onClick={() => addEnrolmentWebsiteMutation()}>
              Submit
            </button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
          <ErrorMessage errMsg={errors} />
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
      </div>
    </CallCenterStructure>
  );
};

export default CustomerEnrolmentFromWebsite;
