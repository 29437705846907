import React, {useState} from 'react';
import {Times, ViewPen} from '../../assets/icons';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import SearchInput from '../../component/SearchInput/SearchInput';
import styles from '../../styles/CreatePartnerCategory.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';

const CreatePartnerCategory = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [clearModal, setClearModal] = useState(false);
  const onClose = () => {
    setDeleteModal(false);
  };
  const data = [
    {id: 2, name: 'Restaurants'},
    {id: 1, name: 'Restaurants'},
    {id: 3, name: 'Restaurants'},
  ];

  return (
    <CallCenterStructure>
      <div className={styles.createPartnerCategoryContainer}>
        <div className={styles.headerText}>
          <p>LSL Partner Catalogue Category</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputInnerContainer}>
            <div className={styles.labelAndInputContainer}>
              <label htmlFor="">
                Partner Category Name<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.buttonContainer}>
              <button className={styles.submit}>Submit</button>
              <button
                className={styles.reset}
                type="button"
                onClick={() => setClearModal(true)}>
                Reset
              </button>
            </div>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.searchInputContainer}>
            <SearchInput />
            <button className={styles.searchButton}>search</button>
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Partner Category ID</th>
                  <th>Partner Category Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map(each => (
                  <tr>
                    <td>{each.id}</td>
                    <td>{each.name}</td>
                    <td>
                      {' '}
                      <div className={each.iconContainer}>
                        <span className={styles.icon}>
                          <ViewPen />
                        </span>
                        <span
                          className={styles.icon}
                          onClick={() => setDeleteModal(true)}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && <MenuDeleteModal onClose={onClose} />}
      </div>
    </CallCenterStructure>
  );
};

export default CreatePartnerCategory;
