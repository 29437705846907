import React, {useState} from 'react';
import styles from '../../styles/RadioButtonBlue.module.scss';

const RadioButton = ({options, selectedOption, onChange}) => {
  const handleRadioChange = option => {
    onChange(option);
  };

  return (
    <div className={styles.radioButtons}>
      {options?.map((option, index) => (
        <div
          className={styles.radioButtonSingle}
          key={index}
          onClick={() => handleRadioChange(option)}>
          <div className={styles.myCheckbox}>
            <div
              className={
                option === selectedOption ? styles.checked : styles.uncheck
              }></div>
          </div>
          <p>{option}</p>
        </div>
      ))}
    </div>
  );
};

export default RadioButton;
