import React from 'react';
import Category from '../../component/Category/Category';
import TemplateFooter from '../../component/TemplateFooter/TemplateFooter';
import TemplateNavbar from '../../component/TemplateNavbar/TemplateNavbar';
import styles from '../../styles/Vouchers.module.scss';
import CR from '../../assets/images/cr.png';
import Spar from '../../assets/images/spar.png';
import Uber from '../../assets/images/uber.png';

const Vouchers = () => {
  const vouchers = [
    {name: 'Uber', img: Uber},
    {name: 'Spar', img: Spar},
    {name: 'Chicken Republic', img: CR},
  ];
  return (
    <div className={styles.VouchersContainer}>
      <TemplateNavbar />
      <Category />
      <div className={styles.vouchersContent}>
        <h3 className={styles.headerText}>Grab a voucher with your Points</h3>
        <div className={styles.voucherContainer}>
          {vouchers.map(voucher => (
            <div className={styles.voucherCard} key={voucher.name}>
              <div className={styles.voucherImg}>
                <img src={voucher.img} alt={voucher.name} />
              </div>
              <p>{voucher.name}</p>
              <div className={styles.voucherBTNContainer}>
                <button className={styles.voucherBTN}>Generate Voucher</button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <TemplateFooter />
    </div>
  );
};

export default Vouchers;
