import React, {useState, useEffect} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import {Times, ViewPen} from '../../assets/icons';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import styles from '../../styles/UserGroupMaster.module.scss';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import { Headers } from '../../service/Essentials';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';
import { ProgramID } from '../../service/Essentials';
import Dropdown from '../../component/DropDown/DropDown';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const UserGroupMaster = () => {
  const [clearModal, setClearModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userTypeID, setUserTypeID] = useState('');
  const [userType, setUserType] = useState('');
  const [userGroupName, setUserGroupName] = useState('');
  const [desc, setDesc] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState('');
  const [selectedItemID, setSelectedItemId] = useState('');
  const [error, setError] = useState();
  const [errors, setErrors] = useState(null);
  const queryClient = useQueryClient();
  const onClose = () => {
    setDeleteModal(false);
  };

  const handleID = (id) => {
    setUserTypeID(id);
  };

  const handleName = (name) => {
    setUserType(name);
  };
  
  const formData = {
    user_type_id: userTypeID,
    user_group_name: userGroupName,
    description: desc,
  };

  //RedirectToLogin();

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const fetchUserGroups = async page => {
    const res = await axiosProperty.get(
      ApiConfig.fetchUserGroup + `?page=${page}`,
      {
        headers: Headers(),
      },
    );
    return res.data;
  };
  const {data, status} = useQuery(
    ['usergroups', page],
    () => fetchUserGroups(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = searchTerm => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data) {
      const filtered = data.data.filter(
        (item) =>
          item.user_group_name.toLowerCase().includes(searchTerm.toLowerCase())||
          item.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  const addUserGroup = async () => {
    try{
        await axiosProperty.post(ApiConfig.createUserGroup, formData, 
        {
          headers:Headers(),
      }
        );
  setErrors(null);
  setUserGroupName('');
  setUserType('');
  setDesc('');
  swal({
    title: "Success!",
    text: "User group created successfully",
    icon: "success",
  });
  setUserType('');
  setUserTypeID('');

    }catch(err){
      if(err.response){
       setError(err.response.data.message);
       setErrors(err.response.data);
      }else{
      setError('Something went wrong, try again'); 
      }
      console.log(err);
      swal({
        title: 'Error!',
        text: error || 'server issue',
        icon: 'error',
      });
    }
  };

  const {mutate: addUserGroupMutation} = useMutation({
    mutationFn: addUserGroup,
    onSuccess: () => {
      queryClient.invalidateQueries(['usergroups']);
    },
  });

  const updateUserGroup = async () => {
    try {
      await axiosProperty.patch(
        ApiConfig.updateUserGroup + `/${updateID}`,
        formData,
        {
          headers:Headers(),
      }
        );
        setErrors(null);
        setUserGroupName('');
        setUserTypeID('');
        setUserType('');
        setDesc('');
        setIsSubmit(true);
        setIsUpdate(false);
        swal({
          title: "Success!",
          text: "User group updated successfully",
          icon: "success",
        });
      
    }catch(err){
      if(err.response){
        setError(err.response.data.message);
        setErrors(err.response.data);
      }else{
       setError('Something went wrong, try again'); 
      }
//      console.log(err);

      swal({
        title: 'Error!',
        text: error,
        icon: 'error',
      });
    }
  };

  const {mutate: updateUserGroupMutation} = useMutation({
    mutationFn: updateUserGroup,
    onSuccess: () => {
      queryClient.invalidateQueries(['usergroups']);
    },
  });

  const editUserGroup = async id => {
    try {
      const res = await axiosProperty.get(ApiConfig.editUserGroup + `/${id}`, {
        headers: Headers(),
      });
      setIsUpdate(true);
      setIsSubmit(false);
      setUserGroupName(res.data.data.user_group_name || 'no data');
      setUserType(res.data.data.name || 'no data');
      setUserTypeID(res.data.data.user_type_id || 'no data');
      setDesc(res.data.data.description || 'no data');
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);
      swal({
        title: 'Warning!',
        text: error,
        icon: 'warning',
      });
    }
  };

  const deleteUserGroup = async id => {
    try {
      const res = await axiosProperty.delete(
        ApiConfig.deleteUserGroup + `/${id}`,
        {
          headers: Headers(),
        },
      );
      setDeleteModal(false);
      swal({
        title: 'Success!',
        text: 'User group deleted successfully',
        icon: 'success',
      });
      queryClient.invalidateQueries(['usergroups']);
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);

      swal({
        title: 'Error!',
        text: error,
        icon: 'error',
      });
    }
  };
  return (
    <CallCenterStructure>
      <div className={styles.companyTransactionTypeMasterSetup}>
        <div className={styles.headerText}>
          <p>User Group Master</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select User Type<sup>*</sup>
              </label>
              {/* <input type="text" value={userTypeID} onChange={(e)=>setUserTypeID(e.target.value)}/> */}
              <Dropdown header={Headers()} api={ApiConfig.fetchUserType} onSelect={handleID} onSelectOption={handleName} column={'name'} retrievedValue={userTypeID} key="usergroup" />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Enter User Group Name<sup>*</sup>
              </label>
              <input
                type="text"
                value={userGroupName}
                onChange={e => setUserGroupName(e.target.value)}
              />
            </div>
          </div>

          <div className={styles.fieldContainer2}>
            <label htmlFor="">
              Enter Description<sup>*</sup>
            </label>
            <textarea
              name=""
              id=""
              value={desc}
              onChange={e => setDesc(e.target.value)}></textarea>
          </div>

          <div className={styles.buttonContainer}>
            {isSubmit && (
              <button
                className={styles.submit}
                onClick={() => addUserGroupMutation()}>
                Submit
              </button>
            )}
            {isUpdate && (
              <button
                className={styles.submit}
                onClick={() => updateUserGroupMutation()}>
                Update
              </button>
            )}
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
          <ErrorMessage errMsg={errors} />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>User Type</th>
                  <th>User Group Name</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map(each => (
                    <tr key={each.id}>
                      <td>{each.user_type_id}</td>
                      <td>{each.user_group_name}</td>
                      <td>{each.description}</td>

                      <td>
                        <div className={styles.iconContainer}>
                          <span
                            className={styles.icon}
                            onClick={() => {
                              editUserGroup(each.id);
                              setUpdateID(each.id);
                            }}>
                            <ViewPen />
                          </span>
                          <span
                            className={styles.icon}
                            onClick={() => {
                              setDeleteModal(true);
                              setSelectedItemId(each.id);
                            }}>
                            <Times />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colspan="4">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={data} setPage={setPage} />
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && (
          <MenuDeleteModal
            onClose={onClose}
            onDelete={() => deleteUserGroup(selectedItemID)}
          />
        )}
      </div>
    </CallCenterStructure>
  );
};

export default UserGroupMaster;
