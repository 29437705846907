import React, {useState, useEffect} from 'react';
import {Mark, Times, View, ViewPen} from '../../assets/icons';
import styles from '../../styles/UserType.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import { useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
//import {getToken, getUser,getCompanyType } from '../../service/common';
import { Headers, ProgramID} from '../../service/Essentials';

//import { useNavigate } from "react-router-dom";
import Paginate from '../../component/Paginate/Paginate';
import SearchInput from '../../component/SearchInput/SearchInput';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';

const UserType = () => {

//const navigate = useNavigate();
const queryClient = useQueryClient();

//const user = getUser();

//const company = getCompanyType();

  const program_id = ProgramID() ?? '';

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState({name:''});
  const [formData, setFormData] = useState({name:''});
 
  const fetchUserTypes = async (page) =>{
    const res = await axiosProperty.get(ApiConfig.fetchUserType+`?page=${page}`, {
      headers:Headers()
  }
    );
    return res.data;
  }  
  const {data, status} = useQuery(['usertypes', page], () => fetchUserTypes(page), {keepPreviousData:true,});

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data) {
      const filtered = data.data.filter(
        (item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  //const [text, setText] = useState('');
 // const [successModal, setSuccessModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState();

  const handleChange = e => {
    //setText(e.target.value);
    const {name, value} = e.target;
    setFormData({...formData, [name]:value});
  };

  const addUserType = async () => {
    //e.preventDefault();
    //setData(prev => [...prev, {id: 4, name: text}]);
    try{
        await axiosProperty.post(ApiConfig.createUserType, formData, 
          {
            headers:Headers()
        }
        );
       // setSuccessModal(true);
       // setText('');
       setFormData({name:''});
       setErrors({});
        swal({
          title: "Success!",
          text: "Usertype created successfully",
          icon: "success",
        });
    }catch(err){
      if(err.response){
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: "Error!",
          text: error,
          icon: "error",
        });
      }else{
        setError('Something went wrong, probably network'); 
        swal({
          title: "warning!",
          text: error,
          icon: "error",
        });
      }
      console.log(err);
    }
  };

const {mutate:addUserTypeMutation} = useMutation(
  {
    mutationFn: addUserType,
    onSuccess:()=>{queryClient.invalidateQueries(['usertypes'])}, 
  
  }
  );

  const updateUserType = async () => {
    //e.preventDefault();
    try{
        await axiosProperty.patch(ApiConfig.updateUserType+`/${updateID}`, formData,
        {
          headers:Headers()
      }
        );
       // setSuccessModal(true);
      //  setText('');
        setFormData({name:''});
        setIsSubmit(true);
        setIsUpdate(false);
        setErrors(null);
        swal({
          title: "Success!",
          text: "Usertype updated Successfully",
          icon: "success",
        });
    }catch(err){
      if(err.response){
     setError(err.response.data.message);
     setErrors(err.response.data);
     swal({
      title: "Error!",
      text: error,
      icon: "error",
    });
      }else{
      setError('Something went wrong, probably network'); 
      swal({
        title: "Warning!",
        text: error,
        icon: "warning",
      });
      }
      console.log(err);
    }
  };

  const {mutate:updateUserTypeMutation} = useMutation(
    {
      mutationFn: updateUserType,
      onSuccess:()=>{queryClient.invalidateQueries(['usertypes'])}, 
    
    }
    );

  const editUserType = async (id) => {
       const res = await axiosProperty.get(ApiConfig.editUserType+`/${id}`,
       {
        headers:Headers()
    }
      );
      setIsUpdate(true); 
      setIsSubmit(false); 
      setFormData((prevFormData)=>({
        ...prevFormData,
        name:res.data.data.name
      }));

  };

  return (
    <CallCenterStructure>
      <div className={styles.userTypeContainer}>
        <div>
          <h6 className={styles.headerText}>User Type Master</h6>
        </div>

        <div className={styles.inputContainer}>
          <label htmlFor="" className={styles.userTypeLabel}>
            User Type Name
          </label>

      <div className={styles.formContainer}>
            <input
              type="text"
              name='name'
              value={formData.name}
              onChange={handleChange}
              className={styles.userTypeInput}
            />

           {isSubmit && (<button className={styles.userTypeBtn} onClick={()=>addUserTypeMutation()}>
              Submit
            </button>)}
            {isUpdate && (<button className={styles.userTypeBtn} onClick={()=>updateUserTypeMutation()}>
              Update
            </button>)}
          </div>
          {errors?.name && (<p className='text-danger'>{errors?.name}</p>)}

          <p className={styles.userTypeNoteText}>
            *Note: Click on column header for Ascending/Descending
          </p>
        </div>
        <div className={styles.tableContainer}>
          {/* <p>Search Result</p> */}
          {/* <ErrorMessage errMsg={errors} /> */}
          <div className={styles.formContainer}>
            <div className={styles.searchInput}>
              <SearchInput onSearchInputChange={handleSearchInputChange} />
            </div>
            {/* <button className={styles.transactionTypeBtn}>Search</button> */}
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.dataTable}>
              <thead>
                <tr>
                  <th>User Type ID</th>
                  <th>User Type Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {
                status === 'error' && (<tr><td className='text-danger'>Error Occured</td></tr>)
              }
              {
                status === 'loading' && (<tr><td className='text-info'>Loading...</td></tr>)
              }
                {
                status === 'success' && (filteredData.map(each => (
                  <tr key={each.id}>
                    <td>{each.id}</td>
                    <td>{each.name}</td>
                    <td onClick={()=>{editUserType(each.id); setUpdateID(each.id)}}>
                      <ViewPen />
                    </td>
                  </tr>
                )))
                }
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={data} setPage={setPage} />
          </div>
        </div>
        {/* {successModal && (
          <div className={styles.successModal}>
            <Mark />
            <p className={styles.successText}>Operation successful</p>
            <span onClick={() => setSuccessModal(false)}>
              <Times />
            </span>
          </div>
        )} */}
      </div>
    </CallCenterStructure>
  );
};

export default UserType;
