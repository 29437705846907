import React, {useState, useEffect} from 'react';
import {Times} from '../../assets/icons';
import styles from '../../styles/CartTable.module.scss';

const Table = ({data, removeFromCart}) => {
//  const [itemValue, setItemValue] = useState(1);

//   const handleChange = e => {
//     setItemValue(e.target.value);
//   };

//const [itemQuantities, setItemQuantities] = useState(data.map(item => item.Quantity));

const [itemQuantities, setItemQuantities] = useState([]);

  // Use useEffect to set itemQuantities when data changes
  useEffect(() => {
    // Initialize itemQuantities with Quantity values from data
    setItemQuantities(data.map(item => item.Quantity));
  }, [data]);

//console.log(itemQuantities);
//console.log(data);


  const handleChange = (newValue, index) => {
    // Create a new array with the updated quantity
    const updatedQuantities = [...itemQuantities];
    updatedQuantities[index] = newValue;
    setItemQuantities(updatedQuantities);
  };

  return (
    <table className={styles.cartTable}>
      <thead>
        <tr>
          {data.length > 0 && Object.keys(data[0]).map((key, index) => (
            <th key={index}>{key}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.length > 0 ? (data.map((item, index) => (
          <tr key={index}>
            {Object.entries(item).map(([key, value]) => (
              <td key={key}>
                {key === 'Quantity' ? (
                  <input
                    type="number"
                    //value={value}
                  //  onChange={e => {
                    // const newValue = parseInt(e.target.value);
                   // item['Quantity'] = newValue;
                     //handleChange(e);
                  //  }}
                  value={itemQuantities[index]}
                  onChange={e => {
                    const newValue = parseInt(e.target.value, 10);
                    handleChange(newValue, index);
                  }}
                    className={styles.quantityField}
                  />
                ) : key === 'Action' ? (
                  <div className={styles.removeButton} onClick={() => removeFromCart(index)}>
                    {' '}
                    <Times />
                  </div>
                ) : (
                  value
                )}
              </td>
            ))}
          </tr>
        ))):(<tr><td class="text-danger">No record</td></tr>)}
      </tbody>
    </table>
  );
};

export default Table;
