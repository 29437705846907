import React, {useState, useEffect} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/CompanyCataloguePartnerMaster.module.scss';
import Dropdown from '../../component/DropDown/DropDown';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
import defaultImage from '../../assets/images/defaultImage.png';
import SearchInput from '../../component/SearchInput/SearchInput';
import {Times, ViewPen} from '../../assets/icons';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import { Headers } from '../../service/Essentials';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CompanyCataloguePartnerMaster = () => {
  const [clearModal, setClearModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateID, setUpdateID] = useState('');
  const [selectedItemID, setSelectedItemId] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [countryID, setCountryID] = useState(1);
  const [stateID, setStateID] = useState('');
  const [cityID, setCityID] = useState('');
  const [zoneID, setZoneID] = useState('');
  const [regionID, setRegionID] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [zone, setZone] = useState('');
  const [region, setRegion] = useState('');
  const [partnerType, setPartnerType] = useState('');
  const [partnerCategory, setPartnerCategory] = useState('');
  const [partnerTypeID, setPartnerTypeID] = useState();
  const [partnerCategoryID, setPartnerCategoryID] = useState();
  const [partnerName, setPartnerName] = useState();
  const [partnerAddress, setPartnerAddress] = useState();
  const [contactPerson, setContactPerson] = useState();
  const [contactEmail, setContactEmail] = useState();
  const [contactPhone, setContactPhone] = useState();
  const [websiteURL, setwebsiteURL] = useState();
  const [corporateEmailApplicable, setcorporateEmailApplicable] =
    useState(false);
  const [corporateEmailID, setcorporateEmailID] = useState();
  const [markupPercent, setmarkupPercent] = useState();
  const [vat, setVat] = useState();
  const [errors, setErrors] = useState(null);
  const [error, setError] = useState('ooops, something went wrong');
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const handleStateID = id => {
    setStateID(id);
  };
  const handleCountryID = id => {
    setCountryID(id);
  };
  const handleCityID = id => {
    setCityID(id);
  };
  const handleRegionID = id => {
    setRegionID(id);
  };
  const handleZoneID = id => {
    setZoneID(id);
  };
  const handlePartnerTypeID = id => {
    setPartnerTypeID(id);
  };
  const handlePartnerCategoryID = id => {
    setPartnerCategoryID(id);
  };
  const handleCountry = name => {
    setCountry(name);
  };
  const handleRegion = name => {
    setRegion(name);
  };
  const handleZone = name => {
    setZone(name);
  };
  const handleState = name => {
    setState(name);
  };
  const handleCity = name => {
    setCity(name);
  };
  const handlePartnerType = name => {
    setPartnerType(name);
  };
  const handlePartnerCategory = name => {
    setPartnerCategory(name);
  };

  const onClose = () => {
    setDeleteModal(false);
  };

  const queryClient = useQueryClient();

  const formData = {
    partner_type_id: partnerTypeID,
    partner_category_id: partnerCategoryID,
    partner_name: partnerName,
    address: partnerAddress,
    country_currency_id: countryID,
    region_id: regionID,
    zone_id: zoneID,
    state_id: stateID,
    city_id: cityID,
    contact_person_name: contactPerson,
    contact_person_email: contactEmail,
    contact_person_no: contactPhone,
    website_url: websiteURL,
    corporate_email_applicable: Number(corporateEmailApplicable),
    corporate_email_id: corporateEmailID,
    partner_logo: selectedImage,
    vat: vat,
    markup_percentage: markupPercent,
  };

 // RedirectToLogin();

  const fetchPartnerMaster = async page => {
    const res = await axiosProperty.get(
      ApiConfig.fetchPartnermaster + `?page=${page}`,
      {
        headers: Headers(),
      },
    );
    return res.data;
  };
  const {data, status} = useQuery(
    ['partnermasters', page],
    () => fetchPartnerMaster(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = searchTerm => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data?.data) {
      const filtered = data.data.filter(
        (item) =>
          item.partner_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.contact_person_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.contact_person_email
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.contact_person_phone
            .toLowerCase()
            .includes(searchTerm.toLowerCase()),
      );
      setFilteredData(filtered);
    }
  };

  const addPartnerMaster = async () => {
    try {
      await axiosProperty.post(ApiConfig.createPartnerMaster, formData, {
        headers: Headers(),
      });
      setSelectedImage(null);
      setSelectedImageName('');
      setContactEmail('');
      setContactPerson('');
      setContactPhone('');
      setPartnerCategoryID('');
      setPartnerTypeID('');
      setPartnerName('');
      setPartnerAddress('');
      setwebsiteURL('');
      setcorporateEmailApplicable('');
      setcorporateEmailID('');
      setmarkupPercent('');
      setVat('');
      setCountryID('');
      setRegionID('');
      setStateID('');
      setCityID('');
      setZoneID('');
      setErrors(null);
      swal({
        title: 'Success!',
        text: 'Partner setup created successfully',
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setErrors(err.response.data);
        setError(err.response.data.message);
      } else {
        setErrors('Something went wrong, try again');
      }
      swal({
        title: 'Error!',
        text: error,
        icon: 'error',
      });
    }
  };

  const {mutate: addPartnerMasterMutation} = useMutation({
    mutationFn: addPartnerMaster,
    onSuccess: () => {
      queryClient.invalidateQueries(['partnermasters']);
    },
  });

  const updatePartnerMaster = async () => {
    try {
      await axiosProperty.patch(
        ApiConfig.updatePartnermaster(updateID),
        formData,
        {
          headers: Headers(),
        },
      );
      setSelectedImage(null);
      setSelectedImageName('');
      setContactEmail('');
      setContactPerson('');
      setContactPhone('');
      setPartnerCategoryID('');
      setPartnerTypeID('');
      setPartnerName('');
      setPartnerAddress('');
      setwebsiteURL('');
      setcorporateEmailApplicable('');
      setcorporateEmailID('');
      setmarkupPercent('');
      setVat('');
      setCountryID('');
      setRegionID('');
      setStateID('');
      setCityID('');
      setZoneID('');
      setErrors(null);
      setIsSubmit(true);
      setIsUpdate(false);
      swal({
        title: 'Success!',
        text: 'Partner setup updated successfully',
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setErrors(err.response.data);
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      swal({
        title: 'Error!',
        text: error,
        icon: 'error',
      });
    }
  };

  const {mutate: updatePartnerMasterMutation} = useMutation({
    mutationFn: updatePartnerMaster,
    onSuccess: () => {
      queryClient.invalidateQueries(['partnermasters']);
    },
  });

  const editPartnerMaster = async id => {
    try {
      const res = await axiosProperty.get(ApiConfig.editPartnermaster(id), {
        headers: Headers(),
      });
      setIsUpdate(true);
      setIsSubmit(false);
      setContactEmail(res.data.data.contact_person_email || '');
      setContactPerson(res.data.data.contact_person_name || '');
      setContactPhone(res.data.data.contact_person_no || '');
      setPartnerCategoryID(res.data.data.partner_category_id || '');
      setPartnerTypeID(res.data.data.partner_type_id || '');
      setPartnerName(res.data.data.partner_name || '');
      setPartnerAddress(res.data.data.address || '');
      setwebsiteURL(res.data.data.website_url || '');
      setcorporateEmailApplicable(
        res.data.data.corporate_email_applicable || '',
      );
      setcorporateEmailID(res.data.data.corporate_email_id || '');
      setmarkupPercent(res.data.data.markup_percentage || '');
      setVat(res.data.data.vat || '');
      setCountryID(res.data.data.country_currency_id || '');
      setRegionID(res.data.data.region_id || '');
      setZoneID(res.data.data.zone_id || '');
      setStateID(res.data.data.state_id || '');
      setCityID(res.data.data.city_id || '');
      setErrors(null);
      // setSelectedImage(res.data.data.logo || 'no data');
      // setSelectedImageName(res.data.data.logo || 'no data');
    } catch (err) {
      if (err.response) {
        setErrors(err.response.data);
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);
      swal({
        title: 'Warning!',
        text: error,
        icon: 'warning',
      });
    }
  };
  const deletePartnerMaster = async id => {
    try {
      const res = await axiosProperty.delete(
        ApiConfig.deletePartnermaster(id),
        {
          headers: Headers(),
        },
      );
      setDeleteModal(false);
      swal({
        title: 'Success!',
        text: 'Partner deleted successfully',
        icon: 'success',
      });
      queryClient.invalidateQueries(['partnermasters']);
    } catch (err) {
      if (err.response) {
        setErrors(err.response.data);
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);
      swal({
        title: 'Warning!',
        text: error,
        icon: 'warning',
      });
      console.log(err);
    }
  };

  return (
    <div>
      <CallCenterStructure>
        <div className={styles.CompanyCataloguePartnerMaster}>
          <div className={styles.headerText}>
            <p> Company Catalogue Partner Master</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field1}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Select Partner Type</label>
                <Dropdown
                  onSelect={handlePartnerTypeID}
                  onSelectOption={handlePartnerType}
                  column={'name'}
                  retrievedValue={partnerTypeID}
                />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select Partner Category<sup>*</sup>
                </label>
                <Dropdown
                  onSelect={handlePartnerCategoryID}
                  onSelectOption={handlePartnerCategory}
                  column={'name'}
                  retrievedValue={partnerCategoryID}
                />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Enter Partner Name<sup>*</sup>
                </label>
                <input
                  value={partnerName}
                  type="text"
                  onChange={e => setPartnerName(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.fieldContainer2}>
              <label htmlFor="">Enter Partner Address</label>
              <textarea
                value={partnerAddress}
                id=""
                onChange={e => setPartnerAddress(e.target.value)}
              />
            </div>
            <div className={styles.field3}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select Country<sup>*</sup>
                </label>
                <Dropdown header={Headers()} api={ApiConfig.fetchCountry} onSelect={handleCountryID} onSelectOption={handleCountry} retrievedValue={countryID} column={'name'} key="country"/>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select Region<sup>*</sup>
                </label>
                <Dropdown header={Headers()} api={ApiConfig.fetchRegion(countryID)} onSelect={handleRegionID} onSelectOption={handleRegion} retrievedValue={regionID} column={'name'} key="region" />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select Zone<sup>*</sup>
                </label>
                <Dropdown header={Headers()} api={ApiConfig.fetchZone(regionID)} onSelect={handleZoneID} onSelectOption={handleZone} retrievedValue={zoneID} column={'name'} key="zone" />
              </div>
            </div>
            <div className={styles.field4}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Select State<sup>*</sup>
                </label>
                <Dropdown header={Headers()} api={ApiConfig.fetchState(zoneID)} onSelect={handleStateID} onSelectOption={handleState} retrievedValue={stateID} column={'name'} key="state" />
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Select City<sup>*</sup>
                </label>
                <Dropdown header={Headers()} api={ApiConfig.fetchCity(stateID)} onSelect={handleCityID} onSelectOption={handleCity} retrievedValue={cityID} column={'name'} key="city"/>
              </div>
            </div>
            <div className={styles.field3}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Enter Name Of Contact Person<sup>*</sup>
                </label>
                <input
                  value={contactPerson}
                  type="text"
                  onChange={e => setContactPerson(e.target.value)}
                />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Enter Phone Number<sup>*</sup>
                </label>
                <input
                  value={contactPhone}
                  type="text"
                  onChange={e => setContactPhone(e.target.value)}
                />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Enter Email Id<sup>*</sup>
                </label>
                <input
                  value={contactEmail}
                  type="text"
                  onChange={e => setContactEmail(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.field4}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Enter Website<sup>*</sup>
                </label>
                <input
                  value={websiteURL}
                  type="text"
                  onChange={e => setwebsiteURL(e.target.value)}
                />
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">Corporate Email Id</label>
                <div className={styles.radioButtonInnerContainer}>
                  <RadioButtonBlue
                    firstText="Yes"
                    secondText="No"
                    onChange={setcorporateEmailApplicable}
                  />
                </div>
              </div>
            </div>
            <div className={styles.itemInputContainer4}>
              <div className={styles.defaultImageContainer}>
                <div className={styles.imageContainer}>
                  {selectedImage ? (
                    <img src={selectedImage} alt="default" />
                  ) : (
                    <img src={defaultImage} alt="default" />
                  )}
                </div>
                <p>Image</p>
              </div>

              <div className={styles.uploadContainer}>
                <div className={styles.uploadInputContainer}>
                  <label htmlFor="">Photograph</label>
                  <ImageDragAndDrop
                    selectedImageName={selectedImageName}
                    setSelectedImageName={setSelectedImageName}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                  />
                </div>
              </div>
            </div>
            <div className={styles.field4}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Enter VAT<sup>*</sup>
                </label>
                <input
                  value={vat}
                  type="text"
                  onChange={e => setVat(e.target.value)}
                />
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Set Markup Percentage<sup>*</sup>
                </label>
                <input
                  value={markupPercent}
                  type="text"
                  onChange={e => setmarkupPercent(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.buttonContainer}>
              {isSubmit && (
                <button
                  className={styles.submit}
                  onClick={() => addPartnerMasterMutation()}>
                  Submit
                </button>
              )}
              {isUpdate && (
                <button
                  className={styles.submit}
                  onClick={() => updatePartnerMasterMutation()}>
                  Update
                </button>
              )}
              <button
                className={styles.reset}
                type="button"
                onClick={() => setClearModal(true)}>
                Reset
              </button>
            </div>
            <ErrorMessage errMsg={errors} />
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.searchInputContainer}>
              <div className={styles.searchInput}>
                <SearchInput onSearchInputChange={handleSearchInputChange} />
              </div>
              {/* <button className={styles.searchBTN}>search</button> */}
            </div>
            <div className={styles.tableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Partner ID</th>
                    <th>Partner Name</th>
                    <th>Type of Partner</th>
                    <th>Partner Address</th>
                    <th>Contact Person Name</th>
                    <th>Contact Person Phone Number</th>
                    <th>Contact Person Email</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    filteredData.map(each => (
                      <tr key={each.id}>
                        <td>{each.id}</td>
                        <td>{each.partner_name}</td>
                        <td>{each.partner_type_id}</td>
                        <td>{each.address}</td>
                        <td>{each.contact_person_name}</td>
                        <td>{each.contact_person_no}</td>
                        <td>{each.contact_person_email}</td>
                        <td>
                          <div className={styles.iconContainer}>
                            <span
                              className={styles.icon}
                              onClick={() => {
                                editPartnerMaster(each.id);
                                setUpdateID(each.id);
                              }}>
                              <ViewPen />
                            </span>

                            <span
                              className={styles.icon}
                              onClick={() => {
                                setDeleteModal(true);
                                setSelectedItemId(each.id);
                              }}>
                              <Times />
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colspan="8">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className={styles.pagination}>
              {/* <p>Pagination</p> */}
              <Paginate page={page} data={data} setPage={setPage} />
            </div>
          </div>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
          {deleteModal && (
            <MenuDeleteModal
              onClose={onClose}
              onDelete={() => deletePartnerMaster(selectedItemID)}
            />
          )}
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default CompanyCataloguePartnerMaster;
