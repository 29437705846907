import React, { useState, useEffect } from "react";
import Dropdown from "../DropDown/DropDown";
import ApiConfig from "../../service/ApiConfig";
import { HeadersOperator } from "../../service/Essentials";

import {
  ChevronDown,
  ChevronUp,
  CompanyMasterSetup,
  CustomerEnrolment,
  DashabordIcon,
  Hamburger,
  LogoutIcon,
  PhoneIcon,
  SearchIcon,
} from "../../assets/icons";
import wole from "../../assets/images/wole.jpeg";
import styles from "../../styles/NavBar.module.scss";
import loyaltyImg from "../../assets/images/loyaltyImg.png";
import fidelity from "../../assets/images/fidelity.png";

import logoSmall from "../../assets/images/PerxLogoSmall.png";
import { Link, useNavigate } from "react-router-dom";
import { getUser, removeSession } from "../../service/common";
import axiosProperty from "../../service/HttpConfig";

import { useQuery } from "react-query";



function Navbar({
  toggleIsNav,
  isNav,
  state,
  handleAllState,
  handleAllValues,
  dropwDownValue,
}) {
  const [isInputFocused, setInputFocused] = useState(false);
  const [companyType, setCompanyType] = useState("");
  const [companyTypeId, setCompanyTypeId] = useState(null);
  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleInputBlur = () => {
    setInputFocused(false);
  };

  const user = getUser();
  const [isOpen, setIsOpen] = useState(false);
  const [mobileSideDropdown, setMobileSideDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
const [page, setPage] = useState(1)
  const [isMenu, setIsMenu] = useState(false);
  const [isMobileNav, setIsMobileNav] = useState(false);
  const [getMenu, setGetMenu] = useState([])
  const [openId, setOpenId] = useState(null);
  const [oneId, setOneId] = useState(null);

  const handleCompanyTypeId = (id) => {

    setCompanyTypeId(id);
    if(id !== undefined){

      sessionStorage.setItem('companyTypeId',JSON.stringify(id))
    }
  };
  const handleCompanyType = (name) => {
    setCompanyType(name);
  };

  // const [dropwDownValue, setDropwDownValue] = useState({
  //   value1: false,
  //   value2: false,
  // });

  // const handleAllValues = key => {
  //   localStorage.setItem('values', key);
  //   setDropwDownValue(prev => ({
  //     ...prev,
  //     value1: false,
  //     value2: false,
  //     [key]: true,
  //   }));
  // };

  // const mainValue = localStorage.getItem('values');
  // useEffect(() => {
  //   handleAllValues(mainValue);
  // }, [mainValue]);

  const getNavId = JSON.parse(sessionStorage.getItem("navId"));
  const getChildId = JSON.parse(sessionStorage.getItem("childId"));

  useEffect(() => {
    setOpenId(getNavId);
    setOneId(getChildId);
  }, [getNavId, getChildId]);

  const toggleDropdown = (index) => {
    if (getNavId === null) {
      setOpenId(index);
    } else {
      setOpenId((prevIndex) => {
        if (prevIndex === getNavId) {
          return null;
        } else if (prevIndex !== getNavId) {
          return index;
        }
      });
    }
  };

  const toggleMobileSideDropdown = () => {
    setMobileSideDropdown(!mobileSideDropdown);
  };

  const handleToggle = () => {
    setIsMobileNav(!isMobileNav);
  };

  const toggleDropdownMenu = () => {
    setIsMenu(!isMenu);
    setIsOpen(false);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const company = [
    { image: loyaltyImg, id: 1, name: "Loyalty" },
    { image: fidelity, id: 2, name: "Fidelity Bank Rewards" },
  ];

  const navigate = useNavigate();

  const handleLogout = () => {
    removeSession();
    sessionStorage.clear('navId')
    sessionStorage.clear('childId')
    navigate("/");
  };
const handleMenu = async (page) => {
  const res = await axiosProperty.get(ApiConfig.fetchAssignMenu(page), {
    headers: HeadersOperator(),
  });
  return res.data;
}

const { data: allData, status } = useQuery(
  ["allmenu", page],
  () => handleMenu(page),
  { keepPreviousData: true }
);

useEffect(() => {
  setGetMenu(allData?.data || []);
}, [allData]);


  return (
    <>
      <div
        className={
          isNav ? styles.navbarContainerAdjust : styles.navbarContainer
        }
      >
        <ul className={styles.navbarInnerContainer}>
          <li>
            <span className={styles.hamburger} onClick={toggleIsNav}>
              <Hamburger />
            </span>
          </li>
          <li className={styles.searchBarContainer}>
            <div className={styles.searchField}>
              <div className={styles.searchIconContainer}>
                <SearchIcon />
              </div>
              <input
                type="text"
                className={styles.searchInput}
                placeholder="Insert your text here"
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
              />
            </div>
            {isInputFocused && (
              <div className={styles.searchContent}>
                Search Contents Goes Here
              </div>
            )}
          </li>
          <li>
            <div className={styles.dropdown}>
              <div className={styles.dropdownHeader} onClick={toggleDropdown}>
                {selectedOption ? (
                  <div className={styles.selectedCompany}>
                    <img src={selectedOption.image} alt={selectedOption.name} />
                    <div className={styles.companyName}>
                      <p className={styles.selectedOption}>
                        {selectedOption.name}
                      </p>
                      <p className={styles.selectOtherCompany}>
                        Select Other company
                      </p>
                    </div>
                  </div>
                ) : (
                  "Select Company"
                )}
              </div>
              {isOpen && 
                <ul className={styles.dropdownOptions} style={{position:'absolute', top: 0}}>
                 <Dropdown
                  header={HeadersOperator()}
                  api={ApiConfig.fetchCompanyType}
                  onSelect={handleCompanyTypeId}
                  onSelectOption={handleCompanyType}
                  column={"company_name"}
                  retrievedValue={companyType}
                  key="company_name"
                  idValue="company_type_id"
                />
                </ul>
            }
            </div>
          </li>
          <li>
            <div className={styles.dropdownMenu} onClick={toggleDropdownMenu}>
              <div className={styles.profilePictureContainer}>
                <div className={styles.profilePicture}>
                  <img src={wole} alt="profile pic" />
                </div>
                <p>Hi, {user.name.split(" ")[0]}</p>
              </div>
            </div>
            {isMenu && (
              <ul className={styles.dropdownMenus}>
                <li onClick={() => setIsMenu(false)}>
                  <div className={styles.menuProperty}>
                    <LogoutIcon />
                    {/* <img src={loyaltyImg} alt="log out" /> */}
                    <p onClick={handleLogout}>Logout</p>
                  </div>
                </li>
                <li onClick={() => setIsMenu(false)}>
                  <div className={styles.menuProperty}>
                    <PhoneIcon />
                    {/* <img src={loyaltyImg} alt="log out" /> */}
                    <p>Contact Us</p>
                  </div>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>

      <div className={styles.mobileNavContainer}>
        <div className={styles.logoContainerSmall}>
          <img src={logoSmall} alt="logo" />
        </div>
        <div className={styles.rightMobileNav}>
          <div className={styles.dropdown}>
            <div className={styles.dropdownHeader} onClick={toggleDropdown}>
              {selectedOption ? (
                <div className={styles.selectedCompany}>
                  <img src={selectedOption.image} alt={selectedOption.name} />
                  <div className={styles.companyName}>
                    <p className={styles.selectedOption}>
                      {selectedOption.name}
                    </p>
                    <p className={styles.selectOtherCompany}>
                      Select Other company
                    </p>
                  </div>
                </div>
              ) : (
                "Select Company"
              )}
            </div>
            {isOpen && (
              <ul className={styles.dropdownOptions}>
                {company.map((each) => {
                  return (
                    <li
                      onClick={() => handleOptionClick(each)}
                      key={each.id}
                      className={styles.companyLi}
                    >
                      <img src={each.image} alt={each.name} />
                      <p>{each.name}</p>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
          <div className={styles.profilePicture}>
            <img src={wole} alt="profile pic" />
          </div>
          <div>
            <span onClick={handleToggle} className={styles.hamburger}>
              <Hamburger />
            </span>
          </div>
        </div>
        <ul
          className={`${styles.sidebarMenu} ${isMobileNav ? styles.open : ""}`}
        >
          <li
            className={styles.menuItem1}
            onClick={() => [handleAllState("dashboard"), setIsOpen(false)]}
          >
            <DashabordIcon state={state} />
            <div className={styles.sideNav}>
              <Link to="/">
                <p
                  className={
                    state.dashboard ? styles.activeRoute : styles.unactiveRoute
                  }
                >
                  Dashboard
                </p>
              </Link>
            </div>
          </li>
          {getMenu?.map(each=>{
            return <li className={styles.menuItem} key={each.id}>
            <div className={styles.menuItemAdjust}>
              <div
                className={styles.menuItemDropDown}
                onClick={() => [
                  handleAllState(each.name),
                  // setDropDownText(true),
                ]}
              >
                {/* <PhoneIcon state={state} /> */}
            <CompanyMasterSetup state={state} />

                <div
                  className={styles.sideDropdownHeader}
                  onClick={()=>{
                    sessionStorage.setItem(
                      "navId",
                      JSON.stringify(each.id)
                    );
                    toggleDropdown(each.id)
                  }}
                >
                  <div className={styles.sideNavDropDown}>
                    <div className={styles.sideNavDropDownInner}>
                      <p
                        className={
                          openId === each.id
                            ? styles.activeRoute
                            : styles.unactiveRoute
                        }
                      >
                       {each.name}
                      </p>

                      {openId === each.id ? <ChevronUp /> : <ChevronDown />}
                    </div>
                  </div>
                </div>
              </div>
              {openId === each.id && (
                <ul className={styles.sidebarDropdownOptions}>
                  {each.children?.map((one)=>{
                    return <li key={one.id} onClick={()=>{
                      sessionStorage.setItem(
                        "childId",
                        JSON.stringify(one.id)
                      );
                      setOneId(one.id)}}>
                    <Link to={`/${one.href}`}>
                      <p
                        className={
                          oneId === one.id
                            ? styles.dropdownTextActive
                            : styles.dropdownText
                        }
                      >
                        {one.name}
                      </p>
                    </Link>
                  </li>
                  })}
                  
                </ul>
              )}
            </div>
          </li>
          })}
          {/* <li
            className={styles.menuItem}
            onClick={() => [handleAllState("masterSetup"), setIsOpen(false)]}
          >
            <CompanyMasterSetup state={state} />
            <div className={styles.sideNav}>
              <div>
                <p
                  className={
                    state.masterSetup
                      ? styles.activeRoute
                      : styles.unactiveRoute
                  }
                >
                  Company Master Setup
                </p>
              </div>
            </div>
          </li>
          <li
            className={styles.menuItem}
            onClick={() => [handleAllState("enrolment"), setIsOpen(false)]}
          >
            <CustomerEnrolment state={state} />
            <div className={styles.sideNav}>
              <div>
                <p
                  className={
                    state.enrolment ? styles.activeRoute : styles.unactiveRoute
                  }
                >
                  Customer Enrolment
                </p>
              </div>
            </div>
          </li> */}
        </ul>
      </div>
    </>
  );
}

export default Navbar;
