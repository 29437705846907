import React from 'react';
import styles from '../../styles/SecurityQuestion.module.scss';
import PerxLogo from '../../assets/images/PerxBig.png';
import Dropdown from '../../component/DropDown/DropDown';

const SecurityQuestion = () => {
  return (
    <div className={styles.securityQuestionContainer}>
      <div className={styles.securityQuestionInnerContainer}>
        <div className={styles.perxLogo}>
          <img src={PerxLogo} alt="perx logo" />
        </div>
        <div className={styles.formContainer}>
          <h6>Security Questions</h6>
          <p className={styles.securityQuestionInfo}>
            Select 2 security questions below and provide their answers. These
            questions will help us verify your identity should you forget your
            pin
          </p>
          <div className={styles.formMainContainer}>
            <label htmlFor="">Question 1</label>
            <Dropdown />
          </div>
          <div className={styles.formMainContainer1}>
            <label htmlFor="">Answer 1</label>
            <input type="text" name="" id="" />
          </div>
          <div className={styles.formMainContainer1}>
            <label htmlFor="">Question 2</label>
            <Dropdown />
          </div>
          <div className={styles.formMainContaine2}>
            <label htmlFor="">Answer 2</label>
            <input type="text" name="" id="" />
          </div>
          <button className={styles.sendBTN}>Save & Continue</button>
        </div>
      </div>
    </div>
  );
};

export default SecurityQuestion;
