import React from 'react';
import styles from '../../styles/AuctionSweepstakeWinner.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';

const AuctionSweepstakeWinner = () => {
  const data = [
    {
      detail: '28/6/2023',
      number: '28/6/2023',
      level: 'Auction',
      prize: '54663',
      name: 'description',
      winner: '54663',
      //status: 'approved',
      //redraw: 'redraw',
    },
    {
      detail: '28/6/2023',
      number: '28/6/2023',
      level: 'Auction',
      prize: '54663',
      name: 'description',
      winner: '54663',
     // status: 'approved',
     // redraw: 'redraw',
    },
    {
      detail: '28/6/2023',
      number: '28/6/2023',
      level: 'Auction',
      prize: '54663',
      name: 'description',
      winner: '54663',
     // status: 'approved',
     // redraw: 'redraw',
    },
    {
      detail: '28/6/2023',
      number: '28/6/2023',
      level: 'Auction',
      prize: '54663',
      name: 'description',
      winner: '54663',
      //status: 'approved',
      //redraw: 'redraw',
    },
  ];

  return (
    <CallCenterStructure>
      <div className={styles.auctionMaster}>
        <div className={styles.headerText}>Auction Sweepstake Winner</div>
        <div className={styles.noteText}>
          <p>Click on column header for Ascending/Descending</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <h6 className={styles.infoText}>
              Sweepstake Winners to be Approved
            </h6>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Details</th>
                  <th>Ticket Number</th>
                  <th>Prize Level</th>
                  <th>Prize</th>
                  <th>Sweepstake Name</th>
                  <th>Winner</th>
                  <th>Approve/Unapprove</th>
                  <th>Redraw</th>
                </tr>
              </thead>
              <tbody>
                {data.map(each => (
                  <tr>
                    <td>{each.detail}</td>
                    <td>{each.number}</td>
                    <td>{each.level}</td>
                    <td>{each.prize}</td>
                    <td>{each.name}</td>
                    <td>{each.winner}</td>
                    <td>
                      <span class="text-success">Approve</span>&nbsp;
                      <span class="text-danger">Disapprove</span>
                    </td>
                    <td>Redraw</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <p>Pagination</p>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <h6 className={styles.infoText}>Approved Sweepstake Winners</h6>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Details</th>
                  <th>Ticket Number</th>
                  <th>Prize Level</th>
                  <th>Prize</th>
                  <th>Sweepstake Name</th>
                  <th>Winner</th>
                  <th>Approve/Unapprove</th>
                  {/* <th>Redraw</th> */}
                </tr>
              </thead>
              <tbody>
                {data.map(each => (
                  <tr>
                    <td>{each.detail}</td>
                    <td>{each.number}</td>
                    <td>{each.level}</td>
                    <td>{each.prize}</td>
                    <td>{each.name}</td>
                    <td>{each.winner}</td>
                    <td>{each.status}</td>
                    {/* <td>{each.redraw}</td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <p>Pagination</p>
          </div>
        </div>
      </div>
    </CallCenterStructure>
  );
};

export default AuctionSweepstakeWinner;
