import React from 'react';

const RadioButtonSingle = ({
  name,
  value,
  selected,
  borderWidth,
  borderHeight,
  onChange,
}) => {
  const handleClick = () => {
    if (!selected && onChange) {
      onChange(value);
    }
  };

  //   const borderWidthSize = 20 + borderWidth * 2 + (selected ? 6 : 0);
  //   const borderHeightSize = 20 + borderHeight * 2 + (selected ? 6 : 0);

  const buttonSize = 24;

  const borderWidthSize = buttonSize - 4 + borderWidth * 2 + (selected ? 6 : 0);
  const borderHeightSize =
    buttonSize - 4 + borderHeight * 2 + (selected ? 6 : 0);

  return (
    <div
      style={{
        border: selected ? '8px solid #00BCE1' : '2px solid #00BCE1',
        padding: '2px',
        borderRadius: '50%',
        marginRight: '3px',
        display: 'inline-block',
        width: `${borderWidthSize}px`,
        height: `${borderHeightSize}px`,
        boxSizing: 'border-box',
      }}
      onClick={handleClick}>
      <input
        className="form-control"
        type="radio"
        name={name}
        value={value}
        checked={selected}
        onChange={onChange}
        style={{display: 'none'}}
      />
    </div>
  );
};

export default RadioButtonSingle;
