import React from 'react';
import styles from '../../styles/Profile.module.scss';
import styles1 from '../../styles/FamilyCard.module.scss';

import CartStructure from '../CartStructure/CartStructure';
import familyCard from '../../assets/images/FamilyCard.png';
import {Link, useLocation} from 'react-router-dom';

const FamilyCard = () => {
  return (
    <CartStructure>
      <div>
        <div className={styles.profileNavOuterContainer}>
          <div className={styles.profileNav}>
            <div>
              <Link
                className={styles1.unActiveLink}
                to="/call-center/member-profile">
                Profile
              </Link>
            </div>
            <div>
              <Link
                className={styles1.unActiveLink}
                to="/call-center/redeem-catalogue">
                Redeem Catalogue
              </Link>
            </div>
            <div>
              <Link
                className={styles1.unActiveLink}
                to="/call-center/transaction-details">
                Transaction details
              </Link>
            </div>
            <div>
              <Link
                className={styles1.unActiveLink}
                to="/call-center/member-query-record">
                Member Query Record
              </Link>
            </div>
            <div>
              <Link
                className={styles1.unActiveLink}
                to="/call-center/log-query">
                Log Query
              </Link>
            </div>
            <div>
              <Link
                className={`${styles.currentNav} ${styles.linkstyles}`}
                to="/call-center/add-family-card">
                Add Family Card
              </Link>
            </div>
          </div>
        </div>
        <div className={styles1.familyCardMainContainer}>
          <p>Add Family Card</p>
          <div className={styles1.familyCardContainer}>
            <div className={styles1.familyCardInnerContainer}>
              <img src={familyCard} alt="family card" />
              <p>
                Currently, Company does not allow Family Member to be Enrolled
              </p>
            </div>
          </div>
        </div>
      </div>
    </CartStructure>
  );
};

export default FamilyCard;
