import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import DownloadButtonPdf from '../../component/DownloadButtonPdf/DownloadButtonPdf';
import DownloadButtonXlsx from '../../component/DownloadButtonXlsx/DownloadButtonXlsx';
import RadioButtonSingle from '../../component/RadioButtonSingle/RadioButtonSingle';

const MemberDeliveryOrderStatusReport = ()=>{
    const [selectedOption, setSelectedOption] = useState(null);

    const handleRadioButtonChange = value => {
      setSelectedOption(value);
    };
  const data = [
    {
    receipt: '#75588', membId: '67', transDate:'23/06/2023', membName:'Kenneth', 
    billAmount:5000, redeemAmount:8000, balPaid:16000, pointsGained:8
    }
  ];
    return(
    <>
        <CallCenterStructure>
        <div className='mt-5 p-4'>
           <h4 className='pb-4'>Member Delivery Order Status Report</h4>
           <main className='border border-light p-3'>
           <form action="">
               <div className="row">
               <div className="form-group col-12 col-md-6" >
               <label className='d-flex justify-content-between' htmlFor="fromDate">From Date *</label>
                   <input className="w-100" type="text" placeholder='From date' />
               </div>
               <div className="form-group col-12 col-md-6" >
               <label className='d-flex justify-content-between' htmlFor="tillDate">Till Date *</label>
                   <input className="w-100" type="text" placeholder='Till date' />
               </div>
               </div>

               <div className="row">
               <div className="form-group col-12 col-md-4" >
                   <label htmlFor="selectPartner">Select Partner *</label>
                   <Dropdown/>
               </div>
               <div className="form-group col-12 col-md-4" >
                <p><label htmlFor="selectRedemptionMethod">Redemption Method *</label></p>
                <div className="d-flex">
                 <RadioButtonSingle
                        name="redemptionMethod"
                        value="yes"
                        selected={selectedOption === 'yes'}
                        borderWidth={2}
                        borderHeight={2}
                        onChange={handleRadioButtonChange}
                   />Delivered
                </div>
               </div>
               <div className="form-group col-12 col-md-4" >
                   <label htmlFor="selectRedemptionStatus">Redemption Status *</label>
                   <Dropdown/>
               </div>
               </div>

               <div className='form-group d-flex justify-content-between d-md-flex justify-content-md-start'>
                   <button className='mr-5'>Submit</button>
                </div>

               <div className="row">
               <div className="form-group col-12 col-md-10" >
               <label htmlFor="orderNumber">Order Number *</label>
               <input className="w-100" type="text" placeholder='Till date' />
               </div>
               <div className="form-group col-12 col-md-2" >
                  <button className='mt-4 w-100'>Click Here</button>
               </div>
               </div>
           </form>
           </main>
           <div className='mt-4 d-flex justify-content-between d-md-flex justify-content-md-start'>
           <DownloadButtonPdf />
           <DownloadButtonXlsx />
           </div>
          
           <section className="border border-light mt-4 mb-4">
                <div className="row p-4">
                <div className="col-12 table-responsive">
                <table className='table'>
                    <thead>
                        <tr>
                        <th className=''>Transaction Date</th>
                        <th className=''>Member Name</th>
                        <th className=''>Membership ID</th>
                        <th className=''>Merchandize Item</th>
                        <th className=''>Quantity</th>
                        <th className=''>Redeem Points</th>
                        <th className=''>Voucher No.</th>
                        <th className=''>Order No.</th>
                        <th className=''>Order Status</th>
                        <th className=''>Last Order Status Update Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((each, index) =>(
                             <tr key={index}>
                                <td>{each.receipt}</td>
                                <td>{each.transDate}</td>
                                <td>{each.membId}</td>
                                <td>{each.membName}</td>
                                <td>{each.billAmount}</td>
                                <td>{each.redeemAmount}</td>
                                <td>{each.balPaid}</td>
                                <td>{each.pointsGained}</td>
                                <td>{each.pointsGained}</td>
                                <td>{each.pointsGained}</td>
                             </tr>
                            ))
                        }
                    </tbody>
                </table>
                </div>
                <div className="col-12">Pagination</div>
                </div>
           </section>
           
        </div>
       </CallCenterStructure>
    </>
    );
}
export default MemberDeliveryOrderStatusReport;