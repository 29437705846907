import React, {useState, useEffect} from 'react';
import SearchInput from '../../component/SearchInput/SearchInput';
import {Times, ViewPen} from '../../assets/icons';
import styles from '../../styles/PointsForfeitureMaster.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import { Headers } from '../../service/Essentials';
import swal from 'sweetalert';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';

const PointsForfeitureMaster = () => {
  const [clearModal, setClearModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [productGroupID, setProductGroupID] = useState();
  const [productGroup, setProductGroup] = useState();
  const [productBrand, setProductBrand] = useState();
  const [productCode, setProductCode] = useState();
  const [productCodeID, setProductCodeID] = useState();
  const [productBrandID, setProductBrandID] = useState();
  const [noOfDaysInactivity, setNoOfDaysInactivity] = useState();
  const [forfeitPercent, setForfeitPercent] = useState();
  const [updateID, setUpdateID] = useState('');
  const [selectedItemID, setSelectedItemId] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [error, setError] = useState();
  const [errors, setErrors] = useState(null);
  const queryClient = useQueryClient();

  const onClose = () => {
    setDeleteModal(false);
  };

  const handleProductGroupID = id => {
    setProductGroupID(id);
  };

  const handleProductGroup = name => {
    setProductGroup(name);
  };

  const handleProductBrandID = id => {
    setProductBrandID(id);
  };
  const handleProductBrand = name => {
    setProductBrand(name);
  };

  const handleProductCodeID = (id)=>{
    setProductCodeID(id);
  }
  const handleProductCode = (name)=>{
    setProductCode(name);
  }

  const formData = {
    'product_group_id':productGroupID,
    'product_brand_id':productBrandID,
    'product_brand_id':productBrandID,
    'no_of_days_for_inactivity':noOfDaysInactivity,
    'forfeit_percentage':forfeitPercent,
  }

 // RedirectToLogin();

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const fetchpointforfeitures = async page => {
    const res = await axiosProperty.get(
      ApiConfig.fetchPointforfeiturerule + `?page=${page}`,
      {
        headers: Headers(),
      },
    );
    return res.data;
  };
  const {data, status} = useQuery(
    ['pointforfeitures', page],
    () => fetchpointforfeitures(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = searchTerm => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data) {
      const filtered = data.data.filter(
        (item) =>
          item.forfeit_percentage.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  const addPointForfeiture = async () => {
    try{
        await axiosProperty.post(ApiConfig.createPointforfeiturerule, formData, 
        {
          headers:Headers(),
      }
        );
      setErrors(null);
      setProductGroupID('');
      setProductBrandID('');
      setNoOfDaysInactivity('');
      setForfeitPercent('');
      setProductCodeID('');
      setProductCode('');

      swal({
        title: "Success!",
        text: "Point forfeiture created successfully",
        icon: "success",
      });
    }catch(err){
      if(err.response){
       setError(err.response.data.message);
       setErrors(err.response.data);
       swal({
        title: "Error",
        text: error,
        icon: "error",
      });
      }else{
      setError('Something went wrong, try again'); 
    //  console.log(err);
      swal({
        title: "Error!",
        text: error,
        icon:'error'
      });
      }
    }
  };

  const {mutate: addPointForfeitureMutation} = useMutation({
    mutationFn: addPointForfeiture,
    onSuccess: () => {
      queryClient.invalidateQueries(['pointforfeitures']);
    },
  });

  const updatePointForfeiture = async () => {
    try {
      await axiosProperty.patch(
        ApiConfig.updatePointforfeiturerule + `/${updateID}`,
        formData,
        {
          headers:Headers(),
        }
        );
      setErrors(null);
      setProductGroupID('');
      setProductBrandID('');
      setNoOfDaysInactivity('');
      setForfeitPercent('');
      setProductCodeID('');
      setProductCode('');
      setIsSubmit(true);
      setIsUpdate(false);
        swal({
          title: "Success!",
          text: "Point Forfeiture updated successfully",
          icon: "success",
        });   
    }catch(err){
      if(err.response){
        setError(err.response.data.message);
        setErrors(err.response.data);
        swal({
          title: "Error",
          text: error,
          icon: "error",
        });   
      }else{
       setError('Something went wrong, try again'); 
       swal({
        title: "Error",
        text: error,
        icon: "error",
      });  
      }
    }
  };

  const {mutate:updatePointForfeitureMutation} = useMutation(
    {
      mutationFn: updatePointForfeiture,
      onSuccess:()=>{queryClient.invalidateQueries(['pointforfeitures'])}, 
    }
    );

  const editPointForfeiture = async (id) => {
    try{
       const res = await axiosProperty.get(ApiConfig.editPointforfeiturerule+`/${id}`,
        {
          headers:Headers(),
      }
      );
  setIsUpdate(true); 
  setIsSubmit(false); 

  setProductGroupID(res.data.data.product_group_id || '');
  setProductBrandID(res.data.data.product_brand_id || '');
  setNoOfDaysInactivity(res.data.data.no_of_days_for_inactivity || '');
  setForfeitPercent(res.data.data.forfeit_percentage || '');
    }catch(err){
      if(err.response){
        setError(err.response.data.message);
      swal({
        title: "Warning!",
        text: error,
        icon: "warning",
      });
      }else{
       setError('Something went wrong, try again'); 
      swal({
        title: "Warning!",
        text: error,
        icon: "warning",
      });
      }
    }
  };
  const deletePointForfeiture = async (id) => {
     const res = await axiosProperty.delete(ApiConfig.deletePointforfeiturerule+`/${id}`,
      {
        headers: Headers(),
      },
    );
    setDeleteModal(false);
    swal({
      title: 'Success!',
      text: 'Deleted successfully',
      icon: 'success',
    });
    queryClient.invalidateQueries(['pointforfeitures']);
  };

  return (
    <div>
      <CallCenterStructure>
        <div className={styles.PointsForfeitureMaster}>
          <div className={styles.headerText}>
            <p>Points Forfeiture Master</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field1}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select Product Group<sup>*</sup>
                </label>
                <Dropdown header={Headers()} api={ApiConfig.fetchProductGroup} onSelect={handleProductGroupID} onSelectOption={handleProductGroup} retrievedValue={productGroupID} column={'group_name'} key="productgroup"/>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select Product Brand<sup>*</sup>
                </label>
                <Dropdown header={Headers()} api={ApiConfig.fetchProductBrand} onSelect={handleProductBrandID} onSelectOption={handleProductBrand} retrievedValue={productBrandID} column={'brand_name'} key="brandgroup"/>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Select Product<sup>*</sup>
                </label>
                <Dropdown header={Headers()} api={ApiConfig.fetchProductCode} onSelect={handleProductCodeID} onSelectOption={handleProductCode} retrievedValue={productCodeID} column={'product_code'} key="productcode"/>
              </div>
            </div>
            <div className={styles.field5}>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Enter No.of days for In-Activity<sup>*</sup>
                </label>
                <input
                  value={noOfDaysInactivity}
                  type="text"
                  onChange={e => setNoOfDaysInactivity(e.target.value)}
                />
              </div>
              <div className={styles.fieldContainer4}>
                <label htmlFor="">
                  Enter Forfeit in %<sup>*</sup>
                </label>
                <input
                  value={forfeitPercent}
                  type="text"
                  onChange={e => setForfeitPercent(e.target.value)}
                />
              </div>
            </div>

            <div className={styles.buttonContainer}>
              {isSubmit && (
                <button
                  className={styles.submit}
                  onClick={() => addPointForfeitureMutation()}>
                  Submit
                </button>
              )}
              {isUpdate && (
                <button
                  className={styles.submit}
                  onClick={() => updatePointForfeitureMutation()}>
                  Update
                </button>
              )}
              <button
                className={styles.reset}
                type="button"
                onClick={() => setClearModal(true)}>
                Reset
              </button>
            </div>
            <ErrorMessage errMsg={errors} />
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.searchInputContainer}>
              <div className={styles.searchInput}>
                <SearchInput />
              </div>
              <button className={styles.searchBTN}>search</button>
            </div>
            <div className={styles.tableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Product Group</th>
                    <th>Product Brand</th>
                    {/* <th>Item Code</th> */}
                    <th>No. of days for In-Activity</th>
                    <th>Forfeit in %</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    filteredData.map(each => (
                      <tr key={each.id}>
                        <td>{each.product_group_id}</td>
                        <td>{each.product_brand_id}</td>
                        <td>{each.no_of_days_for_inactivity}</td>
                        <td>{each.forfeit_percentage}</td>
                        <td>
                          <div className={styles.iconContainer}>
                            <span
                              className={styles.icon}
                              onClick={() => {
                                editPointForfeiture(each.id);
                                setUpdateID(each.id);
                              }}>
                              <ViewPen />
                            </span>

                            <span
                              className={styles.icon}
                              onClick={() => {
                                setDeleteModal(true);
                                setSelectedItemId(each.id);
                              }}>
                              <Times />
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colspan="5">No data available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className={styles.pagination}>
              {/* <p>Pagination</p> */}
              <Paginate page={page} data={data} setPage={setPage} />
            </div>
          </div>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
          {deleteModal && (
            <MenuDeleteModal
              onClose={onClose}
              onDelete={() => deletePointForfeiture(selectedItemID)}
            />
          )}
        </div>
      </CallCenterStructure>
    </div>
  );
};
export default PointsForfeitureMaster;
