import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/CustomerEnrolmentFileUpload.module.scss';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import Dropdown from '../../component/DropDown/DropDown';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
import defaultImage from '../../assets/images/defaultImage.png';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CustomerEnrolmentFileUpload = () => {
  const [clearModal, setClearModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');

  return (
    <CallCenterStructure>
      <div className={styles.customerEnrolmentFileUpload}>
        <div className={styles.headerText}>
          <p>Upload Flat File for Enrollment</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.fieldInnerContainer1}>
              <label htmlFor="">
                Upload Date<sup>*</sup>
              </label>
              <input type="date" />
            </div>
            <div className={styles.fieldInnerContainer1}>
              <label htmlFor="">
                Select User<sup>*</sup>
              </label>
              <RadioButtonBlue firstText="Customer" secondText="Employee" />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.fieldInnerContainer1}>
              <label htmlFor="">
                Select Customer Loyalty Program<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.fieldInnerContainer1}>
              <label htmlFor="">
                Select data Mapping Filename<sup>*</sup>
              </label>
              <Dropdown />
            </div>
          </div>
          <div className={styles.itemInputContainer4}>
            <div className={styles.defaultImageContainer}>
              <div className={styles.imageContainer}>
                {selectedImage ? (
                  <img src={selectedImage} alt="default" />
                ) : (
                  <img src={defaultImage} alt="default" />
                )}
              </div>
              <p>Logo</p>
            </div>

            <div className={styles.uploadContainer}>
              <div className={styles.uploadInputContainer}>
                <label htmlFor="">Upload</label>
                <ImageDragAndDrop
                  selectedImageName={selectedImageName}
                  setSelectedImageName={setSelectedImageName}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Submit</button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
      </div>
    </CallCenterStructure>
  );
};

export default CustomerEnrolmentFileUpload;
