import React from 'react';
import {
  Account,
  Address,
  ArrowRight,
  Auction,
  Order,
  Pin,
  Statement,
  Tier,
  Transfer,
} from '../../assets/icons';
import Category from '../../component/Category/Category';
import TemplateFooter from '../../component/TemplateFooter/TemplateFooter';
import TemplateNavbar from '../../component/TemplateNavbar/TemplateNavbar';
import styles from '../../styles/AccountDashboard.module.scss';

const AccountDashboard = () => {
  const navs = [
    {name: 'Account Dashboard', icon: <Account />},
    {name: 'Tier Status', icon: <Tier />},
    {name: 'Statement', icon: <Statement />},
    {name: 'Order History', icon: <Order />},
    {name: 'Auction Bid History', icon: <Auction />},
    {name: 'Transfer Points', icon: <Transfer />},
    {name: 'Delivery Addresses', icon: <Address />},
    {name: 'Change Password', icon: <Transfer />},
    {name: 'Change Pin', icon: <Pin />},
  ];

  return (
    <div className={styles.accountDashboardContainer}>
      <TemplateNavbar />
      <Category />
      <div className={styles.mobileItemNav}>
        <ArrowRight />
        <div className={styles.mobileNavText}>
          <p>Account Dashboard</p>
        </div>
      </div>
      <div className={styles.accountDashboard}>
        <div className={styles.leftDashboard}>
          <div className={styles.leftTopContent}>
            <h6>Welcome</h6>
            <p>8711128</p>
          </div>
          <ul className={styles.itemUl}>
            {navs.map(each => (
              <li key={each.name} className={styles.itemList}>
                <div className={styles.eachItem}>
                  {each.icon}
                  <p>{each.name}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.rightDashboard}>
          <div className={styles.dashboardText}>
            <p>Dashboard</p>
          </div>
          <div className={styles.accountDashboardScreen}>
            <div className={styles.accountScreen}>
              <div className={styles.accountInnerScreen}>
                <p>Total Current Balance</p>
                <h6>134, 567</h6>
              </div>
              <div className={styles.accountInnerScreen}>
                <p>Total Gained Points</p>
                <h6>134, 567</h6>
              </div>
              <div className={styles.accountInnerScreen}>
                <p>Total Redeemed Points</p>
                <h6>134, 567</h6>
              </div>
            </div>
          </div>
          <div className={styles.defaultAddressContainer}>
            <h6>Default Address</h6>
            <div className={styles.defaultAddressInnerContainer}>
              <p>Atibioke Akinlolu</p>
              <p>99A Beckingson Street, Ajao Estate Lagos</p>
              <p>+234-814-6819-263</p>
            </div>
          </div>
          <div className={styles.optoutContainer}>
            <div className={styles.optoutInnerContainer}>
              <p>
                If you would like to leave the rewards program, click the button
                below
              </p>
              <button className={styles.optoutBTN}>opt-out of program</button>
            </div>
          </div>
        </div>
      </div>

      <TemplateFooter />
    </div>
  );
};

export default AccountDashboard;
