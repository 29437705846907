import React from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import DownloadButtonPdf from '../../component/DownloadButtonPdf/DownloadButtonPdf';
import DownloadButtonXlsx from '../../component/DownloadButtonXlsx/DownloadButtonXlsx';

const HotListedMemberReport = ()=>{

    return(
    <>
        <CallCenterStructure>
        <div className='mt-5 p-4'>
           <h4 className='pb-4'>Hot Listed Member Report</h4>
           <div className='mt-4 d-flex justify-content-between d-md-flex justify-content-md-start'>
           <DownloadButtonPdf />
          <DownloadButtonXlsx />
           </div>
          
           <section className="border border-light mt-4 mb-4">
                <div className="row p-4">
                <div className="col-12 table-responsive">
                <table className='table'>
                    <thead>
                        <tr>
                        <th className=''>User Name</th>
                        <th className=''>User Type</th>
                        <th className=''>Membership ID</th>
                        <th className=''>Phone Number</th>
                        <th className=''>Email ID</th>
                        <th className=''>Enrolled Date</th>
                        <th className=''>Enrolled By</th>
                        <th className=''>Enrolled At</th>
                        <th className=''>Membership ID</th>
                        <th className=''>Hotlisted By</th>
                        </tr>
                    </thead>
                    <tbody>
                    
                    </tbody>
                </table>
                </div>
                <div className="col-12">Pagination</div>
                </div>
           </section>
           
        </div>
       </CallCenterStructure>
    </>
    );
}
export default HotListedMemberReport;