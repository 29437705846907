import React, {useState, useEffect} from 'react';
import {Mark, Times, ViewPen} from '../../assets/icons';
import {SearchIcon} from '../../assets/icons';
import SearchInput from '../../component/SearchInput/SearchInput';
import styles from '../../styles/PrivilegeMaster.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import {HeadersOperator} from '../../service/Essentials';
import ApiConfig from '../../service/ApiConfig';
import axiosProperty from '../../service/HttpConfig';
import swal from 'sweetalert';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import Paginate from '../../component/Paginate/Paginate';

const PrivilegeMaster = () => {
  const queryClient = useQueryClient();
  const [error, setError] = useState('oops... something went wrong');
  const [page, setPage] = useState(1);
  const [privilege, setPrivilege] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  // const [deleteId, setDeleteId] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const [deleteModal, setDeleteModal] = useState(false);
  const onClose = () => {
    setDeleteModal(false);
  };

  const [errors, setErrors] = useState({
    name: '',
    description: '',
  });

  const [formData, setFormData] = useState({
    name: '',
    description: '',
  });

  const handleSearch = event => {
    setSearchValue(event.target.value);
  };

  const handleChange = e => {
    const {name, value} = e.target;
    setFormData(prevData => ({...prevData, [name]: value}));
  };

  const addPrivilege = async () => {
    try {
      const res = await axiosProperty.post(
        ApiConfig.createPrivilege,
        formData,
        {
          headers: HeadersOperator(),
        },
      );

      setErrors({});

      swal({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };
  const {mutate: addPrivilegeMutation} = useMutation({
    mutationFn: addPrivilege,
  });

  const searchPrivilege = async name => {
    try {
      const res = await axiosProperty.post(
        ApiConfig.searchPrivilege(name),
        searchValue,
        {
          headers: HeadersOperator(),
        },
      );

      setErrors({});
      setPrivilege(res.data.data);

      swal({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const getPrivilege = async page => {
    const res = await axiosProperty.get(ApiConfig.fetchPrivilege(page), {
      headers: HeadersOperator(),
    });
    return res.data;
  };
  const {data: privilegeData, status} = useQuery(
    ['privilege', page],
    () => getPrivilege(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setPrivilege(privilegeData?.data?.data || []);
  }, [privilegeData]);
  console.log(privilege);

  const updatePrivilege = async () => {
    try {
      const res = await axiosProperty.patch(
        ApiConfig.updatePrivilege(updateID),
        formData,
        {
          headers: HeadersOperator(),
        },
      );

      setIsSubmit(true);
      setIsUpdate(false);

      setErrors({});
      swal({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: updatePrivilegeMutation} = useMutation({
    mutationFn: updatePrivilege,
    onSuccess: () => {
      queryClient.invalidateQueries(['privilege']);
    },
  });

  // const deletePrivilege = async (id) => {
  //   try {
  //     const res = await axiosProperty.delete(ApiConfig.deletePrivilege(id), {
  //       headers: HeadersOperator(),
  //     });
  //     setDeleteModal(false);
  //     swal({
  //       title: "Success!",
  //       text: res.data.message,
  //       icon: "success",
  //     });
  //     queryClient.invalidateQueries("privilege");
  //   } catch (err) {
  //     if (err.response) {
  //       setError(err.response.data.message);
  //     } else {
  //       setError("Something went wrong, try again");
  //     }
  //    ;
  //     swal({
  //       title: "Warning!",
  //       text: error,
  //       icon: "warning",
  //     });
  //   }
  // };

  return (
    <CallCenterStructure>
      <div className={styles.transactionTypeContainer}>
        <div>
          <h6 className={styles.headerText}>Privilege Master</h6>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.formContainer}>
            <div className={styles.typeCodeInputContainer}>
              <label htmlFor="">
                Enter Privilege Name<sup>*</sup>
              </label>
              <input
                type="text"
                value={formData.name}
                name="name"
                onChange={handleChange}
                // className={styles.transactionTypeInput}
              />
              {errors?.description && (
                <p className="text-danger">{errors?.description}</p>
              )}
            </div>
            <div className={styles.typeCodeInputContainer}>
              <label htmlFor="" className={styles.transactionTypeLabel}>
                Enter Privilege Description<sup>*</sup>
              </label>
              <input
                type="text"
                value={formData.description}
                name="description"
                onChange={handleChange}
                className={styles.transactionTypeInput}
              />
              {errors?.description && (
                <p className="text-danger">{errors?.description}</p>
              )}
            </div>
            {isSubmit && (
              <button
                className={styles.transactionTypeBtn}
                onClick={event => {
                  event.preventDefault();
                  addPrivilegeMutation();
                }}>
                Submit
              </button>
            )}
            {isUpdate && (
              <button
                className={styles.transactionTypeBtn}
                onClick={event => {
                  event.preventDefault();
                  updatePrivilegeMutation();
                }}>
                Update
              </button>
            )}
          </div>
          <p className={styles.transactionTypeNoteText}>
            *Note:Click on column header for Ascending/Descending
          </p>
        </div>
        <div className={styles.tableContainer}>
          <div className={styles.formContainer}>
            <div className={styles.searchInput}>
              <div className={styles.searchBarContainer}>
                <div className={styles.searchField}>
                  <div className={styles.searchIconContainer}>
                    <SearchIcon />
                  </div>
                  <input
                    type="text"
                    name="name"
                    value={searchValue}
                    className={styles.searchInput}
                    onChange={handleSearch}
                  />
                  {/* <SearchInput onSearchInputChange={()=>setSearchValue()}/> */}
                </div>
              </div>
            </div>
            <button
              className={styles.transactionTypeBtn}
              onClick={event => {
                event.preventDefault();
                searchPrivilege(searchValue);
              }}>
              Search
            </button>
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.dataTable}>
              <thead>
                <tr>
                  <th>Privilege ID</th>
                  <th>Privilege Name</th>
                  <th>Privilege Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {status === 'loading' && (
                  <tr>
                    <td className="text-info">loading...</td>
                  </tr>
                )}

                {status === 'success' &&
                  (privilege?.length === 0 ? (
                    <tr>
                      <td>No Data!</td>
                    </tr>
                  ) : (
                    privilege?.map(each => (
                      <tr key={each.id}>
                        <td>{each.id}</td>
                        <td>{each.name}</td>
                        <td>{each.description}</td>
                        <td>
                          <span
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                              setUpdateID(parseInt(each.id));
                              setIsSubmit(false);
                              setIsUpdate(true);
                              setSelectedItem(each);
                              setFormData({
                                name: each?.name,
                                description: each?.description,
                              });
                              window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth',
                              });
                            }}>
                            <ViewPen />
                          </span>{' '}
                        </td>
                      </tr>
                    ))
                  ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <Paginate page={page} data={privilegeData} setPage={setPage} />
          </div>
        </div>
      </div>
    </CallCenterStructure>
  );
};

export default PrivilegeMaster;
