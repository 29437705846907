import React, {useState, useEffect} from 'react';
import {ChevronDown, ChevronUp} from '../../assets/icons';
import styles from '../../styles/DropDown.module.scss';
import axiosProperty from '../../service/HttpConfig';
import {useQuery, useQueryClient} from 'react-query';
//import {getUser} from '../../service/common';
//import {getToken} from '../../service/common';
//import { CallCenterHeaders, CompanySurveyHeaders } from '../../service/Essentials';

const Dropdown = ({
  header,
  api,
  onSelect,
  onSelectOption,
  retrievedValue,
  column,
  idValue,
  enumType,
  disabled = false,
}) => {
  if (idValue) {
    var idColumn = idValue;
  } else {
    var idColumn = 'id';
  }

  const queryClient = useQueryClient();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  // const user = getUser();
  // const program_id = user?.user_group_id ?? '';
  const fetchoptionLists = async () => {
    try {
      const res = await axiosProperty.get(api, {
        headers: header,
        // headers: {
        //   Authorization: 'Bearer ' + getToken(),
        //   'Program-Id': program_id,
        //   Admin:CallCenterHeaders().admin,
        //   Token:CallCenterHeaders().token,
        //   Token:CompanySurveyHeaders().token,
        // },
      });

      if (res.data.data.data !== undefined) {
        return res.data.data.data;
      }
      if (res.data.data.regions !== undefined) {
        return res.data.data.regions;
      }
      if (res.data.data.zones !== undefined) {
        return res.data.data.zones;
      }
      if (res.data.data.states !== undefined) {
        return res.data.data.states;
      }
      if (res.data.data.cities !== undefined) {
        return res.data.data.cities;
      }
      if (res.data.data !== undefined) {
        return res.data.data;
      }
    } catch (err) {}
  };

  const {
    data: dataFromAPI,
    isError,
    isLoading,
  } = useQuery([`lists+${api}`], fetchoptionLists, {cacheTime: false});

  const localData = [
    {id: 1, name: 'Atext'},
    {id: 2, name: 'Btext'},
    {id: 3, name: 'Ctext'},
    {id: 4, name: 'Dtext'},
    {id: 5, name: 'Etext'},
    {id: 6, name: 'Ftext'},
    {id: 7, name: 'Gtext'},
    {id: 8, name: 'Htext'},
    {id: 9, name: 'Itext'},
    {id: 10, name: 'Jtext'},
  ];
  //  const localData = local;
  if (enumType?.length > 0) {
    var options = enumType;
  } else {
    options =
      isError || isLoading || dataFromAPI === undefined
        ? localData
        : dataFromAPI;
  }
  const isRetrievedNumber = Number(retrievedValue);
  if (isRetrievedNumber) {
    const setOption = (id, value, value2) => {
      const getOption = id => options.find(option => option[value2] === id);
      const result = getOption(id);
      if (result === undefined) {
        return;
      }
      return result[value];
    };
    var setValue = setOption(retrievedValue, column, idColumn);
  } else {
    setValue = retrievedValue;
  }

  // useEffect(() => {
  //   setSelectedOption(setValue);
  // }, [setValue, enumType]);

  // useEffect(() => {
  //   queryClient.invalidateQueries([`lists+${api}`]);
  // }, [key]);

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = option => {
    setSelectedOption(option[column]);
    onSelectOption(option[column]);
    setIsOpen(false);
    onSelect(option[idColumn]);
  };

  // console.log(selectedOption, 'selectedOption from dropdown');

  return (
    <div className={styles.dropdown}>
      <div
        className={
          disabled ? styles.dropdownToggleDisabled : styles.dropdownToggle
        }
        onClick={handleDropdownToggle}>
        {selectedOption ? (
          selectedOption
        ) : (
          <p className={styles.dropdownInitialText}>Select</p>
        )}

        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </div>
      {isOpen && (
        <ul className={styles.dropdownMenu}>
          {options?.map(option => (
            <li
              key={option.id}
              className={styles.dropdownItem}
              onClick={() => handleOptionSelect(option)}>
              {option[column]}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
