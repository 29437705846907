import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import DownloadButtonPdf from '../../component/DownloadButtonPdf/DownloadButtonPdf';
import DownloadButtonXlsx from '../../component/DownloadButtonXlsx/DownloadButtonXlsx';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CallCentreMemberInteractionReport = () => {
  const [clearModal, setClearModal] = useState(false);
  const data = [
    {
      receipt: '#75588',
      membId: '67',
      transDate: '23/06/2023',
      membName: 'Kenneth',
      billAmount: 5000,
      redeemAmount: 8000,
      balPaid: 16000,
      pointsGained: 8,
    },
  ];

  return (
    <>
      <CallCenterStructure>
        <div className="mt-5 p-4">
          <h4 className="pb-4">Call Centre Member Interaction Report</h4>
          <main className="border border-light p-3">
            <form action="">
              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label
                    className="d-flex justify-content-between"
                    htmlFor="memberName">
                    Member Name/Membership ID *
                  </label>
                  <input className="w-100" type="text" placeholder="" />
                </div>
                <div className="form-group col-12 col-md-6">
                  <label
                    className="d-flex justify-content-between"
                    htmlFor="queryType">
                    Query Type *
                  </label>
                  <input className="w-100" type="text" placeholder="" />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="queryType">Sub Query *</label>
                  <Dropdown />
                </div>
              </div>

              <div className="form-group d-flex justify-content-between d-md-flex mt-4">
                <button className="mr-5">Search</button>
                <button
                  className="resetButton"
                  type="button"
                  onClick={() => setClearModal(true)}>
                  Reset
                </button>
              </div>
            </form>
          </main>

          <div
            className="d-flex justify-content-between d-md-flex justify-content-md-start"
            style={{marginTop: '50px'}}>
            <DownloadButtonPdf />
            <DownloadButtonXlsx />
          </div>

          <section className="border border-light mt-4 mb-4">
            <div className="row p-4">
              <div className="col-12 table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="">Membership ID</th>
                      <th className="">Query Log Ticket</th>
                      <th className="">Query Type</th>
                      <th className="">Query</th>
                      <th className="">Query Status</th>
                      <th className="">Interaction</th>
                      <th className="">Interaction Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((each, index) => (
                      <tr key={index}>
                        <td>{each.receipt}</td>
                        <td>{each.transDate}</td>
                        <td>{each.membId}</td>
                        <td>{each.membName}</td>
                        <td>{each.billAmount}</td>
                        <td>{each.receipt}</td>
                        <td>{each.receipt}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="col-12">Pagination</div>
            </div>
          </section>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            // clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
        </div>
      </CallCenterStructure>
    </>
  );
};
export default CallCentreMemberInteractionReport;
