import React from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/ViewOrEditCompanyUser.module.scss';

const ViewOrEditCompanyUser = () => {
  return (
    <CallCenterStructure>
      <div className={styles.viewOrEditCompanyUser}>
        <div className={styles.headerText}>
          <p>Company User Enrollment</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer}>
            <label htmlFor="">
              Enter User Name / Phone no / Email ID. *( Phone No: Do not preceed
              with Country code or '0')
            </label>
            <input type="text" />
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.next}>Next</button>
          </div>
        </div>
      </div>
    </CallCenterStructure>
  );
};

export default ViewOrEditCompanyUser;
