import React from 'react';
import styles from '../../styles/DownloadButtonPdf.module.scss';

const DownloadButtonPdf = () => {
    return ( 
        <>
        <button className={styles.pdf}>Download as pdf</button>
        </>
     );
}
 
export default DownloadButtonPdf;