import React, {useEffect, useState} from 'react';
import Category from '../../component/Category/Category';
import TemplateFooter from '../../component/TemplateFooter/TemplateFooter';
import TemplateNavbar from '../../component/TemplateNavbar/TemplateNavbar';
import styles from '../../styles/Events.module.scss';
import event1 from '../../assets/images/event1.png';
import event2 from '../../assets/images/event2.png';
import event3 from '../../assets/images/event3.png';
import event4 from '../../assets/images/event4.png';
import event5 from '../../assets/images/event5.png';
import {TemplateCalendar} from '../../assets/icons';

const Events = () => {
  const [currentEventsIndex, setCurrentEventsIndex] = useState(3);
  const [mainEvents, setMainEvents] = useState([]);

  const eventItems = [
    {
      img: event1,
      name: 'Future Leadership Conference 2022',
      date: '28 June 2023,',
      time: '9 AM',
      desc: 'Landmark Event Centre, Victoria Island, Lagos',
      points: '1,400',
    },
    {
      img: event2,
      name: 'Future Leadership Conference 2022',
      date: '28 June 2023,',
      time: '9 AM',
      desc: 'Landmark Event Centre, Victoria Island, Lagos',
      points: '1,400',
    },
    {
      img: event3,
      name: 'Future Leadership Conference 2022',
      date: '28 June 2023,',
      time: '9 AM',
      desc: 'Landmark Event Centre, Victoria Island, Lagos',
      points: '1,400',
    },
    {
      img: event4,
      name: 'Future Leadership Conference 2022',
      date: '28 June 2023,',
      time: '9 AM',
      desc: 'Landmark Event Centre, Victoria Island, Lagos',
      points: '1,400',
    },
    {
      img: event5,
      name: 'Future Leadership Conference 2022',
      date: '28 June 2023,',
      time: '9 AM',
      desc: 'Landmark Event Centre, Victoria Island, Lagos',
      points: '1,400',
    },
    {
      img: event5,
      name: 'Future Leadership Conference 2022',
      date: '28 June 2023,',
      time: '9 AM',
      desc: 'Landmark Event Centre, Victoria Island, Lagos',
      points: '1,400',
    },
    {
      img: event4,
      name: 'Future Leadership Conference 2022',
      date: '28 June 2023,',
      time: '9 AM',
      desc: 'Landmark Event Centre, Victoria Island, Lagos',
      points: '1,400',
    },
    {
      img: event5,
      name: 'Future Leadership Conference 2022',
      date: '28 June 2023,',
      time: '9 AM',
      desc: 'Landmark Event Centre, Victoria Island, Lagos',
      points: '1,400',
    },
    {
      img: event5,
      name: 'Future Leadership Conference 2022',
      date: '28 June 2023,',
      time: '9 AM',
      desc: 'Landmark Event Centre, Victoria Island, Lagos',
      points: '1,400',
    },
    {
      img: event4,
      name: 'Future Leadership Conference 2022',
      date: '28 June 2023,',
      time: '9 AM',
      desc: 'Landmark Event Centre, Victoria Island, Lagos',
      points: '1,400',
    },
    {
      img: event5,
      name: 'Future Leadership Conference 2022',
      date: '28 June 2023,',
      time: '9 AM',
      desc: 'Landmark Event Centre, Victoria Island, Lagos',
      points: '1,400',
    },
    {
      img: event5,
      name: 'Future Leadership Conference 2022',
      date: '28 June 2023,',
      time: '9 AM',
      desc: 'Landmark Event Centre, Victoria Island, Lagos',
      points: '1,400',
    },
  ];

  useEffect(() => {
    const eventSlice = eventItems.slice(0, currentEventsIndex);
    setMainEvents(eventSlice);
  }, [currentEventsIndex]);

  return (
    <div className={styles.eventsContainer}>
      <TemplateNavbar />
      <Category />
      <div className={styles.eventContent}>
        <h1 className={styles.headerText}>Events</h1>
        <div className={styles.eventCardContainer}>
          {mainEvents.map(each => (
            <div className={styles.eventCard} key={each.img}>
              <div className={styles.eventImg}>
                <img src={each.img} alt={each.name} />
              </div>
              <p className={styles.eventName}>{each.name}</p>
              <div className={styles.timeContainer}>
                <TemplateCalendar />
                <p>{each.date}</p>
                <p>{each.time}</p>
              </div>
              <p className={styles.desc}>{each.desc}</p>
              <p className={styles.points}>{each.points} Points</p>
            </div>
          ))}
        </div>
        <div className={styles.loadMoreContainer}>
          <button
            onClick={() => setCurrentEventsIndex(events => events + 3)}
            className={
              eventItems.length === mainEvents.length
                ? styles.loadMoreBTNEmpty
                : styles.loadMoreBTN
            }>
            Load More
          </button>
        </div>
      </div>
      <TemplateFooter />
    </div>
  );
};

export default Events;
