import React from 'react';
import styles from '../../styles/ChangePassword.module.scss';
import PerxLogo from '../../assets/images/PerxBig.png';
const ChangePassword = () => {
  return (
    <div className={styles.ChangePasswordContainer}>
      <div className={styles.ChangePasswordInnerContainer}>
        <div className={styles.perxLogo}>
          <img src={PerxLogo} alt="perx logo" />
        </div>
        <div className={styles.formContainer}>
          <h6>Change Password</h6>
          <div className={styles.formMainContainer}>
            <label htmlFor="">Enter your old password</label>
            <input type="text" name="" id="" />
          </div>
          <div className={styles.formMainContainer1}>
            <label htmlFor="">Enter your old password</label>
            <input type="text" name="" id="" />
          </div>
          <div className={styles.formMainContaine2}>
            <label htmlFor="">Confirm your password</label>
            <input type="text" name="" id="" />
          </div>
          <button className={styles.sendBTN}>Change Password</button>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
