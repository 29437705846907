import React, {useState, useEffect} from 'react';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import styles from '../../styles/LoyaltyProgramMaster.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import defaultImage from '../../assets/images/defaultImage.png';
import {Times, ViewPen} from '../../assets/icons';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import { Headers } from '../../service/Essentials';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const LoyaltyProgramMaster = () => {
  const [clearModal, setClearModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [applyRadio, setapplyRadio] = useState(false);
  const [programName, setProgramName] = useState('');
  const [pointsDefValue, setPointsDefValue] = useState('');
  const [pointsName, setPointsName] = useState('');
  const [updateID, setUpdateID] = useState('');
  const [selectedItemID, setSelectedItemId] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [errors, setErrors] = useState(null);
  const [error, setError] = useState('ooops, something went wrong');

  const queryClient = useQueryClient();

  const onClose = () => {
    setDeleteModal(false);
  };

  const formData = {
    program_name: programName,
    applied_to: Number(applyRadio),
    points_value_definition: pointsDefValue,
    name_of_points: pointsName,
    logo: selectedImage,
    company_setup_id: 1,
  };

  //RedirectToLogin();

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const fetchLoyaltyProgram = async page => {
    const res = await axiosProperty.get(
      ApiConfig.fetchLoyaltyProgram + `?page=${page}`,
      {
        headers: Headers(),
      },
    );
    return res.data;
  };
  const {data, status} = useQuery(
    ['loyaltyprograms', page],
    () => fetchLoyaltyProgram(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = searchTerm => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data) {
      const filtered = data.data.filter(
        (item) =>
          item.program_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  const addLoyaltyProgram = async () => {
    try {
      await axiosProperty.post(ApiConfig.createLoyaltyProgram, formData, {
        headers: Headers(),
      });
      setSelectedImage(null);
      setSelectedImageName('');
      setPointsDefValue('');
      setPointsName('');
      setProgramName('');
      setapplyRadio(false);
      setErrors(null);
      swal({
        title: 'Success!',
        text: 'Loyalty Program created successfully',
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setErrors(err.response.data);
        setError(err.response.data.message);
      } else {
        setErrors('Something went wrong, try again');
      }
      swal({
        title: 'Error!',
        text: error,
        icon: 'error',
      });
    }
  };

  const {mutate: addLoyaltyProgramMutation} = useMutation({
    mutationFn: addLoyaltyProgram,
    onSuccess: () => {
      queryClient.invalidateQueries(['loyaltyprograms']);
    },
  });

  const updateLoyaltyProgram = async () => {
    try {
      await axiosProperty.patch(
        ApiConfig.updateLoyaltyProgram + `/${updateID}`,
        formData,
        {
          headers: Headers(),
        },
      );
      setSelectedImage(null);
      setSelectedImageName('');
      setPointsDefValue('');
      setPointsName('');
      setProgramName('');
      setapplyRadio(false);
      setIsSubmit(true);
      setIsUpdate(false);
      setErrors(null);
      swal({
        title: 'Success!',
        text: 'Loyalty program updated successfully',
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setErrors(err.response.data);
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      swal({
        title: 'Error!',
        text: error,
        icon: 'error',
      });
    }
  };

  const {mutate: updateLoyaltyProgramMutation} = useMutation({
    mutationFn: updateLoyaltyProgram,
    onSuccess: () => {
      queryClient.invalidateQueries(['loyaltyprograms']);
    },
  });

  const editLoyaltyProgram = async id => {
    try {
      const res = await axiosProperty.get(
        ApiConfig.editLoyaltyProgram + `/${id}`,
        {
          headers: Headers(),
        },
      );
  setIsUpdate(true); 
  setIsSubmit(false); 
  setPointsDefValue(res.data.data.points_value_definition || 'no data');
  setPointsName(res.data.data.name_of_points || 'no data');
  setapplyRadio(res.data.data.applied_to);
  setProgramName(res.data.data.name || 'no data');
  setErrors(null);
  // setSelectedImage(res.data.data.logo || 'no data');
  // setSelectedImageName(res.data.data.logo || 'no data');
    }catch(err){
      if(err.response){
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);
      swal({
        title: "Warning!",
        text: error,
        icon: "warning",
      });
    }
  };

  const deleteLoyaltyProgram = async (id) => {
    try{
      const res = await axiosProperty.delete(ApiConfig.deleteLoyaltyProgram+`/${id}`,
          {
            headers:Headers(),
          }
      );

      setDeleteModal(false); 

      swal({
        title: "Success!",
        text: 'Loyalty program deleted successfully',
        icon: "success",
      });

      queryClient.invalidateQueries(['loyaltyprograms']);

    }catch(err){
      if(err.response){
        setError(err.response.data.message);
      }else{
       setError('Something went wrong, try again'); 
      }
      console.log(err);
      swal({
        title: "Warning!",
        text: error,
        icon: "warning",
      });

    }
};

  return (
    <CallCenterStructure>
      <div className={styles.loyaltyProgramMaster}>
        <div className={styles.headerText}>
          <p>Loyalty Program Master</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Enter Loyalty Program Name<sup>*</sup>
              </label>
              <input
                value={programName}
                type="text"
                onChange={e => setProgramName(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Apply To<sup>*</sup>
              </label>
              <RadioButtonBlue
                firstText="Customer"
                secondText="Employee"
                onChange={setapplyRadio}
              />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Points Value Definition<sup>*</sup>
              </label>
              <input
                value={pointsDefValue}
                type="text"
                onChange={e => setPointsDefValue(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Name of Points<sup>*</sup>
              </label>
              <input
                value={pointsName}
                type="text"
                onChange={e => setPointsName(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.itemInputContainer4}>
            <div className={styles.defaultImageContainer}>
              <div className={styles.imageContainer}>
                {selectedImage ? (
                  <img src={selectedImage} alt="default" />
                ) : (
                  <img src={defaultImage} alt="default" />
                )}
              </div>
              <p>Logo</p>
            </div>

            <div className={styles.uploadContainer}>
              <div className={styles.uploadInputContainer}>
                <label htmlFor="">
                  Upload Loyalty Program Logo <sup>*</sup> You can upload upto
                  maximum 100kb. Use Image of Resolution 230 Pixels (H) X 140
                  Pixels (V)
                </label>
                <ImageDragAndDrop
                  selectedImageName={selectedImageName}
                  setSelectedImageName={setSelectedImageName}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            {isSubmit && (
              <button
                className={styles.submit}
                onClick={() => addLoyaltyProgramMutation()}>
                Submit
              </button>
            )}
            {isUpdate && (
              <button
                className={styles.submit}
                onClick={() => updateLoyaltyProgramMutation()}>
                Update
              </button>
            )}
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
          <br />
          <ErrorMessage errMsg={errors} />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Loyalty Program Name</th>
                  <th>User Apply to</th>
                  <th>Points Value Definition</th>
                  <th>Name Of Points</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map(each => (
                    <tr key={each.point}>
                      <td>{each.program_name}</td>
                      <td>{each.applied_to}</td>
                      <td>{each.points_value_definition}</td>
                      <td>{each.name_of_points}</td>
                      <td>
                        <div className={styles.iconContainer}>
                          <span
                            className={styles.icon}
                            onClick={() => {
                              editLoyaltyProgram(each.id);
                              setUpdateID(each.id);
                            }}>
                            <ViewPen />
                          </span>
                          <span
                            className={styles.icon}
                            onClick={() => {
                              setDeleteModal(true);
                              setSelectedItemId(each.id);
                            }}>
                            <Times />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colspan="5">No data available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={data} setPage={setPage} />
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && (
          <MenuDeleteModal
            onClose={onClose}
            onDelete={() => deleteLoyaltyProgram(selectedItemID)}
          />
        )}
      </div>
    </CallCenterStructure>
  );
};

export default LoyaltyProgramMaster;
