import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import RadioButtonSingle from '../../component/RadioButtonSingle/RadioButtonSingle';
import DownloadButtonPdf from '../../component/DownloadButtonPdf/DownloadButtonPdf';
import DownloadButtonXlsx from '../../component/DownloadButtonXlsx/DownloadButtonXlsx';


const MemberTransactionReport = ()=>{
    const [selectedOption, setSelectedOption] = useState(null);

    const handleRadioButtonChange = value => {
      setSelectedOption(value);
    };
    const data = [
        {
        receipt: '#75588', membId: '67', transDate:'23/06/2023', membName:'Kenneth', 
        billAmount:5000, redeemAmount:8000, balPaid:16000, pointsGained:8
        }
      ];
    return(
    <>
        <CallCenterStructure>
        <div className='mt-5 p-4'>
           <h4 className='pb-4'>Member Transaction Report</h4>
           <main className='border border-light p-3'>
           <form action="">
               <div className="row">
               <div className="form-group col-12 col-md-6" >
               <label className='d-flex justify-content-between' htmlFor="fromDate">From Date *</label>
                   <input className="w-100" type="text" placeholder='From date' />
               </div>
               <div className="form-group col-12 col-md-6" >
               <label className='d-flex justify-content-between' htmlFor="tillDate">Till Date *</label>
                   <input className="w-100" type="text" placeholder='Till date' />
               </div>
               </div>

               <div className="row">
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="selectCountry">Select Country *</label>
                   <Dropdown/>
               </div>
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="selectRegion">Select Region *</label>
                   <Dropdown/>
               </div>
               </div>
               <div className="row">
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="selectZone">Select Zone *</label>
                   <Dropdown/>
               </div>
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="selectState">Select State *</label>
                   <Dropdown/>
               </div>
               </div>
               <div className="row">
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="reportCity">Report City *</label>
                   <Dropdown/>
               </div>
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="selectBranch">Select Branch *</label>
                   <Dropdown/>
               </div>
               </div>
               <div className="row">
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="reportTier">Report Tier *</label>
                   <Dropdown/>
               </div>
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="selectSegment">Select Segment *</label>
                   <Dropdown/>
               </div>
               </div>
               <div className="row">
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="selectTransactionType">Select Transaction Type *</label>
                   <Dropdown/>
               </div>
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="selectView">Select View *</label>
                   <Dropdown/>
               </div>
               </div>
               <label htmlFor="chooseMembers">Choose Members *</label>

               <div className="row">
               <div className="form-group col-12 col-md-4 d-flex" >
                <div className="d-flex mr-5 mt-4">
                 <RadioButtonSingle
                        name="members"
                        value="1"
                        selected={selectedOption === '1'}
                        borderWidth={2}
                        borderHeight={2}
                        onChange={handleRadioButtonChange}
                   />Who have done transaction(s)
                </div>
                </div>

                <div className="form-group col-12 col-md-4 d-flex" >
                <div className="d-flex mt-4 mr-5">
                 <RadioButtonSingle
                        name="members"
                        value="2"
                        selected={selectedOption === '2'}
                        borderWidth={2}
                        borderHeight={2}
                        onChange={handleRadioButtonChange}
                   />Who have not done transaction(s)
                </div>
                </div>

                <div className="form-group col-12 col-md-4 d-flex" >
                <div className="d-flex mt-4">
                 <RadioButtonSingle
                        name="members"
                        value="3"
                        selected={selectedOption === '3'}
                        borderWidth={2}
                        borderHeight={2}
                        onChange={handleRadioButtonChange}
                   />Single Member
                </div>
                </div>
               </div>
               <div className='form-group d-flex justify-content-between d-md-flex justify-content-md-start mt-4'>
                   <button className='mr-5'>Submit</button>
                </div>
           </form>
           </main>

           <div className='d-flex justify-content-between d-md-flex justify-content-md-start' style={{marginTop:'150px'}}>
           <DownloadButtonPdf />
           <DownloadButtonXlsx />
           </div>
          
           <section className="border border-light mt-4 mb-4">
                <div className="row p-4">
                <div className="col-12 table-responsive">
                <table className='table'>
                    <thead>
                        <tr>
                        <th className=''>Transaction Date</th>
                        <th className=''>Posting Date</th>
                        <th className=''>Transaction Type</th>
                        <th className=''>Member Name</th>
                        <th className=''>Membership ID</th>
                        <th className=''>Branch Name</th>
                        <th className=''>Branch username</th>
                        <th className=''>Bill No.</th>
                        <th className=''>Item Name</th>
                        <th className=''>Quantity</th>
                        <th className=''>Transaction Amount</th>
                        <th className=''>Order No.</th>
                        <th className=''>Redeem Points</th>


                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((each, index) =>(
                             <tr key={index}>
                                <td>{each.receipt}</td>
                                <td>{each.transDate}</td>
                                <td>{each.membId}</td>
                                <td>{each.membName}</td>
                                <td>{each.billAmount}</td>
                                <td>{each.receipt}</td>
                                <td>{each.transDate}</td>
                                <td>{each.membId}</td>
                                <td>{each.membName}</td>
                                <td>{each.billAmount}</td>
                                <td>{each.membId}</td>
                                <td>{each.membName}</td>
                                <td>{each.billAmount}</td>
                             </tr>
                            ))
                        }
                    </tbody>
                </table>
                </div>
                <div className="col-12">Pagination</div>
                </div>
           </section>
        </div>
       </CallCenterStructure>
    </>
    );
}
export default MemberTransactionReport;