import React, {useState, useEffect} from 'react';
import {Mark, Times, ViewPen} from '../../assets/icons';
import SearchInput from '../../component/SearchInput/SearchInput';
import styles from '../../styles/ApplicationTransaction.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import { useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import { Headers, ProgramID} from '../../service/Essentials';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';

const ApplicationTransaction = () => {

const queryClient = useQueryClient();

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState(null);

  const fetchTransactionTypes = async (page) =>{
    const res = await axiosProperty.get(ApiConfig.fetchTransactionType+`?page=${page}`, {
        headers:Headers()
    }
    );
    return res.data;
  }  
  const {data, status} = useQuery(['transactiontypes', page], () => fetchTransactionTypes(page), {keepPreviousData:true});

  useEffect(() => {
    setFilteredData(data?.data || []);
    setTransactionCode(String(+new Date()))
  }, [data]);

  const handleSearchInputChange = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data) {
      const filtered = data.data.filter(
        (item) =>
          item.transaction_name.toLowerCase().includes(searchTerm.toLowerCase())||
          item.transaction_code.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  const [text, setText] = useState('');
  const [transaction_code, setTransactionCode] = useState(String(+new Date()));
 // const [successModal, setSuccessModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState();
  //const company_setup_id = ProgramID();

  const handleChange = e => {
    setText(e.target.value);
  };
  const addTransactionType = async () => {
    try{
        await axiosProperty.post(ApiConfig.createTransactionType, {transaction_code: transaction_code, transaction_name: text, program_id:ProgramID()}, 
        {
          headers:Headers()
      }
        );
       // setSuccessModal(true);
        setText('');
        setErrors(null);
        swal({
          title: "Success!",
          text: "Transaction type added successfully",
          icon: "success",
        });
    }catch(err){
      if(err.response){
       setError(err.response.data.message);
       setErrors(err.response.data);
       swal({
        title: "Error!",
        text: error,
        icon: "error",
      });
      }else{
         setError('Something went wrong, probably network'); 
         swal({
          title: "warning!",
          text: error,
          icon: "warning",
        });
      }
      console.log(err);
    }
  };

const {mutate:addTransactionTypeMutation} = useMutation(
  {
    mutationFn: addTransactionType,
    onSuccess:()=>{queryClient.invalidateQueries(['transactiontypes'])}, 
  
  }
  );

  const updateTransactionType = async () => {
    try{
        await axiosProperty.patch(ApiConfig.updateTransactionType+`/${updateID}`, {transaction_code: transaction_code, transaction_name: text},
        {
          headers:Headers(),
      }
        );
       // setSuccessModal(true);
        setText('');
        setErrors(null);
        setIsSubmit(true);
        setIsUpdate(false);
        swal({
          title: "Success!",
          text: "Transaction type updated successfully",
          icon: "success",
        });
    }catch(err){
      if(err.response){
       setError(err.response.data.message);
       setErrors(err.response.data);
       swal({
        title: "Error!",
        text: error,
        icon: "error",
      });
      }else{
       setError('Something went wrong, probably network'); 
       swal({
        title: "Warning!",
        text: error,
        icon: "warning",
      });
      }
      console.log(err);
    }
  };

  const {mutate:updateTransactionTypeMutation} = useMutation(
    {
      mutationFn: updateTransactionType,
      onSuccess:()=>{queryClient.invalidateQueries(['transactiontypes'])}, 
    
    }
    );

  const editTransactionType = async (id) => {
       const res = await axiosProperty.get(ApiConfig.editTransactionType+`/${id}`,
        {
          headers:Headers(),
      }
      );
      setIsUpdate(true); 
      setIsSubmit(false); 
      setText(res.data.data.transaction_name);
      setTransactionCode(res.data.data.transaction_code)
  };

  return (
    <CallCenterStructure>
      <div className={styles.transactionTypeContainer}>
        <div>
          <h6 className={styles.headerText}>
            Application Transaction Type Master Setup
          </h6>
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="" className={styles.transactionTypeLabel}>
            Enter Transaction Type Name
          </label>


        <div className={styles.formContainer}>
            <input
              type="text"
              value={text}
              onChange={handleChange}
              className={styles.transactionTypeInput}
            />
            {isSubmit && (<button
              className={styles.transactionTypeBtn}
              onClick={()=>addTransactionTypeMutation()}>
              Submit
            </button>)}
            {isUpdate && (<button
              className={styles.transactionTypeBtn}
              onClick={()=>updateTransactionTypeMutation()}>
              Update
            </button>)}
          </div>
          <p className={styles.transactionTypeNoteText}>
            *Note:Click on column header for Ascending/Descending
          </p>
        </div>
        <div className={styles.tableContainer}>
        <ErrorMessage errMsg={errors} />
          <div className={styles.formContainer}>
            <div className={styles.searchInput}>
              <SearchInput onSearchInputChange={handleSearchInputChange} />
            </div>
            {/* <button className={styles.transactionTypeBtn}>Search</button> */}
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.dataTable}>
              <thead>
                <tr>
                  <th>Transaction Type ID</th>
                  <th>Transaction Type iName</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {
                status === 'error' && (<tr><td className='text-danger'>Error Occured</td></tr>)
              }

              {
                status === 'loading' && (<tr><td className='text-info'>loading</td></tr>)
              }
                
                {
                status === 'success' && (filteredData.map(each => (
                  <tr key={each.id}>
                    <td>{each.id}</td>
                    <td>{each.transaction_name}</td>
                    <td onClick={()=>{editTransactionType(each.id); setUpdateID(each.id)}}>
                      <ViewPen />
                    </td>
                  </tr>
                )))

                }

              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={data} setPage={setPage} />
          </div>
        </div>
        {/* {successModal && (
          <div className={styles.successModal}>
            <Mark />
            <p className={styles.successText}>User Type addes successfully</p>
            <span onClick={() => setSuccessModal(false)}>
              <Times />
            </span>
          </div>
        )} */}
      </div>
    </CallCenterStructure>
  );
};

export default ApplicationTransaction;
