import React, {useState, useEffect} from 'react';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import Dropdown from '../../component/DropDown/DropDown';
import styles from '../../styles/SurveyStructureMaster.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import {getUser} from '../../service/common';
import swal from 'sweetalert';
import Paginate from '../../component/Paginate/Paginate';
import { CompanySurveyHeaders, ProgramID } from '../../service/Essentials';
import {Mark, Times, View, ViewPen} from '../../assets/icons';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const SurveyStructureMaster = () => {
  const [clearModal, setClearModal] = useState(false);
  const user = getUser();

  const program_id = ProgramID();

  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [filteredSentData, setFilteredSentData] = useState([]);
  const [filteredNotSentData, setFilteredNotSentData] = useState([]);
  const [filteredClosedData, setFilteredClosedData] = useState([]);
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState({
    Survey_type: '',
    Company_id: '',
    Survey_name: '',
    No_of_questions: '',
    No_of_multiple_choice: '',
    No_of_text_based: '',
    Reminder_days_after: '',
    Reminder_days_before: '',
    Active_Flag: '',
    Status: '',
    Start_date: '',
    End_Date: '',
    Enrollment_id: '',
  });
  const [surveyTypeID, setOptionID] = useState('');
  const [surveyType, setSurveyType] = useState();
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItemID, setSelectedItemId] = useState('');

  const [formData, setFormData] = useState({
    Survey_type: surveyTypeID,
    Company_id: program_id,
    Survey_name: '',
    No_of_questions: '',
    No_of_multiple_choice: '',
    No_of_text_based: '',
    Reminder_days_after: '',
    Reminder_days_before: '',
    Active_Flag: 1,
    Status: 1,
    Start_date: '',
    End_Date: '',
    Enrollment_id: 1,
  });

  const handleSurveyType = name => {
    setSurveyType(name);
  };
  const handleDropdownChange = id => {
    setOptionID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Survey_type: id,
    }));
  };

  const fetchSurveysSent = async page => {
    const res = await axiosProperty.get(
      ApiConfig.fetchSurveyTypeSent + `?page=${page}`,
      {
        headers: CompanySurveyHeaders(),
      },
    );
    return res.data;
  };

  const fetchSurveysNotSent = async page => {
    const res = await axiosProperty.get(
      ApiConfig.fetchSurveyTypeNotSent + `?page=${page}`,
      {
        headers: CompanySurveyHeaders(),
      },
    );
    return res.data;
  };

  const fetchSurveysClosed = async page => {
    const res = await axiosProperty.get(
      ApiConfig.fetchSurveyTypeClosed + `?page=${page}`,
      {
        headers: CompanySurveyHeaders(),
      },
    );
    return res.data;
  };

  const {data: sentsurveys, status: sentstatus} = useQuery(
    ['sentsurveys', page],
    () => fetchSurveysSent(page),
    {keepPreviousData: true},
  );
  const {data: notsentsurveys, status: notsentstatus} = useQuery(
    ['notsentsurveys', page],
    () => fetchSurveysNotSent(page),
    {keepPreviousData: true},
  );
  const {data: closedsurveys, status: closedstatus} = useQuery(
    ['closedsurveys', page],
    () => fetchSurveysClosed(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setFilteredSentData(sentsurveys?.data || []);
    setFilteredNotSentData(notsentsurveys?.data || []);
    setFilteredClosedData(closedsurveys?.data || []);
  }, [sentsurveys, notsentsurveys, closedsurveys]);

  const handleChange = e => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const addSurvey = async () => {
    //e.preventDefault();
    //setData(prev => [...prev, {id: 4, name: text}]);
   // return
    try{
       const res = await axiosProperty.post(ApiConfig.createSurvey, formData, 
        {
          headers:CompanySurveyHeaders(),
      }
        );
        if(res.data.status == 'Failed!'){
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          }); 
          return
        }
        if(res.status === 200){
          setFormData({Survey_type:'', Company_id:program_id, Survey_name:'', No_of_questions:'', No_of_multiple_choice:'',
          No_of_text_based:'', Reminder_days_after:'', Reminder_days_before:'', Active_Flag:1, Status:1, Start_date:'', End_Date:'', Enrollment_id:1
        });
        setErrors({});

        swal({
          title: 'Success!',
          text: 'Survey added successfully',
          icon: 'success',
        });
      } else {
        setError('Something went wrong with the request');
        swal({
          title: 'Error!',
          text: 'Something went wrong with the request',
          icon: 'error',
        });
      }
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
      console.log(err);
    }
  };

  const {mutate: addSurveyMutation} = useMutation({
    mutationFn: addSurvey,
    onSuccess: () => {
      queryClient.invalidateQueries(['sentsurveys']);
      queryClient.invalidateQueries(['notsentsurveys']);
      queryClient.invalidateQueries(['closedsurveys']);
    },
  });

  const updateSurvey = async () => {
    try {
      const res = await axiosProperty.put(
        ApiConfig.updateSurveyType(updateID),
        formData,
        {
          headers: CompanySurveyHeaders(),
        },
      );

      if (res.status === 200) {
        setIsSubmit(true);
        setIsUpdate(false);
        setFormData({
          Survey_type: '',
          Company_id: '',
          Survey_name: '',
          No_of_questions: '',
          No_of_multiple_choice: '',
          No_of_text_based: '',
          Reminder_days_after: '',
          Reminder_days_before: '',
          Active_Flag: '',
          Status: '',
          Start_date: '',
          End_Date: '',
          Enrollment_id: '',
        });
        setErrors({});
        swal({
          title: 'Success!',
          text: 'Survey Type updated successfully',
          icon: 'success',
        });
      } else {
        setError('Something went wrong with the request');
        swal({
          title: 'Error!',
          text: 'Something went wrong with the request',
          icon: 'error',
        });
      }
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, try again');
        swal({
          title: 'Warning!',
          text: error,
          icon: 'warning',
        });
      }
    }
  };

  const {mutate: updateSurveyMutation} = useMutation({
    mutationFn: updateSurvey,
    onSuccess: () => {
      queryClient.invalidateQueries(['sentsurveys']);
      queryClient.invalidateQueries(['notsentsurveys']);
      queryClient.invalidateQueries(['closedsurveys']);
    },
  });

  const editSurvey = async id => {
    const res = await axiosProperty.get(ApiConfig.editSurveyType(id), {
      headers: CompanySurveyHeaders(),
    });
    setIsUpdate(true);
    setIsSubmit(false);
    setFormData(prevFormData => ({
      ...prevFormData,
      Survey_type: res.data.data.Survey_type,
      Company_id: program_id,
      Survey_name: res.data.data.Survey_name,
      No_of_questions: res.data.data.No_of_questions,
      No_of_multiple_choice: res.data.data.No_of_multiple_choice,
      No_of_text_based: res.data.data.No_of_text_based,
      Reminder_days_after: res.data.data.Reminder_days_after,
      Reminder_days_before: res.data.data.Reminder_days_before,
      Active_Flag: res.data.data.Active_Flag,
      Status: 1,
      Start_date: res.data.data.Start_date,
      End_Date: res.data.data.End_Date,
      Enrollment_id: 1,
    }));
  };

  const deleteSurvey = async id => {
    try {
      const res = await axiosProperty.delete(ApiConfig.deleteSurveyType(id), {
        headers: CompanySurveyHeaders(),
      });
      //setSuccessModal(true);
      setDeleteModal(false);
      swal({
        title: 'Success!',
        text: 'Delete was successful',
        icon: 'success',
      });
      queryClient.invalidateQueries(['sentsurveys']);
      queryClient.invalidateQueries(['notsentsurveys']);
      queryClient.invalidateQueries(['closedsurveys']);
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);
      swal({
        title: 'Warning!',
        text: error,
        icon: 'warning',
      });
    }
  };

  const onClose = () => {
    setDeleteModal(false);
  };

  return (
    <CallCenterStructure>
      <div className={styles.companySurveyManagement}>
        <div className={styles.headerText}>
          <p>Survey Structure Master</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Name of Survey<sup>*</sup>
              </label>
              <input
                type="text"
                name="Survey_name"
                value={formData.Survey_name}
                onChange={handleChange}
              />
              {errors?.Survey_name && (
                <p className="text-danger">{errors?.Survey_name}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Type of Survey<sup>*</sup>
              </label>
              <Dropdown
                header={CompanySurveyHeaders()}
                api={ApiConfig.fetchSurveyAll}
                onSelect={handleDropdownChange}
                onSelectOption={handleSurveyType}
                column={'Survey_type'}
                idValue={'Survey_type_id'}
                retrievedValue={formData?.Survey_type}
                key="surveyType"
              />
              {errors?.Survey_type && (
                <p className="text-danger">{errors?.Survey_type}</p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Enter Number of Questions<sup>*</sup>
              </label>
              <input
                type="number"
                name="No_of_questions"
                value={formData.No_of_questions}
                onChange={handleChange}
              />
              {errors?.No_of_questions && (
                <p className="text-danger">{errors?.No_of_questions}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Number of Multiple Choice Questions<sup>*</sup>
              </label>
              <input
                type="number"
                name="No_of_multiple_choice"
                value={formData.No_of_multiple_choice}
                onChange={handleChange}
              />
              {errors?.No_of_multiple_choice && (
                <p className="text-danger">{errors?.No_of_multiple_choice}</p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Number of Text Based Questions<sup>*</sup>
              </label>
              <input
                type="number"
                name="No_of_text_based"
                value={formData.No_of_text_based}
                onChange={handleChange}
              />
              {errors?.No_of_text_based && (
                <p className="text-danger">{errors?.No_of_text_based}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Valid From<sup>*</sup>
              </label>
              <input
                type="date"
                name="Start_date"
                value={formData.Start_date}
                onChange={handleChange}
              />
              {errors?.Start_date && (
                <p className="text-danger">{errors?.Start_date}</p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Valid Till<sup>*</sup>
              </label>
              <input
                type="date"
                name="End_Date"
                value={formData.End_Date}
                onChange={handleChange}
              />
              {errors?.End_Date && (
                <p className="text-danger">{errors?.End_Date}</p>
              )}
            </div>
          </div>
          <div className={styles.notificationText}>
            <p>Reminder Notification Days</p>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Reminder Notification Days After Validity Starts<sup>*</sup>
              </label>
              <input
                type="number"
                name="Reminder_days_after"
                value={formData.Reminder_days_after}
                onChange={handleChange}
              />
              {errors?.Reminder_days_after && (
                <p className="text-danger">{errors?.Reminder_days_after}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Reminder Notification Days Before Validity Ends<sup>*</sup>
              </label>
              <input
                type="number"
                name="Reminder_days_before"
                value={formData.Reminder_days_before}
                onChange={handleChange}
              />
              {errors?.Reminder_days_before && (
                <p className="text-danger">{errors?.Reminder_days_before}</p>
              )}
            </div>
          </div>
          <div className={styles.buttonContainer}>
            {/* <button className={styles.submit} onClick={()=>addSurveyMutation()}>Submit</button> */}
            {isSubmit && (
              <button
                className={styles.submit}
                onClick={() => addSurveyMutation()}>
                Submit
              </button>
            )}
            {isUpdate && (
              <button
                className={styles.submit}
                onClick={() => updateSurveyMutation()}>
                Update
              </button>
            )}
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <h6>Active Survey(s) - Not Sent</h6>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Survey Name</th>
                  <th>Survey Type</th>
                  <th>Number of Questions</th>
                  <th>Multiple Choice Questions</th>
                  <th>Text Based Questions</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Survey Details</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredNotSentData.map(each => (
                  <tr key={each?.id}>
                    <td>{each?.Survey_name}</td>
                    <td>{each?.Survey_type}</td>
                    <td>{each?.No_of_questions}</td>
                    <td>{each?.No_of_multiple_choice}</td>
                    <td>{each?.No_of_text_based}</td>
                    <td>{each?.Start_date}</td>
                    <td>{each?.End_Date}</td>
                    <td>
                      <a href="#">Preview</a>
                    </td>
                    <td>
                      <div className={styles.iconContainer}>
                        <span
                          className={styles.icon}
                          onClick={() => {
                            editSurvey(each.Survey_id);
                            setUpdateID(each.Survey_id);
                          }}>
                          <ViewPen />
                        </span>
                        <span
                          className={styles.icon}
                          onClick={() => {
                            setDeleteModal(true);
                            setSelectedItemId(each.Survey_id);
                          }}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate
              page={page}
              data={filteredNotSentData}
              setPage={setPage}
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <h6>Active Survey(s) - Sent</h6>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Survey Name</th>
                  <th>Survey Type</th>
                  <th>Number of Questions</th>
                  <th>Multiple Choice Questions</th>
                  <th>Text Based Questions</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Survey Details</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredSentData.map(each => (
                  <tr key={each?.id}>
                    <td>{each?.Survey_name}</td>
                    <td>{each?.Survey_type}</td>
                    <td>{each?.No_of_questions}</td>
                    <td>{each?.No_of_multiple_choice}</td>
                    <td>{each?.No_of_text_based}</td>
                    <td>{each?.Start_date}</td>
                    <td>{each?.End_Date}</td>
                    <td>
                      <a href="#">Preview</a>
                    </td>
                    <td>
                      <div className={styles.iconContainer}>
                        <span
                          className={styles.icon}
                          onClick={() => {
                            editSurvey(each.Survey_id);
                            setUpdateID(each.Survey_id);
                          }}>
                          <ViewPen />
                        </span>
                        <span
                          className={styles.icon}
                          onClick={() => {
                            setDeleteModal(true);
                            setSelectedItemId(each.Survey_id);
                          }}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={filteredSentData} setPage={setPage} />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <h6>Closed Survey(s)</h6>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Survey Name</th>
                  <th>Survey Type</th>
                  <th>Number of Questions</th>
                  <th>Multiple Choice Questions</th>
                  <th>Text Based Questions</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Survey Details</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredClosedData.map(each => (
                  <tr key={each?.id}>
                    <td>{each?.Survey_name}</td>
                    <td>{each?.Survey_type}</td>
                    <td>{each?.No_of_questions}</td>
                    <td>{each?.No_of_multiple_choice}</td>
                    <td>{each?.No_of_text_based}</td>
                    <td>{each?.Start_date}</td>
                    <td>{each?.End_Date}</td>
                    <td>
                      <a href="#">Preview</a>
                    </td>
                    <td>
                      <div className={styles.iconContainer}>
                        <span
                          className={styles.icon}
                          onClick={() => {
                            editSurvey(each.Survey_id);
                            setUpdateID(each.Survey_id);
                          }}>
                          <ViewPen />
                        </span>
                        <span
                          className={styles.icon}
                          onClick={() => {
                            setDeleteModal(true);
                            setSelectedItemId(each.Survey_id);
                          }}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={filteredClosedData} setPage={setPage} />
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && (
          <MenuDeleteModal
            onClose={onClose}
            onDelete={() => deleteSurvey(selectedItemID)}
          />
        )}
      </div>
    </CallCenterStructure>
  );
};

export default SurveyStructureMaster;
