import React, {useState, useEffect} from 'react';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';

import {Times, ViewPen} from '../../assets/icons';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import styles from '../../styles/CompanyBranchMaster.module.scss';
import Dropdown from '../../component/DropDown/DropDown';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import { Headers } from '../../service/Essentials';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';


const CompanyBranchMaster = () => {
  const [clearModal, setClearModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [countryID, setCountryID] = useState(1);
  const [stateID, setStateID] = useState('');
  const [cityID, setCityID] = useState('');
  const [zoneID, setZoneID] = useState('');
  const [regionID, setRegionID] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [zone, setZone] = useState('');
  const [region, setRegion] = useState('');
  const [branchCode, setBranchCode] = useState('');
  const [branchName, setBranchName] = useState('');
  const [branchAddress, setBranchAddress] = useState('');
  const [seriesPOSNumber, setSeriesPOSNumber] = useState('');
  const [updateID, setUpdateID] = useState('');
  const [selectedItemID, setSelectedItemId] = useState('');

  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState(null);
  const queryClient = useQueryClient();

  const onClose = () => {
    setDeleteModal(false);
  };
  const handleStateID = id => {
    setStateID(id);
  };
  const handleState = name => {
    setState(name);
  };
  const handleCountryID = id => {
    setCountryID(id);
  };
  const handleCountry = name => {
    setCountry(name);
  };
  const handleCityID = id => {
    setCityID(id);
  };
  const handleCity = name => {
    setCity(name);
  };
  const handleRegionID = id => {
    setRegionID(id);
  };
  const handleRegion = name => {
    setRegion(name);
  };
  const handleZoneID = id => {
    setZoneID(id);
  };
  const handleZone = name => {
    setZone(name);
  };

  const formData = {
    branch_code: branchCode,
    branch_name: branchName,
    branch_address: branchAddress,
    country_currency_id: countryID,
    region_id: regionID,
    zone_id: zoneID,
    state_id: stateID,
    city_id: cityID,
    starting_series_of_pos_bill_no: seriesPOSNumber,
  };

  //RedirectToLogin();

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const fetchCompanyBranch = async page => {
    const res = await axiosProperty.get(
      ApiConfig.fetchCompanyBranch + `?page=${page}`,
      {
        headers: Headers(),
      },
    );
    return res.data;
  };
  const {data, status} = useQuery(
    ['companybranches', page],
    () => fetchCompanyBranch(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = searchTerm => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data?.data) {
      const filtered = data.data.filter(
        (item) =>
          item.branch_name.toLowerCase().includes(searchTerm.toLowerCase())||
          item.branch_code.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  const addCompanyBranch = async () => {
    try{
        await axiosProperty.post(ApiConfig.createCompanyBranch, formData, 
        {
          headers:Headers(),
      }
        );
        
  setErrors(null);
  setSelectedImage(null);
  setSelectedImageName('');
  setCountryID('');
  setRegionID('');
  setStateID('');
  setCityID('');
  setZoneID('');
  setCountry('');
  setRegion('');
  setZone('');
  setState('');
  setCity('');
  setBranchAddress('');
  setBranchCode('');
  setBranchName('');

  swal({
    title: "Success!",
    text: "Company branch created successfully",
    icon: "success",
  });

    }catch(err){
      if(err.response){
        setError(err.response.data.message);
        setErrors(err.response.data);
      }else{
      setError('Something went wrong, try again'); 
      }
      console.log(err);
      swal({
        title: 'Error!',
        text: error,
        icon: 'error',
      });
    }
  };

  const {mutate: addCompanyBranchMutation} = useMutation({
    mutationFn: addCompanyBranch,
    onSuccess: () => {
      queryClient.invalidateQueries(['companybranches']);
    },
  });

  const updateCompanyBranch = async () => {
    try {
      await axiosProperty.patch(
        ApiConfig.updateCompanyBranch + `/${updateID}`,
        formData,
        {
          headers:Headers(),
      }
        );
        setErrors(null);
        setSelectedImage(null);
        setSelectedImageName('');  
        setCountryID('');
        setRegionID('');
        setStateID('');
        setCityID('');
        setZoneID('');
        setCountry('');
        setRegion('');
        setZone('');
        setState('');
        setCity('');
        setUpdateID('');
        setBranchAddress('');
        setBranchCode('');
        setBranchName('');
        setIsSubmit(true);
        setIsUpdate(false);

      swal({
        title: 'Success!',
        text: 'Company branch updated successfully',
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data);
      }else{
       setError('Something went wrong, try again'); 
      }
      console.log(err);

      swal({
        title: 'Error!',
        text: error,
        icon: 'error',
      });
    }
  };

  const {mutate: updateCompanyBranchMutation} = useMutation({
    mutationFn: updateCompanyBranch,
    onSuccess: () => {
      queryClient.invalidateQueries(['companybranches']);
    },
  });

  const editCompanyBranch = async id => {
    const res = await axiosProperty.get(
      ApiConfig.editCompanyBranch + `/${id}`,
      {
        headers: Headers(),
      },
    );
    setIsUpdate(true);
    setIsSubmit(false);

  setCountryID(res.data.data.country_currency_id);
  setRegionID(res.data.data.region_id);
  setStateID(res.data.data.state_id);
  setCityID(res.data.data.city_id);
  setZoneID(res.data.data.zone_id);
  setBranchAddress(res.data.data.branch_address);
  setBranchCode(res.data.data.branch_code);
  setBranchName(res.data.data.branch_name);
  setSeriesPOSNumber(res.data.data.starting_series_of_pos_bill_no || '');
  setCountry(res.data.data.country || '');
  setRegion(res.data.data.region || '');
  setZone(res.data.data.zone || '');
  setState(res.data.data.state || '');
  setCity(res.data.data.city || '');
 
  };

  const deleteCompanyBranch = async (id) => {
    try{
      const res = await axiosProperty.delete(ApiConfig.deleteCompanyBranch+`/${id}`,
      {
        headers: Headers(),
      },
    );
    setErrors(null);
    setDeleteModal(false); 
    swal({
      title: 'Success!',
      text: 'Company branch deleted successfully',
      icon: 'success',
    });
    queryClient.invalidateQueries(['companybranches']);
    }catch(err){
      if(err.response){
        setError(err.response.data.message);
        setErrors(err.response.data);
        }else{
       setError('Something went wrong, probably network'); 
        }
        console.log(err);
        swal({
          title: "Error!",
          text: error,
          icon: "error",
        });
    }
    
};
  
  return (
    <CallCenterStructure>
      <div className={styles.loyaltyProgramMaster}>
        <div className={styles.headerText}>
          <p>Company Branch Master</p>
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Enter Branch Code<sup>*</sup>
              </label>
              <input
                value={branchCode}
                type="text"
                onChange={e => setBranchCode(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Enter Branch Name<sup>*</sup>
              </label>
              <input
                value={branchName}
                type="text"
                onChange={e => setBranchName(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer2}>
              <label htmlFor="">
                Enter Branch Address<sup>*</sup>
              </label>
              <textarea
                value={branchAddress}
                name=""
                id=""
                onChange={e => setBranchAddress(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer3}>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                Select Country<sup>*</sup>
              </label>
              <Dropdown api={ApiConfig.fetchCountry} onSelect={handleCountryID} onSelectOption={handleCountry} retrievedValue={countryID} column={'name'} key="country" />
            </div>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                Select Region<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchRegion(countryID)} onSelect={handleRegionID} onSelectOption={handleRegion} retrievedValue={regionID} column={'name'} key="region"/>
            </div>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                Select Zone<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchZone(regionID)} onSelect={handleZoneID} onSelectOption={handleZone} retrievedValue={zoneID} column={'name'} key="zone"/>
            </div>
          </div>
          <div className={styles.fieldContainer3}>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                Select State<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchState(zoneID)} onSelect={handleStateID} onSelectOption={handleState} retrievedValue={stateID} column={'name'} key="state"/>
            </div>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                Select City<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchCity(stateID)} onSelect={handleCityID} onSelectOption={handleCity} retrievedValue={cityID} column={'name'} key="city" />
            </div>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                Enter Starting Series of PoS Bill No.<sup>*</sup>
              </label>
              <input
                value={seriesPOSNumber}
                type="text"
                onChange={e => setSeriesPOSNumber(e.target.value)}
              />
            </div>
          </div>

          <div className={styles.buttonContainer}>
            {isSubmit && (
              <button
                className={styles.submit}
                onClick={() => addCompanyBranchMutation()}>
                Submit
              </button>
            )}
            {isUpdate && (
              <button
                className={styles.submit}
                onClick={() => updateCompanyBranchMutation()}>
                Update
              </button>
            )}
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
          <ErrorMessage errMsg={errors} />
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Branch Code</th>
                  <th>Branch Name</th>
                  <th>Branch Address</th>
                  {/* <th>Name Of Points</th> */}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map(each => (
                  <tr key={each.point}>
                    <td>{each.branch_code}</td>
                    <td>{each.branch_name}</td>
                    <td>{each.branch_address}</td>
                    <td>
                      <div className={styles.iconContainer}>
                        <span
                          className={styles.icon}
                          onClick={() => {
                            editCompanyBranch(each.id);
                            setUpdateID(each.id);
                          }}>
                          <ViewPen />
                        </span>
                        <span
                          className={styles.icon}
                          onClick={() => {
                            setDeleteModal(true);
                            setSelectedItemId(each.id);
                          }}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={data} setPage={setPage} />
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && (
          <MenuDeleteModal
            onClose={onClose}
            onDelete={() => deleteCompanyBranch(selectedItemID)}
          />
        )}
      </div>
    </CallCenterStructure>
  );
};

export default CompanyBranchMaster;
