import React, {useState, useRef, useEffect} from 'react';
import styles1 from '../../styles/TransactionDetails.module.scss';
import CartStructure from '../CartStructure/CartStructure';
import styles from '../../styles/Profile.module.scss';
import {Link} from 'react-router-dom';
import Dropdown from '../../component/DropDown/DropDown';
import TransactionTable from '../../component/TransactionTable/TransanctionTable';
import Modal from '../../component/Modal/Modal';

const TransactionDetails = () => {
  const modalRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const onClose = () => {
    setShowModal(false);
  };

  const data = [
    {
      ORDERNUMBER: 24395,
      DATE: '2023-05-09',
      ITEMNAME: 'Mfed Crinkle Mesh Skirt',
      ITEMCODE: 'LSL-KON-SKI-83802-14321',
      Quantity: 1,
      POINTSREDEEMEDPERITEM: 18743,
      TOTALPOINTSREDEEMED: 18743,
      REDEMPTIONTYPE: 'Redeem in Person',
      VOUCHERNO: '59615196132CC28C',
      PARTNERNAME: 'Konga',
      PARTNERADDRESS: 'Idimu RoadIkotun Bus Stop',
      REMARK: 'Null',
      STATUS: 'Cancelled',
    },
  ];

  const data1 = [
    {
      Date: '2023-05-08',
      'Transaction No': 'Redemption',
      Description:
        'Item Name: Simply Baked Beans In Tomato 420gRedemption Type : Redeem in PersonStatus : Issued',
      'Order Number': 0,
      Receipt: 'View',

      Credit: 1800,
      Debt: 0,
      Bal: '28,000',
    },
    {
      Date: '2023-05-08',
      'Transaction Type': 'Redemption',
      Description:
        'Item Name: Simply Baked Beans In Tomato 420gRedemption Type : Redeem in PersonStatus : Issued',
      'Order Number': 0,
      Receipt: 'View',
      Credit: 1800,
      Debt: 0,
      Bal: '28,000',
    },
  ];

  useEffect(() => {
    const handleCloseModal = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false);
      }
    };

    window.addEventListener('mousedown', handleCloseModal);

    return () => {
      window.removeEventListener('mousedown', handleCloseModal);
    };
  }, []);

  return (
    <CartStructure>
      <div className={styles.mainContainer}>
        <div className={styles.profileNavOuterContainer}>
          <div className={styles.profileNav}>
            <div>
              <Link
                className={styles1.unActiveLink}
                to="/call-center/member-profile">
                Profile
              </Link>
            </div>
            <div>
              <Link
                className={styles1.unActiveLink}
                to="/call-center/redeem-catalogue">
                Redeem Catalogue
              </Link>
            </div>
            <div className={styles.currentNav}>
              <Link
                className={`${styles.currentNav} ${styles.linkstyles}`}
                to="/call-center/transaction-details">
                Transaction details
              </Link>
            </div>
            <div>
              <Link
                className={styles1.unActiveLink}
                to="/call-center/member-query-record">
                Member Query Record
              </Link>
            </div>
            <div>
              <Link
                className={styles1.unActiveLink}
                to="/call-center/log-query">
                Log Query
              </Link>
            </div>
            <div>
              <Link
                className={styles1.unActiveLink}
                to="/call-center/add-family-card">
                Add Family Card
              </Link>
            </div>
          </div>
        </div>
        <div className={styles1.transactionDetailsInputContainer}>
          <p>Transaction details</p>
          <div className={styles1.calendarContainer}>
            <div className={styles1.calendarInnerContainer}>
              <label htmlFor="">From Date</label>
              <input type="date" />
            </div>
            <div className={styles1.calendarInnerContainer}>
              <label htmlFor="">To Date</label>
              <input type="date" />
            </div>
            <div className={styles1.calendarInnerContainer}>
              <label htmlFor="">Transaction Type</label>
              <Dropdown />
            </div>
          </div>
        </div>
        <div className={styles1.transactionTable}>
          <div className={styles1.tableContainer}>
            <TransactionTable
              data={data1}
              setShowModal={setShowModal}
              // handleView={handleView}
              // open={open}
            />
          </div>
          <div className={styles1.paginationPage}> pagination</div>
        </div>
        {/* <div className={styles1.transactionTable}>
          <p>Shipping Details for Redeem Transactions</p>
          <div className={styles1.tableContainer}>
            <TransactionTable
              data={data1}
              setShowModal={setShowModal}
              // handleView={handleView}
              // open={open}
            />
          </div>
          <div className={styles1.paginationPage}> pagination</div>
        </div> */}
        {showModal && (
          <Modal
            name="Transaction Type :Redemption"
            onClose={onClose}
            data={data}
            modalRef={modalRef}
          />
        )}
      </div>
    </CartStructure>
  );
};

export default TransactionDetails;
