import React, {useState} from 'react';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';
import Dropdown from '../../component/DropDown/DropDown';
import styles from '../../styles/GenerateLicenseKeyForStandaloneCompany.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';

const GenerateLicenseKeyForStandaloneCompany = () => {
  const [clearModal, setClearModal] = useState(false);
  return (
    <CallCenterStructure>
      <div className={styles.generateKeyContainer}>
        <div className={styles.headerText}>
          <p>Generate License Key (Standalone)</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select Company Name<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select License Period<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer2}>
              <label htmlFor="">
                New License Key<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <button className={styles.generateBtn}>Generate License Key</button>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Submit</button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
      </div>
    </CallCenterStructure>
  );
};

export default GenerateLicenseKeyForStandaloneCompany;
