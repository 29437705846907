import React, { useState, useEffect } from "react";
import { Times } from "../../assets/icons";
import styles2 from "../../styles/Modal.module.scss";
import styles from "../../styles/AuctionMaster.module.scss";

import Dropdown from "../../component/DropDown/DropDown";
import { useMutation, useQueryClient } from "react-query";
import ImageDragAndDrop from "../../component/ImageDragAndDrop/ImageDragAndDrop";
import defaultImage from "../../assets/images/defaultImage.png";
import axiosProperty from "../../service/HttpConfig";
import ApiConfig from "../../service/ApiConfig";
import { Headers, ApproveWinnerHeaders } from "../../service/Essentials";
import swal from "sweetalert";

const EditAuctionModal = ({ name, onClose, data, modalRef }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState("");
  const [programID, setProgramID] = useState("");
  const [programName, setProgramName] = useState("");
  const [closeTime, setCloseTime] = useState("");

  const handleProgramID = () => {
    const getCompanyId = JSON.parse(sessionStorage.getItem("company"));
    setProgramID(getCompanyId.id);
    setFormData((prevFormData) => ({
      ...prevFormData,
      program_id: getCompanyId.id,
    }));
  };

  const handleProgramName = (name) => {
    setProgramName(name);
    setFormData((prevFormData) => ({
      ...prevFormData,
      program: name,
    }));
  };

  const handleHourID = (id) => {};

  const handleMinuteID = (id) => {};

  const handleHour = (name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      hour: name,
    }));
  };

  const handleMinute = (name) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      minute: name,
    }));
  };

  const queryClient = useQueryClient();

  const [error, setError] = useState("oops... something went wrong");
  const [updateID, setUpdateID] = useState();

  const [errors, setErrors] = useState({
    //updated data
    minimum_bid: "",
    start_date: "",
    end_date: "",
    prize_description: "",
    auction_img_url: "",
    prize: "",
    program: "",
    auction_name: "",
    minimum_increment: "",
    close_time: "",
    hour: "",
    minute: "",
    program_id: "",
  });

  const [formData, setFormData] = useState({
    minimum_bid: data?.minimum_bid,
    start_date: data?.valid_from,
    end_date: data?.valid_until,
    prize_description: data?.prize_description,
    auction_img_url: data?.logo,
    prize: data?.prize,
    program_id: "",
    program: "",
    auction_name: data?.auction_name,
    minimum_increment: data?.minimum_increment,
    closeTime: data?.closeTime,
    hour: "",
    minute: "",
  });
  useEffect(() => {
    setFormData(data);
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  //for the time format
  useEffect(() => {
    let hour = 0;
    let min = 0;

    if (formData.hour <= 9) {
      hour = `0${formData?.hour}`;
    } else {
      hour = `${formData?.hour}`;
    }
    if (formData.minute <= 9) {
      min = `0${formData?.minute}`;
    } else {
      min = `${formData?.minute}`;
    }

    setCloseTime(`${hour}:${min}`);
    setFormData((prevFormData) => ({
      ...prevFormData,
      close_time: `${hour}:${min}`,
    }));
  }, [formData.minute, formData.hour, closeTime]);

  var hourArray = [];

  for (var i = 0; i <= 23; i++) {
    hourArray.push({ id: i, value: i });
  }

  var minuteArray = [];

  for (var ii = 0; ii <= 59; ii++) {
    minuteArray.push({ id: ii, value: ii });
  }

  useEffect(() => {
    setUpdateID(data.id);
  }, [data]);

  const updateAuction = async () => {
    try {
      await axiosProperty.patch(
        ApiConfig.updateAuction(updateID),
        {
          minimum_bid: formData?.minimum_bid,
          start_date: formData?.start_date,
          end_date: formData?.end_date,
          prize_description: formData?.prize_description,
          auction_img_url: formData?.selectedImage,
          prize: formData?.prize,
          loyalty_program_id: programID,
          program: programName,
          auction_name: formData?.auction_name,
          minimum_increment: formData?.minimum_increment,
          hour: formData?.hour,
          minute: formData?.minute,
          close_time: closeTime,
        },
        {
          headers: ApproveWinnerHeaders(),
        }
      );

      setErrors({});
      swal({
        title: "Success!",
        text: "Auction updated successfully",
        icon: "success",
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: "Error!",
          text: error,
          icon: "error",
        });
      } else {
        setError("Something went wrong, probably network");
        swal({
          title: "warning!",
          text: error,
          icon: "error",
        });
      }
    }
  };

  const { mutate: updateAuctionMutation } = useMutation({
    mutationFn: updateAuction,
    onSuccess: () => {
      queryClient.invalidateQueries(["auctions"]);
    },
  });

  const handleReset = () => {
    setFormData({});
  };

  return (
    <div className={styles2.modalOverlay}>
      <div className={styles2.modalEditAuction} ref={modalRef}>
        <div className={styles2.modalHeader}>
          <h3 className={styles2.modalTitle}>{name}</h3>
          <span className={styles2.closeIcon} onClick={onClose}>
            <Times />
          </span>
        </div>
        <div className={styles2.modalContent}>
          <div className={styles.inputContainer}>
            <div className={styles.fieldContainer1}>
              <div className={styles.innerFieldContainer1}>
                <label htmlFor="">
                  Loyalty Programme<sup>*</sup>
                </label>
                <Dropdown
                  header={Headers()}
                  api={ApiConfig.fetchLoyaltyProgram}
                  onSelect={handleProgramID}
                  onSelectOption={handleProgramName}
                  column={"name"}
                  retrievedValue={formData.program}
                  key="programme_id"
                />
                {errors?.program && (
                  <p className="text-danger">{errors?.program}</p>
                )}
              </div>
              <div className={styles.innerFieldContainer1}>
                <label htmlFor="">
                  Valid From<sup>*</sup>
                </label>
                <input
                  type="date"
                  name="start_date"
                  onChange={handleChange}
                  value={formData.start_date}
                />
                {errors?.start_date && (
                  <p className="text-danger">{errors?.start_date}</p>
                )}
              </div>
              <div className={styles.innerFieldContainer1}>
                <label htmlFor="">
                  Valid Till<sup>*</sup>
                </label>
                <input
                  type="date"
                  name="end_date"
                  onChange={handleChange}
                  value={formData.end_date}
                />
                {errors?.end_date && (
                  <p className="text-danger">{errors?.end_date}</p>
                )}
              </div>
            </div>
            <div className={styles.fieldContainer2}>
              <div className={styles.innerFieldContainer2}>
                <label htmlFor="">
                  Auction Close Time (HH)<sup>*</sup>
                </label>
                <Dropdown
                  onSelect={handleHourID}
                  onSelectOption={handleHour}
                  column={"value"}
                  retrievedValue={formData.hour}
                  key="hour"
                  enumType={hourArray}
                />
                {errors?.hour && <p className="text-danger">{errors?.hour}</p>}
              </div>
              <div className={styles.innerFieldContainer2}>
                <label htmlFor="">
                  Auction Close Time (MM)<sup>*</sup>
                </label>
                <Dropdown
                  onSelect={handleMinuteID}
                  onSelectOption={handleMinute}
                  column={"value"}
                  retrievedValue={formData.minute}
                  key="minute"
                  enumType={minuteArray}
                />
                {errors?.minute && (
                  <p className="text-danger">{errors?.minute}</p>
                )}
              </div>
              <div className={styles.innerFieldContainer2}>
                <label htmlFor="">
                  Auction Name<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="auction_name"
                  onChange={handleChange}
                  value={formData.auction_name}
                />
                {errors?.auction_name && (
                  <p className="text-danger">{errors?.auction_name}</p>
                )}
              </div>
              <div className={styles.innerFieldContainer2}>
                <label htmlFor="">
                  Prize<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="prize"
                  onChange={handleChange}
                  value={formData.prize}
                />
                {errors?.prize && (
                  <p className="text-danger">{errors?.prize}</p>
                )}
              </div>
            </div>
            <div className={styles.fieldContainer3}>
              <div className={styles.innerFieldContainer3}>
                <label htmlFor="">
                  Minimum Points to Bid<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="minimum_bid"
                  onChange={handleChange}
                  value={formData.minimum_bid}
                />
                {errors?.minimum_bid && (
                  <p className="text-danger">{errors?.minimum_bid}</p>
                )}
              </div>
              <div className={styles.innerFieldContainer3}>
                <label htmlFor="">
                  Minimum Increment<sup>*</sup>
                </label>
                <input
                  type="text"
                  name="minimum_increment"
                  onChange={handleChange}
                  value={formData.minimum_increment}
                />
                {errors?.minimum_increment && (
                  <p className="text-danger">{errors?.minimum_increment}</p>
                )}
              </div>
            </div>
            <div className={styles.itemInputContainer4}>
              <div className={styles.defaultImageContainer}>
                <div className={styles.imageContainer}>
                  {selectedImage ? (
                    <img src={selectedImage} alt="default" />
                  ) : (
                    <img src={defaultImage} alt="default" />
                  )}
                </div>
                <p>Image</p>
              </div>

              <div className={styles.uploadContainer}>
                <div className={styles.uploadInputContainer}>
                  <label htmlFor="">Image (maximum size of 100kb)</label>
                  <ImageDragAndDrop
                    selectedImageName={selectedImageName}
                    setSelectedImageName={setSelectedImageName}
                    selectedImage={selectedImage}
                    setSelectedImage={setSelectedImage}
                  />
                </div>
              </div>
            </div>
            <div className={styles.fieldContainer4}>
              <label htmlFor="">Prize Description</label>
              <input
                type="text"
                name="prize_description"
                onChange={handleChange}
                value={formData.prize_description}
              />
              {errors?.prize_description && (
                <p className="text-danger">{errors?.prize_description}</p>
              )}
            </div>
            <div className={styles.buttonContainer}>
              <button
                className={styles.submit}
                onClick={(e) => {
                  e.preventDefault();
                  updateAuctionMutation();
                }}
              >
                Update
              </button>
              <button className={styles.reset} onClick={handleReset}>
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAuctionModal;
