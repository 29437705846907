import React, {useState} from 'react';
import {useMutation} from 'react-query';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import InvalidPin from '../../component/InvalidPin/InvalidPin';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
//import { getUser } from '../../service/common';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';
import swal from 'sweetalert';
import {TransactionHeaders, ProgramID} from '../../service/Essentials';

const ManualDebitTransaction = () => {

const [showModal, setShowModal] = useState(false);

const onClose = () => {
  setShowModal(false);
};

//const user = getUser();

  //const program_id = user.user_group_id == 1 ? 2 : user.user_group_id;
  const program_id = ProgramID();

  // const dataString = sessionStorage.getItem('profile');
  // if (dataString) {
  //   var profile = JSON.parse(dataString);
  // } else {
  // profile = {};
  // }
  // const membership_id = profile?.Membership_id;
  // const enrolment_id = profile?.Enrollment_id;
  // const pin = profile?.pin;

  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState({"debit_point":'', "remark":'', "membership_id":'', pin:''});
  const [formData, setFormData] = useState({"debit_point":'', "remark":'', "membership_id":'', pin:''});
  const [clearModal,setClearModal] = useState(false)
  
  console.log(formData);

  const handleChange = e => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const addManualDebit = async () => {
    try{
       const res = await axiosProperty.post(ApiConfig.createManualDebit(1), formData, 
        {
          headers:TransactionHeaders(),
      }
        );
        if(res.data.status == 'Failed!'){
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          }); 
          return;
        }
      setFormData({debit_point:'', remark:'', membership_id:'', pin:''});
      setErrors({});
        swal({
          title: "Success!",
          text: "Manual debit was successful",
          icon: "success",
        });
    }catch(err){
      if(err.response){
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: addManualDebitMutation} = useMutation({
    mutationFn: addManualDebit,
  });

  return (
    <CallCenterStructure>
      <div className="mt-5 p-4">
        <h4 className="pb-4">Manual Debit Transaction</h4>
        <main className="border border-light p-3">
          <form>
            <div className='row'>
            <div className='form-group col-12 col-md-6'>
                <label htmlFor='pointsDebited'>Membership ID *</label>
                <input className='w-100' type='text' name='membership_id' onChange={handleChange} value={formData?.membership_id} />
              </div>
              <div className='form-group col-12 col-md-6'>
                <label htmlFor='pointsDebited'>Points debited *</label>
                <input type='text' name='debit_point' onChange={handleChange} className='w-100' value={formData?.debit_point} />
             {errors?.debit_point && <p class="text-danger">{errors?.debit_point}</p>}
              </div>
            </div>

            <div className='row'>
              <div className='form-group col-12 col-md-6'>
                <label htmlFor='pin'>Pin *</label>
                <input className='w-100' name="pin" type='text' placeholder='pin' onChange={handleChange} value={formData?.pin} />
              </div>
              <div className='form-group col-12 col-md-6'>
                <label htmlFor='remark'>Remarks</label>
                <input type='text' name="remark" onChange={handleChange} className='w-100' value={formData?.remark} />
                {errors?.remark && <p class="text-danger">{errors?.remark}</p>}
              </div>
            </div>

            <div className="form-group d-flex justify-content-between d-md-flex">
              <button
                className="mr-4"
                onClick={e => {
                  e.preventDefault();
                  addManualDebitMutation();
                }}>
                Submit
              </button>
              <button
                className="resetButton"
                type="button"
                onClick={() => setClearModal(true)}>
                Reset
              </button>
            </div>
          </form>
        </main>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {showModal && <InvalidPin onClose={onClose} />}
      </div>
    </CallCenterStructure>
  );
};

export default ManualDebitTransaction;
