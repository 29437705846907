import React, { useState, useEffect } from "react";
import CheckBox from "../../component/CheckBox/CheckBox";
import Dropdown from "../../component/DropDown/DropDown";
import SearchInput from "../../component/SearchInput/SearchInput";
import styles from "../../styles/AssignPrivilegesToCompanyUsers.module.scss";
import CallCenterStructure from "../CallCenterStructure/CallCenterStructure";
import ApiConfig from "../../service/ApiConfig";
import { HeadersOperator } from "../../service/Essentials";
import { useQuery, useMutation } from "react-query";
import axiosProperty from "../../service/HttpConfig";
import CheckboxUpgradePrivilege from "../../component/CheckboxUpgradePrivilege/CheckboxUpgradePrivilege";
import swal from "sweetalert";

const AssignPrivilegesToCompanyUsers = () => {
  const [userType, setUserType] = useState("");
  const [userTypeId, setUserTypeId] = useState(null);
  const [userGroup, setUserGroup] = useState("");
  const [userGroupId, setUserGroupId] = useState(null);
  
  const [companyTypeId, setCompanyTypeId] = useState(null);
  const [page, setpage] = useState(1);
  const [assignPrivilege, setAssignPrivilege] = useState([]);
  const [privilegeCheck, setPrivilegeCheck] = useState([]);
  const [privilegeArr, setPrivilegeArr] = useState([]);
  const [assignedPrivilege, setAssignedPrivilege] = useState([]);
  const [privilegeAssignedCheck, setPrivilegeAssignedCheck] = useState([]);
  const [privilegeAssignedArr, setPrivilegeAssignedArr] = useState([]);
  const [sAll, setsAll] = useState(false);
  const [all, setAll] = useState(false);
  const [error, setError] = useState("oops... something went wrong");


  const handleUserTypeId = (id) => {
    setUserTypeId(id);
    setAssign((prev) => ({ ...prev, user_type_id: id }));
  };
  const handleUserType = (name) => {
    setUserType(name);
  };
  const handleUserGroupId = (id) => {
    setUserGroupId(id);
    setAssign((prev) => ({ ...prev, user_group_id: id }));
  };
  const handleUserGroup = (name) => {
    setUserGroup(name);
  };
  useEffect(()=>{
    const idd = JSON.parse(sessionStorage.getItem('companyTypeId'))
    setCompanyTypeId(idd);
    setAssign((prev) => ({ ...prev, company_type_id: idd }));
  },[])
  
  const [errors, setErrors] = useState({
    user_type_id: "",
    company_type_id: "",
    menu_id: "",
    user_group_id: "",
    parent_id: "",
    operation: "",
  });
  const [assign, setAssign] = useState({
    user_type_id: "",
    company_type_id: "",
    menu_id: "",
    user_group_id: "",
    parent_id: "",
    operation: "",
  });

  const handleReset = () => {
    setUserGroupId(null);
    setUserTypeId(null);
    setAssign({
      user_type_id: "",
      company_type_id: "",
      menu_id: "",
      user_group_id: "",
      parent_id: "",
      operation: "",
    });
  };

  const getAssignPrivilege = async (page) => {
    const res = await axiosProperty.get(
      ApiConfig.fetchPrivilegeToAssign(page),
      {
        headers: HeadersOperator(),
      }
    );

    return res.data;
  };

  const { data: assignPrivilegeData, status } = useQuery(
    ["assignprivilege", page],
    () => getAssignPrivilege(page),
    { keepPreviousData: true }
  );

  useEffect(() => {
    setAssignPrivilege(assignPrivilegeData?.data || []);
  }, [assignPrivilegeData]);

  const getAssignedPrivilege = async (page) => {
    const res = await axiosProperty.get(
      ApiConfig.fetchAssignedPrivilege(userGroupId, companyTypeId, 0),
      {
        headers: HeadersOperator(),
      }
    );

    return res.data;
  };

  const { data: assignedPrivilegeData, status: stat } = useQuery(
    ["assignprivilege", userGroupId, companyTypeId],
    () => getAssignedPrivilege(userGroup, companyTypeId),
    { keepPreviousData: true }
  );

  useEffect(() => {
    setAssignedPrivilege(assignedPrivilegeData?.data || []);
  }, [assignedPrivilegeData]);

  useEffect(() => {
    assignPrivilege?.map((each) => {
      const getItem = privilegeCheck.find((item) => {
        return item.privilege_id === each.id;
      });
      if (!getItem) {
        setPrivilegeCheck((prev) => [
          ...prev,
          {
            name: each.name,
            privilege_id: each.id,
            company_type_id: companyTypeId,
            user_group_id: userGroupId,
            user_type_id: userTypeId,
            tag: false,
          },
        ]);
      }
      if (!getItem && all) {
        setPrivilegeCheck((prev) => [
          ...prev,
          {
            name: each.name,
            privilege_id: each.id,
            company_type_id: companyTypeId,
            user_group_id: userGroupId,
            user_type_id: userTypeId,
            tag: true,
          },
        ]);
      }
      return null;
    });
  }, [
    assignPrivilege,
    all,
    privilegeCheck,
    userGroupId,
    userTypeId,
    companyTypeId,
  ]);

  useEffect(() => {
    assignedPrivilege?.map((each) => {
      const getItem = privilegeAssignedCheck.find((item) => {
        return item.privilege_id === each.id;
      });
      if (!getItem) {
        setPrivilegeAssignedCheck((prev) => [
          ...prev,
          {
            name: each.name,
            privilege_id: each.id,
            company_type_id: companyTypeId,
            user_group_id: userGroupId,
            user_type_id: userTypeId,
            tag: false,
          },
        ]);
      }
      return null;
    });
  }, [
    assignedPrivilege,
    privilegeAssignedCheck,
    userGroupId,
    userTypeId,
    companyTypeId,
  ]);

  const handleCheck = (tag, i) => {
    let solution = privilegeCheck[i];
    solution.tag = !tag;
    let menuCheckClone = [...privilegeCheck];
    menuCheckClone[i] = solution;
    setPrivilegeCheck([...menuCheckClone]);
    if (menuCheckClone[i].tag === true) {
      setPrivilegeArr((prev) => [
        ...prev,
        {
          company_type_id: companyTypeId,
          privilege_id: menuCheckClone[i].privilege_id,
          user_type_id: userTypeId,
          user_group_id: userGroupId,
        },
      ]);
    } else if (menuCheckClone[i].tag === false) {
      const getItem = privilegeArr.find((item) => {
        return item.privilege_id === menuCheckClone[i].privilege_id;
      });
      if (getItem) {
        setPrivilegeArr(
          privilegeArr.filter((itemDel) => {
            return itemDel !== getItem;
          })
        );
      }
    }
  };

  const handleAssignedCheck = (tag, i) => {
    let solution = privilegeAssignedCheck[i];
    solution.tag = !tag;
    let menuCheckClone = [...privilegeAssignedCheck];
    menuCheckClone[i] = solution;
    setPrivilegeAssignedCheck([...menuCheckClone]);
    if (menuCheckClone[i].tag === true) {
      setPrivilegeAssignedArr((prev) => [
        ...prev,
        {
          company_type_id: companyTypeId,
          privilege_id: menuCheckClone[i].privilege_id,
          user_type_id: userTypeId,
          user_group_id: userGroupId,
        },
      ]);
    } else if (menuCheckClone[i].tag === false) {
      const getItem = privilegeAssignedArr.find((item) => {
        return item.privilege_id === menuCheckClone[i].privilege_id;
      });
      if (getItem) {
        setPrivilegeAssignedArr(
          privilegeAssignedArr.filter((itemDel) => {
            return itemDel !== getItem;
          })
        );
      }
    }
  };

  const handleAssignPrivilege = async () => {
    try {
      const res = await axiosProperty.post(
        ApiConfig.createAssignPrivilege,
        privilegeArr,
        {
          headers: HeadersOperator(),
        }
      );

      setErrors({});

      swal({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: "Error!",
          text: error,
          icon: "error",
        });
      } else {
        setError("Something went wrong, probably network");
        swal({
          title: "warning!",
          text: error,
          icon: "error",
        });
      }
    }
  };
  const { mutate: handleAssignPrivilegeMutation } = useMutation({
    mutationFn: handleAssignPrivilege,
  });
// const handleAll = () =>{
//   privilegeCheck?.map((a)=>{
//     if(a.tag === false)
//     {a.tag=true}else{
//       a.tag = false
//     }
//     return null
//   })
// }

  return (
    <div>
      <CallCenterStructure>
        <div className={styles.AssignMenusToCompanyUsers}>
          <div className={styles.headerText}>
            <p>Assign Privileges to Company Users</p>
          </div>
          <div className={styles.inputContainer}>
            <div>
              <div className={styles.field} >
                
                <div className={styles.fieldContainer}>
                  <label htmlFor="">
                    Select User Type<sup>*</sup>
                  </label>
                  <Dropdown
                    header={HeadersOperator()}
                    api={ApiConfig.fetchUserType}
                    onSelect={handleUserTypeId}
                    onSelectOption={handleUserType}
                    column={"name"}
                    retrievedValue={userType}
                    key="name"
                  />
                  {errors?.user_type_id && (
                    <p className="text-danger">{errors?.user_type_id}</p>
                  )}
                </div>
                <div className={styles.fieldContainer}>
                  <label htmlFor="">
                    Select User Group<sup>*</sup>
                  </label>
                  <Dropdown
                    header={HeadersOperator()}
                    api={ApiConfig.fetchUserGroupEach(userTypeId)}
                    onSelect={handleUserGroupId}
                    onSelectOption={handleUserGroup}
                    column={"user_group_name"}
                    retrievedValue={userGroup}
                    key="name"
                  />
                  {errors?.user_group_id && (
                    <p className="text-danger">{errors?.user_group_id}</p>
                  )}
                </div>
                <div className={styles.fieldContainer}>
                  <label htmlFor="">
                    Select User Name<sup>*</sup>
                  </label>
                  <Dropdown />
                </div>
              </div>

              <div className={styles.checkboxContainerTop}>
                <label htmlFor="">Privileges to be assigned</label>
                <div className={styles.searchSection}>
                  <div className={styles.line1}></div>

                  <div className={styles.line2}>
                    <div className={styles.searchInputContainer}>
                      <div className={styles.searchInput}>
                        <SearchInput />
                      </div>
                      <button>Search</button>
                    </div>
                  </div>
                </div>
                <div className={styles.checkboxMainContainerTop}>
                  <div className={styles.checkboxInnerContainerTop}>
                    {/* <div className={styles.line3}>
                      <div>
                      <CheckboxUpgradePrivilege id='all' name='all' checked={all} onChange={handleAll} />
                        <p>Select all</p>
                      </div>
                    </div> */}
                    {status === "error" && (
                      <p className="text-danger">Error Occured!</p>
                    )}

                    {status === "loading" && (
                      <p className="text-info">loading...</p>
                    )}

                    {status === "success" &&
                      privilegeCheck.map(({ name, tag }, i) => (
                        <div className={styles.line4} key={i}>
                          <div>
                            <CheckboxUpgradePrivilege
                              id={i}
                              checked={tag}
                              onChange={() => handleCheck(tag, i)}
                            />
                            <p>{name}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <button className={styles.submit}
                onClick={()=>handleAssignPrivilegeMutation()}>Assign Menu</button>
              </div>
            </div>

            <div className={styles.buttonCheckBTNContainer}>
              <div className={styles.checkboxContainerTop}>
                <label htmlFor="">Assigned Privileges</label>
                <div className={styles.searchSection}>
                  <div className={styles.line1}></div>

                  <div className={styles.line2}>
                    <div className={styles.searchInputContainer}>
                      <div className={styles.searchInput}>
                        <SearchInput />
                      </div>
                      <button>Search</button>
                    </div>
                  </div>
                </div>
                <div className={styles.checkboxMainContainerTop}>
                  <div className={styles.checkboxInnerContainerTop}>
                    {/* <div className={styles.line3}>
                      <div>
                        <CheckboxUpgradePrivilege id='all' name='all' value={all.value} checked={all.checked} onChange={handleAll} />
                        <p>Select all</p>
                      </div>
                    </div> */}
                    {stat === "error" && (
                      <p className="text-danger">Error Occured!</p>
                    )}

                    {stat === "loading" && (
                      <p className="text-info">loading...</p>
                    )}

                    {stat === "success" &&
                      privilegeAssignedCheck.map(({ name, tag }, i) => (
                        <div className={styles.line4} key={i}>
                          <div>
                            <CheckboxUpgradePrivilege
                              id={i}
                              checked={tag}
                              onChange={() => handleAssignedCheck(tag, i)}
                            />
                            <p>{name}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <button className={styles.submit}
                >Assign Menu</button>
              </div>
            </div>
          </div>
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default AssignPrivilegesToCompanyUsers;
