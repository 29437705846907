import React from 'react';
import styles from '../../styles/LSLPoints.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';

const LSLPoints = () => {
  const table1 = [
    {
      partners: 'Jumia',
      cost: '356728.2',
      payable: '159261.10',
      billed: '1751960.21',
    },
    {
      partners: 'The Store',
      cost: '356728.2',
      payable: '159261.10',
      billed: '1751960.21',
    },
    {
      partners: 'Regal Flowers',
      cost: '356728.2',
      payable: '159261.10',
      billed: '1751960.21',
    },
    {
      partners: 'Supermart NG',
      cost: '356728.2',
      payable: '159261.10',
      billed: '1751960.21',
    },
  ];

  return (
    <CallCenterStructure>
      <div className={styles.lslPointsContainer}>
        <div className={styles.headerText}>
          <p>LSL Points Liability Report</p>
        </div>
        <div className={styles.downloadBtnContainer}>
          <button className={styles.downloadBtnPDF}>Download as PDF</button>
          <button className={styles.downloadBtnXLSX}>Download as xlsx</button>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer1}>
            <table className={styles.tableInnerContainer1}>
              <thead>
                <tr>
                  <th>Total out standing liability to LSL Partners</th>
                  <th>Total Cost Payable to Partner</th>
                  <th>Total Cost Payable to Partner</th>
                  <th>Total Billed to Clients</th>
                </tr>
              </thead>
              <tbody>
                {table1.map(each => (
                  <tr>
                    <td>{each.partners}</td>
                    <td>{each.cost}</td>
                    <td>{each.payable}</td>
                    <td>{each.billed}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </CallCenterStructure>
  );
};

export default LSLPoints;
