import React, {useState, useRef} from 'react';
import styles from '../../styles/ImageDragAndDrop.module.scss';

const ImageDragAndDrop = ({
  selectedImage,
  setSelectedImage,
  selectedImageName,
  setSelectedImageName,
  // selectedImagePath,
  // setSelectedImagePath,
}) => {
  // console.log(selectedImageName, selectedImage, 'selectedImage');

  const handleDrop = event => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    handleImageUpload(file);
  };

  const handleDragOver = event => {
    event.preventDefault();
  };

  const handleImageUpload = file => {
    const reader = new FileReader();
    setSelectedImageName(file.name);

    reader.onload = () => {
      setSelectedImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // Browse Computer Function

  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = event => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
    // Handle the uploaded file here
    // console.log('Selected file:', file, event);
    setSelectedImageName(file.name);
    setSelectedImage(file.name);
  };

  // console.log(selectedImage, 'image path');

  return (
    <div className={styles.imageDragAndDropContainer}>
      <div className={styles.uploadImageContainer}>
        <div>
          <button onClick={handleClick} className={styles.browseBTN}>
            Upload
          </button>
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*, .pdf"
            style={{display: 'none'}}
            onChange={handleFileInputChange}
          />
        </div>

        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className={styles.dropBox}>
          {selectedImage ? (
            <p className={styles.dragDorpText}>{selectedImageName}</p>
          ) : (
            <p className={styles.dragDorpText}>Drag and drop file</p>
          )}
        </div>
      </div>
      {/* <div className={styles.submitImageBTN}>
        <button>Submit</button>
      </div> */}
    </div>
  );
};

export default ImageDragAndDrop;
