import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import DownloadButtonPdf from '../../component/DownloadButtonPdf/DownloadButtonPdf';
import DownloadButtonXlsx from '../../component/DownloadButtonXlsx/DownloadButtonXlsx';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const MemberSpecialDateReport = () => {
  const [clearModal, setClearModal] = useState(false);
  const data = [
    {
      receipt: '#75588',
      membId: '67',
      transDate: '23/06/2023',
      membName: 'Kenneth',
      billAmount: 5000,
      redeemAmount: 8000,
      balPaid: 16000,
      pointsGained: 8,
    },
  ];
  return (
    <>
      <CallCenterStructure>
        <div className="mt-5 p-4">
          <h4 className="pb-4">Member Special Date Report</h4>
          <main className="border border-light p-3">
            <form action="">
              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label
                    className="d-flex justify-content-between"
                    htmlFor="fromDate">
                    From Date *
                  </label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="From date"
                  />
                </div>
                <div className="form-group col-12 col-md-6">
                  <label
                    className="d-flex justify-content-between"
                    htmlFor="tillDate">
                    Till Date *
                  </label>
                  <input
                    className="w-100"
                    type="text"
                    placeholder="Till date"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label htmlFor="occassion">Occassion *</label>
                  <Dropdown />
                </div>
              </div>
              <div className="form-group d-flex justify-content-between d-md-flex mt-4">
                <button className="mr-5">Search</button>
                <button
                  className="resetButton"
                  type="button"
                  onClick={() => setClearModal(true)}>
                  Reset
                </button>
              </div>
            </form>
          </main>

          <div
            className="d-flex justify-content-between d-md-flex justify-content-md-start"
            style={{marginTop: '50px'}}>
            <DownloadButtonPdf />
            <DownloadButtonXlsx />
          </div>

          <section className="border border-light mt-4 mb-4">
            <div className="row p-4">
              <div className="col-12 table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="">Membership ID</th>
                      <th className="">Customer Name</th>
                      <th className="">Date of Birth</th>
                      <th className="">Wedd Anniversary Date</th>
                      <th className="">Phone Number</th>
                      <th className="">Email ID</th>
                      <th className="">Current Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((each, index) => (
                      <tr key={index}>
                        <td>{each.receipt}</td>
                        <td>{each.transDate}</td>
                        <td>{each.membId}</td>
                        <td>{each.membName}</td>
                        <td>{each.billAmount}</td>
                        <td>{each.receipt}</td>
                        <td>{each.receipt}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="col-12">Pagination</div>
            </div>
          </section>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
        </div>
      </CallCenterStructure>
    </>
  );
};
export default MemberSpecialDateReport;
