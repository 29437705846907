import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import SearchInput from '../../component/SearchInput/SearchInput';
import {Times, ViewPen} from '../../assets/icons';
import styles from '../../styles/DataMappingForTransaction.module.scss';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const DataMappingForTransaction = () => {
  const [clearModal, setClearModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const onClose = () => {
    setDeleteModal(false);
  };

  const tableData = [
    {
      id: 4,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Granny Nuts Natural Almond',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 3,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Granny Nuts Natural Almond',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 9,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Granny Nuts Natural Almond',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    {
      id: 6,
      name: 'Fidelity Green Rewards',
      code: 'Lsl-THE OTH 521627',
      groupName: 'Granny Nuts Natural Almond',
      itemName: 'candy',

      brandName: 'The Store',
      price: '139150 Points',
    },
    ,
  ];
  return (
    <div>
      {' '}
      <CallCenterStructure>
        <div className={styles.PoSItemOpeningBalanceMaster}>
          <div className={styles.headerText}>
            <p>Data Mapping for Transaction</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field1}>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Select Loyalty Program<sup>*</sup>
                </label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Enter Name for Data Mapping File
                  <sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>

            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Enter No. of Header Rows<sup>*</sup>
                </label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Enter Column No. of Transaction Date<sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Enter Column No. of Membership ID<sup>*</sup>
                </label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Enter Column No. of Product Code<sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Enter Column No. of Product Quantity<sup>*</sup>
                </label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Enter Column No. of Transaction Type Code<sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Enter Column No. of Transaction Channel Code
                </label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Enter Column No. of Transaction Amount<sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Enter Column No. of Account No.<sup>*</sup>
                </label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Enter Column No. of Branch Code<sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Enter Date Format ("dd-mm-yyyy")
                  <sup>*</sup>
                </label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Column No. of Bill No. or Transaction refrence no.
                  <sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>

            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor="">Column Name for Field 1</label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor="">Column No. of Field 1</label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor="">Column Name for Field 2</label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor="">Column No. of Field 2</label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor="">Column Name for Field 3</label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor="">Column No. of Field 3</label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor="">Column Name for Field 4</label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor="">Column No. of Field 4</label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer}>
                <label htmlFor="">Column No. of Field 5</label>
                <input type="text" />
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor="">Column No. of Field 5</label>
                <input type="text" />
              </div>
            </div>

            <div className={styles.buttonContainer}>
              <button className={styles.submit}>Submit</button>
              <button
                className={styles.reset}
                type="button"
                onClick={() => setClearModal(true)}>
                Reset
              </button>
            </div>
          </div>
          <div className={styles.tableSection}>
            <div className={styles.searchInputContainer}>
              <div className={styles.searchInput}>
                <SearchInput />
              </div>
              <button className={styles.searchBTN}>Search</button>
            </div>
            <div className={styles.innerTableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Company Name</th>
                    <th>Loyalty Program Name</th>
                    <th>File Name</th>
                    <th>Header Rows</th>
                    <th>Column No. of Customer Name</th>
                    <th> Column No. of Email ID</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr key={each.id}>
                      <td>{each.code}</td>
                      <td>{each.name}</td>
                      <td>{each.itemName}</td>
                      <td>{each.groupName}</td>
                      <td>{each.brandName}</td>
                      <td>{each.brandName}</td>

                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>
                          <span
                            className={styles.icon}
                            onClick={() => setDeleteModal(true)}>
                            <Times />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default DataMappingForTransaction;
