import React, {useEffect, useState} from 'react';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import styles from '../../styles/CreateSweepstake.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import defaultImage from '../../assets/images/defaultImage.png';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import {Times, ViewPen} from '../../assets/icons';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import PrizeComponent from '../../component/PrizeComponent/PrizeComponent';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import {SweepStakeHeaders} from '../../service/Essentials';
import swal from 'sweetalert';
import RadioButton from '../../component/SingleRadioButton/SingleRadioButton';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CreateSweepstake = () => {
  const [clearModal, setClearModal] = useState(false);
  const queryClient = useQueryClient();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [prizeForm, setPrizeForm] = useState([]);
  const [prizesData, setPrizesData] = useState([]);
  const [page, setPage] = useState(1);
  const [error, setError] = useState('oops... something went wrong');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState();
  const [selectedItemID, setSelectedItemId] = useState('');
  const [activesweepstakes, setActiveSweepStakes] = useState([]);
  const [disabledsweepstakes, setDisabledSweepStakes] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState(null);
  const options = ['Yes', 'No'];

  const [errors, setErrors] = useState({
    start_date: '',
    end_date: '',
    number_of_winners: '',
    //win_once: "",
    prize: '',
    name: '',
    //start_ticket_no: "",
    ticket_prefix: '',
  });
  const [formData, setFormData] = useState({
    start_date: '12-23-2024',
    end_date: '',
    number_of_winners: '',
    //"win_once": "",
    prize: '',
    name: '',
    //"start_ticket_no": (Math.ceil(Math.random() * (6789 - 20) + 20)),
    ticket_prefix: '',
  });

  const handleRadio = option => {
    setSelectedRadio(option);
  };

  const onClose = () => {
    setDeleteModal(false);
  };

  const handleAddComponent = () => {
    const length = prizeForm.length;
    setPrizeForm(
      prizeForm.concat(
        <PrizeComponent setPrizesData={setPrizesData} index={length} key={1} />,
      ),
    );
  };

  //   const [prizes, setPrizes] = useState([]);

  useEffect(() => {
    // Handle form submission or any other operations using the 'prizes' array
    //console.log(prizesData);
  }, [prizesData]);

  //   const handleAddComponent = () => {
  //     setPrizeForm(prevPrizes => [...prevPrizes, null]);
  //   };

  const handleChange = e => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const addSweepStake = async () => {
    try {
      await axiosProperty.post(ApiConfig.createSweepStake, formData, {
        headers: SweepStakeHeaders(),
      });

      setFormData({
        start_date: '',
        end_date: '',
        number_of_winners: '',
        //"win_once": "",
        prize: '',
        name: '',
        // "start_ticket_no": "",
        ticket_prefix: '',
      });
      setErrors({});
      swal({
        title: 'Success!',
        text: 'Sweepstake added successfully',
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.data);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: addSweepStakeMutation} = useMutation({
    mutationFn: addSweepStake,
  });

  const updateSweepStake = async () => {
    try {
      await axiosProperty.post(ApiConfig.updateSweepStake(updateID), formData, {
        headers: SweepStakeHeaders(),
      });
      setIsSubmit(true);
      setIsUpdate(false);
      setFormData({
        start_date: '',
        end_date: '',
        number_of_winners: '',
        win_once: '',
        prize: '',
        name: '',
        start_ticket_no: '',
        ticket_prefix: '',
      });
      setErrors({});
      swal({
        title: 'Success!',
        text: 'Sweepstake updated successfully',
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        if (err.response.data.message !== 'update failed') {
          setErrors(err.response.data.data);
        }
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: updateSweepStakeMutation} = useMutation({
    mutationFn: updateSweepStake,
    onSuccess: () => {
      queryClient.invalidateQueries(['activesweepstakes']);
      queryClient.invalidateQueries(['disabledsweepstakes']);
    },
  });

  const editSweepStake = async id => {
    const res = await axiosProperty.get(ApiConfig.getSweepStake(id), {
      headers: SweepStakeHeaders(),
    });
    setIsUpdate(true);
    setIsSubmit(false);
    setFormData(prevFormData => ({
      ...prevFormData,
      name: res.data.data.name,
      prize: res.data.data.prize,
      ticket_prefix: res.data.data.ticket_prefix,
      start_date: res.data.data.start_date.slice(0, 10),
      end_date: res.data.data.end_date.slice(0, 10),
      win_once: res.data.data.win_once,
      number_of_winners: res.data.data.number_of_winners,
    }));
  };

  const fetchActiveSweepStakes = async page => {
    const res = await axiosProperty.get(ApiConfig.fetchActiveSweepStakes, {
      headers: SweepStakeHeaders(),
    });
    return res.data;
  };

  const {data: activesweepstakedata, status: activeStatus} = useQuery(
    ['activesweepstakes', page],
    () => fetchActiveSweepStakes(page),
    {keepPreviousData: true},
  );

  const fetchDisabledSweepStakes = async page => {
    const res = await axiosProperty.get(ApiConfig.fetchDisabledSweepStakes, {
      headers: SweepStakeHeaders(),
    });
    return res.data;
  };

  const {data: disabledsweepstakedata, status: disabledStatus} = useQuery(
    ['disabledsweepstakes', page],
    () => fetchDisabledSweepStakes(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setActiveSweepStakes(activesweepstakedata?.data?.data || []);
    setDisabledSweepStakes(disabledsweepstakedata?.data?.data || []);
  }, [activesweepstakedata, disabledsweepstakedata]);

  // const data = [
  //   {
  //     name: 'Campaign',
  //     from: '28/6/2023',
  //     to: '28/6/2023',
  //     winner: 'Rasgan',
  //     prize: '$2000',
  //     desc: 'first position',
  //     image: 'first.png',
  //   },
  //   {
  //     name: 'Campaign',
  //     from: '28/6/2023',
  //     to: '28/6/2023',
  //     winner: 'Rasgan',
  //     prize: '$3000',
  //     desc: 'first position',
  //     image: 'first.png',
  //   },
  //   {
  //     name: 'Campaign',
  //     from: '28/6/2023',
  //     to: '28/6/2023',
  //     winner: 'Rasgan',
  //     prize: '$8000',
  //     desc: 'first position',
  //     image: 'first.png',
  //   },
  //   {
  //     name: 'Campaign',
  //     from: '28/6/2023',
  //     to: '28/6/2023',
  //     winner: 'Rasgan',
  //     prize: '$10000',
  //     desc: 'first position',
  //     image: 'first.png',
  //   },
  // ];
  const deleteSweepStake = async id => {
    try {
      const res = await axiosProperty.delete(ApiConfig.deleteSweepStake(id), {
        headers: SweepStakeHeaders(),
      });
      setDeleteModal(false);
      swal({
        title: 'Success!',
        text: 'Delete was successful',
        icon: 'success',
      });
      queryClient.invalidateQueries('disabledsweepstakes');
      queryClient.invalidateQueries('activesweepstakes');
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);
      swal({
        title: 'Warning!',
        text: error,
        icon: 'warning',
      });
    }
  };

  return (
    <CallCenterStructure>
      <div className={styles.createSweepstake}>
        <div className={styles.headerText}>
          <p>Create Sweepstake</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                From Date<sup>*</sup>
              </label>
              <input
                type="date"
                name="start_date"
                value={formData.start_date}
                onChange={handleChange}
              />
              {errors.start_date && (
                <p class="text-danger">{errors.start_date}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                To Date<sup>*</sup>
              </label>
              <input
                type="date"
                name="end_date"
                value={formData.end_date}
                onChange={handleChange}
              />
              {errors.end_date && <p class="text-danger">{errors.end_date}</p>}
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Prize - 1st<sup>*</sup>
              </label>
              <input
                type="number"
                name="prize"
                value={formData.prize}
                onChange={handleChange}
              />
              {errors.prize && <p class="text-danger">{errors.prize}</p>}
            </div>
          </div>
          <div className={styles.itemInputContainer4}>
            <div className={styles.defaultImageContainer}>
              <div className={styles.imageContainer}>
                {selectedImage ? (
                  <img src={selectedImage} alt="default" />
                ) : (
                  <img src={defaultImage} alt="default" />
                )}
              </div>
              <p>Image</p>
            </div>

            <div className={styles.uploadContainer}>
              <div className={styles.uploadInputContainer}>
                <label htmlFor="">
                  Prize - 1st Image (maximum size of 100kb)
                </label>
                <ImageDragAndDrop
                  selectedImageName={selectedImageName}
                  setSelectedImageName={setSelectedImageName}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Sweepstake Name<sup>*</sup>
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && <p class="text-danger">{errors.name}</p>}
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Prize - 1st Description<sup>*</sup>
              </label>
              <input
                type="text"
                name="ticket_prefix"
                value={formData.ticket_prefix.slice(0, 2)}
                onChange={handleChange}
              />
              {errors.ticket_prefix && (
                <p class="text-danger">{errors.ticket_prefix}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Number of Winners<sup>*</sup>
              </label>
              <input
                type="number"
                name="number_of_winners"
                value={formData.number_of_winners}
                onChange={handleChange}
              />
              {errors.number_of_winners && (
                <p class="text-danger">{errors.number_of_winners}</p>
              )}
            </div>
          </div>

          <div className={styles.fieldContainer3}>
            <label htmlFor="">Link to Campaign *</label>
            <RadioButton
              options={options}
              selectedOption={selectedRadio}
              onChange={handleRadio}
            />
            {/* <RadioButtonBlue /> */}
            {/* <button className={styles.addMoreBTN} onClick={handleAddComponent}>
              Add More Prize
            </button> */}
          </div>

          {prizeForm}
          <div className={styles.fieldContainer3}>
            <button className={styles.addMoreBTN} onClick={handleAddComponent}>
              Add More Prize
            </button>
          </div>
          <div className={styles.buttonContainer}>
            {isSubmit && (
              <button
                className={styles.submit}
                onClick={e => {
                  e.preventDefault();
                  addSweepStakeMutation();
                }}>
                Submit
              </button>
            )}
            {isUpdate && (
              <button
                className={styles.submit}
                onClick={e => {
                  e.preventDefault();
                  updateSweepStakeMutation();
                }}>
                Update
              </button>
            )}
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <h6 className={styles.infoText}>
              Sweepstakes which are currently ON or COMPLETED but results are
              yet to be drawn (Active Sweepstakes)
            </h6>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Sweepstake Name</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Winners</th>
                  <th>Prize</th>
                  <th>Description</th>
                  <th>Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {activesweepstakes.map(each => (
                  <tr key={each?.id}>
                    <td>{each?.name}</td>
                    <td>{each?.start_date}</td>
                    <td>{each?.end_date}</td>
                    <td>{each?.number_of_winners}</td>
                    <td>{each?.prize}</td>
                    <td>{each?.slug}</td>
                    <td>{each?.image}</td>
                    <td>
                      <div className={styles.iconContainer}>
                        <span
                          className={styles.icon}
                          onClick={() => {
                            editSweepStake(each.slug);
                            setUpdateID(each.slug);
                          }}>
                          <ViewPen />
                        </span>
                        <span
                          className={styles.icon}
                          onClick={() => {
                            setDeleteModal(true);
                            setSelectedItemId(each.slug);
                          }}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate
              page={page}
              data={activesweepstakedata}
              setPage={setPage}
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <h6 className={styles.infoText}>
              Sweepstakes which are COMPLETED and results have been drawn
              (Inactive Sweepstakes)
            </h6>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Sweepstake Name</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Winners</th>
                  <th>Prize</th>
                  <th>Description</th>
                  <th>Image</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {disabledsweepstakes.map(each => (
                  <tr key={each?.id}>
                    <td>{each?.name}</td>
                    <td>{each?.start_date}</td>
                    <td>{each?.end_date}</td>
                    <td>{each?.number_of_winners}</td>
                    <td>{each?.prize}</td>
                    <td>{each?.slug}</td>
                    <td>{each?.image}</td>
                    <td>
                      <div className={styles.iconContainer}>
                        <span
                          className={styles.icon}
                          onClick={() => {
                            editSweepStake(each.slug);
                            setUpdateID(each.slug);
                          }}>
                          <ViewPen />
                        </span>
                        <span
                          className={styles.icon}
                          onClick={() => {
                            setDeleteModal(true);
                            setSelectedItemId(each.slug);
                          }}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate
              page={page}
              data={disabledsweepstakedata}
              setPage={setPage}
            />
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && (
          <MenuDeleteModal
            onClose={onClose}
            onDelete={() => deleteSweepStake(selectedItemID)}
          />
        )}
      </div>
    </CallCenterStructure>
  );
};

export default CreateSweepstake;
