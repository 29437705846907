import React, {useState} from 'react';
import {Unview, View} from '../../assets/icons';
import styles from '../../styles/TransactionTable.module.scss';

const TransactionTable = ({data, setShowModal}) => {
  const [viewedRows, setViewedRows] = useState([]);
  const handleView = index => {
    setViewedRows(prevItemValues => [...prevItemValues, index]);
    setShowModal(true);
  };

  const isRowViewed = index => {
    return viewedRows.includes(index);
  };

  return (
    <table className={styles.transactionTable}>
      <thead>
        <tr>
          {Object.keys(data[0]).map(key => (
            <th key={key}>{key}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            {Object.entries(row).map(([key, value]) => (
              <td key={key} className={styles.transactionTD}>
                {key === 'Receipt' ? (
                  <div
                    className={styles.removeButton}
                    onClick={() => handleView(index)}>
                    {isRowViewed(index) ? <Unview /> : <View />} {value}
                  </div>
                ) : (
                  value
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TransactionTable;
