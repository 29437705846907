import React, {useState} from 'react';
import styles from '../../styles/ResellerCompanyRegistration.module.scss';
import Dropdown from '../../component/DropDown/DropDown';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import defaultImage from '../../assets/images/defaultImage.png';
import CheckBox from '../../component/CheckBox/CheckBox';
import SearchInput from '../../component/SearchInput/SearchInput';
import {Times, ViewPen} from '../../assets/icons';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const ResellerCompanyRegistration = () => {
  const [clearModal, setClearModal] = useState(false);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);

  const onClose = () => {
    setDeleteModal(false);
  };

  const tableData = [
    {
      name: 'Fidelity',

      companyType: 'Enterprice',
      reg: '647373',
      solution: 'solution',
      address: 'Lekki, Nigeria',
      personName: 'John',
      email: 'smith@doe.ng',
      phone: '08123456789',
    },
    {
      name: 'Fidelity',

      companyType: 'Enterprice',
      reg: '647373',
      solution: 'solution',
      address: 'Lekki, Nigeria',
      personName: 'John',
      email: 'smith@doe.ng',
      phone: '08123456789',
    },
    {
      name: 'Fidelity',

      companyType: 'Enterprice',
      reg: '647373',
      solution: 'solution',
      address: 'Lekki, Nigeria',
      personName: 'John',
      email: 'smith@doe.ng',
      phone: '08123456789',
    },
  ];

  return (
    <CallCenterStructure>
      <div className={styles.resellerCompanyRegistration}>
        <div className={styles.headerText}>
          <p>Reseller Company Registration</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.itemInputContainer1}>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Enter Company Name<sup>*</sup>
              </label>

              <input type="text" />
            </div>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Select Type of Company<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Select Type of Solution<sup>*</sup>
              </label>
              <Dropdown />
            </div>
          </div>
          <div className={styles.textAreasContainer}>
            <div className={styles.textAreaContainer}>
              <label htmlFor="">
                Enter Company Address<sup>*</sup>
              </label>
              <textarea
                name=""
                placeholder="Insert your text here"
                className={styles.mainTextArea}></textarea>
            </div>
            <div className={styles.textAreaContainer}>
              <label htmlFor="">
                Enter P.O. Box Address<sup>*</sup>
              </label>
              <textarea
                name=""
                placeholder="Insert your text here"
                className={styles.mainTextArea}></textarea>
            </div>
          </div>
          <div className={styles.itemInputContainer2}>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Select Country<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Select Region<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Select Zone<sup>*</sup>
              </label>
              <Dropdown />
            </div>
          </div>
          <div className={styles.itemInputContainer2}>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Select State<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Select City<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Select TimeZone<sup>*</sup>
              </label>
              <Dropdown />
            </div>
          </div>
          <div className={styles.itemInputContainer2}>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Enter Company Pri. Person Name<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Enter Company Pri. Person Email Id<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Enter Company Pri. Person Phone No.<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.itemInputContainer2}>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Enter Company Sec. Person Name<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Enter Company Sec. Person Email ID<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Enter Company Sec. Person Phone No.<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.itemInputContainer2}>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Enter Company Website<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">Enter Company Domain</label>
              <input type="text" />
            </div>
            <div className={styles.itemInputInnerContainer1}>
              <label htmlFor="">
                Enter Company Sec. Person Phone No.<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          {/* <div className={styles.itemInputContainer3}>
            <div className={styles.inputField3}>
              <label htmlFor="">
                Enter Weight<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.radioButtonField3}>
              <label htmlFor="">
                Enable Remark Field<sup>*</sup>
              </label>
              <RadioButtonBlue firstText="Enable" secondText="Disable" />
            </div>

            <div className={styles.radioButtonField3}>
              <label htmlFor="">
                Status<sup>*</sup>
              </label>
              <RadioButtonBlue firstText="Enable" secondText="Disable" />
            </div>
          </div> */}
          <div className={styles.itemInputContainer4}>
            <div className={styles.defaultImageContainer}>
              <div className={styles.imageContainer}>
                {selectedImage ? (
                  <img src={selectedImage} alt="default" />
                ) : (
                  <img src={defaultImage} alt="default" />
                )}
              </div>
              <p>Logo</p>
            </div>

            <div className={styles.uploadContainer}>
              <div className={styles.uploadInputContainer}>
                <label htmlFor="">Upload Photograph of Merchandize item</label>
                <ImageDragAndDrop
                  selectedImageName={selectedImageName}
                  setSelectedImageName={setSelectedImageName}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </div>
            </div>
          </div>
          <div className={styles.belowUpload}>
            <label htmlFor="">
              Enter Company Registration No.<sup>*</sup>
            </label>
            <input type="text" />
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Submit</button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
          <div className={styles.inputContainer}>
            <label htmlFor="">Active Companies</label>
            {/* <div className={styles.searchInputContainer}>
              <SearchInput />
              <button className={styles.searchInputBtn}>Search</button>
            </div> */}
            {/* <div className={styles.inputFieldContainer5}>
              <div className={styles.inputFieldInnerContainer5}>
                <label htmlFor="">Select Partner</label>
                <Dropdown />
              </div>
              <div className={styles.inputFieldInnerContainer5}>
                <label htmlFor="">Select Merchandize Category</label>
                <Dropdown />
              </div>
            </div> */}
            <div className={styles.tableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Company Name</th>
                    <th>Type Of Company</th>
                    <th>Type Of Solution</th>
                    <th>Company Reg. No.</th>
                    <th>Company Address</th>
                    <th>Contact Person Name</th>
                    <th>Contact Person Email Id</th>
                    <th>Phone No.</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr>
                      <td>{each.name}</td>
                      <td>{each.companyType}</td>
                      <td>{each.solution}</td>
                      <td>{each.reg}</td>
                      <td>{each.address}</td>
                      <td>{each.personName}</td>
                      <td>{each.email}</td>
                      <td>{each.phone}</td>
                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>

                          <span
                            className={styles.icon}
                            onClick={() => setDeleteModal(true)}>
                            <Times />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.paginationContainer}>
              <p>Pagination</p>
            </div>
          </div>
          <div className={styles.inputContainer}>
            <label htmlFor="">In-Active Companies</label>
            {/* <div className={styles.searchInputContainer}>
              <SearchInput />
              <button className={styles.searchInputBtn}>Search</button>
            </div> */}
            {/* <div className={styles.inputFieldContainer5}>
              <div className={styles.inputFieldInnerContainer5}>
                <label htmlFor="">Select Partner</label>
                <Dropdown />
              </div>
              <div className={styles.inputFieldInnerContainer5}>
                <label htmlFor="">Select Merchandize Category</label>
                <Dropdown />
              </div>
            </div> */}
            <div className={styles.tableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Company Name</th>
                    <th>Type Of Company</th>
                    <th>Type Of Solution</th>
                    <th>Company Reg. No.</th>
                    <th>Company Address</th>
                    <th>Contact Person Name</th>
                    <th>Contact Person Email Id</th>
                    <th>Phone No.</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr>
                      <td>{each.name}</td>
                      <td>{each.companyType}</td>
                      <td>{each.solution}</td>
                      <td>{each.reg}</td>
                      <td>{each.address}</td>
                      <td>{each.personName}</td>
                      <td>{each.email}</td>
                      <td>{each.phone}</td>
                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>

                          <span
                            className={styles.icon}
                            onClick={() => setDeleteModal(true)}>
                            <Times />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.paginationContainer}>
              <p>Pagination</p>
            </div>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && <MenuDeleteModal onClose={onClose} />}
      </div>
    </CallCenterStructure>
  );
};

export default ResellerCompanyRegistration;
