import React, {useState} from 'react';
import {ViewPen} from '../../assets/icons';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';
import Dropdown from '../../component/DropDown/DropDown';
import SearchInput from '../../component/SearchInput/SearchInput';
import styles from '../../styles/WeightMasterSetup.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';

const WeightMasterSetup = () => {
  const [clearModal, setClearModal] = useState(false);
  const tableData = [
    {
      id: 1,
      start: '0.00',
      end: '1.00',
      unit: 'KG',
    },
    {
      id: 2,
      start: '0.00',
      end: '1.00',
      unit: 'KG',
    },
    {
      id: 3,
      start: '0.00',
      end: '1.00',
      unit: 'KG',
    },
  ];
  return (
    <CallCenterStructure>
      <div className={styles.countryCurrencyContainer}>
        <div className={styles.headerText}>
          <p>Weight Master Reference</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputFieldContainer1}>
            <div className={styles.inputFieldInnerContainer1}>
              <label htmlFor="">
                Enter Weight Start Range<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.inputFieldInnerContainer1}>
              <label htmlFor="">
                Enter Weight End Range<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.inputFieldInnerContainer1}>
              <label htmlFor="">
                Select Weight Unit<sup>*</sup>
              </label>
              <Dropdown />
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Submit</button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>

        <div className={styles.inputContainer}>
          <div>
            <div className={styles.searchInputContainer}>
              <div className={styles.inputFieldInnerContainer2}>
                <label htmlFor="">
                  Start Range<sup>*</sup>
                </label>
                <input type="date" />
              </div>
              <div className={styles.inputFieldInnerContainer2}>
                <label htmlFor="">
                  End Range<sup>*</sup>
                </label>
                <input type="date" />
              </div>

              <button className={styles.searchInputBtn}>Search</button>
            </div>
            <div className={styles.tableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>Weight ID</th>
                    <th>Weight Start Range</th>
                    <th>Weight End Range</th>
                    <th>Weight Unit</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr key={each.id}>
                      <td>{each.id}</td>
                      <td>{each.start}</td>
                      <td>{each.end}</td>
                      <td>{each.unit}</td>

                      <td>
                        <span className={styles.icon}>
                          <ViewPen />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.paginationContainer}>
              <p>Pagination</p>
            </div>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
      </div>
    </CallCenterStructure>
  );
};

export default WeightMasterSetup;
