import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import RadioButtonSingle from '../../component/RadioButtonSingle/RadioButtonSingle';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const CompanyGameMaster = () => {
  const [clearModal, setClearModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleRadioButtonChange = value => {
    setSelectedOption(value);
  };

  return (
    <>
      <CallCenterStructure>
        <div className="mt-5 p-4">
          <h4 className="pb-4">Company Game Master</h4>
          <main className="border border-light p-3">
            <form action="">
              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <label className="" htmlFor="selectGame">
                    Select Game *
                  </label>
                  <Dropdown />
                </div>
                <div className="form-group col-12 col-md-6">
                  <p>
                    <label htmlFor="gameFor">Game For</label>
                  </p>
                  <div className="d-flex">
                    <RadioButtonSingle
                      name="GameFor"
                      value="yes"
                      selected={selectedOption === 'yes'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    Yes
                    <div className="w-25"></div>
                    <RadioButtonSingle
                      name="GameFor"
                      value="no"
                      selected={selectedOption === 'no'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    No
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <p>
                    <label htmlFor="linkToGameCampaign">
                      Link To Game Campaign
                    </label>
                  </p>
                  <div className="d-flex">
                    <RadioButtonSingle
                      name="LinkToGameCampaign"
                      value="L1"
                      selected={selectedOption === 'L1'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    Yes
                    <div className="w-25"></div>
                    <RadioButtonSingle
                      name="LinkToGameCampaign"
                      value="L0"
                      selected={selectedOption === 'L0'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    No
                  </div>
                </div>
                <div className="form-group col-12 col-md-6">
                  <p>
                    <label htmlFor="GameForCompetition">
                      Game For Competition
                    </label>
                  </p>
                  <div className="d-flex">
                    <RadioButtonSingle
                      name="GameForCompetition"
                      value="G1"
                      selected={selectedOption === 'G1'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    Yes
                    <div className="w-25"></div>
                    <RadioButtonSingle
                      name="GameForCompetition"
                      value="G0"
                      selected={selectedOption === 'G0'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    No
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-12 col-md-6">
                  <p>
                    <label htmlFor="enableLevel">Enable levels</label>
                  </p>
                  <div className="d-flex">
                    <RadioButtonSingle
                      name="Enablelevel"
                      value="E1"
                      selected={selectedOption === 'E1'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    Yes
                    <div className="w-25"></div>
                    <RadioButtonSingle
                      name="Enablelevel"
                      value="E0"
                      selected={selectedOption === 'E0'}
                      borderWidth={2}
                      borderHeight={2}
                      onChange={handleRadioButtonChange}
                    />
                    No
                  </div>
                </div>
              </div>

              <div className="form-group mt-3 d-flex justify-content-between d-md-flex">
                <button className="mr-5">submit</button>
                <button
                  className="resetButton"
                  type="button"
                  onClick={() => setClearModal(true)}>
                  Reset
                </button>
              </div>
            </form>
          </main>
          {/* Pass your clear input function to clearInputFn */}
          <ClearInputModal
            //   clearInputFn={handleReset}
            clearModalStatus={clearModal}
            clearInputModalState={setClearModal}
          />
        </div>
      </CallCenterStructure>
    </>
  );
};
export default CompanyGameMaster;
