import React, {useState} from 'react';
import styles from '../../styles/CartCard.module.scss';
import Dropdown from '../DropDown/DropDown';

const CardCart = ({image, name, price, updateCartLength}) => {
  const [isPickup, setIsPickup] = useState(false);
  const [isAddedToCART, setIsAddedToCART] = useState(false);
  const [inputChecked, setInputChecked] = useState(false);
  const [itemPickChoice, setItemPickChoice] = useState('Delivery');
  //   const [pickupLocation, setPickupLocation] = useState('');

  const handleRadioChangeDelivery = event => {
    // setIsPickup(event.target.value === 'pickup');
    setInputChecked(false);
    setIsPickup(false);
    setItemPickChoice('Delivery');
  };

  const handleRadioChangePickup = event => {
    // setIsPickup(event.target.value === 'pickup');
    setInputChecked(true);
    setIsPickup(true);
    setItemPickChoice('Pick-Up');
  };

  //   const handlePickupLocationChange = event => {
  //     setPickupLocation(event.target.value);
  //   };

  const handleAddToCart = () => {
    setIsAddedToCART(true);
  const existingItems = JSON.parse(sessionStorage.getItem('cart')) || [];
 // Check if an item with the same name and redemption method already exists
 const existingItemIndex = existingItems.findIndex(
  (item) => item['Item name'] === name
);
//console.log(existingItemIndex);
if (existingItemIndex !== -1) {
    existingItems[existingItemIndex].Quantity += 1;
} else {
  const newItem = { 'Item name':name,'Redemption Method': itemPickChoice, Quantity:1, 'Redeemable Points':price, 'Shipping Points':0, Action:'remove' };
  existingItems.push(newItem);
  }

    sessionStorage.setItem('cart', JSON.stringify(existingItems));
    updateCartLength(existingItems.length);
  };

  //const arrItem = JSON.parse(sessionStorage.getItem('cart'));
  //console.log(arrItem);

  return (
    <div className={styles.card}>
      <img src={image} alt="Product" className={styles.cardImage} />
      <h3 className={styles.cardName}>{name}</h3>
      <p className={styles.cardPrice}>
        <s>N</s>
        {price}
      </p>
      <div className={styles.radioButtons}>
        <div className={styles.radioButton}>
          <div className={styles.myCheckbox} onClick={handleRadioChangePickup}>
            <div
              className={inputChecked ? styles.checked : styles.uncheck}></div>
          </div>
          {/* <input
            type="radio"
            value="pickup"
            checked={isPickup}
            onChange={handleRadioChange}
          /> */}
          <p>Pick-up</p>
        </div>
        <div className={styles.radioButton}>
          {/* <input
            type="radio"
            value="delivery"
            checked={!isPickup}
            onChange={handleRadioChange}
          /> */}
          <div
            className={styles.myCheckbox}
            onClick={handleRadioChangeDelivery}>
            <div
              className={!inputChecked ? styles.checked : styles.uncheck}></div>
          </div>
          <p>Delivery</p>
        </div>
      </div>

      <div className={styles.pickupDropdown}>
        <label htmlFor="">Partner Location</label>
        <Dropdown />
      </div>

      <button
        className={isAddedToCART ? styles.addedToCart : styles.addToCartButton}
        onClick={handleAddToCart}>
        {isAddedToCART ? 'Item added to cart' : 'Add to cart '}
      </button>
    </div>
  );
};

export default CardCart;
