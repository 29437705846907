import React from 'react';
import styles from '../../styles/DownloadButtonXlsx.module.scss';

const DownloadButtonXlsx = () => {
    return ( 
        <>
        <button className={styles.xlsx}>Download as xlsx</button>
        </>
     );
}
 
export default DownloadButtonXlsx;