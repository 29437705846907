import React from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import DownloadButtonPdf from '../../component/DownloadButtonPdf/DownloadButtonPdf';
import DownloadButtonXlsx from '../../component/DownloadButtonXlsx/DownloadButtonXlsx';

const AuditTrackingReport = ()=>{
    return(
    <>
        <CallCenterStructure>
        <div className='mt-5 p-4'>
           <h4 className='pb-4'>Audit Tracking Report</h4>
           <main className='border border-light p-3'>
           <form action="">
               <div className="row">
               <div className="form-group col-12 col-md-6" >
               <label className='d-flex justify-content-between' htmlFor="fromDate">From Date *</label>
                   <input className="w-100" type="text" placeholder='From date' />
               </div>
               <div className="form-group col-12 col-md-6" >
               <label className='d-flex justify-content-between' htmlFor="tillDate">Till Date *</label>
                   <input className="w-100" type="text" placeholder='Till date' />
               </div>
               </div>

               <div className="row">
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="transactionFrom">Transaction From *</label>
                   <Dropdown/>
               </div>
               <div className="form-group col-12 col-md-6" >
               <label htmlFor="selectUserType">Select User Type *</label>
                   <Dropdown/>
               </div>
               </div>

               <div className="row">
               <div className="form-group col-12 col-md-6" >
                   <label htmlFor="enterUser">Enter User *</label>
                   <input className="w-100" type="text" placeholder='' />
               </div>
               <div className="form-group col-12 col-md-6" >
               <label htmlFor="operationMode">Operation Mode *</label>
                   <Dropdown/>
               </div>
               </div>

               <div className='form-group d-flex justify-content-between d-md-flex justify-content-md-start'>
                   <button className='mr-5'>Submit</button>
                   <button className='resetButton'>Reset</button>
                </div>

               <div className="row">
               <div className="form-group col-12 col-md-10" >
               <label htmlFor="selectVoucherNumber">Voucher Number *</label>
                   <Dropdown/>
               </div>
               <div className="form-group col-12 col-md-2" >
                  <button className='mt-4 w-100'>Search</button>
               </div>
               </div>
           </form>
           </main>
           <div className='mt-4 d-flex justify-content-between d-md-flex justify-content-md-start'>
           <DownloadButtonPdf />
           <DownloadButtonXlsx />
           </div>
          
           <section className="border border-light mt-4 mb-4">
                <div className="row p-4">
                <div className="col-12 table-responsive">
                <table className='table'>
                    <thead>
                        <tr>
                        <th className=''>Transaction Date</th>
                        <th className=''>who Did</th>
                        <th className=''>User Type</th>
                        <th className=''>What was Done</th>
                        <th className=''>Menu Option</th>
                        <th className=''>Type of Operation</th>
                        <th className=''>Inserted/Updated/Deleted</th>
                        </tr>
                    </thead>
                    <tbody>
                
                    </tbody>
                </table>
                </div>
                <div className="col-12">Pagination</div>
                </div>
           </section>
           
        </div>
       </CallCenterStructure>
    </>
    );
}
export default AuditTrackingReport;