import React, {useState, useEffect} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import styles from '../../styles/Profile.module.scss';
import styles1 from '../../styles/RedeemCatalogue.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import CartStructure from '../CartStructure/CartStructure';
import av from '../../assets/images/av.png';
import recieved from '../../assets/images/recieved.png';
import blocked from '../../assets/images/blocked.png';
import used from '../../assets/images/used.png';
import {Link} from 'react-router-dom';
import {Cart, SearchIcon} from '../../assets/icons';
import Dropdown from '../../component/DropDown/DropDown';
import hp1 from '../../assets/images/hp1.png';
import hp2 from '../../assets/images/hp2.png';
import hp3 from '../../assets/images/hp3.png';
import hp4 from '../../assets/images/hp4.png';
import CardCart from '../../component/CartCard/CartCard';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import SearchInput from '../../component/SearchInput/SearchInput';
import {CallCenterHeaders} from '../../service/Essentials';

const RedeemCatalogue = () => {
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const queryClient = useQueryClient();
  const [redemptionMethod, setRedemptionMethod] = useState('');
  const [redemptionMethodID, setRedemptionMethodID] = useState('');
  const [cartLength, setCartLength] = useState(0);

  const fetchRedeemItems = async () => {
    const res = await axiosProperty.get(ApiConfig.fetchRedeemItems, {
      headers: CallCenterHeaders(),
    });
    return res.data;
  };
  const {data, status} = useQuery(
    ['redeemItems', page],
    () => fetchRedeemItems(page),
    {keepPreviousData: true},
  );

  const cartItems = JSON.parse(sessionStorage.getItem('cart')) || [];

  useEffect(() => {
    setCartLength(cartItems.length);
  }, [cartItems]);

  const updateCartLength = newCartLength => {
    setCartLength(newCartLength);
  };

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = searchTerm => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data) {
      const filtered = data?.data.filter(
        item =>
          item.Merchandize_item_name.toLowerCase().includes(
            searchTerm.toLowerCase(),
          ) || item.Cost_price.toString().includes(searchTerm.toString()),
      );
      setFilteredData(filtered);
    }
  };

  const handleDropdownChange = id => {
    setRedemptionMethodID(id);
  };

  const handleRedemptionMethod = name => {
    setRedemptionMethod(name);
  };

  // const data1 = [
  //   {image: hp1, name: 'HP Omen 16 (2022)', price: 2500},
  //   {image: hp2, name: 'HP Spectre x360 16 f-1003tu', price: 3000},
  //   {image: hp3, name: 'HP Spectre x360 13.5 ef-0053tu', price: 3100},
  //   {image: hp4, name: 'HP Envy 17 (2022)', price: 2300},
  //   {image: hp4, name: 'HP Envy 19 (2022)', price: 2800},
  // ];

  return (
    <CartStructure>
      <div className={styles1.redeemCatalogueContainer}>
        {/* <div>
          <h4 className={styles.handleMemberQueryHeader}>
            Membership Id - (8711130)
          </h4>
          <p className={styles.balance}>Current Balance - (1293982)</p>
        </div>
        <div className={styles.balanceContainer}>
          <div className={styles.pointsContainer}>
            <div>
              <img src={av} alt=" available balance" />
            </div>
            <div className={styles.balanceInnerContainer}>
              <p>Available balance</p>
              <h6>1293982</h6>
            </div>
          </div>
          <div className={styles.pointsContainer}>
            <div>
              <img src={recieved} alt="recieved points" />
            </div>
            <div className={styles.balanceInnerContainer}>
              <p>Total Points Received</p>
              <h6>28725916</h6>
            </div>
          </div>
          <div className={styles.pointsContainer}>
            <div>
              <img src={blocked} alt="blocked points" />
            </div>
            <div className={styles.balanceInnerContainer}>
              <p>Total Blocked Points</p>
              <h6>0</h6>
            </div>
          </div>
          <div className={styles.pointsContainer}>
            <div>
              <img src={used} alt="used points" />
            </div>
            <div className={styles.balanceInnerContainer}>
              <p>Total Points used</p>
              <h6>15956333</h6>
            </div>
          </div>
        </div> */}
        <div className={styles1.redeemCatalogueMainContainer}>
          <div className={styles.profileNavOuterContainer}>
            <div className={styles.profileNav}>
              <div>
                <Link
                  to="/call-center/member-profile"
                  className={styles1.unActiveLink}>
                  Profile
                </Link>
              </div>
              <div className={styles.currentNav}>
                <Link
                  className={`${styles.currentNav} ${styles.linkstyles}`}
                  to="/call-center/redeem-catalogue">
                  Redeem Catalogue
                </Link>
              </div>
              <div>
                <Link
                  className={styles1.unActiveLink}
                  to="/call-center/transaction-details">
                  Transaction details
                </Link>
              </div>
              <div>
                <Link
                  className={styles1.unActiveLink}
                  to="/call-center/member-query-record">
                  Member Query Record
                </Link>
              </div>
              <div>
                <Link
                  className={styles1.unActiveLink}
                  to="/call-center/log-query">
                  Log Query
                </Link>
              </div>
              <div>
                <Link
                  className={styles1.unActiveLink}
                  to="/call-center/add-family-card">
                  Add Family Card
                </Link>
              </div>
            </div>
          </div>
          <div className={styles1.redeemTextContainer}>
            <p>Redeem Catalogue</p>
          </div>
          <div className={styles1.cartContainer}>
            <div className={styles1.searchField}>
              <div className={styles1.searchIconContainer}>
                <SearchIcon />
              </div>
              <input
                type="text"
                className={styles1.searchInput}
                placeholder="Search"
                onChange={e => {
                  handleSearchInputChange(e.target.value);
                }}
              />
            </div>
            <Link to="/cart">
              <div className={styles1.cartIconContainer}>
                <div className={styles1.cartIcon}>
                  <Cart />
                </div>
                <div>
                  <p className={styles1.cartNum}>{cartLength}</p>
                </div>
              </div>
            </Link>
          </div>
          <div className={styles1.dropdownContainer}>
            <div className={styles1.dropdownInnerContainer}>
              <label htmlFor="">Select Merchandise Catagory</label>
              <Dropdown />
            </div>
            <div className={styles1.dropdownInnerContainer}>
              <label htmlFor="">Select Redemption Method</label>
              <Dropdown
                column={'name'}
                onSelect={handleDropdownChange}
                onSelectOption={handleRedemptionMethod}
              />
            </div>
            <div className={styles1.dropdownInnerContainer}>
              <label htmlFor="">Sort By</label>
              <Dropdown />
            </div>
          </div>
          <div>
            <div className={styles1.cardContainer}>
              {status === 'error' && (
                <p className="text-danger">Error Occured</p>
              )}

              {status === 'loading' && (
                <p className="text-info">Loading redeem items...</p>
              )}

              {status === 'success' &&
                filteredData.map(each => (
                  <CardCart
                    image={hp1}
                    price={each.Cost_price}
                    name={each.Merchandize_item_name}
                    updateCartLength={updateCartLength}
                  />
                ))}
            </div>
            <Link className={styles1.proceedBTN} to="/cart">
              <button className={styles1.toCardBtn}>Proceed to cart</button>
            </Link>
            <div className={styles1.paginationContainer}>
              {/* <p>Pagination here</p> */}
              <Paginate page={page} data={data} setPage={setPage} />
            </div>
          </div>
        </div>
      </div>
    </CartStructure>
  );
};

export default RedeemCatalogue;
