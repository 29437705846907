import React, {useState, useEffect} from 'react';
import styles from '../../styles/CreateCampaign.module.scss';
import styles1 from '../../styles/BonusRegularCampaign.module.scss';
import {
  CloseCampaignIcon,
  OpenCampaignIcon,
  Times,
  ViewPen,
} from '../../assets/icons';
import Dropdown from '../../component/DropDown/DropDown';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import swal from 'sweetalert';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import {useQuery, useQueryClient, useMutation} from 'react-query';
import Paginate from '../../component/Paginate/Paginate';
import {
  HeadersOperator,
  Headers,
  SweepStakeHeaders,
  campaignHeaders,
} from '../../service/Essentials';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';
import SelectedCampaignItem from '../../component/SelectedCampaignItem/SelectedCampaignItem';

const BonusRegularCampaign = () => {
  const [clearModal, setClearModal] = useState(false);
  const queryClient = useQueryClient();
  const [error, setError] = useState('oops... something went wrong');
  const [deleteModal, setDeleteModal] = useState(false);
  const [page, setPage] = useState(1);
  const [bonus, setBonus] = useState([]);
  const [deleteId, setDeleteId] = useState('');

  const [selectedValues, setSelectedValues] = useState({});
  const [fix, setFix] = useState(false);
  const [discrete, setDiscrete] = useState(false);
  const [branch, setBranch] = useState('');
  const [branchId, setBranchId] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [transactionName, setTransactionName] = useState('');
  const [transactionChannelId, setTransactionChannelId] = useState('');
  const [transactionChannelName, setTransactionChannelName] = useState('');
  const [productGroupId, setProductGroupId] = useState('');
  const [productGroupName, setProductGroupName] = useState('');
  const [productBrandId, setProductBrandId] = useState('');
  const [productBrandName, setProductBrandName] = useState('');
  const [productCodeId, setProductCodeId] = useState('');
  const [productCodeName, setProductCodeName] = useState('');

  const [sweepstakeID, setsweepstakeID] = useState('');
  const [sweepstakeSlug, setsweepstakeSlug] = useState('');
  const [sweepstakeFlag, setSweepstakeFlag] = useState('');
  const [transactionFlag, setTransactionFlag] = useState('');
  const [rewardFlag, setRewardFlag] = useState('');
  const [tierFlag, setTierFlag] = useState('');
  const [rewardOnceFlag, setRewardOnceFlag] = useState('');
  const [programID, setProgramID] = useState('');
  const [programName, setProgramName] = useState('');
  const [companyID, setCompanyID] = useState('');
  const [operator, setOperator] = useState('');
  const [operatorId, setOperatorId] = useState('');
  const [tier, setTier] = useState('');
  const [campaignStatus, setCampaignStatus] = useState('');
  const [tierId, setTierId] = useState('');
  const [schedule, setSchedule] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState();
  const [selectedItem, setSelectedItem] = useState({});

  const handleRadioButtonChange = (id, checked) => {
    setSelectedValues(prevValues => ({
      ...prevValues,
      [id]: checked,
    }));
  };

  const onClose = () => {
    setDeleteModal(false);
  };
  useEffect(() => {
    const getCompanyId = JSON.parse(sessionStorage.getItem('company'));
    setCompanyID(getCompanyId.id);
    // setCompanyID(1);
    setFormData(prevFormData => ({
      ...prevFormData,
      Company_id: getCompanyId.id,
    }));
  }, []);

  const handleProgramName = name => {
    setProgramName(name);
    setFormData(prevFormData => ({
      ...prevFormData,
      program: name,
    }));
  };

  const handleProgramID = id => {
    setProgramID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Loyalty_program_id: id,
    }));
  };

  const handleSweepstakeID = id => {
    setsweepstakeID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Sweepstake_id: id,
    }));
  };
  const handleSweepstakeSlug = name => {
    setsweepstakeSlug(name);
  };

  const handleSweepstakeFlag = value => {
    if (value === 'Yes') {
      setSweepstakeFlag('Yes');
    } else {
      setSweepstakeFlag('No');
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      Sweepstake_flag: value === 'Yes' ? 1 : 0,
    }));
  };

  const handleBranchName = name => {
    console.log(name);
    setBranch(name);
  };

  const handleBranchID = id => {
    console.log(id);
    setBranchId(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      branch_id: id,
    }));
  };

  const handleTierName = name => {
    setTier(name);
  };

  const handleTransactionTypeID = id => {
    setTransactionId(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Transaction_id: id,
    }));
  };

  const handleTransactionTypeName = name => {
    setTransactionName(name);
  };

  const handleTransactionChannelID = id => {
    setTransactionChannelId(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Transaction_channel_id: id,
    }));
  };

  const handleTransactionChannelName = name => {
    setTransactionChannelName(name);
  };

  const handleProductGroupID = id => {
    setProductGroupId(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Product_group_id: id,
    }));
  };

  const handleProductGroupName = name => {
    setProductGroupName(name);
  };

  const handleProductBrandID = id => {
    setProductBrandId(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Product_brand_id: id,
    }));
  };

  const handleProductBrandName = name => {
    setProductBrandName(name);
  };

  const handleProductCodeID = id => {
    setProductCodeId(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Product_code: id,
    }));
  };

  const handleProductCodeName = name => {
    setProductCodeName(name);
  };

  const handleTierID = id => {
    setTierId(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Tier_id: id,
    }));
  };

  const handleTierFlag = value => {
    if (value === 'Yes') {
      setTierFlag('Yes');
    } else {
      setTierFlag('No');
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      Tier_flag: value === 'Yes' ? 1 : 0,
    }));
  };
  const handleRewardOnceFlag = value => {
    if (value === 'Yes') {
      setRewardOnceFlag('Yes');
    } else {
      setRewardOnceFlag('No');
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      Reward_once_flag: value === 'Yes' ? 1 : 0,
    }));
  };

  const handleTransactionFlag = value => {
    if (value === 'Yes') {
      setTransactionFlag('Yes');
    } else {
      setTransactionFlag('No');
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      Transaction_amt_flag: value === 'Yes' ? 1 : 0,
    }));
  };
  const handleSchedule = value => {
    if (value === 'Yes') {
      setSchedule('Yes');
    } else {
      setSchedule('No');
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      Schedule: value === 'Yes' ? 1 : 0,
    }));
  };

  const handleRewardFlag = value => {
    if (value === 'Point') {
      setRewardFlag('Point');
    } else if (value === 'Percentage') {
      setRewardFlag('Percentage');
    }
  };

  const handleCampaignStatus = value => {
    if (value === 'Enabled') {
      setCampaignStatus('Enabled');
    } else {
      setCampaignStatus('Disabled');
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      campaign_status: value ? 'Enabled' : 'Disabled',
    }));
  };

  const handleOperator = namee => {
    setOperator(namee);
    setFormData(prevFormData => ({
      ...prevFormData,
      operator: namee,
    }));
  };
  const handleOperatorID = id => {
    setOperatorId(id);
  };

  const [errors, setErrors] = useState({
    Loyalty_program_id: '',
    Company_id: '',
    Membership_id: '',
    Product_group_id: '',
    Product_brand_id: '',
    Transaction_id: '',
    Transaction_channel_id: '',
    Transaction_amount: '',
    Transaction_amt_flag: '',
    Product_code: '',
    Tier_flag: '',
    Tier_id: '',
    branch_id: '',
    From_date: '',
    To_date: '',
    Campaign_name: '',
    Campaign_description: '',
    campaign_status: '',
    Reward_points: '',
    Reward_percent: '',
    company_name: '',
    program: '',
    Sweepstake_id: '',
    Sweepstake_flag: '',
    Sweepstake_ticket_limit: '',
    Schedule: '',
  });

  const [formData, setFormData] = useState({
    Loyalty_program_id: programID,
    Company_id: companyID,
    Membership_id: '7744889000',
    Product_group_id: '',
    Product_brand_id: '',
    Transaction_id: '',
    Product_code: '',
    Transaction_channel_id: '',
    Transaction_amount: '',
    Transaction_amt_flag: '',
    Tier_flag: '',
    Tier_id: '',
    branch_id: '',
    From_date: '',
    To_date: '',
    Campaign_name: '',
    Campaign_description: '',
    campaign_status: 'Disabled',
    Reward_points: '',
    Reward_percent: '',
    Reward_fix_amt_flag: '',
    company_name: '',
    program: '',
    Sweepstake_id: '',
    Sweepstake_flag: '',
    Sweepstake_ticket_limit: '',
    Schedule: '',
  });

  const handleChange = event => {
    const {name, value} = event.target;
    setFormData(prev => ({...prev, [name]: value}));
  };

  const handleReset = () => {
    setFormData({
      Loyalty_program_id: '',
      Company_id: '',
      Membership_id: '',
      Product_group_id: '',
      Product_brand_id: '',
      Transaction_id: '',
      Product_code: '',
      Transaction_channel_id: '',
      Transaction_amount: '',
      Transaction_amt_flag: '',
      Tier_flag: '',
      branch_id: '',
      From_date: '',
      To_date: '',
      Fixed_amount: '',
      Discrete_amt: '',
      Campaign_name: '',
      Campaign_description: '',
      campaign_status: '',
      Reward_points: '',
      Reward_percent: '',
      company_name: '',
      program: '',
      Sweepstake_id: '',
      Sweepstake_flag: '',
      Sweepstake_ticket_limit: '',
      Schedule: '',
    });
  };

  const addBonus = async () => {
    try {
      const res = await axiosProperty.post(
        ApiConfig.createBonusRegular,
        {
          Company_id: 1,
          Membership_id: 7744889000,
          Loyalty_program_id: 1,
          Tier_id: 1,
          Sweepstake_id: 3,
          Campaign_name: 'Bonus Budget Campaign',
          Sweepstake_ticket_limit: 3,
          Campaign_description: 'Bonus Budget Description',
          From_date: '2024-11-29',
          To_date: '2026-12-31',
          Tier_flag: 1,
          Reward_flag: 1,
          Reward_points: 1000,
          Reward_percent: '',
          Sweepstake_flag: 1,
          Reward_once_flag: 1,
          Transaction_amt_flag: 1,
          Transaction_amount: 7500,
          operator: '<',
          Product_group_id: 1,
          Product_brand_id: 1,
          Transaction_id: 1,
          Transaction_channel_id: 3,
          branch_id: 6,
          campaign_status: 'Enabled',
          Schedule: '',
          Jan: '',
          Feb: '',
          Mar: '',
          Apr: '',
          May: '',
          Jun: '',
          Jul: '',
          Aug: '',
          Sep: '',
          Oct: '',
          Nov: '',
          Dec: '',
          Mon: '',
          Tue: '',
          Wed: '',
          Thu: '',
          Fri: '',
          Sat: '',
          Sun: '',
          First_week: '',
          Second_week: '',
          Third_week: '',
          Fourth_week: '',
          Start_time: '',
          End_time: '',

          // Loyalty_program_id: programID,
          // Company_id: 1,
          // Membership_id: "7744889000",
          // Product_group_id: formData?.Product_group_id,
          // Product_brand_id: formData?.Product_brand_id,
          // Transaction_id: formData?.Transaction_id,
          // Product_code: formData?.Product_code,
          // Transaction_channel_id: formData?.Transaction_channel_id,
          // Transaction_amount: formData?.Transaction_amount,
          // Transaction_amt_flag: formData?.Transaction_amt_flag,
          // Tier_flag: formData?.Tier_flag,
          // Tier_id: formData?.Tier_id,
          // branch_id: formData?.branch_id,
          // From_date: formData?.From_date,
          // To_date: formData?.To_date,
          // Campaign_name: formData?.Campaign_name,
          // Campaign_description: formData?.Campaign_description,
          // campaign_status: formData?.campaign_status,
          // company_name: formData?.company_name,
          // program: formData?.program,
          // Sweepstake_id: formData?.Sweepstake_id,
          // Sweepstake_flag: formData?.Sweepstake_flag,
          // Sweepstake_ticket_limit: formData?.Sweepstake_ticket_limit,
          // Schedule: formData?.Schedule,
        },
        {
          headers: campaignHeaders(),
        },
      );

      setErrors({});

      swal({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: addBonusMutation} = useMutation({
    mutationFn: addBonus,
  });

  const fetchBonus = async page => {
    const res = await axiosProperty.get(ApiConfig.fetchBonusRegular(page), {
      headers: campaignHeaders(),
    });
    return res.data;
  };

  const {data: bonusData, status} = useQuery(
    ['bonus', page],
    () => fetchBonus(page),
    {keepPreviousData: true},
  );

  useEffect(() => {
    setBonus(bonusData?.data || []);
  }, [bonusData]);

  const updateBonus = async () => {
    try {
      const res = await axiosProperty.put(
        ApiConfig.updateBonusRegular(updateID),
        {
          Loyalty_program_id: programID,
          Company_id: companyID,
          Membership_id: '7744889000',
          Product_group_id: formData?.Product_group_id,
          Product_brand_id: formData?.Product_brand_id,
          Transaction_id: formData?.Transaction_id,
          Product_code: formData?.Product_code,
          Transaction_channel_id: formData?.Transaction_channel_id,
          Transaction_amount: formData?.Transaction_amount,
          Transaction_amt_flag: formData?.Transaction_amt_flag,
          Tier_flag: formData?.Tier_flag,
          Tier_id: formData?.Tier_id,
          branch_id: formData?.branch_id,
          From_date: formData?.From_date,
          To_date: formData?.To_date,
          Campaign_name: formData?.Campaign_name,
          Campaign_description: formData?.Campaign_description,
          campaign_status: formData?.campaign_status,
          company_name: formData?.company_name,
          program: formData?.program,
          Sweepstake_id: formData?.Sweepstake_id,
          Sweepstake_flag: formData?.Sweepstake_flag,
          Sweepstake_ticket_limit: formData?.Sweepstake_ticket_limit,
          Schedule: formData?.Schedule,
        },
        {
          headers: campaignHeaders(),
        },
      );

      setIsSubmit(true);
      setIsUpdate(false);

      setErrors({});
      swal({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, probably network');
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: updateBonusMutation} = useMutation({
    mutationFn: updateBonus,
    onSuccess: () => {
      queryClient.invalidateQueries(['bonus']);
    },
  });

  const deleteBonusCampaign = async id => {
    try {
      const res = await axiosProperty.delete(ApiConfig.deleteBonusRegular(id), {
        headers: campaignHeaders(),
      });
      setDeleteModal(false);
      swal({
        title: 'Success!',
        text: res.data.message,
        icon: 'success',
      });
      queryClient.invalidateQueries('bonus');
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);
      swal({
        title: 'Warning!',
        text: error,
        icon: 'warning',
      });
    }
  };

  const [disableInput, setDisableInput] = useState(false);

  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState();
  const [isOpen3, setIsOpen3] = useState();

  const handleOpen1 = () => {
    setIsOpen1(!isOpen1);
    setIsOpen2(false);
    setIsOpen3(false);
  };

  const handleOpen2 = () => {
    setIsOpen1(false);
    setIsOpen2(!isOpen2);
    setIsOpen3(false);
  };

  const handleOpen3 = () => {
    setIsOpen1(false);
    setIsOpen2(false);
    setIsOpen3(!isOpen3);
  };

  //SELECT BRANCH PROPS START
  const list = [
    {name: 'First Bank', id: 1},
    {name: 'UBA', id: 2},
    {name: 'Fidelity', id: 3},
    {name: 'Polaris', id: 4},
    {name: 'Access', id: 5},
    {name: 'GTB', id: 6},
    {name: 'Eco Bank', id: 7},
    {name: 'Micro Finance', id: 8},
  ];
  const [pickedBranch, setPickedBranch] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredResult, setFilteredResult] = useState(list);
  const [disabledItems, setDisabledItems] = useState([]);
  const handleInputChange = e => {
    const inputValue = e.target.value;
    setSearchTerm(inputValue);
    filterResult(inputValue);
  };

  const filterResult = input => {
    const filteredList = list.filter(each =>
      each.name.toLowerCase().includes(input.toLowerCase()),
    );
    setFilteredResult(filteredList);
  };

  const handlePickedBranch = selectedBranch => {
    setPickedBranch(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setDisabledItems(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setSearchTerm('');
  };

  const handleRemoveBranch = deletedBranch => {
    const removedBranch = pickedBranch.filter(each => each !== deletedBranch);
    setPickedBranch(removedBranch);
    setDisabledItems(removedBranch);
  };
  const handleOnFocusChange = () => {
    setIsFocused(true);
  };

  const handleOnBlurChange = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 200);
  };

  //SELECT BRANCH PROPS END

  // Transaction Type START///////////////////////////
  const transactionType = [
    {name: 'Bitcoin', id: 1},
    {name: 'USSD', id: 2},
    {name: 'USDT', id: 3},
    {name: 'Tranfer', id: 4},
    {name: 'NFT', id: 5},
  ];
  const [pickedTT, setPickedTT] = useState([]);
  const [disabledTT, setDisabledTT] = useState([]);
  const [searchTT, setSearchTT] = useState('');
  const [transactionTypeData, setTransactionTypeData] =
    useState(transactionType);
  const [isTTVisible, setIsTTVisible] = useState(false);

  const handleInputChangeTT = e => {
    const inputValue = e.target.value;
    setSearchTT(inputValue);
    filterResultTT(inputValue);
  };

  const filterResultTT = input => {
    const filteredList = transactionType.filter(each =>
      each.name.toLowerCase().includes(input.toLowerCase()),
    );
    setTransactionTypeData(filteredList);
  };

  const handlePickedTT = selectedBranch => {
    setPickedTT(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setDisabledTT(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setSearchTT('');
  };

  const handleRemoveTT = deletedTT => {
    const removedBranch = pickedTT.filter(each => each !== deletedTT);
    setPickedTT(removedBranch);
    setDisabledTT(removedBranch);
  };
  const handleOnFocusChangeTT = () => {
    setIsTTVisible(true);
  };

  const handleOnBlurChangeTT = () => {
    setTimeout(() => {
      setIsTTVisible(false);
    }, 200);
  };

  //TRANSACTION TYPE ENDS

  //TRANSACTION CHANNEL START
  const [pickedTC, setPickedTC] = useState([]);
  const [disabledTC, setDisabledTC] = useState([]);
  const [searchTC, setSearchTC] = useState('');
  const [transactionChanelData, setTransactionChanelData] =
    useState(transactionType);
  const [transactionChanelVisible, setTransactionChanelVisible] =
    useState(false);
  const handleInputChangeTC = e => {
    const inputValue = e.target.value;
    setSearchTC(inputValue);
    filterResultTC(inputValue);
  };

  const filterResultTC = input => {
    const filteredList = transactionType.filter(each =>
      each.name.toLowerCase().includes(input.toLowerCase()),
    );
    setTransactionChanelData(filteredList);
  };

  const handlePickedTC = selectedBranch => {
    setPickedTC(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setDisabledTC(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setSearchTC('');
  };

  const handleRemoveTC = deletedTT => {
    const removedBranch = pickedTT.filter(each => each !== deletedTT);
    setPickedTC(removedBranch);
    setDisabledTC(removedBranch);
  };
  const handleOnFocusChangeTC = () => {
    setTransactionChanelVisible(true);
  };

  const handleOnBlurChangeTC = () => {
    setTimeout(() => {
      setTransactionChanelVisible(false);
    }, 200);
  };

  //TRANSACTION CHANNEL END

  //   For Product Group///////////////////////////
  const [productGroupData, setProductGroupData] = useState(transactionType);
  const [searchPG, setSearchPG] = useState('');
  const [pickedPG, setPickedPG] = useState([]);
  const [disabledPG, setDisabledPG] = useState([]);
  const [productGroupVisible, setProductGroupVisible] = useState(false);
  const handleInputChangePG = e => {
    const inputValue = e.target.value;
    setSearchPG(inputValue);
    filterResultPG(inputValue);
  };

  const filterResultPG = input => {
    const filteredList = productGroupData.filter(each =>
      each.name.toLowerCase().includes(input.toLowerCase()),
    );
    setProductGroupData(filteredList);
  };

  const handlePickedPG = selectedBranch => {
    setPickedPG(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setDisabledPG(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setSearchPG('');
  };

  const handleRemovePG = deletedTT => {
    const removedBranch = pickedPG.filter(each => each !== deletedTT);
    setPickedPG(removedBranch);
    setDisabledPG(removedBranch);
  };

  const handleOnFocusChangePG = () => {
    setProductGroupVisible(true);
  };

  const handleOnBlurChangePG = () => {
    setTimeout(() => {
      setProductGroupVisible(false);
    }, 200);
  };

  //   For Product Group Ends///////////////////////////

  //   For Product Brand///////////////////////////

  const [searchPB, setSearchPB] = useState('');
  const [disabledPB, setDisabledPB] = useState([]);
  const [productBrandVisible, setProductBrandVisible] = useState(false);
  const [productBrandData, setProductBrandData] = useState(transactionType);
  const [pickedPB, setPickedPB] = useState([]);

  const handleInputChangePB = e => {
    const inputValue = e.target.value;
    setSearchPB(inputValue);
    filterResultPB(inputValue);
  };

  const filterResultPB = input => {
    const filteredList = productBrandData.filter(each =>
      each.name.toLowerCase().includes(input.toLowerCase()),
    );
    setProductBrandData(filteredList);
  };

  const handlePickedPB = selectedBranch => {
    setPickedPB(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setDisabledPB(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setSearchPB('');
  };

  const handleRemovePB = deletedTT => {
    const removedBranch = pickedPB.filter(each => each !== deletedTT);
    setPickedPB(removedBranch);
    setDisabledPB(removedBranch);
  };

  const handleOnFocusChangePB = () => {
    setProductBrandVisible(true);
  };

  const handleOnBlurChangePB = () => {
    setTimeout(() => {
      setProductBrandVisible(false);
    }, 200);
  };

  //   For Product Brand Ends///////////////////////////

  //   For Product Code///////////////////////////
  const [productCodeData, setProductCodeData] = useState(transactionType);
  const [searchPC, setSearchPC] = useState('');
  const [pickedPC, setPickedPC] = useState([]);
  const [disabledPC, setDisabledPC] = useState([]);
  const [productCodeVisible, setProductCodeVisible] = useState(false);
  const handleInputChangePC = e => {
    const inputValue = e.target.value;
    setSearchPC(inputValue);
    filterResultPC(inputValue);
  };

  const filterResultPC = input => {
    const filteredList = productCodeData.filter(each =>
      each.name.toLowerCase().includes(input.toLowerCase()),
    );
    setProductCodeData(filteredList);
  };

  const handlePickedPC = selectedBranch => {
    setPickedPC(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setDisabledPC(prevPickedBranch => [...prevPickedBranch, selectedBranch]);
    setSearchPC('');
  };

  const handleRemovePC = deletedTT => {
    const removedBranch = pickedPC.filter(each => each !== deletedTT);
    setPickedPC(removedBranch);
    setDisabledPC(removedBranch);
  };

  const handleOnFocusChangePC = () => {
    setProductCodeVisible(true);
  };

  const handleOnBlurChangePC = () => {
    setTimeout(() => {
      setProductCodeVisible(false);
    }, 200);
  };

  //PRODUCT CODE END

  return (
    <CallCenterStructure>
      <div className={styles1.bonusRegularCampaign}>
        <div className={styles.headerText}>
          <p>Create Bonus Regular Campaign</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.BasicInfoContainer}>
            <div className={styles.campaignHeader} onClick={handleOpen1}>
              <p>
                Basic Information<sup>*</sup>
              </p>
              {isOpen1 ? (
                <span>
                  <OpenCampaignIcon />
                </span>
              ) : (
                <span>
                  <CloseCampaignIcon />
                </span>
              )}
            </div>
            {isOpen1 && (
              <div className={styles.basicInfoForm}>
                <div className={styles.fieldContainer1}>
                  <div className={styles.fieldInnerContainer1}>
                    <label htmlFor="">
                      Loyalty Program <sup>*</sup>
                    </label>
                    <Dropdown
                      header={Headers()}
                      api={ApiConfig.fetchLoyaltyProgram}
                      onSelect={handleProgramID}
                      onSelectOption={handleProgramName}
                      column={'program_name'}
                      retrievedValue={formData.program}
                      key="programme_name"
                    />
                    {errors?.program && (
                      <p className="text-danger">{errors?.program}</p>
                    )}
                  </div>
                  <div className={styles.fieldInnerContainer1}>
                    <label htmlFor="">
                      Start Date<sup>*</sup>
                    </label>
                    <input
                      type="date"
                      name="From_date"
                      onChange={handleChange}
                      value={formData.From_date}
                    />
                    {errors?.From_date && (
                      <p className="text-danger">{errors?.From_date}</p>
                    )}
                  </div>
                </div>
                <div className={styles.fieldContainer2}>
                  <div className={styles.fieldInnerContainer1}>
                    <label htmlFor="">
                      Campaign Name<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      name="Campaign_name"
                      value={formData?.Campaign_name}
                      onChange={handleChange}
                    />
                    {errors?.Campaign_name && (
                      <p className="text-danger">{errors?.Campaign_name}</p>
                    )}
                  </div>
                  <div className={styles.fieldInnerContainer1}>
                    <label htmlFor="">
                      End Date<sup>*</sup>
                    </label>
                    <input
                      type="date"
                      name="To_date"
                      onChange={handleChange}
                      value={formData.To_date}
                    />
                    {errors?.To_date && (
                      <p className="text-danger">{errors?.To_date}</p>
                    )}
                  </div>
                </div>

                <div className={styles.fieldContainer3}>
                  <div className={styles.fieldContent3}>
                    <div className={styles.fieldInnerContainer3}>
                      <label htmlFor="">
                        Select Branches<sup>*</sup>
                      </label>
                      <div
                        className={
                          disableInput
                            ? styles.searchResultContainerDisabled
                            : styles.searchResultContainer
                        }>
                        <SelectedCampaignItem
                          pickedItems={pickedBranch}
                          handleOnFocusChange={handleOnFocusChange}
                          handleOnBlurChange={handleOnBlurChange}
                          searchTerm={searchTerm}
                          handleInputChange={handleInputChange}
                          handleRemoveBranch={handleRemoveBranch}
                          disableInput={disableInput}
                        />
                      </div>
                    </div>
                    {isFocused && (
                      <div className={styles.branchContainer}>
                        <ul className={styles.branchInnerContainer}>
                          {filteredResult.map(each => (
                            <li
                              className={
                                disabledItems.includes(each.name)
                                  ? styles.disabledItem
                                  : styles.activedItem
                              }
                              onClick={() => handlePickedBranch(each.name)}
                              key={each.id}>
                              {each.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.fieldContainer3}>
                  <div className={styles.fieldContent3}>
                    <div className={styles.fieldInnerContainer3}>
                      <label htmlFor="">Transaction Type</label>
                      <div
                        className={
                          disableInput
                            ? styles.searchResultContainerDisabled
                            : styles.searchResultContainer
                        }>
                        <SelectedCampaignItem
                          pickedItems={pickedTT}
                          handleOnFocusChange={handleOnFocusChangeTT}
                          handleOnBlurChange={handleOnBlurChangeTT}
                          searchTerm={searchTT}
                          handleInputChange={handleInputChangeTT}
                          handleRemoveBranch={handleRemoveTT}
                          disableInput={disableInput}
                        />
                      </div>
                    </div>
                    {isTTVisible && (
                      <div className={styles.branchContainer}>
                        <ul className={styles.branchInnerContainer}>
                          {transactionTypeData.map(each => (
                            <li
                              className={
                                disabledTT.includes(each.name)
                                  ? styles.disabledItem
                                  : styles.activedItem
                              }
                              onClick={() => handlePickedTT(each.name)}
                              key={each.id}>
                              {each.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.fieldContainer3}>
                  <div className={styles.fieldContent3}>
                    <div className={styles.fieldInnerContainer3}>
                      <label htmlFor="">Transaction Channel</label>
                      <div
                        className={
                          disableInput
                            ? styles.searchResultContainerDisabled
                            : styles.searchResultContainer
                        }>
                        <SelectedCampaignItem
                          pickedItems={pickedTC}
                          handleOnFocusChange={handleOnFocusChangeTC}
                          handleOnBlurChange={handleOnBlurChangeTC}
                          searchTerm={searchTC}
                          handleInputChange={handleInputChangeTC}
                          handleRemoveBranch={handleRemoveTC}
                          disableInput={disableInput}
                        />
                      </div>
                    </div>
                    {transactionChanelVisible && (
                      <div className={styles.branchContainer}>
                        <ul className={styles.branchInnerContainer}>
                          {transactionChanelData.map(each => (
                            <li
                              className={
                                disabledTC.includes(each.name)
                                  ? styles.disabledItem
                                  : styles.activedItem
                              }
                              onClick={() => handlePickedTC(each.name)}
                              key={each.id}>
                              {each.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.fieldContainer3}>
                  <div className={styles.fieldContent3}>
                    <div className={styles.fieldInnerContainer3}>
                      <label htmlFor="">Product Group</label>
                      <div
                        className={
                          disableInput
                            ? styles.searchResultContainerDisabled
                            : styles.searchResultContainer
                        }>
                        <SelectedCampaignItem
                          pickedItems={pickedPG}
                          handleOnFocusChange={handleOnFocusChangePG}
                          handleOnBlurChange={handleOnBlurChangePG}
                          searchTerm={searchPG}
                          handleInputChange={handleInputChangePG}
                          handleRemoveBranch={handleRemovePG}
                          disableInput={disableInput}
                        />
                      </div>
                    </div>
                    {productGroupVisible && (
                      <div className={styles.branchContainer}>
                        <ul className={styles.branchInnerContainer}>
                          {productGroupData.map(each => (
                            <li
                              className={
                                disabledPG.includes(each.name)
                                  ? styles.disabledItem
                                  : styles.activedItem
                              }
                              onClick={() => handlePickedPG(each.name)}
                              key={each.id}>
                              {each.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.fieldContainer3}>
                  <div className={styles.fieldContent3}>
                    <div className={styles.fieldInnerContainer3}>
                      <label htmlFor="">Product Brand</label>
                      <div
                        className={
                          disableInput
                            ? styles.searchResultContainerDisabled
                            : styles.searchResultContainer
                        }>
                        <SelectedCampaignItem
                          pickedItems={pickedPB}
                          handleOnFocusChange={handleOnFocusChangePB}
                          handleOnBlurChange={handleOnBlurChangePB}
                          searchTerm={searchPB}
                          handleInputChange={handleInputChangePB}
                          handleRemoveBranch={handleRemovePB}
                          disableInput={disableInput}
                        />
                      </div>
                    </div>
                    {productBrandVisible && (
                      <div className={styles.branchContainer}>
                        <ul className={styles.branchInnerContainer}>
                          {productBrandData.map(each => (
                            <li
                              className={
                                disabledPB.includes(each.name)
                                  ? styles.disabledItem
                                  : styles.activedItem
                              }
                              onClick={() => handlePickedPB(each.name)}
                              key={each.id}>
                              {each.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.fieldContainer3}>
                  <div className={styles.fieldContent3}>
                    <div className={styles.fieldInnerContainer3}>
                      <label htmlFor="">Product Code</label>
                      <div
                        className={
                          disableInput
                            ? styles.searchResultContainerDisabled
                            : styles.searchResultContainer
                        }>
                        <SelectedCampaignItem
                          pickedItems={pickedPC}
                          handleOnFocusChange={handleOnFocusChangePC}
                          handleOnBlurChange={handleOnBlurChangePC}
                          searchTerm={searchPC}
                          handleInputChange={handleInputChangePC}
                          handleRemoveBranch={handleRemovePC}
                          disableInput={disableInput}
                        />
                      </div>
                    </div>
                    {productCodeVisible && (
                      <div className={styles.branchContainer}>
                        <ul className={styles.branchInnerContainer}>
                          {productCodeData.map(each => (
                            <li
                              className={
                                disabledPC.includes(each.name)
                                  ? styles.disabledItem
                                  : styles.activedItem
                              }
                              onClick={() => handlePickedPC(each.name)}
                              key={each.id}>
                              {each.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles.BasicInfoContainer}>
            <div
              className={
                disableInput
                  ? styles.campaignHeader2Disabled
                  : styles.campaignHeader2
              }
              onClick={handleOpen2}>
              <p>
                Sweepstakes & Transaction<sup>*</sup>
              </p>
              {isOpen2 ? (
                <span>
                  <OpenCampaignIcon />
                </span>
              ) : (
                <span>
                  <CloseCampaignIcon />
                </span>
              )}
            </div>
            {isOpen2 && (
              <>
                <div className={styles1.fieldContainer3}>
                  <div className={styles1.checkboxContainer1}>
                    <label htmlFor="">Sweepstake to Link</label>
                    <RadioButtonBlue
                      firstText="Yes"
                      secondText="No"
                      name="Sweepstake_flag"
                      onChange={handleSweepstakeFlag}
                      value={formData.Sweepstake_flag}
                    />
                    {errors?.Sweepstake_flag && (
                      <p className="text-danger">{errors?.Sweepstake_flag}</p>
                    )}
                  </div>
                  <div className={styles1.innerFieldContainer3}>
                    <label htmlFor="">
                      Sweepstake<sup>*</sup>
                    </label>
                    <Dropdown
                      header={SweepStakeHeaders()}
                      api={ApiConfig.fetchActiveSweepStakes}
                      onSelect={handleSweepstakeID}
                      onSelectOption={handleSweepstakeSlug}
                      column={'slug'}
                      retrievedValue={formData.Sweepstake_id}
                      key="sweepstake"
                    />
                    {errors?.Sweepstake_id && (
                      <p className="text-danger">{errors?.Sweepstake_id}</p>
                    )}
                  </div>
                  <div className={styles1.innerFieldContainer3}>
                    <label htmlFor="">
                      No. of Sweepstake
                      {/* entries per Qualifying Transaction */}
                      <sup>*</sup>
                    </label>
                    <input
                      type="text"
                      name="Sweepstake_ticket_limit"
                      value={formData?.Sweepstake_ticket_limit}
                      onChange={handleChange}
                    />
                    {errors?.Sweepstake_ticket_limit && (
                      <p className="text-danger">
                        {errors?.Sweepstake_ticket_limit}
                      </p>
                    )}
                  </div>
                </div>
                <div className={styles1.fieldContainer3}>
                  <div className={styles1.checkboxContainer1}>
                    <label htmlFor="">Transaction Amount Criteria</label>
                    <RadioButtonBlue
                      firstText="Yes"
                      secondText="No"
                      name="Transaction_amt_flag"
                      onChange={handleTransactionFlag}
                      value={formData.Transaction_amt_flag}
                    />
                    {errors?.Transaction_amt_flag && (
                      <p className="text-danger">
                        {errors?.Transaction_amt_flag}
                      </p>
                    )}
                  </div>
                  <div className={styles1.innerFieldContainer3}>
                    <label htmlFor="">
                      Transaction Amount<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      name="Transaction_amount"
                      value={formData?.Transaction_amount}
                      onChange={handleChange}
                    />
                    {errors?.Transaction_amount && (
                      <p className="text-danger">
                        {errors?.Transaction_amount}
                      </p>
                    )}
                  </div>
                  <div className={styles1.innerFieldContainer3}>
                    <label htmlFor="">
                      Operator{/* entries per Qualifying Transaction */}
                      <sup>*</sup>
                    </label>
                    <Dropdown
                      header={HeadersOperator()}
                      api={ApiConfig.fetchCodeDecodeMaster}
                      onSelect={handleOperatorID}
                      onSelectOption={handleOperator}
                      column={'type_code'}
                      retrievedValue={formData?.operator}
                      key="operator"
                    />
                    {errors?.operator && (
                      <p className="text-danger">{errors?.operator}</p>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={styles.BasicInfoContainer}>
            <div
              className={
                disableInput
                  ? styles.campaignHeader2Disabled
                  : styles.campaignHeader2
              }
              onClick={handleOpen3}>
              <p>
                Rewards<sup>*</sup>
              </p>
              {isOpen3 ? (
                <span>
                  <OpenCampaignIcon />
                </span>
              ) : (
                <span>
                  <CloseCampaignIcon />
                </span>
              )}
            </div>
            {isOpen3 && (
              <>
                <div className={styles1.fieldContainer4}>
                  <div className={styles1.checkboxContainer4}>
                    <label htmlFor="">Reward Based on tier</label>
                    <RadioButtonBlue
                      firstText="Yes"
                      secondText="No"
                      name="Tier_flag"
                      onChange={handleTierFlag}
                      value={formData.Tier_flag}
                    />
                    {errors?.Tier_flag && (
                      <p className="text-danger">{errors?.Tier_flag}</p>
                    )}
                  </div>
                  <div className={styles1.innerFieldContainer4}>
                    <label htmlFor="">
                      Select Tier<sup>*</sup>
                    </label>
                    <Dropdown
                      header={HeadersOperator()}
                      api={ApiConfig.fetchTier}
                      onSelect={handleTierID}
                      column={'name'}
                      retrievedValue={tier}
                      key="tier"
                      onSelectOption={handleTierName}
                    />
                    {errors?.Tier_id && (
                      <p className="text-danger">{errors?.Tier_id}</p>
                    )}
                  </div>
                </div>

                <div className={styles1.fieldContainer3}>
                  <div className={styles1.checkboxContainer1}>
                    <label htmlFor="">
                      Reward Criteria<sup>*</sup>
                    </label>
                    <RadioButtonBlue
                      firstText="Point"
                      secondText="Percentage"
                      onChange={handleRewardFlag}
                      value={rewardFlag}
                    />
                  </div>
                  <div className={styles1.innerFieldContainer3}>
                    <label htmlFor="">
                      Reward<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      name={
                        rewardFlag === 'Point'
                          ? 'Reward_points'
                          : 'Reward_percent'
                      }
                      value={
                        (rewardFlag === 'Point'
                          ? formData.Reward_points
                          : (formData.Reward_points = '')) ||
                        (rewardFlag === 'Percentage'
                          ? formData.Reward_percent
                          : (formData.Reward_percent = ''))
                      }
                      onChange={handleChange}
                    />
                  </div>
                  <div className={styles1.innerFieldContainer3}>
                    <label htmlFor="">
                      Campaign Description
                      <sup>*</sup>
                    </label>
                    <input
                      type="text"
                      name="Campaign_description"
                      value={formData.Campaign_description}
                      onChange={handleChange}
                    />
                    {errors?.Campaign_description && (
                      <p className="text-danger">
                        {errors?.Campaign_description}
                      </p>
                    )}
                  </div>
                </div>

                <div className={styles1.fieldContainer6}>
                  <div className={styles1.checkboxContainer6}>
                    <label htmlFor="">
                      Reward once (Customer + Account + Product)
                    </label>
                    <RadioButtonBlue
                      firstText="Yes"
                      secondText="No"
                      name="Tier_flag"
                      onChange={handleRewardOnceFlag}
                      value={formData.Reward_once_flag}
                    />
                    {errors?.Reward_once_flag && (
                      <p className="text-danger">{errors?.Reward_once_flag}</p>
                    )}
                  </div>
                  <div className={styles1.checkboxContainer6}>
                    <label htmlFor="">Campaign Status *</label>
                    <RadioButtonBlue
                      firstText="Enabled"
                      secondText="Disabled"
                      name="campaign_status"
                      onChange={handleCampaignStatus}
                      value={formData.campaign_status}
                    />
                    {errors?.campaign_status && (
                      <p className="text-danger">{errors?.campaign_status}</p>
                    )}
                  </div>
                </div>

                <div className={styles1.fieldContainer5}>
                  <div className={styles1.checkboxContainer5}>
                    <label htmlFor="">Schedule</label>
                    <RadioButtonBlue
                      firstText="Yes"
                      secondText="No"
                      name="Schedule"
                      onChange={handleSchedule}
                      value={formData.Schedule}
                    />
                    {errors?.Schedule && (
                      <p className="text-danger">{errors?.Schedule}</p>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={styles.buttonContainer}>
            {isSubmit && (
              <button
                className={styles.submit}
                onClick={e => {
                  e.preventDefault();
                  addBonusMutation();
                }}>
                Submit
              </button>
            )}

            {isUpdate && (
              <button
                className={styles.submit}
                onClick={e => {
                  e.preventDefault();
                  updateBonusMutation();
                }}>
                Update
              </button>
            )}
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer1}>
            <table className={styles.mainTable1}>
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th>Linked to LBS?</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Transaction Amount</th>
                  <th>Reward Points</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {status === 'error' && (
                  <tr>
                    <td className="text-danger">Error Occured!</td>
                  </tr>
                )}

                {status === 'loading' && (
                  <tr>
                    <td className="text-info">loading...</td>
                  </tr>
                )}

                {status === 'success' &&
                  (bonus.length === 0 ? (
                    <tr>
                      <td>No Data!</td>
                    </tr>
                  ) : (
                    bonus?.map(
                      each =>
                        each?.Active_flag === 1 && (
                          <tr key={each.Campaign_id}>
                            <td>{each.Campaign_name}</td>
                            <td>{each.LBS_linked}</td>
                            <td>{each.From_date}</td>
                            <td>{each.To_date}</td>
                            <td>{each.Transaction_amount}</td>
                            <td>{each.Reward_points}</td>

                            <td>
                              <div className={styles.iconContainer}>
                                <span
                                  className={styles.icon}
                                  onClick={() => {
                                    setUpdateID(each.Campaign_id);
                                    setIsSubmit(false);
                                    setIsUpdate(true);
                                    setSelectedItem(each);
                                    setFormData({
                                      Loyalty_program_id:
                                        each?.Loyalty_program_id,
                                      Company_id: each?.Company_id,
                                      Membership_id: each?.Membership_id,
                                      Product_group_id: each?.Product_group_id,
                                      Product_brand_id: each?.Product_brand_id,
                                      Transaction_id: each?.Transaction_id,
                                      Product_code: each?.Product_code,
                                      Transaction_channel_id:
                                        each?.Transaction_channel_id,
                                      Transaction_amount:
                                        each?.Transaction_amount,
                                      Transaction_amt_flag:
                                        each?.Transaction_amt_flag,
                                      Tier_flag: each?.Tier_flag,
                                      Tier_id: each?.Tier_id,
                                      branch_id: each?.branch_id,
                                      From_date: each?.From_date,
                                      To_date: each?.To_date,
                                      Campaign_name: each?.Campaign_name,
                                      Campaign_description:
                                        each?.Campaign_description,
                                      campaign_status: each?.campaign_status,
                                      company_name: each?.company_name,
                                      program: each?.program,
                                      Sweepstake_id: each?.Sweepstake_id,
                                      Sweepstake_flag: each?.Sweepstake_flag,
                                      Sweepstake_ticket_limit:
                                        each?.Sweepstake_ticket_limit,
                                      Schedule: each?.Schedule,
                                    });
                                    window.scrollTo({
                                      top: 0,
                                      left: 0,
                                      behavior: 'smooth',
                                    });
                                  }}>
                                  <ViewPen />
                                </span>
                                <span
                                  className={styles.icon}
                                  onClick={() => {
                                    setDeleteId(each.Campaign_id);
                                    setDeleteModal(true);
                                  }}>
                                  <Times />
                                </span>
                              </div>
                            </td>
                          </tr>
                        ),
                    )
                  ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <Paginate page={page} data={bonusData} setPage={setPage} />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <p className={styles.disabledCampaigns}>Disabled Campaigns</p>

          <div className={styles.tableContainer1}>
            <table className={styles.mainTable1}>
              <thead>
                <tr>
                  <th>Campaign Name</th>
                  <th>Linked to LBS?</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Transaction Amount</th>
                  <th>Reward Points</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {status === 'error' && (
                  <tr>
                    <td className="text-danger">Error Occured!</td>
                  </tr>
                )}

                {status === 'loading' && (
                  <tr>
                    <td className="text-info">loading...</td>
                  </tr>
                )}

                {status === 'success' &&
                  (bonus.length === 0 ? (
                    <tr>
                      <td>No Data!</td>
                    </tr>
                  ) : (
                    bonus?.map(
                      each =>
                        each?.Active_flag === 0 && (
                          <tr key={each.Campaign_id}>
                            <td>{each.Campaign_name}</td>
                            <td>{each.LBS_linked}</td>
                            <td>{each.From_date}</td>
                            <td>{each.To_date}</td>
                            <td>{each.Transaction_amount}</td>
                            <td>{each.Reward_points}</td>

                            <td>
                              <div className={styles.iconContainer}>
                                <span
                                  className={styles.icon}
                                  onClick={() => {
                                    setUpdateID(each.Campaign_id);
                                    setIsSubmit(false);
                                    setIsUpdate(true);
                                    setSelectedItem(each);
                                    setFormData({
                                      Loyalty_program_id:
                                        each?.Loyalty_program_id,
                                      Company_id: each?.Company_id,
                                      Membership_id: each?.Membership_id,
                                      Product_group_id: each?.Product_group_id,
                                      Product_brand_id: each?.Product_brand_id,
                                      Transaction_id: each?.Transaction_id,
                                      Product_code: each?.Product_code,
                                      Transaction_channel_id:
                                        each?.Transaction_channel_id,
                                      Transaction_amount:
                                        each?.Transaction_amount,
                                      Transaction_amt_flag:
                                        each?.Transaction_amt_flag,
                                      Tier_flag: each?.Tier_flag,
                                      Tier_id: each?.Tier_id,
                                      branch_id: each?.branch_id,
                                      From_date: each?.From_date,
                                      To_date: each?.To_date,
                                      Campaign_name: each?.Campaign_name,
                                      Campaign_description:
                                        each?.Campaign_description,
                                      campaign_status: each?.campaign_status,
                                      company_name: each?.company_name,
                                      program: each?.program,
                                      Sweepstake_id: each?.Sweepstake_id,
                                      Sweepstake_flag: each?.Sweepstake_flag,
                                      Sweepstake_ticket_limit:
                                        each?.Sweepstake_ticket_limit,
                                      Schedule: each?.Schedule,
                                    });
                                  }}>
                                  <ViewPen />
                                </span>
                                <span
                                  className={styles.icon}
                                  onClick={() => {
                                    setDeleteId(each.Campaign_id);
                                    setDeleteModal(true);
                                  }}>
                                  <Times />
                                </span>
                              </div>
                            </td>
                          </tr>
                        ),
                    )
                  ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <Paginate page={page} data={bonusData} setPage={setPage} />
          </div>
        </div>
      </div>
      {/* Pass your clear input function to clearInputFn */}
      <ClearInputModal
        clearInputFn={handleReset}
        clearModalStatus={clearModal}
        clearInputModalState={setClearModal}
      />
      {deleteModal && (
        <MenuDeleteModal
          onClose={onClose}
          onDelete={() => deleteBonusCampaign(deleteId)}
        />
      )}
    </CallCenterStructure>
  );
};

export default BonusRegularCampaign;