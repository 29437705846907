import React from 'react';
import styles from '../../styles/Table.module.scss';

const TableModule = ({data}) => {
  return (
    // <div className={styles.tableContainer}>
    <table className={styles.tableInnerContainer}>
      <thead className={styles.thead}>
        <tr>
          {data?.length > 0 &&
            Object.keys(data?.[0]).map(key => <th key={key}>{key}</th>)}
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => (
          <tr key={index}>
            {Object.values(item).map((value, idx) => (
              <td key={idx}>{value}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
    // </div>
  );
};

export default TableModule;
