import React, {useState} from 'react';
import Dropdown from '../../component/DropDown/DropDown';
import styles from '../../styles/CustomerWebsiteLogin.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import login1 from '../../assets/images/login1.png';
import login2 from '../../assets/images/login2.png';
import styles1 from '../../styles/RadioButtonBlue.module.scss';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
const CustomerWebsiteLogin = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [selectedImageName1, setSelectedImageName1] = useState('');
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [selectedImageName2, setSelectedImageName2] = useState('');
  const [selectedImage3, setSelectedImage3] = useState(null);
  const [selectedImageName3, setSelectedImageName3] = useState('');

  // Upload Images State Up There

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);

  const options = [
    {label: 'Template 1', img: login1},
    {label: 'Template 2', img: login2},
  ];

  const options1 = [{color: '#628628'}, {color: '#8D8298'}, {color: '#AAA628'}];
  const options2 = [{color: '#628698'}, {color: '#8D8298'}, {color: '#AAB628'}];
  const options3 = [{color: '#628898'}, {color: '#8A8298'}, {color: '#AAC628'}];

  const handleOptionChange = option => {
    setSelectedOption(option);
  };

  const handleOptionChange1 = option => {
    setSelectedOption1(option);
  };

  const handleOptionChange2 = option => {
    setSelectedOption2(option);
  };
  const handleOptionChange3 = option => {
    setSelectedOption3(option);
  };
  return (
    <div>
      <CallCenterStructure>
        <div className={styles.CustomerWebsiteLogin}>
          <div className={styles.headerText}>
            <p>Customer Website Login</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field1}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Enter Customer Website Login Template Name<sup>*</sup>
                </label>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder="Insert your text here"
                />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer2}>
                <label htmlFor="">
                  Choose Template<sup>*</sup>
                </label>
                <div className={styles.radioButtonsContainer}>
                  {options.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer}
                      key={index}
                      onClick={() => handleOptionChange(option.label)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.label === selectedOption
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <div className={styles.radioInnerImage}>
                          <img src={option.img} alt={option.label} />
                        </div>
                        <p>{option.label}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Choose Background Color</label>
                <div className={styles.radioButtonsContainer3}>
                  {options1.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer3}
                      key={index}
                      onClick={() => handleOptionChange1(option.color)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.color === selectedOption1
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <div
                          style={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: `${option.color}`,
                          }}></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">More Color:</label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Choose Background Image</label>
                <div className={styles.radioButtonsContainer3}>
                  {options2.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer3}
                      key={index}
                      onClick={() => handleOptionChange2(option.color)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.color === selectedOption2
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <div
                          style={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: `${option.color}`,
                          }}></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Upload Image</label>
                <ImageDragAndDrop
                  selectedImage1={selectedImage1}
                  selectedImageName1={selectedImageName1}
                  setSelectedImage1={setSelectedImage1}
                  setSelectedImageName1={setSelectedImageName1}
                />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Choose Image for Header Panel</label>
                <div className={styles.radioButtonsContainer3}>
                  {options3.map((option, index) => (
                    <div
                      className={styles.radioButtonContainer3}
                      key={index}
                      onClick={() => handleOptionChange3(option.color)}>
                      <div className={styles1.myCheckbox}>
                        <div
                          className={
                            option.color === selectedOption3
                              ? styles1.checked
                              : styles1.uncheck
                          }></div>
                      </div>
                      <div className={styles.radioImage}>
                        <div
                          style={{
                            width: '40px',
                            height: '40px',
                            backgroundColor: `${option.color}`,
                          }}></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Upload Image</label>
                <ImageDragAndDrop
                  selectedImage1={selectedImage1}
                  selectedImageName1={selectedImageName1}
                  setSelectedImage1={setSelectedImage1}
                  setSelectedImageName1={setSelectedImageName1}
                />
              </div>
            </div> */}
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Choose Background Image<sup>*</sup>
                </label>
                <ImageDragAndDrop
                  selectedImage2={selectedImage2}
                  selectedImageName2={selectedImageName2}
                  setSelectedImage2={setSelectedImage2}
                  setSelectedImageName2={setSelectedImageName2}
                />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Upload Your Company Logo<sup>*</sup>
                </label>
                <ImageDragAndDrop
                  selectedImage3={selectedImage3}
                  selectedImageName3={selectedImageName3}
                  setSelectedImage3={setSelectedImage3}
                  setSelectedImageName3={setSelectedImageName3}
                />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Headline Message<sup>*</sup>
                </label>
                <textarea name="" id="" placeholder="Insert your text here" />
              </div>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">
                  Welcome Message<sup>*</sup>
                </label>
                <textarea name="" id="" placeholder="Insert your text here" />
              </div>
            </div>
            <div className={styles.field2}>
              <div className={styles.fieldContainer1}>
                <label htmlFor="">Select Font Family to Apply</label>
                <Dropdown />
              </div>
            </div>

            <div className={styles.buttonContainer}>
              <button className={styles.submit}>Submit</button>
              <button className={styles.reset}>Preview</button>
            </div>
          </div>
        </div>
      </CallCenterStructure>
    </div>
  );
};

export default CustomerWebsiteLogin;
