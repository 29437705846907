import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import styles from "../../styles/SideBar.module.scss";
import logo from "../../assets/images/PerxLogo.png";
import logoSmall from "../../assets/images/PerxLogoSmall.png";
import {
  ChevronDown,
  ChevronUp,
  CompanyMasterSetup,
  CustomerEnrolment,
  DashabordIcon,
  DefinedMaster,
  PhoneIcon,
} from "../../assets/icons";
import { Link, useFetcher, useNavigate } from "react-router-dom";
import axiosProperty from "../../service/HttpConfig";
import ApiConfig from "../../service/ApiConfig";
import { HeadersOperator } from "../../service/Essentials";
import { useQuery } from "react-query";
// import wole from

const Sidebar = ({
  isNav,
  state,
  handleAllState,
  handleAllValues,
  dropwDownValue,
  definedMasterDropdown,
  handleDefinedMasterValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [getMenu, setGetMenu] = useState([]);
  const queryClient = useQueryClient();

  // const [dropdownText, setDropDownText] = useState(false);
  // const [dropdownText1, setDropDownText1] = useState(false);

  // console.log(mainValue, 'mainValue');

  // console.log(dropwDownValue, 'dropwDownValue');

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [definedMasterState, setDefinedMasterState] = useState(false);
  const [openId, setOpenId] = useState(null);
  const [oneId, setOneId] = useState(null);
  const navigate = useNavigate();
  // useEffect(() => {
  //   const value1 = localStorage.getItem('dropdownText');
  //   const value2 = localStorage.getItem('dropdownText1');

  //   if (value1 === 'true') {
  //     handleDropdownText();
  //   }
  //   if (value2 === 'true') {
  //     handleDropdownText1();
  //   }
  // }, []);

  // const handleDropdownText = () => {
  //   setDropDownText(true);
  //   setDropDownText1(false);
  // };

  // const handleDropdownText1 = () => {
  //   setDropDownText1(true);
  //   setDropDownText(false);
  // };

  // useEffect(() => {
  //   if (dropdownText) {
  //     localStorage.setItem('dropdownText', 'true');
  //   } else {
  //     localStorage.setItem('dropdownText', 'false');
  //   }
  // }, [dropdownText]);

  // useEffect(() => {
  //   if (dropdownText1) {
  //     localStorage.setItem('dropdownText1', 'true');
  //   } else {
  //     localStorage.setItem('dropdownText1', 'false');
  //   }
  // }, [dropdownText1]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMouseEnter1 = () => {
    setIsHovered1(true);
  };

  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };

  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };
  const handleMouseEnter3 = () => {
    setIsHovered3(true);
  };

  const handleMouseLeave3 = () => {
    setIsHovered3(false);
  };

  // const toggleDropdown = (event) => {
  //   // console.log(id)
  //   // if (showMenu && ref.current && !ref.current.contains(event.target)) {
  //   //   setIsOpen(false);
  //   // }
  //   setIsOpen(!isOpen);
  //   // if(id === openId){
  //   // }
  // };
  const getNavId = JSON.parse(sessionStorage.getItem("navId"));
  const getChildId = JSON.parse(sessionStorage.getItem("childId"));

  useEffect(() => {
    setOpenId(getNavId);
    setOneId(getChildId);
  }, [getNavId, getChildId]);

  const toggleDropdown = (index) => {
    if (getNavId === null) {
      setOpenId(index);
    } else {
      setOpenId((prevIndex) => {
        if (prevIndex === getNavId) {
          return null;
        } else if (prevIndex !== getNavId) {
          return index;
        }
      });
    }
  };

  const toggleDefinedMasterState = () => {
    setDefinedMasterState(!definedMasterState);
  };

  const handleMenu = async (page) => {
    const res = await axiosProperty.get(ApiConfig.fetchAssignMenu(page), {
      headers: HeadersOperator(),
    });
    return res.data;
  };

  const { data: allData, status } = useQuery(
    ["allmenu", page],
    () => handleMenu(page),
    // queryClient.invalidateQueries(["allmenu"]),
    { keepPreviousData: true }
    // client.queryManager.refetchQueryByName('allmenu')
  );

  useEffect(() => {
    setGetMenu(allData?.data || []);
  }, [allData]);
  //  const { mutate: handleMenuMutation } = useMutation({
  //     mutationFn: handleMenu,
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(["allmenu"]);
  //     }
  //   });

  return (
    <>
      {isNav ? (
        <div>
          <div className={styles.sidebarSmall}>
            <div className={styles.logoContainerSmall}>
              <img src={logoSmall} alt="logo" />
            </div>

            <ul className={styles.sidebarMenuSmall}>
              <li
                className={styles.menuItemSmall}
                onClick={() => handleAllState("dashboard")}
              >
                <div
                  className={styles.iconSettingsSmall}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <Link to="/" className={styles.singlelink}>
                    <DashabordIcon state={state} />
                  </Link>
                  <div
                    className={`${styles.container} ${
                      isHovered ? styles.visible : styles.invisible
                    }`}
                  >
                    <Link to="/" className={styles.singlelink}>
                      <div className={styles.innerContainer}>
                        <p>Dashboard</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              {getMenu?.map((each) => {
                return (
                  <li
                    className={styles.menuItemSmall}
                    onClick={() => handleAllState(`${each.name}`)}
                  >
                    <div
                      className={styles.iconSettingsSmall}
                      onMouseEnter={handleMouseEnter1}
                      onMouseLeave={handleMouseLeave1}
                    >
                      <PhoneIcon state={state} />
                      <div
                        className={`${styles.container} ${
                          isHovered1 ? styles.visible : styles.invisible
                        }`}
                      >
                        <div className={styles.innerContainerDropdown}>
                          <p>{each.name}</p>
                          <ul className={styles.innerUl}>
                            {each.children?.map((child) => {
                              return (
                                <li className={styles.innerli}>
                                  <Link
                                    to={child.href}
                                    className={styles.dropdownLink}
                                  >
                                    {" "}
                                    <span>•</span> {child.name}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}

              <li
                className={styles.menuItemSmall}
                onClick={() => handleAllState("masterSetup")}
              >
                <div
                  className={styles.iconSettingsSmall}
                  onMouseEnter={handleMouseEnter2}
                  onMouseLeave={handleMouseLeave2}
                >
                  <CompanyMasterSetup state={state} />
                  <div
                    className={`${styles.container} ${
                      isHovered2 ? styles.visible : styles.invisible
                    }`}
                  >
                    <Link className={styles.singlelink} to="/">
                      <div className={styles.innerContainer}>
                        <p>Company Master Setupppp</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
              <li
                className={styles.menuItemSmall}
                onClick={() => handleAllState("enrolment")}
              >
                <div
                  className={styles.iconSettingsSmall}
                  onMouseEnter={handleMouseEnter3}
                  onMouseLeave={handleMouseLeave3}
                >
                  <CustomerEnrolment state={state} />
                  <div
                    className={` ${styles.container} ${
                      isHovered3 ? styles.visible : styles.invisible
                    }`}
                  >
                    <Link className={styles.singlelink} to="/">
                      <div className={styles.innerContainer}>
                        <p>Customer Enrolment</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className={styles.sidebar}>
          <ul className={styles.sidebarMenu}>
            <li className={styles.menuItemLogo}>
              <div className={styles.logoContainer}>
                <img src={logo} alt="perx logo" />
              </div>
            </li>
            <div className={styles.makeScroll}>
              <div className={styles.makeInnerScroll}>
                <li
                  className={styles.menuItem1}
                  onClick={() => [
                    handleAllState("dashboard"),
                    setIsOpen(false),
                  ]}
                >
                  <DashabordIcon state={state} />
                  <div className={styles.sideNav}>
                    <Link to="/dashboard">
                      <p
                        className={
                          state.dashboard
                            ? styles.activeRoute
                            : styles.unactiveRoute
                        }
                      >
                        Dashboard
                      </p>
                    </Link>
                  </div>
                </li>
                {status === "loading" && (
                  <p className="text-info" style={{ paddingLeft: "10px" }}>
                    loading...
                  </p>
                )}

                {status === "success" &&
                  getMenu?.map((each, i) => {
                    return (
                      <li className={styles.menuItem} key={i}>
                        <div className={styles.menuItemAdjust}>
                          <div
                            className={styles.menuItemDropDown}
                            // onClick={() => [
                            //   handleAllState(each.name),
                            //   // setDropDownText(true),
                            // ]}
                            onClick={() => {
                              sessionStorage.setItem(
                                "navId",
                                JSON.stringify(each.id)
                              );
                              toggleDropdown(each.id);
                            }}
                          >
                            {/* <PhoneIcon state={state} /> */}
                            <CompanyMasterSetup state={state} />

                            <div className={styles.sideDropdownHeader}>
                              <div className={styles.sideNavDropDown}>
                                <div className={styles.sideNavDropDownInner}>
                                  <p
                                    className={
                                      openId === each.id
                                        ? styles.activeRoute
                                        : styles.unactiveRoute
                                    }
                                  >
                                    {each.name}
                                  </p>
                                  <div className={styles.chevronContainer}>
                                    {openId === each.id ? (
                                      <ChevronUp />
                                    ) : (
                                      <ChevronDown />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {openId === each.id && (
                            <ul className={styles.sidebarDropdownOptions}>
                              {each.children?.map((one, ii) => {
                                return (
                                  <li
                                    key={ii}
                                    onClick={() => {
                                      sessionStorage.setItem(
                                        "childId",
                                        JSON.stringify(one.id)
                                      );
                                      setOneId(one.id);
                                    }}
                                  >
                                    <Link to={`/${one.href}`}>
                                      <p
                                        className={
                                          oneId === one.id
                                            ? styles.dropdownTextActive
                                            : styles.dropdownText
                                        }
                                        // onClick={(event)=>{
                                        //   event.preventDefault()
                                        //   navigate(`/${one.href}`,{replace: true})
                                        // }}
                                      >
                                        {one.name}
                                      </p>
                                    </Link>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      </li>
                    );
                  })}
              </div>
            </div>
          </ul>
        </div>
      )}
    </>
  );
};

// const renderMenu = items => {
//   return (
//     <ul>
//       {items.map(i => {
//         return (
//           <li>
//             <a href={i.link}>{i.title}</a>
//             {i.menu && renderMenu(i.menu)}
//           </li>
//         );
//       })}
//     </ul>
//   );
// };

// const Menu = ({data}) => {
//   return (
//     <nav>
//       <h2>{data.title}</h2>
//       {renderMenu(data.menu)}
//     </nav>
//   );
// };

export default Sidebar;
