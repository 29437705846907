import React from 'react';
import Dropdown from '../../component/DropDown/DropDown';
import styles from '../../styles/PaymentToLSL.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';

const PaymentToLSL = () => {
  return (
    <CallCenterStructure>
      <div className={styles.paymentToLSLContainer}>
        <div className={styles.headerText}>
          <p>Payment to LSL Catalogue Partner</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputInnerContainer}>
            <div className={styles.inputAndLabelContainer}>
              <label htmlFor="">
                Enter Invoice Number<sup>*</sup>
              </label>
              <input type="text" />
            </div>
            <div className={styles.inputAndLabelContainer}>
              <label htmlFor="">
                Select Partner<sup>*</sup>
              </label>

              <Dropdown />
            </div>
            <div className={styles.inputAndLabelContainer}>
              <label htmlFor="">
                {' '}
                Select Company<sup>*</sup>
              </label>
              <Dropdown />
            </div>
          </div>

          <div className={styles.inputInnerContainer}>
            <div className={styles.inputAndLabelContainer}>
              <label htmlFor="">
                From Date<sup>*</sup>
              </label>

              <input type="date" />
            </div>
            <div className={styles.inputAndLabelContainer}>
              <label htmlFor="">
                Till Date<sup>*</sup>
              </label>

              <input type="date" />
            </div>
            <div className={styles.inputAndLabelContainer}>
              <label htmlFor="">
                Select Loyalty Program<sup>*</sup>
              </label>

              <Dropdown />
            </div>
          </div>
        </div>
      </div>
    </CallCenterStructure>
  );
};

export default PaymentToLSL;
