import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {Login} from './pages/Login/Login';
// import CallCenter from './pages/CallCenter/CallCenter';
import DashBoard from './pages/Home/DashBoard';
import HandleMemberQuery from './pages/HandleMemberQuery/HandleMemberQuery';
import Profile from './pages/Profile/Profile';
import RedeemCatalogue from './pages/RedeemCatalogue/RedeemCatalogue';
import Cart from './pages/Cart/Cart';
import TransactionDetails from './pages/TransactionDetails/TransactionDetails';
import MemberQueryRecord from './pages/MemberQueryRecord/MemberQueryRecord';
import LogQuery from './pages/LogQuery/LogQuery';
import FamilyCard from './pages/FamilyCard/FamilyCard';
import LogDetails from './pages/LogDetails/LogDetails';
import UserType from './pages/UserType/UserType';
import ApplicationTransaction from './pages/ApplicationTransaction/ApplicationTransaction';
import NotificationTemplate from './pages/NotificationTemplate/NotificationTemplate';
import PaymentType from './pages/PaymentType/PaymentType';
import CodeDecode from './pages/CodeDecode/CodeDecode';
import CodeDecodeMaster from './pages/CodeDecodeMaster/CodeDecodeMaster';
// import AddTierCriteria from './pages/AddTierCriteria/AddTierCriteria';
import TierLevelMaster from './pages/TierLevelMaster/TierLevelMaster';
import CreateMasterMenu from './pages/CreateMasterMenu/CreateMasterMenu';
import ViewAndUpdateMenuMap from './pages/ViewAndUpdateMenuMap/ViewAndUpdateMenuMap';
import PrivilegeMaster from './pages/PrivilegeMaster/PrivilegeMaster';
import CompanySolutionTypeLogo from './pages/CompanySolutionTypeLogo/CompanySolutionTypeLogo';
import PaymentToLSL from './pages/PaymentToLSL/PaymentToLSL';
import LSLPoints from './pages/LSLPoints/LSLPoints';
import LSLInvoice from './pages/LSLInvoice/LSLInvoice';
import LSLPartner from './pages/LSLPartner/LSLPartner';
import LSLPartnerMaster from './pages/LSLPartnerMaster/LSLPartnerMaster';
import LSLPartnerBranchMaster from './pages/LSLPartnerBranchMaster/LSLPartnerBranchMaster';
import CreatePartnerCategory from './pages/CreatePartnerCategory/CreatePartnerCategory';
import CreateCatalogueMerchandizeCategory from './pages/CreateCatalogueMerchandizeCategory/CreateCatalogueMerchandizeCategory';
import CreateCatalogueMerchandizeItems from './pages/CreateCatalogueMerchandizeItems/CreateCatalogueMerchandizeItems';
import CountryCurrencyMasterSetup from './pages/CountryCurrencyMasterSetup/CountryCurrencyMasterSetup';
import RegionMaster from './pages/RegionMaster/RegionMaster';
import ZoneMasterSetup from './pages/ZoneMasterSetup/ZoneMasterSetup';
import StateMasterSetup from './pages/StateMasterSetup/StateMasterSetup';
import CityMasterSetup from './pages/CityMasterSetup/CityMasterSetup';
import WeightMasterSetup from './pages/WeightMasterSetup/WeightMasterSetup';
import DeliveryPriceMasterReference from './pages/DeliveryPriceMasterReference/DeliveryPriceMasterReference';
import CustomerEnrolmentAtBranch from './pages/CustomerEnrolmentAtBranch/CustomerEnrolmentAtBranch';
import TemplateMasterSetupForSMS from './pages/TemplateMasterSetupForSMS/TemplateMasterSetupForSMS';
import TemplateMasterSetupForEmail from './pages/TemplateMasterSetupForEmail/TemplateMasterSetupForEmail';
import RenewalOfLicense from './pages/RenewalOfLicense/RenewalOfLicense';
import RegenerationOfMembershipID from './pages/RegenerationOfMembershipID/RegenerationOfMembershipID';
import ResellerCompanyRegistration from './pages/ResellerCompanyRegistration/ResellerCompanyRegistration';
import SummaryOfLicenseMembershipRegeneration from './pages/SummaryOfLicenseMembershipRegeneration/SummaryOfLicenseMembershipRegeneration';
import RegisterStandaloneCompany from './pages/RegisterStandaloneCompany/RegisterStandaloneCompany';
import GenerateLicenseKeyForStandaloneCompany from './pages/GenerateLicenseKeyForStandaloneCompany/GenerateLicenseKeyForStandaloneCompany';
import GameMasterSetup from './pages/GameMasterSetup/GameMasterSetup';
import CustomerEnrolmentFromWebsite from './pages/CustomerEnrolmentFromWebsite/CustomerEnrolmentFromWebsite';
import AssignMembershipID from './pages/AssignMembershipID/AssignMembershipID';
import AssignMembeshipIdList from './pages/AssignMembeshipIdList/AssignMembeshipIdList';
import AddFamilyMembersAtBranch from './pages/AddFamilyMembersAtBranch/AddFamilyMembersAtBranch';
import AddFamilyMemberAtBranchForm from './pages/AddFamilyMemberAtBranchForm/AddFamilyMemberAtBranchForm';
import AssignMembershipIDToFamilyMembers from './pages/AssignMembershipIDToFamilyMembers/AssignMembershipIDToFamilyMembers';
// import HandleMemberQueryAtBranch from './pages/AssignFamilyMembermembershipIDList/AssignFamilyMembermembershipIDList';
import AssignFamilyMembermembershipIDList from './pages/AssignFamilyMembermembershipIDList/AssignFamilyMembermembershipIDList';
import CustomerEnrolmentFileUpload from './pages/CustomerEnrolmentFileUpload/CustomerEnrolmentFileUpload';
import CreateValueSpendRegularTable from './pages/ValueSpendRegularCampaignTable/ValueSpendRegularCampaignTable';
import CreateFixedBudgetRegularTable from './pages/FixedBudgetRegularCampaignTable/FixedBudgetRegularCampaignTable';
import CashbackRegularCampaign from './pages/CashbackRegularCampaign/CashbackRegularCampaign';
import BonusRegularCampaign from './pages/BonusRegularCampaign/BonusRegularCampaign';
import DiscountBenefitCampaign from './pages/DiscountBenefitCampaign/DiscountBenefitCampaign';
import UpgradePrivilegeCampaign from './pages/UpgradePrivilegeCampaign/UpgradePrivilegeCampaign';
import ReferalCampaign from './pages/ReferalCampaign/ReferalCampaign';
import PromoCampaign from './pages/PromoCampaign/PromoCampaign';
import CreateSweepstake from './pages/CreateSweepstake/CreateSweepstake';
import AddCriteria from './pages/AddCriteria/AddCriteria';
import AuctionMaster from './pages/AuctionMaster/AuctionMaster';
import AuctionSweepstakeWinner from './pages/AuctionSweepstakeWinner/AuctionSweepstakeWinner';
import ApproveAuction from './pages/ApproveAuction/ApproveAuction';
import CumulativePeriodic from './pages/CumulativePeriodic/CumulativePeriodic';
import FrequencyCountPeriodicCampaign from './pages/FrequencyCountPeriodicCampaign/FrequencyCountPeriodicCampaign';
import SpecialDateCampaign from './pages/SpecialDateCampaign/SpecialDateCampaign';
import CashbackPeriodicCampaign from './pages/CashbackPeriodicCampaign/CashbackPeriodicCampaign';
import BonusPeriodicCampaign from './pages/BonusPeriodicCampaign/BonusPeriodicCampaign';
import FixedBudgetPeriodicCampaign from './pages/FixedBudgetPeriodicCampaign/FixedBudgetPeriodicCampaign';
import CreateGameCampaign from './pages/CreateGameCampaign/CreateGameCampaign';
import ApproveGameWinner from './pages/ApproveGameWinner/ApproveGameWinner';
import InwardStockEntry from './pages/InwardStockEntry/InwardStockEntry';
import ManualDebitTransaction from './pages/ManualDebitTransaction/ManualDebitTransaction';
import ManualCreditTransaction from './pages/ManualCreditTransaction/ManualCreditTransaction';
import EmailMasterSetup from './pages/EmailMasterSetup/EmailMasterSetup';
import WalletCreditTransaction from './pages/WalletCreditTransaction/WalletCreditTransaction';
import WalletDebitTransaction from './pages/WalletDebitTransaction/WalletDebitTransaction';
import PointsAwardTransaction from './pages/PointsAwardTransaction/PointsAwardTransaction';
import SurveyStructureMaster from './pages/SurveyStructureMaster/SurveyStructureMaster';
import RegisterCompany from './pages/RegisterCompany/RegisterCompany';
import PaymentToCataloguePartner from './pages/PaymentToCataloguePartner/PaymentToCataloguePartner';
import SurveyQuestionnaireMaster from './pages/SurveyQuestionnaireMaster/SurveyQuestionnaireMaster';
import SendSurvey from './pages/SendSurvey/SendSurvey';
import LoyaltyProgramMaster from './pages/LoyaltyProgramMaster/LoyaltyProgramMaster';
import CompanyBranchMaster from './pages/CompanyBranchMaster/CompanyBranchMaster';
import CompanyTransactionTypeMasterSetup from './pages/CompanyTransactionTypeMasterSetup/CompanyTransactionTypeMasterSetup';
import CompanyTransactionChannelMaster from './pages/CompanyTransactionChannelMaster/CompanyTransactionChannelMaster';
import UserGroupMaster from './pages/UserGroupMaster/UserGroupMaster';
import ProductGroupMaster from './pages/ProductGroupMaster/ProductGroupMaster';
import ProductBrandMaster from './pages/ProductBrandMaster/ProductBrandMaster';
import CompanyUserEnrollment from './pages/CompanyUserEnrollment/CompanyUserEnrollment';
import ViewOrEditCompanyUser from './pages/ViewOrEditCompanyUser/ViewOrEditCompanyUser';
import CompanyDashboardGraphSetup from './pages/CompanyDashboardGraphSetup/CompanyDashboardGraphSetup';
import BranchTransactionReport from './pages/BranchTransactionReport/BranchTransactionReport';
import ShippingCostReport from './pages/ShippingCostReport/ShippingCostReport';
import UserRedemptionReport from './pages/UserRedemptionReport/UserRedemptionReport';
import CashBackSendProcess from './pages/CashBackSendProcess/CashBackSendProcess';
import SystemUserEnrolmentReport from './pages/SystemUserEnrolmentReport/SystemUserEnrolmentReport';
import EVoucherRedemptionReport from './pages/EVoucherRedemptionReport/EVoucherRedemptionReport';
import CampaignReport from './pages/CampaignReport/CampaignReport';
import FileUploadStatusReport from './pages/FileUploadStatusReport/FileUploadStatusReport';
import LoginStatusReport from './pages/LoginStatusReport/LoginStatusReport';
import CompanyPointsLiabilityReport from './pages/CompanyPointsLiabilityReport/CompanyPointsLiabilityReport';
import BenefitPartnerReport from './pages/BenefitPartnerReport/BenefitPartnerReport';
import HotListedMemberReport from './pages/HotListedMemberReport/HotListedMemberReport';
import AuditTrackingReport from './pages/AuditTrackingReport/AuditTrackingReport';
import SurveyReport from './pages/SurveyReport/SurveyReport';
import MemberDeliveryOrderStatusReport from './pages/MemberDeliveryOrderStatusReport/MemberDeliveryOrderStatusReport';
import CompanyGiftCardReport from './pages/CompanyGiftCardReport/CompanyGiftCardReport';
import SmsDetailReport from './pages/SmsDetailReport/SmsDetailReport';
import EmailSmsDetailReport from './pages/EmailSmsDetailReport/EmailSmsDetailReport';
import CompanyInvoiceSettlementReport from './pages/CompanyInvoiceSettlementReport/CompanyInvoiceSettlementReport';
import MemberTransactionReport from './pages/MemberTransactionReport/MemberTransactionReport';
import MemberHighValueTransactionReport from './pages/MemberHighValueTransactionReport/MemberHighValueTransactionReport';
import PointsExpiryReport from './pages/PointsExpiryReport/PointsExpiryReport';
import PointsForfeitureReport from './pages/PointsForfeitureReport/PointsForfeitureReport';
import MemberSpecialDateReport from './pages/MemberSpecialDateReport/MemberSpecialDateReport';
import MemberMissingEmailOrPhoneReport from './pages/MemberMissingEmailOrPhoneReport/MemberMissingEmailOrPhoneReport';
import MemberEnrollmentReport from './pages/MemberEnrollmentReport/MemberEnrollmentReport';
import MemberWalletTransactionReport from './pages/MemberWalletTransaction/MemberWalletTransaction';
import BranchStockInventoryReport from './pages/BranchStockInventoryReport/BranchStockInventoryReport';
import BranchStockMovementReport from './pages/BranchStockMovementReport/BranchStockMovementReport';
import CallCentreQueryStatusReport from './pages/CallCentreQueryStatusReport/CallCentreQueryStatusReport';
import CallCentreMemberInteractionReport from './pages/CallCentreMemberInteractionReport/CallCentreMemberInteractionReport';
import CompanyGameMaster from './pages/CompanyGameMaster/CompanyGameMaster';
import CompanyGameConfiguration from './pages/CompanyGameConfiguration/CompanyGameConfiguration';
import ProtectedRoute from './service/ProtectedRoute';
import {QueryClient, QueryClientProvider} from 'react-query';
import CompanyCataloguePartnerMaster from './pages/CompanyCataloguePartnerMaster/CompanyCataloguePartnerMaster';
import CompanyPartnerBranchMaster from './pages/CompanyPartnerBranchMaster/CompanyPartnerBranchMaster';
import PartnerProductAndServiceCategory from './pages/PartnerProductAndServiceCategory/PartnerProductAndServiceCategory';
import PartnerUserEnrollment from './pages/PartnerUserEnrollment/PartnerUserEnrollment';
import ViewOrEditPartnerUser from './pages/ViewOrEditPartnerUser/ViewOrEditPartnerUser';
import PointsForfeitureMaster from './pages/PointsForfeitureMaster/PointsForfeitureMaster';
import CreateCompanyMemberTiers from './pages/CreateCompanyMemberTiers/CreateCompanyMemberTiers';
import SegmentTypeMaster from './pages/SegmentTypeMaster/SegmentTypeMaster';
import CompanyMerchandizeCatalogue from './pages/CompanyMerchandizeCatalogue/CompanyMerchandizeCatalogue';
import SelectItemsFromLSLMerchandizeCatalogue from './pages/SelectItemsFromLSLMerchandizeCatalogue/SelectItemsFromLSLMerchandizeCatalogue';
import ApproveCompanyMerchandizeCatalogue from './pages/ApproveCompanyMerchandizeCatalogue/ApproveCompanyMerchandizeCatalogue';
import CompanyMerchandizeCatalogueCategory from './pages/CompanyMerchandizeCatalogueCategory/CompanyMerchandizeCatalogueCategory';
import AssignMenusToCompanyUsers from './pages/AssignMenusToCompanyUsers/AssignMenusToCompanyUsers';
import AssignPrivilegesToCompanyUsers from './pages/AssignPrivilegesToCompanyUsers/AssignPrivilegesToCompanyUsers';
import AssignAdditionalMenuForCompany from './pages/AssignAdditionalMenuForCompany/AssignAdditionalMenuForCompany';
import PoSInventoryMaster from './pages/PoSInventoryMaster/PoSInventoryMaster';
import PoSItemOpeningBalanceMaster from './pages/PoSItemOpeningBalanceMaster/PoSItemOpeningBalanceMaster';
import DataMappingForEnrollment from './pages/DataMappingForEnrollment/DataMappingForEnrollment';
import DataMappingForTransaction from './pages/DataMappingForTransaction/DataMappingForTransaction';
import DataMappingForCallCenterTransactionUpload from './pages/DataMappingForCallCenterTransactionUpload/DataMappingForCallCenterTransactionUpload';
import ReportAutomationMaster from './pages/ReportAutomationMaster/ReportAutomationMaster';
import ApplicationManagementWebsite from './pages/ApplicationManagementWebsite/ApplicationManagementWebsite';

import CompanyEmailTemplateMaster from './pages/CompanyEmailTemplateMaster/CompanyEmailTemplateMaster';
import CompanySMSTemplateMaster from './pages/CompanySMSTemplateMaster/CompanySMSTemplateMaster';
import PoSReceiptTemplate from './pages/PoSReceiptTemplate/PoSReceiptTemplate';
import VoucherRedemptionReceiptTemplate from './pages/VoucherRedemptionReceiptTemplate/VoucherRedemptionReceiptTemplate';
import DiscountOrUpgradePrivilegeReceiptTemplate from './pages/DiscountOrUpgradePrivilegeReceiptTemplate/DiscountOrUpgradePrivilegeReceiptTemplate';
import ReceiptFooterMessageMaster from './pages/ReceiptFooterMessageMaster/ReceiptFooterMessageMaster';
import CustomerWebsiteLogin from './pages/CustomerWebsiteLogin/CustomerWebsiteLogin';
import SetupCustomerWebsiteHomePageLayout from './pages/SetupCustomerWebsiteHomePageLayout/SetupCustomerWebsiteHomePageLayout';
import SetupCustomerSmartphonePageLayout from './pages/SetupCustomerSmartphonePageLayout/SetupCustomerSmartphonePageLayout';
import CallCenterQueryType from './pages/CallCenterQueryType/CallCenterQueryType';
import CallCenterQuerySetup from './pages/CallCenterQuerySetup/CallCenterQuerySetup';
import CallCenterResolutionPriorityLevel from './pages/CallCenterResolutionPriorityLevel/CallCenterResolutionPriorityLevel';
import CallCenterEscalationMatrix from './pages/CallCenterEscalationMatrix/CallCenterEscalationMatrix';
import MultipleChoiceStructureMaster from './pages/MultipleChoiceStructureMaster/MultipleChoiceStructureMaster';
import LoginTemplate1 from './pages/LoginTemplate1/LoginTemplate1';
import LoginTemplate2 from './pages/LoginTemplate2/LoginTemplate2';
import SingleCategory from './pages/SingleCategory/SingleCategory';
import TemplateCart from './pages/TemplateCart/TemplateCart';
import OrderReceipt from './pages/OrderReceipt/OrderReceipt';
import AccountDashboard from './pages/AccountDashboard/AccountDashboard';
import Movies from './pages/Movies/Movies';
import AirtimeAndBills from './pages/AirtimeAndBills/AirtimeAndBills';
import AirtimeCheckout from './pages/AirtimeCheckout/AirtimeCheckout';
import Vouchers from './pages/Vouchers/Vouchers';
import Events from './pages/Events/Events';
import SmartPhoneHomePage from './pages/SmartPhoneHomePage/SmartPhoneHomePage';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ChangePassword from './pages/ChangePassword/ChangePassword';
import SecurityQuestion from './pages/SecurityQuestion/SecurityQuestion';
import CallTypeSetup from './pages/CallTypeSetup/CallTypeSetup';
import LSLUserDashboard from './pages/LSLUserDashboard/LSLUserDashboard';
import ClientDashboard from './pages/ClientDashboard/ClientDashboard';
import SuperAdminDashboard from './pages/SuperAdminDashboard/SuperAdminDashboard';
import CreateCampaign from './pages/CreateCampaign/CreateCampaign';
import CreateAndEditFixedBudgetRegularCampaign from './pages/CreateAndEditFixedBudgetRegularCampaign/CreateAndEditFixedBudgetRegularCampaign';
import CashbackRegularCampaignTable from './pages/CashbackRegularCampaignTable/CashbackRegularCampaignTable';
import BonusRegularCampaignTable from './pages/BonusRegularCampaignTable/BonusRegularCampaignTable';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/call-center/member-profile" element={<Profile />} />
            <Route path="/lsl-user-dashboard" element={<LSLUserDashboard />} />
            <Route path="/client-dashboard" element={<ClientDashboard />} />
            <Route
              path="/super-admin-dashboard"
              element={<SuperAdminDashboard />}
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/security-questions" element={<SecurityQuestion />} />
            <Route
              path="/call-center/handle-member-query"
              element={<HandleMemberQuery />}
            />
            <Route path="/cart" element={<Cart />} />
            <Route path="/call-center/log-query" element={<LogQuery />} />
            <Route
              path="/call-center/add-family-card"
              element={<FamilyCard />}
            />
            <Route path="/call-center/log-details" element={<LogDetails />} />
            <Route
              path="/call-center/member-query-record"
              element={<MemberQueryRecord />}
            />
            <Route
              path="/system-defined-master/user-type-master-setup"
              element={<UserType />}
            />
            <Route
              path="/system-defined-master/payment-type-master"
              element={<PaymentType />}
            />
            <Route
              path="/system-defined-master/code-decode-type-master"
              element={<CodeDecode />}
            />
            <Route
              path="/system-defined-master/code-decode-master"
              element={<CodeDecodeMaster />}
            />
            <Route
              path="/tier-master-setup/add-tier-criteria"
              element={<AddCriteria />}
            />
            <Route
              path="/tier-master-setup/add-tier-level"
              element={<TierLevelMaster />}
            />
            <Route
              path="/menu-and-privilege-management/create-master-menu"
              element={<CreateMasterMenu />}
            />
            <Route
              path="/menu-and-privilege-management/view-and-update-master-map"
              element={<ViewAndUpdateMenuMap />}
            />
            <Route
              path="/menu-and-privilege-management/priviledge-master"
              element={<PrivilegeMaster />}
            />
            <Route
              path="/menu-and-privilege-management/company-solution-type-logo"
              element={<CompanySolutionTypeLogo />}
            />
            <Route
              path="/system-defined-master/application-transaction-type-master-setup"
              element={<ApplicationTransaction />}
            />
            <Route
              path="/system-defined-master/notification-template-type-master-setup"
              element={<NotificationTemplate />}
            />
            <Route
              path="/call-center/transaction-details"
              element={<TransactionDetails />}
            />
            {/* <Route path="/call-center" element={<CallCenter />} /> */}
            <Route
              path="/call-center/redeem-catalogue"
              element={<RedeemCatalogue />}
            />
            {/*//////////////////////// Unmounted Routes/////////////////////////////////// */}
            <Route
              path="/reports-and-transactions/payment-to-lsl-catalogue-partner"
              element={<PaymentToLSL />}
            />
            <Route
              path="/reports-and-transactions/lsl-points-liability-report"
              element={<LSLPoints />}
            />
            <Route
              path="/reports-and-transactions/lsl-invoice-settlement-report"
              element={<LSLInvoice />}
            />
            <Route
              path="/reports-and-transactions/lsl-partner-redemption-report"
              element={<LSLPartner />}
            />
            <Route
              path="/lsl-master-setup/lsl-partner-master"
              element={<LSLPartnerMaster />}
            />
            <Route
              path="/lsl-master-setup/create-lsl-partner-branch"
              element={<LSLPartnerBranchMaster />}
            />
            <Route
              path="/lsl-master-setup/create-partner-category"
              element={<CreatePartnerCategory />}
            />
            <Route
              path="/lsl-master-setup/create-catalogue-merchandize-category"
              element={<CreateCatalogueMerchandizeCategory />}
            />
            <Route
              path="/lsl-master-setup/create-catalogue-merchandize-items"
              element={<CreateCatalogueMerchandizeItems />}
            />
            <Route
              path="/lsl-master-setup/country-currency-master-setup"
              element={<CountryCurrencyMasterSetup />}
            />
            <Route
              path="/lsl-master-setup/region-master-setup"
              element={<RegionMaster />}
            />
            <Route
              path="/lsl-master-setup/zone-master-setup"
              element={<ZoneMasterSetup />}
            />
            <Route
              path="/lsl-master-setup/state-master-setup"
              element={<StateMasterSetup />}
            />
            <Route
              path="/lsl-master-setup/city-master-setup"
              element={<CityMasterSetup />}
            />
            {/* DeliveryPriceMasterReference */}
            <Route
              path="/lsl-master-setup/weight-master-setup"
              element={<WeightMasterSetup />}
            />
            <Route
              path="/lsl-master-setup/delivery-price-master-reference"
              element={<DeliveryPriceMasterReference />}
            />
            <Route
              path="/lsl-master-setup/template-master-setup-for-sms"
              element={<TemplateMasterSetupForSMS />}
            />
            <Route
              path="/lsl-master-setup/template-master-for-email"
              element={<TemplateMasterSetupForEmail />}
            />
            <Route
              path="/lsl-master-setup/renewal-of-license"
              element={<RenewalOfLicense />}
            />
            <Route
              path="/lsl-master-setup/regeneration-of-membership-id"
              element={<RegenerationOfMembershipID />}
            />
            <Route
              path="/lsl-master-setup/reseller-company-registration"
              element={<ResellerCompanyRegistration />}
            />
            <Route
              path="/lsl-master-setup/summary-of-license-membership-regeneration"
              element={<SummaryOfLicenseMembershipRegeneration />}
            />
            <Route
              path="/lsl-master-setup/register-standalone-company"
              element={<RegisterStandaloneCompany />}
            />
            <Route
              path="/lsl-master-setup/generate-license-key-for-standalone-company"
              element={<GenerateLicenseKeyForStandaloneCompany />}
            />
            <Route
              path="/lsl-master-setup/game-master-setup"
              element={<GameMasterSetup />}
            />
            <Route
              path="/lsl-master-setup/email-master-setup"
              element={<EmailMasterSetup />}
            />
            {/*//////////////////////// Customer Enrolment /////////////////////////*/}
            <Route
              path="/customer-enrolment/customer-enrolment-at-branch"
              element={<CustomerEnrolmentAtBranch />}
            />
            <Route
              path="/customer-enrolment/customer-enrolment-from-website"
              element={<CustomerEnrolmentFromWebsite />}
            />
            <Route
              path="/customer-enrolment/assign-membershipid-to-customer"
              element={<AssignMembershipID />}
            />
            <Route
              path="/customer-enrolment/add-family-members-at-branch"
              element={<AddFamilyMembersAtBranch />}
            />
            <Route
              path="/customer-enrolment/add-family-member-at-branch-form"
              element={<AddFamilyMemberAtBranchForm />}
            />
            <Route
              path="/customer-enrolment/assign-membershipID-to-family-member"
              element={<AssignMembershipIDToFamilyMembers />}
            />
            <Route
              path="/customer-enrolment/assign-family-member-membershipID-list"
              element={<AssignFamilyMembermembershipIDList />}
            />
            <Route
              path="/customer-enrolment/assign-membershipID-list"
              element={<AssignMembeshipIdList />}
            />
            <Route
              path="/customer-enrolment/customer-enrolment-file-upload"
              element={<CustomerEnrolmentFileUpload />}
            />
            {/*/////////////////////////// Company Campaigns/////////////////////////////// */}
            {/* value-spend-regular-campaign */}
            <Route
              path="/company-campaigns/value-spend-regular-campaign-table"
              element={<CreateValueSpendRegularTable />}
            />
            <Route
              path="/company-campaigns/create-value-spend-regular-campaign"
              element={<CreateCampaign />}
            />
            <Route
              path="/company-campaigns/edit-value-spend-regular-campaign/*"
              element={<CreateCampaign />}
            />
            {/* value-spend-regular-campaign */}
            <Route
              path="/company-campaigns/fixed-budget-regular-campaign-table"
              element={<CreateFixedBudgetRegularTable />}
            />
            <Route
              path="/company-campaigns/create-fixed-budget-regular-campaign"
              element={<CreateAndEditFixedBudgetRegularCampaign />}
            />
            <Route
              path="/company-campaigns/edit-fixed-budget-regular-campaign/*"
              element={<CreateAndEditFixedBudgetRegularCampaign />}
            />

            <Route
              path="/company-campaigns/cashback-regular-campaign"
              element={<CashbackRegularCampaign />}
            />
            <Route
              path="/company-campaigns/cashback-regular-campaign-table"
              element={<CashbackRegularCampaignTable />}
            />
            <Route
              path="/company-campaigns/bonus-regular-campaign"
              element={<BonusRegularCampaign />}
            />
            <Route
              path="/company-campaigns/bonus-regular-campaign-table"
              element={<BonusRegularCampaignTable />}
            />
            <Route
              path="/company-campaigns/discount-benefit-campaign"
              element={<DiscountBenefitCampaign />}
            />
            <Route
              path="/company-campaigns/upgrade-privilege-campaign"
              element={<UpgradePrivilegeCampaign />}
            />
            <Route
              path="/company-campaigns/create-referal-campaign"
              element={<ReferalCampaign />}
            />
            <Route
              path="/company-campaigns/create-promo-campaign"
              element={<PromoCampaign />}
            />
            <Route
              path="/company-campaigns/create-sweepstake"
              element={<CreateSweepstake />}
            />
            <Route
              path="/company-campaigns/auction-master"
              element={<AuctionMaster />}
            />
            <Route
              path="/company-campaigns/auction-sweepstake-winner"
              element={<AuctionSweepstakeWinner />}
            />
            <Route
              path="/company-campaigns/approve-auction"
              element={<ApproveAuction />}
            />
            <Route
              path="/company-campaigns/cumulative-periodic-campaign"
              element={<CumulativePeriodic />}
            />
            <Route
              path="/company-campaigns/frequency-count-periodic-campaign"
              element={<FrequencyCountPeriodicCampaign />}
            />
            <Route
              path="/company-campaigns/special-date-campaign"
              element={<SpecialDateCampaign />}
            />
            <Route
              path="/company-campaigns/cashback-periodic-campaign"
              element={<CashbackPeriodicCampaign />}
            />
            <Route
              path="/company-campaigns/bonus-periodic-campaign"
              element={<BonusPeriodicCampaign />}
            />
            <Route
              path="/company-campaigns/fixed-budget-periodic-campaign"
              element={<FixedBudgetPeriodicCampaign />}
            />
            <Route
              path="/company-campaigns/create-game-campaign"
              element={<CreateGameCampaign />}
            />
            <Route
              path="/company-campaigns/approve-game-winner"
              element={<ApproveGameWinner />}
            />

            {/*Perform Company Transaction */}
            <Route
              path="/perform-company-transaction/inward-stock-entry"
              element={<InwardStockEntry />}
            />
            <Route
              path="/perform-company-transaction/manual-debit-transaction"
              element={<ManualDebitTransaction />}
            />
            <Route
              path="/perform-company-transaction/manual-credit-transaction"
              element={<ManualCreditTransaction />}
            />
            <Route
              path="/perform-company-transaction/wallet-credit-transaction"
              element={<WalletCreditTransaction />}
            />
            <Route
              path="/perform-company-transaction/wallet-debit-transaction"
              element={<WalletDebitTransaction />}
            />
            <Route
              path="/perform-company-transaction/points-award-transaction"
              element={<PointsAwardTransaction />}
            />
            <Route
              path="/perform-company-transaction/payment-to-cataloque-partner"
              element={<PaymentToCataloguePartner />}
            />
            {/*/////////////// Company Survey Management /////////////*/}
            <Route
              path="/company-survey-management/survey-structure-master"
              element={<SurveyStructureMaster />}
            />
            <Route
              path="/company-survey-management/create-and-edit-survey-questions"
              element={<SurveyQuestionnaireMaster />}
            />
            <Route
              path="/company-survey-management/send-survey-to-customer"
              element={<SendSurvey />}
            />
            {/*////////////// Company Master Setup //////////////*/}
            <Route
              path="/company-master-setup/register-company"
              element={<RegisterCompany />}
            />
            <Route
              path="/company-master-setup/loyalty-program-master"
              element={<LoyaltyProgramMaster />}
            />
            <Route
              path="/company-master-setup/company-branch-master"
              element={<CompanyBranchMaster />}
            />
            <Route
              path="/company-master-setup/company-transaction-type-master-setup"
              element={<CompanyTransactionTypeMasterSetup />}
            />
            <Route
              path="/company-master-setup/company-transaction-channel-master"
              element={<CompanyTransactionChannelMaster />}
            />
            <Route
              path="/company-master-setup/user-group-master"
              element={<UserGroupMaster />}
            />
            <Route
              path="/company-master-setup/create-company-product-groups"
              element={<ProductGroupMaster />}
            />
            <Route
              path="/company-master-setup/create-company-product-brands"
              element={<ProductBrandMaster />}
            />
            <Route
              path="/company-master-setup/enroll-company-users"
              element={<CompanyUserEnrollment />}
            />
            <Route
              path="/company-master-setup/view-or-edit-company-user"
              element={<ViewOrEditCompanyUser />}
            />
            <Route
              path="/company-master-setup/company-dashboard-graph-setup"
              element={<CompanyDashboardGraphSetup />}
            />
            <Route
              path="/company-master-setup/company-catalogue-partner-master"
              element={<CompanyCataloguePartnerMaster />}
            />
            <Route
              path="/company-master-setup/company-partner-branch-master"
              element={<CompanyPartnerBranchMaster />}
            />
            <Route
              path="/company-master-setup/partner-product-and-service-category"
              element={<PartnerProductAndServiceCategory />}
            />
            <Route
              path="/company-master-setup/partner-user-enrollment"
              element={<PartnerUserEnrollment />}
            />
            <Route
              path="/company-master-setup/view-or-edit-partner-user"
              element={<ViewOrEditPartnerUser />}
            />
            <Route
              path="/company-master-setup/points-forfieture-master"
              element={<PointsForfeitureMaster />}
            />
            <Route
              path="/company-master-setup/create-company-member-tiers"
              element={<CreateCompanyMemberTiers />}
            />
            <Route
              path="/company-master-setup/segment-type-master"
              element={<SegmentTypeMaster />}
            />
            <Route
              path="/company-master-setup/company-merchandize-catalogue"
              element={<CompanyMerchandizeCatalogue />}
            />
            <Route
              path="/company-master-setup/select-items-from-lsl-merchandize-catalogue"
              element={<SelectItemsFromLSLMerchandizeCatalogue />}
            />
            <Route
              path="/company-master-setup/approve-company-merchandize-catalogue"
              element={<ApproveCompanyMerchandizeCatalogue />}
            />
            <Route
              path="/company-master-setup/company-merchandize-catalogue-category"
              element={<CompanyMerchandizeCatalogueCategory />}
            />
            <Route
              path="/company-master-setup/assign-menus-to-company-users"
              element={<AssignMenusToCompanyUsers />}
            />
            <Route
              path="/company-master-setup/assign-privileges-to-company-users"
              element={<AssignPrivilegesToCompanyUsers />}
            />
            <Route
              path="/company-master-setup/assign-additional-menu-for-company"
              element={<AssignAdditionalMenuForCompany />}
            />
            <Route
              path="/company-master-setup/pos-inventory-master"
              element={<PoSInventoryMaster />}
            />
            <Route
              path="/company-master-setup/pos-item-opening-balance-master"
              element={<PoSItemOpeningBalanceMaster />}
            />
            <Route
              path="/company-master-setup/data-mapping-for-enrollment"
              element={<DataMappingForEnrollment />}
            />
            <Route
              path="/company-master-setup/data-mapping-for-transaction"
              element={<DataMappingForTransaction />}
            />
            <Route
              path="/company-master-setup/data-mapping-for-callcenter-transaction-upload"
              element={<DataMappingForCallCenterTransactionUpload />}
            />
            <Route
              path="/company-master-setup/report-automation-master"
              element={<ReportAutomationMaster />}
            />
            <Route
              path="/company-master-setup/application-management-website"
              element={<ApplicationManagementWebsite />}
            />
            <Route
              path="/company-master-setup/company-email-template-master"
              element={<CompanyEmailTemplateMaster />}
            />
            <Route
              path="/company-master-setup/company-sms-template-master"
              element={<CompanySMSTemplateMaster />}
            />
            <Route
              path="/company-master-setup/pos-receipt-template"
              element={<PoSReceiptTemplate />}
            />
            <Route
              path="/company-master-setup/voucher-redemption-receipt-template"
              element={<VoucherRedemptionReceiptTemplate />}
            />
            <Route
              path="/company-master-setup/discount-or-upgrade-privilege-receipt-template"
              element={<DiscountOrUpgradePrivilegeReceiptTemplate />}
            />
            <Route
              path="/company-master-setup/receipt-footer-message-master"
              element={<ReceiptFooterMessageMaster />}
            />
            <Route
              path="/company-master-setup/customer-website-login"
              element={<CustomerWebsiteLogin />}
            />
            <Route
              path="/company-master-setup/setup-customer-website-homepage-layout"
              element={<SetupCustomerWebsiteHomePageLayout />}
            />
            <Route
              path="/company-master-setup/setup-customer-smartphone-page-layout"
              element={<SetupCustomerSmartphonePageLayout />}
            />
            <Route
              path="/company-master-setup/call-center-query-type"
              element={<CallCenterQueryType />}
            />
            <Route
              path="/company-master-setup/call-center-sub-query-setup"
              element={<CallCenterQuerySetup />}
            />
            <Route
              path="/company-master-setup/call-center-resolution-priority-level"
              element={<CallCenterResolutionPriorityLevel />}
            />
            <Route
              path="/company-master-setup/call-center-escalation-matrix"
              element={<CallCenterEscalationMatrix />}
            />
            <Route
              path="/company-master-setup/multiple-choice-structure-master"
              element={<MultipleChoiceStructureMaster />}
            />
            <Route
              path="/company-master-setup/call-master-setup"
              element={<CallTypeSetup />}
            />
            {/*////////////// Company Report Management //////////////*/}
            <Route
              path="/company-report-management/branch-transaction-report"
              element={<BranchTransactionReport />}
            />
            <Route
              path="/company-report-management/user-redemption-report"
              element={<UserRedemptionReport />}
            />
            <Route
              path="/company-report-management/cash-back-send-process"
              element={<CashBackSendProcess />}
            />
            <Route
              path="/company-report-management/shipping-cost-report"
              element={<ShippingCostReport />}
            />
            <Route
              path="/company-report-management/system-user-enrolment-report"
              element={<SystemUserEnrolmentReport />}
            />
            <Route
              path="/company-report-management/campaign-report"
              element={<CampaignReport />}
            />
            <Route
              path="/company-report-management/e-voucher-redemption-report"
              element={<EVoucherRedemptionReport />}
            />
            <Route
              path="/company-report-management/file-upload-status-report"
              element={<FileUploadStatusReport />}
            />
            <Route
              path="/company-report-management/login-status-report"
              element={<LoginStatusReport />}
            />
            <Route
              path="/company-report-management/company-points-liability-report"
              element={<CompanyPointsLiabilityReport />}
            />
            <Route
              path="/company-report-management/benefit-partner-report"
              element={<BenefitPartnerReport />}
            />
            <Route
              path="/company-report-management/hotlisted-member-report"
              element={<HotListedMemberReport />}
            />
            <Route
              path="/company-report-management/audit-tracking-report"
              element={<AuditTrackingReport />}
            />
            <Route
              path="/company-report-management/survey-report"
              element={<SurveyReport />}
            />
            <Route
              path="/company-report-management/member-delivery-order-status-report"
              element={<MemberDeliveryOrderStatusReport />}
            />
            <Route
              path="/company-report-management/company-gift-card-report"
              element={<CompanyGiftCardReport />}
            />
            <Route
              path="/company-report-management/sms-detail-report"
              element={<SmsDetailReport />}
            />
            <Route
              path="/company-report-management/email-sms-detail-report"
              element={<EmailSmsDetailReport />}
            />
            <Route
              path="/company-report-management/company-invoice-settlement-report"
              element={<CompanyInvoiceSettlementReport />}
            />
            <Route
              path="/company-report-management/member-transaction-report"
              element={<MemberTransactionReport />}
            />
            <Route
              path="/company-report-management/member-high-value-transaction-report"
              element={<MemberHighValueTransactionReport />}
            />
            <Route
              path="/company-report-management/points-expiry-report"
              element={<PointsExpiryReport />}
            />
            <Route
              path="/company-report-management/points-forfeiture-report"
              element={<PointsForfeitureReport />}
            />
            <Route
              path="/company-report-management/member-special-date-report"
              element={<MemberSpecialDateReport />}
            />
            <Route
              path="/company-report-management/member-missing-email-or-phone-report"
              element={<MemberMissingEmailOrPhoneReport />}
            />
            <Route
              path="/company-report-management/member-enrollment-report"
              element={<MemberEnrollmentReport />}
            />
            <Route
              path="/company-report-management/member-wallet-transaction-report"
              element={<MemberWalletTransactionReport />}
            />
            <Route
              path="/company-report-management/branch-stock-inventory-report"
              element={<BranchStockInventoryReport />}
            />
            <Route
              path="/company-report-management/branch-stock-movement-report"
              element={<BranchStockMovementReport />}
            />
            <Route
              path="/company-report-management/call-centre-query-status-report"
              element={<CallCentreQueryStatusReport />}
            />
            <Route
              path="/company-report-management/call-centre-member-interaction-report"
              element={<CallCentreMemberInteractionReport />}
            />
            {/*////////////// Company Game Setup //////////////*/}
            <Route
              path="/company-game-setup/company-game-master"
              element={<CompanyGameMaster />}
            />
            <Route
              path="/company-game-setup/company-game-configuration"
              element={<CompanyGameConfiguration />}
            />
            {/*//////////////////////////// Templates /////////////////////////////*/}
            <Route path="/login-template-1" element={<LoginTemplate1 />} />
            <Route path="/login-template-2" element={<LoginTemplate2 />} />
            <Route
              path="/single-category-template"
              element={<SingleCategory />}
            />
            <Route path="/cart-template" element={<TemplateCart />} />
            <Route path="/order-receipt-template" element={<OrderReceipt />} />
            <Route
              path="/account-dashboard-template"
              element={<AccountDashboard />}
            />
            <Route path="/movies-template" element={<Movies />} />
            <Route
              path="/airtime-and-bills-template"
              element={<AirtimeAndBills />}
            />
            <Route
              path="/airtime-checkout-template"
              element={<AirtimeCheckout />}
            />
            <Route path="/vouchers-template" element={<Vouchers />} />
            <Route path="/events-template" element={<Events />} />
            <Route
              path="/smartphone-homepage-template"
              element={<SmartPhoneHomePage />}
            />
          </Route>
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
