import React, {useState, useEffect} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import {Times, ViewPen} from '../../assets/icons';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import styles from '../../styles/CompanyTransactionTypeMasterSetup.module.scss';
import { useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import { Headers } from '../../service/Essentials';
import swal from 'sweetalert';
import { ProgramID } from '../../service/Essentials';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';

const CompanyTransactionTypeMasterSetup = () => {
  const [clearModal, setClearModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [transactionCode, setTransactionCode] = useState('');
  const [transactionName, setTransactionName] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState('');
  const [selectedItemID, setSelectedItemId] = useState('');
  const queryClient = useQueryClient();
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState(null);

  const onClose = () => {
    setDeleteModal(false);
  };
  
  const formData = {
      'transaction_code':transactionCode,
      'transaction_name':transactionName,
      'program_id':ProgramID(),
  }

  //RedirectToLogin();

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const fetchTransactionTypes = async (page) =>{
    const res = await axiosProperty.get(ApiConfig.fetchTransactionType+`?page=${page}`, {
        headers:Headers()
    }
    );
    return res.data;
  }  
  const {data, status} = useQuery(['transactiontypes', page], () => fetchTransactionTypes(page), {keepPreviousData:true,});

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data) {
      const filtered = data.data.filter(
        (item) =>
          item.transaction_name.toLowerCase().includes(searchTerm.toLowerCase())||
          item.transaction_code.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  const addTransactionType = async () => {
    try{
        await axiosProperty.post(ApiConfig.createTransactionType, formData, 
        {
          headers:Headers(),
      }
        );
  setErrors(null);
  setTransactionCode('');
  setTransactionName('');
  swal({
    title: "Success!",
    text: "Transaction type created successfully",
    icon: "success",
  });

    }catch(err){
      if(err.response){
       setError(err.response.data.message);
       setErrors(err.response.data);
      }else{
      setError('Something went wrong, try again'); 
      }
      console.log(err);
      swal({
        title: "Error!",
        text: error || 'server issue',
        icon:'error'
      });
    
    }
  };

const {mutate:addTransactionTypeMutation} = useMutation(
  {
    mutationFn: addTransactionType,
    onSuccess:()=>{queryClient.invalidateQueries(['transactiontypes'])}, 
  }
  );

  const updateTransactionType = async () => {
    try{
        await axiosProperty.patch(ApiConfig.updateTransactionType+`/${updateID}`, formData,
        {
          headers:Headers(),
      }
        );
        setErrors(null);
        setTransactionCode('');
        setTransactionName('');
        setIsSubmit(true);
        setIsUpdate(false);

        swal({
          title: "Success!",
          text: "Transaction type updated successfully",
          icon: "success",
        });
      
    }catch(err){
      if(err.response){
        setError(err.response.data.message);
        setErrors(err.response.data);
      }else{
       setError('Something went wrong, try again'); 
      }
      console.log(err);

      swal({
        title: "Error!",
        text: error,
        icon: "error",
      });

    }
  };

  const {mutate:updateTransactionTypeMutation} = useMutation(
    {
      mutationFn: updateTransactionType,
      onSuccess:()=>{queryClient.invalidateQueries(['transactiontypes'])}, 
    }
    );

  const editTrandsactionType = async (id) => {
    try{
       const res = await axiosProperty.get(ApiConfig.editTransactionType+`/${id}`,
        {
          headers:Headers(),
      }
      );
  setIsUpdate(true); 
  setIsSubmit(false); 
  setTransactionCode(res.data.data.transaction_code);
  setTransactionName(res.data.data.transaction_name);
    }catch(err){
      if(err.response){
        setError(err.response.data.message);
      }else{
       setError('Something went wrong, try again'); 
      }
    //  console.log(err);
      swal({
        title: "Warning!",
        text: error,
        icon: "warning",
      });
    }
  };

  const deleteTransactionType = async (id) => {
    try{
     const res = await axiosProperty.delete(ApiConfig.deleteTransactionType+`/${id}`,
      {
        headers:Headers(),
    }
    );
    setDeleteModal(false); 
    swal({
      title: "Success!",
      text: 'Transaction type deleted successfully',
      icon: "success",
    });
    queryClient.invalidateQueries(['transactiontypes']);
}catch(err){
  if(err.response){
    setError(err.response.data.message);
  }else{
   setError('Something went wrong, try again'); 
  }
 // console.log(err);

  swal({
    title: "Error!",
    text: error,
    icon: "error",
  });

}
  }
  return (
    <CallCenterStructure>
      <div className={styles.companyTransactionTypeMasterSetup}>
        <div className={styles.headerText}>
          <p>Company Transaction Type Master Setup</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Enter Transaction Type Code<sup>*</sup>
              </label>
              <input value={transactionCode} type="text" onChange={(e)=>setTransactionCode(e.target.value)} />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Enter Transaction Type Name<sup>*</sup>
              </label>
              <input value={transactionName} type="text" onChange={(e)=>setTransactionName(e.target.value)} />
            </div>
          </div>

          <div className={styles.buttonContainer}>
            {isSubmit && (<button className={styles.submit} onClick={()=>addTransactionTypeMutation()}>Submit</button>)}
            {isUpdate && (<button className={styles.submit} onClick={()=>updateTransactionTypeMutation()}>Update</button>)}
            <button className={styles.reset}>Reset</button>
          </div>
          <ErrorMessage errMsg={errors} />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Transaction Type Code</th>
                  <th>Transaction Type Name</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map(each => (
                  <tr key={each.transaction_code}>
                    <td>{each.transaction_code}</td>
                    <td>{each.transaction_name}</td>

                    <td>
                      <div className={styles.iconContainer}>
                        <span className={styles.icon} onClick={()=>{editTrandsactionType(each.id); setUpdateID(each.id)}}>
                          <ViewPen />
                        </span>
                        <span
                          className={styles.icon}
                          onClick={() => {setDeleteModal(true); setSelectedItemId(each.id);}}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={data} setPage={setPage} />
          </div>
        </div>
        {deleteModal && <MenuDeleteModal onClose={onClose} onDelete={()=>deleteTransactionType(selectedItemID)} />}
      </div>
    </CallCenterStructure>
  );
};

export default CompanyTransactionTypeMasterSetup;
