import React, {useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import CartStructure from '../CartStructure/CartStructure';
import styles from '../../styles/Profile.module.scss';
import styles1 from '../../styles/LogQuery.module.scss';
import Dropdown from '../../component/DropDown/DropDown';
import RadioButton from '../../component/SingleRadioButton/SingleRadioButton';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
//import { getUser } from '../../service/common';
import swal from 'sweetalert';
import { CallCenterHeaders, ProgramID } from '../../service/Essentials';
import { useMutation} from 'react-query';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const LogQuery = () => {
  const [clearModal, setClearModal] = useState(false);
  // const user = getUser();

  //const program_id = user.user_group_id ?? '';

  const dataString = sessionStorage.getItem('profile');
  if (dataString) {
    var data = JSON.parse(dataString);
  } else {
    data = {};
  }

  const membership_id = data?.Membership_id;
  const enrolment_id = data?.Enrollment_id;
  const [queryType, setQueryType] = useState();
  const [queryTypeID, setQueryTypeID] = useState('');
  const [subQueryType, setSubQueryType] = useState();
  const [subQueryTypeID, setSubQueryTypeID] = useState('');
  const [callType, setCallType] = useState();
  const [callTypeID, setCallTypeID] = useState('');
  const [commType, setCommType] = useState();
  const [commTypeID, setCommTypeID] = useState('');
  const [queryPriority, setQueryPriority] = useState();
  const [queryPriorityID, setQueryPriorityID] = useState('');
  const [inputChecked, setInputChecked] = useState(false);
  const options = ['Yes', 'No'];
  const [selectedRadioButton, setSelectedRadioButton] = useState(null);
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState({Company_id:'', Enrollment_id:'', Query_type_id:'', Query_details:'', Closure_date:'', Query_assign:'', Membership_id:'', Call_type:'', Communication_type:'', Query_status:'', Query_id:'', Resolution_id:'', Next_action_date:''});
  const [formData, setFormData] = useState({Company_id:ProgramID(), Enrollment_id:enrolment_id, Query_type_id:queryTypeID, Query_details:'', Closure_date:'', Query_assign:'', Membership_id:membership_id, Call_type:callType, Communication_type:commType, Query_status:'Open', Query_id:subQueryTypeID, Resolution_id:queryPriorityID, Next_action_date:''});

  //console.log(formData);

  const handleQueryType = name => {
    setQueryType(name);
  };

  const handleQueryTypeID = id => {
    setQueryTypeID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Query_type_id: id,
    }));
  };

  const handleCallType = name => {
    setCallType(name);
    setFormData(prevFormData => ({
      ...prevFormData,
      Call_type: name,
    }));
  };

  const handleCallTypeID = id => {
    setCallTypeID(id);
  };

  const handleCommType = name => {
    setCommType(name);
    setFormData(prevFormData => ({
      ...prevFormData,
      Communication_type: name,
    }));
  };

  const handleCommTypeID = id => {
    setCommTypeID(id);
  };

  const handleSubQueryType = name => {
    setSubQueryType(name);
  };

  const handleSubQueryTypeID = id => {
    setSubQueryTypeID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Query_id: 7,
    }));
  };

  const handleQueryPriority = name => {
    setQueryPriority(name);
  };

  const handleQueryPriorityID = id => {
    setQueryPriorityID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Resolution_id: id,
    }));
  };

  const handleChange = e => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const handleRadio = option => {
    setSelectedRadioButton(option);
  };

  const addLogQuery = async () => {
    try {
      await axiosProperty.post(ApiConfig.createLogQuery, formData, {
        headers: CallCenterHeaders(),
      });
      setFormData({
        Company_id: 2,
        Enrollment_id: 4,
        Query_type_id: queryTypeID,
        Query_details: '',
        Closure_date: '',
        Query_assign: '',
        Membership_id: membership_id,
        Call_type: callType,
        Communication_type: commType,
        Query_status: 'Open',
        Query_id: subQueryTypeID,
        Resolution_id: queryPriorityID,
        Next_action_date: '',
      });
      setQueryPriority('');
      setQueryPriorityID('');
      setQueryType('');
      setQueryTypeID('');
      setSubQueryType('');
      setSubQueryTypeID('');
      setCallType('');
      setCallTypeID('');
      setCommType('');
      setCommTypeID();
      setErrors({});
      swal({
        title: 'Success!',
        text: 'Log Query Added successfully',
        icon: 'success',
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError(err.response.data.message);
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: addLogQueryMutation} = useMutation({
    mutationFn: addLogQuery,
  });

  return (
    <CartStructure>
      <div className={styles.mainContainer}>
        <div className={styles.profileNavOuterContainer}>
          <div className={styles.profileNav}>
            <div>
              <Link
                className={styles1.unActiveLink}
                to="/call-center/member-profile">
                Profile
              </Link>
            </div>
            <div>
              <Link
                className={styles1.unActiveLink}
                to="/call-center/redeem-catalogue">
                Redeem Catalogue
              </Link>
            </div>
            <div>
              <Link
                className={styles1.unActiveLink}
                to="/call-center/transaction-details">
                Transaction details
              </Link>
            </div>
            <div>
              <Link
                className={styles1.unActiveLink}
                to="/call-center/member-query-record">
                Member Query Record
              </Link>
            </div>
            <div className={styles.currentNav}>
              <Link
                className={`${styles.currentNav} ${styles.linkstyles}`}
                to="/call-center/log-query">
                Log Query
              </Link>
            </div>
            <div>
              <Link
                className={styles1.unActiveLink}
                to="/call-center/add-family-card">
                Add Family Card
              </Link>
            </div>
          </div>
        </div>
        <div className={styles1.logQueryContainer}>
          <p>Log Query</p>
          <div className={styles1.logQueryInnerContainer}>
            <div>
              <div className={styles1.queryInputContainer}>
                <div className={styles1.queryInputBox}>
                  <label htmlFor="">
                    Customer Name <sup>*</sup>
                  </label>
                  <input
                    name="Query_assign"
                    value={formData.Query_assign}
                    onChange={handleChange}
                    type="text"
                    placeholder="Customer Name"
                  />
                  {errors?.Query_assign && (
                    <p className="text-danger">{errors?.Query_assign}</p>
                  )}
                </div>
                <div className={styles1.queryInputBox}>
                  <label htmlFor="">
                    Membership ID <sup>*</sup>
                  </label>
                  <input
                    name="Membership_id"
                    onChange={handleChange}
                    value={membership_id}
                    type="text"
                    placeholder="Membership ID"
                  />
                  {errors?.Membership_id && (
                    <p className="text-danger">{errors?.Membership_id}</p>
                  )}
                </div>
              </div>
              <div className={styles1.queryInputContainer}>
                <div className={styles1.queryInputBox}>
                  <label htmlFor="">
                    Type of Call <sup>*</sup>
                  </label>
                  <Dropdown
                    onSelect={handleCallTypeID}
                    onSelectOption={handleCallType}
                    retrievedValue={callType}
                    enumType={[
                      {id: 1, name: 'Inbound'},
                      {id: 2, name: 'Outbound'},
                    ]}
                    column={'name'}
                    key="calltypes"
                  />
                  {errors?.Call_type && (
                    <p className="text-danger">{errors?.Call_type}</p>
                  )}
                </div>
                <div className={styles1.queryInputBox}>
                  <label htmlFor="">
                    Communication Type<sup>*</sup>
                  </label>
                  <Dropdown
                    onSelect={handleCommTypeID}
                    onSelectOption={handleCommType}
                    retrievedValue={commType}
                    enumType={[
                      {id: 1, name: 'Email'},
                      {id: 2, name: 'Voice'},
                    ]}
                    column={'name'}
                    key="commtypes"
                  />
                  {errors?.Communication_type && (
                    <p className="text-danger">{errors?.Communication_type}</p>
                  )}
                </div>
              </div>
              <div className={styles1.queryInputContainer}>
                <div className={styles1.queryInputBox}>
                  <label htmlFor="">
                    Query Type<sup>*</sup>
                  </label>
                  <Dropdown
                    header={CallCenterHeaders()}
                    api={ApiConfig.fetchQueryTypes(7744889018)}
                    onSelect={handleQueryTypeID}
                    onSelectOption={handleQueryType}
                    retrievedValue={queryTypeID}
                    idValue={'Query_type_id'}
                    column={'Query_type_name'}
                    key="querytypes"
                  />
                  {errors?.Query_type_id && (
                    <p className="text-danger">{errors?.Query_type_id}</p>
                  )}
                </div>
                <div className={styles1.queryInputBox}>
                  <label htmlFor="">
                    Sub Query Type<sup>*</sup>
                  </label>
                  <Dropdown
                    header={CallCenterHeaders()}
                    api={ApiConfig.fetchSubQueryTypes(7744889018)}
                    onSelect={handleSubQueryTypeID}
                    onSelectOption={handleSubQueryType}
                    retrievedValue={subQueryTypeID}
                    idValue={'Query_type_id'}
                    column={'Query_type_name'}
                    key="subquerytypes"
                  />
                  {errors?.Query_id && (
                    <p className="text-danger">{errors?.Query_id}</p>
                  )}
                </div>
              </div>
              <div className={styles1.queryTextareaContainer}>
                <label htmlFor="">
                  Query Details<sup>*</sup>
                </label>
                <textarea
                  name="Query_details"
                  value={formData.Query_details}
                  id=""
                  onChange={handleChange}
                />
                {errors?.Query_details && (
                  <p className="text-danger">{errors?.Query_details}</p>
                )}
              </div>
            </div>
            <div className={styles1.priorityInputOuterContainer}>
              <div className={styles1.priorityInputContainer}>
                <label htmlFor="">
                  Query Priority<sup>*</sup>
                </label>
                <Dropdown
                  header={CallCenterHeaders()}
                  api={ApiConfig.fetchResolutionLevels(7744889018)}
                  onSelect={handleQueryPriorityID}
                  onSelectOption={handleQueryPriority}
                  retrievedValue={queryPriorityID}
                  column={'Level_name'}
                  idValue={'Resolution_id'}
                  key="querypriority"
                />
                {errors?.Resolution_id && (
                  <p className="text-danger">{errors?.Resolution_id}</p>
                )}
              </div>
              <div className={styles1.priorityInputContainer}>
                <label htmlFor="">
                  Expected Closure Date And Time<sup>*</sup>
                </label>
                <input
                  type="date"
                  value={formData.Closure_date}
                  name="Closure_date"
                  placeholder="Date And Time"
                  onChange={handleChange}
                />
                {errors?.Closure_date && (
                  <p className="text-danger">{errors?.Closure_date}</p>
                )}
              </div>
              <div className={styles1.priorityRadioOuterContainer}>
                <label htmlFor="">
                  Have you been able to resolve this Query?
                </label>
                <div className={styles1.priorityRadioContainer}>
                  <RadioButton
                    options={options}
                    selectedOption={selectedRadioButton}
                    onChange={handleRadio}
                  />
                  {/* <div className={styles1.radioButton}>
                    <div
                      className={styles1.myCheckbox}
                      onClick={() => setInputChecked(true)}>
                      <div
                        className={
                          inputChecked ? styles1.checked : styles1.uncheck
                        }></div>
                    </div>

                    <p>Yes</p>
                  </div>
                  <div className={styles1.radioButton}>
                    <div
                      className={styles1.myCheckbox}
                      onClick={() => setInputChecked(false)}>
                      <div
                        className={
                          !inputChecked ? styles1.checked : styles1.uncheck
                        }></div>
                    </div>
                    <p>No</p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles1.queryBtnContainer}>
          <button
            onClick={() => addLogQueryMutation()}
            className={styles1.queryBtn}>
            Submit
          </button>
          <button
            className={styles1.reset}
            type="button"
            onClick={() => setClearModal(true)}>
            Reset
          </button>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
      </div>
    </CartStructure>
  );
};

export default LogQuery;
