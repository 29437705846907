import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import styles1 from '../../styles/MemberQueryRecord.module.scss';
import CartStructure from '../CartStructure/CartStructure';
import styles from '../../styles/Profile.module.scss';
import Dropdown from '../../component/DropDown/DropDown';
import Table from '../../component/CartTable/CartTable';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import { CallCenterHeaders } from '../../service/Essentials';
import swal from 'sweetalert';
import Paginate from '../../component/Paginate/Paginate';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const MemberQueryRecord = () => {
  const [clearModal, setClearModal] = useState(false);
  const [searchItem, setSearchItem] = useState('');
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState(null);
  const [details, setDetails] = useState([]);
  const [buttonStatus, setButtonStatus] = useState('Search');
  const [page, setPage] = useState(1);

  const fetchQueryDetails = async () => {
    setButtonStatus('Fetching record');
    try {
      const res = await axiosProperty.get(
        ApiConfig.fetchQueryRecord(searchItem),
        {
          headers: CallCenterHeaders(),
        },
      );
      if (res.data.data.length < 1) {
        swal({
          title: 'error!',
          text: 'Record not found',
          icon: 'error',
        });
        setButtonStatus('Search');
        return;
      }
      setDetails(res.data.data);
      setSearchItem('');
      setErrors(null);
      setButtonStatus('Search');
    } catch (err) {
      setDetails({});
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data);
        swal({
          title: 'error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, try again');
        swal({
          title: 'error!',
          text: error,
          icon: 'error',
        });
      }
      setButtonStatus('Search');
    }
  };
  
 //const data = Object.keys(details).length > 0 ? [details] : [];
//console.log(data);

  const keyMapping = {
    Querylog_ticket: 'Ticket',
    Membership_id: 'Membership ID',
    Query_type_name: 'Query Type',
    Sub_query: 'Sub Query',
    Query_details: 'Query Details',
    Level_name: 'Priority',
    Creation_date: 'Creation Date',
    Query_status: 'Query Status',
    First_name: 'Logged By',
  };

  const newDetails = details.map(item => {
    const renamedItem = {};
    for (const oldKey in item) {
      if (oldKey in keyMapping) {
        renamedItem[keyMapping[oldKey]] = item[oldKey];
      } else {
        renamedItem[oldKey] = item[oldKey];
      }
    }
    return renamedItem;
  });

  return (
    <CartStructure>
      <div className={styles.mainContainer}>
        <div className={styles.profileNavOuterContainer}>
          <div className={styles.profileNav}>
            <div>
              <Link
                className={styles1.unActiveLink}
                to="/call-center/member-profile">
                Profile
              </Link>
            </div>
            <div>
              <Link className={styles1.unActiveLink} to="/redeem-catalogue">
                Redeem Catalogue
              </Link>
            </div>
            <div>
              <Link
                className={styles1.unActiveLink}
                to="/call-center/transaction-details">
                Transaction details
              </Link>
            </div>
            <div className={styles.currentNav}>
              <Link
                className={`${styles.currentNav} ${styles.linkstyles}`}
                to="/call-center/member-query-record">
                Member Query Record
              </Link>
            </div>
            <div>
              <Link
                className={styles1.unActiveLink}
                to="/call-center/log-query">
                Log Query
              </Link>
            </div>
            <div>
              <Link
                className={styles1.unActiveLink}
                to="/call-center/add-family-card">
                Add Family Card
              </Link>
            </div>
          </div>
        </div>
        <div className={styles1.queryInputContainer}>
          <p>Member Query Record</p>
          <div className={styles1.queryInputInnerContainer}>
            <div className={styles1.queryInputBox}>
              <label htmlFor="">Ticket Number</label>
              <input
                type="text"
                value={searchItem}
                onChange={e => setSearchItem(e.target.value)}
              />
            </div>
            <div className={styles1.queryInputBox}>
              <label htmlFor="">Query Status</label>
              <Dropdown />
            </div>
            <button
              className={styles1.queryBtn}
              onClick={async e => {
                e.preventDefault();
                await fetchQueryDetails(searchItem);
              }}>
              {buttonStatus}
            </button>
            <button
              className={styles1.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>

        <div className={styles1.queryMainContainer}>
          <div className={styles1.tableContainer}>
            <Table data={newDetails} />
          </div>
          <div className={styles1.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={details} setPage={setPage} />
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
      </div>
    </CartStructure>
  );
};

export default MemberQueryRecord;
