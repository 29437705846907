import React from 'react';
import styles1 from '../../styles/CartStructure.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import av from '../../assets/images/av.png';
import recieved from '../../assets/images/recieved.png';
import blocked from '../../assets/images/blocked.png';
import used from '../../assets/images/used.png';
import styles from '../../styles/Profile.module.scss';

const CartStructure = ({children}) => {
  const dataString = sessionStorage.getItem('profile');
if (dataString) {
  var data = JSON.parse(dataString);
} else {
data = {};
}
  return (
    <CallCenterStructure>
      <div className={styles1.cartContainer}>
        <div>
          <h4 className={styles.handleMemberQueryHeader}>
            Membership Id - ({data?.Membership_id})
          </h4>
          <p className={styles.balance}>Current Balance - ({data.Current_balance})</p>
        </div>
        <div className={styles.balanceContainer}>
          <div className={styles.pointsContainer}>
            <div>
              <img src={av} alt=" available balance" />
            </div>
            <div className={styles.balanceInnerContainer}>
              <p>Available balance</p>
              <h6>{data.Current_balance}</h6>
            </div>
          </div>
          <div className={styles.pointsContainer}>
            <div>
              <img src={recieved} alt="recieved points" />
            </div>
            <div className={styles.balanceInnerContainer}>
              <p>Total Points Received</p>
              <h6>{data.Total_gained_points}</h6>
            </div>
          </div>
          <div className={styles.pointsContainer}>
            <div>
              <img src={blocked} alt="blocked points" />
            </div>
            <div className={styles.balanceInnerContainer}>
              <p>Total Blocked Points</p>
              <h6>0</h6>
            </div>
          </div>
          <div className={styles.pointsContainer}>
            <div>
              <img src={used} alt="used points" />
            </div>
            <div className={styles.balanceInnerContainer}>
              <p>Total Points used</p>
              <h6>{data?.Total_redeem_points}</h6>
            </div>
          </div>
        </div>
        <div className={styles1.cartMainContainer}>{children}</div>
      </div>
    </CallCenterStructure>
  );
};

export default CartStructure;
