import React, {useState, useEffect} from 'react';
import {Mark, Times, ViewPen} from '../../assets/icons';
import SearchInput from '../../component/SearchInput/SearchInput';
import styles from '../../styles/AddTierCriteria.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import { useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import { Headers} from '../../service/Essentials';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';

const AddCriteria = () => {

const queryClient = useQueryClient();

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const fetchTierCriterias = async (page) =>{
    const res = await axiosProperty.get(ApiConfig.fetchTierCriteria+`?page=${page}`, {
        headers:Headers()
    }
    );
    return res.data;
  }  
  const {data, status} = useQuery(['tiercriterias', page], () => fetchTierCriterias(page), {keepPreviousData:true,});

  useEffect(() => {
    setFilteredData(data?.data || []);
  }, [data]);

  const handleSearchInputChange = (searchTerm) => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredData(data?.data || []); // Reset to original data if search term is empty
    } else if (data?.data) {
      const filtered = data.data.filter(
        (item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.desc.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  };

  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [successModal, setSuccessModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState();
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState(null);

  const handleName = e => {
    setName(e.target.value);
  };

  const handleDesc = e => {
    setDesc(e.target.value);
  };

  const addTierCriteria = async () => {
    try{
        await axiosProperty.post(ApiConfig.createTierCriteria, {name: name, desc: desc}, 
        {
          headers:Headers()
      }
        );
        //setSuccessModal(true);
        setName('');
        setDesc('');
        setErrors(null);
        swal({
          title: "Success!",
          text: "Tier criteria created successfully",
          icon: "success",
        });
    }catch(err){
      if(err.response){
      setErrors(err.response.data);
      setError(err.response.data.message);
      }else{
      setError('Something went wrong, probably network'); 
      }
      console.log(err);
      swal({
        title: "error!",
        text: error,
        icon: "error",
      });
    }
  };

const {mutate:addTierCriteriaMutation} = useMutation(
  {
    mutationFn: addTierCriteria,
    onSuccess:()=>{queryClient.invalidateQueries(['tiercriterias'])}, 
  }
  );

  const updateTierCriteria = async () => {
    try{
        await axiosProperty.patch(ApiConfig.updateTierCriteria+`/${updateID}`, {name: name, desc: desc},
        {
          headers:Headers(),
      }
        );
        //setSuccessModal(true);
        setName('');
        setDesc('');        
        setIsSubmit(true);
        setIsUpdate(false);
        setErrors(null);
        swal({
          title: "Success!",
          text: "Tier criteria updated successfully",
          icon: "success",
        });
    }catch(err){
      if(err.response){
        setErrors(err.response.data);
        setError(err.response.data.message);
      }else{
       setError('Something went wrong, try again'); 
      }
      console.log(err);
      swal({
        title: "Error!",
        text: error,
        icon: "error",
      });
    }
  };

  const {mutate:updateTierCriteriaMutation} = useMutation(
    {
      mutationFn: updateTierCriteria,
      onSuccess:()=>{queryClient.invalidateQueries(['tiercriterias'])}, 
    }
    );

  const editTierCriteria = async (id) => {
       const res = await axiosProperty.get(ApiConfig.editTierCriteria+`/${id}`,
        {
          headers:Headers(),
      }
      );
      setIsUpdate(true); 
      setIsSubmit(false); 
      setName(res.data.data.name);
      setDesc(res.data.data.desc)
  };
  
  return (
    <CallCenterStructure>
      <div className={styles.transactionTypeContainer}>
        <div>
          <h6 className={styles.headerText}>Tier Criteria Master</h6>
        </div>
        <div className={styles.inputContainer}>

       <div className={styles.formContainer}>
            <div className={styles.typeCodeInputContainer}>
              <label htmlFor="">
                Enter Name Of Tier Criteria<sup>*</sup>
              </label>
              <input
               type="text" 
               onChange={handleName} 
               value={name}
               />
            </div>
            <div className={styles.typeCodeInputContainer}>
              <label htmlFor="" className={styles.transactionTypeLabel}>
                Enter Tier Criteria Description<sup>*</sup>
              </label>
              <input
                type="text"
                value={desc}
                onChange={handleDesc}
                className={styles.transactionTypeInput}
              />
            </div>
            {isSubmit && (<button
              className={styles.transactionTypeBtn}
              onClick={()=>addTierCriteriaMutation()}>
              Submit
            </button> )}
            {isUpdate && (<button
              className={styles.transactionTypeBtn}
              onClick={()=>updateTierCriteriaMutation()}>
              Update
            </button>) }
          </div>
          <p className={styles.transactionTypeNoteText}>
            *Note:Click on column header for Ascending/Descending
          </p>
        </div>
        <ErrorMessage errMsg={errors} />
        <div className={styles.tableContainer}>
          <div className={styles.formContainer}>
            <div className={styles.searchInput}>
              <SearchInput onSearchInputChange={handleSearchInputChange} />
            </div>
            {/* <button className={styles.transactionTypeBtn}>Search</button> */}
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.dataTable}>
              <thead>
                <tr>
                  <th>Tier Criteria ID</th>
                  <th>Tier Criteria Name</th>
                  <th>Tier Criteria Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
              {
                status === 'error' && (<tr><td className='text-danger'>Error Occured</td></tr>)
              }

              {
                status === 'loading' && (<tr><td className='text-info'>loading</td></tr>)
              }

                { status === 'success' && (filteredData.map(each => (
                  <tr key={each.id}>
                    <td>{each.id}</td>
                    <td>{each.name}</td>
                    <td>{each.desc}</td>
                    <td onClick={()=>{editTierCriteria(each.id); setUpdateID(each.id)}}>
                      <ViewPen />
                    </td>
                  </tr>
                )))}


              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={data} setPage={setPage} />
          </div>
        </div>
        {successModal && (
          <div className={styles.successModal}>
            <Mark />
            <p className={styles.successText}>
              Tier Criteria Inserted Successfully!
            </p>
            <span onClick={() => setSuccessModal(false)}>
              <Times />
            </span>
          </div>
        )}
      </div>
    </CallCenterStructure>
  );
};

export default AddCriteria;
