import React from 'react';
import styles from '../../styles/Footer.module.scss';

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerTextContainer}>
        <p className={styles.footerText}>
          Powered by Loyalty Solutions Limited, Nigeria
        </p>
      </div>
      <div className={styles.footerTextContainer}>
        <p className={styles.footerText}>
          © Copyright 2023 Loyalty Solutions Limited. All rights reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
