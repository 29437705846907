import React from 'react';
import {Times} from '../../assets/icons';
import styles from '../../styles/ReIssuanceModal.module.scss';

const ReIssuanceModal = ({onClose}) => {
  const modalData = [
    {id: 1, membershipID: '997044692870'},
    {id: 2, membershipID: '997044692870'},
    {id: 3, membershipID: '997044692870'},
  ];
  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeaderContainer}>
          <p>Company Name : Fidelity Bank Plc.</p>
          <span className={styles.icon} onClick={onClose}>
            <Times />
          </span>
        </div>
        <div className={styles.belowHeader}>
          <p>Membership Regeneration Iteration : All</p>
          <p>Number of Membership ID Issued : 2147483647</p>
          <p>Report Date : June 28, 2023</p>
        </div>
        <div className={styles.tableContainer}>
          <table className={styles.mainTable}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Membership ID</th>
              </tr>
            </thead>
            <tbody>
              {modalData.map(each => (
                <tr>
                  <td>{each.id}</td>
                  <td>{each.membershipID}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ReIssuanceModal;
