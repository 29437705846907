import React from 'react';
import {Times} from '../../assets/icons';
import styles from '../../styles/Modal.module.scss';

const LogDetailsModal = ({name, onClose, data, modalRef}) => {
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal} ref={modalRef}>
        <div className={styles.modalHeader}>
          <h3 className={styles.modalTitle}>{name}</h3>
          <span className={styles.closeIcon} onClick={onClose}>
            <Times />
          </span>
        </div>
        <div className={styles.modalContent}>
          <div className={styles.modalInnerContent}>
            <div className={styles.itemName}>
              <p>Log Query Ticket</p>
            </div>
            <div className={styles.itemValue}>
              <p>{data[0].TicketNum}</p>
            </div>
          </div>
          <div className={styles.modalInnerContent}>
            <div className={styles.itemName}>
              <p>Assigned/ Forwarded To</p>
            </div>
            <div className={styles.itemValue}>
              <p>{data[0].ForwardedTo}</p>
            </div>
          </div>
          <div className={styles.modalInnerContent}>
            <div className={styles.itemName}>
              <p>Interaction Date</p>
            </div>
            <div className={styles.itemValue}>
              <p>{data[0].Date}</p>
            </div>
          </div>
          <div className={styles.modalInnerContent}>
            <div className={styles.itemName}>
              <p>Query Details</p>
            </div>
            <div className={styles.itemValue}>
              <p>{data[0].Query}</p>
            </div>
          </div>
          <div className={styles.modalInnerContent}>
            <div className={styles.itemName}>
              <p>Interaction Details</p>
            </div>
            <div className={styles.itemValue}>
              <p>{data[0].Quantity}</p>
            </div>
          </div>
          <div className={styles.modalInnerContent}>
            <div className={styles.itemName}>
              <p>Status</p>
            </div>
            <div className={styles.itemValue}>
              <p>{data[0].Status}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogDetailsModal;
