import React from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import Dropdown from '../../component/DropDown/DropDown';
import DownloadButtonPdf from '../../component/DownloadButtonPdf/DownloadButtonPdf';
import DownloadButtonXlsx from '../../component/DownloadButtonXlsx/DownloadButtonXlsx';

const BenefitPartnerReport = ()=>{

    return(
    <>
        <CallCenterStructure>
        <div className='mt-5 p-4'>
           <h4 className='pb-4'>Benefit Partner Report</h4>
           <main className='border border-light p-3'>
           <form action="">
               <div className="row">
               <div className="form-group col-12 col-md-6" >
               <label className='d-flex justify-content-between' htmlFor="fromDate">From Date *</label>
                   <input className="w-100" type="text" placeholder='From date' />
               </div>
               <div className="form-group col-12 col-md-6" >
               <label className='d-flex justify-content-between' htmlFor="tillDate">Till Date *</label>
                   <input className="w-100" type="text" placeholder='Till date' />
               </div>
               </div>
              
               <div className="row">
               <div className="form-group col-12 col-md-6" >
               <label htmlFor="selectPartner">Select Partner *</label>
                   <Dropdown/>
               </div>
               <div className="form-group col-12 col-md-6" >
               <label htmlFor="selectPartnerBranch">Select Partner Branch *</label>
                   <Dropdown/>
               </div>
               </div>

               <div className="row">
               <div className="form-group col-12 col-md-6" >
               <label htmlFor="selectPartner">Select Benefit Type *</label>
                   <Dropdown/>
               </div>
               </div>
               <div className='form-group mt-3 d-flex justify-content-between d-md-flex justify-content-md-start'>
                   <button className='mr-5'>Submit</button>
            </div>

           </form>
           </main>
           <div className='mt-4 d-flex justify-content-between d-md-flex justify-content-md-start'>
           <DownloadButtonPdf />
          <DownloadButtonXlsx />
           </div>
          
           <section className="border border-light mt-4 mb-4">
                <div className="row p-4">
                <div className="col-12 table-responsive">
                <table className='table'>
                    <thead>
                        <tr>
                        <th className=''>Transaction Date</th>
                        <th className=''>Member Name</th>
                        <th className=''>Membership ID</th>
                        <th className=''>Partner Name</th>
                        <th className=''>Branch Name</th>
                        <th className=''>Receipt No.</th>
                        <th className=''>Benefit Description</th>
                        <th className=''>Discount</th>
                        </tr>
                    </thead>
                    <tbody>
                    
                    </tbody>
                </table>
                </div>
                <div className="col-12">Pagination</div>
                </div>
           </section>
           
        </div>
       </CallCenterStructure>
    </>
    );
}
export default BenefitPartnerReport;