import React, { useState, useEffect } from "react";
import CheckBox from "../../component/CheckBox/CheckBox";
import Dropdown from "../../component/DropDown/DropDown";
import SearchInput from "../../component/SearchInput/SearchInput";
import styles from "../../styles/AssignMenusToCompanyUsers.module.scss";
import CallCenterStructure from "../CallCenterStructure/CallCenterStructure";
import { HeadersOperator, Headers } from "../../service/Essentials";
import ApiConfig from "../../service/ApiConfig";
import axiosProperty from "../../service/HttpConfig";
import CheckboxUpgradePrivilege from "../../component/CheckboxUpgradePrivilege/CheckboxUpgradePrivilege";
import { useQuery } from "react-query";
import swal from "sweetalert";
import { useMutation, useQueryClient } from "react-query";
import MenuDeleteModal from "../../component/MenuDeleteModal/MenuDeleteModal";

const AssignMenusToCompanyUsers = () => {
  const [userType, setUserType] = useState("");
  const [userTypeId, setUserTypeId] = useState(null);
  const [userGroup, setUserGroup] = useState("");
  const [userGroupId, setUserGroupId] = useState(null);
  const [companyType, setCompanyType] = useState("");
  const [companyTypeId, setCompanyTypeId] = useState(null);
  const [page, setPage] = useState(1);
  const [assignMenu, setAssignMenu] = useState([]);
  const [i, setI] = useState(null);
  const [companyAssignedMenu, setCompanyAssignedMenu] = useState([]);
  const [id, setId] = useState(null);
  const [newOperation, setNewOperation] = useState([]);
  const [error, setError] = useState("oops... something went wrong");
  const queryClient = useQueryClient();
  const [menuCheck, setMenuCheck] = useState([]);
  const [assignedMenuCheck, setAssignedMenuCheck] = useState([]);
  const [updateData, setUpdateData] = useState();

  const [menuArr, setMenuArr] = useState([]);
  const [assignedMenuArr, setAssignedMenuArr] = useState([]);
  const [newArr, setNewArr] = useState([]);
  const [newObj, setNewObj] = useState({});

  const [checkOperation, setCheckOperation] = useState([]);
  const [operationArr, setOperationArr] = useState([]);
  const [operationArr2, setOperationArr2] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);
  const onClose = () => {
    setDeleteModal(false);
  };

  const idd = JSON.parse(sessionStorage.getItem("companyTypeId"));

  const handleUserTypeId = (id) => {
    setUserTypeId(id);
    setAssign((prev) => ({ ...prev, user_type_id: id }));
  };
  const handleUserType = (name) => {
    setUserType(name);
  };
  const handleCompanyTypeId = (id) => {
    setAssign((prev) => ({ ...prev, company_type_id: id }));
  };
  const handleCompanyType = (name) => {
    setCompanyType(name);
  };
  const handleUserGroupId = (id) => {
    setAssignedMenuCheck([]);
    setUserGroupId(id);
    setAssign((prev) => ({ ...prev, user_group_id: id }));
  };
  const handleUserGroup = (name) => {
    setAssignedMenuCheck([]);
    setUserGroup(name);
  };

  const [errors, setErrors] = useState({
    user_type_id: "",
    company_type_id: "",
    menu_id: "",
    user_group_id: "",
    parent_id: "",
    operation: "",
  });
  const [assign, setAssign] = useState({
    user_type_id: "",
    company_type_id: "",
    menu_id: "",
    user_group_id: "",
    parent_id: "",
    operation: "",
  });
 
  const handleReset = () => {
    setUserGroupId(null);
    setUserTypeId(null);
    setAssign({
      user_type_id: "",
      company_type_id: "",
      menu_id: "",
      user_group_id: "",
      parent_id: "",
      operation: "",
    });
  };
  const handleCheckOperation = (tag, ii, i, name, menu_id) => {
    let operation = assignedMenuCheck[i].operation[ii];

    operation.tag = !tag;

    let checkClone = [...assignedMenuCheck[i].operation];

    checkClone[ii] = operation;
    if (checkClone[ii].tag === true) {
      setOperationArr2((prev) => [
        ...prev,
        { id: menu_id, name: checkClone[ii].name },
      ]);
    } else if (checkClone[ii].tag === false) {
      const getItem = operationArr2.find((item) => {
        return item.id === menu_id && item.name === checkClone[ii].name;
      });
      if (getItem) {
        setOperationArr2(
          operationArr2.filter((itemDel) => {
            return itemDel !== getItem;
          })
        );
      }
    }
  };

 

  useEffect(() => {
    assignMenu?.map((each) => {
      const getItem = menuCheck.find((item) => {
        return item.menu_id === each.id;
      });
      if (!getItem) {
        setMenuCheck((prev) => [
          ...prev,
          {
            name: each.name,
            children: each?.children,
            parent_id: each.parent_id,
            menu_id: each.id,
            operation: each.operation,
            company_type_id: idd,
            user_group_id: userGroupId,
            user_type_id: userTypeId,
            tag: false,
          },
        ]);
      }
      return null;
    });
  }, [assignMenu, menuCheck, userGroupId, userTypeId, idd]);

  useEffect(() => {
    companyAssignedMenu?.map((each) => {
      // setMenuCheck()
      const getItem = assignedMenuCheck.find((item) => {
        return item.menu_id === each.id;
      });

      if (!getItem) {
        setAssignedMenuCheck((prev) => [
          ...prev,
          {
            name: each.name,
            children: each?.children,
            parent_id: each.parent_id,
            menu_id: each.id,
            operation: [
              { name: "Add", tag: false },
              { name: "View", tag: false },
              { name: "Modify", tag: false },
              { name: "Delete", tag: false },
            ],
            company_type_id: idd,
            user_group_id: userGroupId,
            user_type_id: userTypeId,
            tag: false,
            assign_menu: each?.assign_menu,
          },
        ]);
      }

      return null;
    });
  }, [
    companyAssignedMenu,
    assignedMenuCheck,
    userGroupId,
    userTypeId,
    idd,
    operationArr2,
  ]);

  const handleCheck = (tag, i) => {
    let solution = menuCheck[i];
    solution.tag = !tag;
    let menuCheckClone = [...menuCheck];
    menuCheckClone[i] = solution;
    setMenuCheck([...menuCheckClone]);
    if (menuCheckClone[i].tag === true) {
      setMenuArr((prev) => [
        ...prev,
        {
          company_type_id: idd,
          menu_id: menuCheckClone[i].menu_id,
          user_type_id: userTypeId,
          user_group_id: userGroupId,
          parent_id: menuCheckClone[i].parent_id,
          operation: menuCheckClone[i].operation,
        },
      ]);
    } else if (menuCheckClone[i].tag === false) {
      const getItem = menuArr.find((item) => {
        return item.menu_id === menuCheckClone[i].menu_id;
      });
      if (getItem) {
        setMenuArr(
          menuArr.filter((itemDel) => {
            return itemDel !== getItem;
          })
        );
      }
    }
  };
  
  const handleAssignMenu = async () => {
    try {
      const res = await axiosProperty.post(
        ApiConfig.createAssignMenu,
        menuArr,
        {
          headers: HeadersOperator(),
        }
      );

      setErrors({});

      swal({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: "Error!",
          text: error,
          icon: "error",
        });
      } else {
        setError("Something went wrong, probably network");
        swal({
          title: "warning!",
          text: error,
          icon: "error",
        });
      }
    }
  };
  const { mutate: handleAssignMenuMutation } = useMutation({
    mutationFn: handleAssignMenu,
    onSuccess: () => {
      queryClient.invalidateQueries(["assignedmenu"]);
    },
  });

  const handleAssignedCheck = (tag, i) => {
    tag = false
    let solution = assignedMenuCheck[i];
    solution.tag = !tag;
    let menuCheckClone = [...assignedMenuCheck];
    menuCheckClone[i] = solution;

    setAssignedMenuCheck([...menuCheckClone]);
    if (menuCheckClone[i].tag === true) {
      setAssignedMenuArr((prev) => [
        ...prev,
        {
          company_type_id: companyTypeId,
          menu_id: menuCheckClone[i].menu_id,
          user_type_id: userTypeId,
          user_group_id: userGroupId,
          parent_id: menuCheckClone[i].parent_id,
          operation: menuCheckClone[i].operation,
          assign_menu: menuCheckClone[i].assign_menu,
        },
      ]);
      setUpdateData((prev) => menuCheckClone[i].assign_menu.map((a) => a));
      // {
      //   return {
      //     "id":a.id,
      //     "company_type_id":a.company_type_id,
      //     "menu_id":a.menu_id,
      //     "user_type_id":a.user_type_id,
      //     "user_group_id":a.user_group_id,
      //     "parent_id":a.parent_id,
      //     "operation":a.operation
      //   }
      // }
    } else if (menuCheckClone[i].tag === false) {
      const getItem = assignedMenuArr.find((item) => {
        return item.menu_id === menuCheckClone[i].menu_id;
      });
      const getItem2 = updateData.find((item) => {
        return item;
      });
      if (getItem) {
        setAssignedMenuArr(
          assignedMenuArr.filter((itemDel) => {
            return itemDel !== getItem;
          })
        );
      }

      if (getItem2) {
        setUpdateData((prev) =>
          menuCheckClone[i].assign_menu.filter((a) => a.id !== getItem2.id)
        );
      }
    }
  };

  useEffect(() => {
    console.log(updateData);
    setNewArr([...assignedMenuArr]);
  }, [assignedMenuArr, updateData]);

  const getAssignMenu = async (page) => {
    const res = await axiosProperty.get(ApiConfig.fetchAssignMenu(page), {
      headers: HeadersOperator(),
    });
    return res.data;
  };
  const { data: assignMenuData, status } = useQuery(
    ["assignmenu", {page,companyAssignedMenu}],
    () => getAssignMenu(page),
    { keepPreviousData: true }
  );
  useEffect(() => {
    setAssignMenu(assignMenuData?.data || []);
  }, [assignMenuData]);

  const getCompanyAssignedMenu = async (userGroupId, idd, page) => {
    const res = await axiosProperty.get(
      ApiConfig.fetchAssignedMenu(userGroupId, idd, 0, page),
      {
        headers: HeadersOperator(),
      }
    );

    return res.data;
  };

  const { data: assignedMenuData, status: stat } = useQuery(
    ["assignedmenu", { userGroupId, idd, page }],
    () => getCompanyAssignedMenu(userGroupId, idd, 0, page)
    // { keepPreviousData: true }
  );

  useEffect(() => {
    setCompanyAssignedMenu(assignedMenuData?.data || []);
  }, [assignedMenuData]);
  newArr.map((a) => {
    a.assign_menu?.map(
      (b) =>
        (b.operation = operationArr2
          .map((aa) => aa.id === a.menu_id && aa.name)
          .filter((n) => n !== false)
          .toString())
    );
    // a.operation = operationArr2
    //   .map((aa) => aa.id === a.menu_id && aa.name)
    //   .filter((n) => n !== false)
    //   .toString();

    return null;
  });
console.log(assignedMenuArr)
  console.log(updateData);
  const updateAssignedMenu = async () => {
    try {
      const res = await axiosProperty.patch(
        ApiConfig.updateAssignedMenu(userGroupId, idd, 0),
        updateData,
        {
          headers: HeadersOperator(),
        }
      );

      setErrors({});
      swal({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      }).then((result) => {
        if (result.value) {
          setUpdateData();
        }
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: "Error!",
          text: error,
          icon: "error",
        });
      } else {
        setError("Something went wrong, probably network");
        swal({
          title: "warning!",
          text: error,
          icon: "error",
        });
      }
    }
  };

  const { mutate: updateAssignedMenuMutation } = useMutation({
    mutationFn: updateAssignedMenu,
    onSuccess: () => {
      queryClient.invalidateQueries(["assignedmenu"]);
    },
  });

  const deleteAssignedMenu = async () => {
    try {
      const config = {headers:HeadersOperator(),
      data: updateData}
      const res = await axiosProperty.delete(
        ApiConfig.deleteAssignedMenu(userGroupId, idd, 0),
        config
      );
      setDeleteModal(false);
      swal({
        title: "Success!",
        text: res.data.message,
        icon: "success",
      }).then((result) => {
        if (result.value) {
          setUpdateData();
        }
      });
      queryClient.invalidateQueries("assignedmenu");
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else {
        setError("Something went wrong, try again");
      }
      swal({
        title: "Warning!",
        text: error,
        icon: "warning",
      });
    }
  };

  return (
    <div>
      <CallCenterStructure>
        <div className={styles.AssignMenusToCompanyUsers}>
          <div className={styles.headerText}>
            <p>Assign Menus To Company Users</p>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.field}>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Select User Type<sup>*</sup>
                </label>
                <Dropdown
                  header={HeadersOperator()}
                  api={ApiConfig.fetchUserType}
                  onSelect={handleUserTypeId}
                  onSelectOption={handleUserType}
                  column={"name"}
                  retrievedValue={userType}
                  key="name"
                />
                {errors?.user_type_id && (
                  <p className="text-danger">{errors?.user_type_id}</p>
                )}
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Select User Group<sup>*</sup>
                </label>
                <Dropdown
                  header={HeadersOperator()}
                  api={ApiConfig.fetchUserGroupEach(userTypeId)}
                  onSelect={handleUserGroupId}
                  onSelectOption={handleUserGroup}
                  column={"user_group_name"}
                  retrievedValue={userGroup}
                  key="name"
                />
                {errors?.user_group_id && (
                  <p className="text-danger">{errors?.user_group_id}</p>
                )}
              </div>
              <div className={styles.fieldContainer}>
                <label htmlFor="">
                  Select User Name<sup>*</sup>
                </label>
                <input type="text" />
              </div>
            </div>
            <div className={styles.buttonContainer}>
              {/* <button className={styles.submit}>Approve Selection</button> */}
              <button className={styles.reset} onClick={handleReset}>
                Reset
              </button>
            </div>
            <div className={styles.checkboxContainerTop}>
              <label htmlFor="">Menus to be assigned</label>
              <div className={styles.searchSection}>
                <div className={styles.line1}></div>

                <div className={styles.line2}>
                  <div className={styles.searchInputContainer}>
                    <div className={styles.searchInput}>
                      <SearchInput />
                    </div>
                    <button>Search</button>
                  </div>
                </div>
              </div>
              <div className={styles.checkboxMainContainerTop}>
                {status === "error" && (
                  <tr>
                    <td className="text-danger">Error Occured!</td>
                  </tr>
                )}

                {status === "loading" && (
                  <tr>
                    <td className="text-info">loading...</td>
                  </tr>
                )}

                {status === "success" &&
                  menuCheck.map(({ name, tag, children }, i) => (
                    <div className={styles.checkboxInnerContainerTop} key={i}>
                      <div className={styles.line4}>
                        <div>
                          <CheckboxUpgradePrivilege
                            id={i}
                            checked={tag}
                            onChange={() => handleCheck(tag, i)}
                          />

                          <p>{name}</p>
                        </div>
                      </div>
                      {children.length > 0 &&
                        children?.map((child) => {
                          return (
                            <div key={child.id} className={styles.line5}>
                              <div>
                                <CheckboxUpgradePrivilege
                                  id={i}
                                  checked={tag}
                                  onChange={() => handleCheck(tag, i)}
                                />
                                <p>{child?.name}</p>
                              </div>
                              {child.children.map((each) => {
                                return (
                                  <div key={each.id} className={styles.line6}>
                                    <div>
                                      <CheckboxUpgradePrivilege
                                        id={i}
                                        checked={tag}
                                        onChange={() => handleCheck(tag, i)}
                                      />
                                      <p>{each?.name}</p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                    </div>
                  ))}
              </div>
              <button
                className={styles.submit}
                onClick={(event) => {
                  event.preventDefault();
                  handleAssignMenuMutation();
                }}
              >
                Assign Menu
              </button>
            </div>
            <div className={styles.checkboxContainerButtom}>
              <label htmlFor="">Assigned Menus</label>
              <div className={styles.checkboxInnerContainerButtom}>
                <div className={styles.checkboxMainContainerButtom}>
                  <div className={styles.line6}>
                    <div className={styles.leftLine6}></div>
                    <div className={styles.rightLine6}>
                      <div className={styles.innerRightLine6}>
                        <p>Add</p>
                      </div>
                      <div className={styles.innerRightLine6}>
                        <p>View</p>
                      </div>
                      <div className={styles.innerRightLine6}>
                        <p>Modify</p>
                      </div>
                      <div className={styles.innerRightLine6}>
                        <p>Delete</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className={styles.line3}>
                    <div>
                      <CheckBox />
                      <p>Select all</p>
                    </div>
                  </div> */}
                  {stat === "error" && (
                    <tr>
                      <td className="text-danger">Error Occured!</td>
                    </tr>
                  )}

                  {stat === "loading" && (
                    <tr>
                      <td className="text-info">loading...</td>
                    </tr>
                  )}
                  {stat === "success" &&
                    assignedMenuCheck.map(
                      ({ name, tag, children, operation, menu_id }, i) => (
                        <React.Fragment key={i}>
                          <div className={styles.line7}>
                            <div className={styles.innerLeftLine7}>
                              <CheckboxUpgradePrivilege
                                id={i}
                                checked={tag}
                                onChange={() => handleAssignedCheck(tag, i)}
                              />
                              <p className={styles.actionText}>{name}</p>
                            </div>
                            <div className={styles.innerRightLine7}>
                              {operation.map(({ name, tag }, ii) => {
                                return (
                                  <div className={styles.line7check} key={ii}>
                                    <CheckboxUpgradePrivilege
                                      id={ii}
                                      checked={tag}
                                      onChange={() =>
                                        handleCheckOperation(
                                          tag,
                                          ii,
                                          i,
                                          name,
                                          menu_id
                                        )
                                      }
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          {children.length > 0 &&
                            children?.map((child) => {
                              return (
                                <div className={styles.line8}>
                                  <div className={styles.innerLeftLine8}>
                                    <CheckboxUpgradePrivilege
                                      id={i}
                                      checked={tag}
                                      onChange={() =>
                                        handleAssignedCheck(tag, i)
                                      }
                                    />
                                    <p className={styles.actionText}>
                                      {child.name}
                                    </p>
                                  </div>
                                  <div className={styles.innerRightLine8}>
                                    {operation.map(({ name, tag }, ii) => {
                                      return (
                                        <div
                                          className={styles.line7check}
                                          key={ii}
                                        >
                                          <CheckboxUpgradePrivilege
                                            id={ii}
                                            checked={tag}
                                            onChange={() =>
                                              handleCheckOperation(
                                                tag,
                                                ii,
                                                i,
                                                name,
                                                menu_id
                                              )
                                            }
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              );
                            })}
                        </React.Fragment>
                      )
                    )}
                </div>
              </div>
              <div className={styles.buttonContainer}>
                <button
                  className={styles.submit}
                  onClick={() => {
                    setDeleteModal(true);
                  }}
                >
                  Delete Menu
                </button>
                <button
                  className={styles.reset}
                  onClick={(event) => {
                    event.preventDefault();
                    updateAssignedMenuMutation();
                  }}
                >
                  Update Operation
                </button>
              </div>
            </div>
          </div>
        </div>
        {deleteModal && (
          <MenuDeleteModal
            onClose={onClose}
            onDelete={() => deleteAssignedMenu()}
          />
        )}
      </CallCenterStructure>
    </div>
  );
};

export default AssignMenusToCompanyUsers;
