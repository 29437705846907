import React, {useState} from 'react';
import {Times, ViewPen} from '../../assets/icons';
import CheckBox from '../../component/CheckBox/CheckBox';
import Dropdown from '../../component/DropDown/DropDown';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import RadioBlueButton3 from '../../component/RadioBlueButton3/RadioBlueButton3';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/BonusPeriodicCampaign.module.scss';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const BonusPeriodicCampaign = () => {
  const [clearModal, setClearModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const onClose = () => {
    setDeleteModal(false);
  };

  const data = [
    {
      code: '4653',
      name: 'Campaign',
      linked: '376664',
      from: '28/6/2023',
      to: '28/6/2023',
      amount: '756382',
      point: '294',
      percentage: 56,
    },
    {
      code: '4653',
      name: 'Campaign',
      linked: '376664',
      from: '28/6/2023',
      to: '28/6/2023',
      amount: '756382',
      point: '294',
      percentage: 56,
    },
    {
      code: '4653',
      name: 'Campaign',
      linked: '376664',
      from: '28/6/2023',
      to: '28/6/2023',
      amount: '756382',
      point: '294',
      percentage: 56,
    },
    {
      code: '4653',
      name: 'Campaign',
      linked: '376664',
      from: '28/6/2023',
      to: '28/6/2023',
      amount: '756382',
      point: '294',
      percentage: 56,
    },
  ];
  return (
    <CallCenterStructure>
      <div className={styles.frequencyPeriodic}>
        <div className={styles.headerText}>
          <p>Bonus Periodic Campaign</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Loyalty Programme<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Start Date<sup>*</sup>
              </label>
              <input type="date" name="" id="" />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Campaign Name<sup>*</sup>
              </label>
              <input type="text" name="" id="" />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                End Date<sup>*</sup>
              </label>
              <input type="date" name="" id="" />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select branches<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Product Group<sup>*</sup>
              </label>
              <Dropdown />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Transaction Type<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Product Brand <sup>*</sup>
              </label>
              <Dropdown />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Transaction Channel <sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Product Code<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.fieldContainer3}>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                Sweepstake to Link<sup>*</sup>
              </label>
              <RadioButtonBlue firstText="Yes" secondText="No" />
            </div>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                Sweepstake<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                No. of Sweepstake
                <sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.fieldContainer3}>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                Transaction Amount Criteria<sup>*</sup>
              </label>
              <RadioButtonBlue firstText="Yes" secondText="No" />
            </div>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                Transaction Amount<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.innerFieldContainer3}>
              <label htmlFor="">
                Operator<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>

          <div className={styles.fieldContainer2}>
            <div className={styles.radioButtonField2}>
              <label htmlFor="">
                Reward Based on tier<sup>*</sup>
              </label>
              <RadioButtonBlue firstText="Yes" secondText="No" />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select Tier<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.radioButtonField2}>
              <label htmlFor="">
                Reward Criteria<sup>*</sup>
              </label>
              <RadioButtonBlue firstText="Point" secondText="Percentage" />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Reward<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.fieldContainer6}>
            <div className={styles.innerFieldContainer6}>
              <label htmlFor="">Campaign Status</label>
              <RadioButtonBlue firstText="Enabled" secondText="Disabled" />
            </div>
            <div className={styles.innerFieldContainer6}>
              <label htmlFor="">
                Reward once
                {/* (Customer + Account + Product) */}
              </label>
              <RadioButtonBlue firstText="Yes" secondText="No" />
            </div>
            <div className={styles.innerFieldContainer6}>
              <label htmlFor="">
                Campaign Description<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          {/* <div className={styles.fieldContainer2}>
            <div className={styles.radioButtonField2}>
              <label htmlFor="">
                Campaign Status<sup>*</sup>
              </label>
              <RadioButtonBlue firstText="Enabled" secondText="Disabled" />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Campaign Description<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div> */}
          {/* <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Reward<sup>*</sup>
              </label>
              <Dropdown />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Campaign Description<sup>*</sup>
              </label>
              <input type="text" />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.radioButtonField2}>
              <label htmlFor="">
                Reward based on Tier<sup>*</sup>
              </label>
              <RadioButtonBlue firstText="Point" secondText="Percentage" />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select Tier<sup>*</sup>
              </label>
              <Dropdown />
            </div>
          </div> */}
          <div className={styles.fieldContainer4}>
            <div className={styles.radioButtonField4}>
              <label htmlFor="">
                Recurring Campaign<sup>*</sup>
              </label>
              <RadioButtonBlue firstText="Yes" secondText="No" />
            </div>
            <div className={styles.checkbox2}>
              <label htmlFor="">
                Execute Criteria<sup>*</sup>
              </label>
              <RadioBlueButton3
                firstText="Monthly"
                secondText="Weekly"
                thirdText="Days"
              />
            </div>
          </div>

          <div className={styles.monthsContainer}>
            <label htmlFor="">
              Month<sup>*</sup>
            </label>
            <div className={styles.monthsMainContainer}>
              <div className={styles.innerMonthsContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>Select All</p>
              </div>
              <div className={styles.innerMonthsContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>January</p>
              </div>
              <div className={styles.innerMonthsContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>February</p>
              </div>
              <div className={styles.innerMonthsContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>March</p>
              </div>
              <div className={styles.innerMonthsContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>April</p>
              </div>
              <div className={styles.innerMonthsContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>May</p>
              </div>
              <div className={styles.innerMonthsContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>June</p>
              </div>
              <div className={styles.innerMonthsContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>July</p>
              </div>
              <div className={styles.innerMonthsContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>August</p>
              </div>
              <div className={styles.innerMonthsContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>September</p>
              </div>
              <div className={styles.innerMonthsContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>October</p>
              </div>
              <div className={styles.innerMonthsContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>November</p>
              </div>
              <div className={styles.innerMonthsContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>December</p>
              </div>
            </div>
          </div>
          <div className={styles.weeksContainer}>
            <label htmlFor="">
              Weeks <sup>*</sup>
            </label>
            <div className={styles.mainWeeksContainer}>
              <div className={styles.innerMonthsContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>Select All</p>
              </div>
              <div className={styles.innerMonthsContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>First</p>
              </div>
              <div className={styles.innerMonthsContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>Second</p>
              </div>
              <div className={styles.innerMonthsContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>Third</p>
              </div>
              <div className={styles.innerMonthsContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>Fourth</p>
              </div>
              <div className={styles.innerMonthsContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>Fifth</p>
              </div>
            </div>
          </div>
          <div className={styles.daysContainer}>
            <label htmlFor="">
              Days<sup>*</sup>
            </label>
            <div className={styles.mainDaysContainer}>
              <div className={styles.innerDaysContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>Select All</p>
              </div>
              <div className={styles.innerDaysContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>Monday</p>
              </div>
              <div className={styles.innerDaysContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>Tuesday</p>
              </div>
              <div className={styles.innerDaysContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>Wednesday</p>
              </div>
              <div className={styles.innerDaysContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>Thursday</p>
              </div>
              <div className={styles.innerDaysContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>Friday</p>
              </div>
              <div className={styles.innerDaysContainer}>
                <CheckBox />
                <p className={styles.checkboxText}>Saturday</p>
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer5}>
            <label htmlFor="">Time (HH:MM)</label>
            <div className={styles.outterFieldContainer5}>
              <div className={styles.innerFieldContainer5}>
                <label htmlFor="">From-HH</label>
                <Dropdown />
              </div>
              <div className={styles.innerFieldContainer5}>
                <label htmlFor="">From-MM</label>
                <Dropdown />
              </div>
              <div className={styles.innerFieldContainer5}>
                <label htmlFor="">From-HH</label>
                <Dropdown />
              </div>
              <div className={styles.innerFieldContainer5}>
                <label htmlFor="">From-MM</label>
                <Dropdown />
              </div>
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Submit</button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Company Code</th>
                  <th>Campaign Name</th>
                  <th>Linked to LBS?</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Cumulative Amount</th>
                  <th>Reward Points</th>
                  <th>Reward Percentage</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map(each => (
                  <tr key={each.code}>
                    <td>{each.code}</td>
                    <td>{each.name}</td>
                    <td>{each.linked}</td>
                    <td>{each.from}</td>
                    <td>{each.to}</td>
                    <td>{each.amount}</td>
                    <td>{each.point}</td>
                    <td>{each.percentage}</td>
                    <td>
                      <div className={styles.iconContainer}>
                        <span className={styles.icon}>
                          <ViewPen />
                        </span>
                        <span
                          className={styles.icon}
                          onClick={() => setDeleteModal(true)}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <p>Pagination</p>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.tableContainer}>
            <h6 className={styles.diabledText}>Disabled Campaigns</h6>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Company Code</th>
                  <th>Campaign Name</th>
                  <th>Linked to LBS?</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Cumulative Amount</th>
                  <th>Reward Points</th>
                  <th>Reward Percentage</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map(each => (
                  <tr key={each.code}>
                    <td>{each.code}</td>
                    <td>{each.name}</td>
                    <td>{each.linked}</td>
                    <td>{each.from}</td>
                    <td>{each.to}</td>
                    <td>{each.amount}</td>
                    <td>{each.point}</td>
                    <td>{each.percentage}</td>
                    <td>
                      <div className={styles.iconContainer}>
                        <span className={styles.icon}>
                          <ViewPen />
                        </span>
                        <span
                          className={styles.icon}
                          onClick={() => setDeleteModal(true)}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            <p>Pagination</p>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && <MenuDeleteModal onClose={onClose} />}
      </div>
    </CallCenterStructure>
  );
};

export default BonusPeriodicCampaign;
