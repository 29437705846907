import React, {useState} from 'react';
import Dropdown from '../../component/DropDown/DropDown';
import styles from '../../styles/SurveyQuestionnaireMaster.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import {getUser} from '../../service/common';
import swal from 'sweetalert';
import { CompanySurveyHeaders , ProgramID} from '../../service/Essentials';
import { useMutation} from 'react-query';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const SurveyQuestionnaireMaster = () => {
const user = getUser();
const program_id = ProgramID();

  const [responseID, setResponseID] = useState('');
  const [surveyID, setSurveyID] = useState('');
  const [choiceID, setChoiceID] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState();
  const [clearModal,setClearModal] = useState(false)


  const [survey, setSurvey] = useState('');
  const [choice, setChoice] = useState('');
  const [response, setResponse] = useState('');
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState({
    Company_id: '',
    Response_type: '',
    Survey_id: '',
    Enrollment_id: '',
    Choice_id: '',
    Question: '',
    Active_Flag: '',
  });

  const [formData, setFormData] = useState({
    Company_id: program_id,
    Response_type: responseID,
    Survey_id: surveyID,
    Enrollment_id: 1,
    Choice_id: 2,
    Question: '',
    Active_Flag: 1,
  });
  console.log(formData);
  const handleChange = e => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
  };

  const handleSurvey = name => {
    setSurvey(name);
  };

  const handleSurveyID = id => {
    setSurveyID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Survey_id: id,
    }));
  };

  const handleResponse = name => {
    setResponse(name);
  };

  const handleResponseID = id => {
    setResponseID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Response_type: id,
    }));
  };

  const handleChoice = name => {
    setChoice(name);
  };

  const handleChoiceID = id => {
    setChoiceID(id);
    setFormData(prevFormData => ({
      ...prevFormData,
      Choice_id: id,
    }));
  };

  const addQuestionnaire = async () => {
    //e.preventDefault();
    //setData(prev => [...prev, {id: 4, name: text}]);
   // return
    try{
       const res = await axiosProperty.post(ApiConfig.createQuestionnaire, formData, 
        {
          headers:CompanySurveyHeaders(),
      }
        );

        if(res.data.status == 'Failed!'){
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          }); 
          return
        }
      
       setFormData({Company_id:program_id, Response_type:'', Survey_id: '',
        Enrollment_id:1, Choice_id:2, Question:'', Active_Flag:1});
        setSurvey('');
        setSurveyID('');
        setResponseID('');
        setResponse('');
        setErrors({});
        swal({
          title: "Success!",
          text: "Questionnaire added successfully",
          icon: "success",
        });
    }catch(err){
      if(err.response){
        setError(err.response.data.message);
        setErrors(err.response.data.errors);
        swal({
          title: 'Error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError(err.response.data.message);
        swal({
          title: 'warning!',
          text: error,
          icon: 'error',
        });
      }
    }
  };

  const {mutate: addQuestionnaireMutation} = useMutation({
    mutationFn: addQuestionnaire,
  });

  return (
    <CallCenterStructure>
      <div className={styles.surveyQuestionnaireMaster}>
        <div className={styles.headerText}>
          <p>Survey Questionnaire Master</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select Survey<sup>*</sup>
              </label>
              <Dropdown
                header={CompanySurveyHeaders()}
                api={ApiConfig.fetchSurveyTypeAll}
                onSelect={handleSurveyID}
                onSelectOption={handleSurvey}
                column={'Survey_name'}
                idValue={'Survey_id'}
                retrievedValue={surveyID}
                key="survey"
              />
              {errors?.Survey_id && (
                <p className="text-danger">{errors?.Survey_id}</p>
              )}
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Enter Question<sup>*</sup>
              </label>
              <input
                type="text"
                name="Question"
                value={formData.Question}
                onChange={handleChange}
              />
              {errors?.Question && (
                <p className="text-danger">{errors?.Question}</p>
              )}
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select Response<sup>*</sup>
              </label>
              <Dropdown
                onSelect={handleResponseID}
                onSelectOption={handleResponse}
                column={'name'}
                enumType={[
                  {id: 1, name: 'Multiple Choice'},
                  {id: 2, name: 'Text Based'},
                ]}
                retrievedValue={responseID}
                key="response"
              />
              {errors?.Response_type && (
                <p className="text-danger">{errors?.Response_type}</p>
              )}
            </div>
          </div>
          <div className={styles.buttonContainer}>
            {/* <button className={styles.submit}>Submit</button> */}
            {isSubmit && (
              <button
                className={styles.submit}
                onClick={() => addQuestionnaireMutation()}>
                Submit
              </button>
            )}
            {isUpdate && (
              <button
                className={styles.submit}
                onClick={() => addQuestionnaireMutation()}>
                Update
              </button>
            )}
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
      </div>
    </CallCenterStructure>
  );
};

export default SurveyQuestionnaireMaster;
