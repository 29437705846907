import React, {useState, useEffect} from 'react';
import Dropdown from '../../component/DropDown/DropDown';
import styles from '../../styles/RegisterCompany.module.scss';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import defaultImage from '../../assets/images/defaultImage.png';
import ImageDragAndDrop from '../../component/ImageDragAndDrop/ImageDragAndDrop';
import RadioButtonBlue from '../../component/RadioButtonBlue/RadioButtonBlue';
import RadioBlueButton3 from '../../component/RadioBlueButton3/RadioBlueButton3';
import CheckBox from '../../component/CheckBox/CheckBox';
import facebook from '../../assets/images/facebook.png';
import twitter from '../../assets/images/twitter.png';
import linkedin from '../../assets/images/linkedin.png';
import google from '../../assets/images/google.png';
import {Times, View, ViewPen} from '../../assets/icons';
import SearchInput from '../../component/SearchInput/SearchInput';
import {useQuery, useMutation, useQueryClient} from 'react-query';
import axiosProperty from '../../service/HttpConfig';
import ApiConfig from '../../service/ApiConfig';
import Paginate from '../../component/Paginate/Paginate';
import { Headers} from '../../service/Essentials';
import swal from 'sweetalert';
import ErrorMessage from '../../component/ErrorMessage/ErrorMessage';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const RegisterCompany = () => {
  const [clearModal, setClearModal] = useState(false);
  //image state
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageName, setSelectedImageName] = useState('');
  const [resellerRadio, setResellerRadio] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedItemID, setSelectedItemId] = useState('');

  //radio states
  const [passwordExpiryRadio, setpasswordExpiryRadio] = useState(false);
  const [notifySourceRadio, setnotifySourceRadio] = useState('email');
  const [familyCardRadio, setfamilyCardRadio] = useState(false);
  const [pinRadio, setpinRadio] = useState(false);
  const [custPinTranRadio, setcustPinTranRadio] = useState(false);
  const [custPinRedRadio, setcustPinRedRadio] = useState(false);
  const [userPinTranRadio, setuserPinTranRadio] = useState(false);
  const [userPinRedRadio, setuserPinRedRadio] = useState(false);
  const [emailRadio, setemailRadio] = useState(false);
  const [refferalRadio, setrefferalRadio] = useState(false);
  const [firstTimeRadio, setfirstTimeRadio] = useState(false);
  const [discountRadio, setdiscountRadio] = useState(false);
  const [callCenterRadio, setcallCenterRadio] = useState(false);
  const [joiningRadio, setjoiningRadio] = useState('immediately');
  const [surveyRewardRadio, setsurveyRewardRadio] = useState(false);
  const [lbsRadio, setlbsRadio] = useState(false);
  const [enrollSMSRadio, setenrollSMSRadio] = useState(false);
  const [posSMSRadio, setposSMSRadio] = useState(false);
  const [flatFileSMSRadio, setflatFileSMSRadio] = useState(false);
  const [auctionSMSRadio, setauctionSMSRadio] = useState(false);
  const [pointsTransferSMSRadio, setpointsTransferSMSRadio] = useState(false);
  const [promoCodeSMSRadio, setpromoCodeSMSRadio] = useState(false);
  const [pointsExpirySMSRadio, setpointsExpirySMSRadio] = useState(false);
  const [pointsForfeitureSMSRadio, setpointsForfeitureSMSRadio] =
    useState(false);
  const [memberQuerySMSRadio, setmemberQuerySMSRadio] = useState(false);
  const [evoucherSMSRadio, setevoucherSMSRadio] = useState(false);
  const [tierUpgradeSMSRadio, settierUpgradeSMSRadio] = useState(false);
  const [pinPasswordSMSRadio, setpinPasswordSMSRadio] = useState(false);
  const [birtdaySMSRadio, setbirthdaySMSRadio] = useState(false);
  const [anniversarySMSRadio, setanniversarySMSRadio] = useState(false);
  const [customSMSRadio, setcustomSMSRadio] = useState(false);
  const [transactionNotifySMSRadio, settransactionNotifySMSRadio] =
    useState(false);
  const [triggerNotifySMSRadio, settriggerNotifySMSRadio] = useState(false);
  const [includeCompanyLogoRadio, setincludeCompanyLogoRadio] = useState(false);
  const [companyWalletRadio, setcompanyWalletRadio] = useState(false);
  const [shareRadio, setshareRadio] = useState(true);
  const [googleURLCheck, setgoogleURLCheck] = useState(false);
  const [linkedinURLCheck, setlinkedinURLCheck] = useState(false);
  const [twitterURLCheck, settwitterURLCheck] = useState(false);
  const [facebookCheck, setfacebookURLCheck] = useState(false);

  // dropdown states
  const [optionID, setOptionID] = useState('');
  const [companyTypeID, setCompanyTypeID] = useState('');
  const [solutionTypeID, setSolutionTypeID] = useState('');
  const [currencyID, setCurrencyID] = useState(1);
  const [regionID, setRegionID] = useState('');
  const [stateID, setStateID] = useState('');
  const [cityID, setCityID] = useState('');
  const [zoneID, setZoneID] = useState('');

  const [companyType, setCompanyType] = useState('');
  const [solutionType, setSolutionType] = useState('');
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [zone, setZone] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [timezone, setTimezone] = useState('');
  const [memberExpiryID, setMemberExpiryID] = useState('');
  const [memberExpiryYears, setMemberExpiryYears] = useState('');
  const [licenseUsage, setLicenseUsage] = useState('');
  const [membershipGenerate, setMembershipGenerate] = useState('');

  //input states
  const [companyName, setCompanyName] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [companyPO_box, setCompanyPO_box] = useState('');
  const [pryPersonName, setPryPersonName] = useState('');
  const [pryPersonEmail, setPryPersonEmail] = useState('');
  const [pryPersonPhone, setPryPersonPhone] = useState('');
  const [secPersonName, setSecPersonName] = useState('');
  const [secPersonEmail, setSecPersonEmail] = useState('');
  const [secPersonPhone, setSecPersonPhone] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyDomain, setCompanyDomain] = useState('');
  const [emailID, setEmailID] = useState('');
  const [smsSenderName, setsmsSenderName] = useState('');
  const [applicationWebsite, setApplicationWebsite] = useState('');
  const [membershipCard, setMembershipcard] = useState('');
  const [membershipCardStartSeries, setMembershipcardStartSeries] =
    useState('');
  const [pointValueDef, setPointValueDef] = useState('');
  const [pointExpiryDays, setPointExpiryDays] = useState('');
  const [orderNumberStartSeries, setOrderNumberStartSeries] = useState('');
  const [evoucherExpiryDays, setEvoucherExpiryDays] = useState('');
  const [evoucherExpiryRem, setEvoucherExpiryRem] = useState('');
  const [apkLinkUsers, setApkLinkUsers] = useState('');
  const [apkLinkUcompany, setApkLinkCompany] = useState('');
  const [iosLinkUsers, setIosLinkUsers] = useState('');
  const [iosLinkUcompany, setIosLinkCompany] = useState('');
  const [apiUsername, setApiUsername] = useState('');
  const [apiPassword, setApiPassword] = useState('');
  const [shareLimit, setShareLimit] = useState('');
  const [facebookSharePoint, setFacebookSharePoint] = useState('');
  const [twitterSharePoint, setTwitterSharePoint] = useState('');
  const [linkedinSharePoint, setLinkedinSharePoint] = useState('');
  const [googleSharePoint, setGoogleSharePoint] = useState('');
  const [redirectURL, setRedirectURL] = useState('');
  const [ticketSeriesNumber, setTicketSeriesNumber] = useState('');
  const [vat, setVat] = useState('');
  const [firstPersonName, setFirstPersonName] = useState('');
  const [firstPersonEmail, setFirstPersonEmail] = useState('');
  const [secondPersonName, setSecondPersonName] = useState('');
  const [secondPersonEmail, setSecondPersonEmail] = useState('');
  const [lslName, setLslName] = useState('');
  const [lslEmail, setLslEmail] = useState('');
  const [lslPhone, setLslPhone] = useState('');

  const [successModal, setSuccessModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  const [updateID, setUpdateID] = useState();
  const [error, setError] = useState('oops... something went wrong');
  const [errors, setErrors] = useState(null);
  const queryClient = useQueryClient();
  
  const formData = {
    company_name: companyName,
    company_type_id: companyTypeID,
    company_solution_id: solutionTypeID,
    country_currency_id: currencyID,
    state_id: stateID,
    zone_id: zoneID,
    region_id: regionID,
    city_id: cityID,
    company_address: companyAddress,
    po_box: companyPO_box,
    membership_generation: 'Automated',
    membership_no_series: membershipCardStartSeries,
    membership_id_length: '',
    no_of_membership_card: membershipCard,
    points_value_defination: pointValueDef,
    points_expiry_period: pointExpiryDays,
    VAT: vat,
    notification_source: notifySourceRadio,
    order_no_starting_series: orderNumberStartSeries,
    sms_limit: 1,
    primary_contact_name: pryPersonName,
    primary_contact_email: pryPersonEmail,
    primary_contact_no: secPersonPhone,
    secondary_contact_name: secPersonName,
    secondary_contact_email: secPersonEmail,
    secondary_contact_no: secPersonPhone,
    company_website_url: companyWebsite,
    company_logo: selectedImageName,
    company_registration_no: String(+new Date()),
    member_expiry: memberExpiryID,
    corporate_email_applicable: emailRadio,
    corporate_email_id: emailID,
    allowed_family_card: familyCardRadio,
    reffered_by_someone: refferalRadio,
    member_password_expiry: passwordExpiryRadio,
    member_password_expiry_date: 4,
    e_voucher_expiry_days: evoucherExpiryDays,
    e_voucher_expiry: Boolean(evoucherExpiryDays),
    e_voucher_expiring_period_reminder: evoucherExpiryRem,
    timezone: timezone,
    application_website: applicationWebsite,
    email_sending_id: emailID,
    sms_senders_name: smsSenderName,
    lsl_contact_person_name: lslName,
    lsl_contact_person_email: lslEmail,
    lsl_contact_person_phone: lslPhone,
    link_to_lbs: lbsRadio,
    link_to_reseller: resellerRadio,
    pin_no_applicable: pinRadio,
    customer_pin_validation_for_transanction: custPinTranRadio,
    customer_pin_validation_for_redemption: custPinRedRadio,
    branch_user_pin_validation_for_transaction: userPinTranRadio,
    branch_user_pin_validation_for_redemption: userPinRedRadio,
    allowed_call_center_notification_to_member: callCenterRadio,
    joining_bonus: joiningRadio,
    first_time_app_login_bonus: firstTimeRadio,
    discounts_applicable: discountRadio,
    period_of_license_usage: '30 Days',
    enable_survey_reward: surveyRewardRadio,
    query_log_series_ticket_no: ticketSeriesNumber,
    enrollment_sms: enrollSMSRadio,
    pos_transaction_sms: posSMSRadio,
    flat_file_process_sms: flatFileSMSRadio,
    auction_bid_sms: auctionSMSRadio,
    point_transfer_sms: pointsTransferSMSRadio,
    promo_code_sms: promoCodeSMSRadio,
    points_expiry_sms: pointsExpirySMSRadio,
    points_forfeiture_sms: pointsForfeitureSMSRadio,
    handle_member_query_or_branch_callcenter_sms: memberQuerySMSRadio,
    'e-voucher_expiry_sms': evoucherSMSRadio,
    tier_upgrade_or_downgrade_sms: tierUpgradeSMSRadio,
    pin_and_password_sms: pinPasswordSMSRadio,
    birthday_sms: birtdaySMSRadio,
    anniversary_sms: anniversarySMSRadio,
    custom_communication_sms: customSMSRadio,
    transaction_notification_sms: transactionNotifySMSRadio,
    trigger_notification_sms: triggerNotifySMSRadio,
    include_company_logo_in_customer_website: includeCompanyLogoRadio,
    company_api_username: apiUsername,
    company_api_password: apiPassword,
    enable_company_wallet: companyWalletRadio,
    enter_apk_link_for_customers: apkLinkUsers,
    enter_apk_link_for_company_users: apkLinkUcompany,
    enter_ios_link_for_customers: iosLinkUsers,
    enter_ios_link_for_company_users: iosLinkUcompany,
    share_type: 'Share Multiple Times',
    share_limit: shareLimit,
    enable_facebook_share: facebookCheck,
    facebook_share_points: facebookSharePoint,
    enable_linkedin_share: linkedinURLCheck,
    linkedin_share_points: linkedinSharePoint,
    enable_twitter_share: twitterURLCheck,
    twitter_share_points: twitterSharePoint,
    enable_googleplus_share: googleURLCheck,
    googleplus_share_points: googleSharePoint,
    share_redirection_url: redirectURL,
    is_active: 1,
  };

  //RedirectToLogin();
  
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredActiveData, setFilteredActiveData] = useState([]);
  const [filteredInactiveData, setFilteredInactiveData] = useState([]);

  const fetchActiveCompanies = async page => {
    const res = await axiosProperty.get(
      ApiConfig.activeCompanyMaster + `?page=${page}`,
      {
        headers: Headers(),
      },
    );
    return res.data;
  };
  const {data: activeCompany, status: activestatus} = useQuery(
    ['activecompanies', page],
    () => fetchActiveCompanies(page),
    {keepPreviousData: true},
  );

  const fetchInactiveCompanies = async page => {
    const res = await axiosProperty.get(
      ApiConfig.inactiveCompanyMaster + `?page=${page}`,
      {
        headers: Headers(),
      },
    );
    return res.data;
  };

  const {data:inactiveCompany, status:inactivestatus} = useQuery(['inactivecompanies', page], () => fetchInactiveCompanies(page), {keepPreviousData:true,});
 console.log(activeCompany);
  useEffect(() => {
    setFilteredActiveData(activeCompany?.data?.data || []);
    setFilteredInactiveData(inactiveCompany?.data?.data || []);
  }, [activeCompany, inactiveCompany]);

  const handleSearchInputChange = searchTerm => {
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      setFilteredInactiveData(inactiveCompany?.data?.data || []); // Reset to original data if search term is empty
    } else if (inactiveCompany?.data) {
      const filtered = inactiveCompany.data.data.filter(
        item =>
          item.company_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.company_address.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setFilteredInactiveData(filtered);
    }
  };

  const handleDropdownChange = id => {
    setOptionID(id);
  };

  const handleCompanyTypeID = id => {
    setCompanyTypeID(id);
  };

  const handleSolutionTypeID = id => {
    setSolutionTypeID(id);
  };

  const handleCurrencyID = id => {
    setCurrencyID(id);
  };

  const handleRegionID = id => {
    setRegionID(id);
  };

  const handleStateID = id => {
    setStateID(id);
  };
  const handleCityID = id => {
    setCityID(id);
  };

  const handleZoneID = id => {
    setZoneID(id);
  };

  const handleMemberExpiryID = id => {
    setMemberExpiryID(id);
  };

  const handleCompanyType = name => {
    setCompanyType(name);
  };
  const handleSolutionType = name => {
    setSolutionType(name);
  };
  const handleCountry = name => {
    setCountry(name);
  };
  const handleRegion = name => {
    setRegion(name);
  };
  const handleZone = name => {
    setZone(name);
  };
  const handleState = name => {
    setState(name);
  };
  const handleCity = name => {
    setCity(name);
  };
  const handleTimezone = name => {
    setTimezone(name);
  };
  const handleMembershipGenerate = name => {
    setMembershipGenerate(name);
  };
  const handleMemberExpiryYears = name => {
    setMemberExpiryYears(name);
  };
  const handleLicenseUsage = name => {
    setLicenseUsage(name);
  };

  const onClose = () => {
    setDeleteModal(false);
  };

  const addCompany = async () => {
    try {
      await axiosProperty.post(ApiConfig.createCompanyMaster, formData, {
        headers: Headers(),
      });
      setSuccessModal(true);
      setSelectedImage(null);
      setSelectedImageName('');
      //radio states
      setpasswordExpiryRadio(false);
      setnotifySourceRadio('email');
      setfamilyCardRadio(false);
      setpinRadio(false);
      setcustPinTranRadio(false);
      setcustPinRedRadio(false);
      setuserPinTranRadio(false);
      setuserPinRedRadio(false);
      setemailRadio(false);
      setrefferalRadio(false);
      setfirstTimeRadio(false);
      setdiscountRadio(false);
      setcallCenterRadio(false);
      setjoiningRadio('immediately');
      setsurveyRewardRadio(false);
      setlbsRadio(false);
      setenrollSMSRadio(false);
      setposSMSRadio(false);
      setflatFileSMSRadio(false);
      setauctionSMSRadio(false);
      setpointsTransferSMSRadio(false);
      setpromoCodeSMSRadio(false);
      setpointsExpirySMSRadio(false);
      setpointsForfeitureSMSRadio(false);
      setmemberQuerySMSRadio(false);
      setevoucherSMSRadio(false);
      settierUpgradeSMSRadio(false);
      setpinPasswordSMSRadio(false);
      setbirthdaySMSRadio(false);
      setanniversarySMSRadio(false);
      setcustomSMSRadio(false);
      settransactionNotifySMSRadio(false);
      settriggerNotifySMSRadio(false);
      setincludeCompanyLogoRadio(false);
      setcompanyWalletRadio(false);
      setshareRadio(true);
      setgoogleURLCheck(false);
      setlinkedinURLCheck(false);
      settwitterURLCheck(false);
      setfacebookURLCheck(false);

      // dropdown states
      setOptionID('');
      setCompanyTypeID('');
      setSolutionTypeID('');
      setCurrencyID('');
      setRegionID('');
      setStateID('');
      setCityID('');
      setZoneID('');

      setCompanyType('');
      setSolutionType('');
      setCountry('');
      setRegion('');
      setZone('');
      setState('');
      setCity('');
      setTimezone('');
      setMemberExpiryID('');
      setMemberExpiryYears('');
      setLicenseUsage('');
      setMembershipGenerate('');

      //input states
      setCompanyName('');
      setCompanyAddress('');
      setCompanyPO_box('');
      setPryPersonName('');
      setPryPersonEmail('');
      setPryPersonPhone('');
      setSecPersonName('');
      setSecPersonEmail('');
      setSecPersonPhone('');
      setCompanyWebsite('');
      setCompanyDomain('');
      setEmailID('');
      setsmsSenderName('');
      setApplicationWebsite('');
      setMembershipcard('');
      setMembershipcardStartSeries('');
      setPointValueDef('');
      setPointExpiryDays('');
      setOrderNumberStartSeries('');
      setEvoucherExpiryDays('');
      setEvoucherExpiryRem('');
      setApkLinkUsers('');
      setApkLinkCompany('');
      setIosLinkUsers('');
      setIosLinkCompany('');
      setApiUsername('');
      setApiPassword('');
      setShareLimit('');
      setFacebookSharePoint('');
      setTwitterSharePoint('');
      setLinkedinSharePoint('');
      setGoogleSharePoint('');
      setRedirectURL('');
      setTicketSeriesNumber('');
      setVat('');
      setFirstPersonName('');
      setFirstPersonEmail('');
      setSecondPersonName('');
      setSecondPersonEmail('');
      setLslName('');
      setLslEmail('');
      setLslPhone('');

      swal({
        title: 'Success!',
        text: 'Company registered successfully',
        icon: 'success',
      });
      setErrors(null);
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        setErrors(err.response.data);
        swal({
          title: 'error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, try again');
        swal({
          title: 'error!',
          text: error,
          icon: 'error',
        });
      }
      console.log(err);
    }
  };

  const {mutate: addCompanyMutation} = useMutation({
    mutationFn: addCompany,
    onSuccess: () => {
      queryClient.invalidateQueries('activecompanies');
      queryClient.invalidateQueries('inactivecompanies');
    },
  });

  const updateCompany = async () => {
    try {
      await axiosProperty.patch(
        ApiConfig.updateCompanyMaster + `/${updateID}`,
        {},
        {
          headers: Headers(),
        },
      );
      setSelectedImage(null);
      setSelectedImageName('');
      setIsSubmit(true);
      setIsUpdate(false);
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
        swal({
          title: 'error!',
          text: error,
          icon: 'error',
        });
      } else {
        setError('Something went wrong, try again');
        swal({
          title: 'error!',
          text: error,
          icon: 'error',
        });
      }
      console.log(err);
    }
  };

  const {mutate: updateCompanyMutation} = useMutation({
    mutationFn: updateCompany,
    onSuccess: () => {
      queryClient.invalidateQueries('activecompanies');
      queryClient.invalidateQueries('inactivecompanies');
    },
  });

  const editCompany = async id => {
    const res = await axiosProperty.get(
      ApiConfig.editCompanyMaster + `/${id}`,
      {
        headers: Headers(),
      },
    );
    setIsUpdate(true);
    setIsSubmit(false);
    setpasswordExpiryRadio(res.data.data.member_password_expiry);
    setnotifySourceRadio(res.data.data.notification_source);
    setfamilyCardRadio(res.data.data.allowed_family_card);
    setpinRadio(res.data.data.pin_no_applicable);
    setcustPinTranRadio(res.data.data.customer_pin_validation_for_transanction);
    setcustPinRedRadio(res.data.data.customer_pin_validation_for_redemption);
    setuserPinTranRadio(
      res.data.data.branch_user_pin_validation_for_transaction,
    );
    setuserPinRedRadio(res.data.data.branch_user_pin_validation_for_redemption);
    setemailRadio(res.data.data.email_sending_id);
    setrefferalRadio(res.data.data.reffered_by_someone);
    setfirstTimeRadio(res.data.data.first_time_app_login_bonus);
    setdiscountRadio(res.data.data.discounts_applicable);
    setcallCenterRadio(
      res.data.data.allowed_call_center_notification_to_member,
    );
    setjoiningRadio(res.data.data.joining_bonus);
    setsurveyRewardRadio(res.data.data.enable_survey_reward);
    setlbsRadio(res.data.data.link_to_lbs);
    setenrollSMSRadio(res.data.data.enrollment_sms);
    setposSMSRadio(res.data.data.pos_transaction_sms);
    setflatFileSMSRadio(res.data.data.flat_file_process_sms);
    setauctionSMSRadio(res.data.data.auction_bid_sms);
    setpointsTransferSMSRadio(res.data.data.point_transfer_sms);
    setpromoCodeSMSRadio(res.data.data.promo_code_sms);
    setpointsExpirySMSRadio(res.data.data.points_expiry_sms);
    setpointsForfeitureSMSRadio(res.data.data.points_forfeiture_sms);
    setmemberQuerySMSRadio(
      res.data.data.handle_member_query_or_branch_callcenter_sms,
    );
    setevoucherSMSRadio(res.data.data.e_voucher_expiry);
    settierUpgradeSMSRadio(res.data.data.tier_upgrade_or_downgrade_sms);
    setpinPasswordSMSRadio(res.data.data.pin_and_password_sms);
    setbirthdaySMSRadio(res.data.data.birthday_sms);
    setanniversarySMSRadio(res.data.data.anniversary_sms);
    setcustomSMSRadio(res.data.data.custom_communication_sms);
    settransactionNotifySMSRadio(res.data.data.transaction_notification_sms);
    settriggerNotifySMSRadio(res.data.data.trigger_notification_sms);
    setincludeCompanyLogoRadio(
      res.data.data.include_company_logo_in_customer_website,
    );
    setcompanyWalletRadio(res.data.data.enable_company_wallet);
    setshareRadio(res.data.data.share_type);
    setgoogleURLCheck(res.data.data.enable_googleplus_share);
    setlinkedinURLCheck(res.data.data.enable_linkedin_share);
    settwitterURLCheck(res.data.data.enable_twitter_share);
    setfacebookURLCheck(res.data.data.enable_facebook_share);

    // dropdown states
    setOptionID(res.data.data.id);
    setCompanyTypeID(res.data.data.company_type_id);
    setSolutionTypeID(res.data.data.company_solution_id);
    setCurrencyID(res.data.data.country_currency_id);
    setRegionID(res.data.data.region_id);
    setStateID(res.data.data.state_id);
    setCityID(res.data.data.city_id);
    setZoneID(res.data.data.zone_id);

    setCompanyType(res.data.data.company_type_id || '');
    setSolutionType(res.data.data.company_solution_id || '');
    setCountry(res.data.data.country_currency_id || '');
    setRegion(res.data.data.region_id || '');
    setZone(res.data.data.zone_id || '');
    setState(res.data.data.state_id || '');
    setCity(res.data.data.city_id || '');
    setTimezone(res.data.data.timezone || '');
    setMemberExpiryID(res.data.data.member_expiry || '');
    setMemberExpiryYears(res.data.data.member_password_expiry_date || '');
    setLicenseUsage(res.data.data.period_of_license_usage || '');
    setMembershipGenerate(res.data.data.membership_generation || '');

    //input states
    setCompanyName(res.data.data.company_name || '');
    setCompanyAddress(res.data.data.company_address || '');
    setCompanyPO_box(res.data.data.po_box || '');
    setPryPersonName(res.data.data.primary_contact_name || '');
    setPryPersonEmail(res.data.data.primary_contact_email || '');
    setPryPersonPhone(res.data.data.primary_contact_no || '');
    setSecPersonName(res.data.data.secondary_contact_name || '');
    setSecPersonEmail(res.data.data.secondary_contact_email || '');
    setSecPersonPhone(res.data.data.secondary_contact_no || '');
    setCompanyWebsite(res.data.data.company_website_url || '');
    setCompanyDomain();
    setEmailID(res.data.data.corporate_email_id || '');
    setsmsSenderName(res.data.data.sms_senders_name || '');
    setApplicationWebsite(res.data.data.application_website || '');
    setMembershipcard(res.data.data.no_of_membership_card || '');
    setMembershipcardStartSeries(res.data.data.membership_no_series || '');
    setPointValueDef(res.data.data.points_value_defination || '');
    setPointExpiryDays(res.data.data.points_expiry_period || '');
    setOrderNumberStartSeries(res.data.data.order_no_starting_series || '');
    setEvoucherExpiryDays(res.data.data.e_voucher_expiry_days || '');
    setEvoucherExpiryRem(
      res.data.data.e_voucher_expiring_period_reminder || '',
    );
    setApkLinkUsers(res.data.data.enter_apk_link_for_customers || '');
    setApkLinkCompany(res.data.data.enter_apk_link_for_company_users);
    setIosLinkUsers(res.data.data.enter_ios_link_for_customers || '');
    setIosLinkCompany(res.data.data.enter_ios_link_for_company_users || '');
    setApiUsername(res.data.data.company_api_username || '');
    setApiPassword(res.data.data.company_api_password || '');
    setShareLimit(res.data.data.share_limit || '');
    setFacebookSharePoint(res.data.data.facebook_share_points || '');
    setTwitterSharePoint(res.data.data.twitter_share_points || '');
    setLinkedinSharePoint(res.data.data.linkedin_share_points || '');
    setGoogleSharePoint(res.data.data.googleplus_share_points || '');
    setRedirectURL(res.data.data.share_redirection_url || '');
    setTicketSeriesNumber(res.data.data.query_log_series_ticket_no || '');
    setVat(res.data.data.vat || '');
    setFirstPersonName(res.data.data.firs || '');
    setFirstPersonEmail('');
    setSecondPersonName('');
    setSecondPersonEmail('');
    setLslName(res.data.data.lsl_contact_person_name || '');
    setLslEmail(res.data.data.lsl_contact_person_email || '');
    setLslPhone(res.data.data.lsl_contact_person_phone || '');
    //setSelectedImage(res.data.data.logo);
  };

  const deleteCompany = async id => {
    try {
      const res = await axiosProperty.delete(
        ApiConfig.deleteCompanyMaster + `/${id}`,
        {
          headers: Headers(),
        },
      );
      //setSuccessModal(true);
      setDeleteModal(false);
      swal({
        title: 'Success!',
        text: 'Delete was successful',
        icon: 'success',
      });
      queryClient.invalidateQueries('activecompanies');
      queryClient.invalidateQueries('inactivecompanies');
    } catch (err) {
      if (err.response) {
        //setErrors(err.response.data);
        setError(err.response.data.message);
      } else {
        setError('Something went wrong, try again');
      }
      console.log(err);
      swal({
        title: 'Warning!',
        text: error,
        icon: 'warning',
      });
      console.log(err);
    }
  };

  return (
    <CallCenterStructure>
      <div className={styles.registerCompany}>
        <div className={styles.headerText}>
          <p>Register Company</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.fieldContainer1}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Enter Company Name<sup>*</sup>
              </label>
              <input
                type="text"
                value={companyName}
                onChange={e => setCompanyName(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select Type of Company<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchCompanyTypes} onSelect={handleCompanyTypeID} onSelectOption={handleCompanyType} retrievedValue={companyTypeID} column={'type'} key="companytype"/>
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select Type of Solution<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchCompanyTypes} onSelect={handleSolutionTypeID} onSelectOption={handleSolutionType} retrievedValue={solutionTypeID} column={'solution'} key="solutiontype"/>
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer2}>
              <label htmlFor="">
                Enter Company Address<sup>*</sup>
              </label>
              <input
                type="text"
                value={companyAddress}
                onChange={e => setCompanyAddress(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer2}>
              <label htmlFor="">
                Enter P.O. Box Address<sup>*</sup>
              </label>
              <input
                type="text"
                value={companyPO_box}
                onChange={e => setCompanyPO_box(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer3}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select Country<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchCountry} onSelect={handleCurrencyID} onSelectOption={handleCountry} retrievedValue={currencyID} column={'name'} key="country"/>
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select Region<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchRegion(currencyID)} onSelect={handleRegionID} onSelectOption={handleRegion} retrievedValue={regionID} column={'name'} key="region"/>
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select Zone<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchZone(regionID)} onSelect={handleZoneID} onSelectOption={handleZone} retrievedValue={zoneID} column={'name'} key="zone"/>
            </div>
          </div>
          <div className={styles.fieldContainer3}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select State<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchState(zoneID)} onSelect={handleStateID} onSelectOption={handleState} retrievedValue={stateID} column={'name'} key="state"/>
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select City<sup>*</sup>
              </label>
              <Dropdown header={Headers()} api={ApiConfig.fetchCity(stateID)} onSelect={handleCityID} onSelectOption={handleCity} retrievedValue={cityID} column={'name'} key="city"/>
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Select TimeZone<sup></sup>
              </label>
              <Dropdown header={Headers()} onSelect={handleDropdownChange} onSelectOption={handleTimezone} retrievedValue={timezone} column={'timezone'} key="timezone"/>
            </div>
          </div>
          <div className={styles.fieldContainer3_}>
            <div className={styles.innerFieldContainer1_}>
              <label htmlFor="">
                Enter Pry Person Name<sup>*</sup>
              </label>
              <input
                type="text"
                value={pryPersonName}
                onChange={e => setPryPersonName(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer1_}>
              <label htmlFor="">
                Enter Pry Person Email Id<sup>*</sup>
              </label>
              <input
                type="text"
                value={pryPersonEmail}
                onChange={e => setPryPersonEmail(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer1_}>
              <label htmlFor="">
                Enter Pry Phone No. <sup>*</sup> (+country code)
              </label>
              <input
                type="text"
                value={pryPersonPhone}
                onChange={e => setPryPersonPhone(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer3_}>
            <div className={styles.innerFieldContainer1_}>
              <label htmlFor="">
                Enter Sec Person Name<sup>*</sup>
              </label>
              <input
                type="text"
                value={secPersonName}
                onChange={e => setSecPersonName(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer1_}>
              <label htmlFor="">
                Enter Sec. Person Email ID<sup>*</sup>
              </label>
              <input
                type="text"
                value={secPersonEmail}
                onChange={e => setSecPersonEmail(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer1_}>
              <label htmlFor="">
                Enter Sec. Phone No. <sup>*</sup> (+country code)
              </label>
              <input
                type="text"
                value={secPersonPhone}
                onChange={e => setSecPersonPhone(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer2}>
            <div className={styles.innerFieldContainer2}>
              <label htmlFor="">
                Enter Company Website<sup>*</sup>
              </label>
              <input
                type="text"
                value={companyWebsite}
                onChange={e => setCompanyWebsite(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer2}>
              <label htmlFor="">
                Enter Company Domain<sup>*</sup>
              </label>
              <input
                type="text"
                value={companyDomain}
                onChange={e => setCompanyDomain(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.itemInputContainer4}>
            <div className={styles.defaultImageContainer}>
              <div className={styles.imageContainer}>
                {selectedImage ? (
                  <img src={selectedImage} alt="default" />
                ) : (
                  <img src={defaultImage} alt="default" />
                )}
              </div>
              <p>Logo</p>
            </div>

            <div className={styles.uploadContainer}>
              <div className={styles.uploadInputContainer}>
                <label htmlFor="">
                  Upload Company Logo <sup>*</sup> Max. File Size 50kb. Image
                  Resolution preferred 150 Pixels(H) X 75 Pixels(V)
                </label>
                <ImageDragAndDrop
                  selectedImageName={selectedImageName}
                  setSelectedImageName={setSelectedImageName}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer3Adjust}>
            <div className={styles.innerFieldContainer1Adjust}>
              <label htmlFor="">
                Enter Company Email Sending ID<sup>*</sup>
              </label>
              <input
                type="text"
                value={emailID}
                onChange={e => setEmailID(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer1Adjust}>
              <label htmlFor="">
                Enter Company SMS Sender Name<sup>*</sup>
              </label>
              <input
                type="text"
                value={smsSenderName}
                onChange={e => setsmsSenderName(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer1Adjust}>
              <label htmlFor="">
                Enter Application Website<sup>*</sup>
              </label>
              <input
                type="text"
                value={applicationWebsite}
                onChange={e => setApplicationWebsite(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer4}>
            <div className={styles.innerFieldContainer4}>
              <label htmlFor="">Linked to Reseller</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setResellerRadio}
              />
            </div>
            <div className={styles.innerFieldContainer4}>
              <label htmlFor="">
                Select Membership Generation<sup>*</sup>
              </label>
              <Dropdown header={Headers()} onSelect={handleDropdownChange} onSelectOption={handleMembershipGenerate} retrievedValue={optionID} column={'name'} enumType={[{id:1, name:'manual'}, {id:2, name:'Automated'}, {id:3, name:'Pre-Generated'}]}/>
            </div>
            <div className={styles.innerFieldContainer4}>
              <label htmlFor="">
                Enter Total No. of Membership Card<sup>*</sup>
              </label>
              <input
                type="text"
                value={membershipCard}
                onChange={e => setMembershipcard(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer4}>
              <label htmlFor="">
                Enter Starting Series Of Membership Card<sup>*</sup>
              </label>
              <input
                type="text"
                value={membershipCardStartSeries}
                onChange={e => setMembershipcardStartSeries(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer3}>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Enter Point Value Definition<sup>*</sup>
              </label>
              <input
                type="text"
                value={pointValueDef}
                onChange={e => setPointValueDef(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Enter Point Expiry Period in days<sup>*</sup>
              </label>
              <input
                type="text"
                value={pointExpiryDays}
                onChange={e => setPointExpiryDays(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer1}>
              <label htmlFor="">
                Enter VAT<sup>*</sup>
              </label>
              <input
                type="text"
                value={vat}
                onChange={e => setVat(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer4_}>
            <div className={styles.innerFieldContainer4_}>
              <label htmlFor="">Enter Order Number Starting Series</label>
              <input
                type="text"
                value={orderNumberStartSeries}
                onChange={e => setOrderNumberStartSeries(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer4_}>
              <label htmlFor="">
                Select Notification Source<sup>*</sup>
              </label>
              <RadioBlueButton3
                firstText="Email"
                secondText="Sms"
                thirdText="Both"
                onchange={setnotifySourceRadio}
              />
            </div>
          </div>
          <div className={styles.fieldContainer4}>
            <div className={styles.innerFieldContainer4}>
              <label htmlFor="">Member Password Expiry Period applicable</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setpasswordExpiryRadio}
              />
            </div>
            <div className={styles.innerFieldContainer4}>
              <label htmlFor="">
                Enter e-Voucher Expiry Period in Days<sup>*</sup>
              </label>
              <input
                type="text"
                value={evoucherExpiryDays}
                onChange={e => setEvoucherExpiryDays(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer4}>
              <label htmlFor="">
                e-Voucher Expiry Period Reminder<sup>*</sup>
              </label>
              <input
                type="text"
                value={evoucherExpiryRem}
                onChange={e => setEvoucherExpiryRem(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer4}>
              <label htmlFor="">
                Select Member Expiry in Years<sup>*</sup>
              </label>
              <Dropdown header={Headers()} onSelect={handleMemberExpiryID} onSelectOption={handleMemberExpiryYears} retrievedValue={memberExpiryID} column={'name'} key="memberexpiry"/>
            </div>
          </div>
          <div className={styles.fieldContainer5}>
            <div className={styles.innerFieldContainer5}>
              <div className={styles.radioButton5}>
                <label htmlFor="">
                  Allow Family Card<sup>*</sup>
                </label>
                <RadioButtonBlue
                  firstText="Yes"
                  secondText="No"
                  onChange={setfamilyCardRadio}
                />
              </div>
            </div>
            <div className={styles.innerFieldContainer5}>
              <div className={styles.radioButton5}>
                <label htmlFor="">
                  Pin No. Applicable<sup>*</sup>
                </label>
                <RadioButtonBlue
                  firstText="Yes"
                  secondText="No"
                  onChange={setpinRadio}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer5}>
            <div className={styles.innerFieldContainer5}>
              <div className={styles.radioButton5}>
                <label htmlFor="">
                  Customer Pin Validation (Transaction)<sup>*</sup>
                </label>
                <RadioButtonBlue
                  firstText="Yes"
                  secondText="No"
                  onChange={setcustPinTranRadio}
                />
              </div>
            </div>
            <div className={styles.innerFieldContainer5}>
              <div className={styles.radioButton5}>
                <label htmlFor="">
                  Customer Pin Validation (Redemption)<sup>*</sup>
                </label>
                <RadioButtonBlue
                  firstText="Yes"
                  secondText="No"
                  onChange={setcustPinRedRadio}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer5}>
            <div className={styles.innerFieldContainer5}>
              <div className={styles.radioButton5}>
                <label htmlFor="">
                  Branch User Pin Validation (Transaction)<sup>*</sup>
                </label>
                <RadioButtonBlue
                  firstText="Yes"
                  secondText="No"
                  onChange={setuserPinTranRadio}
                />
              </div>
            </div>
            <div className={styles.innerFieldContainer5}>
              <div className={styles.radioButton5}>
                <label htmlFor="">
                  Branch User Pin Validation (Redemption)<sup>*</sup>
                </label>
                <RadioButtonBlue
                  firstText="Yes"
                  secondText="No"
                  onChange={setuserPinRedRadio}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer5}>
            <div className={styles.innerFieldContainer5}>
              <div className={styles.radioButton5}>
                <label htmlFor="">
                  Corporate Email Id Applicable<sup>*</sup>
                </label>
                <RadioButtonBlue
                  firstText="Yes"
                  secondText="No"
                  onChange={setemailRadio}
                />
              </div>
            </div>
            <div className={styles.innerFieldContainer5}>
              <div className={styles.radioButton5}>
                <label htmlFor="">
                  Use Refferal?<sup>*</sup>
                </label>
                <RadioButtonBlue
                  firstText="Yes"
                  secondText="No"
                  onChange={setrefferalRadio}
                />
              </div>
            </div>
          </div>

          <div className={styles.fieldContainer5}>
            <div className={styles.innerFieldContainer5}>
              <div className={styles.radioButton5}>
                <label htmlFor="">
                  First Time App Login Bonus<sup>*</sup>
                </label>
                <RadioButtonBlue
                  firstText="Yes"
                  secondText="No"
                  onChange={setfirstTimeRadio}
                />
              </div>
            </div>
            <div className={styles.innerFieldContainer5}>
              <div className={styles.radioButton5}>
                <label htmlFor="">
                  Discount Applicable<sup>*</sup>
                </label>
                <RadioButtonBlue
                  firstText="Yes"
                  secondText="No"
                  onChange={setdiscountRadio}
                />
              </div>
            </div>
          </div>
          <div className={styles.fieldContainer6}>
            <div className={styles.innerFieldContainer6}>
              <div className={styles.radioButton5}>
                <label htmlFor="">
                  Allow Call Center Notification to Member?<sup>*</sup>
                </label>
                <RadioButtonBlue
                  firstText="Yes"
                  secondText="No"
                  onChange={setcallCenterRadio}
                />
              </div>
            </div>

            <div className={styles.radioButton6}>
              <label htmlFor="">
                Joining Bonus<sup>*</sup>
              </label>
              <RadioBlueButton3
                firstText="Immediately"
                secondText="On First Transaction"
                thirdText="No"
                onchange={setjoiningRadio}
              />
            </div>
          </div>
          <div className={styles.fieldContainer7}>
            <div className={styles.innerFieldContainer7}>
              <label htmlFor="">
                Select Period of Licence Usage<sup>*</sup>
              </label>
              <Dropdown header={Headers()} onSelect={handleDropdownChange} onSelectOption={handleLicenseUsage} retrievedValue={licenseUsage} column={'name'} key="licenseusage" enumType={[{id:1, name:'30 Days'},{id:2, name:'60 Days'}, {id:3, name:'90 Days'},{id:4, name:'1 Year'}, {id:5, name:'2 Years'}, {id:5, name:'3 Years'}]}/>
            </div>
            <div className={styles.innerFieldContainer7}>
              <label htmlFor="">Enable Survey Reward</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setsurveyRewardRadio}
              />
            </div>
            <div className={styles.innerFieldContainer7}>
              <label htmlFor="">Query Log Ticket Series No</label>
              <input
                type="text"
                value={ticketSeriesNumber}
                onChange={e => setTicketSeriesNumber(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <h6 className={styles.formHeaders}>For 'Contact Us' Details</h6>
          <div className={styles.fieldContainer8}>
            <div className={styles.innerFieldContainer8}>
              <label htmlFor="">Enter 1st Person Name</label>
              <input
                type="text"
                value={firstPersonName}
                onChange={e => setFirstPersonName(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer8}>
              <label htmlFor="">Enter 1st Person Email Id</label>
              <input
                type="text"
                value={firstPersonEmail}
                onChange={e => setFirstPersonEmail(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer8}>
              <label htmlFor="">Enter 2nd Person Name</label>
              <input
                type="text"
                value={secondPersonName}
                onChange={e => setSecondPersonName(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer8}>
              <label htmlFor="">Enter 2nd Person Email Id</label>
              <input
                type="text"
                value={secondPersonEmail}
                onChange={e => setSecondPersonEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <h6 className={styles.formHeaders}>LSL Contact Person Details</h6>
          <div className={styles.fieldContainer10}>
            <div className={styles.innerFieldContainer10}>
              <label htmlFor="">LSL Person Name</label>
              <input
                type="text"
                value={lslName}
                onChange={e => setLslName(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer10}>
              <label htmlFor="">LSL Person Email Id</label>
              <input
                type="text"
                value={lslEmail}
                onChange={e => setLslEmail(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer10}>
              <label htmlFor="">LSL Person Phone No ( + country code)</label>
              <input
                type="text"
                value={lslPhone}
                onChange={e => setLslPhone(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <h6 className={styles.formHeaders}>For 'Smartphone APK' Details</h6>
          <div className={styles.fieldContainer9}>
            <div className={styles.innerFieldContainer9}>
              <label htmlFor="">Enter APK Link for Customers</label>
              <input
                type="text"
                value={apkLinkUsers}
                onChange={e => setApkLinkUsers(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer9}>
              <label htmlFor="">Enter APK Link for Company Users</label>
              <input
                type="text"
                value={apkLinkUcompany}
                onChange={e => setApkLinkCompany(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <h6 className={styles.formHeaders}>For 'Smartphone IOS' Details</h6>
          <div className={styles.fieldContainer9}>
            <div className={styles.innerFieldContainer9}>
              <label htmlFor="">Enter IOS Link for Customers</label>
              <input
                type="text"
                value={iosLinkUsers}
                onChange={e => setIosLinkUsers(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer9}>
              <label htmlFor="">Enter IOS Link for Company Users</label>
              <input
                type="text"
                value={iosLinkUcompany}
                onChange={e => setIosLinkCompany(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <h6 className={styles.formHeaders}>For 'LBS' Details</h6>
          <div className={styles.fieldContainer12}>
            <div className={styles.innerFieldContaine12}>
              <label htmlFor="">Linked To LBS(Location Based Service)</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setlbsRadio}
              />
            </div>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <h6 className={styles.formHeaders}>For 'SMS Provision' Details</h6>
          <div className={styles.fieldContainer13}>
            <div className={styles.innerFieldContainer13}>
              <label htmlFor="">Enrollment SMS</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setenrollSMSRadio}
              />
            </div>
            <div className={styles.innerFieldContainer13}>
              <label htmlFor="">Pos Transaction SMS</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setposSMSRadio}
              />
            </div>
            <div className={styles.innerFieldContainer13}>
              <label htmlFor="">Flat File Process SMS</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setflatFileSMSRadio}
              />
            </div>
          </div>
          <div className={styles.fieldContainer13}>
            <div className={styles.innerFieldContainer13}>
              <label htmlFor="">Auction Bid SMS</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setauctionSMSRadio}
              />
            </div>
            <div className={styles.innerFieldContainer13}>
              <label htmlFor="">Points Transfer SMS</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setpointsTransferSMSRadio}
              />
            </div>
            <div className={styles.innerFieldContainer13}>
              <label htmlFor="">Promo Code SMS</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setpromoCodeSMSRadio}
              />
            </div>
          </div>
          <div className={styles.fieldContainer13Adjust}>
            <div className={styles.innerFieldContainer13Adjust}>
              <label htmlFor="">Points Expiry SMS</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setpointsExpirySMSRadio}
              />
            </div>
            <div className={styles.innerFieldContainer13Adjust}>
              <label htmlFor="">Points Forfeiture SMS</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setpointsForfeitureSMSRadio}
              />
            </div>
            <div className={styles.innerFieldContainer13Adjust}>
              <label htmlFor="">
                Handle Member Query - Branch/Call Center - Send SMS
              </label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setmemberQuerySMSRadio}
              />
            </div>
          </div>
          <div className={styles.fieldContainer13}>
            <div className={styles.innerFieldContainer13}>
              <label htmlFor="">e-Voucher Expiry SMS</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setevoucherSMSRadio}
              />
            </div>
            <div className={styles.innerFieldContainer13}>
              <label htmlFor="">Tier Upgrade-Downgrade SMS</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={settierUpgradeSMSRadio}
              />
            </div>
            <div className={styles.innerFieldContainer13}>
              <label htmlFor="">Pin & Password SMS</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setpinPasswordSMSRadio}
              />
            </div>
          </div>
          <div className={styles.fieldContainer13}>
            <div className={styles.innerFieldContainer13}>
              <label htmlFor="">Birthday SMS</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setbirthdaySMSRadio}
              />
            </div>
            <div className={styles.innerFieldContainer13}>
              <label htmlFor="">Anniversary SMS</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setanniversarySMSRadio}
              />
            </div>
            <div className={styles.innerFieldContainer13}>
              <label htmlFor="">Custom Communication SMS</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setcustomSMSRadio}
              />
            </div>
          </div>
          <div className={styles.fieldContainer14}>
            <div className={styles.innerFieldContainer14}>
              <label htmlFor="">
                Transaction Notification SMS( Monthly/ Quarterly )
              </label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={settransactionNotifySMSRadio}
              />
            </div>
            <div className={styles.innerFieldContainer14}>
              <label htmlFor="">Trigger Notification SMS</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={settriggerNotifySMSRadio}
              />
            </div>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <h6 className={styles.formHeaders}>For 'Compnay Logo' Details</h6>
          <div className={styles.fieldContainer12}>
            <div className={styles.innerFieldContaine12}>
              <label htmlFor="">Include Company Logo in Customer Website</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setincludeCompanyLogoRadio}
              />
            </div>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <h6 className={styles.formHeaders}>For 'Compnay API' Details</h6>
          <div className={styles.fieldContainer9}>
            <div className={styles.innerFieldContainer9}>
              <label htmlFor="">Enter Company API Username</label>
              <input
                type="text"
                value={apiUsername}
                onChange={e => setApiUsername(e.target.value)}
              />
            </div>
            <div className={styles.innerFieldContainer9}>
              <label htmlFor="">Enter Company API Password</label>
              <input
                type="text"
                value={apiPassword}
                onChange={e => setApiPassword(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <h6 className={styles.formHeaders}>For 'Compnay Wallet'</h6>
          <div className={styles.fieldContainer12}>
            <div className={styles.innerFieldContaine12}>
              <label htmlFor="">Enable Company Wallet?</label>
              <RadioButtonBlue
                firstText="Yes"
                secondText="No"
                onChange={setcompanyWalletRadio}
              />
            </div>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <h6 className={styles.formHeaders}>
            For 'Notification Share on Social Media'
          </h6>
          <div className={styles.fieldContainer15}>
            <div className={styles.innerFieldContaine15}>
              <label htmlFor="">Share Type</label>
              <RadioButtonBlue
                firstText="Share once"
                secondText="Share Multiple Time"
                onChange={setshareRadio}
              />
            </div>
            <div className={styles.innerFieldContaine15}>
              <label htmlFor="">Enter Share Limit</label>
              <input
                type="text"
                value={shareLimit}
                onChange={e => setShareLimit(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer15}>
            <div className={styles.innerFieldContaine15}>
              <label htmlFor="">Facebook Share</label>
              <div className={styles.checkboxAndImgContainer}>
                <CheckBox onChange={setfacebookURLCheck} />
                <img src={facebook} alt="facebook" />
              </div>
            </div>
            <div className={styles.innerFieldContaine15}>
              <label htmlFor="">Facebook Share Points</label>
              <input
                type="text"
                value={facebookSharePoint}
                onChange={e => setFacebookSharePoint(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer15}>
            <div className={styles.innerFieldContaine15}>
              <label htmlFor="">Twitter Share</label>
              <div className={styles.checkboxAndImgContainer}>
                <CheckBox onChange={settwitterURLCheck} />
                <img src={twitter} alt="twitter" />
              </div>
            </div>
            <div className={styles.innerFieldContaine15}>
              <label htmlFor="">Twitter Share Points</label>
              <input
                type="text"
                value={twitterSharePoint}
                onChange={e => setTwitterSharePoint(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer15}>
            <div className={styles.innerFieldContaine15}>
              <label htmlFor="">Linkedin Share</label>
              <div className={styles.checkboxAndImgContainer}>
                <CheckBox onChange={setlinkedinURLCheck} />
                <img src={linkedin} alt="linkedin" />
              </div>
            </div>
            <div className={styles.innerFieldContaine15}>
              <label htmlFor="">Linkedin Share Points</label>
              <input
                type="text"
                value={linkedinSharePoint}
                onChange={e => setLinkedinSharePoint(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer15}>
            <div className={styles.innerFieldContaine15}>
              <label htmlFor="">Google Share</label>
              <div className={styles.checkboxAndImgContainer}>
                <CheckBox onChange={setgoogleURLCheck} />
                <img src={google} alt="google" />
              </div>
            </div>
            <div className={styles.innerFieldContaine15}>
              <label htmlFor="">Google Share Points</label>
              <input
                type="text"
                value={googleSharePoint}
                onChange={e => setGoogleSharePoint(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.fieldContainer16}>
            <label htmlFor="">Share Redirection URL</label>
            <input
              type="text"
              value={redirectURL}
              onChange={e => setRedirectURL(e.target.value)}
            />
          </div>
          <div className={styles.buttonContainer}>
            {isSubmit && (
              <button
                className={styles.submit}
                onClick={() => addCompanyMutation()}>
                Submit
              </button>
            )}
            {isUpdate && (
              <button
                className={styles.submit}
                onClick={() => updateCompanyMutation()}>
                Update
              </button>
            )}
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
          <br />
          <ErrorMessage errMsg={errors} />
        </div>

        <div className={styles.inputContainer}>
          <h6 className={styles.companyStatus}>active company</h6>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Type Of Company</th>
                  <th>Type Of Solution</th>
                  <th>Company Reg. No.</th>
                  <th>Company Address</th>
                  <th>Contact Person Name</th>
                  <th>Contact Person Email Id</th>
                  <th>Phone No.</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredActiveData.map(each => (
                  <tr key={each.reg}>
                    <td>{each.company_name}</td>
                    <td>{each.company_type_id}</td>
                    <td>{each.company_solution_id}</td>
                    <td>{each.membership_no_series}</td>
                    <td>{each.company_address}</td>
                    <td>{each.lsl_contact_person_name}</td>
                    <td>{each.lsl_contact_person_email}</td>
                    <td>{each.lsl_contact_person_phone}</td>
                    <td>
                      <div className={styles.iconContainer}>
                        <span
                          className={styles.icon}
                          onClick={() => editCompany(each.id)}>
                          <ViewPen />
                        </span>
                        <span
                          className={styles.icon}
                          onClick={() => {
                            setDeleteModal(true);
                            setSelectedItemId(each.id);
                          }}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={activeCompany} setPage={setPage} />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <h6 className={styles.companyStatus}>inactive company</h6>
          <div className={styles.searchInputContainer}>
            <SearchInput onSearchInputChange={handleSearchInputChange} />
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Type Of Company</th>
                  <th>Type Of Solution</th>
                  <th>Company Reg. No.</th>
                  <th>Company Address</th>
                  <th>Contact Person Name</th>
                  <th>Contact Person Email Id</th>
                  <th>Phone No.</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredInactiveData.map(each => (
                  <tr key={each.reg}>
                    <td>{each.company_name}</td>
                    <td>{each.company_type_id}</td>
                    <td>{each.company_solution_id}</td>
                    <td>{each.membership_no_series}</td>
                    <td>{each.company_address}</td>
                    <td>{each.lsl_contact_person_name}</td>
                    <td>{each.lsl_contact_person_email}</td>
                    <td>{each.lsl_contact_person_phone}</td>
                    <td>
                      <div className={styles.iconContainer}>
                        <span
                          className={styles.icon}
                          onClick={() => editCompany(each.id)}>
                          <ViewPen />
                        </span>
                        <span
                          className={styles.icon}
                          onClick={() => {
                            setDeleteModal(true);
                            setSelectedItemId(each.id);
                          }}>
                          <Times />
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className={styles.paginationContainer}>
            {/* <p>Pagination</p> */}
            <Paginate page={page} data={inactiveCompany} setPage={setPage} />
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && (
          <MenuDeleteModal
            onClose={onClose}
            onDelete={() => deleteCompany(selectedItemID)}
          />
        )}
      </div>
    </CallCenterStructure>
  );
};

export default RegisterCompany;
