import React, {useState} from 'react';
import CallCenterStructure from '../CallCenterStructure/CallCenterStructure';
import styles from '../../styles/TemplateMasterSetupForSMS.module.scss';
import Dropdown from '../../component/DropDown/DropDown';
import SearchInput from '../../component/SearchInput/SearchInput';
import {Times, ViewPen} from '../../assets/icons';
import MenuDeleteModal from '../../component/MenuDeleteModal/MenuDeleteModal';
import ClearInputModal from '../../component/ClearInputModal/ClearInputModal';

const TemplateMasterSetupForSMS = () => {
  const [clearModal, setClearModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const onClose = () => {
    setDeleteModal(false);
  };
  const tableData = [
    {id: 1, name: 'Customer Enrollment Template', desc: 'sjhshhs'},
    {id: 2, name: 'Customer Enrollment Template', desc: 'sjhshhs'},
    {id: 3, name: 'Customer Enrollment Template', desc: 'sjhshhs'},
  ];

  return (
    <CallCenterStructure>
      <div className={styles.smsTemplateContainer}>
        <div className={styles.headerText}>
          <p>SMS Template Master</p>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputField1}>
            <div className={styles.inputFieldContainer1}>
              <label htmlFor="">Select Template Name</label>
              <Dropdown />
            </div>
            <div className={styles.inputFieldContainer1}>
              <label htmlFor="">
                Template Name <sup>*</sup>
              </label>

              <input type="text" />
            </div>
          </div>
          <div className={styles.inputField2}>
            <label htmlFor="">Enter Description</label>
            <textarea
              name=""
              placeholder="Insert your text here"
              className={styles.textArea}></textarea>
          </div>
          <div className={styles.inputField2}>
            <label htmlFor="">
              Enter SMS Content<sup>*</sup>
            </label>
            <textarea
              name=""
              placeholder="Insert your text here"
              className={styles.textArea}></textarea>
          </div>
          <div className={styles.inputField3}>
            <div className={styles.selectVariable}>
              <label htmlFor="">Select Variables For SMS Content</label>
              <Dropdown />
            </div>
            <button className={styles.previewBtn}>Preview</button>
          </div>
          <div className={styles.buttonContainer}>
            <button className={styles.submit}>Submit</button>
            <button
              className={styles.reset}
              type="button"
              onClick={() => setClearModal(true)}>
              Reset
            </button>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.searchInputContainer}>
              <SearchInput />
              <button className={styles.searchBtn}>Search</button>
            </div>
            <div className={styles.tableContainer}>
              <table className={styles.mainTable}>
                <thead>
                  <tr>
                    <th>SMS Template ID</th>
                    <th>SMS Template Name</th>
                    <th>Template Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map(each => (
                    <tr key={each.id}>
                      <td>{each.id}</td>
                      <td>{each.name}</td>
                      <td>{each.desc}</td>
                      <td>
                        <div className={styles.iconContainer}>
                          <span className={styles.icon}>
                            <ViewPen />
                          </span>
                          <span
                            className={styles.icon}
                            onClick={() => setDeleteModal(true)}>
                            <Times />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles.paginationContainer}>
              <p>Pagination</p>
            </div>
          </div>
        </div>
        {/* Pass your clear input function to clearInputFn */}
        <ClearInputModal
          //   clearInputFn={handleReset}
          clearModalStatus={clearModal}
          clearInputModalState={setClearModal}
        />
        {deleteModal && <MenuDeleteModal onClose={onClose} />}
      </div>
    </CallCenterStructure>
  );
};

export default TemplateMasterSetupForSMS;
