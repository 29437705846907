import React from 'react';
import Category from '../../component/Category/Category';
import Dropdown from '../../component/DropDown/DropDown';
import TemplateFooter from '../../component/TemplateFooter/TemplateFooter';
import TemplateNavbar from '../../component/TemplateNavbar/TemplateNavbar';
import styles from '../../styles/AirtimeCheckout.module.scss';
import mtnImage from '../../assets/images/mtn.png';

const AirtimeCheckout = () => {
  return (
    <div className={styles.AirtimeCheckoutContainer}>
      <TemplateNavbar />
      <Category />
      <div className={styles.AirtimeCheckoutContent}>
        <div className={styles.AirtimeCheckoutLeft}>
          <div className={styles.checkoutLogo}>
            <img src={mtnImage} alt="Checkout Logo" />
          </div>
        </div>
        <div className={styles.AirtimeCheckoutRight}>
          <div className={styles.innerRight}>
            <h6 className={styles.hearderText}>Topup Your Airtime</h6>
            <p className={styles.parag}>
              Please select your preferred value of airtime below
            </p>
            <div>
              <label htmlFor="">Choose a value</label>
              <Dropdown />
            </div>
            <div className={styles.userNumber}>
              <label htmlFor="">Enter Recipient’s Phone Number</label>
              <input type="number" />
            </div>
            <h6 className={styles.recipientText}>Recipient</h6>
            <div className={styles.userNumber}>
              <label htmlFor="">Email</label>
              <input type="email" />
            </div>
            <div className={styles.userNumber}>
              <label htmlFor="">Phone Number</label>
              <input type="number" />
            </div>
            <div className={styles.totalContainer}>
              <h5>Total</h5>
              <h6>28,000 Points</h6>
            </div>
            <div className={styles.processBTN}>
              <button className={styles.processOrderBTN}>Process Order</button>
            </div>
          </div>
          <div className={styles.checkoutText}>
            <p className={styles.checkoutText1}>
              By processing an order, you agree to (provider)’s Terms and
              Privacy policy
            </p>
            <p className={styles.checkoutText2}>
              Information and update on this order will be sent to your
              registered email address
            </p>
          </div>
        </div>
      </div>
      <TemplateFooter />
    </div>
  );
};

export default AirtimeCheckout;
